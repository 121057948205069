import React, { useState, useEffect, useRef } from "react";
// icons
import LeftArrow from "../../../assets/arrow-left.svg";
import ClockIcon from "../../../assets/clock.svg";
import { ConfirmModal } from "../../SessionModal/SessionModal";
import { Col, Row, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SuccessToast, ErrorToast } from "../../MyToaster";
import moment from "moment-timezone";
import "moment-timezone/data/meta/latest.json";
import { reScheduleForMySession, reScheduleForMySessionConfirm } from "../../../service/redux/middleware/meeting";
import { viewSession } from "../../../service/redux/middleware/session";
import DateTimePicker from "react-datetime-picker";
import { mySessionNotBooked } from "../../../service/redux/middleware/session";
import ScreenLoader from "../../Loader/ScreenLoader";
import CalendarEaxct from "../../../assets/CalendarEaxct.svg";
import { current } from "@reduxjs/toolkit";
import { mySession } from "../../../service/redux/middleware/session";

const formatDate = (date) => {
  var inputDate = new Date(date);
  var year = inputDate.getFullYear();
  var month = inputDate.getMonth() + 1;
  var day = inputDate.getDate();
  var formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
    }${day}`;
  return formattedDate;
};

const timeHalfHour = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  // "23:00",
  // "23:30",
  // "00:00",
];
const RescheduleSession = ({
  setRescheduleProfileElem,
  singleData,
  setMySessionDataTemp,
}) => {
  function timeconversionTime(time) {
    const formattedStartDate = moment(time).format("H:mm");
    return formattedStartDate;
  }
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [changeColor, setChangeColor] = useState();
  const [showDelete,setShowDelete]=useState(false)
  const [showDeleteData,setShowDeleteData]=useState()

  const [hourStartTime, setHourStartTime] = useState("");
  const [timeZoneValue, setTimeZoneValue] = useState("");
  const [timeZone, setTimeZone] = useState(singleData?.slot?.timezone);
  const [timeZoneArray, setTimeZoneArray] = useState(getTimeZones());
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const searchRef = useRef(null);
  const [openDateCalender, setOenDateCalender] = useState(false)



  console.log('singleData', singleData);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BacktoMysession = () => {
    setRescheduleProfileElem(false);
  };
  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];

      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };

  function getTimeZones() {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset() / 60;
      const offsetString = offset >= 0 ? `+${offset}` : offset;
      const timeZoneName = moment.tz(zone).zoneAbbr();
      return {
        value: zone,
        label: `${zone}, ${timeZoneName} (GMT ${offsetString})`,
      };
    });
  }
  const timeZones = getTimeZones();

  const [zoneValue, setZoneValue] = useState("");
  const selectedValue = (elem) => {
    const parts = elem?.slot?.timeZoneValue.split(", ");
    const timezone = parts[0].trim();
    const origin = moment.tz(timezone);
    let s1 = origin.format("HH");
    let s2 = origin.format("mm");
    if (s1 < 22) {
      if (s2 <= 30) {
        s2 = "30";
      } else {
        s2 = "00";
        s1 = Number(s1) + 1;
        if (s1 < 10) {
          s1 = "0" + s1;
        }
      }
    }
    let ss = s1 + ":" + s2;

    setZoneValue(ss);
    if (elem?.slot?.date) {
      setTimeSlot((prevState) => ({
        ...prevState,
        dateSend: elem?.slot?.date,
      }));
    }
    let convert = "";
    if (elem?.slot?.startDateTime) {
      let a = timeconversionTime(elem?.slot?.startDateTime);
      convert = timeParser(a);
    }
    const initialOption = timeHalfHour.find((item) => item == convert);
    if (initialOption) {
      setHourStartTime(initialOption);
      setTimeSlot((prevState) => ({
        ...prevState,
        startTime: initialOption,
        originTime: ss,
        timeZoneValue: elem?.slot?.timeZoneValue,
        endTime: addAndCheckTime(initialOption),
      }));
    }
    const timeZoneValue = timeZones.find(
      (item) => item.label == singleData?.slot?.timeZoneValue
    );
    if (timeZoneValue) {
      setTimeZoneValue(timeZoneValue?.label);
    }
  };

  useEffect(() => {
    selectedValue(singleData);
  }, [singleData]);

  function getEndTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setHours(currentTime.getHours() + 1);
    currentTime.setMinutes(currentTime.getMinutes() + 60);
    const updatedHours = currentTime.getHours().toString().padStart(2, "0");
    const updatedMinutes = currentTime.getMinutes().toString().padStart(2, "0");
    return `${updatedHours}:${updatedMinutes}`;
  }

  const handleSelectTimeZone = (selected) => {
    if (selected !== "default") {
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === selected
      );
      const selectedTimeZone = matchedTimeZone.value;
      const selectedLabel = matchedTimeZone.label;
      const origin = moment.tz(selectedTimeZone);
      setTimeZoneValue(matchedTimeZone.value);
      let s1 = origin.format("HH");
      let s2 = origin.format("mm");
      if (s1 < 22) {
        if (s2 <= 30) {
          s2 = "30";
        } else {
          s2 = "00";
          s1 = Number(s1) + 1;
          if (s1 < 10) {
            s1 = "0" + s1;
          }
        }
      }
      let ss = s1 + ":" + s2;
      let date11 = origin?.format("MMM DD, yyyy");

      if (ss <= "22:30") {
        console.log("if sufiiiiiiiii");
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const initialOption1 = timeHalfHour.find((item) => item == ss);
        if (initialOption1) {
          const updatedTimeSlot = {
            ...timeSlot,
            startTime: initialOption1,
            date: moment(origin).format("YYYY-MM-DD"),
            dateSend: date11,
            timezone: offsetMinutes,
            timeZoneValue: selectedLabel,
            originTime: initialOption1,
            endTime: getEndTime(initialOption1),
          };
          setHourStartTime(initialOption1);
          setTimeSlot(updatedTimeSlot);
        }
      } else {
        console.log("else sufiiiiiiiii");
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const updatedTimeSlot = {
          ...timeSlot,
          startTime: "00:00",
          timezone: offsetMinutes,
          timeZoneValue: selectedLabel,
          originTime: "00:00",
          endTime: getEndTime("00:00"),
        };
        setHourStartTime("00:00");
        setTimeSlot(updatedTimeSlot);
      }
    }
  };
  const dispatch = useDispatch();

  const formValidation = () => {
    if (timeZone === null || timeZone === undefined) {
      ErrorToast("Please select your timezone");
      return false;
    }
    let allGood3 = true;
    if (timeSlot?.startTime !== "") {
    } else {
      ErrorToast("Plese Select Date and Time");
      allGood3 = false;
    }

    if (allGood3) {
    } else {
      return false;
    }
  };
  const [loader, setLoader] = useState(false);
  const data = {
    userId: userData?._id,
    token: token,
  };
  const reScheduleSession = () => {
    let send = timeSlot?.dateSend;
    if (timeSlot?.dateSend === singleData?.slot?.date) {
    } else {
      send = formatDate(timeSlot?.dateSend);
      if (singleData?.slot?.date === timeSlot?.dateSend) {
        send = timeSlot?.date;
      } else {
        send = formatDate(timeSlot?.dateSend);
      }
    }
    const data1 = {
      timezone: timeZone,
      date: send,
      startTime: timeSlot?.startTime,
      timeZoneValue: timeSlot?.timeZoneValue,
      endTime: timeSlot?.endTime,
      meetingId: singleData?.slot?._id,
      creatorId: singleData?.creatorId,
      userId: userData?._id,
      token: token,
      confirm:false,
    };
    setLoader(true);
    dispatch(reScheduleForMySession(data1)).then((res) => {

      console.log("this is the response",res)
      if (res?.payload?.status === 200) {
        SuccessToast(res?.payload?.data?.message);
        dispatch(mySession(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            let meetings = res?.payload?.data
            meetings.sort((a, b) => new Date(a.slot.startDateTime) - new Date(b.slot.startDateTime));
            setMySessionDataTemp(meetings);
            console.log(meetings)
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
        setShowConfirmModal(false);
        setLoader(false);
      }else if(res?.payload?.status === 201) {
        setLoader(false);
        setShowDelete(true)
        setShowConfirmModal(false)
        setShowDeleteData(res?.payload?.data?.data)

      }
      
      else {
        ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };
  const confitmReScheduleSession = () => {
    let send = timeSlot?.dateSend;
    if (timeSlot?.dateSend === singleData?.slot?.date) {
    } else {
      send = formatDate(timeSlot?.dateSend);
      if (singleData?.slot?.date === timeSlot?.dateSend) {
        send = timeSlot?.date;
      } else {
        send = formatDate(timeSlot?.dateSend);
      }
    }
    const data1 = {
      timezone: timeZone,
      date: send,
      startTime: timeSlot?.startTime,
      timeZoneValue: timeSlot?.timeZoneValue,
      endTime: timeSlot?.endTime,
      meetingId: singleData?.slot?._id,
      creatorId: singleData?.creatorId,
      userId: userData?._id,
      token: token,
      conflictingId:showDeleteData?._id,
      
    };
    setLoader(true);
    dispatch(reScheduleForMySessionConfirm(data1)).then((res) => {

      console.log("this is the response",res)
      if (res?.payload?.status === 200) {
        SuccessToast(res?.payload?.data?.message);
        dispatch(mySession(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setShowDelete()
            let meetings = res?.payload?.data
            meetings.sort((a, b) => new Date(a.slot.startDateTime) - new Date(b.slot.startDateTime));
            setMySessionDataTemp(meetings);
            console.log(meetings)
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
        setShowConfirmModal(false);
        setLoader(false);
      }else if(res?.payload?.status === 201) {
        setLoader(false);
        setShowDelete(true)
        setShowConfirmModal(false)
        setShowDeleteData(res?.payload?.data?.data)

      }
      
      else {
        ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };
  const cancelConfirmation = () => {
    setShowDelete(false);
  };
  const SubmitBookSesssion = () => {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setShowConfirmModal(true);
  };

  const [timeSlot, setTimeSlot] = useState({
    timezone: timeZone,
    timeZoneValue: timeZoneValue,
    date: moment(new Date()).format("YYYY-MM-DD"),
    dateSend: new Date(),
    startTime: hourStartTime,
    endTime: "",
    originTime: "",
  });
  const handleSelectStartTime = (selected) => {
    const updatedTimeSlot = { ...timeSlot };
    updatedTimeSlot["startTime"] = selected;
    updatedTimeSlot["endTime"] = getEndTime(selected);
    setTimeSlot(updatedTimeSlot);
  };
  const handleSelectEndTime = (selected) => {
    const updatedTimeSlot = { ...timeSlot };
    if (updatedTimeSlot.startTime === "") {
      ErrorToast("Please select start time first.");
    } else {
      updatedTimeSlot["endTime"] = selected;
      setTimeSlot(updatedTimeSlot);
    }
  };
  const handleChangeForDateAndTime = (e, isDate = "") => {
    const updatedTimeSlot = { ...timeSlot };
    if (isDate.length > 0) {
      let isToday = false;
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      if (timeZoneValue === userTimeZone) {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["startTime"] = hourStartTime;
          updatedTimeSlot["endTime"] = getEndTime(hourStartTime);
          updatedTimeSlot["originTime"] = zoneValue;
          setTimeSlot(updatedTimeSlot);
        } else {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["endTime"] = "";
          updatedTimeSlot["startTime"] = "";
          updatedTimeSlot["originTime"] = "";
          setTimeSlot(updatedTimeSlot);
        }
      } else {
        const now = moment.tz(timeZoneValue);
        const timeZoness = now.format("YYYY-MM-DD HH:mm:ss");
        const todayDate = moment
          .utc(timeZoness)
          .startOf("day")
          .format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["startTime"] = hourStartTime;
          updatedTimeSlot["endTime"] = getEndTime(hourStartTime);
          updatedTimeSlot["originTime"] = zoneValue;
          setTimeSlot(updatedTimeSlot);
        } else {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["endTime"] = "";
          updatedTimeSlot["startTime"] = "";
          updatedTimeSlot["originTime"] = "";
          setTimeSlot(updatedTimeSlot);
        }
      }
    } else if (e.target.name === "startTime") {
      updatedTimeSlot[e.target.name] = e.target.value;
      updatedTimeSlot["endTime"] = getEndTime(e.target.value);
    } else if (e.target.name === "endTime") {
      if (updatedTimeSlot.startTime === "") {
        ErrorToast("Please select start time first.");
      } else {
        updatedTimeSlot[e.target.name] = e.target.value;
      }
    } else {
      updatedTimeSlot[e.target.name] = e.target.value;
    }
    setTimeSlot(updatedTimeSlot);
  };
  function addAndCheckTime(timeStr) {
    const currentTime = new Date();
    const [hours, minutes] = timeStr?.split(":").map(Number);
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59);
    const timeDifference = endOfDay - currentTime;
    let endDF = timeDifference / 60 / 60 / 1000;
    if (endDF === 1.4997222222222222) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 29);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else if (timeDifference > 1.5 * 60 * 60 * 1000) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else {
      return "25:00";
    }
  }
 
  const [searchedTimezone, setSearchedTimezone] = useState("");
  const searchTimeZonesValue = (e) => {
    setSearchedTimezone(e.target.value);
  };
  const searchTimezonesFromArray = () => {
    if (searchedTimezone === "") {
      setTimeZoneArray(timeZones);
    } else {
      let list = [...timeZones];
      list = list.filter((item) =>
        item.label.toLowerCase().includes(searchedTimezone.toLowerCase())
      );
      setTimeZoneArray(list);
    }
  };

  const handleDropdownToggleTimeZone = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    searchTimezonesFromArray();
  }, [searchedTimezone]);




  return (
    <>
      {loader && <ScreenLoader />}
      <div className="reschedule__session__container">
        <div className="reschedule__session__container__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={BacktoMysession}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back To My Sessions
            </p>
          </div>
        </div>
        <div className="reschedule__session__container__bottom">
          <Row className="reschedule__session__container__bottom__row">
            <Col
              style={{ width: "68%" }}
              md={6}
              className="reschedule__session__container__bottom__left"
            >
              <h2 className="reschedule__session__container__bottom__left__title">
                Reschedule Session
              </h2>
              <div className="reschedule__session__container__bottom__left__form">
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Timezone
                    </p>
                    <img
                      src={ClockIcon}
                      alt="clock icon"
                      className="reschedule__session__container__bottom__left__form__container__label__icon"
                    />
                  </label>
                  <Dropdown onSelect={handleSelectTimeZone} onToggle={handleDropdownToggleTimeZone}>
                    <Dropdown.Toggle
                      style={{ height: "48px" }}
                      id="dropdown-basic"
                      className="dropdownToSelect select__icon"
                    >
                      <p className="forumm__button__text">
                        {timeZoneValue
                          ? timeZoneValue
                          : "Please select timezone by city"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu timezone__relative"
                      style={{ padding: "0px" }}
                    >
                      <Form.Control
                        ref={searchRef}

                        type="text"
                        className="search__input search__timezone"
                        placeholder="Search timezone by city..."
                        name="timezone"
                        value={searchedTimezone}
                        onChange={(e) => searchTimeZonesValue(e)}
                      />
                      {timeZoneArray?.map((zone) => (
                        <Dropdown.Item
                          key={zone.value}
                          eventKey={zone.value}
                          className="dropdownToSelect__item"
                        >
                          {zone.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Date
                    </p>
                  </label>
                  <DateTimePicker
                    format="MMM dd, yyyy"
                    disableClock={true}
                    className="schedules__guides__contaiener__calendar calender__padding"
                    name="dateSend"
                    isCalendarOpen={openDateCalender ? true : false}
                    onClick={() => setOenDateCalender(!openDateCalender)}
                    placeholder="Select expiration date..."
                    calendarIcon={
                      <img
                        src={CalendarEaxct}
                        alt="Calendar Icon"
                        style={{ width: "20px", height: "20px" }} // Set the size as needed
                      />
                    }
                    onChange={(e) => handleChangeForDateAndTime(e, "dateSend")}
                    value={timeSlot?.dateSend}
                  />
                </div>

                <div className="reschedule__session__container__bottom__left__form__container__two">
                  <Dropdown
                    onSelect={(e) => handleSelectStartTime(e)}
                    style={{ width: "49%" }}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={`dropdownToSelect select__icon calender__padding ${timeZone == null ? "dropdownToSelect__maindisabled" : ""
                        }`}
                    >
                      <p className="forumm__button__text">
                        {timeSlot?.startTime
                          ? timeSlot.startTime
                          : "Select start time"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                      {timeHalfHour.map((hour) => (
                        <Dropdown.Item
                          className={`${hour < timeSlot?.originTime
                            ? " dropdownToSelect__disabled"
                            : timeSlot?.startTime === hour
                              ? "dropdownToSelect__item__checked"
                              : "dropdownToSelect__item"
                            }`}
                          eventKey={hour}
                          key={hour}
                          disabled={hour < timeSlot?.originTime}
                        >
                          {hour}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    onSelect={(e) => handleSelectEndTime(e)}
                    style={{ width: "49%" }}
                    show={false}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={`dropdownToSelect  ${timeZone == null ? "dropdownToSelect__maindisabled" : ""
                        }`}
                    >
                      <p className="forumm__button__text">
                        {timeSlot?.endTime
                          ? timeSlot?.endTime
                          : timeSlot?.startTime === ""
                            ? "End time"
                            : addAndCheckTime(timeSlot.startTime)}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                      {timeHalfHour.map((hour) => (
                        <Dropdown.Item
                          className={`${hour < timeSlot?.startTime
                            ? " dropdownToSelect__disabled"
                            : timeSlot?.endTime === hour
                              ? "dropdownToSelect__item__checked"
                              : "dropdownToSelect__item"
                            }`}
                          eventKey={hour}
                          key={hour}
                          disabled={hour <= hourStartTime}
                        >
                          {hour}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <p style={{ margin: "12px 0px 24px 0px " }} className="reschedule__session__container__bottom__left__subtitle">
                Each meeting slot is allocated a duration of 1.5 hours
              </p>

              <div className="reschedule__session__container__bottom__left__form margin-propose-32">
                <button className="btn-green" onClick={reScheduleSession}>
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <ConfirmModal show={showDelete} setShow={setShowDelete}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Conflicting Session</div>
          <div className="para__para__para ">
           This reschedule request conflicts with an already proposed session. <br/>Do you want to delete your previously proposed session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={confitmReScheduleSession}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={cancelConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>

      {/* <ConfirmModal show={showConfirmModal} setShow={setShowConfirmModal}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Reschedule Session</div>
          <div className="para__para__para ">
            Are you sure you want to go ahead and reschedule this session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={reScheduleSession}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={cancelConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal> */}
    </>
  );
};

export default RescheduleSession;
