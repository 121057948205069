// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginCheckbox {
  display: flex;
  margin-top: 28px;
  gap: 10px;
  color: var(--gray-gray-200, #b9bdc7);
  font-family: AktivGrotesk-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}
.loginCheckbox p {
  margin-bottom: 0px;
}
.loginCheckbox__span {
  color: #3dd7a1 !important;
  font-family: AktivGrotesk-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.form-check-input {
  background-color: #292b2e !important;
  border-color: #292b2e !important;
}

.not__cofirm {
  pointer-events: none;
  opacity: 0.5;
  display: none;
}

.flex__names__container {
  display: flex;
  gap: 20px;
}
.flex__names__container div {
  flex: 1 1;
}

.margin___last__name {
  margin-top: 32px !important;
  position: relative;
}

@media (max-width: 768px) {
  .flex__names__container {
    flex-direction: column;
    gap: 0;
  }
  .textmt {
    margin-top: 52px !important;
  }
  .margin___last__name {
    margin-top: 28px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Login/SignupEmail.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;EAEA,SAAA;EAMA,oCAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,WAAA;AARF;AAHE;EACE,kBAAA;AAKJ;AAME;EACE,yBAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;;AAQA;EACE,oCAAA;EACA,gCAAA;AALF;;AASA;EACE,oBAAA;EACA,YAAA;EACA,aAAA;AANF;;AAUA;EACA,aAAA;EACA,SAAA;AAPA;AAQA;EACE,SAAA;AANF;;AAUA;EACA,2BAAA;EACA,kBAAA;AAPA;;AAUA;EACE;IACE,sBAAA;IACA,MAAA;EAPF;EASA;IACE,2BAAA;EAPF;EASA;IACE,2BAAA;EAPF;AACF","sourcesContent":[".NameField {}\n\n.loginCheckbox {\n  display: flex;\n  margin-top: 28px;\n  // align-items: center;\n  gap: 10px;\n\n  p {\n    margin-bottom: 0px;\n  }\n\n  color: var(--gray-gray-200, #b9bdc7);\n  font-family: AktivGrotesk-Regular;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 140%;\n\n  /* 19.6px */\n  &__span {\n    color: #3dd7a1 !important;\n    font-family: AktivGrotesk-Regular;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 140%;\n  }\n}\n\n.form-check-input {\n  background-color: #292b2e !important;\n  border-color: #292b2e !important;\n}\n\n\n.not__cofirm{\n  pointer-events: none;\n  opacity: 0.5;\n  display: none;\n}\n\n\n.flex__names__container{\ndisplay: flex;\ngap: 20px;\ndiv{\n  flex: 1;\n}\n}\n\n.margin___last__name{\nmargin-top: 32px !important;\nposition: relative;\n}\n\n@media (max-width: 768px){\n  .flex__names__container{\n    flex-direction: column;\n    gap: 0;\n  }\n  .textmt{\n    margin-top: 52px !important;\n  }\n  .margin___last__name{\n    margin-top: 28px !important;\n    }\n\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
