import React from "react";
import "./Footer.scss";

//
import FooterLogo from "../../assets/8.svg";
const FooterForum = () => {
  return (
    <div className="footer__container">
      <div className="footer__container__inner">
        <img
          src={FooterLogo}
          alt="footer logo"
          className="footer__container__inner__image"
        />
        <p className="footer__container__inner__text">
          That’s all at the moment
        </p>
      </div>
    </div>
  );
};

export default FooterForum;
