// import { createRoot } from 'react-dom/client';
// // import './index.css';
// import * as React from 'react';
// import { useState, useRef, useEffect } from 'react';
// import { SliderComponent } from '@syncfusion/ej2-react-inputs';

// import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
// // import { PropertyPane } from './property-pane';
// import { isNullOrUndefined } from '@syncfusion/ej2-base';
// const slidercss = `
// .content-wrapper {
//     width: 80%;
//     margin: 0 auto;
//     min-width: 185px;
// }

// .sliderwrap {
//     height: 375px;
//     margin-top: 10px;
//     width: 60%;
//     margin: auto;
// }

// .e-bigger .content-wrapper {
//     width: 80%;
// }

// .sliderwrap .e-lbl {
//     display: block;
//     font-size: 11px;
//     font-weight: 500;
//     margin-top: 15px;
//     margin-left: -10px;
// }

// .sliderwrap:last-child .e-lbl {
//     margin-left: -2px;
// }

// .slider_table td {
//     text-align: center;
// }

// .slider_table {
//     border: 0;
//     width: 100%
// }
// `;
// const VerticalRange = () => {
//     const [tooltip, setTooltip] = useState({
//         isVisible: true,
//         placement: 'Before'
//     });
//     const [ticks, setTicks] = useState({
//         placement: 'Before',
//         largeStep: 20,
//         smallStep: 5,
//         showSmallTicks: true
//     });
//     //Instance of the components
//     let defaultObj = useRef(null);
//     let rangeObj = useRef(null);
//     let minRangeObj = useRef(null);
//     let reverseObj = useRef(null);
//     // Checkbox change handlers
//     const enableDisableTicks = (args) => {
//         setTicks({ ...ticks, placement: args.checked ? 'Before' : 'None' });
//     };
//     const enableDisableTooltip = (args) => {
//         setTooltip({ ...tooltip, isVisible: args.checked });
//     };
//     const refreshTooltip = (e) => {
//         if (rangeObj.current && defaultObj.current && minRangeObj.current && reverseObj.current) {
//             defaultObj.current.refreshTooltip(defaultObj.tooltipTarget);
//             minRangeObj.current.refreshTooltip(minRangeObj.tooltipTarget);
//             rangeObj.current.refreshTooltip(rangeObj.tooltipTarget);
//             reverseObj.current.refreshTooltip(reverseObj.tooltipTarget);
//         }
//     };
//     if (!isNullOrUndefined(document.getElementById('right-pane'))) {
//         document.getElementById('right-pane').addEventListener('scroll', refreshTooltip.bind(this));
//     }
//     return (<div className='control-pane' style={{ overflow: 'hidden' }}>
//             <style>{slidercss}</style>
//             <div className='control-section'>
//                 <div className='col-lg-8'>
//                     <div className="content-wrapper">
//                         <table className="slider_table">
//                             <tbody>
//                                 <tr>
//                                     <td>
//                                         <div className="sliderwrap">
//                                             {/* Initialize Slider component with Vertical orientation */}
//                                             <SliderComponent id={"slider01"} value={30} orientation='Vertical' ticks={ticks} tooltip={tooltip} ref={defaultObj}/>
//                                         </div>
//                                     </td>
//                                     <td>
//                                         <div className="sliderwrap">
//                                             {/* Initialize  Slider component with type MinRange and Vertical orientation */}
//                                             <SliderComponent id={"slider02"} value={30} type='MinRange' orientation='Vertical' ticks={ticks} tooltip={tooltip} ref={minRangeObj}/>
//                                         </div>
//                                     </td>
//                                     <td>
//                                         <div className="sliderwrap">
//                                             {/* Initialize Range Slider component with type Range and Vertical orientation */}
//                                             <SliderComponent id={"slider03"} value={[30, 70]} type='Range' orientation='Vertical' ticks={ticks} tooltip={tooltip} ref={rangeObj}/>
//                                         </div>
//                                     </td>
//                                     <td>
//                                         <div className="sliderwrap">
//                                             {/* Initialize Range Slider component with type Range and Vertical orientation */}
//                                             <SliderComponent id={"slider04"} value={[30, 70]} min={100} max={0} type='Range' orientation='Vertical' ticks={ticks} tooltip={tooltip} ref={reverseObj}/>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//                 <div id="slider_event" className="col-lg-4 property-section">
//                     <PropertyPane title='Properties'>
//                         <table id="property" title="Properties" className='property-panel-table' style={{ width: '100%' }}>
//                             <tbody>
//                                 <tr>
//                                     <td style={{ width: '50%', paddingTop: '10px' }}>
//                                         <div className="userselect">Ticks</div>
//                                     </td>
//                                     <td style={{ width: '50%', paddingRight: '10px' }}>
//                                         <div>
//                                             {/* Initialize Range CheckBox component */}
//                                             <CheckBoxComponent checked={true} change={enableDisableTicks.bind(this)}/>
//                                         </div>
//                                     </td>
//                                 </tr>
//                                 <tr>
//                                     <td style={{ width: '50%', paddingTop: '10px' }}>
//                                         <div className="userselect">Tooltip</div>
//                                     </td>
//                                     <td style={{ width: '50%', paddingRight: '10px' }}>
//                                         <div style={{ paddingLeft: 0, paddingTop: 0 }}>
//                                             {/* Initialize Range CheckBox component */}
//                                             <CheckBoxComponent checked={true} change={enableDisableTooltip.bind(this)}/>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </PropertyPane>
//                 </div>
//             </div>
//         </div>);
// };
// export default VerticalRange;


// export const PropertyPane = (props) => {
//     return (
//         <div className='property-panel-section'>
//             <div className="property-panel-header">
//                 {props.title}
//             </div>
//             <div className="property-panel-content">
//                 {props.children}
//             </div>
//         </div>
//     );
// };


// import React, { useState,useEffect } from "react";
// import ReactSlider from "react-slider";

// const dates = ["Oct 1", "Oct 10", "Oct 20"];
// const totalDates = dates.length;

// const VerticalRange = () => {
//     const [selectedIndex, setSelectedIndex] = useState(Math.floor(totalDates / 2))
//     const [sliderValue, setSliderValue] = useState(selectedIndex * (100 / (totalDates - 1)));

//     useEffect(() => {
//       setSliderValue(selectedIndex * (100 / (totalDates - 1)));
//     }, [selectedIndex]);

//     const handleSliderChange = (newValues) => {
//       const index = Math.round((newValues[1] / 100) * (totalDates - 1));
//       setSelectedIndex(index);
//     };
//     return (
//         <>
//             <div className="vertical-slider-container">
//                 <div className="date-list">
//                     {dates.map((date, index) => (
//                         <div key={index} className={index === selectedIndex ? "selected-date" : ""}>
//                             {date}
//                         </div>
//                     ))}
//                 </div>
//                 <ReactSlider
//                     className="vertical-slider"
//                     thumbClassName="example-thumb"
//                     trackClassName="example-track"
//                     defaultValue={[0, sliderValue, 100]}
//                     ariaLabel={["Lowest thumb", "Middle thumb", "Top thumb"]}
//                     renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
//                     orientation="vertical"
//                     invert
//                     pearling
//                     minDistance={10}
//                     onChange={handleSliderChange}
//                 />
//                 <div className="red-line"></div>
//             </div>
//         </>
//     );
// };
// export default VerticalRange;


// import React, { useState } from "react";

// const VerticalRange = () => {
//     const dates = ["Date 1", "Date 2", "Date 3", "Date 4", "Date 5"];
//   const [selectedDate, setSelectedDate] = useState(dates[0]);

//   const handleSlide = (event) => {
//     const selectedIndex = Math.floor(
//       event.target.scrollTop / (event.target.scrollHeight / dates.length)
//     );
//     setSelectedDate(dates[selectedIndex]);
//   };

//   return (
//     <div className="vertical-slider" onScroll={handleSlide}>
//       {dates.map((date, index) => (
//         <div
//           className={`slider-item ${date === selectedDate ? "selected" : ""}`}
//           key={index}
//         >
//           {date}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default VerticalRange;


// import React, { useState } from "react";

// const VerticalRange = () => {
//     const dates = ["Date 1", "Date 2", "Date 3", "Date 4", "Date 5"];
//     const [selectedDate, setSelectedDate] = useState(dates[0]);

//   const handleDateClick = (date) => {
//     setSelectedDate(date);
//     const dateIndex = dates.indexOf(date);
//     const line = document.getElementById("slider-line");
//     const itemHeight = line.clientHeight / dates.length;
//     line.scrollTo(0, dateIndex * itemHeight);
//   };

//   return (
//         <div className="slider-container">
//           <div className="slider-line" id="slider-line">
//             {dates.map((date, index) => (
//               <div className="line-item" key={index}></div>
//             ))}
//           </div>
//           <div className="circle-slider"></div>
//           <div className="dates-container">
//             {dates.map((date, index) => (
//               <div
//                 key={index}
//                 className={`date ${date === selectedDate ? "selected" : ""}`}
//                 onClick={() => handleDateClick(date)}
//               >
//                 {date}
//               </div>
//             ))}
//           </div>
//         </div>
//   );
// };

// export default VerticalRange;



// const VerticalRange = () => {
//     const timelineData = [
//         {
//             id: "001",
//             startDate: "11/2010",
//             endDate: "Present",
//             title: "Title, Company",
//             subtitle: "Subtitle",
//             content: "Your content here..."
//         },
//         {
//             id: "002",
//             startDate: "04/2009",
//             endDate: "11/2010",
//             title: "Title, Company",
//             subtitle: "Subtitle",
//             content: "Your content here..."
//         },
//         {
//             id: "003",
//             startDate: "11/2008",
//             endDate: "04/2009",
//             title: "Title, Company",
//             subtitle: "Subtitle",
//             content: "Your content here..."
//         },
//         {
//             id: "004",
//             startDate: "08/2008",
//             endDate: "11/2008",
//             title: "Title, Company",
//             subtitle: "Subtitle",
//             content: "Your content here..."
//         }
//     ];

//     return (

//         <Timeline lineColor={'#ddd'}>
//             {timelineData.map(item => (
//                 <TimelineItem
//                     key={item.id}
//                     dateText={`${item.startDate} – ${item.endDate}`}
//                     style={{ color: '#e86971'}}
//                 >
//                     {/* <div style={{ textAlign: 'right' }}>
//                         <h3>{item.title}</h3>
//                         <h4>{item.subtitle}</h4>
//                         <p>{item.content}</p>
//                     </div> */}
//                 </TimelineItem>
//             ))}
//         </Timeline>

//     )
// }
// export default VerticalRange



import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const dates = ['2023-01-01', '2023-02-01', '2023-03-01', '2023-04-01', '2023-05-01'];

const reversedDates = [...dates].reverse();

const VerticalRange = () => {
    const [selectedDate, setSelectedDate] = useState(dates[0]);

    const handleChange = (event, newValue) => {
        setSelectedDate(dates[newValue]);
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '20px',
        },
        sliderContainer: {
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        slider: {
            color: '#2F3033'
        },
    };
    const GreenSlider = withStyles({
        thumb: {
            color: '#4CAF50', // Green color for the slider thumb
        },
        track: {
            color: 'white', // White color for the active track
        },
        rail: {
            color: 'white', // White color for the non-active track
        },
    })(Slider);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px' }}>
            <div style={{ width: '70%', marginRight: '20px' }}>
                {/* Your post and comments component */}
                <div>
                    {/* Display post and comments here */}
                </div>
            </div>
            <div style={{ height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <GreenSlider
                    orientation="vertical"
                    value={dates.indexOf(selectedDate)}
                    onChange={handleChange}
                    defaultValue={0}
                    aria-labelledby="vertical-slider"
                    marks={reversedDates.map((date, index) => ({ value: index, label: date }))}
                    step={1}
                    min={0}
                    max={dates.length - 1}
                />
            </div>
        </div>
    );
};

export default VerticalRange;
