import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import NavLogo from "../../assets/8.svg";
import Notification from "../../assets/notification.svg";
import Profile from "../../assets/Avatar.png";
import Search from "../../assets/search.svg";
import MovieIcon from "../../assets/video.svg";
import StarGreen from "../../assets/star-green.svg";
import MenuIcon from "../../assets/menu.svg";
import LogoutIcon from "../../assets/logout-icon.svg";
import coin from "../../assets/coin.svg";

import TeacherIcon from "../../assets/teacher-icon.svg";
import ProfileIcon from "../../assets/profile-icon.svg";
import NotificationNone from "../../assets/notification-none.png";
import "./navbar.scss";
import { useHistory } from "react-router-dom";
import { getUserImage } from "../../service/redux/middleware/userProfile";
//
import { socket } from "../../socket";
import { ErrorToast, SuccessToast } from "../MyToaster";
import notiSound from "../../assets/audio/notification_sound.mp3";
//
import ArrowRight from "../../assets/arrow-right-navbar.svg";
import EmptyNotifications from "../../assets/empty_notifications.svg";
import ArrowLeft from "../../assets/arrow-left-navbar.svg";
import ArrowLeftFull from "../../assets/arrow-left.svg";
import CancelNavbar from "../../assets/cancel-navbar.svg";
//
import { io } from "socket.io-client";
import axios from "axios";
import { API_URL } from "../../service/client";
import moment from "moment";
//
import LinkedIn from "../../assets/linkdinIcon.svg";
import FacebookIcon from "../../assets/facebook.svg";
import TwitterIcon from "../../assets/twitter.svg";
import MailIcon from "../../assets/messageIcon.svg";
import AvatarIcon from "../../assets/avatar.svg";
import { storeLogout } from "../../service/redux/middleware/signin";
import OutsideClickHandler from "react-outside-click-handler";
import { viewMentor } from "../../service/redux/middleware/mentor";

export default function NavBar() {
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isJoinUS, setIsJoinUs] = useState(false);
  const [open, setOpen] = useState(false);
  const [mentorData, setMentorData] = useState([]);
  const [isMobileNotificationActive, setIsMobileNotificationActive] =
    useState(false);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const userData = useSelector((state) => state.signin.signInData.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  //
  let audio = new Audio(notiSound);
  //
  const data1 = [];
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(storeLogout(data1)).then((res) => { });
    localStorage.clear();
    history.push("/login");
  };

  const GoToMentor = () => {
    history.push("/mentor");
  };
  const GoToAccount = () => {
    if(userData?.role==0){
      history.push("/profilesettings");

    }
    else{
      history.push("/mentorSetting");
    }
  };
 

  const history = useHistory();
  const [image, setImage] = useState("");
  const data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    dispatch(getUserImage(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setImage(res?.payload?.data);
      }
    });
    setLoader(true);
    dispatch(viewMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMentorData(res?.payload?.data);
        // checkingIfFullyBooked(res?.payload.data);
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });
  }, []);
  //
  //
  const convertingToLocal = (data) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(data);
    const localTime = utcDate?.toLocaleString(undefined, {
      timeZone: userTimeZone,
    });
    return localTime;
  };
  //
  //
  // Socekts
  //
  //
  const getNotifications = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/notification/${userData?._id}`
      );
      if (response.status === 200) {
        setNotifications(response?.data?.reverse());
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // notification for normal user start
  const getProposedNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getBookNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getMeetingCancelNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getBuyNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getBookedByMeNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getMeetingStartingSoonNotifications = () => {
    audio.play();
    getNotifications();
  };
  const getMeetingStartingSoonNetworkNotifications = () => {
    audio.play();
    getNotifications();
  };

  // notification for normal user end

  // notification for mentor start

  const getProposedNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getBookNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getMeetingSoonNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getBookedByMentor = () => {
    audio.play();
    getNotifications();
  };

  // notification for mentor end

  useEffect(() => {
    getNotifications();
    socket.on("connect", () => {
      // audio.play();
      console.log("socket is connecting")
      socket.emit("join", userData?._id);
    });
    if (userData?.role == 0) {
      if (userData?._id) {
        socket.on("propose_sesssion", getProposedNotifications);
        socket.on("meeting-booked", getBookNotifications);
        socket.on("meeting-cancel", getMeetingCancelNotifications);
        socket.on("buy-credit", getBuyNotifications);
        socket.on("meeting-bookedbyme", getBookedByMeNotifications);
        socket.on(
          "interview_meeting_soon_creator",
          getMeetingStartingSoonNotifications
        );
        socket.on(
          "network_meeting_soon_bookedBy",
          getMeetingStartingSoonNetworkNotifications
        );
      }
      socket.on("disconnect", (reason) => { });
      return () => {
        if (userData?._id) {
          socket.off("propose_sesssion", getProposedNotifications);
          socket.off("meeting-booked", getBookNotifications);
          socket.off("meeting-cancel", getMeetingCancelNotifications);
          socket.off("buy-credit", getBuyNotifications);
          socket.off("meeting-bookedbyme", getBookedByMeNotifications);
          socket.off(
            "interview_meeting_soon_creator",
            getMeetingStartingSoonNotifications
          );
          socket.off(
            "network_meeting_soon_bookedBy",
            getMeetingStartingSoonNetworkNotifications
          );
        }
        socket.off("connect", (reason) => { });
        socket.off("disconnect", (reason) => { });
      };
    }
    else {
      if (userData?._id) {
        socket.on("mentor_propose_sesssion", getProposedNotificationsOfMentor);
        socket.on("mentor_booked_by", getBookNotificationsOfMentor);
        socket.on("network_meeting_soon_creator", getMeetingSoonNotificationsOfMentor);
        socket.on("booked_mentor", getBookedByMentor);
        // socket.on("buy-credit", getBuyNotifications);
        // socket.on("meeting-bookedbyme", getBookedByMeNotifications);
      }
      socket.on("disconnect", (reason) => { });
      return () => {
        if (userData?._id) {
          socket.off("propose_sesssion", getProposedNotificationsOfMentor);
          socket.off("meeting-booked", getBookNotificationsOfMentor);
          socket.off("network_meeting_soon_creator", getMeetingSoonNotificationsOfMentor);
          socket.off("booked_mentor", getBookedByMentor);
        }
        socket.off("connect", (reason) => { });
        socket.off("disconnect", (reason) => { });
      };
    }
  }, []);

  const BuyCredits = () => {
    history.push("/buycredits");
  };

  const goToRoute = async (extractedActionVerb) => {
    console.log('extractedActionVerb', extractedActionVerb);
    try {
      if (userData?.role == 0) {
        if (extractedActionVerb?.read === false) {
          const response = await axios.post(
            `${API_URL}/api/readNotification/${extractedActionVerb?._id}`
          );
          if (response.status === 200) {
            if (extractedActionVerb?.purpose === "cancelPropose") {
              history.push("/interview/1");
            } else if (extractedActionVerb?.purpose === "Meeting Booked") {
              history.push("/interview/1");
            } else if (extractedActionVerb?.purpose === "Meeting Proposal") {
              history.push("/interview/0");
            } else if (extractedActionVerb?.purpose === "credits") {
              history.push("/interview");
            }
            else if (extractedActionVerb?.purpose === "Mentor Booked Meeting") {
              history.push("/network/1");
            }
            else if (extractedActionVerb?.purpose === "cancelMentor") {
              history.push("/network/1");
            }
            else if (extractedActionVerb?.purpose === "cancelmeetingbyMentor") {
              history.push("/network/1");
            }
            else {
              history.push("/interview");
            }
          } else {
          }
        } else {
          if (extractedActionVerb?.purpose === "cancelPropose") {
            history.push("/interview/1");
          } else if (extractedActionVerb?.purpose === "Meeting Booked") {
            history.push("/interview/1");
          } else if (extractedActionVerb?.purpose === "Meeting Proposal") {
            history.push("/interview/0");
          } else if (extractedActionVerb?.purpose === "credits") {
            history.push("/interview");
          } else if (extractedActionVerb?.purpose === "Mentor Booked Meeting") {
            history.push("/network/1");
          }
          else if (extractedActionVerb?.purpose === "cancelMentor") {
            history.push("/network/1");
          }
          else if (extractedActionVerb?.purpose === "cancelmeetingbyMentor") {
            history.push("/network/1");
          }
          else {
            history.push("/interview");
          }
        }
      }
      else {
        // alert('notification of mentor')
        if (extractedActionVerb?.read === false) {
          const response = await axios.post(`${API_URL}/api/readNotification/${extractedActionVerb?._id}`);
          if (response.status === 200) {
            if (extractedActionVerb?.purpose === "Mentor Meeting Proposal") {
              history.push("/mentordashboard/0");
            }
            else if (extractedActionVerb?.purpose === "Mentor Booked Meeting") {
              history.push("/mentordashboard/1");
            }
            else if (extractedActionVerb?.purpose === "cancelmeetingbyMentorMe") {
              history.push("/mentordashboard/1");
            }
            else {
              history.push("/mentordashboard");
            }
          }
        } else {
          if (extractedActionVerb?.purpose === "Mentor Meeting Proposal") {
            history.push("/mentordashboard/0");
          }
          else if (extractedActionVerb?.purpose === "Mentor Booked Meeting") {
            history.push("/mentordashboard/1");
          }
          else if (extractedActionVerb?.purpose === "cancelmeetingbyMentorMe") {
            history.push("/mentordashboard/1");
          }

          else {
            history.push("/mentordashboard");
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }

    // if (extractedActionVerb?.purpose === "cancel") {
    //   history.push("/interview/1");
    // } else if (extractedActionVerb?.purpose === "Meeting Booked") {
    //   history.push("/interview/1");
    // } else if (extractedActionVerb?.purpose === "Meeting Proposal") {
    //   history.push("/interview/propose");
    // } else if (extractedActionVerb?.purpose === "credits") {
    //   history.push("/interview");
    // } else {
    //   history.push("/error");
    // }
  };

  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      // Convert the stored string back to a boolean
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);

  const switchToMentor = () => {
    localStorage.removeItem("mentorFlag");
    history.push("/mentordashboard");
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsMobileActive(false);
        setIsMobileNotificationActive(false);
        setOpen(false);
      }}
    >
      <div className="Wrapper ">
        <div className="container__custom navbar__container">
          <div className="Nav__Brand ms-4 mt-4 mb-4">
            <a href="https://www.fast-track.community/" className="nav__logo">
              <img src={NavLogo} alt="Logo" />
            </a>
          </div>
          <ul className=" nav__Right__navigations">
            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/"
                className={`nav__Right__navigations__item__link ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                Home
              </a>
            </li>

            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/resources"
                className={`nav__Right__navigations__item__link`}
              >
                Study
              </a>
            </li>

            <li className="nav__Right__navigations__item">
              <Link
                to={userData?.role === 0 ? "/interviewdashboard" : "/interview"}
                className={`nav__Right__navigations__item__link ${location.pathname === "/interview" ||
                  location.pathname === "/interviewdashboard"  ||  location.pathname === "/mysession" ||  location.pathname === "/interviewguidinterview" ||  location.pathname === "/perfoemanceInterview"
                  
                  ? "active"
                  : ""
                  }`}
              >
                Interview
              </Link>
            </li>
            <li className="nav__Right__navigations__item">
              <Link
                to={userData?.role === 0 ? "/networkDashboard" : "/network"}
                className={`nav__Right__navigations__item__link ${location.pathname === "/network" ||
                  location.pathname === "/networkDashboard" ||  location.pathname === "/networkperformance"|| location.pathname === "/networkinterviewguid" ||  location.pathname === "/networkmysession" 
                  ? "active"
                  : ""
                  }`}
              >
                Network
              </Link>
            </li>
            <li className="nav__Right__navigations__item">
              <Link
                to="/forum"
                className={`nav__Right__navigations__item__link ${location.pathname === "/forum" ? "active" : ""
                  }`}
              >
                Forum
              </Link>
            </li>

            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/contact"
                className={`nav__Right__navigations__item__link`}
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="nav_Right__container">
            {userData?._id && isMentor === false ? (
              <div className="nav__Right">
                {/* <img src={Search} alt="Logo" /> */}
                <Dropdown className="navbar__notification">
                  <Dropdown.Toggle className="navbar__notification__dropdown">
                    <img
                      src={
                        notifications?.every((session) => session.read)
                          ? NotificationNone
                          : Notification
                      }
                      alt="Logo"
                      className=""
                      onClick={getNotifications}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      background: "#202224",
                      color: "white",
                      padding: "0px",
                    }}
                    className=""
                  >
                    <h2 className="navbar__notification__dropdown__title">
                      Notifications
                    </h2>
                    <div
                      style={{ height: "500px" }}
                      className={`navbar__notification__dropdown__main
                     ${notifications?.length > 0 ? "" : "mentor__bot"}`}
                    >
                      {notifications?.length > 0 ? (
                        notifications?.map((notification) => {
                          return (
                            <Dropdown.Item
                              onClick={() => goToRoute(notification)}
                              style={{ color: "white" }}
                              // className="navbar__notification__dropdown__container navbar__notification__item__green"
                              className={`navbar__notification__dropdown__container ${notification?.read === false
                                ? "navbar__notification__item__green"
                                : ""
                                }`}
                            >
                              <div className="navbar__notification__dropdown__container__inner">
                                <img
                                  src={
                                    notification?.purpose === "credits"
                                      ? StarGreen
                                      : MovieIcon
                                  }
                                  className="navbar__notification__dropdown__container__inner__image"
                                />
                                <div>
                                  <p className="navbar__notification__dropdown__container__inner__message">
                                    {notification.massage}
                                  </p>
                                  {/* <p className="navbar__notification__dropdown__container__inner__type">
                                    {notification.purpose !== "credits"
                                      ? "View my sessions"
                                      : ""}
                                  </p> */}
                                </div>
                                <p className="navbar__notification__dropdown__container__inner__time">
                                  {moment(notification?.createdAt).fromNow()}
                                </p>
                              </div>
                              {notification?.read === false && (
                                <div>
                                  <div className="dot-green" />
                                </div>
                              )}
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <div
                          className="no__notifications"
                          style={{ height: "500px" }}
                        >
                          <div className="no__notifications__container">
                            <img
                              src={EmptyNotifications}
                              className="no__notifications__container__image"
                            />
                            <h2 className="no__notifications__container__text">
                              No Notifications Found
                            </h2>
                          </div>
                        </div>
                      )}
                      <>
                        {/* 
                      <Dropdown.Item
                        style={{ color: "white" }}
                        href=""
                        className="navbar__notification__dropdown__container navbar__notification__item__green"
                      >
                        <div className="navbar__notification__dropdown__container__inner">
                          <img
                            src={MovieIcon}
                            className="navbar__notification__dropdown__container__inner__image"
                          />
                          <div>
                            <p className="navbar__notification__dropdown__container__inner__message">
                              <span>John Doe</span> has accepted your proposed
                              session,
                              <br /> scheduled for 11:00 AM - 12:30PM (GMT+1).
                            </p>
                            <p className="navbar__notification__dropdown__container__inner__type">
                              View my sessions
                            </p>
                          </div>
                          <p className="navbar__notification__dropdown__container__inner__time">
                            10mins Ago
                          </p>
                        </div>
                        <div>
                          <div className="dot-green" />
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "white" }}
                        href=""
                        className="navbar__notification__dropdown__container"
                      >
                        <div className="navbar__notification__dropdown__container__inner">
                          <img
                            src={StarGreen}
                            className="navbar__notification__dropdown__container__inner__image"
                          />
                          <div>
                            <p className="navbar__notification__dropdown__container__inner__message">
                              You just earned a <span>Top Subscriber</span>{" "}
                              recognition.
                            </p>
                            <p className="navbar__notification__dropdown__container__inner__type">
                              see what this means
                            </p>
                          </div>
                          <p className="navbar__notification__dropdown__container__inner__time">
                            10mins Ago
                          </p>
                        </div>
                        <div><div className="dot-green" /> </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "white" }}
                        href=""
                        className="navbar__notification__dropdown__container"
                      >
                        <div className="navbar__notification__dropdown__container__inner">
                          <img
                            src={StarGreen}
                            className="navbar__notification__dropdown__container__inner__image"
                          />
                          <div>
                            <p className="navbar__notification__dropdown__container__inner__message">
                              You just earned a <span>Top Subscriber</span>{" "}
                              recognition.
                            </p>
                            <p className="navbar__notification__dropdown__container__inner__type">
                              see what this means
                            </p>
                          </div>
                          <p className="navbar__notification__dropdown__container__inner__time">
                            10mins Ago
                          </p>
                        </div>
                        <div><div className="dot-green" /> </div>
                      </Dropdown.Item> */}
                      </>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="avatar__menu">
                    {userData?.role == 0 ? (
                      <img
                        src={
                          userData?.images
                            ? userData?.images
                            : image?.image
                              ? image?.image
                              : AvatarIcon
                        }
                        alt="Logo"
                        style={{ borderRadius: "50%" }}
                        width="40px"
                        height="40px"
                      />
                    ) : (
                      <img
                        src={
                          mentorData[0]?.mentorProfiles[0]?.image
                            ? mentorData[0]?.mentorProfiles[0]?.image
                            : AvatarIcon
                        }
                        alt="Logo"
                        style={{ borderRadius: "50%" }}
                        width="40px"
                        height="40px"
                      />
                    )}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu className="custom__dropdown custom__dropdown__bg"> */}
                  <Dropdown.Menu className=" custom__dropdownNavbar custom__dropdown__bg">
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={GoToAccount}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={ProfileIcon}
                          alt="profileicon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">Account</p>
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={GoToMentor}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={TeacherIcon}
                          alt="teachericon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">
                          Become a Mentor
                        </p>
                      </div>
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={Logout}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={LogoutIcon}
                          alt="logouticon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">Log out</p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="nav__login">
                {isMentor ? (
                  <div
                    onClick={switchToMentor}
                    className={`nav__Right__navigations__item__link `}
                  >
                    <label class="switchmentor switch__container">
                      <span className="switchmentor__text">
                        Switch to mentor
                      </span>
                      <input type="checkbox" />
                      <span class="slidermentor"></span>
                    </label>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/signup"
                      className={`nav__Right__navigations__item__link`}
                    >
                      Sign up
                    </Link>
                    <Link
                      to="/login"
                      className={`nav__Right__navigations__item__link nav__login__button`}
                    >
                      Log in
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="mobile__nav__container">
            {userData?._id && isMentor === false && (
              <div className="nav__Right">
                <div className="image__notifications__container">
                  <img
                    src={
                      notifications?.every((session) => session.read)
                        ? NotificationNone
                        : Notification
                    }
                    alt="Logo"
                    className="image__notifications"
                    onClick={() => {
                      setIsMobileNotificationActive(
                        !isMobileNotificationActive
                      );
                      setIsMobileActive(false);
                      getNotifications();
                    }}
                  />
                </div>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="avatar__menu">
                    {userData?.role == 0 ? (

                      <img
                        src={
                          userData?.images
                            ? userData?.images
                            : image?.image
                              ? image?.image
                              : AvatarIcon
                        }
                        alt="Logo"
                        style={{ borderRadius: "50%" }}
                        width="24px"
                        height="24px"
                      />
                    )
                      : (
                        <img
                          src={

                            mentorData[0]?.mentorProfiles[0]?.image
                              ? mentorData[0]?.mentorProfiles[0]?.image
                              : AvatarIcon
                          }
                          alt="Logo"
                          style={{ borderRadius: "50%" }}
                          width="24px"
                          height="24px"
                        />

                      )
                    }
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu className="custom__dropdown custom__dropdown__bg"> */}
                  <Dropdown.Menu className="custom__dropdownNavbarMob custom__dropdown__bg">
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={GoToAccount}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={ProfileIcon}
                          alt="profileicon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">Account</p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={BuyCredits}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={coin}
                          alt="profileicon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">Buy Credits</p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={GoToMentor}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={TeacherIcon}
                          alt="teachericon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">
                          Become a Mentor
                        </p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom__dropdown__item"
                      onClick={Logout}
                    >
                      <div className="profile__dropdown">
                        <img
                          src={LogoutIcon}
                          alt="logouticon"
                          className="profile__dropdown__image"
                        />
                        <p className="profile__dropdown__text">Log out</p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {userData?._id && isMentor === true && (
              <div
                onClick={switchToMentor}
                className={`nav__Right__navigations__item__link  me-2`}
              >
                <label class="switchmentor switch__container">
                  <span className="switchmentor__text">Switch to mentor</span>
                  <input type="checkbox" />
                  <span class="slidermentor"></span>
                </label>
              </div>
            )}
            <img
              className="me-4 hamburger__icon"
              src={MenuIcon}
              alt="hamburger"
              onClick={() => {
                setIsMobileActive(!isMobileActive);
                setIsMobileNotificationActive(false);
              }}
            />
          </div>
          {isMobileActive && (
            <ul
              className={`nav__mobile__navigations ${isMobileActive && "nav__mobile__navigations__shown"
                }`}
            >
              <div className="mobile__menu__top">
                <img src={NavLogo} />
                <img
                  src={CancelNavbar}
                  className="mobile__menu__top__button"
                  onClick={() => {
                    setIsMobileActive(!isMobileActive);
                    setOpen(false);
                  }}
                />
              </div>
              {!open ? (
                <>
                  <div className="nav__mobile__navigations__container">
                    <li className="nav__mobile__navigations__container__item">
                      <a
                        href="https://www.fast-track.community/"
                        onClick={() => {
                          setIsMobileActive(false);
                          setOpen(!open);
                        }}
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <a
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        href="https://www.fast-track.community/resources"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Study
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);
                          setOpen(!open);
                        }}
                        isMobileActive
                        to={
                          userData?.role === 0
                            ? "/interviewdashboard"
                            : "/interview"
                        }
                        className={`nav__mobile__navigations__container__item__link ${location.pathname === "/interview" ||
                          location.pathname === "/interviewdashboard" ||
                          location.pathname === "/interview/1"
                          ? "active"
                          : ""
                          }`}
                      >
                        Interview
                      </Link>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        to={
                          userData?.role === 0
                            ? "/networkDashboard"
                            : "/network"
                        }
                        className={`nav__mobile__navigations__container__item__link ${location.pathname === "/network" ||
                          location.pathname === "/networkDashboard"
                          ? "active"
                          : ""
                          }`}
                      >
                        Network
                      </Link>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        to="/forum"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Forum
                      </Link>
                    </li>

                    <li className="nav__mobile__navigations__container__item">
                      <a
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        href="https://www.fast-track.community/contact"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Contact
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <button
                        onClick={() => {
                          setOpen(true);
                        }}
                        // href='https://www.fast-track.community/contact'
                        className={`nav__mobile__navigations__container__item__link nav__mobile__navigations__container__item__button`}
                      >
                        <p>Join Us</p>
                        <img src={ArrowRight} />
                      </button>
                    </li>
                    {userData?._id && (
                      <li className="nav__mobile__navigations__container__item">
                        <Link
                          onClick={Logout}
                          // to="/"
                          className={`nav__mobile__navigations__container__item__link`}
                        >
                          Log out
                        </Link>
                      </li>
                    )}
                    {!userData?._id && (
                      <div className="nav__login__mobile">
                        <Link
                          onClick={() => setIsMobileActive(!isMobileActive)}
                          to="/signup"
                          className={`nav__Right__navigations__item__link`}
                        >
                          Sign up
                        </Link>
                        <Link
                          onClick={() => setIsMobileActive(!isMobileActive)}
                          to="/signup"
                          className={`nav__Right__navigations__item__link nav__login__button`}
                        >
                          Log in
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="nav__mobile__navigations__footer">
                    <div className="nav__mobile__navigations__footer__inner">
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={LinkedIn} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={FacebookIcon} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={TwitterIcon} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={MailIcon} />
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <div className="nav__mobile__navigations__container">
                  <li className="nav__mobile__navigations__container__item">
                    <button
                      onClick={() => setOpen(false)}
                      // href='https://www.fast-track.community/contact'
                      className={`nav__mobile__navigations__container__item__link nav__mobile__navigations__container__item__button2`}
                    >
                      <img src={ArrowLeft} />
                      <p>Join Us</p>
                    </button>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      to="/signup"
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Sign up
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Join us at fast_track
                      </span>
                    </Link>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      to="/mentor"
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Become a Mentor
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Mentor people from all over the world
                      </span>
                    </Link>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      to="/login"
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Log in
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Learn more on the go from peers
                      </span>
                    </Link>
                  </li>
                </div>
              )}
            </ul>
          )}
          {isMobileNotificationActive && (
            <div
              className={`mobile__notifications ${isMobileNotificationActive && "mobile__notifications__shown"
                }`}
            >
              <div
                className="mobile__notifications__top"
                onClick={() => {
                  setIsMobileNotificationActive(false);
                }}
              >
                <img
                  src={ArrowLeftFull}
                  className="mobile__notifications__top__image"
                />
                <h2 className="mobile__notifications__top__title">
                  Notifications
                </h2>
              </div>
              <div
                className={`mobile__notifications__bottom  ${notifications?.length > 0 ? "" : "mentor__bot"
                  }`}
              >
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <div
                      onClick={() => goToRoute(notification)}
                      style={{ color: "white" }}
                      href=""
                      className={`navbar__notification__dropdown__container ${notification?.read === false
                        ? "navbar__notification__item__green"
                        : ""
                        }`}
                    >
                      <div className="navbar__notification__dropdown__container__inner">
                        <img
                          src={MovieIcon}
                          className="navbar__notification__dropdown__container__inner__image"
                        />
                        <div>
                          <p className="navbar__notification__dropdown__container__inner__message">
                            {notification.massage}
                          </p>
                          {/* <p className="navbar__notification__dropdown__container__inner__type">
                            View my sessions
                          </p> */}
                        </div>
                        <p className="navbar__notification__dropdown__container__inner__time">
                          {moment(notification?.createdAt).fromNow()}
                        </p>
                      </div>
                      {notification?.read === false && (
                        <div>
                          <div className="dot-green" />
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no__notifications no__notifications__height">
                    <div className="no__notifications__container">
                      <img
                        src={EmptyNotifications}
                        className="no__notifications__container__image"
                      />
                      <h2 className="no__notifications__container__text">
                        No Notifications Found
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
}
