import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ProtectedRoute = ({ component: Component, role, profile, ...rest }) => {
  const [path, setPath] = useState("");
  useEffect(() => {
    const currentPath = window.location.pathname;
    setPath(currentPath);
  }, [path]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          role === 0 &&
          profile === true &&
          rest.path === "/interviewdashboard"
        ) {
          return <Component {...props} />;
        } else if (
          role === 0 &&
          profile === false &&
          rest.path === "/personalInfo"
        ) {
          return <Component {...props} />;
        } else if (role === 1 && rest.path === "/mentordashboard") {
          return <Component {...props} />;
        } else if (role === 0 && rest.path === "/meetingpage") {
          return <Component {...props} />;
        } else if (role === 1 && rest.path === "/meetingpagementor") {
          return <Component {...props} />;
        } else if (role === 0 && profile === true) {
          return <Redirect to="/interviewdashboard" />;
        } else if (role === 1) {
          return <Redirect to="/mentordashboard" />;
        }else if (role === 0 && rest.path === "/buycredits") {
          return <Component {...props} />;
        }
        else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
