import React from 'react'

const MyLoader = () => {
    return (
        // <div class="spinner-border" role="status">
        //     <span class="sr-only"></span>
        // </div>
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="4" viewBox="0 0 11 4" fill="none">
                <path d="M4 0.75H6.66667V3.41667H4V0.75ZM8 0.75H10.6667V3.41667H8V0.75ZM0 0.75H2.66667V3.41667H0V0.75Z" fill="black" />
            </svg>
        </>
    )
}

export default MyLoader