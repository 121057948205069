import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SuccessToast } from "../components/MyToaster";
import { updatprofile, getUserProfile } from "../service/redux/middleware/userProfile";

import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UpdateProfile = () => {
    const userData = useSelector((state) => state?.signin?.signInData?.data);
    const token = useSelector((state) => state?.signin?.signInData?.token);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");

    const data = {
        userId: userData?._id,
        token: token
      }
    useEffect(() => {

        dispatch(getUserProfile(data)).then((res) => {
            if (res?.payload?.status === 200) {
                setName(res?.payload?.data?.data?.name)
                setPhone(res?.payload?.data?.data?.phone)
                setAddress(res?.payload?.data?.data?.address)
                setCity(res?.payload?.data?.data?.city)

                // setOnSuccess(true);
            }
        });
    }, [userData])


    const dispatch = useDispatch();
    const history = useHistory();


    const handleLogin = (e) => {
        e?.preventDefault()
        // if (!loginEmail) {
        //     ErrorToast("Please enter email");
        //     return;
        // } else if (!loginPassword) {
        //     ErrorToast("Please enter password")
        //     return;
        // }
        const data = {
            _id: userData?._id,
            name: name,
            phone: phone,
            address: address,
            city: city,
            token: token
        };
        dispatch(updatprofile(data)).then((res) => {
            if (res?.payload?.status === 200) {
                SuccessToast("Profile update successfully.")
                history.push("/dashboard")
            }
        });
    };



    return (
        <div className="container">
            <div className="form-box">
                <Link to='/dashboard' className='mb-3'>Back</Link>
                <div className="body-form">
                    <form>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                className="form-control" placeholder="name" />
                        </div>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                                className="form-control" placeholder="phone" />
                        </div>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                                className="form-control" placeholder="address" />
                        </div>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                                className="form-control" placeholder="city" />
                        </div>
                        <button type="button" className="btn btn-secondary btn-block"
                            onClick={(e) => {
                                // navigate("/");
                                handleLogin(e);
                            }}
                        >Update Profile</button>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default UpdateProfile