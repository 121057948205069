import React, { useEffect, useState } from "react";
import BookSession from "./BookSession/BookSession";
import MySessions from "./MySession/MySession";
import Interview from "./InterviewGuides/Interview";
import Performance from "./Performance/Performance";
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import PerformanceIcon from "../../assets/performance-icon.svg";
import PerformanceIconGreen from "../../assets/performance-icon-green.svg";
import InterviewIcon from "../../assets/interview-icon.svg";
import InterviewIconGreen from "../../assets/interview-icon-green.svg";
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import NavBar from "../Navbar/NavBar";
import "./Networkdashboard.scss";
import "./dashboardbar.scss";
import { useSelector } from "react-redux";
import pay from "../../assets/play-circle.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";


const NetworkDashboard = ({children}) => {
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const history = useHistory();
  const currentRoute = history.location.pathname;

  const [selected, setSelected] = useState(0);
  const setSelectedComponent = (asd) => {
    setSelected(asd);
  };
  const { mysessionId } = useParams();
  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      // Convert the stored string back to a boolean
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);

  useEffect(() => {
    if (userData?.role === 1) {
      history.push("/network");
    }
  }, []);

  useEffect(() => {
    if (mysessionId == undefined) {
      setSelectedComponent(0);
    } else if (mysessionId=='1') {
      setSelectedComponent(mysessionId);
    }
    else{
      setSelectedComponent(mysessionId);
    }
  }, [mysessionId]);
  return (
    <>
      <NavBar />

      {(!userData?._id || isMentor) && (
        <div className="guest__landingpage">
          <img src={pay} />
          <div className="guest__landingpage__text">
            Explainer or something similar, to make things super easy for the
            users
          </div>
        </div>
      )}
      <div className="container__custom">
        {userData?._id && isMentor === false && (
          <div className="dashboardbar__container">
            <ul className="dashboardbar__container__nav">
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    history.push("/networkDashboard")
                  }}
                  className={`dashboardbar__container__nav__item__link ${currentRoute === "/networkDashboard" ? "active" : ""
                    }`}
                >
                  <img
                    src={currentRoute === "/networkDashboard" ? saveAddGreen : saveAdd}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    Book sessions
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    history.push("/networkmysession")
                  }}
                  className={`dashboardbar__container__nav__item__link ${currentRoute === "/networkmysession" ? "active" : ""
                    }`}
                >
                  <img
                    src={currentRoute === "/networkmysession" ? SaveSessionsGreen : SaveSessionsIcon}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    My sessions
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                   onClick={() => {
                    history.push("/networkinterviewguid")
                  }}
                  className={`dashboardbar__container__nav__item__link ${currentRoute === "/networkinterviewguid" ? "active" : ""
                    }`}
                >
                  <img
                    src={currentRoute === "/networkinterviewguid" ? InterviewIconGreen : InterviewIcon}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    Interview guides
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    history.push("/networkperformance")
                  }}
                  className={`dashboardbar__container__nav__item__link ${ currentRoute === "/networkperformance" ? "active" : ""
                    }`}
                >
                  <img
                    src={
                      currentRoute === "/networkperformance" ? PerformanceIconGreen : PerformanceIcon
                    }
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    My performance
                  </p>
                </div>
              </li>
            </ul>
          </div>
        )}
        {
          children
        }
        {/* <div className="dashboard__container__d">
          {selected == 0 ? (
            <BookSession setSelectedComponent={setSelectedComponent} />
          ) : selected == 1 ? (
            <MySessions setSelectedComponent={setSelectedComponent} />
          ) : selected == 2 ? (
            <Interview setSelectedComponent={setSelectedComponent} />
          ) : (
            <Performance />
          )}
        </div> */}
        <NewsletterFooter />
      </div>
    </>
  );
};

export default NetworkDashboard;
