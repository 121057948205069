import { createSlice } from "@reduxjs/toolkit";
import { getForum } from "../middleware/forum";

const initialState = {
  loading: false,
  error: "",
  forumData: [],
};
const singUpSliceForMentorEmail = createSlice({
  name: "getForum",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getForum.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getForum.fulfilled, (state, action) => {
      state.loading = false;
      state.forumData = action.payload;
    });
    builder.addCase(getForum.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default singUpSliceForMentorEmail.reducer;
