import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import NavBar from "../../Navbar/NavBar";
import LeftArrow from "../../../assets/arrow-left.svg";
// import MentorPersonalInfo from "./MentorPersonalInfo";

//merge imports
import countryList from "react-select-country-list";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import Dropdown from "react-bootstrap/Dropdown";
import { API_URL, client } from "../../../service/client";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "./MentorPersonalInfo.scss";
import editIcon from "../../../assets/editGreen.svg";
import EditIcon from "../../../assets/edit.svg";
import TrashIcon from "../../../assets/trash.svg";
import { Link } from "react-router-dom";
import { ConfirmModal, SessionModal } from "../../SessionModal/SessionModal";
import {
  createStripe,
  getStripAccount,
  viewMentorProfile,
  updateMentorProfile,
  updateMentorEducation,
  updateMentorProfession,
  updateMentorBank,
  addMentorEducation,
  addMentorProfessional,
  addInvestmentBank,
  deleteMentorProfession,
  deleteMentorBank,
  deleteEducations,
} from "../../../service/redux/middleware/mentor";
import moment from "moment";
import { changeMail } from "../../../service/redux/middleware/userProfile";
import CalendarEaxct from "../../../assets/CalendarEaxct.svg";
//bank list

import minus from "../../../assets/minus.svg";
import plusWhite from "../../../assets/plusWhite.svg";
import DateTimePicker from "react-datetime-picker";
import RemoveIcon from "../../../assets/remove-icon.svg";
import squareAddWhite from "../../../assets/squareAddWhite.svg";

//
import cvIcon from "../../../assets/cvIcon.svg";
import ScreenLoader from "../../../components/Loader/ScreenLoader";
import bankLogo from "../../../assets/BankLogo.svg";
//
import { MenuItem, Select } from "@mui/material";

//
import "./MentorSetting.scss";
import {
  mentorUpdateServices,
  viewMentor,
  getCurrency,
} from "../../../service/redux/middleware/mentor";
import MentorSecondNavbar from "../MentorSecondNavbar";
import Footer from "../../Footer/Footer";
import NewsletterFooter from "../../NewsletterFooter/NewsletterFooter";

const MentorSetting = ({ setSelectedFromSetting, setMentorData1 }) => {
  const optionsCountries = useMemo(() => countryList().getData(), []);
  //
  const [indexwait, setindexwait] = useState(0);
  const [showText, setShowText] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const rightContentRef = useRef(null);

  ///merge mentorpersonal
  const [openModalForEdit, setOpenModalForEdit] = useState(false);
  const [openModalForEdit2, setOpenModalForEdit2] = useState(false);
  const [openModalForEdit3, setOpenModalForEdit3] = useState(false);
  const [openModalForEdit4, setOpenModalForEdit4] = useState(false);
  const [openModalForEdit5, setOpenModalForEdit5] = useState(false);

  // const ChangeInputValue = (e) => {
  //   e.preventDefault();
  //   setOpenModalForEdit(true);
  // };

  const [openModalForEdit1, setOpenModalForEdit1] = useState(false);
  const ChangeInputValue1 = (e) => {
    e.preventDefault();
    setOpenModalForEdit1(true);
  };

  // const [startDate, setStartDate] = useState(new Date());
  const [isVisiblePersonal, setIsVisiblePersonal] = useState(true);
  const [isVisibleEducation, setIsVisibleEducation] = useState(false);
  const [isVisibleExperience, setIsVisibleExperience] = useState(false);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);

  //personal info
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");

  // education states
  const [isChecked, setIsChecked] = useState([false]);
  const [multiplemages, setmultiplemages] = useState([
    {
      institution: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    },
  ]);
  //
  // professional experience
  const [uploadPdf, setUploadPdf] = useState("");
  const [multiplemages1, setmultiplemages1] = useState([
    {
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);
  const [experienceCheck, setExperienceCheck] = useState([false]);
  //
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const dispatch = useDispatch();
  // const history = useHistory();

  // Haris WORK Profile settings

  const [mentorData, setMentorData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    background: "",
    location: "",
    language: "",
  });

  const [userProfileData, setUserProfileData] = useState({
    email: "",
    phoneNo: "",
  });

  const [currency, setCurrency] = useState({
    CurrencyValue: "",
  });

  const [eduDetail, setEduDetail] = useState({
    institution: "",
    startDate: "",
    degree: "",
    endDate: "",
    currentlyStuding: "",
  });

  const [verified, setVerified] = useState(false);
  const [passwordVer, setPasswordVer] = useState();

  const ChangeInputValue = (e) => {
    e.preventDefault();
    if (!mentorData?.user?.social) {
      setOpenModalForEdit(true);
    } else {
      ErrorToast("you logined through social");
    }
  };

  const [bankData, setBankData] = useState([]);
  const data = {
    userId: userData?._id,
    token: token,
  };
  useEffect(() => {
    setLoader(true);
    dispatch(viewMentorProfile(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMentorData(res?.payload?.data);
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });

    dispatch(getStripAccount(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setBankData(res?.payload?.data);
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });

    // for check currency

    setLoader(true);
    dispatch(getCurrency(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setDropDown(res?.payload?.data?.currency);
        // setMentorData(res?.payload?.data);
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });
  }, []);

  useEffect(() => {
    setProfileData({
      firstName: mentorData?.user?.firstName ? mentorData?.user?.firstName : "",
      lastName: mentorData?.user?.lastName ? mentorData?.user?.lastName : "",
      background: mentorData?.data?.background
        ? mentorData?.data?.background
        : "",
      location: mentorData?.data?.location ? mentorData?.data?.location : "",
      language: mentorData?.data?.language ? mentorData?.data?.language : "",
    });

    setUserProfileData({
      email: mentorData?.user?.email ? mentorData?.user?.email : "",
      phoneNo: mentorData?.user?.phoneNo ? mentorData?.user?.phoneNo : "",
    });

    setEduDetail({
      institution: mentorData?.data?.educationDetails?.institution
        ? mentorData?.data?.educationDetails?.institution
        : "",
      degree: mentorData?.data?.educationDetails?.degree
        ? mentorData?.data?.educationDetails?.degree
        : "",
      startDate: mentorData?.data?.educationDetails?.startDate
        ? mentorData?.data?.educationDetails?.startDate
        : "",
      endDate: mentorData?.data?.educationDetails?.endDate
        ? mentorData?.data?.educationDetails?.endDate
        : "",
      currentlyStuding: mentorData?.data?.educationDetails?.currentlyStuding
        ? mentorData?.data?.educationDetails?.currentlyStuding
        : "",
    });
    setCurrency({
      CurrencyValue: "Dollar",
    });
  }, [mentorData]);

  const handleChange = (e) => {
    profileData[e.target?.name] = e.target?.value;
    setProfileData({ ...profileData });
  };
  //
  //
  const handleSelectCountry = (selected) => {
    let toUpdate = { ...profileData };
    toUpdate.location = selected;
    setProfileData({ ...toUpdate });
  };
  //
  //
  const handleChnageAccount = (e) => {
    if (verified) {
      userProfileData[e.target?.name] = e.target?.value;
      setUserProfileData({ ...userProfileData });
    }
  };

  const handleChnagePhone = (e) => {
    if (verified) {
      userProfileData.phoneNo = e;
      setUserProfileData({ ...userProfileData });
    }
  };

  const handleChangeEmailReset = (e) => {
    setPasswordVer(e.target.value);
    // userProfileData[e.target.name] = e.target.value;
    // setUserProfileData({ ...userProfileData });
  };

  // handle change for save button
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      userId: userData?._id,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      location: profileData?.location,
      bio: profileData?.background,
      language: profileData?.language,
      email: userProfileData?.email,
      phoneNo: userProfileData?.phoneNo,
      investmentBank: bankInfo[0],
      token: token,
    };
    setLoader(true);
    dispatch(updateMentorProfile(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Profile updated successfully");

            setMentorData(res?.payload?.data);
            setVerified(false);
            setLoader(false);
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };

  const checkVerification = () => {
    const data = {
      userId: userData?._id,
      passwordVer: passwordVer,
    };

    setLoader(true);
    dispatch(changeMail(data)).then((res) => {
      if (res?.payload?.status === 200) {
        SuccessToast("Success");
        setVerified(true);
        setLoader(false);
        // setTemp(res?.payload?.data)
      } else {
        ErrorToast(res?.payload?.message);
      }
    });
  };

  const toggleVisibilityEducation = () => {
    setIsVisibleEducation(!isVisibleEducation);
    setIsVisiblePersonal(false);
    setIsVisibleExperience(false);
  };
  const toggleVisibilityExperience = () => {
    setIsVisibleExperience(!isVisibleExperience);
    setIsVisibleEducation(false);
    setIsVisiblePersonal(false);
  };

  const history = useHistory();
  const hitfunctionss = (asd) => {
    setindexwait(asd);
  };

  const back = () => {
    history.push("/mentordashboard");
  };

  useEffect(() => {
    // Function to handle the scroll event
    function handleScroll() {
      // Get the scroll position relative to the current tab content
      const scrollPosition = window.scrollY - indexwait * 20;

      // Check if the user has scrolled down by a certain threshold (e.g., 200 pixels for each tab)
      if (scrollPosition > 0 && scrollPosition < 170) {
        setindexwait(0); // Personal Information
      } else if (scrollPosition >= 170 && scrollPosition < 250) {
        setindexwait(1); // Account Management
      } else if (scrollPosition >= 250 && scrollPosition < 340) {
        setindexwait(2); // payments
      } else if (scrollPosition >= 340) {
        setindexwait(3); // Password & Security
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [indexwait]);

  //Bank addition code

  const bank = [
    "JPMorgan",
    "Goldman Sachs",
    "Morgan Stanley",
    "Bank of America Merrill Lynch",
    "Citigroup",
    "redit Suisse",
    "Deutsche Bank",
    "UBS Group",
    "Barclays",
    "Wells Fargo Securities",
    "HSBC",
    "Nomura",
    "Evercore",
    "Lazard",
    "Rothschild",
    "Jefferies",
    "RBC Capital Markets",
    "Centerview Partners",
    "BNP Paribas",
  ];

  const [isPersonalDone, setIsPersonalDone] = useState(false);
  const [isVisibleBank, setIsVisibleBank] = useState(false);
  const [isBankDone, setIsBankDone] = useState(false);

  const [isBankCheck, setIsBankCheck] = useState([false]);
  const [isExperienceCheck, setIsExperienceCheck] = useState([false]);

  const [availableBanks, setAvailableBanks] = useState(bank);

  const [dropdown, setDropDown] = useState("Please select currency");

  const [bankInfo, setBankInfo] = useState([
    {
      bank: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);

  const toggleVisibilityBank = (isComing = false) => {
    if (isPersonalDone || isComing) {
      setIsVisibleBank(!isVisibleBank);
      //other
      setIsVisiblePersonal(false);
      setIsVisibleExperience(false);
      setIsVisiblePayment(false);
      setIsVisibleEducation(false);
    } else {
      ErrorToast("Please fill all Personal details to continue.");
    }
  };

  //
  //
  const handleDropdownBankChangeNew = (selected, index) => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo[index]["bank"] = selected;
    setBankInfo(updatedExperienceInfo);
  };

  //
  //
  const bankHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedExperienceInfo = [...bankInfo];
      updatedExperienceInfo[index][isDate] = e;
      setBankInfo(updatedExperienceInfo);
    }
    // else if (bankInfo[index]?.bank) {
    //   const selectedBank = e.target?.value;
    //   const updatedAvailableBanks = availableBanks.filter((bank) => bank != selectedBank);
    //   const updatedExperienceInfo = [...bankInfo];
    //   updatedExperienceInfo[index][e.target?.name] = selectedBank;
    //   setAvailableBanks(updatedAvailableBanks);
    //   setBankInfo(updatedExperienceInfo);
    // }
    else {
      // const { name, value } = e.target;
      const updatedExperienceInfo = [...bankInfo];
      updatedExperienceInfo[index][e.target?.name] = e.target?.value;
      setBankInfo(updatedExperienceInfo);
      // e.target.value = null;
    }
  };

  const handleCheckboxChangeBank = (index) => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo[index].currentlyEmployed =
      !updatedExperienceInfo[index].currentlyEmployed;
    // updatedExperienceInfo[index].endDate = "";

    setBankInfo(updatedExperienceInfo);
    const updatedIsExperienceCheck = [...isBankCheck];
    updatedIsExperienceCheck[index] = !updatedIsExperienceCheck[index]; // Toggle the value
    setIsBankCheck(updatedIsExperienceCheck);
  };

  const handleRemoveBankClick = (e, index) => {
    e.preventDefault();
    const list = [...bankInfo];
    list.splice(index, 1);
    setBankInfo(list);
    //
    const checkboxes = isBankCheck;
    checkboxes.splice(index, 1);
    setIsExperienceCheck(checkboxes);
  };
  // currency
  const handleSelectCurrency = (selected) => {
    setDropDown(selected);
  };

  //
  //
  const handleAddAnotherBank = () => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo.push({
      bank: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    });
    setBankInfo(updatedExperienceInfo);
    const checkboxes = isBankCheck;
    checkboxes.push(false);
    setIsBankCheck(checkboxes);
  };

  const handleBankSubmit = () => {
    let allGood = true;
    bankInfo.map((item, index) => {
      if (item.bank && item.position && item.startDate) {
        if (item.currentlyStuding) {
        } else {
          if (item.endDate) {
          } else {
            allGood = false;
            return;
          }
        }
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsBankDone(true);
      setIsVisibleBank(false);
      setIsVisibleExperience(true);
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
      setIsBankDone(false);
    }
  };

  const EditOtherBank = (elem) => {
    // e.preventDefault();
    setBankInfo([elem]);
    setOpenModalForEdit2(true);
  };

  const addotherbank = () => {
    // e.preventDefault();
    setOpenModalForEdit2(true);
  };

  //Education Section edit

  const [singleEducation, setSingleEducation] = useState([]);
  const [isEducationCheck, setIsEducationCheck] = useState([false]);
  const [isEducationDone, setIsEducationDone] = useState(false);

  const [educationInfo, setEducationInfo] = useState([
    {
      institution: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    },
  ]);

  const EditEducation = (elem) => {
    // e.preventDefault();
    setSingleEducation(elem);
    setEducationInfo([elem]);
    setOpenModalForEdit3(true);
  };

  const educationHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][isDate] = e;
      setEducationInfo(updatedEducationInfo);
    } else {
      // const { name, value } = e.target;
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][e.target?.name] = e.target?.value;
      setEducationInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };

  const handleRemoveEducationClick = (e, index) => {
    e.preventDefault();
    const list = [...educationInfo];
    list.splice(index, 1);
    setEducationInfo(list);
    //
    const checkboxes = isEducationCheck;
    checkboxes.splice(index, 1);
    setIsEducationCheck(checkboxes);
  };
  const handleCheckboxChange = (index) => {
    const updatedEducationInfo = [...educationInfo];
    updatedEducationInfo[index].currentlyStuding =
      !updatedEducationInfo[index].currentlyStuding;
    // updatedEducationInfo[index].endDate = "";

    setEducationInfo(updatedEducationInfo);
    const updatedIsEducationCheck = [...isEducationCheck];
    updatedIsEducationCheck[index] = !updatedIsEducationCheck[index]; // Toggle the value
    setIsEducationCheck(updatedIsEducationCheck);
  };

  const handleEducationSubmit = () => {
    let allGood = true;
    educationInfo.map((item, index) => {
      if (item.degree && item.institution && item.startDate) {
        if (item.currentlyStuding) {
        } else {
          if (item.endDate) {
          } else {
            allGood = false;
            return;
          }
        }
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsEducationDone(true);
      toggleVisibilityBank(true);
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
      setIsEducationDone(false);
    }
  };

  // handle change for save button
  const UpdateEducationAPIcall = async (event) => {
    event.preventDefault();
    const data = {
      userId: userData?._id,
      id: singleEducation._id,
      // id:educationInfo[0]._id,
      institute: educationInfo[0]?.institution,
      field: educationInfo[0]?.degree,
      stateDate: educationInfo[0]?.startDate,
      endDate: educationInfo[0]?.endDate,
      current: educationInfo[0]?.currentlyStuding,
      token: token,
    };

    setLoader(true);
    dispatch(updateMentorEducation(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Education updated successfully ");

            setMentorData(res?.payload?.data);
            // setVerified(false);
            setLoader(false);
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };

  //professional Exerience

  const [cv, setCV] = useState("");

  const [experienceInfo, setExperienceInfo] = useState([
    {
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);

  const handleAddDropDownProgrammeForEdit = (e, index) => {
    setExperienceInfo((prevEducationInfo) => {
      const updatedEducationInfo = [...prevEducationInfo];
      updatedEducationInfo[index] = {
        ...updatedEducationInfo[index],
        programme: e,
      };
      return updatedEducationInfo;
    });
  };

  console.log('experienceInfo',experienceInfo);

  const [editSingleProExp, setEditSingleProExp] = useState([]);
  const EditProfessionInfo = (elem) => {
    // e.preventDefault();
    setEditSingleProExp(elem);
    setExperienceInfo([elem]);
    setOpenModalForEdit4(true);
  };

  // handle change for save button
  const UpdateProfessionAPIcall = async (event) => {
    event.preventDefault();
    const data = {
      userId: userData?._id,
      id: editSingleProExp._id,
      position: experienceInfo[0]?.position,
      startDate: experienceInfo[0]?.startDate,
      endDate: experienceInfo[0]?.endDate,
      current: experienceInfo[0]?.currentlyEmployed,
      company: experienceInfo[0]?.company,
      token: token,
    };

    setLoader(true);
    dispatch(updateMentorProfession(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Professional Experience updated successfully");

            setMentorData(res?.payload?.data);
            dispatch(viewMentorProfile(data)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setLoader(false);
                // setTemp(res?.payload?.data)
              }
            });
            // setVerified(false);
            setLoader(false);
            setOpenModalForEdit3(false);
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };

  const deleteMentorProfessions = async (event) => {
    setLoader(true);
    const data = {
      userId: userData?._id,
      id: event,

      token: token,
    };
    dispatch(deleteMentorProfession(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setMentorData(res?.payload?.data);
            setLoader(false);
            // setTemp(res?.payload?.data)
          }
        });

        SuccessToast("Professionl Experience Deleted");
      }
    });

  };
  const deleteInvestmentBanks = async (event) => {
    setLoader(true);
    const data = {
      userId: userData?._id,
      id: event,
      token: token,
    };
    dispatch(deleteMentorBank(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setMentorData(res?.payload?.data);
            setLoader(false);
            // setTemp(res?.payload?.data)
          }
        });

        SuccessToast("Investment bank  deleted successfully");
      }
    });
  };
  const deleteMentorEducation = async (event) => {
    setLoader(true);
    const data = {
      userId: userData?._id,
      id: event,
      token: token,
    };
    dispatch(deleteEducations(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setMentorData(res?.payload?.data);
            setLoader(false);
            // setTemp(res?.payload?.data)
          }
        });

        SuccessToast("Education  deleted successfully");
      }
    });
  };

  // handle change for save button
  const UpdateBankAPIcall = async (event) => {
    event.preventDefault();
    const data = {
      userId: userData?._id,
      id: bankInfo[0]?._id,
      bank: bankInfo[0]?.bank,
      position: bankInfo[0]?.position,
      startDate: bankInfo[0]?.startDate,
      endDate: bankInfo[0]?.endDate,
      current: bankInfo[0]?.currentlyEmployed,
      token: token,
    };
    setLoader(true);
    dispatch(updateMentorBank(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        dispatch(viewMentorProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            SuccessToast("Investment bank updated successfully");
            setMentorData(res?.payload?.data);
            setLoader(false);
            setOpenModalForEdit2(false);
            dispatch(viewMentorProfile(data)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setLoader(false);
                // setTemp(res?.payload?.data)
              }
            });
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };

  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size >= 2872139) {
      ErrorToast("File size should not exceed maximum limit of 3 MB");
    } else {
      var data = new FormData();
      data.append("files", file);
      if (file) {
        const responses = await client.post(`${API_URL}/api/upload`, data);
        if (responses?.status === 200) {
          SuccessToast("CV uploaded successfully");
        }
        setCV(responses.data.imageUrl);
      }
    }
  };

  const experienceHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][isDate] = e;
      setExperienceInfo(updatedExperienceInfo);
    } else {
      // const { name, value } = e.target;
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][e.target?.name] = e.target?.value;
      setExperienceInfo(updatedExperienceInfo);
      // e.target.value = null;
    }
  };

  const handleCheckboxChangeExperience = (index) => {
    const updatedExperienceInfo = [...experienceInfo];
    updatedExperienceInfo[index].currentlyEmployed =
      !updatedExperienceInfo[index].currentlyEmployed;
    // updatedExperienceInfo[index].endDate = "";

    setExperienceInfo(updatedExperienceInfo);
    const updatedIsExperienceCheck = [...isExperienceCheck];
    updatedIsExperienceCheck[index] = !updatedIsExperienceCheck[index]; // Toggle the value
    setIsExperienceCheck(updatedIsExperienceCheck);
  };

  const handleRemoveExperienceClick = (e, index) => {
    e.preventDefault();
    const list = [...experienceInfo];
    list.splice(index, 1);
    setExperienceInfo(list);
    //
    const checkboxes = isExperienceCheck;
    checkboxes.splice(index, 1);
    setIsExperienceCheck(checkboxes);
  };

  //
  //
  const scrollToTitle = (title) => {
    if (rightContentRef.current) {
      const titleElement = rightContentRef.current.querySelector(
        `[data-title="${title}"]`
      );
      if (titleElement) {
        titleElement.scrollIntoView({ behavior: "smooth", block: "center" });
        // titleElement.scrollIntoView({ behavior: "smooth",block:"nearest" });
        // titleElement.scrollIntoView({ behavior: "smooth",block:"end" });
      }
    }
  };
  const handleItemClick = (title, index) => {
    setActiveItem(title);
    scrollToTitle(title);
    setindexwait(index);
  };

  // Add Another education
  //
  const [userDataNew, setUserDataNew] = useState([]);
  const [addAnotherEducationCheck, setAddAnotherEducationCheck] =
    useState(false);
  const [addPropdownProgramme, setAddDropdownProgramme] = useState("");
  const [addAnotherEducationInfo, setAddAnotherEducationInfo] = useState({
    institution: "",
    degree: "",
    programme: "",
    startDate: new Date(),
    endDate: new Date(),
    currentlyStuding: false,
  });
  const [isAddEducationCheck, setIsAddEducationCheck] = useState(false);
  const AddEducationHandler = (e, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = { ...addAnotherEducationInfo };
      updatedEducationInfo[isDate] = e;
      setAddAnotherEducationInfo(updatedEducationInfo);
    } else {
      // const { name, value } = e.target;
      const updatedEducationInfo = { ...addAnotherEducationInfo };
      updatedEducationInfo[e.target?.name] = e.target?.value;
      setAddAnotherEducationInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };
  const handleAddDropDownProgramme = (e) => {
    setAddDropdownProgramme(e);
    setAddAnotherEducationInfo({
      ...addAnotherEducationInfo,
      programme: e,
    });
  };
  const handleAddEducationCheckboxChange = () => {
    let toUpdate = { ...addAnotherEducationInfo };
    toUpdate.currentlyStuding = !toUpdate.currentlyStuding;
    setAddAnotherEducationInfo(toUpdate);
    setIsAddEducationCheck(!isAddEducationCheck);
  };
  const validateAddAnotherEducation = () => {
    if (
      addAnotherEducationInfo.institution !== "" &&
      addAnotherEducationInfo.degree !== "" &&
      addAnotherEducationInfo.programme !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleAddAnotherEducationSubmit = () => {
    const isValid = validateAddAnotherEducation();
    if (isValid) {
      const data = {
        userId: userData?._id,
        token: token,
        educationdetail: {
          institution: addAnotherEducationInfo.institution,
          degree: addAnotherEducationInfo.degree,
          programme: addAnotherEducationInfo.programme,
          startDate: moment(addAnotherEducationInfo.startDate).toISOString(),
          endDate: moment(addAnotherEducationInfo.endDate).toISOString(),
          currentlyStuding: addAnotherEducationInfo.currentlyStuding,
        },
      };
      dispatch(addMentorEducation(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          dispatch(viewMentorProfile(data)).then((res) => {
            if (res?.payload?.status === 200) {
              SuccessToast("Education added successfully");
              setMentorData(res?.payload?.data);
              // emptying modal
              setAddAnotherEducationCheck(false);
              setIsAddEducationCheck(false);
              setAddDropdownProgramme("");
              setAddAnotherEducationInfo({
                institution: "",
                degree: "",
                programme: "",
                startDate: new Date(),
                endDate: new Date(),
                currentlyStuding: false,
              });

              //
              setUserDataNew(res?.payload?.data);

              // const initialOption = options.find(
              //   (item) => item.name == res?.payload?.data?.data?.language
              // );
              // if (initialOption) {
              //   setLang([initialOption]);
              //   // setProfileData(initialOption);
              // }
              setLoader(false);
              // setTemp(res?.payload?.data)
            } else {
              setLoader(false);
              ErrorToast(res?.payload?.message);
            }
          });
        } else {
          ErrorToast(resi?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
  };
  //
  //
  // Add another Experience
  //
  //
  const [addAnotherExperienceCheck, setAddAnotherExperienceCheck] =
    useState(false);
  const [addAnotherExperienceInfo, setAddAnotherExperienceInfo] = useState({
    company: "",
    position: "",
    startDate: new Date(),
    endDate: new Date(),
    currentlyEmployed: false,
  });
  const [isAddExperienceCheck, setIsAddExperienceCheck] = useState(false);
  const AddExperienceHandler = (e, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = { ...addAnotherExperienceInfo };
      updatedEducationInfo[isDate] = e;
      setAddAnotherExperienceInfo(updatedEducationInfo);
    } else {
      // const { name, value } = e.target;
      const updatedEducationInfo = { ...addAnotherExperienceInfo };
      updatedEducationInfo[e.target?.name] = e.target?.value;
      setAddAnotherExperienceInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };
  const handleAddExperienceCheckboxChange = () => {
    let toUpdate = { ...addAnotherExperienceInfo };
    toUpdate.currentlyEmployed = !toUpdate.currentlyEmployed;
    setAddAnotherExperienceInfo(toUpdate);
    setIsAddExperienceCheck(!isAddEducationCheck);
  };
  const validateAddAnotherExperience = () => {
    if (
      addAnotherExperienceInfo.company !== "" &&
      addAnotherExperienceInfo.position !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleAddAnotherExperienceSubmit = () => {
    const isValid = validateAddAnotherExperience();
    if (isValid) {
      const data = {
        userId: userData?._id,
        token: token,
        professionalDetail: {
          company: addAnotherExperienceInfo.company,
          position: addAnotherExperienceInfo.position,
          startDate: moment(addAnotherExperienceInfo.startDate).toISOString(),
          endDate: moment(addAnotherExperienceInfo.endDate).toISOString(),
          currentlyEmployed: addAnotherExperienceInfo.currentlyEmployed,
        },
      };
      dispatch(addMentorProfessional(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          dispatch(viewMentorProfile(data)).then((res) => {
            if (res?.payload?.status === 200) {
              SuccessToast("Experience added successfully");
              // emptying modal
              // setAddAnotherExperienceCheck()
              setAddAnotherExperienceCheck(false);
              setIsAddExperienceCheck(false);
              setAddAnotherExperienceInfo({
                company: "",
                position: "",
                startDate: new Date(),
                endDate: new Date(),
                currentlyEmployed: false,
              });
              //
              setMentorData(res?.payload?.data);

              setUserDataNew(res?.payload?.data);

              // const initialOption = options.find(
              //   (item) => item.name == res?.payload?.data?.data?.language
              // );
              // if (initialOption) {
              //   setLang([initialOption]);
              //   // setProfileData(initialOption);
              // }
              setLoader(false);
              // setTemp(res?.payload?.data)
            } else {
              setLoader(false);
              ErrorToast(res?.payload?.message);
            }
          });
        } else {
          ErrorToast(resi?.payload?.message);
          setLoader(false);
        }
      });

    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
  };
  //
  // Add another bank
  //
  const [addAnotherBankInfo, setAddAnotherBankInfo] = useState({
    bank: "",
    position: "",
    startDate: new Date(),
    endDate: new Date(),
    currentlyEmployed: false,
  });
  const [isAddBankCheck, setIsAddBankCheck] = useState(false);
  const AddBankHandler = (e, isDate = "") => {
    if (isDate.length > 0) {
      const updatedBankInfo = { ...addAnotherBankInfo };
      updatedBankInfo[isDate] = e;

      setAddAnotherBankInfo(updatedBankInfo);
    } else {
      // const { name, value } = e.target;
      const updatedBankInfo = { ...addAnotherBankInfo };
      updatedBankInfo[e.target?.name] = e.target?.value;
      setAddAnotherBankInfo(updatedBankInfo);
      // e.target.value = null;
    }
  };
  const handleAddBankDropdown = (selected) => {
    let toUpdate = { ...addAnotherBankInfo };
    toUpdate.bank = selected;
    setAddAnotherBankInfo(toUpdate);
  };
  const handleAddBankCheckboxChange = () => {
    let toUpdate = { ...addAnotherBankInfo };
    toUpdate.currentlyEmployed = !toUpdate.currentlyEmployed;
    setAddAnotherBankInfo(toUpdate);
    setIsAddBankCheck(!isAddBankCheck);
  };
  const validateAddAnotherBank = () => {
    if (addAnotherBankInfo.bank !== "" && addAnotherBankInfo.position !== "") {
      return true;
    } else {
      return false;
    }
  };
  const handleAddAnotherBankSubmit = () => {
    const isValid = validateAddAnotherBank();
    if (isValid) {
      const data = {
        userId: userData?._id,
        token: token,
        investmentBanks: {
          bank: addAnotherBankInfo.bank,
          position: addAnotherBankInfo.position,
          startDate: moment(addAnotherBankInfo.startDate).toISOString(),
          endDate: moment(addAnotherBankInfo.endDate).toISOString(),
          currentlyEmployed: addAnotherBankInfo.currentlyEmployed,
        },
      };
      setLoader(true);
      dispatch(addInvestmentBank(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          dispatch(viewMentorProfile(data)).then((res) => {
            if (res?.payload?.status === 200) {
              SuccessToast("Investment bank added successfully");
              setOpenModalForEdit5(false);
              setMentorData(res?.payload?.data);
              setAddAnotherBankInfo({
                bank: "",
                position: "",
                startDate: new Date(),
                endDate: new Date(),
                currentlyEmployed: false,
              });
              setIsAddBankCheck(false);
              setLoader(false);
            } else {
              setLoader(false);
              ErrorToast(res?.payload?.message);
            }
          });
        } else {
          ErrorToast(resi?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
  };
  //
  //
  //
  //

  const createStripeAccount = () => {
    if (userData?._id) {
      dispatch(createStripe(userData?._id)).then((res) => {
        if (res?.payload?.status === 200) {
          window.open(res?.payload?.data, "_blank");
          // setOnSuccess(true);
        } else {
          ErrorToast(res?.payload?.message);
        }
      });
    }
    // dispatch(mentorStripAccount(userData1?._id))
    //     .then((res) => {
    //         if (res?.payload?.status === 200) {
    //             window.open(res?.payload?.data, '_blank');
    //             // setOnSuccess(true);
    //         }
    //         else {
    //             ErrorToast(res?.payload?.message)
    //         }
    //     })
  };

  const data1 = {
    userId: userData?._id,
    token: token,
  };

  // edit currency
  const editCurrency = async () => {
    const data = {
      mentorId: userData?._id,
      currency: dropdown,
      // summary: updatePriceData?.background,
      // generalGuidance: generalGuidanceValue,
      // cvReview: cvReviewValue,
      // interviewPractice: interviewPracticeValue,
      // coverLetter: coverLetterValue,
      token: token,
    };
    setLoader(true);
    dispatch(mentorUpdateServices(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Currency updated successfully");
            setMentorData1(res?.payload?.data);
            // setShowAddSchedules(false);
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };



  console.log('dertyju',educationInfo);

  return (
    <div className="container__custom">
      <NavBar />
      <MentorSecondNavbar mentor={true} />
      {loader && <ScreenLoader />}
      <div className="container-xxl ">
        <div className="reschedule__session__container__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={back}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back To Dashboard
            </p>
          </div>
          <div className="reschedule__session__container__top__right">
            <button
              className="btn-green"
              // onClick={() => setShowConfirmModal(true)}
              onClick={handleSubmit}
            >
              Save
            </button>
            <button className="btn-grey" onClick={back}>
              Cancel
            </button>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="mobile__topnav">
          <ul className="settings__topnav">
            <li>
              <a
                onClick={() => {
                  handleItemClick("Personal Information", 0);
                }}
                className={
                  indexwait === 0 ? "list__item active" : "list__item "
                }
              >
                <span className="ms-2">Personal Information</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Account Management", 1);
                }}
                className={
                  indexwait === 1 ? "list__item active" : "list__item "
                }
              >
                <span className="ms-2">Account Management</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Payments", 2);
                }}
                className={
                  indexwait === 2 ? "list__item active" : "list__item "
                }
              >
                <div className="set-flex-for-value">
                  <span className="ms-2">Payments</span>
                </div>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Password & Security", 3);
                }}
                className={
                  indexwait === 3 ? "list__item active" : "list__item "
                }
              >
                <div className="set-flex-for-value">
                  <span className="ms-2">Password & Security</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/*  */}
        {/*  */}
        <div style={{ paddingBottom: "70px" }} className="lr_padding">
          <div
            className="app-wrapper scroller__main"
            style={{ height: "90vh" }}
          >
            <div className="sidebar__column web-sidebar scroller__main__left">
              <div className="sidebar__wrapper ">
                <div className="sidebar__wrapper__inner">
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Personal Information", 0);
                        }}
                        className={
                          indexwait === 0 ? "list__item active" : "list__item "
                        }
                      >
                        <span className="ms-2">Personal Information</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Account Management", 1);
                        }}
                        className={
                          indexwait === 1 ? "list__item active" : "list__item "
                        }
                      >
                        <span className="ms-2">Account Management</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Payments", 2);
                        }}
                        className={
                          indexwait === 2 ? "list__item active" : "list__item "
                        }
                      >
                        <div className="set-flex-for-value">
                          <span className="ms-2">Payments</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Password & Security", 3);
                        }}
                        className={
                          indexwait === 3 ? "list__item active" : "list__item "
                        }
                      >
                        <div className="set-flex-for-value">
                          <span className="ms-2">Password & Security</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="scroller__main__right">
              <div className="content__column__1">
                {/* <MentorPersonalInfo   /> */}
                <div className="setting__container">
                  <div ref={rightContentRef}>
                    <h2
                      className="setting__heading setting__heading__padding"
                      data-title={"Personal Information"}
                    >
                      Personal information
                    </h2>
                    <div className="personalDiv">
                      {/* <div className="uploadImage">
                  <h1 className="uploadImage__label"> Upload Picture</h1>
                  <label htmlFor="upload">
                    <div className="uploadImage__circle">
                      {image ? (
                        <img
                          src={image}
                          alt="user profile image"
                          className="uploadImage__circle__pf"
                        />
                      ) : (
                        <img
                          src={plusImage}
                          alt="profile img static"
                          className="uploadImage__circle__plus"
                        />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="upload"
                    onChange={(e) => handleImageUpload(e)}
                  />
                </div> */}
                      <div className="mentor__names">
                        <Form.Group
                          className=" loginfield__optional__margin w-100"
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            First name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="height__input loginfield__type"
                            placeholder="John"
                            name="firstName"
                            value={profileData?.firstName}
                            onChange={(e) => handleChange(e)}
                          // onChange={(e) => setLanguage(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className=" loginfield__optional__margin w-100"
                          controlId=""
                        >
                          <Form.Label className="Languages  loginfield__Label ">
                            Last name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="height__input loginfield__type "
                            placeholder="Deo"
                            name="lastName"
                            value={profileData?.lastName}
                            onChange={(e) => handleChange(e)}
                          // onChange={(e) => setLanguage(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="DropDownLabel">
                        <h1>Location</h1>
                      </div>
                      <Dropdown onSelect={handleSelectCountry}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="height__input dropdownToSelect select__icon"
                        >
                          <p className="forumm__button__text">
                            {profileData?.location
                              ? profileData?.location
                              : "Please select country"}
                          </p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                          {optionsCountries?.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={item.label}
                              className={`dropdownToSelect__item ${item.label === profileData?.location
                                ? "dropdownToSelect__item__checked"
                                : ""
                                }`}
                            >
                              {item.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* Locations */}
                      {/* <Select
                        className="selectTagContainerMaterial select__icon"
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          ".MuiSvgIcon-root-393": {
                            visibility: "hidden",
                          },
                        }}
                        id="location"
                        value={profileData?.location}
                        onChange={(e) => handleChange(e)}
                        displayEmpty
                        name="location"
                      >
                        <MenuItem
                          className="selectTagContainerMaterial__item"
                          value={profileData?.location}
                        >
                          {profileData?.location}
                        </MenuItem>
                        <MenuItem
                          className="selectTagContainerMaterial__item"
                          value={"USA"}
                        >
                          USA
                        </MenuItem>
                        <MenuItem
                          className="selectTagContainerMaterial__item"
                          value={"UK"}
                        >
                          UK
                        </MenuItem>
                      </Select> */}

                      <Form.Group
                        className=" loginfield__optional__margin "
                        controlId=""
                      >
                        <Form.Label className="loginfield__Label">
                          Bio
                          {/* <img src={redstareck} alt="" className="ms-1" /> */}
                        </Form.Label>
                        <textarea
                          type="text"
                          placeholder="Tell candidates about yourself and your background. Explain them why you are the right mentor for them"
                          className="height__input__area reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                          style={{ marginTop: "16px" }}
                          // value={description}
                          // name="description"
                          // onChange={(e) => setDescription(e.target.value)}
                          name="background"
                          value={profileData?.background}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      {/* <FormEditOtherBank.Group className=" loginfield " controlId="">
                        <Form.Label className="loginfield__Label ">
                          Other Languages
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="loginfield__type"
                          placeholder="Select language(s)"
                          name="language"
                          value={profileData?.language}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group> */}
                    </div>
                    {/* <div
            className="PersonalInfoSecForm__footer"
            onClick={toggleVisibilityEducation}
          >
            <h1>Education</h1>
            <img src={isVisibleEducation ? minus : plusWhite} alt="" />
          </div>
          {isVisibleEducation && (
            <div className="EducationDiv">
              {multiplemages?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className={i > 0 ? "sdfdsfdsf" : " sdfdsfdsfdfdf"}
                  >
                    <Form.Group className=" loginfield ">
                      <Form.Label className="loginfield__Label" htmlFor={i}>
                        Institution
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="loginfield__type"
                        placeholder="Crowder University "
                        value={multiplemages[i]?.institution}
                        name="institution"
                        onChange={(e) => handleChange1(e, i)}
                      />
                    </Form.Group>
                    <Form.Group className=" loginfield ">
                      <Form.Label className="loginfield__Label" htmlFor={i}>
                        Field of Study
                      </Form.Label>
                      <Form.Control
                        type=" password"
                        className="loginfield__type"
                        placeholder="Accounting"
                        value={multiplemages[i]?.degree}
                        name="degree"
                        onChange={(e) => handleChange1(e, i)}
                      />
                    </Form.Group>
                    <div className=" datepickerRow ">
                      <DateTimePicker
className="schedules__guides__contaiener__calendar"
                        className="custom-datepicker-input "
                        name="startDate"
                        selected={multiplemages[i]?.startDate}
                        placeholder="Select expiration date..."
                        onChange={(e) => handleChange1(e, i, "startDate")}
                      //  minDate={moment()?.toDate()}
                      />
                      <DateTimePicker
className="schedules__guides__contaiener__calendar"
                        className="custom-datepicker-input ms-2 "
                        selected={multiplemages[i]?.endDate}
                        name="endDate"
                        placeholder="Select expiration date..."
                        onChange={(e) => handleChange1(e, i, "endDate")}
                        disabled={multiplemages[i]?.currentlyStuding}
                      //  minDate={moment()?.toDate()}
                      />
                    </div>
                    <div className="personalCheckbox ">
                      <Form.Check
                        aria-label="option 1"
                        checked={isChecked[i]}
                        onChange={() => handleCheckboxChange(i)}
                      />
                      <p>I am still working at this company</p>
                    </div>
                    <div className="cancelbtn-container">
                      {multiplemages.length > 1 && i > 0 && (
                        <button
                          className="btn-cancel"
                          onClick={(e) => handleRemoveClick(e, i)}
                        >
                          x
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              {multiplemages?.map((x, i) => {
                return (
                  <div key={i}>
                    {multiplemages?.length >= 2 ? (
                      ""
                    ) : (
                      <div className="btn-box">
                        {multiplemages.length - 1 === i && (
                          <button
                            className="personalADD"
                            onClick={(e) => handleAddClick(e)}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                              {i > 0
                                ? "Add another education"
                                : "Add another education"}
                            </p>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <button
                className="login__green__button "
                onClick={(e) => handleEducationSubmit(e)}
              >
                Continue
              </button>
            </div>
          )} */}

                    {/* add education start */}
                    <p
                      style={{ marginTop: "40px" }}
                      className="loginfield__Label"
                    >
                      Education
                    </p>

                    <div className="EducationDiv btn__setting__mtop28_mentor">
                      {mentorData?.data?.educationDetails?.map(
                        (elem, index) => {
                          return (
                            <div className="tab__info__setting">
                              <div className="mentorEducationInfo__Block">
                                <div className="mentorEducationInfo__edu">
                                  <h1>{elem?.institution}</h1>
                                  <h2>
                                    {moment(elem?.startDate).format(
                                      "MMMM, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(elem?.endDate).format("MMMM, YYYY")}{" "}
                                    . {mentorData?.data?.location}
                                  </h2>
                                </div>
                              </div>
                              <div className="mentorEducationInfo__Edit">
                                <div onClick={() => EditEducation(elem)}>
                                  <img
                                    // onClick={() => getSingleData(item)}
                                    src={EditIcon}
                                    alt="edit icon"
                                    style={{ cursor: "pointer" }}
                                    className="editschedules__editcard__container__inner__image"
                                  />
                                </div>
                                {index !== 0 && (
                                  <img
                                    onClick={() => deleteMentorEducation(elem?._id)}
                                    src={TrashIcon}
                                    style={{ cursor: "pointer" }}
                                    alt="trash icon"
                                    className="editschedules__editcard__container__inner__image"
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                      <div className="btn-box">
                        <button
                          className="personalADD padding__addanother"
                          onClick={() => setAddAnotherEducationCheck(true)}
                        >
                          <img src={squareAddWhite} alt="" />
                          <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                            Add education
                          </p>
                        </button>
                      </div>
                    </div>

                    {/* add education end */}

                    {/* add investment bank start */}

                    <p
                      style={{ marginTop: "40px" }}
                      className="loginfield__Label"
                    >
                      Investment bank experience
                    </p>

                    <div className="EducationDiv btn__setting__mtop28_mentor">
                      {mentorData?.data?.investmentBank?.map((elem, index) => {
                        return (
                          <div className="tab__info__setting">
                            <div className="mentorEducationInfo__Block">
                              <div className="mentorEducationInfo__edu">
                                <h1>{elem?.bank}</h1>
                                <h2>
                                  {" "}
                                  {moment(elem?.startDate).format(
                                    "MMMM, YYYY"
                                  )}{" "}
                                  - {moment(elem?.endDate).format("MMMM, YYYY")}{" "}
                                </h2>
                              </div>
                            </div>
                            <div className="mentorEducationInfo__Edit">
                              <div onClick={() => EditOtherBank(elem)}>
                                <img
                                  // onClick={() => getSingleData(item)}
                                  src={EditIcon}
                                  alt="edit icon"
                                  style={{ cursor: "pointer" }}
                                  className="editschedules__editcard__container__inner__image"
                                />
                              </div>

                              {index !== 0 && (
                                <img
                                  onClick={() => deleteInvestmentBanks(elem?._id)}
                                  src={TrashIcon}
                                  style={{ cursor: "pointer" }}
                                  alt="trash icon"
                                  className="editschedules__editcard__container__inner__image"
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="btn-box">
                        <button
                          className="personalADD btn__setting__mbottom padding__addanother"
                          onClick={() => setOpenModalForEdit5(true)}
                        >
                          <img src={squareAddWhite} alt="" />
                          <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                            Add other Investment Banking
                          </p>
                        </button>
                      </div>
                    </div>

                    {/* add investment bank end */}

                    {/* add other experience start */}

                    <p
                      style={{ marginTop: "40px" }}
                      className="loginfield__Label"
                    >
                      Other experience
                    </p>

                    <div className="EducationDiv btn__setting__mtop28_mentor ">
                      {mentorData?.data?.professionalDetails?.map(
                        (elem, index) => {
                          if (elem?.company !== "") {
                            return (
                              <div className="tab__info__setting">
                                {console.log("updating the data", elem)}
                                <div className="mentorEducationInfo__Block">
                                  <div className="mentorEducationInfo__edu">
                                    <h1>{elem?.position}</h1>
                                    <h2>
                                      {" "}
                                      {moment(elem?.startDate).format(
                                        "MMMM, YYYY"
                                      )}{" "}
                                      -{" "}
                                      {moment(elem?.endDate).format(
                                        "MMMM, YYYY"
                                      )}{" "}
                                    </h2>
                                  </div>
                                </div>
                                <div className="mentorEducationInfo__Edit">
                                  <div onClick={() => EditProfessionInfo(elem)}>
                                    <img
                                      // onClick={() => getSingleData(item)}
                                      src={EditIcon}
                                      alt="edit icon"
                                      style={{ cursor: "pointer" }}
                                      className="editschedules__editcard__container__inner__image"
                                    />
                                  </div>

                                  <img
                                    onClick={() =>
                                      deleteMentorProfessions(elem?._id)
                                    }
                                    src={TrashIcon}
                                    style={{ cursor: "pointer" }}
                                    alt="trash icon"
                                    className="editschedules__editcard__container__inner__image"
                                  />

                                </div>
                              </div>
                            );
                          }
                        }
                      )}
                      <div className="btn-box">
                        <button
                          className="personalADD padding__addanother"
                          onClick={() => setAddAnotherExperienceCheck(true)}
                        >
                          <img src={squareAddWhite} alt="" />
                          <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                            Add other Professional Experience
                          </p>
                        </button>
                      </div>
                    </div>
                    {/* add  other experience end */}

                    <div className="setting__main__heading mt__62">
                      <h2
                        className="setting__heading"
                        data-title={"Account Management"}
                      >
                        Account Management
                      </h2>
                      <Form>
                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Email
                          </Form.Label>
                          <div className="change__email__main">
                            <Form.Control
                              className="height__input loginfield__type1"
                              placeholder="johndoe@fasttrack.com"
                              // type="email"
                              // value={loginData.loginEmail}
                              // name="loginEmail"
                              // onChange={(e) => handleChange(e

                              type="email"
                              value={userProfileData?.email}
                              name="email"
                              onChange={(e) => handleChnageAccount(e)}
                            />
                            <button onClick={(e) => ChangeInputValue(e)}>
                              Change
                            </button>
                          </div>
                        </Form.Group>

                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Phone number
                          </Form.Label>
                          <div className="change__email__main">
                            <PhoneInput
                              className="height__input loginfield__type1 ms-3"
                              placeholder="please enter the number"
                              //defaultCountry="RU"
                              // value={loginData.phoneNo}
                              // onChange={(e) => setPhoneNumber(e)}
                              name="phoneNo"
                              disabled={!verified}
                              value={userProfileData?.phoneNo}
                              onChange={(e) => handleChnagePhone(e)}
                            />
                            <button onClick={(e) => ChangeInputValue(e)}>
                              Change
                            </button>
                          </div>
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="setting__main__heading mt__62">
                      <h2 className="setting__heading" data-title={"Payments"}>
                        Payments
                      </h2>
                      {/* BankDetailsSectionModal*/}
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#b9bdc7",
                          marginTop: "28px",
                          marginBottom: "14px",
                        }}
                      >
                        Payment Option
                      </p>
                      {bankData?.data?.map((elem, index) => {
                        return (
                          <div className=" bankCard ">
                            <div className=" bankCard__img">
                              <img className="" src={bankLogo} alt="" />
                            </div>
                            <div className="bankCard__Detail">
                              <h1>{elem?.bank_name}</h1>
                              <p>{elem?.country}</p>
                              <p>{elem?.currency}</p>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        className="personalADD"
                        style={{ marginTop: "20px" }}
                        onClick={createStripeAccount}
                      >
                        <img src={squareAddWhite} alt="" />
                        <p className="m-0 ms-1">Add Payment Method</p>
                      </button>
                      <div className="btn__setting__mtop">
                        {/* <h1>Currency</h1> */}
                        <div className="d-flex justify-content-between align-items-center mt-4">
                          <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                            Change currency
                          </p>
                          <div className="top__right d-none d-md-block">
                            <button
                              className="btn__green__edit__services"
                              onClick={editCurrency}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      <Dropdown onSelect={handleSelectCurrency}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="height__input dropdownToSelect select__icon"
                        >
                          <p className="forumm__button__text">
                            {dropdown === "usd"
                              ? "USD"
                              : dropdown === "euro"
                                ? "EURO"
                                : dropdown === "gbp"
                                  ? "GBP"
                                  : dropdown === "chf"
                                    ? "CHF"
                                    : "Please select currency"}
                          </p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                          <Dropdown.Item
                            eventKey={"usd"}
                            // onClick={() => setDropDown("usd")}
                            className="dropdownToSelect__item"
                          >
                            {/* US Dollar - $ */}
                            USD
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="euro"
                            className="dropdownToSelect__item"
                          >
                            EURO
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={"gbp"}
                            className="dropdownToSelect__item"
                          >
                            GBP
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={"chf"}
                            className="dropdownToSelect__item"
                          >
                            CHF
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="setting__main__heading mt__62">
                      <h2
                        className="setting__heading"
                        data-title={"Password & Security"}
                      >
                        Password & Security
                      </h2>
                      <div className="DropDownLabel">
                        {mentorData?.user?.social ? (
                          <a
                            className="change__password text-capitalize"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              ErrorToast(
                                " Can not change as login through socail "
                              )
                            }
                          >
                            Click here to reset your password
                          </a>
                        ) : (
                          <Link
                            to="/email"
                            className="change__password text-capitalize"
                          >
                            Click here to reset your password
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
      <ConfirmModal show={openModalForEdit} setShow={setOpenModalForEdit}>
        <div className="networkdb__payment__modal__top">
          <h2>Please Edit Your email</h2>
          <Form.Group className="  " controlId="">
            <Form.Label className="loginfield__Label ">
              Email
            </Form.Label>
            <Form.Control
              className="loginfield__type"
              placeholder="johndoe@fasttrack.com"
              type="email"
              // value={loginData.loginEmail}
              name="loginEmail"
              // onChange={(e) => handleChange(e
            />
          </Form.Group>
        </div>
        <div className="networkdb__payment__modal__bottom">
          <button
            onClick={() => {
              setOpenModalForEdit(false);
              checkVerification();
            }}
            className="btn-green"
          >
            Update
          </button>
        </div>
      </ConfirmModal> */}
                {/* <ConfirmModal show={openModalForEdit1} setShow={setOpenModalForEdit1}>
        <div className="networkdb__payment__modal__top">
          <h2>Please Edit Your Number</h2>
          <Form.Group className=" " controlId="">
            <Form.Label className="loginfield__Label ">Phone Number</Form.Label>
            <PhoneInput
              className="loginfield__type"
              placeholder="please enter the number"
              //defaultCountry="RU"
              // value={loginData.phoneNo}
              // onChange={(e) => setPhoneNumber(e)}
            />
          </Form.Group>
        </div>
        <div className="networkdb__payment__modal__bottom">
          <button
            onClick={() => setOpenModalForEdit1(false)}
            className="btn-green"
          >
            Update
          </button>
        </div>
      </ConfirmModal> */}

                <ConfirmModal
                  show={openModalForEdit}
                  setShow={setOpenModalForEdit}
                >
                  <div className="networkdb__payment__modal__top">
                    <h2>Please Enter password to verify</h2>
                    <Form.Group className="  " controlId="">
                      <Form.Label className="loginfield__Label ">
                        Password
                      </Form.Label>
                      <Form.Control
                        className="height__input loginfield__type"
                        placeholder="Enter password"
                        type="password"
                        // value={loginData.loginEmail}
                        name="resetPassword"
                        onChange={(e) => handleChangeEmailReset(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="networkdb__payment__modal__bottom">
                    <button
                      onClick={() => {
                        setOpenModalForEdit(false);
                        checkVerification();
                      }}
                      className="btn-green"
                    >
                      Update
                    </button>
                  </div>
                </ConfirmModal>

                {/* edit Bank Info */}

                <ConfirmModal
                  show={openModalForEdit2}
                  setShow={setOpenModalForEdit2}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="ProfessionalExpDiv">
                      {bankInfo?.map((item, i) => {
                        return (
                          <div key={i}>
                            <Form.Group className=" " controlId="">
                              <Form.Label className="loginfield__Label">
                                Investment bank
                              </Form.Label>
                            </Form.Group>
                            <Dropdown
                              onSelect={(e) =>
                                handleDropdownBankChangeNew(e, i)
                              }
                            >
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="height__input dropdownToSelect select__icon"
                              >
                                <p className="forumm__button__text">
                                  {bankInfo[i]?.bank
                                    ? bankInfo[i]?.bank
                                    : "Select Investment bank"}
                                </p>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                {availableBanks.map((item, index) => (
                                  <Dropdown.Item
                                    eventKey={item}
                                    className={`dropdownToSelect__item ${item === bankInfo[i]?.bank
                                      ? "dropdownToSelect__item__checked"
                                      : ""
                                      }`}
                                    key={item}
                                  >
                                    {item}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* Old banks select */}
                            {/* <select
                              className="DropDownMentor__select select__icon"
                              name="bank"
                              value={bankInfo[i]?.bank ? bankInfo[i]?.bank : ""}
                              onChange={(e) => bankHandler(e, i)}
                            >
                              <option
                                className="DropDownMentor__select__item"
                                value={"default"}
                              >
                                Select Investment Bank
                              </option>
                              {availableBanks?.map((bank) => (
                                <option key={bank} value={bank}>
                                  {bank}
                                </option>
                              ))}
                            </select> */}
                            {/* Old banks select */}

                            <Form.Group
                              className=" loginfield__optional__margin "
                              controlId=""
                            >
                              <Form.Label className="loginfield__Label">
                                Position
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="height__input loginfield__type"
                                placeholder="Chief Accountant"
                                name="position"
                                value={bankInfo[i]?.position}
                                onChange={(e) => bankHandler(e, i)}
                              />
                            </Form.Group>
                            <div className=" datepickerRow ">
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  Start Date
                                </p>
                                <DateTimePicker
                                  format="yyyy-MM-dd"
                                  disableClock={true}
                                  className="mt__cl__16 height__input schedules__guides__contaiener__calendar"
                                  name="startDate"
                                  selected={bankInfo[i]?.startDate}
                                  value={bankInfo[i]?.startDate}
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) =>
                                    bankHandler(e, i, "startDate")
                                  }
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  End Date
                                </p>
                                <DateTimePicker
                                  format="yyyy-MM-dd"
                                  disableClock={true}
                                  className="mt__cl__16 schedules__guides__contaiener__calendar height__input"
                                  selected={bankInfo[i]?.endDate}
                                  value={bankInfo[i]?.endDate}
                                  name="endDate"
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) => bankHandler(e, i, "endDate")}
                                  disabled={bankInfo[i]?.currentlyEmployed}
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                            </div>
                            <label
                              id="updatebankmentorrr"
                              className="personalCheckbox "
                            >
                              <Form.Check
                                name="updatebankmentorrr"
                                id="updatebankmentorrr"
                                aria-label="check experience"
                                checked={isBankCheck[i]}
                                onChange={() => handleCheckboxChangeBank(i)}
                              />
                              <p>I am still working at this company</p>
                            </label>
                            <div>
                              <div className="cancelbtn-container">
                                {bankInfo.length > 1 && i > 0 && (
                                  <button
                                    className="btn-cancel"
                                    onClick={(e) => handleRemoveBankClick(e, i)}
                                  >
                                    <p>Remove</p>
                                    <img src={RemoveIcon} />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <button
                        className="personalADD"
                        onClick={handleAddAnotherBank}
                      >
                        <img src={squareAddWhite} alt="" />
                        <p className="m-0">Add another Bank</p>
                      </button> */}
                      <button
                        className="login__green__button"
                        onClick={(e) => {
                          setOpenModalForEdit(false);
                          handleBankSubmit();
                          UpdateBankAPIcall(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* <div className="networkdb__payment__modal__bottom">
                  <button
                        className="login__green__button"
                        
                        onClick={()=>{
                          setOpenModalForEdit(false);
                          handleBankSubmit();
                        }
                    
                        }
                      >
                        Next
                      </button>
                </div> */}
                </ConfirmModal>

                {/* edit EDucation Modal */}

                <ConfirmModal
                  show={openModalForEdit3}
                  setShow={setOpenModalForEdit3}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="EducationDiv">
                      {educationInfo?.map((item, i) => {
                        return (
                          <div key={i}>
                            <Form.Group className="">
                              <Form.Label
                                className="loginfield__Label"
                                htmlFor={"programme"}
                              >
                                Degree
                              </Form.Label>
                              <Dropdown onSelect={(e) => handleAddDropDownProgrammeForEdit(e, i)}>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="height__input dropdownToSelect select__icon"
                                >
                                  <p className="forumm__button__text">
                                    {educationInfo[i]?.programme
                                      ? educationInfo[i]?.programme
                                      : "Please select degree"}
                                  </p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                  <Dropdown.Item
                                    eventKey={"MBA"}
                                    className="dropdownToSelect__item"
                                  >
                                    MBA
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey={"Bachelors"}
                                    className="dropdownToSelect__item"
                                  >
                                    Bachelors
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey={"Masters"}
                                    className="dropdownToSelect__item  "
                                  >
                                    Masters
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                            </Form.Group>
                            <Form.Group className="  " controlId="">
                              <Form.Label className="loginfield__Label">
                                Institution
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="height__input loginfield__type"
                                placeholder="Crowder University"
                                value={educationInfo[i]?.institution}
                                name="institution"
                                onChange={(e) => educationHandler(e, i)}
                              />
                            </Form.Group>

                            <Form.Group
                              className=" loginfield__optional__margin "
                              controlId=""
                            >
                              <Form.Label className="loginfield__Label">
                                Field of study
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="height__input loginfield__type"
                                placeholder="Accounting"
                                value={educationInfo[i]?.degree}
                                name="degree"
                                onChange={(e) => educationHandler(e, i)}
                              />
                            </Form.Group>

                            <div className=" datepickerRow ">
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  Start Date
                                </p>
                                <DateTimePicker
                                  format="MMM dd, yyyy"
                                  disableClock={true}
                                  className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                                  name="startDate"
                                  selected={educationInfo[i]?.startDate}
                                  value={educationInfo[i]?.startDate}
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) =>
                                    educationHandler(e, i, "startDate")
                                  }
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  End Date
                                </p>
                                <DateTimePicker
                                  format="MMM dd, yyyy"
                                  disableClock={true}
                                  className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                                  selected={educationInfo[i]?.endDate}
                                  value={educationInfo[i]?.endDate}
                                  // name="endDate"
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) =>
                                    educationHandler(e, i, "endDate")
                                  }
                                  disabled={educationInfo[i]?.currentlyStuding}
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                            </div>
                            <label
                              className="personalCheckbox "
                              id="mentorsettingediteduadsada"
                            >
                              <Form.Check
                                id="mentorsettingediteduadsada"
                                name="mentorsettingediteduadsada"
                                aria-label="option 1"
                                checked={isEducationCheck[i]}
                                onChange={() => handleCheckboxChange(i)}
                              />
                              <p>I am still attending this institution</p>
                            </label>
                            <div>
                              <div className="cancelbtn-container">
                                {educationInfo.length > 1 && i > 0 && (
                                  <button
                                    className="btn-cancel"
                                    onClick={(e) =>
                                      handleRemoveEducationClick(e, i)
                                    }
                                  >
                                    <p>Remove</p>
                                    <img src={RemoveIcon} />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <button
                        className="login__green__button "
                        // onClick={handleEducationSubmit}
                        onClick={(e) => {
                          setOpenModalForEdit(false);
                          handleEducationSubmit();
                          UpdateEducationAPIcall(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ConfirmModal>

                {/* edit professional Exp */}
                <ConfirmModal
                  show={openModalForEdit4}
                  setShow={setOpenModalForEdit4}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="ProfessionalExpDiv">
                      {/* <label htmlFor="cvupload" className="w-100">
                        <div
                          className={`mt__0__tab ${
                            cv.length > 0
                              ? "resume__button__success"
                              : "resume__button"
                          }`}
                        >
                          {cv.length > 0 ? (
                            "CV Uploaded Successfully"
                          ) : (
                            <>
                              <img src={cvIcon} alt=""></img> Attach Resume or
                              CV
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          accept="application/pdf"
                          className="d-none"
                          id="cvupload"
                          onChange={(e) => handleCVUpload(e)}
                        />
                      </label> */}
                      {experienceInfo?.map((item, i) => {
                        return (
                          <div key={i}>
                            <Form.Group className="  " controlId="">
                              <Form.Label className="loginfield__Label">
                                Company
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="height__input loginfield__type"
                                placeholder="Facebook"
                                value={experienceInfo[i]?.company}
                                name="company"
                                onChange={(e) => experienceHandler(e, i)}
                              />
                            </Form.Group>
                            <Form.Group
                              className=" loginfield__optional__margin "
                              controlId=""
                            >
                              <Form.Label className="loginfield__Label">
                                Position
                              </Form.Label>
                              <Form.Control
                                type=" password"
                                className="height__input loginfield__type"
                                placeholder="Chief Accountant"
                                value={experienceInfo[i]?.position}
                                name="position"
                                onChange={(e) => experienceHandler(e, i)}
                              />
                            </Form.Group>

                            <div className=" datepickerRow ">
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  Start Date
                                </p>
                                <DateTimePicker
                                  format="yyyy-MM-dd"
                                  disableClock={true}
                                  className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                                  name="startDate"
                                  selected={experienceInfo[i]?.startDate}
                                  value={experienceInfo[i]?.startDate}
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) =>
                                    experienceHandler(e, i, "startDate")
                                  }
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                              <div className="datepickerRow__inner">
                                <p className="datepickerRow__inner__text">
                                  End Date
                                </p>
                                <DateTimePicker
                                  format="yyyy-MM-dd"
                                  disableClock={true}
                                  className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                                  selected={experienceInfo[i]?.endDate}
                                  value={experienceInfo[i]?.endDate}
                                  name="endDate"
                                  placeholder="Select expiration date..."
                                  calendarIcon={
                                    <img
                                      src={CalendarEaxct}
                                      alt="Calendar Icon"
                                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                                    />
                                  }
                                  onChange={(e) =>
                                    experienceHandler(e, i, "endDate")
                                  }
                                  disabled={
                                    experienceInfo[i]?.currentlyEmployed
                                  }
                                //  minDate={moment()?.toDate()}
                                />
                              </div>
                            </div>
                            <label
                              className="personalCheckbox "
                              id="mentorperosonlaeditrpeossse"
                            >
                              <Form.Check
                                id="mentorperosonlaeditrpeossse"
                                name="mentorperosonlaeditrpeossse"
                                aria-label="check experience"
                                checked={isExperienceCheck[i]}
                                onChange={() =>
                                  handleCheckboxChangeExperience(i)
                                }
                              />
                              <p>I am still working at this company</p>
                            </label>
                            <div>
                              <div className="cancelbtn-container">
                                {experienceInfo.length > 1 && i > 0 && (
                                  <button
                                    className="btn-cancel"
                                    onClick={(e) =>
                                      handleRemoveExperienceClick(e, i)
                                    }
                                  >
                                    <p>Remove</p>
                                    <img src={RemoveIcon} />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <button
                          className="personalADD"
                          onClick={handleAddAnotherExperience}
                        >
                          <img src={squareAddWhite} alt="" />
                          <p className="m-0">Add another education</p>
                        </button> */}
                      <button
                        className="login__green__button "
                        // onClick={handleExperienceSubmit}
                        onClick={(e) => {
                          setOpenModalForEdit(false);
                          // handleSubmit(e);
                          UpdateProfessionAPIcall(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ConfirmModal>

                {/* Add another Education */}
                <ConfirmModal
                  show={addAnotherEducationCheck}
                  setShow={setAddAnotherEducationCheck}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="EducationDiv">
                      <div>
                        <Form.Group className="">
                          <Form.Label
                            className="loginfield__Label"
                            htmlFor={"programme"}
                          >
                            Degree
                          </Form.Label>
                          <Dropdown onSelect={handleAddDropDownProgramme}>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="height__input dropdownToSelect select__icon"
                            >
                              <p className="forumm__button__text">
                                {addPropdownProgramme
                                  ? addPropdownProgramme
                                  : "Please select degree"}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              <Dropdown.Item
                                eventKey={"MBA"}
                                className="dropdownToSelect__item"
                              >
                                MBA
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey={"Bachelors"}
                                className="dropdownToSelect__item"
                              >
                                Bachelors
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey={"Masters"}
                                className="dropdownToSelect__item  "
                              >
                                Masters
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* Old select for programme */}
                          {/* <select
                  className="DropDownMentor__select"
                  name="programme"
                  id="programme"
                  value={addPropdownProgramme}
                  onChange={(e) => handleAddDropDownProgramme(e)}
                >
                  <option
                    className="DropDownMentor__select__item"
                    value={"All"}
                  >
                    Please select a programme
                  </option>
                  <option className="DropdownMain__menu__item" value="MBA">
                    MBA
                  </option>
                  <option
                    className="DropDownMentor__select__item"
                    value="Bachelors"
                  >
                    Bachelors
                  </option>
                  <option
                    className="DropDownMentor__select__item"
                    value="Masters"
                  >
                    Masters
                  </option>
                </select> */}
                          {/* Old select for programme */}
                        </Form.Group>
                        <Form.Group className="  " controlId="">
                          <Form.Label className="loginfield__Label">
                            Institution
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="height__input loginfield__type"
                            placeholder="Crowder University"
                            value={addAnotherEducationInfo.institution}
                            name="institution"
                            onChange={(e) => AddEducationHandler(e)}
                          />
                        </Form.Group>

                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label">
                            Field of study
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="height__input loginfield__type"
                            placeholder="Accounting"
                            value={addAnotherEducationInfo.degree}
                            name="degree"
                            onChange={(e) => AddEducationHandler(e)}
                          />
                        </Form.Group>

                        <div className=" datepickerRow ">
                          <div className="datepickerRow__inner">
                            <p className="datepickerRow__inner__text">
                              Start Date
                            </p>
                            <DateTimePicker
                              format="MM-dd-yyyy"
                              disableClock={true}
                              className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                              name="startDate"
                              selected={addAnotherEducationInfo.startDate}
                              value={addAnotherEducationInfo.startDate}
                              placeholder="Select expiration date..."
                              calendarIcon={
                                <img
                                  src={CalendarEaxct}
                                  alt="Calendar Icon"
                                  style={{ width: "20px", height: "20px" }} // Set the size as needed
                                />
                              }
                              onChange={(e) =>
                                AddEducationHandler(e, "startDate")
                              }
                            //  minDate={moment()?.toDate()}
                            />
                          </div>
                          <div className="datepickerRow__inner">
                            <p className="datepickerRow__inner__text">
                              End Date
                            </p>
                            <DateTimePicker
                              format="MM-dd-yyyy"
                              disableClock={true}
                              className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                              selected={addAnotherEducationInfo.endDate}
                              value={addAnotherEducationInfo.endDate}
                              // name="endDate"
                              placeholder="Select expiration date..."
                              calendarIcon={
                                <img
                                  src={CalendarEaxct}
                                  alt="Calendar Icon"
                                  style={{ width: "20px", height: "20px" }} // Set the size as needed
                                />
                              }
                              onChange={(e) =>
                                AddEducationHandler(e, "endDate")
                              }
                              disabled={
                                addAnotherEducationInfo.currentlyStuding
                              }
                            //  minDate={moment()?.toDate()}
                            />
                          </div>
                        </div>
                        <label
                          htmlFor="addprofileandothereducations"
                          className="personalCheckbox "
                        >
                          <Form.Check
                            id="addprofileandothereducations"
                            name="addprofileandothereducations"
                            aria-label="option 1"
                            checked={isAddEducationCheck}
                            onChange={() => handleAddEducationCheckboxChange()}
                          />
                          <p>I am still attending this institution</p>
                        </label>
                      </div>
                      <button
                        className="login__green__button "
                        onClick={handleAddAnotherEducationSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ConfirmModal>

                {/* add another bank */}
                <ConfirmModal
                  show={openModalForEdit5}
                  setShow={setOpenModalForEdit5}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="ProfessionalExpDiv">
                      <div>
                        <Form.Group className="  " controlId="">
                          <Form.Label className="loginfield__Label">
                            Investment bank
                          </Form.Label>
                        </Form.Group>
                        <Dropdown onSelect={(e) => handleAddBankDropdown(e)}>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="dropdownToSelect height__input select__icon"
                          >
                            <p className="forumm__button__text">
                              {addAnotherBankInfo?.bank
                                ? addAnotherBankInfo?.bank
                                : "Select Investment Bank"}
                            </p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                            {availableBanks.map((item, index) => (
                              <Dropdown.Item
                                eventKey={item}
                                className={`dropdownToSelect__item ${item === addAnotherBankInfo?.bank
                                  ? "dropdownToSelect__item__checked"
                                  : ""
                                  }`}
                                key={item}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label">
                            Position
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="loginfield__type height__input"
                            placeholder="Chief Accountant"
                            name="position"
                            value={addAnotherBankInfo?.position}
                            onChange={(e) => AddBankHandler(e)}
                          />
                        </Form.Group>
                        <div className=" datepickerRow ">
                          <div className="datepickerRow__inner">
                            <p className="datepickerRow__inner__text">
                              Start Date
                            </p>
                            <DateTimePicker
                              format="yyyy-MM-dd"
                              disableClock={true}
                              className="schedules__guides__contaiener__calendar mt__cl__16  height__input"
                              name="startDate"
                              selected={addAnotherBankInfo?.startDate}
                              value={addAnotherBankInfo?.startDate}
                              placeholder="Select expiration date..."
                              calendarIcon={
                                <img
                                  src={CalendarEaxct}
                                  alt="Calendar Icon"
                                  style={{ width: "20px", height: "20px" }} // Set the size as needed
                                />
                              }
                              onChange={(e) => AddBankHandler(e, "startDate")}
                            //  minDate={moment()?.toDate()}
                            />
                          </div>
                          <div className="datepickerRow__inner">
                            <p className="datepickerRow__inner__text">
                              End Date
                            </p>
                            <DateTimePicker
                              format="yyyy-MM-dd"
                              disableClock={true}
                              className="schedules__guides__contaiener__calendar mt__cl__16   height__input"
                              selected={addAnotherBankInfo?.endDate}
                              value={addAnotherBankInfo?.endDate}
                              name="endDate"
                              placeholder="Select expiration date..."
                              calendarIcon={
                                <img
                                  src={CalendarEaxct}
                                  alt="Calendar Icon"
                                  style={{ width: "20px", height: "20px" }} // Set the size as needed
                                />
                              }
                              onChange={(e) => AddBankHandler(e, "endDate")}
                              disabled={addAnotherBankInfo?.currentlyEmployed}
                            //  minDate={moment()?.toDate()}
                            />
                          </div>
                        </div>
                        <label
                          id="anotherbankmentorrr"
                          className="personalCheckbox "
                        >
                          <Form.Check
                            id="anotherbankmentorrr"
                            name="anotherbankmentorrr"
                            aria-label="check experience"
                            checked={isAddBankCheck}
                            onChange={handleAddBankCheckboxChange}
                          />
                          <p>I am still working at this company</p>
                        </label>
                      </div>
                      <button
                        className="login__green__button"
                        onClick={(e) => {
                          handleAddAnotherBankSubmit();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ConfirmModal>

                {/* Add another Experience */}
                <ConfirmModal
                  show={addAnotherExperienceCheck}
                  setShow={setAddAnotherExperienceCheck}
                >
                  <div
                    className="networkdb__payment__modal__top"
                    style={{ border: "0px" }}
                  >
                    <div className="EducationDiv">
                      <Form.Group className="  " controlId="">
                        <Form.Label className="loginfield__Label">
                          Company
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="height__input loginfield__type"
                          placeholder="Facebook"
                          value={addAnotherExperienceInfo.company}
                          name="company"
                          onChange={(e) => AddExperienceHandler(e)}
                        />
                      </Form.Group>
                      <Form.Group
                        className=" loginfield__optional__margin "
                        controlId=""
                      >
                        <Form.Label className="loginfield__Label">
                          Position
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="height__input loginfield__type"
                          placeholder="Chief Accountant"
                          value={addAnotherExperienceInfo?.position}
                          name="position"
                          onChange={(e) => AddExperienceHandler(e)}
                        />
                      </Form.Group>
                      <div className=" datepickerRow ">
                        <div className="datepickerRow__inner">
                          <p className="datepickerRow__inner__text">
                            Start Date
                          </p>
                          <DateTimePicker
                            format="yyyy-MM-dd"
                            disableClock={true}
                            className="schedules__guides__contaiener__calendar mt__cl__16 height__input"
                            name="startDate"
                            selected={addAnotherExperienceInfo?.startDate}
                            value={addAnotherExperienceInfo?.startDate}
                            placeholder="Select expiration date..."
                            calendarIcon={
                              <img
                                src={CalendarEaxct}
                                alt="Calendar Icon"
                                style={{ width: "20px", height: "20px" }} // Set the size as needed
                              />
                            }
                            onChange={(e) =>
                              AddExperienceHandler(e, "startDate")
                            }
                          //  minDate={moment()?.toDate()}
                          />
                        </div>
                        <div className="datepickerRow__inner">
                          <p className="datepickerRow__inner__text">End Date</p>
                          <DateTimePicker
                            format="yyyy-MM-dd"
                            disableClock={true}
                            className="schedules__guides__contaiener__calendar mt__cl__16 height__input"
                            selected={addAnotherExperienceInfo?.endDate}
                            value={addAnotherExperienceInfo?.endDate}
                            name="endDate"
                            placeholder="Select expiration date..."
                            calendarIcon={
                              <img
                                src={CalendarEaxct}
                                alt="Calendar Icon"
                                style={{ width: "20px", height: "20px" }} // Set the size as needed
                              />
                            }
                            onChange={(e) => AddExperienceHandler(e, "endDate")}
                            disabled={
                              addAnotherExperienceInfo.currentlyEmployed
                            }
                          //  minDate={moment()?.toDate()}
                          />
                        </div>
                      </div>
                      <label
                        className="personalCheckbox "
                        id="addmentorexpericensas"
                      >
                        <Form.Check
                          id="addmentorexpericensas"
                          name="addmentorexpericensas"
                          aria-label="check experience"
                          checked={isAddExperienceCheck}
                          onChange={() => handleAddExperienceCheckboxChange()}
                        />
                        <p>I am still working at this company</p>
                      </label>
                      <button
                        className="login__green__button "
                        onClick={handleAddAnotherExperienceSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ConfirmModal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsletterFooter />
    </div>
  );
};

export default MentorSetting;
