import React, { useState } from "react";
import LeftArrow from "../../../../assets/arrow-left.svg";
import ScreenLoader from "../../../Loader/ScreenLoader";
import "./EditSchedules.scss";
import RangeSlider from "react-bootstrap-range-slider";
import { useSelector, useDispatch } from "react-redux";
import {
  mentorUpdateServices,
  mentorDeleteServicesOfGeneralGuidance,
  mentorDeleteServiceCVReviews,
  mentorDeleteServicesInterviewPractice,
  mentorDeleteServicesCoverLetterReview,
} from "../../../../service/redux/middleware/mentor";
import { useHistory } from "react-router-dom";
import { viewMentor } from "../../../../service/redux/middleware/mentor";
import { ErrorToast, SuccessToast } from "../../../MyToaster";
import TimeRangeSlider from "react-time-range-slider";
import ReactSimpleRange from "react-simple-range";
import { Slider } from "primereact/slider";
import minus from "../../../../assets/minus.svg";
import plusWhite from "../../../../assets/plusWhite.svg";
import deletetrash from "../../../../assets/deletetrash.svg";
import addplus from "../../../../assets/addplus.svg";

const AddSchedules = ({ setShowAddSchedules, setMentorData, mentorData }) => {
  const [isVisibleGeneralGuidance, setIsVisibleGeneralGuidance] =
    useState(false);
  const [isVisibleCvReview, setIsVisibleCvReview] = useState(false);
  const [isVisibleInterviewPractice, setIsVisibleInterviewPractice] =
    useState(false);
  const [isVisibleCoverLetterReview, setIsVisibleCoverLetterReview] =
    useState(false);

  const [saveBtnGeneralGuidance, setSaveBtnGeneralGuidance] = useState(false);
  const [saveBtnCvReview, setSaveBtnCvReview] = useState(false);
  const [saveBtnInterviewPractice, setSaveBtnInterviewPractice] =
    useState(false);
  const [saveBtnCoverLetterReview, setSaveBtnCoverLetterReview] =
    useState(false);

  // const [saveBtn, setSaveBtn] = useState(false)
  // const [saveBtn, setSaveBtn] = useState(false)
  // const [saveBtn, setSaveBtn] = useState(false)

  const toggleVisibilityGeneralGuidance = () => {
    setIsVisibleGeneralGuidance(!isVisibleGeneralGuidance);
    setIsVisibleCvReview(false);
    setIsVisibleInterviewPractice(false);
    setIsVisibleCoverLetterReview(false);
    setSaveBtnGeneralGuidance(true);
    setSaveBtnCvReview(false);
    setSaveBtnInterviewPractice(false);
    setSaveBtnCoverLetterReview(false);
  };
  const toggleVisibilityCvReview = () => {
    setIsVisibleGeneralGuidance(false);
    setIsVisibleCvReview(!isVisibleCvReview);
    setIsVisibleInterviewPractice(false);
    setIsVisibleCoverLetterReview(false);
    setSaveBtnGeneralGuidance(false);
    setSaveBtnCvReview(true);
    setSaveBtnInterviewPractice(false);
    setSaveBtnCoverLetterReview(false);
  };

  const toggleVisibilityInterviewPractice = () => {
    setIsVisibleGeneralGuidance(false);
    setIsVisibleCvReview(false);
    setIsVisibleInterviewPractice(!isVisibleInterviewPractice);
    setIsVisibleCoverLetterReview(false);
    setSaveBtnGeneralGuidance(false);
    setSaveBtnCvReview(false);
    setSaveBtnInterviewPractice(true);
    setSaveBtnCoverLetterReview(false);
  };

  const toggleVisibilityCoverLetterReview = () => {
    setIsVisibleGeneralGuidance(false);
    setIsVisibleCvReview(false);
    setIsVisibleInterviewPractice(false);
    setIsVisibleCoverLetterReview(!isVisibleCoverLetterReview);
    setSaveBtnGeneralGuidance(false);
    setSaveBtnCvReview(false);
    setSaveBtnInterviewPractice(false);
    setSaveBtnCoverLetterReview(true);
  };

  const [loader, setLoader] = useState(false);
  const [updatePriceData, setUpdatePriceData] = useState({
    background: mentorData[0]?.mentorPricing[0]?.summary
      ? mentorData[0]?.mentorPricing[0]?.summary
      : "",
    //   generalGuidance: mentorData[0]?.mentorPricing[0]?.generalGuidanceDescription
    //     ? mentorData[0]?.mentorPricing[0]?.generalGuidanceDescription
    //     : "",
    //   cvReview: mentorData[0]?.mentorPricing[0]?.cvReviewDescription
    //     ? mentorData[0]?.mentorPricing[0]?.cvReviewDescription
    //     : "",
    //   interviewPractice: mentorData[0]?.mentorPricing[0]
    //     ?.interviewPracticeDescription
    //     ? mentorData[0]?.mentorPricing[0]?.interviewPracticeDescription
    //     : "",
    //   coverLetter: mentorData[0]?.mentorPricing[0]?.coverLetterDescription
    //     ? mentorData[0]?.mentorPricing[0]?.coverLetterDescription
    //     : "",
  });
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const handleChange = (e) => {
    updatePriceData[e.target.name] = e.target.value;
    setUpdatePriceData({ ...updatePriceData });
  };
  const data1 = {
    userId: userData?._id,
    token: token,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      mentorId: userData?._id,
      summary: updatePriceData?.background,
      // generalGuidance: generalGuidanceValue,
      // cvReview: cvReviewValue,
      // interviewPractice: interviewPracticeValue,
      // coverLetter: coverLetterValue,
      token: token,
    };
    setLoader(true);
    dispatch(mentorUpdateServices(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Service updated successfully");
            setMentorData(res?.payload?.data);
            // setShowAddSchedules(false);
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  // showAddDate ? (
  //   <AddDate setShowAddDate={setShowAddDate} />
  // ) :

  const [generalGuidanceValue, setGeneralGuidanceValue] = useState(
    mentorData[0]?.mentorPricing[0]?.generalGuidance ? mentorData[0]?.mentorPricing[0]?.generalGuidance : 50
  );

  const [cvReviewValue, setCvReviewValue] = useState(
    mentorData[0]?.mentorPricing[0]?.cvReview
      ? mentorData[0]?.mentorPricing[0]?.cvReview
      : 50
  );

  const [interviewPracticeValue, setInterviewPracticeValue] = useState(
    mentorData[0]?.mentorPricing[0]?.interviewPractice
      ? mentorData[0]?.mentorPricing[0]?.interviewPractice
      : 50
  );

  const [coverLetterValue, setCoverLetterValue] = useState(
    mentorData[0]?.mentorPricing[0]?.coverLetter
      ? mentorData[0]?.mentorPricing[0]?.coverLetter
      : 50
  );

  const deleteGeneralGuidance = () => {
    const data = {
      mentorId: userData?._id,
      generalGuidance: 0,
      token: token,
    };
    setLoader(true);
    dispatch(mentorDeleteServicesOfGeneralGuidance(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Service deleted successfully");
            dispatch(viewMentor(data1)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setGeneralGuidanceValue(50);
                setLoader(false);
                console.log(
                  generalGuidanceValue,
                  mentorData[0]?.mentorPricing[0]?.generalGuidance,
                  "this is mentor data"
                );
              } else {
                setLoader(false);
                // ErrorToast(res?.payload?.message);
              }
            });
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  console.log(
    generalGuidanceValue,
    mentorData[0]?.mentorPricing[0]?.generalGuidance,
    "this is mentor data"
  );

  const deleteCVReview = () => {
    const data = {
      mentorId: userData?._id,
      cvReview: 0,
      token: token,
    };
    setLoader(true);
    dispatch(mentorDeleteServiceCVReviews(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            SuccessToast("Service deleted successfully");

            // SuccessToast(resi?.payload?.data?.success);
            dispatch(viewMentor(data1)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setCvReviewValue(50);
                setLoader(false);
              } else {
                setLoader(false);
                // ErrorToast(res?.payload?.message);
              }
            });
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  const deleteInterviewPractice = () => {
    const data = {
      mentorId: userData?._id,
      interviewPractice: 0,
      token: token,
    };
    setLoader(true);
    dispatch(mentorDeleteServicesInterviewPractice(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Service deleted successfully");

            dispatch(viewMentor(data1)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setInterviewPracticeValue(50);
                setLoader(false);
              } else {
                setLoader(false);
                // ErrorToast(res?.payload?.message);
              }
            });
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  const deleteCoverLetterReview = () => {
    const data = {
      mentorId: userData?._id,
      coverLetter: 0,
      token: token,
    };
    setLoader(true);
    dispatch(mentorDeleteServicesCoverLetterReview(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);
            SuccessToast("Service deleted successfully");

            dispatch(viewMentor(data1)).then((res) => {
              if (res?.payload?.status === 200) {
                setMentorData(res?.payload?.data);
                setCoverLetterValue(50);
                setLoader(false);
              } else {
                setLoader(false);
                // ErrorToast(res?.payload?.message);
              }
            });
            // setShowAddSchedules(false);
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        // ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  const saveGeneralGuidance = () => {
    const data = {
      mentorId: userData?._id,
      generalGuidance: generalGuidanceValue,
      token: token,
    };
    if (generalGuidanceValue >= 50) {
      setLoader(true);
      dispatch(mentorUpdateServices(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          setLoader(false);
          dispatch(viewMentor(data1)).then((res) => {
            if (res?.payload?.status === 200) {
              // SuccessToast(resi?.payload?.data?.success);
              SuccessToast("Service updated successfully");
              setMentorData(res?.payload?.data);
              setSaveBtnGeneralGuidance(false);
              // setShowAddSchedules(false);
              setLoader(false);
            } else {
              setLoader(false);
              // ErrorToast(res?.payload?.message);
            }
          });
        } else {
          // ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Value should be between 50 and 400");
    }
  };

  const saveCvReview = () => {
    const data = {
      mentorId: userData?._id,
      cvReview: cvReviewValue,
      token: token,
    };
    if (cvReviewValue >= 50) {
      setLoader(true);
      dispatch(mentorUpdateServices(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          setLoader(false);
          dispatch(viewMentor(data1)).then((res) => {
            if (res?.payload?.status === 200) {
              // SuccessToast(resi?.payload?.data?.success);
              SuccessToast("Service updated successfully");

              setMentorData(res?.payload?.data);
              setSaveBtnCvReview(false);
              // setShowAddSchedules(false);
              setLoader(false);
            } else {
              setLoader(false);
              // ErrorToast(res?.payload?.message);
            }
          });
        } else {
          // ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Value should be between 50 and 400");
    }
  };

  const saveInterviewPractice = () => {
    const data = {
      mentorId: userData?._id,
      interviewPractice: interviewPracticeValue,
      token: token,
    };
    if (interviewPracticeValue >= 50) {
      setLoader(true);
      dispatch(mentorUpdateServices(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          setLoader(false);
          dispatch(viewMentor(data1)).then((res) => {
            if (res?.payload?.status === 200) {
              // SuccessToast(resi?.payload?.data?.success);
              SuccessToast("Service updated successfully");
              setMentorData(res?.payload?.data);
              setSaveBtnInterviewPractice(false);
              // setShowAddSchedules(false);
              setLoader(false);
            } else {
              setLoader(false);
              // ErrorToast(res?.payload?.message);
            }
          });
        } else {
          // ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Value should be between 50 and 400");
    }
  };

  const saveCoverLetter = () => {
    const data = {
      mentorId: userData?._id,
      coverLetter: coverLetterValue,
      token: token,
    };
    if (coverLetterValue >= 50) {
      setLoader(true);
      dispatch(mentorUpdateServices(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          setLoader(false);
          dispatch(viewMentor(data1)).then((res) => {
            if (res?.payload?.status === 200) {
              // SuccessToast(resi?.payload?.data?.success);
              SuccessToast("Service updated successfully");

              setMentorData(res?.payload?.data);
              setSaveBtnCoverLetterReview(false);
              // setShowAddSchedules(false);
              setLoader(false);
            } else {
              setLoader(false);
              // ErrorToast(res?.payload?.message);
            }
          });
        } else {
          // ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Value should be between 50 and 400");
    }
  };

  console.log(
    interviewPracticeValue,
    mentorData[0]?.mentorPricing[0]?.interviewPractice,
    "this is this ssssssss"
  );

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="editschedules__container dashboard__container__d container__custom">
        <div className="editschedules__container__top">
          <div
            className="editschedules__container__top__container"
            onClick={() => setShowAddSchedules(false)}
          >
            <img
              src={LeftArrow}
              className="editschedules__container__top__container__image"
              alt="left arrow"
            />
            <p className="editschedules__container__top__container__text marginTop">
              Back to Mentor dashboard
            </p>
          </div>

          <div className="top__right d-none d-md-block">
            {/* <button
              className="btn-green"
              onClick={(e) => handleSubmit(e)}
            >
              Save
            </button> */}
            {/* <button className="btn-grey">Cancel</button>  */}
          </div>
        </div>

        {/* <div className="editschedules__container__bottom">
        <Row className="editschedules__container__bottom__container">
          <Col style={{ padding: "0px" }} md={6}>
            <div className="editschedules__container__bottom__container__top">
              <button
                onClick={() => setShowAddDate(!showAddDate)}
                className="btn-green editschedules__container__bottom__container__top__button"
              >
                <p>Add a Date</p>
                <img src={AddIcon} alt="calendar" />
              </button>
              <div className="editschedules__container__bottom__container__top__bottom">
                <h2 className="editschedules__container__bottom__container__top__bottom__title">
                  Available Dates
                </h2>
                {[1, 2, 3, 4].map((item) => (
                  <div className="editschedules__editcard__container">
                    <p className="editschedules__editcard__container__text">
                      11:15am – 12:15pm, August 25, 2023
                    </p>
                    <div className="editschedules__editcard__container__inner">
                      <img
                        src={EditIcon}
                        alt="edit icon"
                        className="editschedules__editcard__container__inner__image"
                      />
                      <img
                        src={TrashIcon}
                        alt="trash icon"
                        className="editschedules__editcard__container__inner__image"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col style={{ padding: "0px" }}>
            <div className="editschedules__container__bottom__container__bottom">
              <h2 className="editschedules__container__bottom__container__bottom__title">
                Calendar
              </h2>
              <div>
                <Calendar
                  onChange={setDate}
                  value={date}
                  className="myavailability__left__center__calendar"
                   minDate={new Date()}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div> */}

        <div className="EditServices__main row">
          <div className="col-md-8 col-xl-7 p-0 last__range">
            <div className="EditServices__main__heading ">Edit services</div>
            <div className="EditServices__main__body line__bottom__about">
              <div className="d-flex justify-content-between align-items-center mt-4">
                <h1>About</h1>
                <div className="top__right d-none d-md-block">
                  <button
                    className="btn__green__edit__services"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
              <textarea
                type="text"
                placeholder="Tell candidates about yourself and your background. Explain them why you are the right mentor for them"
                className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                style={{
                  marginTop: "12px",
                  marginBottom: "40px",
                  color: "#989FAD",
                }}
                value={updatePriceData?.background}
                name="background"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={isVisibleGeneralGuidance ? { borderBottom: "0px", paddingBottom: "0px" } : { borderBottom: "1px solid #2f3033", paddingBottom: "22px" }}
              className="MentorInfoSecForm__footer"
              onClick={toggleVisibilityGeneralGuidance}
            >
              <h1>
                General guidance{" "}
                {mentorData[0]?.mentorPricing[0]?.generalGuidance > 0 && (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300px",
                      color: "#3dd7a1",
                    }}
                  >
                    ({" "}
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {mentorData[0]?.mentorPricing[0]?.generalGuidance} )
                  </span>
                )}
              </h1>
              <div className="MentorInfoSecForm__footer__container">
                {generalGuidanceValue ===
                  mentorData[0]?.mentorPricing[0]?.generalGuidancee &&
                  mentorData[0]?.mentorPricing[0]?.generalGuidance > 0 ? (
                  <>
                    <img
                      src={deletetrash}
                      alt=""
                      onClick={deleteGeneralGuidance}
                    />
                  </>
                ) : (
                  <>
                    {generalGuidanceValue ===
                      mentorData[0]?.mentorPricing[0]?.generalGuidance &&
                      mentorData[0]?.mentorPricing[0]?.generalGuidance > 0 ? (
                      <img
                        src={deletetrash}
                        alt=""
                        onClick={deleteGeneralGuidance}
                      />
                    ) : (
                      <>
                        {generalGuidanceValue !== 50 || isVisibleGeneralGuidance &&
                          (generalGuidanceValue >
                            mentorData[0]?.mentorPricing[0]?.generalGuidance ||
                            generalGuidanceValue <
                            mentorData[0]?.mentorPricing[0]?.generalGuidance) ? (
                          <div className="top__right d-none d-md-block">
                            {" "}
                            <button
                              className="btn__green__edit__services"
                              onClick={saveGeneralGuidance}
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        ) : (
                          <button className="servicesbutton">
                            {" "}
                            <img src={addplus} alt="" />
                            <p>Add service</p>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {isVisibleGeneralGuidance && (
              <div className="EditServices__cardRows">
                {/* <div className="EditServices__cardRows__heading ">
                  General Guidance
                </div> */}
                {/* <div className="EditServices__cardRows__body ">
                  <h1>Description</h1>
                  <textarea
                    type="text"
                    placeholder="Why choose your general guidance session?"
                    className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                    style={{ marginTop: "12px", color: "#989FAD" }}
                    value={updatePriceData?.generalGuidance}
                    name="generalGuidance"
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}
                <div className="rangeSec">
                  <h1 className="rangeSec__label">Price per session</h1>
                  <h2 className="rangeSec__price">
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {generalGuidanceValue}
                  </h2>
                  <Slider
                    value={generalGuidanceValue}
                    onChange={(e) => setGeneralGuidanceValue(e.value)}
                    max="400"
                    min="0"
                    step={50}
                  />
                  <div className="rangeSec__pricevalue">
                    <h1>
                      {/* Min £{generalGuidanceValue ? generalGuidanceValue : "0"} */}
                      Min{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      50
                    </h1>
                    <h1>
                      Max{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      400
                    </h1>
                  </div>
                </div>
              </div>
            )}

            <div
              style={isVisibleCvReview ? { borderBottom: "0px", paddingBottom: "0px" } : { borderBottom: "1px solid #2f3033", paddingBottom: "22px" }}
              className="MentorInfoSecForm__footer"
              onClick={toggleVisibilityCvReview}
            >
              <h1>
                CV review{" "}
                {mentorData[0]?.mentorPricing[0]?.cvReview > 0 && (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300px",
                      color: "#3dd7a1",
                    }}
                  >
                    ({" "}
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {mentorData[0]?.mentorPricing[0]?.cvReview} )
                  </span>
                )}
              </h1>

              <div className="MentorInfoSecForm__footer__container">
                {cvReviewValue === mentorData[0]?.mentorPricing[0]?.cvReview &&
                  mentorData[0]?.mentorPricing[0]?.cvReview > 0 ? (
                  <>
                    <img src={deletetrash} alt="" onClick={deleteCVReview} />
                  </>
                ) : (
                  <>
                    {cvReviewValue ===
                      mentorData[0]?.mentorPricing[0]?.cvReview &&
                      mentorData[0]?.mentorPricing[0]?.cvReview > 0 ? (
                      <img src={deletetrash} alt="" onClick={deleteCVReview} />
                    ) : (
                      <>
                        {cvReviewValue !== 50 || isVisibleCvReview &&
                        (cvReviewValue >
                          mentorData[0]?.mentorPricing[0]?.cvReview ||
                          cvReviewValue <
                          mentorData[0]?.mentorPricing[0]?.cvReview) ? (
                          <div className="top__right d-none d-md-block">
                            {" "}
                            <button
                              className="btn__green__edit__services"
                              onClick={saveCvReview}
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        ) : (
                          <button className="servicesbutton">
                            {" "}
                            <img src={addplus} alt="" />
                            <p>Add service</p>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* {mentorData[0]?.mentorPricing[0]?.cvReview > 0 ? (
                  <>
                    <img src={deletetrash} alt="" onClick={deleteCVReview} />
                  </>
                ) : (
                  <>
                    {!saveBtnCvReview &&
                    mentorData[0]?.mentorPricing[0]?.cvReview ? (
                      <img src={deletetrash} alt="" onClick={deleteCVReview} />
                    ) : (
                      //  <button className="servicesbutton"> <img src={addplus} alt="" /><p>Add service</p></button>
                      <>
                        {saveBtnCvReview ? (
                          <div className="top__right d-none d-md-block">
                            {" "}
                            <button
                              className="btn__green__edit__services"
                              onClick={saveCvReview}
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        ) : (
                          <button className="servicesbutton">
                            {" "}
                            <img src={addplus} alt="" />
                            <p>Add service</p>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )} */}
              </div>
            </div>
            {isVisibleCvReview && (
              <div className="EditServices__cardRows">
                {/* <div className="EditServices__cardRows__heading ">
                  CV Review
                </div>
                <div className="EditServices__cardRows__body ">
                  <h1>Description</h1>
                  <textarea
                    type="text"
                    placeholder="Why choose your general guidance session?"
                    className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                    style={{ marginTop: "12px", color: "#989FAD" }}
                    value={updatePriceData?.cvReview}
                    name="cvReview"
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}
                <div className="rangeSec">
                  <h1 className="rangeSec__label">Price per session</h1>
                  <h2 className="rangeSec__price">
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {cvReviewValue}
                  </h2>
                  <Slider
                    value={cvReviewValue}
                    onChange={(e) => setCvReviewValue(e.value)}
                    max="400"
                    min="0"
                    step={50}
                  />
                  <div className="rangeSec__pricevalue">
                    <h1>
                      Min{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      50
                    </h1>
                    {/* <h1>Min £{cvReviewValue ? cvReviewValue : "0"}</h1> */}

                    <h1>
                      Max{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      400
                    </h1>
                  </div>
                </div>
              </div>
            )}

            <div style={isVisibleCoverLetterReview ? { borderBottom: "0px", paddingBottom: "0px" } : { borderBottom: "1px solid #2f3033", paddingBottom: "22px" }}
              className="MentorInfoSecForm__footer"
              onClick={toggleVisibilityCoverLetterReview}
            >
              <h1>
                {" "}
                Cover letter review{" "}
                {mentorData[0]?.mentorPricing[0]?.coverLetter > 0 && (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300px",
                      color: "#3dd7a1",
                    }}
                  >
                    ({" "}
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {mentorData[0]?.mentorPricing[0]?.coverLetter} )
                  </span>
                )}
              </h1>
              {/* <img
                src={isVisibleCoverLetterReview ? minus : plusWhite}
                alt=""
              />
              <button onClick={deleteCoverLetterReview}>delete</button> */}

              {coverLetterValue ===
                mentorData[0]?.mentorPricing[0]?.coverLetter &&
                mentorData[0]?.mentorPricing[0]?.coverLetter > 0 ? (
                <>
                  <img
                    src={deletetrash}
                    alt=""
                    onClick={deleteCoverLetterReview}
                  />
                </>
              ) : (
                <>
                  {coverLetterValue ===
                    mentorData[0]?.mentorPricing[0]?.coverLetter &&
                    mentorData[0]?.mentorPricing[0]?.coverLetter > 0 ? (
                    <img
                      src={deletetrash}
                      alt=""
                      onClick={deleteCoverLetterReview}
                    />
                  ) : (
                    <>
                      {coverLetterValue !== 50 || isVisibleCoverLetterReview &&
                      (coverLetterValue >
                        mentorData[0]?.mentorPricing[0]?.coverLetter ||
                        coverLetterValue <
                        mentorData[0]?.mentorPricing[0]?.coverLetter) ? (
                        <div className="top__right d-none d-md-block">
                          {" "}
                          <button
                            className="btn__green__edit__services"
                            onClick={saveCoverLetter}
                          >
                            {" "}
                            Save{" "}
                          </button>
                        </div>
                      ) : (
                        <button className="servicesbutton">
                          {" "}
                          <img src={addplus} alt="" />
                          <p>Add service</p>
                        </button>
                      )}
                    </>
                  )}
                </>
              )}

              {/* {mentorData[0]?.mentorPricing[0]?.coverLetter > 0 ? (
                <>
                  <img
                    src={deletetrash}
                    alt=""
                    onClick={deleteCoverLetterReview}
                  />
                </>
              ) : (
                <>
                  {!saveBtnCoverLetterReview &&
                  mentorData[0]?.mentorPricing[0]?.coverLetter ? (
                    <img
                      src={deletetrash}
                      alt=""
                      onClick={deleteCoverLetterReview}
                    />
                  ) : (
                    <>
                      {saveBtnCoverLetterReview ? (
                        <div className="top__right d-none d-md-block">
                          {" "}
                          <button
                            className="btn__green__edit__services"
                            onClick={saveCoverLetter}
                          >
                            {" "}
                            Save{" "}
                          </button>
                        </div>
                      ) : (
                        <button className="servicesbutton">
                          {" "}
                          <img src={addplus} alt="" />
                          <p>Add service</p>
                        </button>
                      )}
                    </>
                  )}
                </>
              )} */}
            </div>

            {isVisibleCoverLetterReview && (
              <div className="EditServices__cardRows">
                {/* <div className="EditServices__cardRows__heading ">
                  Cover Letter Review
                </div>
                <div className="EditServices__cardRows__body ">
                  <h1>Description</h1>
                  <textarea
                    type="text"
                    placeholder="Why choose your general guidance session?"
                    className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                    style={{ marginTop: "12px", color: "#989FAD" }}
                    value={updatePriceData?.coverLetter}
                    name="coverLetter"
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}
                <div className="rangeSec">
                  <h1 className="rangeSec__label">Price per session </h1>
                  <h2 className="rangeSec__price">
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {coverLetterValue}
                  </h2>
                  <Slider
                    value={coverLetterValue}
                    onChange={(e) => setCoverLetterValue(e.value)}
                    max="400"
                    min="0"
                    step={50}
                  />
                  <div className="rangeSec__pricevalue">
                    <h1>
                      Min{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      50
                      {/* {coverLetterValue ? coverLetterValue : "0"} */}
                    </h1>
                    <h1>
                      Max{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      400
                    </h1>
                  </div>
                </div>
              </div>
            )}
            <div style={isVisibleInterviewPractice ? { borderBottom: "0px", paddingBottom: "0px" } : { borderBottom: "1px solid #2f3033", paddingBottom: "22px" }}
              className="MentorInfoSecForm__footer"
              onClick={toggleVisibilityInterviewPractice}
            >
              <h1>
                Interview practice{" "}
                {mentorData[0]?.mentorPricing[0]?.interviewPractice > 0 && (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300px",
                      color: "#3dd7a1",
                    }}
                  >
                    ({" "}
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {mentorData[0]?.mentorPricing[0]?.interviewPractice} )
                  </span>
                )}
              </h1>
              <div className="MentorInfoSecForm__footer__container">
                {interviewPracticeValue ===
                  mentorData[0]?.mentorPricing[0]?.interviewPractice &&
                  mentorData[0]?.mentorPricing[0]?.interviewPractice > 0 ? (
                  <>
                    <img
                      src={deletetrash}
                      alt=""
                      onClick={deleteInterviewPractice}
                    />
                  </>
                ) : (
                  <>
                    {interviewPracticeValue ===
                      mentorData[0]?.mentorPricing[0]?.interviewPractice &&
                      mentorData[0]?.mentorPricing[0]?.interviewPractice > 0 ? (
                      <img
                        src={deletetrash}
                        alt=""
                        onClick={deleteInterviewPractice}
                      />
                    ) : (
                      <>
                        {interviewPracticeValue !== 50 || isVisibleInterviewPractice &&
                        (interviewPracticeValue >
                          mentorData[0]?.mentorPricing[0]?.interviewPractice ||
                          interviewPracticeValue <
                          mentorData[0]?.mentorPricing[0]?.interviewPractice) ? (
                          <div className="top__right d-none d-md-block">
                            {" "}
                            <button
                              className="btn__green__edit__services"
                              onClick={saveInterviewPractice}
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        ) : (
                          <button className="servicesbutton">
                            {" "}
                            <img src={addplus} alt="" />
                            <p>Add service</p>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* {mentorData[0]?.mentorPricing[0]?.interviewPractice > 0 ? (
                  <>
                    <img
                      src={deletetrash}
                      alt=""
                      onClick={deleteInterviewPractice}
                    />
                  </>
                ) : (
                  <>
                    {!saveBtnInterviewPractice &&
                    mentorData[0]?.mentorPricing[0]?.interviewPractice ? (
                      <img
                        src={deletetrash}
                        alt=""
                        onClick={deleteInterviewPractice}
                      />
                    ) : (
                      <>
                        {saveBtnInterviewPractice ? (
                          <div className="top__right d-none d-md-block">
                            {" "}
                            <button
                              className="btn__green__edit__services"
                              onClick={saveInterviewPractice}
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        ) : (
                          <button className="servicesbutton">
                            {" "}
                            <img src={addplus} alt="" />
                            <p>Add service</p>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )} */}
              </div>
            </div>
            {isVisibleInterviewPractice && (
              <div className="EditServices__cardRows">
                {/* <div className="EditServices__cardRows__heading ">
                  Interview Practice
                </div>
                <div className="EditServices__cardRows__body ">
                  <h1>Description</h1>
                  <textarea
                    type="text"
                    placeholder="Why choose your general guidance session?"
                    className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                    style={{ marginTop: "12px", color: "#989FAD" }}
                    value={updatePriceData?.interviewPractice}
                    name="interviewPractice"
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}
                <div className="rangeSec">
                  <h1 className="rangeSec__label">Price per session</h1>
                  <h2 className="rangeSec__price">
                    {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                      ? "$"
                      : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                        ? "€"
                        : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                          ? "£"
                          : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                            ? "₣"
                            : ""}
                    {interviewPracticeValue}
                  </h2>
                  <Slider
                    value={interviewPracticeValue}
                    onChange={(e) => setInterviewPracticeValue(e.value)}
                    max="400"
                    min="0"
                    step={50}
                  />
                  <div className="rangeSec__pricevalue">
                    <h1>
                      Min{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      50
                      {/* {interviewPracticeValue ? interviewPracticeValue : "0"} */}
                    </h1>
                    <h1>
                      Max{" "}
                      {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                        ? "$"
                        : mentorData[0]?.mentorPricing[0]?.currency === "euro"
                          ? "€"
                          : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency === "chf"
                              ? "₣"
                              : ""}
                      400
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="btn__container__add d-md-none mt-5">
            <button className="btn-green" onClick={(e) => handleSubmit(e)}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSchedules;
