import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";
import axios from "axios";
import { API_URL } from "../../client";

export const viewNetworkSession = createAsyncThunk(
  "viewNetworkSession",
  async (data) => {
    try {
      const res = await client.get(`/api/viewMentorSession`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const viewAllNetworkSession = createAsyncThunk(
  "viewNetworkSession",
  async (data) => {
    try {
      const res = await client.post(`/api/viewAllMentorSession`,{
        requestedDate:data?.requestedDate
      }
      
      // {
      //   headers: { authorization: `${data?.token}` },
      // }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const bookNetworkSession = createAsyncThunk(
  "bookNetworkSession",
  async (data) => {
    try {
      const res = await client.post(
        `/create-checkout-session`,
        {
          price: data?.price,
          meetingId: data?.meetingId,
          id: data?.id,
          bookedby: data?.bookedby,
          dificultyLevel: data?.dificultyLevel,
          sessionType: data?.sessionType,
          currency: data?.currency,
          cv: data?.cv,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const getGuidWithMentor = createAsyncThunk(
  "getGuidWithMentor",
  async (data) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/getGuidWithMentor/${data?.userId}`,
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);



export const cancelSessionNetwork = createAsyncThunk("cancelSessionNetwork", async (data) => {
  try {
    const config = {
      headers: {
        authorization: `${data?.token}`,
      },
      data: {
        meetingId: data?.meetingId,
      },
    };
    const res = await axios.delete(
      `${API_URL}/api/cancleSessionwithMentor/${data?.creatorId}`,
      config
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});