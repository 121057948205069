import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data) => {
    try {
      const res = await client.post(
        "/auth/forgot",
        { email: data?.email },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
  try {
    const res = await client.post(
      `/auth/reset/${data.token}`,
      {
        newPassword: data?.newPassword,
      },
      { headers: { authorization: `${data?.mytoken}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});
export const changePassword = createAsyncThunk(
  "changePassword",
  async (data) => {
    try {
      const res = await client.post("/auth/change-password", data, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
