import React, { useEffect, useState } from "react";
import Arrowleft from "./arrow-left.svg";
import ArrowRight from "./arrow-right.svg";
import "./calendar.scss";
import moment from "moment-timezone";
import { ErrorToast } from "../../MyToaster";

const formatingDate = (singleCalendarData) => {
  const originalArray = Object.values(singleCalendarData);
  const newArray = [];

  originalArray.forEach((item) => {
    const existingItem = newArray.find((element) => element.date === item.date);
    if (existingItem) {
      existingItem.time.push({
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
        _id: item._id,
        booked: item.booked,
        bookedby: item.bookedby,
      });
    } else {
      newArray.push({
        date: item.date,
        time: [
          {
            startDateTime: item.startDateTime,
            endDateTime: item.endDateTime,
            _id: item._id,
            booked: item.booked,
            bookedby: item.bookedby,
          },
        ],
      });
    }
  });
  let sortedArray = newArray.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  return sortedArray;
};
const CalendarSlot = ({ singleCalendarData }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dates, setDates] = useState(formatingDate(singleCalendarData));
  const [toShowDates, setToShowDates] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedSlots, setSelectedSlots] = useState([]);

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      )
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      )
    );
  };

  const handleDateChange = (daysToAdd) => {
    // const newDate = new Date(currentDate);
    // newDate.setDate(currentDate.getDate() + daysToAdd);
    // setCurrentDate(newDate);
  };

  function formatDateToCustomString(dateString) {
    const dateObj = new Date(dateString);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[dateObj.getUTCDay()];
    const year = String(dateObj.getUTCFullYear()).slice(-2);
    const month = dateObj.getUTCMonth() + 1;
    return (
      <p>
        {dayOfWeek}
        <br />
        {`${dateObj.getUTCDate()}/${month}`}
      </p>
    );
  }

  const foramtTime = (timeString) => {
    const dateObj = new Date(timeString);
    // Extract time components
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    // Format the time as HH:mma:ss
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    // :${seconds.toString().padStart(2, "0")}`;
    return formattedTime;
  };
  const convertingToLocal = (data) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // // Convert the timestamps to the user's local time
    // const startDateTimeLocal = moment(data.startDateTime)
    //   .tz(userTimeZone)
    //   .format("YYYY-MM-DD HH:mma:ss");
    // const endDateTimeLocal = moment(data.endDateTime)
    //   .tz(userTimeZone)
    //   .format("YYYY-MM-DD HH:mma:ss");

    const utcDate = new Date(data);
    const localTime = utcDate?.toLocaleString(undefined, {
      timeZone: userTimeZone,
    });
    // let time = moment(localTime).tz(userTimeZone).format("h:mm:ss a");

    const onlyTime = localTime.split(",")[1];
    const hm = onlyTime.split(":");
    return `${hm[0]}:${hm[1]}`;

    // return localTime;
    // setLocalTime(localTime);
  };
  const handleSelectedSlot = (item, selectedtime) => {
    setSelectedSlots({ item, selectedtime });
    if (selectedtime?.booked) {
      ErrorToast("This time slot has already been booked");
    } else {
      setSelectedSlots({ item, selectedtime });
      // setCalendarReturn({ item, selectedtime });
    }
  };

  const handlePrevClick = () => {
    if (currentIndex === 0) {
      ErrorToast("There are no other available time slots");
    } else {
      const newIndex = currentIndex - 1;
      setToShowDates(dates.slice(newIndex, newIndex + 1));
      setCurrentIndex(newIndex);
    }
  };
  const handleNextClick = () => {
    if (currentIndex + 1 >= dates.length) {
      ErrorToast("There are no other available time slots");
    } else {
      const newIndex = currentIndex + 1;
      setToShowDates(dates.slice(newIndex, newIndex + 1));
      setCurrentIndex(newIndex);
    }
  };
  useEffect(() => {
    if (dates?.length > 0) {
      setToShowDates(dates?.slice(0, 1));
    }
  }, [dates]);
  useEffect(() => {}, [toShowDates]);

  useEffect(() => {}, [selectedSlots]);

  return (
    <>
      <div className="calendar__container">
        <div className="calendar__container__top">
          {/* <button
            className="calendar__container__top__button"
            onClick={handlePrevMonth}
          >
            <img
              src={Arrowleft}
              alt="arrow-left"
              className="calendar__container__top__button__image"
            />
          </button> */}
          {/* <div className="calendar__container__top__text">
            {currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div> */}
          {/* <button
            className="calendar__container__top__button"
            onClick={handleNextMonth}
          >
            <img
              src={ArrowRight}
              alt="arrow-right"
              className="calendar__container__top__button__image"
            />
          </button> */}
        </div>
        <div className="calendar__container__bottom">
          <div className="calendar__container__bottom__container">
            {/* <button
              className="calendar__container__bottom__container__button"
              onClick={handlePrevClick}
            >
              <img
                src={Arrowleft}
                alt="arrow-left"
                className="calendar__container__bottom__container__button__image"
              />
            </button> */}
            <div className="calendar__container__bottom__container__main">
              {/* left date */}
              {toShowDates?.map((itemmain) => (
                <div
                  className="calendar__container__bottom__container__main__inner"
                  key={itemmain.date}
                >
                  <div className="calendar__container__bottom__container__main__inner__date">
                    {formatDateToCustomString(
                      moment(itemmain.endDateTime).format("YYYY-MM-DD")
                    )}
                  </div>
                  <div className="calendar__container__bottom__container__main__inner__events">
                    {itemmain?.time?.map((item, index) => (
                      <div
                        className={`calendar__container__bottom__container__main__inner__events__pill
                        ${
                          selectedSlots?.item?.date === itemmain?.date &&
                          selectedSlots?.selectedtime?._id === item?._id &&
                          selectedSlots?.selectedtime?.startDateTime ===
                            item?.startDateTime &&
                          selectedSlots?.selectedtime?.endDateTime ===
                            item?.endDateTime &&
                          !item?.booked
                            ? "selected"
                            : ""
                        }
                        ${item?.booked ? "booked" : ""}
                        `}
                        // ${activeDates.includes(item) ? "selected" : ""}
                        key={index}
                        // onClick={() => handleSelectedSlot(itemmain, item)}
                      >
                        <div>{convertingToLocal(item.startDateTime)}</div>
                        <div>-</div>
                        <div>{convertingToLocal(item.endDateTime)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* <button
              className="calendar__container__bottom__container__button"
              onClick={handleNextClick}
            >
              <img
                src={ArrowRight}
                alt="arrow-left"
                className="calendar__container__bottom__container__button__image"
              />
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarSlot;
