import React from "react";
import NavLogo from "../../../assets/8.svg"
import './strippayments.scss'
import { useHistory } from "react-router-dom";
import tick from '../../../assets/cancelMeeting.svg'



const CloseMeeting = () => {
    const history = useHistory()

    const dashboard = () => {
        history.push('/mentordashboard')
    }
    return (
        <div className="stripe__stripe">
            <div className="PersonalInfoSec">
                <div className="PersonalInfoSec__nav">
                    <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="logo" />
                </div>
                <div className="middle">
                    <div>
                        <img src={tick} alt="stripe" />
                    </div>
                    <div className="text___main success__message">
                        <h1 className="">Video call ended</h1>
                    </div>
                    <br/>

                    <div className="">
                        <p style={{color:"white",textAlign:"center"}} className="">Please go back to meeting room and exit meeting to rate the other candidate. <br/> Thank you!</p>
                    </div>
                    {/* <div className="stripe__btn">
                        <button onClick={dashboard} className="btn__common__css first__btn" >
                            Go to Dashboard
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


export default CloseMeeting