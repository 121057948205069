import React, { useState, useEffect } from "react";
import messageIcon from "../../assets/messageIcon.svg";
import ViewIcon from "../../assets/views.svg";
import likedIconForum from "../../assets/likeIConForum.svg";
import plusIcon from "../../assets/plusSimple.svg";
import DropDownIcon from "../../assets/arrow-down.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row, Form } from "react-bootstrap";
import FooterForum from "../../components/Footer/FooterForum";
import NavBar from "../Navbar/NavBar.jsx";
import { Editor } from "primereact/editor";
import { ErrorToast, SuccessToast } from "../MyToaster";
import "./Forum.scss";
import { ConfirmModal } from "../SessionModal/SessionModal";
import Thread from "./Thread/Thread";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createForum, getForum } from "../../service/redux/middleware/forum";
import ScreenLoader from "../Loader/ScreenLoader";
import moment from "moment";
import NavbarLanding from "../Navbar/NavbarLanding";
import forumNoFound from "../../assets/forumnoFound.svg";
import ShieldIcon from "../../assets/shield.svg";
import Avatar1 from "../../assets/a1.jpg";
import axios from "axios";
import { API_URL } from "../../service/client.js";

const Forum = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [singleData, setSingleData] = useState("");
  const [showThread, setShowThread] = useState(false);
  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  // const [discussionContent, setDiscussionContent] = useState([]);

  const [forumShowData, setForumShowData] = useState([]);

  const forumData = useSelector((state) => state?.forumData?.forumData?.data);
  const [loading, setLoading] = useState(false);
  const [discussion, setDiscussion] = useState("");
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const dispatch = useDispatch();
  // const history = useHistory();

  let processedDiscussions;

  useEffect(() => {
    // if (!forumData || forumData.length === 0) {
    setLoader(true);
    dispatch(getForum()).then((res) => {
      console.log("ressssss", res);
      // const tempDiv = document.createElement("div");
      // tempDiv.innerHTML = res?.payload?.data[0]?.forum?.discussion;
      // const imgTags = tempDiv.querySelectorAll("img");
      // imgTags.forEach((img) => {
      //   img.classList.add("images__images__in__forum");
      // });
      // setDiscussionContent(tempDiv.innerHTML);
      const discussions = res?.payload?.data || [];

      console.log("discussions", discussions);

      // Process each discussion item
      processedDiscussions = discussions.map((discussion, index) => {
        console.log(
          "discussiondiscussiondiscussiondiscussion",
          discussion?.forum?.discussion
        );
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = discussion?.forum?.discussion;

        // Add your image processing logic here
        const imgTags = tempDiv.querySelectorAll("img");
        imgTags.forEach((img) => {
          img.classList.add("images__images__in__forum");
        });

        return (
          <div
            key={index}
            className="forum__text__main"
            dangerouslySetInnerHTML={{ __html: tempDiv.innerHTML }}
          />
        );
      });

      setForumShowData(res?.payload.data);
      // console.log('res',res);
      if (res?.payload?.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
    // } else {
    //   setLoader(false);
    // }
  }, [showThread]);

  // form validation

  const formValidation = () => {
    let allGood = true;

    if (title === "" || title === undefined) {
      ErrorToast("Please enter a title before submitting your question.");
      allGood = false;
    }

    // if (allGood && topic === "") {
    //   ErrorToast(
    //     "Please enter a discussion topic before submitting your question."
    //   );
    //   allGood = false;
    // }

    if (allGood && (discussion === null || discussion === "")) {
      ErrorToast("Please add to discussion before submitting your question.");
      allGood = false;
    }
    return allGood;
  };

  // create new foum

  const CreateForum = (e) => {
    e.preventDefault();
    const res = formValidation();
    if (res === false) {
      return false;
    }
    const data = {
      title: title,
      topic: "Blockchain",
      discussion: discussion,
      id: userData?._id,
    };
    if (!loading) {
      try {
        setLoader(true);
        dispatch(createForum(data)).then((res) => {
          // setLoader(false);
          if (res?.payload?.status === 200) {
            // setLoader(false);
            setShowConfirmModal(false);
            setTitle("");
            // setTopic('')
            setDiscussion("");
            dispatch(getForum()).then((res) => {
              if (res?.payload?.status === 200) {
                setForumShowData(res?.payload.data);
                setLoader(false);
                SuccessToast("You have created a discussion successfully");
              } else {
                setLoader(false);
              }
            });
          } else {
            setLoader(false);
            setShowConfirmModal(false);
            ErrorToast(res?.payload?.message);
            // ClearAll();
          }
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // get single data

  const getSingleData = async (elem) => {
    setLoader(true);
    console.log("singleData", elem);
    const resView = await axios.post(
      `${API_URL}/forumsViews/${elem?.forum?._id}`
    );
    if (resView?.status === 200) {
      setLoader(false);
    }
    console.log(resView, "viewResponse");
    setShowThread(true);
    setSingleData(elem);
  };

  const [topicFilter, setTopicFilter] = useState("All Topics");
  const [popular, setPopular] = useState("Sort");

  console.log("popular", popular);

  const ApplyFilter = () => {
    let filteredData = [...forumData];
    let filterFunctions = [];
    if (popular == "Most popular") {
      console.log("popular");
      filteredData.sort(
        (a, b) => b.forum?.likes?.length - a.forum?.likes?.length
      );
    } else if (popular == "Last activity") {
      filterFunctions.push((item) => {
        console.log("item", item);

        // let matchedData = item.comments?.filter((citem) => {
        //   console.log('citem', citem, moment(citem?.comment?.date).format("YYYY-MM-DD HH:mm"));
        //   return moment(citem?.comment?.date).format("YYYY-MM-DD HH:mm");
        // });
        filteredData.sort((a, b) => {
          const dateA = moment(a?.comment?.createdAt).format(
            "YYYY-MM-DD HH:mm"
          );
          const dateB = moment(b?.comment?.createdAt).format(
            "YYYY-MM-DD HH:mm"
          );
          return dateA - dateB;
        });
        return filteredData.length > 0;
      });
    }

    console.log("filteredData", filteredData);

    try {
      filterFunctions.forEach((filterFunction) => {
        filteredData = filteredData.filter(filterFunction);
      });
      return setForumShowData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    ApplyFilter();
    return () => {
      ApplyFilter();
    };
  }, [topicFilter, popular]);

  console.log("forumShowData", forumShowData);

  const excludeImages = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove all img tags
    const images = doc.querySelectorAll("img");
    images.forEach((img) => img.remove());

    // Convert the modified DOM back to HTML
    const modifiedHtml = new XMLSerializer().serializeToString(doc);

    return modifiedHtml;
  };
  const sanitizeAndTruncate = (html, maxLines) => {
    const sanitizedHtml = excludeImages(html);

    // Truncate the text after maxLines
    const truncatedHtml = sanitizedHtml
      .split("\n")
      .slice(0, maxLines)
      .join("\n");

    return truncatedHtml;
  };
  function calculateTotalLikes(forum) {
    let totalLikes = 0;

    if (forum && forum.comments && forum.comments.length > 0) {
      forum.comments.forEach((comment) => {
        if (comment.likes && comment.likes.length > 0) {
          totalLikes += comment.likes.length;
        }
      });
    }

    return totalLikes;
  }

  return (
    <>
      {loader && <ScreenLoader />}
      {showThread ? (
        <Thread
          setShowThread={setShowThread}
          elem={singleData}
          setSingleData={setSingleData}
        />
      ) : (
        <>
          {userData?.role === 0 ? <NavBar /> : <NavbarLanding />}
          <div className="container__custom">
            <div className="ForumSection container__custom">
              <div className="ForumSection__Navbar">
                <div className="ForumSection__Navbar__Filters">
                  <div className="topicFilter sessionFilter">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="forumm__button"
                      >
                        <p className="forumm__button__text">{popular}</p>
                        <img
                          src={DropDownIcon}
                          className="forumm__button__image"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                        <Dropdown.Item
                          onClick={() => setPopular("Most popular")}
                          className="custom__dropdown__item"
                        >
                          Most popular
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setPopular("Last activity")}
                          className="custom__dropdown__item"
                        >
                          Last activity
                        </Dropdown.Item>
                        {/* {popular !== "Last activity" && (
                        <Dropdown.Item
                          onClick={() => setPopular("Last activity")}
                          className="custom__dropdown__item"
                        >
                          Last activity
                        </Dropdown.Item>
                      )}
                      {popular !== "Most popular" && (
                        <Dropdown.Item
                          onClick={() => setPopular("Most popular")}
                          className="custom__dropdown__item"
                        >
                          Most popular
                        </Dropdown.Item>
                      )} */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="ForumSection__Navbar__Button">
                  {userData?._id && userData.role === 0 && (
                    <button
                      className="forum__button"
                      onClick={() => setShowConfirmModal(true)}
                    >
                      <span className="forum__button__text">
                        Ask a Question
                      </span>
                      <img
                        src={plusIcon}
                        alt="Add Schedule"
                        className="forum__button__image"
                      />
                    </button>
                  )}
                </div>
              </div>

              {forumData?.length > 0 ? (
                <>
                  {forumShowData?.map((item) => {
                    console.log("item", item);
                    return (
                      <div
                        className="forum__inner__main mt20__20"
                        onClick={() => getSingleData(item)}
                      >
                        <Row
                          style={{ paddingBottom: "20px" }}
                          className="ForumSection__Content row "
                          key={item}
                        >
                          <Col xs={5} md={8} style={{ padding: "0px" }}>
                            <h1 className="ForumSection__Content__heading1">
                              {item?.forum?.title}
                            </h1>
                            {/* <p className="ForumSection__Content__para1 ">
                            {item?.forum?.topic}
                          </p> */}
                          </Col>
                          <Col style={{ padding: "0px" }} xs={6} md={4}>
                            <div className="ForumSection__Content__data d-flex">
                              {/* <div className="ForumSection__Content__data__left">
                            {item?.comments?.map((item, index) => (
                              <img
                                src={item.image}
                                alt="avatar"
                                className="ForumSection__Content__data__left__image"
                                style={{
                                  zIndex: index + 100,
                                  left: `${index * 20 + 30}px`,
                                }}
                              />
                            ))}
                          </div> */}
                              {/* <div className="ForumSection__Content__data__right d-flex align-items-center">
                            <img
                              src={messageIcon}
                              alt=""
                              className="ForumSection__Content__data__right__image"
                            />
                            <p className="ForumSection__Content__data__right__text">
                              {item?.comments?.length}
                            </p>
                          </div> */}
                              {item?.forum?.comments?.length > 0 ? (
                                <p className="ForumSection__Content__data__end">
                                  Most recent answer{" "}
                                  {console.log(
                                    "this is the forum",
                                    item?.forum
                                  )}
                                  {moment(
                                    item?.forum?.comments[
                                      item?.forum?.comments.length - 1
                                    ]?.date
                                  ).fromNow()}
                                </p>
                              ) : (
                                <p className="ForumSection__Content__data__end">
                                  No answere right now
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="threadup ForumSection__Content">
                          <Col
                            className="thread__container__bottom p-0 "
                            md={12}
                          >
                            <div className="thread__container__bottom__post__post mt_mb_22">
                              <div className="thread__container__bottom__post__left">
                                <img
                                  src={item?.image ? item?.image : Avatar1}
                                  alt="avatar"
                                  className="thread__container__bottom__post__left__image"
                                />
                              </div>
                              <div className="thread__container__bottom__post__right__right">
                                <div className="thread__container__bottom__post__right__top">
                                  <div className="thread__container__bottom__post__right__top__left">
                                    <img
                                      src={Avatar1}
                                      alt="avatar"
                                      className="thread__container__bottom__post__right__top__left__imageA"
                                    />
                                    <h3 className="thread__container__bottom__post__right__top__left__heading">
                                      {item?.firstName} {item?.lastName}
                                    </h3>
                                    <img
                                      src={ShieldIcon}
                                      alt="shield"
                                      className="thread__container__bottom__post__right__top__left__image"
                                    />
                                  </div>
                                  <p className="thread__container__bottom__post__right__top__text">
                                    {moment(item?.forum?.createdAt).format(
                                      "MMM D"
                                    )}
                                  </p>
                                </div>
                                <div />
                                {/* {processedDiscussions} */}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizeAndTruncate(
                                      item?.forum?.discussion,
                                      3
                                    ),
                                  }}
                                  className="forum__text__main forum__ellipses"
                                ></div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Col xs={6} md={4}> */}
                        <div className=" d-flex justify-content-start mt20__20">
                          <div className="ForumSection__Content__data__left">
                            {item?.comments?.map((items, index) => (
                              <img
                                src={items?.image}
                                alt="avatar"
                                className="ForumSection__Content__data__left__image"
                                style={{
                                  zIndex: index + 100,
                                  left: `${index * 20 + 30}px`,
                                }}
                              />
                            ))}
                          </div>
                          <div className="d-flex ">
                            <div className="d-flex  align-items-center">
                              <div
                                className="flex-forum"
                                style={{ height: "100%" }}
                              >
                                <img
                                  src={messageIcon}
                                  alt=""
                                  className="ForumSection__Content__data__right__image"
                                />
                              </div>
                              <div style={{ color: "white",fontSize:"18px" }} className="ms-2">
                                {/* <p style={{ fontSize: "16px" }} className="ForumSection__Content__data__right__text"> */}
                                {item?.comments?.length}
                                {/* </p> */}
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center "
                              style={{ marginLeft: "20px" }}
                            >
                              <div style={{ height: "100%" }}>
                                <img
                                  src={ViewIcon}
                                  alt=""
                                  className="ForumSection__Content__data__right__image"
                                />
                              </div>
                              <div style={{ color: "white",fontSize:"18px" }} className="ms-2">
                                {item?.forum?.views}
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center"
                              style={{ marginLeft: "20px" }}
                            >
                              <div style={{ height: "100%" }}>
                                <img
                                  src={likedIconForum}
                                  alt=""
                                  className="ForumSection__Content__data__right__image"
                                />
                              </div>
                              <div style={{ color: "white",fontSize:"18px" }} className="ms-2">
                                {calculateTotalLikes(item?.forum)}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </Col> */}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // height: "75vh",
                  }}
                  className="reschedule__session__container__bottom__left"
                >
                  <div
                    style={{ marginTop: "184px" }}
                    className="no__found__main"
                  >
                    <div>
                      <img src={forumNoFound} />
                    </div>
                    <div className="no__found">
                      No Discussions happening right now
                    </div>
                    {/* <div className="appear__here">
                    Sessions proposed by others would appear on this page
                  </div> */}
                  </div>
                </div>
              )}

              <div className="ForumSection__footer">
                <FooterForum />
              </div>
            </div>
          </div>
          <ConfirmModal show={showConfirmModal} setShow={setShowConfirmModal}>
            <div className="forum__modal">
              <Form.Group className="forum__modal__group">
                <Form.Label
                  htmlFor="title"
                  className="forum__modal__group__label"
                >
                  Question
                </Form.Label>
                <Form.Control
                  type="text"
                  className="forum__modal__group__input"
                  name="title"
                  placeholder="Interview Toolkit"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group className="forum__modal__group">
                <Form.Label
                  htmlFor="topic"
                  className="forum__modal__group__label"
                >
                  Topic
                </Form.Label>
                <Form.Control
                  as="select"
                  // id="topic"
                  className="forum__modal__group__select"
                  name="topic"
                  value={topic ? topic : ''}
                  onChange={handleTopicChange}
                >
                  <option value=""  selected >
                      Please Select your Topic
                    </option>
                 <option value="IT">IT</option>
                  <option value="Data Science"> Data Science</option>
                  <option value="MDB">MDB</option> 
                </Form.Control>
              </Form.Group> */}
              {/* <Dropdown className="forum__modal__group">
                <Form.Label
                  htmlFor="topic"
                  className="forum__modal__group__label"
                >
                  Topic
                </Form.Label>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="filter__button awdewfewf"
                >
                  <span className="filter__button__text">
                    {topic}
                  </span>
                  <img src={DropDownImage} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                  {topic !== "Education and Learning" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("Education and Learning")
                      }
                      className="custom__dropdown__item"
                    >
                      Education and Learning
                    </Dropdown.Item>
                  )}
                  {topic !== " Virtual Classrooms" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic(" Virtual Classrooms")
                      }
                      className="custom__dropdown__item"
                    >
                      Virtual Classrooms
                    </Dropdown.Item>
                  )}
                  {topic !== "E-Learning Platforms" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("E-Learning Platforms")
                      }
                      className="custom__dropdown__item"
                    >
                      E-Learning Platforms
                    </Dropdown.Item>
                  )}


                  {topic !== "Data Science" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("Data Science")
                      }
                      className="custom__dropdown__item"
                    >
                      Data Science
                    </Dropdown.Item>
                  )}
                  {topic !== "Artificial Intelligence" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("Artificial Intelligence")
                      }
                      className="custom__dropdown__item"
                    >
                      Artificial Intelligence
                    </Dropdown.Item>
                  )}
                  {topic !== "Blockchain" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("Blockchain")
                      }
                      className="custom__dropdown__item"
                    >
                      Blockchain
                    </Dropdown.Item>
                  )}
                  {topic !== "Crypto" && (
                    <Dropdown.Item
                      onClick={() =>
                        setTopic("Crypto")
                      }
                      className="custom__dropdown__item"
                    >
                      Crypto
                    </Dropdown.Item>
                  )}

                </Dropdown.Menu>
              </Dropdown> */}
              {/* <Form.Group className="forum__modal__group">
                <Form.Label
                  htmlFor="title"
                  className="forum__modal__group__label"
                >
                  Topic
                </Form.Label>
                <Form.Control
                  type="text"
                  className="forum__modal__group__input"
                  name="topic"
                  placeholder="Enter your topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group> */}
              <Form.Group>
                <Form.Label
                  htmlFor="description"
                  className="forum__modal__group__label"
                >
                  Detailed description (optional)
                </Form.Label>
                <Editor
                  id="description"
                  value={discussion}
                  onTextChange={(e) => setDiscussion(e.htmlValue)}
                  style={{ height: "320px" }}
                  className="editor__text"
                  placeholder="Let us know what is on your mind today"
                />
              </Form.Group>
              <button
                className="forum__modal__button"
                onClick={(e) => CreateForum(e)}
                // onClick={() => setShowConfirmModal(false)}
              >
                Submit question
              </button>
            </div>
          </ConfirmModal>
        </>
      )}
    </>
  );
};

export default Forum;
