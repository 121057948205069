import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./signup.scss";
import Nav from "react-bootstrap/Nav";
import ArrowLeft from "../../src/assets/arrow-right-green.svg";
import Tab from "react-bootstrap/Tab";
import emailIcon from "../../src/assets/emailIcon.svg";
import googleIcon from "../../src/assets/googleIcon.svg";
import FastLogo from "../assets/8.svg";
import { useDispatch } from "react-redux";
import { signinGoogleAuth } from "../service/redux/middleware/signin";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import LinkedInButton from "./LinkedInButton";
import { useSelector } from "react-redux";

const SingUp = () => {
  const userData = useSelector((state) => state?.signin?.signInData?.data);

  const [activeTab, setActiveTab] = useState("signup");
  const [loader, setLoader] = useState(false);
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const history = useHistory();
  const SignUpWithEmail = () => {
    history.push("/signupEmail");
  };
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      const data = {
        email: datas?.data?.email,
        familyName: datas?.data?.family_name,
        givenName: datas?.data?.given_name,
      };
      // var decoded = jwt_decode(tokenResponse?.access_token?.credential);
      dispatch(signinGoogleAuth(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          if (res?.payload?.data?.role === 0) {
            if (res?.payload?.data?.profileUpdated) {
              SuccessToast("Log in successful");
              history.push("/interviewdashboard");
              // const timer = setTimeout(() => {
              //   window.location.reload()
              // }, 1000);
              // return () => clearTimeout(timer);
            } else {
              SuccessToast("Profile Update Your Profile to Continue.");
              history.push("/personalInfo");
              // const timer = setTimeout(() => {
              //   window.location.reload()
              // }, 1000);
              // return () => clearTimeout(timer);
            }
          } else {
            if (res?.payload?.data?.profileUpdated) {
              history.push("/mentordashboard");
              window.location.reload();
            }
          }
        } else {
          ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    },
  });
  useEffect(() => {
    if (userData?.role === 0) {
      history.push("/interviewdashboard");
    } else if (userData?.role === 1) {
      history.push("/mentordashboard");
    }
  }, []);

  const routeToHome = () => {
    window.location.href = "https://www.fast-track.community";
  };
  return (
    <div className="SignUPSection container__custom login__relative">
      <div className="auth__back__main" onClick={routeToHome}>
        <img src={ArrowLeft} className="auth__back__main__image" />
        <p className="auth__back__main__text">Back to Home</p>
      </div>
      <div className="SignUPSection__main row justify-content-center">
        <div className="col-12  col-md-8 ">
          {/* <div
            className="justify-content-center remove-elements"
            style={{ position: "absolute", top: "0%", left: "45%" }}
          >
            <img src={FastLogo} className="SignUPSection__main__empty__image" />
          </div> */}
          <div className="small_screen__logo">
            <a href="https://www.fast-track.community/">
              <img
                src={FastLogo}
                className="SignUPSection__main__empty__image"
              />
            </a>
          </div>
          <div className="bar-bar-bar"></div>
          <div className="d-flex flex-column-reverse flex-md-column">
            <div className="SignUPSection__main__formSection">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={activeTab}
                className=""
                onSelect={handleTabSelect}
              >
                <div className="col-11 col-md-8 col-xl-5">
                  <div className=" SignUPSection__main__formSection__title">
                    {activeTab === "signup" ? "Create a " : "Log into your "}
                    fast_track account
                  </div>
                  <div className="text__text__text">
                    {activeTab === "signup"
                      ? "Join the leading investment banking community for aspiring bankers"
                      : "Learn more on the go from peers and mentors, from all over the world"}
                  </div>
                  <Nav className="flex-row Toggler justify-content-center ">
                    <Nav.Item
                      className={`col-6 text-center ${
                        activeTab === "signup"
                          ? "Toggler__itemactive"
                          : "Toggler__itemunactive"
                      }`}
                    >
                      <Nav.Link
                        onClick={() => {
                          history.push("/signup");
                        }}
                        className={
                          activeTab === "signup"
                            ? "Toggler__itemactive__textactive"
                            : "Toggler__itemunactive__untextactive"
                        }
                        eventKey="signup"
                      >
                        Sign up
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                      className={`col-6 text-center ${
                        activeTab === "login"
                          ? "Toggler__itemactive"
                          : "Toggler__itemunactive"
                      }`}
                    >
                      {/* <Nav.Link eventKey="login" onClick={clickHandler}> */}
                      <Nav.Link
                        onClick={() => {
                          history.push("/login");
                        }}
                        className={
                          activeTab === "login"
                            ? "Toggler__itemactive__textactive"
                            : "Toggler__itemunactive__untextactive"
                        }
                        eventKey="login"
                      >
                        Log in
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="signup">
                      {/* <Form> */}
                      <button
                        className="signup__session__button11 "
                        onClick={SignUpWithEmail}
                      >
                        <img src={emailIcon} alt="" className=""></img>
                        <p className="signup__session__button__text">
                          {" "}
                          Sign up with Email
                        </p>
                      </button>

                      <div className="emptyline ">
                        <div className="emptyline__first "></div>
                        <p className="">OR</p>
                        <div className="emptyline__first"></div>
                      </div>
                      {/* <LinkedInButton signUpflag={true} loginFlag={false}/> */}
                      <LinkedInButton signUpflag={true} loginFlag={false}>
                        Sign up with LinkedIn
                      </LinkedInButton>
                      <button
                        onClick={() => login()}
                        className="signup__session__button11"
                      >
                        <img src={googleIcon} alt="" className=""></img>
                        <p className="signup__session__button__text">
                          {" "}
                          Sign up with Google
                        </p>
                      </button>
                      {/* </Form> */}
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="login">
                      <Login />
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingUp;
