import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Ratings from "../../../Custom/Ratings/Ratings";
import Calendar from "../../../Custom/Calendar/Calendar";
//
import ArrowLeft from "../../../../assets/arrow-left.svg";
import AvatarIcon from "../../../../assets/Avatar.png";
import LocationIcon from "../../../../assets/location.svg";
import LanguageSquare from "../../../../assets/language-square.svg";
import TaskIcon from "../../../../assets/taskIcon.svg";
import Briefcase from "../../../../assets/briefcase.svg";
import RatedStar from "../../../../assets/star-rated.svg";

import BiDot from "../../../../assets/bi_dot.svg";
import Graduation from "../../../../assets/graduation.svg";
import DiamondGrey from "../../../../assets/diamond-grey.svg";
import RisingStar from "../../../../assets/magic-star.svg";
import EmptyStar from "../../../../assets/star.svg";
// import FullStar from "../../../../assets/star-rating-full.svg";
// import EmptyStar from "../../../../assets/star-rating-empty.svg";
import FullStar from "../../../../assets/star-yellow.svg";
import moment from "moment";
import "./MentorProfile.scss";
import { Rating } from "react-simple-star-rating";
import ScreenLoader from "../../../Loader/ScreenLoader";
import { useDispatch } from "react-redux";
import { getMentorRatings } from "../../../../service/redux/middleware/rating";
const MentorProfile = ({
  setIsViewMentor,
  singleData,
  handleJoinMeeting,
  timeDifferenceForProfile,
  individualRating,
}) => {
  const [ratings, setRatings] = useState([]);
  const [ratingsTemp, setRatingsTemp] = useState([]);
  const [loader, setLoader] = useState([]);
  const [isSeeMore, setIsSeeMore] = useState(false);

  const dispatch = useDispatch();

  //
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  //

  useEffect(() => {
    setLoader(true);
    dispatch(getMentorRatings(singleData?.creatorId)).then((res) => {
      if (res?.payload?.status === 200) {
        setRatings(res?.payload?.data[0]?.rating);
        setRatingsTemp(res?.payload?.data[0]);
      } else {
        console.log("Error getting ratings for " + res?.payload?.message);
      }
      setLoader(false);
    });
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = ratings?.slice(startIndex, endIndex);
  const handleAddButtonClick = () => {
    if (endIndex < ratings?.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  const gettingTopOrRising = (elem) => {
    const reliabilityData = elem?.relability[0];
    const ratings = elem?.ratting;
    const ratingAftDiv =
      Number(ratings?.totalRatting) / Number(ratings?.totalReviews);
    const isHighrated = ratingAftDiv >= 4.5;
    let IsHighRatedReturn = "";
    if (isHighrated) {
      IsHighRatedReturn = () => {
        return (
          <div className="mentor__profile__bottom__left__bottom__recognitions__container">
            <div className="mentor__profile__bottom__left__bottom__recognitions__container__item pill-grey">
              <img src={RatedStar} alt="magic star" />
              <p> High Rated</p>
            </div>
          </div>
        );
      };
    }
    let isTop = "";
    // Rating > 4 & interviews > 5
    if (ratingAftDiv >= 4 && reliabilityData?.booked >= 5) {
      isTop = "Top Coach";
    } else if (ratingAftDiv >= 4 && reliabilityData?.booked >= 2) {
      // Rating > 4 & interviews > 2
      isTop = "Rising";
    }
    return (
      <>
        {isHighrated && <IsHighRatedReturn />}
        {isTop?.length > 0 && (
          <div className="mentor__profile__bottom__left__bottom__recognitions__container">
            <div className="mentor__profile__bottom__left__bottom__recognitions__container__item pill-grey">
              <img
                src={isTop === "Rising" ? RisingStar : DiamondGrey}
                alt="magic star"
              />
              <p>{isHighrated}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  //
  const handleSeemoreClick = () => {
    setIsSeeMore(!isSeeMore);
  };
  return (
    <div className="min__height__100">
      {loader ? (
        <ScreenLoader />
      ) : (
        <>
          <div className="mentor__profile">
            <div className="mentor__profile__top">
              <div
                className="mentor__profile__top__container"
                onClick={() => setIsViewMentor(false)}
              >
                <img src={ArrowLeft} alt="left arrow" />
                <p className="marginTop">Back to My Session</p>
              </div>
              <div className="mentor__profile__top__bottom">
                {/* <button className="mentor__profile__top__bottom__button" onClick={handleJoinMeeting} >
              Session starts{" "}
              {moment(singleData?.slot?.startDateTime).format("HH:mm")}
            </button> */}

                <button
                  className="mentor__profile__top__bottom__button"
                  onClick={handleJoinMeeting}
                >
                  {timeDifferenceForProfile <= 0.0833
                    ? // If current time has exceeded the end time, show "Session starts"
                      `Join meeting `
                    : // If not, show "Join meeting"
                      `Session starts ${moment(
                        singleData?.slot?.startDateTime
                      ).format("HH:mm")}`}
                </button>
                {/* <button className="btn-grey">Message</button> */}
              </div>
            </div>
            {/* rows */}
            <Row className="mentor__profile__bottom">
              <Col
                style={{ padding: "0" }}
                className="mentor__profile__bottom__left"
              >
                {/* card */}
                <div className="mentor__profile__bottom__left__top">
                  <div className="mentor__profile__bottom__left__top__container">
                    <img
                      src={singleData?.userProfile[0]?.image || AvatarIcon}
                      alt="avatar icon"
                      className="mentor__profile__bottom__left__top__container__image"
                    />
                    <div className="mentor__profile__bottom__left__top__container__right">
                      <h2>
                        {singleData?.userData[0]?.firstName}{" "}
                        {singleData?.userData[0]?.lastName} 

                        <span>{singleData?.slot?.sessionType}</span>
                      </h2>
                      <p className={`${!isSeeMore ? "ellipsis__text" : ""} `}>
                        {singleData?.userProfile[0]?.background}
                      </p>
                      {singleData?.userProfile[0]?.background?.length >=
                        320 && (
                        <button
                          className="seemore__button"
                          onClick={handleSeemoreClick}
                        >
                          {!isSeeMore ? "See more" : "See less"}
                        </button>
                      )}
                      <div className="mentor__profile__bottom__left__top__container__right__container">
                        <div className="mentor__profile__bottom__left__top__container__right__container__inner">
                          <img src={LocationIcon} alt="location" />
                          <p>{singleData?.userProfile[0]?.location}</p>
                        </div>
                        {/* <div className="mentor__profile__bottom__left__top__container__right__container__inner">
                      <img src={LanguageSquare} alt="language square" />
                      <p>{singleData?.userProfile[0]?.language}</p>
                    </div> */}
                      </div>
                      <div className="mentor__profile__bottom__left__top__container__right__ratings">
                        {/* <Ratings />
                         */}
                        <Rating
                          readonly={true}
                          initialValue={individualRating(singleData)}
                          allowFraction={true}
                          fillIcon={<img src={FullStar} width={16} alt="" />}
                          emptyIcon={
                            <img src={EmptyStar} width={16} alt={""} />
                          }
                          size={20}
                          fillColor="#F9A63A"
                          className="mb-2"
                        />
                        <p>
                          ( {singleData?.relability?.booked} Interview,{" "}
                          {singleData?.realibility?.count &&
                          singleData?.realibility?.booked
                            ? (
                                (Number(singleData?.realibility?.booked) /
                                  Number(singleData?.realibility?.count)) *
                                100
                              ).toFixed(0)
                            : "0"}
                          % Reliability)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* details */}
                <div className="mentor__profile__bottom__left__bottom">
                  <div className="mentor__profile__bottom__left__bottom__services">
                    <div className="mentor__profile__bottom__left__bottom__services__heading">
                      <p>About Service</p>
                      {/* <img src={TaskIcon} alt="task icon" /> */}
                    </div>
                    <div className="mentor__profile__bottom__left__bottom__services__plans">
                      <p className="mentor__profile__bottom__left__bottom__services__plans__description">
                        {singleData?.mentorPrice[0]?.summary}
                      </p>
                      {/* <div className="mentor__profile__bottom__left__bottom__services__plans__plan">
                    <h3>1 Hour Coaching Plan</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                  <div className="mentor__profile__bottom__left__bottom__services__plans__plan">
                    <h3>1 Hour Coaching Plan</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                  <div className="mentor__profile__bottom__left__bottom__services__plans__plan">
                    <h3>1 Hour Coaching Plan</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div> */}

                  {
                    console.log("================================",singleData)
                  }

                      {singleData?.slot.sessionType  && (
                        <div className="network__book__bottom__left__bottom__services__plans__plan">
                          <h3>{singleData?.slot?.sessionType}</h3>
                        </div>
                      )}
                    

                    

                      
                    </div>
                  </div>
                  <div className="mentor__profile__bottom__left__bottom__experience">
                    <div className="mentor__profile__bottom__left__bottom__experience__heading">
                      <p>Experience</p>
                      {/* <img src={Briefcase} alt="briefcase icon" /> */}
                    </div>
                    <div className="mentor__profile__bottom__left__bottom__experience__container">
                      {singleData?.userProfile[0]?.professionalDetails.map(
                        (exp) => (
                          <div className="mentor__profile__bottom__left__bottom__experience__container__item">
                            <p className="mentor__profile__bottom__left__bottom__experience__container__item__title">
                              {exp?.position}, {exp?.company}
                            </p>
                            <div className="mentor__profile__bottom__left__bottom__experience__container__item__inner">
                              <p>
                                {new Date(exp.startDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                  }
                                )}
                                {/* {!exp?.currentlyEmployed && " - "} */}
                                {" - "}
                                {exp?.currentlyEmployed
                                  ? "Current"
                                  : new Date(exp.endDate).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                      }
                                    )}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="mentor__profile__bottom__left__bottom__education">
                    <div className="mentor__profile__bottom__left__bottom__education__heading">
                      <p>Education</p>
                      {/* <img src={Graduation} alt="graduation" /> */}
                    </div>
                    <div className="mentor__profile__bottom__left__bottom__education__container">
                      {singleData?.userProfile[0]?.educationDetails.map(
                        (edu) => (
                          <div className="mentor__profile__bottom__left__bottom__education__container__item">
                            <p className="mentor__profile__bottom__left__bottom__education__container__item__heading">
                              {edu.degree}, {edu.institution}
                            </p>
                            <p className="mentor__profile__bottom__left__bottom__education__container__item__date">
                              {new Date(edu.startDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                }
                              )}
                              {/* {!edu?.currentlyStuding && " - "}
                               */}
                              {" - "}
                              {edu?.currentlyStuding
                                ? "Current"
                                : new Date(edu.endDate).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                    }
                                  )}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="mentor__profile__bottom__left__bottom__recognitions">
                    <div className="mentor__profile__bottom__left__bottom__recognitions__heading">
                      <p>Recognitions</p>
                      {/* <img src={DiamondGrey} alt="gray diamond" /> */}
                    </div>
                    <div className="mentor__profile__bottom__left__bottom__recognitions__container">
                      {singleData?.ratting && (
                        <>{gettingTopOrRising(singleData)}</>
                      )}
                    </div>
                  </div>
                </div>
                {/* reviews */}
                <div className="mentor__profile__bottom__left__reviews">
                  <div className="mentor__profile__bottom__left__reviews__top">
                    <div className="mentor__profile__bottom__left__reviews__top__container">
                      <h2>
                        {ratingsTemp?.totalReviews > 0
                          ? ratingsTemp?.totalReviews
                          : 0}{" "}
                        Reviews
                      </h2>
                      <div style={{ marginTop: "-3px" }}>
                        <Rating
                          initialValue={
                            ratingsTemp?.totalReviews > 0
                              ? ratingsTemp?.totalRatting /
                                ratingsTemp?.totalReviews
                              : 0
                          }
                          readonly={true}
                          transition={true}
                          fillIcon={<img src={FullStar} width={16} alt="" />}
                          emptyIcon={
                            <img src={EmptyStar} width={16} alt={""} />
                          }
                          size={16}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                  {ratingsTemp?.totalReviews > 0 ? (
                    <>
                      <div className="mentor__profile__bottom__left__reviews__center">
                        {currentData.map((item) => (
                          <div
                            className="mentor__profile__bottom__left__reviews__center__item"
                            key={item?._id}
                          >
                            <div className="mentor__profile__bottom__left__reviews__center__item__top">
                              <div className="mentor__profile__bottom__left__reviews__center__item__top__heading">
                                <h2>
                                  {item?.studentId?.firstName}{" "}
                                  {item?.studentId?.lastName}
                                </h2>
                                <div style={{ marginTop: "-6px" }}>
                                  <Rating
                                    initialValue={item?.interview}
                                    readonly={true}
                                    transition={true}
                                    fillIcon={<img src={FullStar} width={16} />}
                                    emptyIcon={
                                      <img src={EmptyStar} width={16} />
                                    }
                                    size={16}
                                  />
                                </div>
                              </div>
                              <div className=" mentor__profile__bottom__left__reviews__center__item__top__details">
                                <p>{item?.sessionType}</p>
                                {/* <p>1-hour Fit Coaching</p> */}
                                <img src={BiDot} alt="bi dot" />
                                <p>
                                  {new Date(item?.createdAt).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </p>
                                {/* <p>15.08.2023</p> */}
                              </div>
                            </div>
                            <p className="mentor__profile__bottom__left__reviews__center__item__bottom">
                              {item?.feedback}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="mentor__profile__bottom__left__reviews__bottom">
                        <p
                          onClick={handleAddButtonClick}
                          className="btn__loadmore"
                        >
                          Load More Reviews
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="no__rating">No Rating Available</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default MentorProfile;
