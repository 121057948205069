import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, client } from "../../client";
import axios from "axios";

export const ratingForInterviewdDashboard = createAsyncThunk(
  "ratingForInterviewdDashboard",
  async (data) => {
    try {
      const res = await client.get(`/api/getRatting/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const ratingForMentorDashboard = createAsyncThunk(
  "ratingForMentorDashboard",
  async (data) => {
    try {
      const res = await client.get(`/api/getRattingbyMentor/${data?.userId}`,{ headers: { authorization: `${data?.token}` } });
      // const res = await client.get(`/api/getRattingbyMentorToStudent/${data}`);
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add rating

export const addRating = createAsyncThunk("addRating", async (data) => {
  try {
    const res = await axios.post(`${API_URL}/api/addRatting`, data);
    return { status: res.status, data: res?.data?.message };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const addRatingbyMentor = createAsyncThunk(
  "addRatingMentor",
  async (data) => {
    try {
      const res = await axios.post(`${API_URL}/api/addRattingByMentor`, data);
      return { status: res.status, data: res?.data?.message };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

// add rating network
export const addRatingNetwork = createAsyncThunk(
  "addRatingNetwork",
  async (data) => {
    try {
      const res = await axios.post(`${API_URL}/api/mentorRatting`, data);
      return { status: res.status, data: res?.data?.message };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

// const get Mentor ratings
export const getMentorRatings = createAsyncThunk(
  "getMentorRatings",
  async (id) => {
    try {
      const res = await axios.get(`${API_URL}/api/getMentorRating/${id}`);
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
