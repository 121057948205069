import React, { useState, useEffect } from "react";
import { MySessionCard } from "../../Custom";
//
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row } from "react-bootstrap";
// card imports

// icons
import AvatarIcon from "../../../assets/avatar.svg";
import Dots from "../../../assets/dot.svg";
import LocationIcon from "../../../assets/location.svg";
import GraduationIcon from "../../../assets/graduation.svg";
import UniversityIcon from "../../../assets/university.svg";
import SortIcon from "../../../assets/sort.svg";
import GridGreen from "../../../assets/grid-green.svg";
import ListView from "../../../assets/menu.svg";
import LevelIcon from "../../../assets/level-session.svg";
import DocumentIcon from "../../../assets/document.svg";

// internal imports
import Ratings from "../../Custom/Ratings/Ratings";
import "./Mysession.scss";
import MentorProfile from "./MentorProfile/MentorProfile";

import { mentorSession } from "../../../service/redux/middleware/session";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ScreenLoader from "../../Loader/ScreenLoader";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import SessionModal from "../../SessionModal/SessionModal";
import { addRelability } from "../../../service/redux/middleware/relaible";
import { cancelSessionNetwork } from "../../../service/redux/middleware/network";

import { setSessionData } from "../../../service/redux/reducer/meetingSlice";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";

import StarFull from "../../../assets/star-yellow.svg";
import noFound from "../../../assets/mySessionEmpty.svg";

const MySessions = ({ setSelectedComponent }) => {
  const [relaible, setRelaible] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isGrid, setIsGrid] = useState(true);
  const [isViewMentor, setIsViewMentor] = useState(false);
  const [dropdown, setDropDown] = useState("Filter");
  const [dropdownServices, setDropDownServices] = useState("Service");
  const [mySessionDataTemp, setMySessionDataTemp] = useState([]);
  const [mySessionData, setMySessionData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [timeDifferenceForProfile, setTimeDifferenceForProfile] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  //
  const data = {
    userId: userData?._id,
    token: token,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setLoader(true);
    dispatch(mentorSession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        let meetings=res?.payload?.data
        meetings.sort((a, b) => new Date(a.slot.startDateTime) - new Date(b.slot.startDateTime));
        setMySessionData(meetings);
        setMySessionDataTemp(meetings);
        // setOnSuccess(true);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const handleMentorProfileClick = (elem) => {
    if (elem && elem.slot) {
      let timeDifferenceHours = findingDifference(elem.slot);
      setTimeDifferenceForProfile(timeDifferenceHours);
    } else {
      // Handle the case where singleData or slot is not defined or empty.
    }
    setSingleData(elem);
    setIsViewMentor(true);
  };
  const [singleData, setSingleData] = useState("");

  const getSingleData = (elem) => {
    if (elem?.slot?.length > 0) {
      setSingleData(elem);
      setModalShow(!modalShow);
      setSelectedSlot({});
    } else {
      const timeDifferenceHours = findingDifference(elem?.slot);
      if (timeDifferenceHours > 1) {
        ErrorToast("The meeting will be unlocked before One Hour.");
      } else {
        setSingleData(elem);
        setSelectedSlot(elem?.slot);
        if (elem?.slot?.sessionType === "Interview Practice") {
          setModalShow(!modalShow);
        } else {
          let timeDifferenceHours = findingDifference(elem?.slot);
          setTimeDifferenceForProfile(timeDifferenceHours);
          //  he meeting if the time difference is within 5 minutes before the meeting time
          if (timeDifferenceHours <= 0.0833) {
            let idToSend = "";
            if (userData?._id === elem?.slot?.bookedby) {
              idToSend = elem?.creatorId;
            } else {
              idToSend = elem?.slot?.bookedby;
            }
            let data = {
              sessionData: elem,
              isMentor: true,
              idToSend,
            };
            
            dispatch(setSessionData(data));
            history.push("/meetingpage");
            SuccessToast("Welcome to your session");

            // const data2 = {
            //   id: userData?._id,
            //   meetingId: elem?.slot?._id,
            //   token: token,
            // };
            // dispatch(addRelability(data2)).then((res) => {
            //   if (res?.payload?.status === 200) {
            //     setRelaible(res?.payload?.data);
            //     dispatch(setSessionData(data));
            //     history.push("/meetingpage");
            //     SuccessToast("Welcome to the meeting.");
            //   }
            // });
          } else {
            ErrorToast("Meeting not started yet  ");
          }
        }
      }
    }
  };
  const handleDropdownSelect = (eventKey) => {
    setDropDown(eventKey);
    filter(eventKey);
  };
  //
  const handleDropdownSelectServices = (eventKey) => {
    setDropDownServices(eventKey);
    filterServices(eventKey);
  };
  //
  const filterServices = (option) => {
    if (option === "All Services") {
      setMySessionDataTemp(mySessionData);
    } else {
      let filterData = mySessionData;
      filterData = filterData.filter(
        (session) =>
          session.slot.sessionType.toLowerCase() === option.toLowerCase()
      );
      setMySessionDataTemp(filterData);
    }
  };
  //
  const filter = (option) => {
    if (option === "All") {
      setMySessionDataTemp([]);
      setMySessionDataTemp(() => mySessionData);
    } else if (option === "Oldest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.slot.startDateTime) {
          startTime = sessionA.slot.startDateTime;
        } else {
          if (sessionA.slot.length === 1) {
            startTime = sessionA.slot[0].startDateTime;
          } else {
            let filteredData = sessionA.slot.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d2 - d1;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.slot.startDateTime) {
          startTime2 = sessionB.slot.startDateTime;
        } else {
          if (sessionB.slot.length === 1) {
            startTime2 = sessionB.slot[0].startDateTime;
          } else {
            let filteredData2 = sessionB.slot.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d2 - d1;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeB - startTimeA;
      });
      setMySessionDataTemp(filter);
    } else if (option === "Newest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.slot.startDateTime) {
          startTime = sessionA.slot.startDateTime;
        } else {
          if (sessionA.slot.length === 1) {
            startTime = sessionA.slot[0].startDateTime;
          } else {
            let filteredData = sessionA.slot.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d1 - d2;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.slot.startDateTime) {
          startTime2 = sessionB.slot.startDateTime;
        } else {
          if (sessionB.slot.length === 1) {
            startTime2 = sessionB.slot[0].startDateTime;
          } else {
            let filteredData2 = sessionB.slot.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d1 - d2;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeA - startTimeB;
      });
      setMySessionDataTemp(filter);
    }
  };
  //
  //
  //

  const updatementormeetingtime = () => {
    if (singleData && singleData.slot) {
      let timeDifferenceHours = findingDifference(singleData.slot);
      setTimeDifferenceForProfile(timeDifferenceHours);
    } else {
      // Handle the case where singleData or slot is not defined or empty.
    }
  };

  const findingDifference = (slot) => {
    const localDate = new Date();
    const utcDate = new Date(localDate.toUTCString());
    const sameFormat = utcDate.toISOString();
    const dateDb = new Date(slot?.startDateTime);
    const localDateTime = new Date();
    const timeDifferenceMillis = dateDb - localDateTime;
    // const timeDifferenceMillis = Math.abs(dateDb - localDateTime);

    // Convert the time difference to hours
    const timeDifferenceHours = timeDifferenceMillis / (60 * 60 * 1000);
    return timeDifferenceHours;
  };
  const handleJoinMeeting = () => {
    console.log("going to meeting inside");

    if (singleData?.slot) {
      let timeDifferenceHours = findingDifference(singleData?.slot);
      setTimeDifferenceForProfile(timeDifferenceHours);
      //  he meeting if the time difference is within 5 minutes before the meeting time
      if (timeDifferenceHours <= 0.0833) {
        let idToSend = "";
        if (userData?._id === singleData?.slot?.bookedby) {
          idToSend = singleData?.creatorId;
        } else {
          idToSend = singleData?.slot?.bookedby;
        }
        let data = {
          sessionData: singleData,
          isMentor: true,
          idToSend,
        };
        // const data2 = {
        //   id: userData?._id,
        //   meetingId: singleData?.slot?._id,
        //   token: token,
        // };
        dispatch(setSessionData(data));
        history.push("/meetingpage");
        SuccessToast("Welcome to your session");
        // dispatch(addRelability(data2)).then((res) => {
        //   if (res?.payload?.status === 200) {
        //     setRelaible(res?.payload?.data);
        //     dispatch(setSessionData(data));
        //     history.push("/meetingpage");
        //     SuccessToast("Welcome to the meeting.");
        //   }
        // });
      } else {
        ErrorToast("Meeting not started yet  ");
      }
    }
  };

  const individualRating = (elem) => {
    // Use optional chaining and default values to handle potential undefined values
    const totalRatings = Number(elem?.ratting?.totalRatting) || 0;
    const totalReviews = Number(elem?.ratting?.totalReviews) || 0;
    // Calculate the average individual rating
    const averageIndividual = totalRatings / totalReviews;
    return isNaN(averageIndividual) ? 0 : averageIndividual;
  };

  const backtoBooking = () => {
    setSelectedComponent(0);
  };
  const handleCancelSessionClick = (elem) => {
    console.log("handleCancelSessionClick Elem", elem);
    const datacancel = {
      creatorId: elem?.creatorId,
      meetingId: elem?.slot?._id,
      token: token,
    };
    setLoader(true);
    dispatch(cancelSessionNetwork(datacancel)).then((res) => {
      if (res?.payload?.status === 200) {
        SuccessToast(res?.payload?.data?.message);
        dispatch(mentorSession(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setMySessionData(res?.payload?.data);
            setMySessionDataTemp(res?.payload?.data);
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };
  return (
    <>
      {isViewMentor ? (
        <MentorProfile
          setIsViewMentor={setIsViewMentor}
          singleData={singleData}
          handleJoinMeeting={handleJoinMeeting}
          timeDifferenceForProfile={timeDifferenceForProfile}
          individualRating={individualRating}
        />
      ) : (
        <>
          <div className="networkdb__mysessions__container min__height__100">
            {!loader ? (
              <>
                <div className="networkdb__mysessions__container__top">
                  <p className="networkdb__mysessions__container__top__title">
                    <span className="networkdb__mysessions__container__top__title__sub">
                      {mySessionData.length}
                    </span>{" "}
                    Sessions
                  </p>
                  <div className="networkdb__mysessions__container__top__right">
                    {/* <Dropdown onSelect={handleDropdownSelect}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="filter__button"
                      >
                        <img src={SortIcon} className="filter__button__image" />
                        <span className="filter__button__text">{dropdown}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                        <Dropdown.Item
                          onClick={() => setDropDown("All")}
                          className="custom__dropdown__item"
                          eventKey="All"
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Newest"
                          className="custom__dropdown__item"
                        >
                          Newest
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="custom__dropdown__item"
                          eventKey="Oldest"
                        >
                          Oldest
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <Dropdown onSelect={handleDropdownSelectServices}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="filter__button"
                      >
                        <img src={SortIcon} className="filter__button__image" />
                        <span className="filter__button__text">
                          {dropdownServices}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                        <Dropdown.Item
                          eventKey="General Guidance"
                          className="custom__dropdown__item"
                        >
                          General guidance
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="custom__dropdown__item"
                          eventKey="CV Review"
                        >
                          CV review
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="custom__dropdown__item"
                          eventKey="Cover Letter Review"
                        >
                          Cover letter review
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="custom__dropdown__item"
                          eventKey="Interview Practice"
                        >
                          Interview practice
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* change grid list view button */}
                    {/* <div className="listgridview">
                    <div
                      className={`listgridview__left ${
                        isGrid ? "grid__active" : "grid__disabled"
                      }`}
                      onClick={() => setIsGrid(true)}
                    >
                      <img src={GridGreen} alt="grid button" />
                    </div>
                    <div
                      className={`listgridview__right ${
                        isGrid ? "grid__disabled" : "grid__avctive"
                      }`}
                      onClick={() => setIsGrid(false)}
                    >
                      <img src={ListView} alt="list button" />
                    </div>
                  </div> */}
                  </div>
                </div>
                {mySessionData?.length > 0 ? (
                  <>
                    <div className="networkdb__mysessions__container__bottom">
                      <Row className="">
                        {mySessionDataTemp.map((elem, index) => (
                          <Col
                            style={{ marginBottom: "20px" }}
                            md={isGrid ? 6 : 12}
                            lg={isGrid ? 4 : 12}
                            key={elem.id}
                          >
                            <MySessionCard startTime={elem.time}>
                              <div className="mysession__container__top">
                                <img
                                  src={
                                    elem?.userProfile[0]?.image || AvatarIcon
                                  }
                                  alt="avatar icon"
                                  className="mysession__container__top__image"
                                />
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="dots__button"
                                  >
                                    <img
                                      src={Dots}
                                      alt="see more"
                                      className="dots__button__dots"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                    <Dropdown.Item
                                      className="custom__dropdown__item"
                                      onClick={() =>
                                        handleMentorProfileClick(elem)
                                      }
                                    >
                                      View Mentor Profile
                                    </Dropdown.Item>
                                    {findingDifference(elem?.slot) > 1 ? (
                                      <Dropdown.Item
                                        className="custom__dropdown__item"
                                        onClick={() =>
                                          handleCancelSessionClick(elem)
                                        }
                                      >
                                        Cancel Session
                                      </Dropdown.Item>
                                    ) : (
                                      ""
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="mysession__container__center">
                                <h2 className="mysession__container__center__title">
                                  {elem?.userData[0]?.firstName}{" "}
                                  {elem?.userData[0]?.lastName}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "AktivGrotesk-Regular",
                                      background: "#46494D",
                                      padding: "6px 8px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {elem?.slot?.sessionType}
                                  </span>
                                </h2>
                                <div className="mysession__container__center__top">
                                  <div className="mysession__container__center__top__container">
                                    <p>
                                      {moment(elem?.slot?.startDateTime).format(
                                        "HH:mm"
                                      )}
                                      -{" "}
                                      {moment(elem?.slot?.endDateTime).format(
                                        "HH:mm"
                                      )}
                                      ,{" "}
                                      {moment(elem?.slot?.endDateTime).format(
                                        "dddd, MMMM D"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="mysession__container__center__top">
                                  <div className="mysession__container__center__top__container">
                                    <p
                                      style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        WebkitLineClamp: 3,
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {elem?.userProfile[0]?.background
                                        ? elem?.userProfile[0]?.background
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{ alignItems: "flex-end" }}
                                  className="mysession__container__center__ratings"
                                >
                                  {/* <Ratings /> */}
                                  <Rating
                                    readonly={true}
                                    initialValue={individualRating(elem)}
                                    allowFraction={true}
                                    fillIcon={
                                      <img src={StarFull} width={16} alt="" />
                                    }
                                    emptyIcon={
                                      <img
                                        src={EmptyStar}
                                        width={16}
                                        alt={""}
                                      />
                                    }
                                    size={20}
                                    fillColor="#F9A63A"
                                  />

                                  {/* <p>(12 Session)</p> */}
                                  <p>
                                    ({" "}
                                    {
                                      // elem?.relability?.count +
                                      elem?.relability?.booked
                                    }{" "}
                                    Interviews{" "}
                                    {/* {elem?.realibility?.count &&
                                    elem?.realibility?.booked
                                      ? (
                                          (Number(elem?.realibility?.booked) /
                                            Number(elem?.realibility?.count)) *
                                          100
                                        ).toFixed(0)
                                      : "0"} */}
                                   )
                                  </p>
                                </div>
                              </div>
                              <div className="mysession__container__bottom">
                                <button
                                  onClick={() => getSingleData(elem)}
                                  className={`join__session__button join__session__enabled `}
                                >
                                  Join session
                                </button>
                              </div>
                            </MySessionCard>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="nofound__container"
                  >
                    <div className="nofound__container__inner">
                      <div className="nofound__container__inner__image">
                        <img src={noFound} />
                      </div>
                      <div className="nofound__container__inner__container">
                        <div className="nofound__container__inner__container__title">
                          No sessions found
                        </div>
                        <div className="nofound__container__inner__container__text">
                          Sessions booked by you would appear on this page
                        </div>
                      </div>

                      <button
                        onClick={() => backtoBooking()}
                        className="nofound__container__inner__button"
                      >
                        Book sessions
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <ScreenLoader />
            )}
          </div>
          {/* modal for detail */}

          {modalShow && (
            <SessionModal
              show={modalShow}
              setShow={setModalShow}
              style={{ width: "670px !important", padding: "0 !important" }}
            >
              <div
                className="modal__content__container"
                style={{ display: "block" }}
              >
                <h2 className="modal__content__container__title">Scheduled</h2>
                <div className="modal__content__container__bottom__left__topics__pills"></div>
              </div>
              <Row className="modal__content__container__bottom">
                <Col
                  md={12}
                  className="modal__content__container__bottom__left"
                  style={{ padding: "0", paddingRight: "10px" }}
                >
                  <div className="modal__content__container__bottom__left__difficulty">
                    <div className="modal__content__container__bottom__left__difficulty__inner">
                      <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                        Interview guide difficulty level{" "}
                      </h2>
                      <img
                        src={LevelIcon}
                        alt="level"
                        className="modal__content__container__bottom__left__difficulty__inner__image"
                      />
                    </div>
                    <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                      <div className="pill-grey">
                        {selectedSlot?.dificultyLevel}
                        {/* Advanced */}
                      </div>
                    </div>
                  </div>
                  <div className="modal__content__container__bottom__left__topics">
                    <div className="modal__content__container__bottom__left__topics__inner">
                      <h2 className="modal__content__container__bottom__left__topics__inner__title">
                        Topics
                      </h2>
                      <img
                        src={DocumentIcon}
                        alt=""
                        className="modal__content__container__bottom__left__topics__inner__image"
                      />
                    </div>
                    <div className="modal__content__container__bottom__left__topics__pills">
                      {[
                        "Fit",
                        "Brainteasers",
                        "Accounting",
                        "Valuation",
                        "DCF",
                        "LBO",
                        "Merger Model",
                      ].map((item) => (
                        <div className="pill-grey modal__content__container__bottom__left__topics__pills__pill">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="modal__content__container__footer">
                <button
                  // disabled={!singleData?.slot?.meetingLink}
                  onClick={handleJoinMeeting}
                  className="btn-green"
                >
                  Go to Meeting
                </button>
              </div>
              {/* )
          } */}
            </SessionModal>
          )}
        </>
      )}
    </>
  );
};

export default MySessions;
