import React, { Children } from "react";

const MySessionCard = ({ children, heightSp = false }) => {
  return (
    <div
      className={`mysession__container ${
        heightSp ? "my-session-card-height" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default MySessionCard;
