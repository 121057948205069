import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Tab, Tabs } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
//
import arrowLeft from "../../../assets/arrow-left.svg";
import MessageIcon from "../../../assets/messages-rating.svg";
import LogoIcon from "../../../assets/8.svg";
import LinkIcon from "../../../assets/link-square.svg";
import EmptyStar from "../../../assets/star-rating-empty.svg";
import FullStar from "../../../assets/star-rating-full.svg";
//
import { ErrorToast, SuccessToast } from "../../MyToaster";
import "./MeetingPage2.scss";
import SessionModal, { ConfirmModal } from "../../SessionModal/SessionModal";
import { useDispatch } from "react-redux";
import {
  getInterviewGuide,
  getInterviewGuideMentor,
} from "../../../service/redux/middleware/interviewGuide";
import { setSessionData } from "../../../service/redux/reducer/meetingSlice";

import LoaderSmall from "../../Loader/Loadersmall";
import { useSelector } from "react-redux";
import ScreenLoader from "../../Loader/ScreenLoader";
import {
  addRating,
  addRatingbyMentor,
} from "../../../service/redux/middleware/rating";
import axios from "axios";
import { API_URL } from "../../../service/client";
import { useHistory } from "react-router-dom";
//
import { socket } from "../../../socket";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { addRelability } from "../../../service/redux/middleware/relaible";
// import { BookmarkIcon, FileIcon, ThumbnailIcon } from '@react-pdf-viewer/default-layout';

// import { Document, Page } from 'react-pdf';

//
//
const MeetingPageMentor = () => {
  // const [numPages, setNumPages] = useState();
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess(obj) {
  //   var numPages = obj.numPages;
  //   setNumPages(numPages);
  // }

  const [showModalJoin, setShowModalJoin] = useState(false);
  const [showRatingModalCandidate, setShowRatingModalCandidate] =
    useState(false);
  const [loader, setLoader] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [interviewGuide, setInterviewGuide] = useState([]);
  const [ratingPresence, setRatingPresence] = useState(0);
  const [ratingCommunication, setRatingCommunication] = useState(0);
  const [ratingFit, setRatingFit] = useState(0);
  const [ratingTechnical, setRatingTechnical] = useState(0);
  //
  const [name, setName] = useState("");
  const [isMeetingEnded, setIsMeetingEnded] = useState(false);
  const [rattingStar, setRattingStar] = useState(false);

  //
  const history = useHistory();
  // const { meetingId, name, idToSend } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const { idToSend, sessionData } = useSelector((state) => state?.meetingData);
  const handleCloseCall = () => {
    if (!isMeetingEnded) {
      ErrorToast("Please close the video call to proceed.");
    } else {
      if (rattingStar) {
        history.push("/mentordashboard");
      } else {
        setShowRatingModalCandidate(true);
      }
      // if (!rattingStar) {
      //   setShowRatingModalCandidate(true);
      // }
    }
  };
  const getMeetingEnded = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/getmeetingEnded/${sessionData?.mentorAvalible?._id}/${idToSend}`
      );
      const result = await response.data;
      setIsMeetingEnded(result.message);
      setRattingStar(result.ratting);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmitRating = () => {
    if (
      ratingPresence > 0 &&
      ratingCommunication > 0 &&
      ratingFit > 0 &&
      ratingTechnical
    ) {
      let data = {
        studentId: idToSend,
        meetingId: sessionData?.mentorAvalible?._id,
        presence: ratingPresence,
        communication: ratingCommunication,
        fitQuestion: ratingFit,
        technicalQuestion: ratingTechnical,
      };
      setIsSubmitted(true);
      dispatch(addRatingbyMentor(data)).then((res) => {
        if (res?.payload?.status === 200) {
          SuccessToast(res?.payload?.data);
          setShowRatingModalCandidate(false);
          getMeetingEnded();
          history.push("/mentordashboard");

          resetingState();
          window.onbeforeunload = null;
        } else {
          ErrorToast(res?.payload?.message);
        }
        setIsSubmitted(false);
      });
      //
      // api integration
    } else {
      ErrorToast("Please fill in rating to proceed.");
    }
  };

  const handlePresence = (rate) => {
    setRatingPresence(rate);
  };
  const handleCommunication = (rate) => {
    setRatingCommunication(rate);
  };
  const handleFit = (rate) => {
    setRatingFit(rate);
  };
  const handleTechnical = (rate) => {
    setRatingTechnical(rate);
  };
  const resetingState = () => {
    setRatingPresence(0);
    setRatingCommunication(0);
    setRatingFit(0);
    setRatingTechnical(0);
  };
  //
  //
  const getName = async () => {
    try {
      const response = await axios.get(`${API_URL}/getName/${idToSend}`);
      const result = await response.data;
      setName(result);
    } catch (error) {
      console.log(error.message);
    }
  };
  //

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    // setShowModalJoin(true);
    getName();
    getMeetingEnded();
    setLoader(true);
    const data = {
      meetingId: sessionData?.mentorAvalible?._id,
      userId: userData?._id,
    };

    socket.on("connect", () => {
      console.log("the socket i connected");
      // audio.play();
      socket.emit("join", userData?._id);
    });
    // socket
    socket.on("meetingEnded", getMeetingEnded);
    socket.on("meetingEndedCreator", getMeetingEnded);
    socket.on("meetingEndedCreatorMentor", getMeetingEnded);
    socket.on("meetingEndedMentor", getMeetingEnded);
    //
    dispatch(getInterviewGuideMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        console.log("this is the interview guid",res?.payload?.data)
        setInterviewGuide(res?.payload?.data);
      } else {
        setLoader(false);
      }
    });
    //
    //
    return () => {
      socket.off("meetingEnded", getMeetingEnded);
      socket.off("meetingEndedCreator", getMeetingEnded);
      socket.off("meetingEndedCreatorMentor", getMeetingEnded);
      socket.off("meetingEndedMentor", getMeetingEnded);

      window.onbeforeunload = null;
      let data = {
        sessionData: {},
        isMentor: false,
        idToSend: "",
      };
      dispatch(setSessionData(data));
    };
  }, []);
  //
  //

  //
  //
  function confirmExit(e) {
    return "show message";
  }
  const backToInterview = () => {
    history.push("/mentordashboard");
  };

  // const newplugin = defaultLayoutPlugin()

  const [showPdf, setShowPdf] = useState(false);

  const [userPDF, setUserPDF] = useState("");
  const topicOrder = [
    'Intro',
    'Fit',
    'Situational',
    'IB',
    'Accounting concepts',
    'Accounting scenarios',
    'Enterprise value',
    'Valuation',
    'DCF',
    'Other',
    'Brainteasers',
  ];

  const openPDF = () => {
    setShowPdf(true);
  };

  //   const defaultLayoutPluginInstance = defaultLayoutPlugin(
  //     sidebarTabs: defaultTabs => {
  //         ...
  //     },
  // );
  const openMeeting = () => {
    const data2 = {
      id: userData?._id,
      meetingId: sessionData?.mentorAvalible?._id,
      token: token,
    };
    dispatch(addRelability(data2)).then((res) => {
      if (res?.payload?.status === 200) {
      }
    });
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="container__custom meeting2__container min__height__100">
        <Link to="/mentordashboard" className="meeting2__container__top">
          <img src={LogoIcon} alt="logo" />
        </Link>
        <div className="accordian__main meetingpage__top">
          <p
            className="dashboard__title"
            onClick={backToInterview}
            style={{ cursor: "pointer" }}
          >
            <img src={arrowLeft} />
            <span className="ms-2"> Back to Mentor</span>{" "}
          </p>
          {/* <button
                className="meeting2__container__center__top__right__button"
                onClick={openPDF}
              >
                View Cover Letter or CV
              </button> */}
        </div>
        <div className="meeting2__container__center">
          <div className="meeting2__container__center__top">
            <div className="meeting2__container__center__top__left">
              <h2 className="meeting2__container__center__top__left__heading text-capitalize">
                {/* Interview guide with {name?.firstName} {name?.lastName} */}
                {sessionData?.mentorAvalible?.sessionType} with{" "}
                {name?.firstName} {name?.lastName}
              </h2>
              {sessionData?.mentorAvalible?.sessionType ===
                "Interview Practice" && (
                <p className="meeting2__container__center__top__left__text">
                  Beginner Guides
                </p>
              )}
            </div>
            <div className="meeting2__container__center__top__right">
              <a
                className="meeting2__container__center__top__right__button"
                target="_blank"
                href={`${sessionData?.mentorAvalible?.meetingLink}`}
                onClick={openMeeting}
              >
                Open meeting
              </a>
              <button
                className="meeting2__container__center__top__right__button"
                onClick={handleCloseCall}
              >
                Exit meeting
              </button>
            </div>
          </div>
          <div className="meeting2__container__center__inner">
            {sessionData?.mentorAvalible?.sessionType !==
              "Interview Practice" &&
              sessionData?.mentorAvalible?.cv && (
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
                >
                  <Viewer fileUrl={sessionData?.mentorAvalible?.cv} />
                </Worker>
              )}
            {/* </>
            } */}
            {sessionData?.mentorAvalible?.sessionType ===
              "Interview Practice" && (
              <div className="meeting2__container__center__inner__container">
                {interviewGuide[0]?.interViewGuidData?.answeredQuestions
                  .length > 0 && (
                  <div className="">
                    <Row className="flex-column-reverse flex-md-row">
                      <Col xs={12} md={12} className="p-0">
                        <section className="faqs border-grad1">
                          <div className="asked__heading">
                            <p className="dashboard__title">
                              <span className="ms-2"> You will ask</span>
                            </p>
                          </div>
                          <div className="mobile-responsive-table accordion__meeting">
                            <Accordion defaultActiveKey="0">
                              {/* myInterviewGuideData[0]?.interViewGuidData?.answeredQuestions */}
                              {interviewGuide[0]?.interViewGuidData?.answeredQuestions
                                .sort(
                                  (a, b) =>
                                    topicOrder.indexOf(a.text.split(" - ")[0]) -
                                    topicOrder.indexOf(b.text.split(" - ")[0])
                                )
                                .map((elem, index) => (
                                  <Accordion.Item
                                    eventKey={elem?._id}
                                    style={{ background: "#1A1C1F" }}
                                  >
                                    <Accordion.Header>
                                      {/* <span className='numberingss'>{index + 1}.</span>  */}
                                      {/* {elem?.query} */}
                                      {elem?.text}
                                    </Accordion.Header>
                                    <Accordion.Body
                                      style={{ background: "#1A1C1F" }}
                                    >
                                      <div className="my-tabs">
                                        <Tabs
                                          defaultActiveKey="answer"
                                          id="fill-tab-example"
                                          className="mb-3"
                                          fill
                                        >
                                          <Tab eventKey="answer" title="Answer">
                                            <p className="para">
                                              {elem?.answer}
                                            </p>
                                          </Tab>
                                          <Tab
                                            eventKey="scorecard"
                                            title="Scorecard"
                                          >
                                            <p className="para">{elem?.scoreCard}</p>
                                          </Tab>
                                        </Tabs>
                                      </div>
                                      {/* <p className="para">{elem?.answer}</p> */}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                ))}
                            </Accordion>
                          </div>
                        </section>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal show={showModalJoin} setShow={setShowModalJoin}>
        <div className="join__meeting__modal">
          <h2 className="join__meeting__modal__heading">
            Welcome to your interview guide
          </h2>
          <p className="join__meeting__modal__text">
            We have provided a zoom link for your meeting, which holds on a
            <br /> separate page
          </p>
          <div className="join__meeting__modal__link">
            <img
              src={LinkIcon}
              alt="linkicon"
              className="join__meeting__modal__link__iamge"
            />
            <p className="join__meeting__modal__link__text">
              {sessionData?.mentorAvalible?.meetingLink}
            </p>
          </div>
          <a
            className="join__meeting__modal__button"
            target="_blank"
            href={`${sessionData?.mentorAvalible?.meetingLink}`}
          >
            Join Meeting
          </a>
        </div>
      </ConfirmModal>
      {/* <SessionModal show={showRatingModal} setShow={setShowRatingModal}>
        <div className="rating__modal">
          <div className="rating__modal__body">
            <h2 className="rating__modal__body__heading">Rate Interview</h2>
            <div className="rating__modal__body__ratings">
              <p className="rating__modal__body__ratings__text">
                How did{" "}
                <span className="rating__modal__body__ratings__text__bold">
                  {name?.firstName} {name?.lastName}
                </span>{" "}
                do as an interviewer?
              </p>
              <div className="rating__modal__body__ratings__container ">
                <div className="rating__modal__body__ratings__container__inner">
                  <Rating
                    onClick={handleRating}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
              </div>
            </div>
            <div className="rating__modal__body__input">
              <img
                src={MessageIcon}
                className="rating__modal__body__input__image"
              />
              <input
                type="text"
                className="rating__modal__body__input__input"
                placeholder="Add Comment or Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
          </div>
          <div className="rating__modal__footer">
            <button
              className="rating__modal__footer__button"
              onClick={handleNextClickInterview}
            >
              Next
            </button>
          </div>
        </div>
      </SessionModal> */}
      {/* multiple ratings */}
      <SessionModal
        show={showRatingModalCandidate}
        setShow={setShowRatingModalCandidate}
      >
        <div className="rating__modal">
          <div className="rating__modal__body">
            <h2 className="rating__modal__body__heading">Rate Candidate</h2>
            <div className="rating__modal__body__ratings">
              <p className="rating__modal__body__ratings__text">
                How did{" "}
                <span className="rating__modal__body__ratings__text__bold">
                  {name?.firstName} {name?.lastName}
                </span>{" "}
                do as a candidate?
              </p>
              <div className="border__top__rating mt__rating"></div>
              <div className="rating__modal__body__ratings__container ">
                <div className="rating__modal__body__ratings__container__inner ">
                  <p>Presence</p>
                  <Rating
                    onClick={handlePresence}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Communication Skills</p>
                  <Rating
                    onClick={handleCommunication}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Fit Questions</p>
                  <Rating
                    onClick={handleFit}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Technical Questions</p>
                  <Rating
                    onClick={handleTechnical}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rating__modal__footer">
            <button
              className="rating__modal__footer__button"
              onClick={handleSubmitRating}
            >
              <p>Submit</p>
              {isSubmitted && <LoaderSmall />}
            </button>
          </div>
        </div>
      </SessionModal>
    </>
  );
};

export default MeetingPageMentor;
