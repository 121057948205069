import React, { useState } from "react";
import ArrowLeft from "../../assets/arrow-left.svg";
import { Col, Dropdown, Row } from "react-bootstrap";
import NavBar from "../Navbar/NavBar";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentsForm";
//
const PUBLIC_KEY =
  "pk_test_51NaveQLkDgZqqKO4GEMHpl514CG9zCeQwnyTVpZ9juu2WPj3jFgBz34c1B8HF13C9wqMEQl1HUSxVrrVhI7fjtwv00pr9DxyVI";
const stripePromise = loadStripe(PUBLIC_KEY);
//
const Buycredits = () => {
  const [sessionSelected, setSessionSelected] = useState("");
  const [sessionSelectedOption, setSessionSelectedOption] = useState({});
  //
  const sessionOptions = [
    {
      value: 5,
      price: 100,
      sessions: 5,
    },
    {
      value: 10,
      price: 150,
      sessions: 10,
    },
    {
      value: 20,
      price: 200,
      sessions: 20,
    },
  ];

  //
  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    appearance: {
      theme: "Stripe",
      variables: {
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        fontSizeBase: "20px",
        spacingUnit: "2px",
        borderRadius: "8px",
        colorPrimary: "#bc05ff",
        colorBackground: "#2e3033",
        colorText: "#b9bdc7",
        colorDanger: "#ff0033",
      },
      rules: {
        ".Label": {
          marginBottom: "8px",
        },

        ".Action": {
          color: "red!important",
        },
      },
    },
  };
  const history = useHistory();
  const back = () => {
    history.push("/interviewdashboard");
  };

  const handleSelect = (selectedOption) => {
    var toSet = sessionOptions.find(
      (item) => item.value === Number(selectedOption)
    );
    setSessionSelectedOption(toSet);
  };

  return (
    <>
      <NavBar />
      <div className="network__booksession__payment min__height__100">
        <div className="network__booksession__payment__top">
          <div
            className="network__booksession__payment__top__container"
            onClick={back}
          >
            <img src={ArrowLeft} alt="left arrow" />
            <p className="marginTop">Back to Dashboard</p>
          </div>
        </div>

        <Row className="network__booksession__payment__bottom  flex-column-reverse flex-md-row">
          <Col md={7} className="network__booksession__payment__bottom__left">
            <h3 className="network__booksession__payment__bottom__left__heading">
              Payment details
            </h3>
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm
                sessionSelectedOption={sessionSelectedOption}
                amount={sessionSelectedOption?.price}
              />
            </Elements>
          </Col>
          <Col
            style={{ padding: "0" }}
            md={5}
            className="network__booksession__payment__bottom__right"
          >
            <h2 className="network__booksession__payment__bottom__right__heading">
              Order summary
            </h2>
            <label htmlFor="select-payout" className="DropDownPayment">
              <p>Select sessions to buy</p>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdownToSelect select__icon"
                >
                  <p className="forumm__button__text">
                    {sessionSelectedOption?.sessions
                      ? `${sessionSelectedOption?.sessions} Sessions`
                      : "Please select sessions to buy"}
                  </p>
                </Dropdown.Toggle>
                <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                  <Dropdown.Item
                    eventKey={"5"}
                    className="dropdownToSelect__item"
                  >
                    5 sessions
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="10"
                    className="dropdownToSelect__item"
                  >
                    10 sessions
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"20"}
                    className="dropdownToSelect__item"
                  >
                    20 sessions
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </label>
            <label className="network__booksession__payment__bottom__right__inputlabel">
              <span>Enter discount code </span>
              <input type="text" placeholder="Promo99" />
            </label>
            <div className="network__booksession__payment__bottom__right__container">
              <p className="network__booksession__payment__bottom__right__container__heading">
                Order details
              </p>
              <p className="network__booksession__payment__bottom__right__container__text">
                {!sessionSelectedOption?.value
                  ? "Please select sessions to buy"
                  : `${sessionSelectedOption?.sessions} Sessions Package`}
              </p>
            </div>

            <div className="network__booksession__payment__bottom__right__container">
              <p className="network__booksession__payment__bottom__right__container__heading">
                Total price
              </p>
              <p className="network__booksession__payment__bottom__right__container__textprice">
                {!sessionSelectedOption?.value
                  ? "€0"
                  : `€${sessionSelectedOption.price} `}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Buycredits;
