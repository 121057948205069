import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";
import { API_URL } from "../../client";
import axios from "axios";

// export const createMeeting = createAsyncThunk("createMeeting", async (data) => {
//   try {
//     const res = await client.post(`/api/createMeeting`, {
//       creatorId: data?.creatorId,
//       qualification: data?.qualification,
//       meetingDate: data?.meetingDate,
//       startTime: data?.startTime,
//       endTime: data?.endTime,
//       timezone: data?.timezone,
//       timeFormat: data?.timeFormat,
//     }, { headers: { authorization: `${data?.token}` } });
//     return { status: res.status, data: res.data?.data };
//   } catch (error) {
//     return {
//       message: error.response.data.error,
//       status: error.response.status,
//     };
//   }
// });

// export const showMeeting = createAsyncThunk("showMeeting", async (data) => {
//   try {
//     const res = await client.get(`/api/showMeeting`, { headers: { authorization: `${data?.token}` } });
//     return { status: res.status, data: res.data };
//   } catch (error) {
//     return {
//       message: error.response.data.error,
//       status: error.response.status,
//     };
//   }
// });

export const bookMeeting = createAsyncThunk("bookMeeting", async (data) => {
  try {
    const res = await client.post(
      `/api/bookMeeting/${data?.userId}`,
      {
        bookedby: data.bookedBy,
        id: data.id,
        difficultyLevel: data?.difficultyLevel,
      },
      { headers: { authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

// export const viewBookedMeeting = createAsyncThunk(
//   "viewBookedMeeting",
//   async (data) => {
//     try {
//       const res = await client.get(`/api/ViewBookedMeeting`, { headers: { authorization: `${data?.token}` } });
//       return { status: res.status, data: res.data };
//     } catch (error) {
//       return {
//         message: error.response.data.error,
//         status: error.response.status,
//       };
//     }
//   }
// );

export const getMeetingTime = createAsyncThunk(
  "getMeetingTime",
  async (data) => {
    try {
      const res = await client.get(`/api/getAvalibility/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const cancelSession = createAsyncThunk("cancelSession", async (data) => {
  try {
    const config = {
      headers: {
        authorization: `${data?.token}`,
      },
      data: {
        meetingId: data?.meetingId,
      },
    };
    const res = await axios.delete(
      `${API_URL}/api/cancleSession/${data?.creatorId}`,
      config
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const reScheduleForSession = createAsyncThunk(
  "reSchedule",
  async (data) => {
    try {
      const res = await client.post(
        `/api/reSchedule/${data?.userId}`,
        {
          timezone: data?.timezone,
          timeZoneValue: data?.timeZoneValue,
          date: data?.date,
          startTime: data?.startTime,
          endTime: data?.endTime,
          meetingId: data?.meetingId,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const deleteMeeting = createAsyncThunk("deleteMeeting", async (data) => {
  try {
    const config = {
      headers: {
        authorization: `${data?.token}`,
      },
      data: {
        meetingId: data?.meetingId,
        creatorId: data?.creatorId,
      },
    };
    const res = await axios.delete(`${API_URL}/api/deleteMeeting`, config);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});








export const reScheduleForMySession = createAsyncThunk(
  "reSchedule",
  async (data) => {
    try {
      const res = await client.post(`/api/rescheduleBookSession/${data?.creatorId}`,
        {
          timezone: data?.timezone,
          timeZoneValue: data?.timeZoneValue,
          date: data?.date,
          startTime: data?.startTime,
          endTime: data?.endTime,
          meetingId: data?.meetingId,
          userId:data?.userId,
          
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const reScheduleForMySessionConfirm = createAsyncThunk(
  "reSchedule",
  async (data) => {
    try {
      const res = await client.post(`/api/rescheduleBookSessionConfirm/${data?.creatorId}`,
        {
          timezone: data?.timezone,
          timeZoneValue: data?.timeZoneValue,
          date: data?.date,
          startTime: data?.startTime,
          endTime: data?.endTime,
          meetingId: data?.meetingId,
          userId:data?.userId,
          conflictingId:data?.conflictingId
          
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);