import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast } from "../components/MyToaster";
import { resetPassword } from "../service/redux/middleware/password";
import NavLogo from "../assets/8.svg";
import Form from "react-bootstrap/Form";
import eyeIcon from "../../src/assets/eyeIcon.svg";
import { useParams } from "react-router-dom";
import eyeSlashIcon from "../../src/assets/eye-slash.svg";

const ForgetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [onSuccess, setOnSuccess] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let { token } = useParams();
  const mytoken = useSelector((state) => state?.signin?.signInData?.token);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const formValidation = () => {
    const passwordRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    const isPasswordValid = passwordRegex.test(password);
    const isPasswordValidLength = password.length < 8;

    if (!password) {
      ErrorToast("Please enter new password.");
      return false;
    } else if (!isPasswordValid) {
      ErrorToast("Invalid password. Your password must include a special character.");
      return false;
    } else if (isPasswordValidLength) {
      ErrorToast("Invalid password. Your password must include a minimum length of 8 characters");
      return false;
    } else if (!confirmPassword) {
      ErrorToast("Please enter confirm password");
      return false;
    } else if (password !== confirmPassword) {
      ErrorToast("Invalid password. The passwords you have entered do not match.");
      return false;
    }
  };

  const onResetPassword = (e) => {
    e?.preventDefault();
    const res = formValidation();
    if (res === false) {
      return false;
    }
    const data = {
      token: token,
      newPassword: password,
      mytoken: mytoken,
    };
    dispatch(resetPassword(data)).then((res) => {
      if (res?.payload?.status === 200) {
        history.push("/");
        // setOnSuccess(true);
      }
    });
    // }
  };

  // const onResetPassword = (e) => {
  //     if (!oldPassword) {
  //         ErrorToast("Please enter old password");
  //         return;
  //     } else if (!password) {
  //         ErrorToast("Please enter new password")
  //         return;
  //     }
  //     else if (!confirmPassword) {
  //         ErrorToast("Please enter confirm password")
  //         return;
  //     }
  //     e?.preventDefault()
  //     if (password !== confirmPassword) {
  //         ErrorToast("Password and confirm password does not match");
  //     } else {
  //         const data = {
  //             email: userData,
  //             currentPassword: oldPassword,
  //             newPassword: password
  //         }
  //     }
  // };

  return (
    <>
      {/* <div className="container">
                <div className="form-box">
                    <div className="body-form">
                        <form>
                            <div className="input-group mb-3">
                                <input type="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    className="form-control" placeholder="new Password" />
                            </div>
                            <div className="input-group mb-3">
                                <input type="password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }}
                                    className="form-control" placeholder="confirm Password" />
                            </div>
                            <button type="button" className="btn btn-secondary btn-block"
                                onClick={(e) => {
                                    // navigate("/");
                                    onResetPassword(e);
                                }}
                            >Reset Password</button>

                        </form>
                    </div>
                    {onSuccess && (
                        <p
                            className="text-center"
                            fontSize="14px"
                            weight="400"
                            color="#11AF22"
                        >
                            You successfully changed your password
                        </p>
                    )}
                </div>
            </div> */}
      <div className="PersonalInfoSec">
        <div className="widthDiv" style={{ width: "400px" }}>
          <div className="PersonalInfoSec__nav">
            <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="" />
          </div>
          <div className="PersonalInfoSec__Heading">
            <h1 className="">Reset your Password</h1>
            <p className="">Let’s get you a new passwords</p>
          </div>
          <div className="PersonalInfoSecForm">
            <Form>
              <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  New Password
                </Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  className="loginfield__type resetOptionlabelMargin"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <img
                  style={{ top: "59%" }}
                  className="loginfield__img__11"
                  src={!showPassword ? eyeIcon : eyeSlashIcon}
                  onClick={() => setShowPassword(!showPassword)}
                  alt=""
                />
              </Form.Group>
              <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  type={showPassword1 ? "text" : "password"}
                  className="loginfield__type"
                  placeholder="Type Password Again "
                  alue={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <img
                  style={{ top: "59%" }}
                  className="loginfield__img__11"
                  src={!showPassword1 ? eyeIcon : eyeSlashIcon}
                  onClick={() => setShowPassword1(!showPassword1)}
                  alt=""
                />
              </Form.Group>
              <button
                className="login__green__button"
                onClick={(e) => {
                  onResetPassword(e);
                }}
              >
                Reset
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgetPassword;
