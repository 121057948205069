import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./signup.scss";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import emailIcon from "../../src/assets/emailIcon.svg";
import linkdin from "../../src/assets/linkdinIcon.svg";
import googleIcon from "../../src/assets/googleIcon.svg";
import Login from "../../src/Login/Login";
import GreenTick from "../assets/greenTick.svg";
import FastLogo from "../assets/8.svg";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import MyLoader from "../components/Loader/MyLoader";
import { Link } from "react-router-dom";
import ArrowRightGreen from "../assets/arrow-right-green.svg";
import {
  signinGoogleAuth,
  signinLinkedAuth,
} from "../service/redux/middleware/signin";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import LinkedInButton from "./LinkedInButton";

const SingUpActive = () => {
  //   const [val, setValue] = useState('')
  //   useEffect(() => {
  //     const url = window.location.pathname
  //     var s = url.split("/").join("");
  //     setValue(s)
  //   }, [])

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("login");
  const [loader, setLoader] = useState(false);
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const history = useHistory();
  const SignUpWithEmail = () => {
    history.push("/signupEmail");
  };

  const clientId =
    "1017307220638-e5l4r43nti312ksp06bjmdsk931nt9ih.apps.googleusercontent.com";
  // const clientId = "550417388956-o8n6kg03s8h6qdvc0jb3cqr3f1jfhbpt.apps.googleusercontent.com"

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  const loginSuccess = (res) => {
    // setLoader(true)
    const { googleId, name, imageUrl, email } = res.profileObj;
    const { id_token } = res.tokenObj;
    history.push("/interviewdashboard");
    // setLoader(false)
  };

  const failure = (res) => {};

  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      const data = {
        email: datas?.data?.email,
        familyName: datas?.data?.family_name,
        givenName: datas?.data?.given_name,
      };
      // var decoded = jwt_decode(tokenResponse?.access_token?.credential);
      dispatch(signinGoogleAuth(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          if (res?.payload?.data?.role === 0) {
            if (res?.payload?.data?.profileUpdated) {
              SuccessToast("Log in successfully");
              history.push("/interviewdashboard");
              // const timer = setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
              // return () => clearTimeout(timer);
            } else {
              SuccessToast("Profile Update Your Profile to Continue.");
              history.push("/personalInfo");
              // const timer = setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
              // return () => clearTimeout(timer);
            }
          } else {
            if (res?.payload?.data?.profileUpdated) {
              history.push("/mentordashboard");
              // window.location.reload();
            }
          }
        } else {
          ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    },
  });

  return (
    <div className="SignUPSection container__custom">
      <div className="SignUPSection__main row justify-content-center">
        {/* {activeTab === "first" ? (
          <div className="d-none d-md-block col-md-4 SignUPSection__main__empty">
            <a href="https://www.fast-track.community/">
              <img
                src={FastLogo}
                className="SignUPSection__main__empty__image"
              />
            </a>

            <div className="SignUPSection__main__empty__content">
              <h1>
                Join the leading investment <br /> banking community for
                aspiring bankers
              </h1>
              <div className="SignUPSection__main__empty__content__list">
                <div className="SignUPSection__main__empty__content__list__item">
                  <img src={GreenTick} alt=""></img>
                  <p>Here we add different points on how fast track can help</p>
                </div>
                <div className="SignUPSection__main__empty__content__list__item">
                  <img src={GreenTick} alt=""></img>
                  <p>Here we add different points on how fast track can help</p>
                </div>
                <div className="SignUPSection__main__empty__content__list__item">
                  <img src={GreenTick} alt=""></img>
                  <p>Here we add different points on how fast track can help</p>
                </div>
                <div className="SignUPSection__main__empty__content__list__item">
                  <img src={GreenTick} alt=""></img>
                  <p>Here we add different points on how fast track can help</p>
                </div>
                <div className="SignUPSection__main__empty__content__list__item">
                  <img src={GreenTick} alt=""></img>
                  <p>Here we add different points on how fast track can help</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-none d-md-block col-md-4 SignUPSection__main__empty__login">
            <a href="https://www.fast-track.community/">
              <img
                src={FastLogo}
                className="SignUPSection__main__empty__image"
              />
            </a>
            <div className="SignUPSection__main__empty__login__content">
              <h1>
                Learn more on the go from
                <br /> peers and mentors, from all
                <br /> over the world
              </h1>
            </div>
          </div>
        )} */}

        <div className="col-12 col-sm-8 col-md-8">
          {/* <div
            className="justify-content-center remove-elements"
            style={{ position: "absolute", top: "0%", left: "45%" }}
          >
            <img src={FastLogo} className="SignUPSection__main__empty__image" />
          </div> */}
          <div className="small_screen__logo">
            <a href="https://www.fast-track.community/">
              <img
                src={FastLogo}
                className="SignUPSection__main__empty__image"
              />
            </a>
          </div>
          <div className="bar-bar-bar"></div>
          <div className="d-flex flex-column-reverse flex-md-column ">
            <div className="become__mentor__div margin__mentor">
              <div
                className="become__mentor"
                onClick={() => history.push("/mentor")}
              >
                {/* <p className="become__mentor__text ">Become a Mentors</p>
                <img
                  src={ArrowRightGreen}
                  alt="arrow-green"
                  className="become__mentor__image"
                /> */}
              </div>
            </div>
            <div className="SignUPSection__main__formSection">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={activeTab}
                className=""
                onSelect={handleTabSelect}
              >
                <div className="col-11 col-md-5">
                  <div className=" SignUPSection__main__formSection__title">
                    {activeTab === "signup" ? "Create a" : "Log into your"}{" "}
                    fast_track account
                  </div>
                  <div className="text__text__text">
                    {activeTab === "signup"
                      ? "Join the leading investment banking community for aspiring bankers"
                      : "Learn more on the go from peers and mentors, from all over the world"}
                  </div>
                  <Nav className="flex-row Toggler justify-content-center ">
                    <Nav.Item
                      className={`col-6 text-center ${
                        activeTab === "signup"
                          ? "Toggler__itemactive"
                          : "Toggler__itemunactive"
                      }`}
                    >
                      <Nav.Link
                        onClick={() => {
                          history.push("/signup");
                        }}
                        className={
                          activeTab === "signup"
                            ? "Toggler__itemactive__textactive"
                            : "Toggler__itemunactive__untextactive"
                        }
                        eventKey="signup"
                      >
                        Sign up
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                      className={`col-6 text-center ${
                        activeTab === "login"
                          ? "Toggler__itemactive"
                          : "Toggler__itemunactive"
                      }`}
                    >
                      {/* <Nav.Link eventKey="login" onClick={clickHandler}> */}
                      <Nav.Link
                        onClick={() => {
                          history.push("/login");
                        }}
                        className={
                          activeTab === "login"
                            ? "Toggler__itemactive__textactive"
                            : "Toggler__itemunactive__untextactive"
                        }
                        eventKey="login"
                      >
                        Log in
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Login />
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingUpActive;
