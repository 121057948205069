import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Tab, Tabs } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
//
import arrowLeft from "../../../assets/arrow-left.svg";
import MessageIcon from "../../../assets/messages-rating.svg";
import LogoIcon from "../../../assets/8.svg";
import LinkIcon from "../../../assets/link-square.svg";
import EmptyStar from "../../../assets/star-rating-empty.svg";
import FullStar from "../../../assets/star-rating-full.svg";
//
import { ErrorToast, SuccessToast } from "../../MyToaster";
import "./MeetingPage2.scss";
import SessionModal, { ConfirmModal } from "../../SessionModal/SessionModal";
import { useDispatch } from "react-redux";
import { getInterviewGuide } from "../../../service/redux/middleware/interviewGuide";
import { setSessionData } from "../../../service/redux/reducer/meetingSlice";

import LoaderSmall from "../../Loader/Loadersmall";
import { useSelector } from "react-redux";
import ScreenLoader from "../../Loader/ScreenLoader";
import {
  addRating,
  addRatingNetwork,
} from "../../../service/redux/middleware/rating";
import axios from "axios";
import { API_URL } from "../../../service/client";
import { useHistory } from "react-router-dom";
//
import { socket } from "../../../socket";
import HeaderLandingPage from "../../HeaderForLandingPage/HeaderLandingPage";
import { HeaderLandingModal } from "../../Custom/Modal/ModalCustom";
import stepArrow from "../../../assets/meeting/arrow-rightStep.svg";
import HeaderLandingPageForModal from "../../HeaderForLandingPage/HeaderLandingPageForModal";
import lock from "../../../assets/meeting/lock.svg";
import {
  addRelability,
  addRelabilityMentor,
} from "../../../service/redux/middleware/relaible";

//
//
const MeetingPage = () => {
  const [showModalJoin, setShowModalJoin] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showRatingModalNetwork, setShowRatingModalNetwork] = useState(false);
  const [showRatingModalCandidate, setShowRatingModalCandidate] =
    useState(false);
  const [loader, setLoader] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [interviewGuide, setInterviewGuide] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingPresence, setRatingPresence] = useState(0);
  const [ratingCommunication, setRatingCommunication] = useState(0);
  const [ratingFit, setRatingFit] = useState(0);
  const [ratingTechnical, setRatingTechnical] = useState(0);
  const [ratting, setRatting] = useState(false);
  //
  const [name, setName] = useState("");
  const [isMeetingEnded, setIsMeetingEnded] = useState(false);

  // const { meetingId, name, idToSend } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const { idToSend, sessionData, isMentor } = useSelector(
    (state) => state?.meetingData
  );

  const getMeetingEnded = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/getmeetingEnded/${sessionData?.slot?._id}/${idToSend}`
      );
      const result = await response.data;
      setIsMeetingEnded(result.message);
      setRatting(result.ratting);
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const handleCloseCall = () => {
    // if (ratting) {
    //   ErrorToast("Please close the video call to proceed.");
    // } else {
    //   if (isMentor) {
    //     setShowRatingModalNetwork(true);
    //   } else {
    //     setShowRatingModal(true);
    //   }
    // }
    if (!isMeetingEnded) {
      ErrorToast("Please close the video call to proceed.");
    } else {
      if (!ratting) {
        if (isMentor) {
          setShowRatingModalNetwork(true);
        } else {
          setShowRatingModal(true);
        }
      } else {
        history.push("/interviewdashboard");
      }
    }
  };
  //
  const handleNextClickInterview = () => {
    if (rating > 0) {
      // if (feedback !== '') {
      //
      // setShowRatingModal(false);
      setShowRatingModalCandidate(true);
      // } else {
      // ErrorToast('Please fill in rating to proceed.')
      // }
    } else {
      ErrorToast("Please fill in rating to proceed.");
    }
  };
  //
  const handleNextClickNetwork = () => {
    if (rating > 0) {
      if (feedback !== "") {
        const data = {
          studentId: userData?._id,
          mentorId: idToSend,
          interview: rating,
          feedback: feedback,
          meetingId: sessionData?.slot?._id,
        };
        // chage add Rating to mentor rating
        dispatch(addRatingNetwork(data)).then((res) => {
          if (res?.payload?.status === 200) {
            SuccessToast(res?.payload?.data);
            setShowRatingModalNetwork(false);
            resetingState();
            getMeetingEnded();
            history.push("/networkdashboard");
            window.onbeforeunload = null;
            setIsSubmitted(false);
          } else {
            ErrorToast(res?.payload?.message);
            setIsSubmitted(false);
          }
        });
        // api call
      } else {
        ErrorToast("Please give some feedback to proceed.");
      }
    } else {
      ErrorToast("Please fill in rating to proceed.");
    }
  };

  //
  const handleSubmitRating = () => {
    if (
      ratingPresence > 0 &&
      ratingCommunication > 0 &&
      ratingFit > 0 &&
      ratingTechnical
    ) {
      let data = {
        studentId: idToSend,
        meetingId: sessionData?.slot?._id,
        interview: rating,
        presence: ratingPresence,
        communication: ratingCommunication,
        fitQuestion: ratingFit,
        technicalQuestion: ratingTechnical,
        // feedback: feedback,
      };
      setIsSubmitted(true);
      dispatch(addRating(data)).then((res) => {
        if (res?.payload?.status === 200) {
          SuccessToast(res?.payload?.data);
          setShowRatingModal(false);
          setShowRatingModalCandidate(false);
          resetingState();
          getMeetingEnded();

          history.push("/interview");
          // window.onbeforeunload = null;
          setIsSubmitted(false);
        } else {
          ErrorToast(res?.payload?.message);
          setIsSubmitted(false);
        }
      });
      //
      // api integration
    } else {
      ErrorToast("Please fill in rating to proceed.");
    }
  };
  //
  const handleRating = (rate) => {
    setRating(rate);
  };
  const handlePresence = (rate) => {
    setRatingPresence(rate);
  };
  const handleCommunication = (rate) => {
    setRatingCommunication(rate);
  };
  const handleFit = (rate) => {
    setRatingFit(rate);
  };
  const handleTechnical = (rate) => {
    setRatingTechnical(rate);
  };
  const resetingState = () => {
    setFeedback("");
    setRating(0);
    setRatingPresence(0);
    setRatingCommunication(0);
    setRatingFit(0);
    setRatingTechnical(0);
  };
  //
  //
  const getName = async () => {
    try {
      const response = await axios.get(`${API_URL}/getName/${idToSend}`);
      const result = await response.data;
      setName(result);
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log("this is the type", typeof getMeetingEnded);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    // setShowModalJoin(true);
    getName();
    getMeetingEnded();
    setLoader(false);
    // socket for meeting end
    socket.on("connect", () => {
      console.log("the socket i connected");
      // audio.play();
      socket.emit("join", userData?._id);
    });

    socket.on("meetingEnded", getMeetingEnded);
    socket.on("meetingEndedCreator", getMeetingEnded);
    socket.on("meetingEndedCreatorMentor", getMeetingEnded);
    socket.on("meetingEndedMentor", getMeetingEnded);

    if (!isMentor) {
      setLoader(true);
      const data = {
        meetingId: sessionData?.slot?._id,
        userId: idToSend,
      };
      dispatch(getInterviewGuide(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
        
          setInterviewGuide(res?.payload?.data);
        } else {
          setLoader(false);
        }
      });
    }
    //
    //
    return () => {
      socket.off("meetingEnded", getMeetingEnded);
      socket.off("meetingEndedCreator", getMeetingEnded);
      socket.off("meetingEndedCreatorMentor", getMeetingEnded);
      socket.off("meetingEndedMentor", getMeetingEnded);

      window.onbeforeunload = null;
      let data = {
        sessionData: {},
        isMentor: false,
        idToSend: "",
      };
      dispatch(setSessionData(data));
    };
  }, []);
  //
  //
  //
  function confirmExit(e) {
    return "show message";
  }
  const backToInterview = () => {
    if (isMentor) {
      history.push("/network");
    } else {
      history.push("/interview");
    }
  };
  //
  const [headerModal, seHeaderModal] = useState(false);
  //
  //
  const openMeeting = () => {
    if (isMentor) {
      const data3 = {
        id: userData?._id,
        meetingId: sessionData?.slot?._id,
        token: token,
      };
      dispatch(addRelabilityMentor(data3)).then((res) => {
        if (res?.payload?.status === 200) {
        }
      });
    } else {
      const data2 = {
        id: userData?._id,
        meetingId: sessionData?.slot?._id,
        token: token,
      };
      dispatch(addRelability(data2)).then((res) => {
        if (res?.payload?.status === 200) {
        }
      });
    }
  };
  const topicOrder = [
    "Intro",
    "Fit",
    "Situational",
    "IB",
    "Accounting concepts",
    "Accounting scenarios",
    "Enterprise value",
    "Valuation",
    "DCF",
    "Other",
    "Brainteasers",
  ];
  //
  //

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="container__custom meeting2__container min__height__100">
        <Link to="/" className="meeting2__container__top">
          <img src={LogoIcon} alt="logo" />
        </Link>
        <HeaderLandingPage home={false} />
        <div className="guest__landingpage" onClick={() => seHeaderModal(true)}>
          <img src={stepArrow} />
          <div className="ms-2 truncate-text" style={{ lineHeight: "20px" }}>
            See our simple step-by-step guide
          </div>
        </div>
        <HeaderLandingModal
          headerModal={headerModal}
          seHeaderModal={seHeaderModal}
        >
          <HeaderLandingPageForModal home={false} />
        </HeaderLandingModal>
        <>
          {/* <div className="main__tab">
          <div className="inner__tab">
            <div  className="center__div" >
              <div className="main__of__content">
                <img src={message} alt="" />
              </div>
              <p className="my__text">Two windows open</p>
            </div>
            <img className="right__img" src={arrowright} alt="" />
            <div  className="center__div">
              <div className="main__of__content">
                <img src={split} alt="" />
              </div>
              <p className="my__text">Split screen</p>
            </div>
            <img className="right__img"  src={arrowright} alt="" />
            <div className="center__div">
              <div className="main__of__content">
                <img src={videoSlash} alt="" />
              </div>
              <p className="my__text">Exit video</p>
            </div>
            <img className="right__img"  src={arrowright} alt="" />
            <div className="center__div">
              <div className="main__of__content">
                <img src={logoutImg} alt="" />
              </div>
              <p className="my__text exit">Exit meeting room</p>
            </div>

          </div>
        </div> */}
          {/* <HeaderLandingPage/> */}
          {/* <div className="accordian__main meetingpage__top">
          <p
            className="dashboard__title"
            onClick={backToInterview}
            style={{ cursor: "pointer" }}
          >
            <img src={arrowLeft} />
            <span className="ms-2">
              {" "}
              Back to {isMentor ? "Network" : "Interview"}
            </span>{" "}
          </p>
        </div> */}
        </>
        <div className="meeting2__container__center">
          <div className="meeting2__container__center__top">
            <div className="meeting2__container__center__top__left">
              {isMentor ? (
                <h2 className="meeting2__container__center__top__left__heading ">
                  Interview with {name?.firstName} {name?.lastName}
                </h2>
              ) : (
                <>
                  <h2 className="meeting2__container__center__top__left__heading text-capitalize">
                    Interview Guide with {name?.firstName} {name?.lastName}
                  </h2>
                  <p className="meeting2__container__center__top__left__text">
                    {sessionData?.difficultyLevel} Guide
                  </p>
                </>
              )}
            </div>
            <div className="meeting2__container__center__top__right">
              <a
                className="meeting2__container__center__top__right__button"
                target="_blank"
                href={`${sessionData?.slot?.meetingLink}`}
                onClick={openMeeting}
              >
                Open meeting
              </a>
              <button
                className="meeting2__container__center__top__right__button"
                onClick={handleCloseCall}
              >
                Exit meeting
              </button>
            </div>
          </div>
          {isMentor ? (
            <div>
              <div className="candidate__guide__main__main">
                <div className="heading">Candidate guide</div>
                <div>
                  <img src={lock} />
                </div>
              </div>
              <div className="complete__complete">
                Complete interview with Mentor to unlock
              </div>
            </div>
          ) : (
            <div className="meeting2__container__center__inner">
              <div className="meeting2__container__center__inner__container">
                {interviewGuide?.data?.length > 0 && (
                  <div className="">
                    <Row className="flex-column-reverse flex-md-row">
                      <Col xs={12} md={12} className="p-0">
                        <section className="faqs border-grad1">
                          <div className="asked__heading">
                            <h2 className="dashboard__title">You were ask</h2>
                          </div>
                          <div className="mobile-responsive-table accordion__meeting">
                            <Accordion defaultActiveKey="0">
                              {interviewGuide?.data
                                .sort(
                                  (a, b) =>
                                    topicOrder.indexOf(a.text.split(" - ")[0]) -
                                    topicOrder.indexOf(b.text.split(" - ")[0])
                                )
                                .map((elem, index) => (
                                  <Accordion.Item
                                    eventKey={elem?._id}
                                    style={{ background: "#1A1C1F" }}
                                  >
                                    <Accordion.Header>
                                      {elem?.text}
                                    </Accordion.Header>
                                    <Accordion.Body
                                      style={{ background: "#1A1C1F" }}
                                    >
                                      <div className="my-tabs">
                                        <Tabs
                                          defaultActiveKey="answer"
                                          id="fill-tab-example"
                                          className="mb-3"
                                          fill
                                        >
                                          <Tab eventKey="answer" title="Answer">
                                            <p className="para"> 
                                              {elem?.answer}
                                            </p>
                                          </Tab>
                                          <Tab
                                            eventKey="scorecard"
                                            title="Scorecard"
                                          >
                                            <p className="para">
                                              {elem?.scoreCard}
                                            </p>
                                          </Tab>
                                        </Tabs>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                ))}
                            </Accordion>
                          </div>
                        </section>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Confirmation modal */}
      <ConfirmModal show={showModalJoin} setShow={setShowModalJoin}>
        <div className="join__meeting__modal">
          <h2 className="join__meeting__modal__heading">
            Welcome to your interview guide
          </h2>
          <p className="join__meeting__modal__text">
            We have provided a zoom link for your meeting, which holds on a
            <br /> separate page
          </p>
          <div className="join__meeting__modal__link">
            <img
              src={LinkIcon}
              alt="linkicon"
              className="join__meeting__modal__link__iamge"
            />
            <p className="join__meeting__modal__link__text">
              {sessionData?.slot?.meetingLink}
            </p>
          </div>
          <a
            className="join__meeting__modal__button text-decoration-none"
            href={`${sessionData?.slot?.meetingLink}`}
            target="_blank"
          >
            Join Meeting
          </a>
        </div>
      </ConfirmModal>
      {/* Rating network Modal */}
      <SessionModal
        show={showRatingModalNetwork}
        setShow={setShowRatingModalNetwork}
      >
        <div className="rating__modal">
          <div className="rating__modal__body">
            <h2 className="rating__modal__body__heading">Rate Interview</h2>
            <div className="rating__modal__body__ratings">
              <p className="rating__modal__body__ratings__text">
                How did{" "}
                <span className="rating__modal__body__ratings__text__bold">
                  {name?.firstName} {name?.lastName}
                </span>{" "}
                do as an interviewer?
              </p>
              <div className="rating__modal__body__ratings__container ">
                <div className="rating__modal__body__ratings__container__inner">
                  <Rating
                    onClick={handleRating}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
              </div>
            </div>
            <div className="rating__modal__body__input">
              <img
                src={MessageIcon}
                className="rating__modal__body__input__image"
              />
              <input
                type="text"
                className="rating__modal__body__input__input"
                placeholder="Add Comment or Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
          </div>
          <div className="rating__modal__footer">
            <button
              className="rating__modal__footer__button"
              onClick={handleNextClickNetwork}
            >
              Submit
            </button>
          </div>
        </div>
      </SessionModal>
      {/* Rating interview modal Modal */}
      <SessionModal show={showRatingModal} setShow={setShowRatingModal}>
        <div className="rating__modal">
          <div className="rating__modal__body">
            <h2 className="rating__modal__body__heading">Rate Interview</h2>
            <div className="rating__modal__body__ratings">
              <p className="rating__modal__body__ratings__text">
                How did{" "}
                <span className="rating__modal__body__ratings__text__bold">
                  {name?.firstName} {name?.lastName}
                </span>{" "}
                do as an interviewer?
              </p>
              <div className="rating__modal__body__ratings__container ">
                <div className="rating__modal__body__ratings__container__inner">
                  <Rating
                    onClick={handleRating}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rating__modal__footer">
            <button
              className="rating__modal__footer__button"
              onClick={handleNextClickInterview}
            >
              Next
            </button>
          </div>
        </div>
      </SessionModal>
      {/* multiple ratings */}
      <SessionModal
        show={showRatingModalCandidate}
        setShow={setShowRatingModalCandidate}
      >
        <div className="rating__modal">
          <div className="rating__modal__body">
            <h2 className="rating__modal__body__heading">Rate Candidate</h2>
            <div className="rating__modal__body__ratings">
              <p className="rating__modal__body__ratings__text">
                How did{" "}
                <span className="rating__modal__body__ratings__text__bold">
                  {name?.firstName} {name?.lastName}
                </span>{" "}
                do as a candidate?
              </p>
              <div className="border__top__rating mt__rating"></div>
              <div className="rating__modal__body__ratings__container ">
                <div className="rating__modal__body__ratings__container__inner ">
                  <p>Presence</p>
                  <Rating
                    onClick={handlePresence}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Communication skills</p>
                  <Rating
                    onClick={handleCommunication}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Fit questions</p>
                  <Rating
                    onClick={handleFit}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
                <div className="rating__modal__body__ratings__container__inner">
                  <p>Technical Questions</p>
                  <Rating
                    onClick={handleTechnical}
                    // allowFraction={true}
                    transition={true}
                    fillIcon={<img src={FullStar} width={25} />}
                    emptyIcon={<img src={EmptyStar} width={25} />}
                    size={25}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rating__modal__footer">
            <button
              className="rating__modal__footer__button"
              onClick={handleSubmitRating}
            >
              <p>Submit</p>
              {isSubmitted && <LoaderSmall />}
            </button>
          </div>
        </div>
      </SessionModal>
    </>
  );
};

export default MeetingPage;
