import React, { useState, useEffect } from "react";
import "./inteerview.scss";
import { MySessionCard } from "../Custom";
import { Col, Row } from "react-bootstrap";
// icons
import AvatarIcon from "../../assets/avatar.svg";
import Dots from "../../assets/dot.svg";
import level from "../../assets/level.svg";
import SortIcon from "../../assets/sort.svg";
import GridGreen from "../../assets/grid-green.svg";
import ListView from "../../assets/menu.svg";
import { useSelector, useDispatch } from "react-redux";
//
import Dropdown from "react-bootstrap/Dropdown";
import MyAccordion from "../MyAccordion/MyAccordion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getInterviewGuideForUser } from "../../service/redux/middleware/interviewGuide";
import ScreenLoader from "../Loader/ScreenLoader";
import moment from "moment";
import noFound from "../../assets/Frame 160829.svg";
import emptyState from "../../assets/books.svg";

const Interview = ({ setSelectedComponent, mySessionData, SetMySessionData, mySessionDataTemp, setMySessionDataTemp }) => {
  const [show, setShow] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [loader, setLoader] = useState(false);
  const [interviewGuides, setInterviewGuides] = useState([]);
  const [selectedInterviewGuides, setSelectedInterviewGuides] = useState({});
  const [dropdown, setDropDown] = useState("Filter");
  useEffect(() => {
    // window.location.reload("http://localhost:3000/interview")
  }, []);

  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleViewGuide = (item) => {
    setSelectedInterviewGuides(item);
    setShow(true);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getInterviewGuideForUser(userData?._id)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setInterviewGuides(res?.payload.data?.data);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const GotoBooking = () => {
    setSelectedComponent(0);
  };

  const handleDropdownSelect = (eventKey) => {
    setDropDown(eventKey);
    filter(eventKey);
  };
  const filter = (option) => {
    if (option === "All") {
      setMySessionDataTemp([]);
      setMySessionDataTemp(() => mySessionData);
    } else if (option === "Oldest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.mentorAvalible.startDateTime) {
          startTime = sessionA.mentorAvalible.startDateTime;
        } else {
          if (sessionA.mentorAvalible.length === 1) {
            startTime = sessionA.mentorAvalible.startDateTime;
          } else {
            let filteredData = sessionA.mentorAvalible.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d2 - d1;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.mentorAvalible.startDateTime) {
          startTime2 = sessionB.mentorAvalible.startDateTime;
        } else {
          if (sessionB.mentorAvalible.length === 1) {
            startTime2 = sessionB.mentorAvalible.startDateTime;
          } else {
            let filteredData2 = sessionB.mentorAvalible.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d2 - d1;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeB - startTimeA;
      });
      setMySessionDataTemp(filter);
    } else if (option === "Newest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.mentorAvalible.startDateTime) {
          startTime = sessionA.mentorAvalible.startDateTime;
        } else {
          if (sessionA.mentorAvalible.length === 1) {
            startTime = sessionA.mentorAvalible.startDateTime;
          } else {
            let filteredData = sessionA.mentorAvalible.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d1 - d2;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.mentorAvalible.startDateTime) {
          startTime2 = sessionB.mentorAvalible.startDateTime;
        } else {
          if (sessionB.mentorAvalible.length === 1) {
            startTime2 = sessionB.mentorAvalible.startDateTime;
          } else {
            let filteredData2 = sessionB.mentorAvalible.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d1 - d2;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeA - startTimeB;
      });
      setMySessionDataTemp(filter);
    }
  };

  return (
    <>
      {loader && <ScreenLoader />}
      {!show ? (
        <div className="interview__container">
          <div className="interview__container__top">
            <p className="interview__container__top__title">
              <p>
                {interviewGuides?.length > 0 ? interviewGuides?.length : "0"}
                <span> Sessions</span>{" "}
              </p>
            </p>
            <div className="interview__container__top__bottom">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="filter__button">
                  <img src={SortIcon} className="filter__button__image" />
                  <span className="filter__button__text">{dropdown}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                  <Dropdown.Item className="custom__dropdown__item" onClick={() => setDropDown("All")}
                    eventKey="All">
                    All
                  </Dropdown.Item>
                  {/* <Dropdown.Item className="custom__dropdown__item" href="">
                    Sessions by Me
                  </Dropdown.Item> */}
                  <Dropdown.Item className="custom__dropdown__item" eventKey="Newest">
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item className="custom__dropdown__item" eventKey="Oldest">
                    Oldest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* change grid list view button */}
              
            </div>
          </div>
          <div className="interview__container__bottom">
            <Row className="gy-4">
              {!loader ? (
                interviewGuides?.length > 0 ? (
                  interviewGuides?.map((guide, index) => (
                    <Col
                      xs={12}
                      md={isGrid ? 6 : 12}
                      lg={isGrid ? 4 : 12}
                      key={guide?.UserProfile?.userId}
                    >
                      <MySessionCard>
                        <div className="interview__card__top">
                          <img
                            src={guide?.UserProfile?.image || AvatarIcon}
                            alt="avatar icon"
                            className="interview__card__top__image"
                          />
                          <>
                            {/* <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="dots__button"
                          >
                            <img
                              src={Dots}
                              alt="see more"
                              className="dots__button__dots"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                            <Dropdown.Item
                              className="custom__dropdown__item"
                              href=""
                            >
                              Delete Guide
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                            {/* <img src={Dots} alt="see more" className="interview__card__top__dots" /> */}
                          </>
                        </div>
                        <div
                          className={`${
                            !isGrid
                              ? "interview__card__flex__btn__container"
                              : "interview__card__flex__btn__container__mobile"
                          }`}
                        >
                          <div className="interview__card__center">
                            <h2 className="interview__card__center__title">
                              Session with {guide?.UserData?.firstName}{" "}
                              {guide?.UserData?.lastName}
                            </h2>

                            <div className="interview__card__center__bottom">
                              {/* <img
                              src={level}
                              alt="graduation icon"
                              className="interview__card__center__bottom__image"
                            /> */}
                              <p>{guide?.avail?.difficultyLevel} guide</p>
                            </div>
                            <div className="interview__card__center__top">
                              <div className="interview__card__center__top__container">
                                <p>
                                  {moment(guide?.avail?.startDateTime).format(
                                    "HH:mm"
                                  )}
                                  -
                                  {moment(guide?.avail?.endDateTime).format(
                                    "HH:mm"
                                  )}
                                  ,{" "}
                                  {moment(guide?.avail?.endDateTime).format(
                                    "dddd, MMMM D"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleViewGuide(guide)}
                            className={`btn-green`}
                          >
                            View guide
                          </button>
                        </div>{" "}
                      </MySessionCard>
                    </Col>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="nofound__container"
                  >
                    <div className="nofound__container__inner">
                      <div className="nofound__container__inner__image">
                        <img src={emptyState} />
                      </div>
                      <div className="nofound__container__inner__container">
                        <div className="nofound__container__inner__container__title">
                          You currently have no interview guides
                        </div>
                        <div className="nofound__container__inner__container__text">
                          Interview guides from sessions taken would appear on
                          this page
                        </div>
                      </div>

                      <button
                        className="nofound__container__inner__button"
                        onClick={GotoBooking}
                      >
                        Book sessions
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <ScreenLoader />
              )}
            </Row>
          </div>
        </div>
      ) : (
        <MyAccordion
          setShow={setShow}
          selectedGuide={selectedInterviewGuides}
        />
      )}
    </>
  );
};

export default Interview;
