import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";
import { API_URL } from "../../client";
import axios from "axios";

export const getForum = createAsyncThunk("getForum", async () => {
  try {
    const res = await axios.get(`${API_URL}/getForum`);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const createForum = createAsyncThunk("createForum", async (data) => {
  try {
    const res = await client.post(`/createForum/${data?.id}`, {
      title: data?.title,
      topic: data?.topic,
      discussion: data?.discussion,
    });
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const commentForum = createAsyncThunk("commentForum", async (data) => {
  try {
    const res = await client.post(`/forum/${data?.forumId}/comment`, {
      userId: data?.userId,
      text: data?.text,
    });
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.message,
      status: error.response.status,
    };
  }
});

export const likeForum = createAsyncThunk("likeForum", async (data) => {
  try {
    const res = await client.post(`/forum/${data?.forumId}/like`, {
      userId: data?.userId,
    });
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const likesUserComment = createAsyncThunk("likeForum", async (data) => {
  try {
    const res = await client.post(
      `/forum/${data?.forumId}/comment/${data?.commentId}/like`,
      {
        userId: data?.userId,
      }
    );
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const unlikesUserComment = createAsyncThunk(
  "likeForum",
  async (data) => {
    try {
      const res = await client.post(
        `/forum/${data?.forumId}/comment/${data?.commentId}/unlike`,
        {
          userId: data?.userId,
        }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const getForumSingleData = createAsyncThunk(
  "getSingleForum",
  async (data) => {
    try {
      const res = await client.get(`/getSingleForum/${data}`, {
        userId: data?.userId,
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);


export const getRelatedTopic = createAsyncThunk(
  "getRelatedTopic",
  async (data) => {
    try {
      const res = await client.get(`/forums/${data}/related`, {
        userId: data?.userId,
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);



