import React, { useState, useEffect } from "react";
import LeftArrow from "../../../assets/arrow-left.svg";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../../../assets/edit.svg";
import TrashIcon from "../../../assets/trash.svg";
import addIcon from "../../../assets/add.svg";
import noFound from "../../../assets/Frame 160829.svg";
import "./editPurposeScreen.scss";
// import Buycredits from "../../Buycredits/Buycredits";

import EditPusposeSesion from "./EditPusposeSesion";
import ProposeSession from "../ProposeSession/ProposeSession";
import moment from "moment";
import { deleteMeeting } from "../../../service/redux/middleware/meeting";
import { mySessionNotBooked } from "../../../service/redux/middleware/session";
import { useSelector, useDispatch } from "react-redux";
import { SuccessToast, ErrorToast } from "../../MyToaster";
import ScreenLoader from "../../Loader/ScreenLoader";
import { ConfirmModal } from "../../SessionModal/SessionModal";
import { useHistory } from "react-router-dom";
const EditPurposeScreen = ({
  setSessionDataBook,
  setShowEditProposeScreen,
  notBookedSessionData,
  setNotBookedSessionData,
  myCredits,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showEditSchedules, setShowEditSchedules] = useState(false);
  const [showPropose, setShowPropose] = useState(false);
  const [singleData, setSingleData] = useState("");
  const getSingleData = (elem) => {
    setSingleData(elem);
    setShowEditSchedules(true);
  };
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const dispatch = useDispatch();

  const delMySchedules = (elem) => {
    setDelModal(true);
    setSingleData(elem);
  };

  const data = {
    userId: userData?._id,
    token: token,
  };

  const deleteSlot = () => {
    const data1 = {
      creatorId: userData?._id,
      meetingId: singleData?.slot?._id,
      token: token,
    };
    setLoader(true);
    dispatch(deleteMeeting(data1)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setDelModal(false);
        SuccessToast("Your session was removed successfully");
        dispatch(mySessionNotBooked(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setNotBookedSessionData(res?.payload?.data);
          } else {
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
        setDelModal(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };

  const [delModal, setDelModal] = useState(false);

  const history = useHistory();

  const CheckCredits = () => {
    if (myCredits?.credits > 0) {
      setShowPropose(true);
    } else {
      ErrorToast("Please buy sessions before proceeding");
      history.push("/buycredits");

    }
  };

  const changePath = () => {
    setShowEditProposeScreen(false);
  };

  const sortedSessionData = notBookedSessionData
    ?.filter((item) => item?.slot?.startDateTime)
    .sort(
      (a, b) => moment(a.slot.startDateTime) - moment(b.slot.startDateTime)
    );

  return showEditSchedules ? (
    <EditPusposeSesion
      setShowEditSchedules={setShowEditSchedules}
      singleData={singleData}
      setNotBookedSessionData={setNotBookedSessionData}
    />
  ) : showPropose ? (
    <ProposeSession
      setShowPropose={setShowPropose}
      setSessionDataBook={setSessionDataBook}
      setNotBookedSessionData={setNotBookedSessionData}
    />
  ) : (
    <div>
      <>
        {loader && <ScreenLoader />}
        <div className="reschedule__session__container">
          <div className="reschedule__session__container__top">
            <div
              className="reschedule__session__container__top__left"
              onClick={() => changePath()}
            >
              <img
                src={LeftArrow}
                alt="left arrow"
                className="reschedule__session__container__top__left__icon"
              />
              <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
                Back to Book sessions
              </p>
            </div>
          </div>
          <div className="reschedule__session__container__bottom">
            <Row style={{minHeight:"50vh"}} className="reschedule__session__container__bottom__row">
              {notBookedSessionData?.length > 0 ? (
                <Col
                  md={8}
                  lg={10}
                  xl={8}
                  className="reschedule__session__container__bottom__left"
                >
                  <div className="reschedule__session__container__bottom__left__form mb__edit__propose margin-propose-32">
                    <button className="propose-btn" onClick={CheckCredits}>
                      <img src={addIcon} alt="addicon" />{" "}
                      <span className="ms-2">Propose new session</span>
                    </button>
                  </div>
                  <div className="editschedules__container__bottom__container__top">
                    <div className="editschedules__container__bottom__container__top__bottom">
                      <div className="editschedules__container__bottom__container__top__bottom__title flex__container__avaiability">
                        <h1>
                          {" "}
                          Availabilities (
                          {notBookedSessionData?.length > 0
                            ? notBookedSessionData?.length
                            : notBookedSessionData?.length}
                          ){" "}
                        </h1>
                        <p>{userTimeZone}</p>
                      </div>
                      {notBookedSessionData?.length > 0 ? (
                        <>
                          {sortedSessionData?.map((item) => (
                            <div className="editschedules__editcard__container">
                              <p className="editschedules__editcard__container__text textdot">
                                {moment(item?.slot?.startDateTime).format(
                                  "HH:mm"
                                )}
                                <span className="ms-2">-</span>
                                <span className="ms-2">
                                  {moment(item?.slot?.endDateTime).format(
                                    "HH:mm"
                                  )}{" "}
                                  ,
                                </span>

                                <span className="ms-2">
                                  {moment(item?.slot?.startDateTime).format(
                                    "MMMM D, YYYY"
                                  )}
                                </span>
                              </p>
                              <div className="editschedules__editcard__container__inner">
                                <img
                                  onClick={() => getSingleData(item)}
                                  src={EditIcon}
                                  alt="edit icon"
                                  style={{ cursor: "pointer" }}
                                  className="editschedules__editcard__container__inner__image"
                                />
                                <img
                                  onClick={() => delMySchedules(item)}
                                  src={TrashIcon}
                                  style={{ cursor: "pointer" }}
                                  alt="trash icon"
                                  className="editschedules__editcard__container__inner__image"
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="editschedules__editcard__container">
                          <p className="editschedules__editcard__container__text">
                            No Data
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "75vh",
                  }}
                  className="reschedule__session__container__bottom__left"
                >
                  <div className="no__found__main">
                    <div>
                      <img src={noFound} />
                    </div>
                    <div className="no__found">
                      You currently have no proposed sessions
                    </div>
                    <div className="appear__here">
                      Sessions you propose would appear on this page
                    </div>
                    <button
                      className="newpropose__button"
                      onClick={CheckCredits}
                    >
                      <img src={addIcon} alt="addicon" /> Propose new session
                    </button>
                  </div>
                </div>
              )}
            </Row>
          </div>
        </div>
        {/* session confirm modal */}
        <ConfirmModal show={delModal} setShow={() => setDelModal(false)}>
          <div className="confirmModal__main ">
            <div className="heading__heading">Delete Slot</div>
            <div className="para__para__para ">
              Are you sure you want to delete this meeting ?
            </div>
            <hr />
            <div className="confirm__btn__main">
              <button className="confirm__btn" onClick={deleteSlot}>
                Confirm
              </button>
              <button
                className="cancel__btn"
                onClick={() => setDelModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmModal>
      </>
    </div>
  );
};

export default EditPurposeScreen;
