import React, { useEffect, useState, lazy } from "react";
import "./App.scss";
import SideBar from "./components/SideBar/SideBar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Slot from "./components/Slot/Slot";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import InterviewGuides from "./components/InterviewGuides/Interview";
import Performance from "./components/Performance/Performance";
import Login from "./Login/Login";
import SingUp from "./Login/SignUp";
import ForgetPassword from "./Login/ForgetPassword";
import ChangePassword from "./Login/ChangePassword";
import ResetPassword from "./Login/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Login/Profile";
import UpdateProfile from "./Login/UpdateProfile";
import CustomModal from "./components/Custom/Modal/ModalCustom";
// import BookSessionInterview from "./components/BookSession/BookSession";
import ProfileSettings from "./components/ProfileSettings/ProfileSettings";
import Forum from "../src/components/Forum/Forum";
import ForumThread from "./components/ForumThread/ForumThread";
import SignupEmail from "./Login/SignupEmail";
import PersonalInformation from "./components/PersonalInformation/PersonalInformation";
import BecomeMentor from "./components/BecomeMentor/BecomeMentor";
import MentorDashboard from "./components/MentorDashboard/MentorDashboard";
import { useHistory, Redirect } from "react-router-dom";
import Buycredits from "./components/Buycredits/Buycredits";
import MentorProfile from "./components/NetworkDashboard/MySession/MentorProfile/MentorProfile";
import ApproveDeny from "./components/ApproveDeny/ApproveDeny";
//
import RescheduleSession from "./components/Slot/RescheduleSession/RescheduleSession";
//core
import "primereact/resources/primereact.min.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import MentorSignInEmail from "./components/MentorSignInEmail/MentorSignInEmail";
//
import MentorSetting from "./components/MentorDashboard/MentorSetting/MentorSetting";
import ErrorPage from "./components/404/404";

import StripePayments from "./components/MentorDashboard/StripPaymnets/StripePayments";
import StripeSuccess from "./components/MentorDashboard/StripPaymnets/StripSuccess";
import StripeFailure from "./components/MentorDashboard/StripPaymnets/StripFailure";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

//
import UserProfile from "./components/Slot/UserProfile/UserProfile";
// const UserProfile = lazy(() => import('./components/Slot/UserProfile/UserProfile'));

import StripSucessForNetworkBook from "./components/MentorDashboard/StripPaymnets/StripSucessForNetworkBook";
import AlreadyVerified from "./components/EmailVerficationScreens/AlreadyVerified";
import VerificationSuccessful from "./components/EmailVerficationScreens/VerificationSuccessful";
import MeetingPage from "./components/zoom/MeetingPage/MeetingPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import MeetingPageMentor from "./components/zoom/MeetingPage/MeetingPageMentor";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { useSelector } from "react-redux";
import ApiClient from "./service/client";

import ProtectedRoute from "./ProtectedRoute";
// import InterviewHome from "./components/BookSession/InterviewHome";
import Vedio from "./components/vedioapp/Vedio";
import VerticalRange from "./components/Forum/VerticalRange";
import MySessions from "./components/Slot/Slot";
import PreLoader from "./components/Loader/PreLoader";
import FontFaceObserver from "fontfaceobserver";

import InterviewHome from "./components/BookSession/InterviewHome";
import NetworkDashboardHome from "./components/NetworkDashboard/NetworkDashboardHome";

import NetworkDashboard from "./components/NetworkDashboard/NetworkDashboard";
import SingUpActive from "./Login/SignUpActive";
import Dashboard from "./components/Dashboard/Dashboard";
import CloseMeeting from "./components/MentorDashboard/CloseMeting/CloseMeeting";
import MySessionUser from "./components/Slot/MySessionUser";
import BookSessionInterview from "./components/Dashboard/BookSession";
import InterViewGuidInterView from "./components/Dashboard/InterViewGuidInterView";
import MyPerformanceInterview from "./components/Dashboard/MyPerformanceInterview";
import NetworkBookRoute from "./components/NetworkDashboard/NetworkBookRoute";
import NetworkMySessionRoute from "./components/NetworkDashboard/NetworkMySessionRoute";
import NetworkInterviewGuidRoute from "./components/NetworkDashboard/NetworkInterviewGuidRoute";
import NetworkMyPerformanceRoute from "./components/NetworkDashboard/NetworkMyPerformanceRoute";


function App() {
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const [showPreloader, setShowPreloader] = useState(true);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const protectedRoutes = [
    {
      path: "/interviewdashboard",
      component: BookSessionInterview,
      role: userData?.role,
      profile: userData?.profileUpdated,
    },
    {
      path: "/mentordashboard",
      component: MentorDashboard,
      role: userData?.role,
    },
    {
      path: "/personalInfo",
      component: PersonalInformation,
      role: userData?.role,
      profile: userData?.profileUpdated,
    },


    { path: "/meetingpage", component: MeetingPage, role: userData?.role },
    {
      path: "/meetingpagementor",
      component: MeetingPageMentor,
      role: userData?.role,
    },
    {
      path: "/buycredits",
      component: Buycredits,
      role: userData?.role,
    },
  ];
  //
  let redirectUrl = localStorage?.getItem("redirectUrl");
  //
  useEffect(() => {
    const fontRegular = new FontFaceObserver("AktivGrotesk-Regular");
    const fontMedium = new FontFaceObserver("AktivGrotesk-Medium");
    const fontBold = new FontFaceObserver("AktivGrotesk-Bold");
    const fontDM = new FontFaceObserver("DM Sans");

    Promise.all([
      fontRegular.load(),
      fontMedium.load(),
      fontBold.load(),
      fontDM.load(),
    ])
      .then(() => {
        setFontsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading fonts:", error);
      });
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {fontsLoaded ? (
        <div className="">
          {/* {showPreloader ? (
            <PreLoader />
          ) : ( */}
          <Router>
            <CustomModal />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            // theme="light"
            />
            <Switch>
              <Redirect
                exact
                from="/"
                to={
                  redirectUrl === "interviewdashboard" &&
                    userData?.role === 0 &&
                    userData?.profileUpdated === true
                    ? "/interviewdashboard"
                    : redirectUrl === "mentordashboard" && userData?.role === 1
                      ? "/mentordashboard"
                      : "/interview"
                }
              />
              {/* <Redirect exact from="/" to={userData?.role === 0 ? "/interviewdashboard" : "/interview"} /> */}
              {/* Protected Routes */}
              {protectedRoutes.map((route, index) => (
                <ProtectedRoute
                  key={index}
                  exact
                  path={route.path}
                  component={route.component}
                  role={route.role}
                  profile={route?.profile}
                />
              ))}
              {/* <Route exact path="/interview" component={Dashboard} /> */}
              <Route exact path="/signup" component={SingUp} />
              <Route exact path="/login" component={SingUpActive} />
              <Route exact path="/interview" component={InterviewHome} />
              <Route
                exact
                path="/networkDashboard"
                component={NetworkBookRoute}
              />
              <Route exact path="/network" component={NetworkDashboardHome} />
              {/* <Route exact path="/login" component={Login} /> */}
              {/* <Route exact path="/profile" component={Profile} /> */}
              {/* <Route exact path="/updateprofile" component={UpdateProfile} /> */}
              {/* <Route exact path="/" component={SideBar} /> */}
              {/*  */}
              <Route exact path="/slot" component={Slot} />

              {/* <Route exact path="/interview" component={Dashboard} /> */}

              <Route exact path="/performance" component={Performance} />
              {/* <Route exact path="/forum" component={Forum} /> */}
              <Route exact path="/forumthread" component={ForumThread} />
              <Route exact path="/signupEmail" component={SignupEmail} />
              {/* <Route
                  exact
                  path="/personalInfo"
                  component={PersonalInformation}
                /> */}
              <Route
                exact
                path="/forgetPassword/:token"
                component={ForgetPassword}
              />
              <Route
                exact
                path="/mentorsignupemail/:email"
                component={MentorSignInEmail}
              />
              <Route exact path="/mentor" component={BecomeMentor} />
              <Route
                exact
                path="/profilesettings"
                component={ProfileSettings}
              />
              {/* metwork extra pages */}
              {/* <Route exact path="/mentordashboard" component={MentorDashboard} /> */}
              <Route exact path="/email" component={ResetPassword} />
              <Route exact path="/reschedule" component={RescheduleSession} />
              {/* mentor */}
              <Route exact path="/mentorSetting" component={MentorSetting} />
              {/* <Route exact path="/buycredits" component={Buycredits} /> */}
              <Route exact path="/createstripe" component={StripePayments} />
              <Route exact path="/stripsuccess/:ID" component={StripeSuccess} />
              <Route exact path="/metingClose" component={CloseMeeting} />

              <Route exact path="/striperror/:ID" component={StripeFailure} />
              <Route exact path="/mentordashboard/:id" component={MentorDashboard} />
              <Route exact path="/forum" component={Forum} />
              {/* <Route exact path="/404" component={ErrorPage} /> */}
              <Route exact path="/viewuserprofile" component={UserProfile} />
              <Route
                exact
                path="/slotbooked"
                component={StripSucessForNetworkBook}
              />
              <Route
                exact
                path="/alreadyverified"
                component={AlreadyVerified}
              />
              <Route
                exact
                path="/verificationsuccessful"
                component={VerificationSuccessful}
              />
              {/* <Route exact path="/meetingpage" component={MeetingPageBefore} /> */}
              {/* <Route exact path="/meetingpage" component={MeetingPage} />
          <Route exact path="/meetingpagementor" component={MeetingPageMentor} /> */}
              <Route exact path="/termsconditions" component={PrivacyPolicy} />
              <Route exact path="/meeting/room/:roomID" component={Vedio} />
              <Route exact path="/linkedin" component={LinkedInCallback} />
              {/* <Route exact path='/range' component={VerticalRange} /> */}
              <Route
                exact
                path="/interview/:mysessionId"
                component={Dashboard}
              />
              <Route exact path="/interview/:propose" component={Dashboard} />
              <Route exact path="/network/:mysessionId" component={NetworkDashboard} />
              <Route exact path="/reschedule/:id" component={ApproveDeny} />
              <Route exact path="/mySession" component={MySessionUser} />
              <Route exact path="/interviewguidinterview" component={InterViewGuidInterView} />
              <Route exact path="/perfoemanceInterview" component={MyPerformanceInterview} />
              <Route exact path="/networkmysession" component={NetworkMySessionRoute} />
              <Route exact path="/networkinterviewguid" component={NetworkInterviewGuidRoute} />
              <Route exact path="/networkperformance" component={NetworkMyPerformanceRoute} />
              <Route component={ErrorPage} />
            </Switch>
          </Router>
          {/* //  )}  */}
        </div>
      ) : (
        "loading..."
      )}
    </>
  );
}

export default App;
