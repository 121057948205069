import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";

export const myEarning = createAsyncThunk("myEarning", async (data) => {
  try {
    const res = await client.post(
      `/get-mentor-earning`,
      { id: data?.userId },
      { headers: { authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});
