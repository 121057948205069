import React from "react"
// import loader from './loader.json'
import loader from '../../assets/loader/loader 1 fast track.gif'
import Lottie from 'lottie-react';
import animationData from '../../assets/loader/animation_lnailmmc.json'; // Download the JSON file from the LottieFiles URL

// import loader from '../../assets/Avatar.png'

function ScreenLoader({ text }) {
  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100"
          style={{
            zIndex: 999999999999,
            marginTop: -100,
            left: 0,
            position: 'fixed',
            top: 0,
            height: "122%",
            marginLeft: 0,
            background: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {/* <img
                width={150}
                src={loader}
                alt="loader"
              /> */}
              <Lottie
                animationData={animationData} // JSON animation data
                loop={true} // Set loop to true if you want the animation to loop
                autoplay={true} // Set autoplay to true to play the animation when the component mounts
              />
              <h2 className="text-white w-100 text-center mt-5">{text}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ScreenLoader;