// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join__session__button {
  padding: 14px 18px;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: none;
  font-family: AktivGrotesk-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  width: 100%;
}

.join__session__disabled {
  background-color: #2b9972;
}

.join__session__enabled {
  background-color: #3dd7a1;
  color: #1f2024;
}
.join__session__enabled:hover {
  opacity: 0.9;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Custom/Buttons/JoinSession/joinsession.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,cAAA;AAGF;AAFE;EACE,YAAA;EACA,WAAA;AAIJ","sourcesContent":[".join__session__button {\n  padding: 14px 18px;\n  border-radius: 12px;\n  transition: all 0.3s ease;\n  border: none;\n  font-family: AktivGrotesk-Medium;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 100%;\n  width: 100%;\n}\n.join__session__disabled {\n  background-color: #2b9972;\n}\n.join__session__enabled {\n  background-color: #3dd7a1;\n  color: #1f2024;\n  &:hover {\n    opacity: 0.9;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
