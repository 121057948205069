import React, { useState } from "react";
//
import ArrowLeft from "../../../../assets/arrow-left.svg";
import InfoCircle from "../../../../assets/info-circle.svg";
//
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ConfirmModal } from "../../../SessionModal/SessionModal";
import "./payment.scss";
import { ErrorToast } from "../../../MyToaster";
//
//
// import {
//   PaymentElement,
//   useStripe,
//   useElements,
// } from "@stripe/react-stripe-js";

// mentorData={singleData}
// selectedTime={calendarReturn}
const Payment = ({ setShowPayment, mentorData, selectedTime }) => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  ErrorToast(
    `Mentor name ${mentorData?.userData[0]?.firstName}  and slots selected date is ${selectedTime?.item?.date}  and start time is ${selectedTime?.selectedtime?.startDateTime} and end time is ${selectedTime?.selectedtime?.endDateTime}`
  );
  //
  // const stripe = useStripe();
  // const elements = useElements();
  //
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (elements == null) {
  //     return;
  //   }

  //   // Trigger form validation and wallet collection
  //   const { error: submitError } = await elements.submit();
  //   if (submitError) {
  //     // Show error to your customer
  //     setErrorMessage(submitError.message);
  //     return;
  //   }

  //   // Create the PaymentIntent and obtain clientSecret from your server endpoint
  //   const res = await fetch("http://localhost:4242/create-payment-intent", {
  //     method: "GET",
  //     // body: {
  //     //   // mode: "payment",
  //     //   amount: 200,
  //     //   currency: "EUR",
  //     // },
  //   });

  //   const { client_secret: clientSecret } = await res.json();

  //   const { error } = await stripe.confirmPayment({
  //     //`Elements` instance that was used to create the Payment Element
  //     elements,
  //     clientSecret,
  //     confirmParams: {
  //       return_url: "https://example.com/order/123/complete",
  //     },
  //   });

  //   if (error) {
  //     // This point will only be reached if there is an immediate error when
  //     // confirming the payment. Show error to your customer (for example, payment
  //     // details incomplete)
  //     setErrorMessage(error.message);
  //   } else {
  //     setShow(!show);
  //     // Your customer will be redirected to your `return_url`. For some payment
  //     // methods like iDEAL, your customer will be redirected to an intermediate
  //     // site first to authorize the payment, then redirected to the `return_url`.
  //   }
  // };
  const handleSubmit = () => {
    ErrorToast("Submitted...");
  };
  return (
    <>
      <div className="network__booksession__payment">
        {/* top */}
        <div className="network__booksession__payment__top">
          <div
            className="network__booksession__payment__top__container"
            onClick={() => setShowPayment(false)}
          >
            <img src={ArrowLeft} alt="left arrow" />
            <p className="marginTop">Back to Dashboard</p>
          </div>
        </div>
        {/* bottom */}
        <Row className="network__booksession__payment__bottom flex-column-reverse flex-md-row">
          <Col
            style={{ padding: "0" }}
            className="network__booksession__payment__bottom__left"
          >
            <h3 className="network__booksession__payment__bottom__left__heading">
              Payment details
            </h3>
            <div className="network__booksession__payment__bottom__left__form">
              {/* <PaymentElement /> */}
              <Form.Group
                className="network__booksession__payment__bottom__left__form__group"
                controlId="networkdb-cardnumber"
              >
                <Form.Label>Card Number</Form.Label>
                <Form.Control type="text" placeholder="1234 1234 1234 1234" />
              </Form.Group>
              <Form.Group
                className="network__booksession__payment__bottom__left__form__group"
                controlId="networkdb-cardholdername"
              >
                <Form.Label>Cardholder Name</Form.Label>
                <Form.Control type="text" placeholder="Jogn Doe" />
              </Form.Group>
              <Row>
                <Col
                  style={{ padding: "0", marginRight: "16px" }}
                  className="me-0 me-md-3 mb-4 mb-md-0"
                >
                  <Form.Group
                    className="network__booksession__payment__bottom__left__form__group"
                    controlId="networkdb-cardnumber"
                  >
                    <Form.Label>Expiry Date</Form.Label>
                    <Form.Control type="text" placeholder="12/24" />
                  </Form.Group>
                </Col>
                <Col md={3} style={{ padding: "0" }}>
                  <Form.Group
                    className="network__booksession__payment__bottom__left__form__group"
                    controlId="networkdb-cvc"
                  >
                    <Form.Label className="network__booksession__payment__bottom__left__form__group__iconlabel">
                      <span style={{ marginRight: "4px" }}>CVC</span>
                      <img src={InfoCircle} style={{ marginTop: "-2px" }} />
                    </Form.Label>
                    <Form.Control type="number" placeholder="123" />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="network__booksession__payment__bottom__left__form__checkgroup">
                <Form.Check
                  type="checkbox"
                  label="I agree to FastTrack’s terms and conditions, including all extra fees and billings, like session cancellations and rescheduling"
                  id="confirm-terms"
                />
              </Form.Group>
              <div className="network__booksession__payment__bottom__left__form__button">
                <button className="btn-green" onClick={handleSubmit}>
                  Pay $200
                </button>
              </div>
            </div>
          </Col>
          <Col
            style={{ padding: "0" }}
            md={5}
            className="network__booksession__payment__bottom__right"
          >
            <h2 className="network__booksession__payment__bottom__right__heading">
              Order summary
            </h2>
            <label className="network__booksession__payment__bottom__right__inputlabel">
              <span>Enter discount code </span>
              <input type="text" placeholder="Promo99" />
            </label>
            <div className="network__booksession__payment__bottom__right__container">
              <p className="network__booksession__payment__bottom__right__container__heading">
                Service
              </p>
              <p className="network__booksession__payment__bottom__right__container__text">
                1-Hour Coaching
              </p>
            </div>
            <div className="network__booksession__payment__bottom__right__container">
              <p className="network__booksession__payment__bottom__right__container__heading">
                Order details
              </p>
              <p className="network__booksession__payment__bottom__right__container__text">
                Session booked for 11:15am - 12:15pm with Angela Doe,{" "}
              </p>
            </div>
            <div className="network__booksession__payment__bottom__right__container">
              <p className="network__booksession__payment__bottom__right__container__heading">
                Total Price
              </p>
              <p className="network__booksession__payment__bottom__right__container__textprice">
                $200
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmModal show={show} setShow={setShow}>
        <div className="networkdb__payment__modal__top">
          <h2>Session Booked</h2>
          <p>
            Your session with <span>Angela Doe</span> has been successfully
            booked for
            <span>11:15am - 12:15pm</span>
          </p>
        </div>
        <div className="networkdb__payment__modal__bottom">
          <Link to="/" className="btn-green">
            Go to your Sessions
          </Link>
        </div>
      </ConfirmModal>
    </>
  );
};

export default Payment;
