import React ,{useState} from 'react'
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import VideoGreen from "../../assets/video.svg";
import VideoGrey from "../../assets/video-grey.svg";
import wallet from "../../assets/wallet.svg";
import walletGrey from "../../assets/walletGrey.svg";
import { myEarning } from '../../service/redux/middleware/earning';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
const MentorSecondNavbar = ({mentor}) => {
    const [selected, setSelected] = useState(null);
    const [loader,setLoader]=useState(false)
    const setSelectedComponent = (asd) => {
        setSelected(asd);
      };
      const history = useHistory();
      const dispatch = useDispatch();
      const userData = useSelector((state) => state?.signin?.signInData?.data);
      const token = useSelector((state) => state?.signin?.signInData?.token);
      const [earningData, setEarningData] = useState([]);
    
      const data = {
        userId: userData?._id,
        token: token,
      };
      const userEarning = (index) => {
        // setSelectedComponent(index);
        history.push(`/mentordashboard/${index}`);
        setLoader(true);
        dispatch(myEarning(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setEarningData(res?.payload?.data);
            setLoader(false);
            // window.open(res?.payload?.data, '_blank');
            // setSelected(0);
          } else {
            setLoader(false);
          }
        });
      };
      const moveToMentor =(ind)=>{
        history.push(`/mentordashboard/${ind}`);
        // setSelectedComponent(ind);
      }
      const moveToNetwork=()=>{
        history.push(`/network`);

      }
    return (
        <div>
            <div className="dashboardbar__container__mentor__main">
                <div className="dashboardbar__container__mentor">
                    <div className="menu__desktop__mentor">
                        <ul className="dashboardbar__container__mentor__nav">
                        <li className="dashboardbar__container__mentor__nav__item  dashboardbar__container__mentor__nav__item__first">
                                <div
                                    onClick={moveToNetwork}
                                    className={`dashboardbar__container__mentor__nav__item__link ${selected === null  ? "active" : ""
                                        }`}
                                >
                                    <img
                                        src={selected === null  ? saveAddGreen : saveAdd}
                                        className="dashboardbar__container__mentor__nav__item__link__image"
                                    />
                                    <p className="dashboardbar__container__mentor__nav__item__link__text">
                                        Student dashboard
                                    </p>
                                </div>
                            </li>
                            <li className="dashboardbar__container__mentor__nav__item  dashboardbar__container__mentor__nav__item__first">
                                <div
                                    onClick={() => {
                                        moveToMentor(0)
                                    }}
                                    className={`dashboardbar__container__mentor__nav__item__link ${selected === 0 ? "active" : ""
                                        }`}
                                >
                                    <img
                                        src={selected === 0 ? VideoGreen : VideoGrey}
                                        className="dashboardbar__container__mentor__nav__item__link__image"
                                    />
                                    <p className="dashboardbar__container__mentor__nav__item__link__text">
                                        My availability
                                    </p>
                                </div>
                            </li>
                            <li className="dashboardbar__container__mentor__nav__item">
                                <div
                                    onClick={() => {
                                        moveToMentor(1)
                                    }}
                                    className={`dashboardbar__container__mentor__nav__item__link ${selected === 1 ? "active" : ""
                                        }`}
                                >
                                    <img
                                        src={
                                            selected === 1
                                                ? SaveSessionsGreen
                                                : SaveSessionsIcon
                                        }
                                        className="dashboardbar__container__mentor__nav__item__link__image"
                                    />
                                    <p className="dashboardbar__container__mentor__nav__item__link__text">
                                        My sessions
                                    </p>
                                </div>
                            </li>
                            <li className="dashboardbar__container__mentor__nav__item">
                                <div
                                    onClick={() => {
                                        userEarning(2);
                                    }}
                                    className={`dashboardbar__container__mentor__nav__item__link ${selected === 2 ? "active" : ""
                                        }`}
                                >
                                    <img
                                        src={selected === 2 ? wallet : walletGrey}
                                        className="dashboardbar__container__mentor__nav__item__link__image"
                                    />
                                    <p className="dashboardbar__container__mentor__nav__item__link__text">
                                        My earnings
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MentorSecondNavbar