// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForumThreadSection {
  color: white;
}
.ForumThreadSection__Back {
  display: flex;
  align-items: center;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #2f3033;
}
.ForumThreadSection__Back h1 {
  color: var(--gray-gray-25, #F5F6F7);
  font-family: AktivGrotesk-Medium;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-left: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ForumThread/ForumThread.scss"],"names":[],"mappings":"AAAA;EACA,YAAA;AACA;AAEA;EACI,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,+BAAA;EACF,gCAAA;AAAF;AAEA;EACI,mCAAA;EACJ,gCAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,4BAAA;EACA,+BAAA;EACA,4BAAA;AAAA","sourcesContent":[".ForumThreadSection{\ncolor:white;\n\n\n&__Back{\n    display: flex;\n    align-items: center;\n    padding-top: 20px !important;\n    padding-bottom: 20px !important;\n  border-bottom: 1px solid #2f3033;\n\nh1{\n    color: var(--gray-gray-25, #F5F6F7);\nfont-family: AktivGrotesk-Medium;\nfont-size:  14px !important;\nfont-style: normal;\nfont-weight: 400;\nline-height: 100%; /* 14px */\npadding-top: 12px !important;\npadding-bottom: 12px !important;\nmargin-left: 10px !important;\n\n}\n}\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
