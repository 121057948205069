import React from "react";
import ErrorIcon from "../../assets/404.svg";
import { Link } from "react-router-dom";
import "./404.scss";
import NavBar from "../Navbar/NavBar";
const ErrorPage = () => {
  return (
    <>
      <NavBar />
      <div className="error__page container__custom">
        <div className="error__container">
          <div className="error__container__image">
            <img
              src={ErrorIcon}
              alt="error icon"
              className="error__container__image__img"
            />
          </div>
          <div className="error__container__bottom">
            <p className="error__container__bottom__text">
              The page you are looking for doesn't exist or has been moved.
              Please go back to the homepage.
            </p>
            <Link to="/" className="error__container__bottom__button">
              Go Back Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
