import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../Navbar/NavBar.jsx";

//
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import PerformanceIcon from "../../assets/performance-icon.svg";
import PerformanceIconGreen from "../../assets/performance-icon-green.svg";
import InterviewIcon from "../../assets/interview-icon.svg";
import InterviewIconGreen from "../../assets/interview-icon-green.svg";
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import { useParams } from "react-router-dom";
import "./dashboardbar.scss";
//
import BookSession from "../BookSession/BookSession";
import MySessions from "../Slot/Slot";
import Interview from "../InterviewGuides/Interview";
import Performance from "../Performance/Performance";
import pay from "../../assets/play-circle.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter.js";
import { mySession } from "../../service/redux/middleware/session.js";
import { mySessionNotBooked } from "../../service/redux/middleware/session.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRelability } from "../../service/redux/middleware/relaible";

const Dashboard = ({children}) => {
  const [relaible, setRelaible] = useState();
  const [showBook, setShowBook] = useState(false);


  const [mySessionData, setMySessionData] = useState([]);
  const history = useHistory();
  const currentRoute = history.location.pathname;
  console.log("this is the current route",currentRoute)

  const [notBookedSessionData, setNotBookedSessionData] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const { mysessionId } = useParams();
  const [selected, setSelected] = useState(0);
  const setSelectedComponent = (asd) => {
    setSelected(asd);
  };

  const data = {
    userId: userData?._id,
    token: token,
  };
  useEffect(() => {
    // setLoader(true);
    dispatch(mySession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        // setLoader(false);
        setMySessionData(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        // setLoader(false);
      }
    });

    dispatch(mySessionNotBooked(data)).then((res) => {
      if (res?.payload?.status === 200) {
        // setLoader(false);
        setNotBookedSessionData(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        // setLoader(false);
      }
    });

    dispatch(getRelability(data)).then((res) => {
      if (res?.payload?.status === 200) {
        // setLoader(false)
        setRelaible(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        // setLoader(false)
      }
    });
  }, []);

  useEffect(() => {
    if (mysessionId == undefined) {
      setSelectedComponent(0);
    } else if (mysessionId === "purpose") {
      setSelectedComponent(0);
    } else {
      setSelectedComponent(mysessionId);
    }
  }, [mysessionId]);

  const changePath = (index) => {
    // if (mysessionId) {
    setSelectedComponent(index);
    // history.push("/interview");
    // } else {
    // }
  };
  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      // Convert the stored string back to a boolean
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      <NavBar />
      {(!userData?._id || isMentor) && (
        <>
          <div className="guest__landingpage">
            <img src={pay} />
            <div className="ms-2 guest__landingpage__text">
              Explainer or something similar, to make things super easy for the
              users
            </div>
          </div>
        </>
      )}
      <div className="container__custom">
        {userData?._id && isMentor === false && (
          <div className="dashboardbar__container">
            <ul className="dashboardbar__container__nav">
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                   
                    history.push("/interviewdashboard")
                    setShowBook(false)
                  }}
                  className={`dashboardbar__container__nav__item__link ${
                    currentRoute == "/interviewdashboard" ? "active" : ""
                  }`}
                >
                  <img
                    src={ currentRoute == "/interviewdashboard" ? saveAddGreen : saveAdd}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    Book sessions
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    history.push("/mysession")
                  }}
                  className={`dashboardbar__container__nav__item__link ${
                    currentRoute == "/mysession" ? "active " : ""
                  }`}
                >
                  <img
                    src={ currentRoute == "/mysession" ? SaveSessionsGreen : SaveSessionsIcon}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    My sessions
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    history.push("/interviewguidinterview")

                  }}
                  className={`dashboardbar__container__nav__item__link ${
                    currentRoute == "/interviewguidinterview" ? "active" : ""
                  }`}
                >
                  <img
                    src={currentRoute == "/interviewguidinterview" ? InterviewIconGreen : InterviewIcon}
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    Interview guides
                  </p>
                </div>
              </li>
              <li className="dashboardbar__container__nav__item">
                <div
                  onClick={() => {
                    changePath(3);
                    history.push("/perfoemanceInterview")
                  }}
                  className={`dashboardbar__container__nav__item__link ${
                    currentRoute == "/perfoemanceInterview" ? "active" : ""
                  }`}
                >
                  <img
                    src={
                      currentRoute == "/perfoemanceInterview" ? PerformanceIconGreen : PerformanceIcon
                    }
                    className="dashboardbar__container__nav__item__link__image"
                  />
                  <p className="dashboardbar__container__nav__item__link__text">
                    My performance
                  </p>
                </div>
              </li>
            </ul>
          </div>
        )}
        {
          children
        }
        {/* <div className="interview__dashboard__content__container">
          {selected == 0 ? (
            <BookSession
              mySessionData={mySessionData}
              setSelectedComponent={setSelectedComponent}
              notBookedSessionData={notBookedSessionData}
              setNotBookedSessionData={setNotBookedSessionData}
              mysessionId={mysessionId}
              relaible={relaible}
              showBook={showBook}
              setShowBook={setShowBook}

            />
          ) : selected == 1 ? (
            <MySessions
              mySessionData={mySessionData}
              setSelectedComponent={setSelectedComponent}
            />
          ) : selected == 2 ? (
            <Interview setSelectedComponent={setSelectedComponent} />
          ) : (
            <Performance />
          )}
        </div> */}
        <div>
          <NewsletterFooter />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
