import React from "react";
import NavLogo from "../../../assets/8.svg";
import "./strippayments.scss";
import { useHistory } from "react-router-dom";
import tick from '../../../assets/cancelMeeting.svg'


const StripSucessForNetworkBook = () => {
  const history = useHistory();

  const dashboard = () => {
    history.push("/network");
  };
  return (
    <div className="stripe__stripe">
      <div className="PersonalInfoSec">
        <div className="PersonalInfoSec__nav">
          <img
            className="PersonalInfoSec__nav__img "
            src={NavLogo}
            alt="logo"
          />
        </div>
        <div className="middle">
          <div>
            <img src={tick} alt="stripe" />
          </div>
          <div className="text___main success__message">
            <h1 className="">Session with mentor booked successfully</h1>
            <p style={{ color: "white" }} className="">
              Make sure to prepare in advance to take the most out of your
              session. <br/> Thank you!
            </p>
          </div>
          <div className="stripe__btn">
            <button onClick={dashboard} className="btn__common__css first__btn">
            Go back to Networking
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripSucessForNetworkBook;
