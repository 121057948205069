import React, { useState, useEffect } from "react";
import NavLogo from "../../../assets/8.svg";
import stripe from "../../../assets/Stripe-Emblem 1.png";
import "./strippayments.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import {
  createStripe,
  mentorStripAccount,
} from "../../../service/redux/middleware/mentor";

const StripePayments = () => {
  const userData = useSelector(
    (state) => state?.mentorSignup?.signInDataForMentor?.data?.data
  );
  const userData1 = useSelector((state) => state?.signin?.signInData?.data);

  const dispatch = useDispatch();
  const history = useHistory();

  const createStripeAccount = () => {
    if (userData?._id) {
      dispatch(createStripe(userData?._id)).then((res) => {
        if (res?.payload?.status === 200) {
          window.open(res?.payload?.data, "_blank");
          // setOnSuccess(true);
        } else {
          ErrorToast(res?.payload?.message);
        }
      });
    }
    dispatch(mentorStripAccount(userData1?._id)).then((res) => {
      if (res?.payload?.status === 200) {
        window.open(res?.payload?.data, "_blank");
        // setOnSuccess(true);
      } else {
        ErrorToast(res?.payload?.message);
      }
    });
  };

  const laterOn = () => {
    history.push("/mentordashboard");
  };
  return (
    <div className="stripe__stripe">
      <div className="PersonalInfoSec">
        <div className="PersonalInfoSec__nav">
          <img
            className="PersonalInfoSec__nav__img "
            src={NavLogo}
            alt="logo"
          />
        </div>

        {/* <div className="PersonalInfoSecForm">
                        <Form>
                            <Form.Group className=" loginfield " controlId="">
                                <Form.Label className="loginfield__Label " >New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    className="loginfield__type"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}

                                />
                                <img className="loginfield__img" src={eyeIcon} alt=""></img>
                            </Form.Group>
                            <Form.Group className=" loginfield " controlId="">
                                <Form.Label className="loginfield__Label " >Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    className="loginfield__type"
                                    placeholder="Type Password Again "
                                    alue={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }}

                                />
                                <img className="loginfield__img" src={eyeIcon} alt=""></img>
                            </Form.Group>
                            <button className="login__green__button"
                                onClick={(e) => {
                                    onResetPassword(e);
                                }}
                            >Reset</button>

                        </Form>
                    </div> */}
        <div className="middle">
          <div>
            <img src={stripe} alt="stripe" />
          </div>
          <div className="text___main">
            <h1 className="">Receive and Manage your Payments</h1>
            <p className="">
              You will need to create a stripe earning account to receive your
              payments{" "}
            </p>
          </div>
          <div className="stripe__btn">
            <button
              onClick={createStripeAccount}
              className="btn__common__css first__btn"
            >
              Create Stripe Earning Account
            </button>
            <button onClick={laterOn} className="btn__common__css later__btn">
              Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripePayments;
