import React from "react";

const LoaderSmall = () => {
  return (
    <div
      class="spinner-border"
      style={{ height: "14px", width: "14px",color:'white' }}
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  );
};

export default LoaderSmall;
