import React from "react";
import LogoIcon from "../../assets/8.svg";
//
import "./PrivacyPolicy.scss";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="mainWrapper__top">
        <img src={LogoIcon} className="mainWrapper__top__image" />
      </div>
      <div className="mainWrapper">
        <h1>Fast-Track Terms of Service</h1>
        <div className="mainWrapper__item">
          <h2>Acceptance of Terms</h2>
          <p>
            By using Fast-Track, you agree to comply with and be bound by the
            following Terms of Service. If you do not agree to these terms,
            please do not use our website.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Description of Service</h2>
          <p>
            Fast-Track provides a platform that enables students to connect with
            each other to prepare for investment banking interviews via Zoom and
            other communication methods.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>User Registration</h2>
          <p>
            a. To access certain features of the platform, you may be required
            to register for an account. You agree to provide accurate and
            complete information during the registration process and to keep
            your account information up-to-date. <br /> b. You are responsible
            for maintaining the confidentiality of your account and password and
            for all activities that occur under your account.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>User Conduct</h2>
          <p>
            a. You agree to use Fast-Track for lawful purposes only and to
            comply with all applicable laws and regulations. <br />
            b. You will not engage in any activity that disrupts, interferes
            with, or harms the functioning of the website or the experience of
            other users.
            <br />
            c. You will not use Fast-Track for any form of harassment, hate
            speech, or offensive content.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Privacy</h2>
          <p>
            Your use of Fast-Track is also governed by our Privacy Policy, which
            can be found{" "}
            <a href="https://app.fast-track.community/termsconditions" className="link__privacy">
              Privacy policy
            </a>
            .
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Content</h2>
          <p>
            a. You retain ownership of any content you post or upload to
            Fast-Track, but you grant Fast-Track a worldwide, non-exclusive,
            royalty-free, and transferable license to use, display, reproduce,
            and distribute your content on the platform.
            <br />
            b. You are solely responsible for the content you post and any
            consequences that may arise from it.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Termination</h2>
          <p>
            Fast-Track reserves the right to terminate or suspend your account
            and access to the platform at any time, without notice, for any
            reason.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Disclaimers</h2>
          <p>
            a. Fast-Track is not responsible for the accuracy or completeness of
            the content on the platform.
            <br />
            b. Fast-Track is not responsible for any interactions or
            transactions that occur between users.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Limitation of Liability</h2>
          <p>
            Fast-Track is not liable for any direct, indirect, incidental,
            special, or consequential damages arising out of or in any way
            connected with your use of the platform.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Governing Law</h2>
          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of Estonia, where our company is
            registered. Users in other countries using our platform agree to
            submit to the jurisdiction of Estonian courts or an alternative
            dispute resolution mechanism chosen by Fast- Track in the event of
            disputes.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Changes to Terms</h2>
          <p>
            Fast-Track reserves the right to modify or update these Terms of
            Service at any time. Continued use of the platform after such
            changes will constitute your acceptance of the new terms.
          </p>
        </div>
        <div className="mainWrapper__item">
          <h2>Contact Information</h2>
          <p>
            If you have any questions or concerns about these Terms of Service,
            please contact us at admin@fast-track.community.
          </p>
        </div>
        <p>
          By using Fast-Track, you acknowledge that you have read, understood,
          and agreed to these Terms of Service.
        </p>
      </div>
      <NewsletterFooter />
    </>
  );
};

export default PrivacyPolicy;
