import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeRangeSlider from "react-time-range-slider";
import RangeSlider from "react-range-slider-input";
import Footer from "../../Footer/Footer";
import moment from "moment";

import Calendar from "react-calendar";
import { MySessionCard } from "../../Custom";
import Ratings from "../../Custom/Ratings/Ratings";
//
import SortIcon from "../../../assets/sort.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import RatedStar from "../../../assets/star-rated.svg";
import InfoCircle from "../../../assets/info-circle.svg";

import RisingStar from "../../../assets/magic-star.svg";
import AvatarIcon from "../../../assets/avatar.svg";
import BiDot from "../../../assets/bi_dot.svg";
import DropDownArrow from "../../../assets/arrow-down.svg";
import Dots from "../../../assets/dot.svg";
import DiamondGrey from "../../../assets/diamond-grey.svg";
import DateTimePicker from "react-datetime-picker";
import ArrowLeftMini from "../../../assets/arrow-left-mini.svg";
import ArrowRightMini from "../../../assets/arrow-right-mini.svg";
import noFound from "../../../assets/searchempty.svg";

//
import "./Booksession.scss";
import Book from "./Book/Book";
//
import { useDispatch, useSelector } from "react-redux";
import {
  viewAllNetworkSession,
  viewNetworkSession,
} from "../../../service/redux/middleware/network";
import { Link } from "react-router-dom";
import ScreenLoader from "../../Loader/ScreenLoader";
import { mentorSession } from "../../../service/redux/middleware/session";
import DatePicker from "react-datepicker";
import { ErrorToast } from "../../MyToaster";
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";
import StarFull from "../../../assets/star-yellow.svg";

const dropDownOptions = [
  {
    show: "Cover Letter",
    value: "coverLetter",
  },
  {
    show: "CV Review",
    value: "cvReview",
  },
  {
    show: "General Guidance",
    value: "generalGuidance",
  },
  {
    show: "Interview Practice",
    value: "interviewPractice",
  },
];
const InvestmentBanks = [
  "All",
  "BNP Paribas",
  "Barclays",
  "Bank of America Merrill Lynch",
  "Citigroup",
  "Credit Suisse",
  "Centerview Partners",
  "Deutsche Bank",
  "Evercore",
  "Goldman Sachs",
  "HSBC",
  "Jefferies",
  "Lazard",
  "Morgan Stanley",
  "Nomura",
  "RBC Capital Markets",
  "Rothschild",
  "UBS Group",
  "Wells Fargo Securities",
];
const BookSession = ({ setSelectedComponent }) => {
  const [showSession, setShowSession] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState("select price");
  const [mySession, setMySessions] = useState([]);
  const [temp, setTemp] = useState([]);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [selectedElement, setSelectedElement] = useState(null);
  const [education, setEducation] = useState("");
  const [recognition, setRecognition] = useState("");
  const [allMentor, setAllMentor] = useState();
  const [date,setDate]=useState()
  const [temp2, setTemp2] = useState();
  const [startDate, setStartDate] = useState(new Date());

  console.log("temp", temp);

  const educationSelected = (val) => {
    if (education === val) {
      setEducation("");
    } else {
      setEducation(val);
    }
  };
  const recognitionSelected = (val) => {
    if (recognition === val) {
      setRecognition("");
    } else {
      setRecognition(val);
    }
  };
  // const GetPriceValue = (item, index) => {
  //   setSelectedPricing(item);
  //   const newData = [...temp];
  //   newData?.map((mentor) => {
  //     mentor?.mentorPricing?.filter((elem) => {
  //       if (elem[selectedPricing]) {
  //         const selectedPriceValue = elem[selectedPricing][index]; // Get the price based on the index
  //         setSelectedPricingShow(selectedPriceValue);
  //       }
  //     });
  //   });
  // };

  const [banks, setBanks] = useState("");
  const handleDropdownBankChange = (event) => {
    setBanks(event.target.value);
  };
  //
  //
  const handleDropdownBankChangeNew = (selected) => {
    setBanks(selected);
  };
  //
  //

  const [loader, setLoader] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const dispatch = useDispatch();

  const data = {
    token: token,
  };
  const data1 = {
    userId: userData?._id,
    token: token,
  };

  const findClosestProfessionalExperience = (professionalDetails) => {
    if (professionalDetails.length === 0) {
      return null; // Return null if there are no professional details
    }

    const currentDate = new Date();
    let closestProfessionalExperience = null;
    let closestDifference = Infinity;

    for (const experienceEntry of professionalDetails) {
      const endDate = new Date(experienceEntry.endDate);
      const timeDifference = endDate.getTime() - currentDate.getTime();

      // If currentlyEmployed is true, prioritize this entry
      if (experienceEntry.currentlyEmployed) {
        closestProfessionalExperience = experienceEntry;
        break; // Break out of the loop if currentlyEmployed is true
      }

      if (timeDifference >= 0 && timeDifference < closestDifference) {
        closestDifference = timeDifference;
        closestProfessionalExperience = experienceEntry;
      }
    }

    return closestProfessionalExperience;
  };
  const findClosestInvestmentBank = (investmentBankDetails) => {
    const currentDate = new Date();
    let closestInvestmentBank = null;
    let closestDifference = Infinity;

    for (const bankEntry of investmentBankDetails) {
      const endDate = new Date(bankEntry.endDate);
      const timeDifference = endDate.getTime() - currentDate.getTime();

      // If currentlyEmployed is true, prioritize this entry
      if (bankEntry.currentlyEmployed) {
        closestInvestmentBank = bankEntry;
        break; // Break out of the loop if currentlyEmployed is true
      }

      if (timeDifference >= 0 && timeDifference < closestDifference) {
        closestDifference = timeDifference;
        closestInvestmentBank = bankEntry;
      }
    }

    return closestInvestmentBank;
  };
  const findClosestEducation = (educationDetails) => {
    console.log("this is the educationn", educationDetails);
    const currentDate = new Date();
    let closestEducation = null;
    let closestDifference = Infinity;

    for (const education of educationDetails) {
      const endDate = new Date(education?.endDate);
      const timeDifference = endDate?.getTime() - currentDate?.getTime();

      // If currentlyStuding is true, prioritize this entry
      if (education?.currentlyStuding) {
        closestEducation = education;
        break; // Break out of the loop if currentlyStuding is true
      }

      if (timeDifference >= 0 && timeDifference < closestDifference) {
        closestDifference = timeDifference;
        closestEducation = education;
      }
    }

    return closestEducation;
  };

  useEffect(() => {
    setLoader(true);
    dispatch(viewNetworkSession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        addSelectedKey(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        setLoader(false);
      }
    });

    dispatch(mentorSession(data1)).then((res) => {
      if (res?.payload?.status === 200) {
        setMySessions(res?.payload?.data);
      }
    });
  }, []);
useEffect(()=>{
  setLoader(true);
  const datas={
    requestedDate:startDate
  }

  dispatch(viewAllNetworkSession(datas)).then((res) => {
    if (res?.payload?.status === 200) {
      console.log("this is the all network", res);
      addAllSelectedKey(res?.payload?.data);

      // setOnSuccess(true);
    } else {
      setLoader(false);
    }
  });
},[startDate])

  const addSelectedKey = (data) => {
    const toUpdate = data.map((item) => {
      let selectSession = [];
      let mentorPricing = item?.mentorPricing[0];
      if (
        mentorPricing?.["CV Review"] != 0 &&
        mentorPricing?.["CV Review"] !== null &&
        mentorPricing?.["CV Review"] !== undefined
      ) {
        selectSession.push("CV Review");
      }
      if (
        mentorPricing?.["Cover Letter Review"] != 0 &&
        mentorPricing?.["Cover Letter Review"] !== null &&
        mentorPricing?.["Cover Letter Review"] !== undefined
      ) {
        selectSession.push("Cover Letter Review");
      }
      if (
        mentorPricing?.["General Guidance"] != 0 &&
        mentorPricing?.["General Guidance"] !== null &&
        mentorPricing?.["General Guidance"] !== undefined
      ) {
        selectSession.push("General Guidance");
      }
      if (
        mentorPricing?.["Interview Practice"] != 0 &&
        mentorPricing?.["Interview Practice"] !== null &&
        mentorPricing?.["Interview Practice"] !== undefined
      ) {
        selectSession.push("Interview Practice");
      }
      let newItem = {
        ...item,
        // selectedSession: "",
        selectedSession: selectSession?.length > 0 ? selectSession[0] : "",
        selectSession: selectSession,
      };
      return newItem;
    });
    setSessionData(toUpdate);
    console.log("this is the temp data", toUpdate);
    setTemp(toUpdate);
    setLoader(false);
    ApplyFilter();
  };

  const addAllSelectedKey = (data) => {
    const toUpdate = data.map((item) => {
      let selectSessions = [];
      let mentorPricing = item?.mentorPricing;
      if (
        mentorPricing?.cvReview != 0 &&
        mentorPricing?.cvReview !== null &&
        mentorPricing?.cvReview !== undefined
      ) {
        selectSessions.push("CV Review");
      }
      if (
        mentorPricing?.coverLetter != 0 &&
        mentorPricing?.coverLetter !== null &&
        mentorPricing?.coverLetter !== undefined
      ) {
        selectSessions.push("Cover Letter Review");
      }
      if (
        mentorPricing?.generalGuidance != 0 &&
        mentorPricing?.generalGuidance !== null &&
        mentorPricing?.generalGuidance !== undefined
      ) {
        selectSessions.push("General Guidance");
      }
      if (
        mentorPricing?.interviewPractice != 0 &&
        mentorPricing?.interviewPractice !== null &&
        mentorPricing?.interviewPractice !== undefined
      ) {
        selectSessions.push("Interview Practice");
      }
      console.log("this is the temp data2", selectSessions);

      let newItem = {
        ...item,
        // selectedSession: "",
        selectedSession: selectSessions?.length > 0 ? selectSessions : "",
        selectSession: selectSessions,
      };

      return newItem;
    });
    setAllMentor(toUpdate);

    setTemp2(toUpdate);
    setLoader(false);

    // setSessionData(toUpdate);
    // setTemp(toUpdate);
    // setLoader(false);
    // ApplyFilter();
  };

  const handleSelectSession = (eventKey, elem) => {
    const toUpdate = temp;
    let newData = toUpdate.map((item) => {
      return item?._id === elem?._id
        ? { ...item, selectedSession: eventKey }
        : item;
    });

    setTemp(newData);
  };
  const handleSelectSession2 = (eventKey, elem) => {
    const toUpdate = temp2;
    let newData = toUpdate.map((item) => {
      return item?._id === elem?._id
        ? { ...item, selectedSession: eventKey }
        : item;
    });
    console.log(newData, "this is the temp2");

    setTemp2(newData);
  };

  const handleElementClick = (elem) => {
    setSelectedElement(elem);
    if (elem?.selectedSession) {
      setShowSession(true);
    } else {
      ErrorToast("Please select a session");
    }
  };

  const filterTime = (val, isNext = false, isPrev = false) => {
    
    if (val === "") {
      val = new Date();
    }
    if (isNext) {
      let ndate = nextDate(val);
      setStartDate(ndate);
    } else if (isPrev) {
      let pdate = prevDate(val);
      setStartDate(pdate);
    } else {
      setStartDate(val);
    }
  };

  // console.log(startDate,"this is the start date")
  const nextDate = (dateString) => {
    const date = new Date(dateString);

    date.setDate(date.getDate() + 1);
   
    return date;
  };
  const prevDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date;
  };
  const featureRef = useRef();
  const [value, setValue] = useState({
    start: "00:00",
    end: "23:59",
  });
  const changeStartHandler = () => {
    if (value.start <= "00:00") {
      setValue((prevValue) => ({
        ...prevValue,
        start: "00:00",
      }));
    }
  };

  const changeCompleteHandler = (time) => {};
  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];
      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };

  const timeChangeHandler = (time) => {
    const startTime = timeParser(time.start);
    const endTime = timeParser(time.end);
    setValue({
      start: startTime,
      end: endTime,
    });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const ApplyFilter = () => {
    let filteredData = [...sessionData];
    let filterFunctions = [];

    if (education) {
      filterFunctions.push((item) => {
        let matchedData = item.selectSession?.filter(
          (citem) => citem === education
        );
        return matchedData.length > 0;
      });
    }
    if (startDate) {
      filterFunctions.push((item) => {
        if (!item.mentorAvalible || item.mentorAvalible.length === 0) {
          return true; // Include if mentorAvalible is empty
        }

        let matchedData = item.mentorAvalible?.filter((citem) => {
          return citem?.date === moment(startDate).format("YYYY-MM-DD");
        });

        return matchedData.length > 0;
      });
    } else {
      // If startDate is not provided, include items with empty mentorAvalible array
      filterFunctions.push(
        (item) => !item.mentorAvalible || item.mentorAvalible.length === 0
      );
    }

    if (recognition) {
      if (recognition === "High Rated") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return ratingAftDiv >= 4.5;
        });
      } else if (recognition === "Top Coach") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return ratingAftDiv >= 4.5 && item?.reliabilityData?.booked >= 5;
        });
      } else if (recognition === "Rising") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return (
            ratingAftDiv >= 4.5 &&
            item?.reliabilityData?.booked >= 2 &&
            item?.reliabilityData?.booked < 5
          );
        });
      }
    }
    if (banks && banks !== "All") {
      filterFunctions.push((item) => {
        let matchedData = item.mentorProfiles[0]?.investmentBank.filter(
          (citem, index) => citem.bank === banks
        );
        return matchedData.length > 0;
      });
    }

    if (value?.start && value?.end) {
      if (!(value?.start === "00:00" && value?.end === "23:59")) {
        filterFunctions.push((item) => {
          let matchedData = item.mentorAvalible?.filter(
            (citem) =>
              moment(citem.startDateTime).format("HH:mma") >= value?.start &&
              moment(citem?.endDateTime).format("HH:mma") <= value?.end
          );
          return matchedData.length > 0;
        });
      }
    }

    try {
      // Apply all combinations of filters
      filterFunctions.forEach((filterFunction) => {
        filteredData = filteredData.filter(filterFunction);
      });
      // Update the state with filtered data
      return setTemp(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    ApplyFilter();
    return () => {
      ApplyFilter();
    };
    // sessionData
  }, [startDate, education, value, banks, sessionData, recognition]);

  const ResetFilter = () => {
    setTemp(sessionData);
    setEducation("");
    setStartDate(new Date());
    setBanks("");
    setValue({ start: "00:00", end: "23:59" });
    setRecognition("");
  };
  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);

  const individualRating = (elem) => {
    // Use optional chaining and default values to handle potential undefined values
    const totalRatings = Number(elem?.mentorRattings?.[0]?.totalRatting) || 0;
    const totalReviews = Number(elem?.mentorRattings?.[0]?.totalReviews) || 0;
    // Calculate the average individual rating
    const averageIndividual = totalRatings / totalReviews;
    return isNaN(averageIndividual) ? 0 : averageIndividual;
  };
  const individualRatingAll = (elem) => {
    // Use optional chaining and default values to handle potential undefined values
    if (elem?.ratting !== null) {
      const totalRatings = Number(elem?.rating?.totalRatting) || 0;
      const totalReviews = Number(elem?.rating?.totalReviews) || 0;
      // Calculate the average individual rating
      const averageIndividual = totalRatings / totalReviews;
      return isNaN(averageIndividual) ? 0 : averageIndividual;
    } else {
      return 0;
    }
  };

  const gettingTopOrRising = (elem) => {
    const reliabilityData = elem?.relability[0];
    const ratings = elem?.mentorRattings;
    const ratingAftDiv =
      Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
    const isHighrated = ratingAftDiv >= 4.5;
    let IsHighRatedReturn = "";
    if (isHighrated) {
      IsHighRatedReturn = () => {
        return (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={RatedStar}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              High Rated
            </p>
          </div>
        );
      };
    }
    let isTop = "";
    // Rating > 4 & interviews > 5
    if (ratingAftDiv >= 4 && reliabilityData?.booked >= 5) {
      isTop = "Top Coach";
    } else if (ratingAftDiv >= 4 && reliabilityData?.booked >= 2) {
      // Rating > 4 & interviews > 2
      isTop = "Rising";
    }
    return (
      <>
        {isTop?.length > 0 && isTop === "Rising" && (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={RisingStar}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              {isTop}
            </p>
          </div>
        )}
        {isHighrated && <IsHighRatedReturn />}
        {isTop?.length > 0 && isTop === "Top Coach" && (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={DiamondGrey}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              {isTop}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showSession ? (
        <Book
          setShowSession={setShowSession}
          singleData={selectedElement}
          selectedPricing={selectedPricing}
          individualRating={individualRating}
        />
      ) : (
        <>
          {loader && <ScreenLoader />}
          <div className="dashboard__network ">
            <div className="container-fluid" style={{ padding: "0" }}>
              {userData?._id && isMentor === false && (
                <div className="dashboard__network__container d-none d-md-block">
                  <div className="dashboard__network__container__top">
                    <h1>Welcome {userData?.firstName}</h1>
                    <p>Here are some mentors provided for you</p>
                  </div>
                </div>
              )}
              <div className="row available__container min__height__100">
                {/* filter */}
                <div className="col-md-3 col-4 d-none d-md-block p-0 ">
                  <div className="available__container__filter">
                    <div className="available__container__filter__top">
                      <h2>Filters</h2>
                      <p onClick={ResetFilter} style={{ cursor: "pointer" }}>
                        Reset
                      </p>
                    </div>
                    <div className="custom__dropdown">
                      <div className="custom__dropdown__main">
                        <h2 className="custom__dropdown__main__title">Date</h2>
                        <div className="custom__dropdown__main__container">
                          <div className="ascdvre__grehgtr">
                            <div className="filter__label__inner">
                              <img
                                src={ArrowLeftMini}
                                onClick={() =>
                                  filterTime(startDate, false, true)
                                }
                              />
                              <label
                                htmlFor="filterDate"
                                className="filterDate filter__label__inner__dateshow"
                              >
                                <p className="">
                                  {moment(startDate).format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? "Today"
                                    : formatDate(startDate)}
                                </p>
                              </label>
                              <img
                                src={ArrowRightMini}
                                onClick={() =>
                                  filterTime(startDate, true, false)
                                }
                              />
                            </div>
                            <DatePicker
                              className="filter__date__input d-none"
                              name="startDate"
                              id="filterDate"
                              placeholder="Select expiration date..."
                              onSelect={(date) => filterTime(date)}
                              // minDate={moment()?.toDate()}
                            />
                          </div>

                          <div className="custom__dropdown__main__container__range">
                            <p className="mb-3">Time</p>
                            <TimeRangeSlider
                              ref={featureRef}
                              value={value ? value : ""}
                              onChangeStart={changeStartHandler}
                              onChange={timeChangeHandler}
                              onComplete={changeCompleteHandler}
                            />
                            <div
                              className="custom__dropdown__main__container__range__inner"
                              style={{ marginTop: "10px" }}
                            >
                              <p className="ms-1">
                                {value?.start ? value?.start : "00:00"}
                              </p>
                              <p style={{ marginRight: "3px" }}>
                                {" "}
                                {value?.end ? value?.end : "23:59"}
                              </p>
                            </div>
                          </div>

                          <div className="custom__dropdown__main__container__type">
                            <p>Session type</p>
                            <div className="custom__dropdown__main__container__type__pills">
                              <div
                                className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                  education === "General Guidance"
                                    ? "session_active"
                                    : ""
                                }`}
                              >
                                <p
                                  style={{ color: "#F5F6F7" }}
                                  className={`custom__dropdown__main__container__type__pills__name marginTop `}
                                  onClick={() =>
                                    educationSelected("General Guidance")
                                  }
                                >
                                  General guidance
                                </p>
                              </div>
                              <div
                                className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                  education === "CV Review"
                                    ? "session_active"
                                    : ""
                                }`}
                              >
                                <p
                                  style={{ color: "#F5F6F7" }}
                                  className={`custom__dropdown__main__container__type__pills__name marginTop `}
                                  onClick={() => educationSelected("CV Review")}
                                >
                                  CV review
                                </p>
                              </div>
                              <div
                                className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                  education === "Cover Letter Review"
                                    ? "session_active"
                                    : ""
                                }`}
                              >
                                <p
                                  style={{ color: "#F5F6F7" }}
                                  className={`custom__dropdown__main__container__type__pills__name marginTop`}
                                  onClick={() =>
                                    educationSelected("Cover Letter Review")
                                  }
                                >
                                  Cover letter review
                                </p>
                              </div>
                              <div
                                className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                  education === "Interview Practice"
                                    ? "session_active"
                                    : ""
                                }`}
                              >
                                <p
                                  style={{ color: "#F5F6F7" }}
                                  className={`custom__dropdown__main__container__type__pills__name  marginTop`}
                                  onClick={() =>
                                    educationSelected("Interview Practice")
                                  }
                                >
                                  Interview practice
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="custom__dropdown__main__container__experience">
                            <div className="custom__dropdown__main__container__experience__inner">
                              <p>Recognitions</p>
                              <div className="custom__dropdown__main__container__experience__inner__tooltip">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      <p className="custom__tooltip">
                                        Find the best mentors! "Rising" mentors
                                        have garnered over 4 stars with a
                                        minimum of 2 sessions, showcasing
                                        consistent positive performance and
                                        growth potential. "Top Coach" is
                                        reserved for those maintaining a rating
                                        exceeding 4 stars and completing at
                                        least 5 sessions, highlighting expertise
                                        as highly effective mentors. "High
                                        Rated" acknowledges individuals
                                        achieving an outstanding rating
                                        surpassing 4.5 stars.
                                      </p>
                                    </Tooltip>
                                  }
                                >
                                  <img src={InfoCircle} alt="info circle" />
                                </OverlayTrigger>
                              </div>
                            </div>
                            <div className="custom__dropdown__main__container__experience__pills">
                              {[
                                {
                                  name: "Top coach",
                                  icon: DiamondGrey,
                                },
                                {
                                  name: "High rated",
                                  icon: RatedStar,
                                },
                                {
                                  name: "Rising",
                                  icon: RisingStar,
                                },
                              ].map((item, index) => (
                                <div
                                  className={`custom__dropdown__main__container__experience__pills__pill pill-grey
                              ${
                                recognition === item.name
                                  ? "session_active"
                                  : ""
                              }
                                  `}
                                  onClick={() => {
                                    recognitionSelected(item.name);
                                  }}
                                >
                                  <img
                                    src={item.icon}
                                    className="custom__dropdown__main__container__experience__pills__image"
                                  />
                                  <p
                                    style={{ color: "#F5F6F7" }}
                                    className={`custom__dropdown__main__container__experience__pills__name marginTop`}
                                    // ${
                                    //   index === 2
                                    //     ? "custom__dropdown__main__container__experience__pills__selected"
                                    //     : ""
                                    // }`}
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="custom__dropdown__main__container__country">
                            <p>Investment bank</p>
                            <Dropdown onSelect={handleDropdownBankChangeNew}>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="dropdownToSelect select__icon"
                              >
                                <p className="forumm__button__text marginTop">
                                  {banks ? banks : "All"}
                                </p>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                {InvestmentBanks.map((item, index) => (
                                  <Dropdown.Item
                                    eventKey={item}
                                    className={`dropdownToSelect__item ${
                                      item === banks
                                        ? "dropdownToSelect__item__checked"
                                        : ""
                                    }`}
                                    key={item}
                                  >
                                    {item}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* Old bank select */}
                            {/* <select
                              name="banks"
                              value={banks ? banks : ""}
                              onChange={handleDropdownBankChange}
                              className="custom__dropdown__main__container__country__select select__icon"
                            >
                              <option className="custom__dropdown__main__container__country__select__item">
                                All
                              </option>
                              {InvestmentBanks.map((item, index) => (
                                <option
                                  className="custom__dropdown__main__container__country__select__item"
                                  key={item}
                                >
                                  {item}
                                </option>
                              ))}
                            </select> */}
                            {/* Old bank select */}
                          </div>
                          {/* <button className="btn-green">Apply</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12 row p-0 main__network__content">
                  <div
                    className={`${
                      userData?._id ? "" : "col-xl-8"
                    } col-12 p-0 position-relative container__network__cards`}
                  >
                    <div className="available__container__top">
                      <div className="available__container__top__right">
                        <p>
                          <span className="filter__button__mobile__text">
                            {temp?.length}
                          </span>{" "}
                          Available sessions
                        </p>
                      </div>
                      <Dropdown className="d-md-none">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="filter__button__mobile"
                        >
                          <img
                            src={SortIcon}
                            alt="filter button"
                            className="filter__button__mobile__image"
                          />
                          <p className="filter__button__mobile__text">Filter</p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" custom__dropdown__mobile custom__dropdown__bg">
                          <div className="available__container__filter">
                            <div className="available__container__filter__top">
                              <h2>Filters</h2>
                              <p
                                onClick={ResetFilter}
                                style={{ cursor: "pointer" }}
                              >
                                Reset
                              </p>
                            </div>
                            <div className="custom__dropdown">
                              <div className="custom__dropdown__main">
                                <h2 className="custom__dropdown__main__title">
                                  Date
                                </h2>
                                <div className="custom__dropdown__main__container">
                                  <div className="ascdvre__grehgtr">
                                    <div className="filter__label__inner">
                                      <img
                                        src={ArrowLeftMini}
                                        onClick={() =>
                                          filterTime(startDate, false, true)
                                        }
                                      />
                                      <label
                                        htmlFor="filterDate"
                                        className="filterDate filter__label__inner__dateshow"
                                      >
                                        <p className="">
                                          {startDate
                                            ? formatDate(startDate)
                                            : "All"}
                                        </p>
                                      </label>
                                      <img
                                        src={ArrowRightMini}
                                        onClick={() =>
                                          filterTime(startDate, true, false)
                                        }
                                      />
                                    </div>
                                    <DatePicker
                                      className="filter__date__input d-none"
                                      name="startDate"
                                      id="filterDate"
                                      placeholder="Select expiration date..."
                                      onSelect={(date) => filterTime(date)}
                                      // minDate={moment()?.toDate()}
                                    />
                                  </div>

                                  <div className="custom__dropdown__main__container__range">
                                    <p className="mb-3">Time</p>
                                    <TimeRangeSlider
                                      ref={featureRef}
                                      value={value ? value : ""}
                                      onChangeStart={changeStartHandler}
                                      onChange={timeChangeHandler}
                                      onComplete={changeCompleteHandler}
                                    />
                                    <div
                                      className="custom__dropdown__main__container__range__inner "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <p className="ms-1">
                                        {value?.start ? value?.start : "00:00"}
                                      </p>
                                      <p style={{ marginRight: "3px" }}>
                                        {" "}
                                        {value?.end ? value?.end : "23:59"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="custom__dropdown__main__container__type">
                                    <p>Session type</p>
                                    <div className="custom__dropdown__main__container__type__pills">
                                      <div
                                        className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                          education === "General Guidance"
                                            ? "session_active"
                                            : ""
                                        }`}
                                      >
                                        <p
                                          className={`custom__dropdown__main__container__type__pills__name `}
                                          onClick={() =>
                                            educationSelected(
                                              "General Guidance"
                                            )
                                          }
                                        >
                                          General guidance
                                        </p>
                                      </div>
                                      <div
                                        className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                          education === "CV Review"
                                            ? "session_active"
                                            : ""
                                        }`}
                                      >
                                        <p
                                          className={`custom__dropdown__main__container__type__pills__name `}
                                          onClick={() =>
                                            educationSelected("CV Review")
                                          }
                                        >
                                          CV review
                                        </p>
                                      </div>
                                      <div
                                        className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                          education === "Cover Letter Review"
                                            ? "session_active"
                                            : ""
                                        }`}
                                      >
                                        <p
                                          className={`custom__dropdown__main__container__type__pills__name `}
                                          onClick={() =>
                                            educationSelected(
                                              "Cover Letter Review"
                                            )
                                          }
                                        >
                                          Cover letter review
                                        </p>
                                      </div>
                                      <div
                                        className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                          education === "Interview Practice"
                                            ? "session_active"
                                            : ""
                                        }`}
                                      >
                                        <p
                                          className={`custom__dropdown__main__container__type__pills__name `}
                                          onClick={() =>
                                            educationSelected(
                                              "Interview Practice"
                                            )
                                          }
                                        >
                                          Interview practice
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="custom__dropdown__main__container__experience">
                                    <div className="custom__dropdown__main__container__experience__inner">
                                      <p>Recognitions</p>
                                      <div className="custom__dropdown__main__container__experience__inner__tooltip">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              <p className="custom__tooltip">
                                                Find the best mentors! "Rising"
                                                mentors have garnered over 4
                                                stars with a minimum of 2
                                                sessions, showcasing consistent
                                                positive performance and growth
                                                potential. "Top Coach" is
                                                reserved for those maintaining a
                                                rating exceeding 4 stars and
                                                completing at least 5 sessions,
                                                highlighting expertise as highly
                                                effective mentors. "High Rated"
                                                acknowledges individuals
                                                achieving an outstanding rating
                                                surpassing 4.5 stars.
                                              </p>
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            src={InfoCircle}
                                            alt="info circle"
                                          />
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                    <div className="custom__dropdown__main__container__experience__pills">
                                      {[
                                        {
                                          name: "Top coach",
                                          icon: DiamondGrey,
                                        },
                                        {
                                          name: "High rated",
                                          icon: RatedStar,
                                        },
                                        {
                                          name: "Rising",
                                          icon: RisingStar,
                                        },
                                      ].map((item, index) => (
                                        <div
                                          className={`custom__dropdown__main__container__experience__pills__pill pill-grey
                                        ${
                                          recognition === item.name
                                            ? "session_active"
                                            : ""
                                        }`}
                                          onClick={() => {
                                            recognitionSelected(item);
                                          }}
                                        >
                                          <img
                                            src={item.icon}
                                            className="custom__dropdown__main__container__experience__pills__image"
                                          />
                                          <p
                                            className={`custom__dropdown__main__container__experience__pills__name `}
                                            // ${
                                            //   index === 2
                                            //     ? "custom__dropdown__main__container__experience__pills__selected"
                                            //     : ""
                                            // }`}
                                          >
                                            {item.name}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  <div className="custom__dropdown__main__container__country">
                                    <p>Investment bank</p>
                                    <Dropdown
                                      onSelect={handleDropdownBankChangeNew}
                                    >
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className="dropdownToSelect select__icon"
                                      >
                                        <p className="forumm__button__text">
                                          {banks ? banks : "All"}
                                        </p>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                        {InvestmentBanks.map((item, index) => (
                                          <Dropdown.Item
                                            eventKey={item}
                                            className={`dropdownToSelect__item ${
                                              item === banks
                                                ? "dropdownToSelect__item__checked"
                                                : ""
                                            }`}
                                            key={item}
                                          >
                                            {item}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {/* old banks */}
                                    {/* <select
                                      name="banks"
                                      value={banks ? banks : ""}
                                      onChange={handleDropdownBankChange}
                                      className="custom__dropdown__main__container__country__select select__icon"
                                    >
                                      <option
                                        className="custom__dropdown__main__container__country__select__item"
                                        value={"All"}
                                      >
                                        All
                                      </option>
                                      {InvestmentBanks.map((item, index) => (
                                        <option
                                          className="custom__dropdown__main__container__country__select__item"
                                          key={index}
                                        >
                                          {item}
                                        </option>
                                      ))}
                                    </select> */}
                                    {/* old banks */}
                                  </div>
                                  {/* <button className="btn-green">Apply</button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="available__container__bottom">
                      <div className="available__container__bottom__container">
                        {/* {mydata} */}

                        {
                          temp?.length > 0 &&
                            temp?.map((elem, index) => {
                              {
                                console.log("what is the temp data", elem);
                              }
                              if (elem?.selectSession.length > 0) {
                                const closestProfessionalExperience =
                                  findClosestProfessionalExperience(
                                    elem?.mentorProfiles[0]?.professionalDetails
                                  );
                                const closestInvestmentBank =
                                  findClosestInvestmentBank(
                                    elem?.mentorProfiles[0]?.investmentBank
                                  );
                                const closestEducation = findClosestEducation(
                                  elem?.mentorProfiles[0]?.educationDetails
                                );

                                return (
                                  <MySessionCard key={elem?.userData[0]?._id}>
                                    {console.log("this is the data", elem)}
                                    <div className="available__container__bottom__container__card">
                                      <div className="available__container__bottom__container__card__left">
                                        <img
                                          // src={elem?.mentorProfiles[0]?.image}
                                          src={
                                            elem?.mentorProfiles[0]?.image ||
                                            AvatarIcon
                                          }
                                          alt="avatar"
                                          className="available__container__bottom__container__card__left__image"
                                        />
                                        <div className="available__container__bottom__container__card__left__container">
                                          {/* <Ratings /> */}
                                          <Rating
                                            readonly={true}
                                            initialValue={individualRating(
                                              elem
                                            )}
                                            allowFraction={true}
                                            fillIcon={
                                              <img
                                                src={StarFull}
                                                width={16}
                                                alt=""
                                              />
                                            }
                                            emptyIcon={
                                              <img
                                                src={EmptyStar}
                                                width={16}
                                                alt={""}
                                              />
                                            }
                                            size={20}
                                            fillColor="#F9A63A"
                                          />
                                          <p>
                                            ({" "}
                                            {elem?.relability[0]
                                              ? elem?.relability[0]?.booked
                                              : "0"}{" "}
                                            Sessions )
                                          </p>
                                        </div>
                                      </div>
                                      <div className="available__container__bottom__container__card__center">
                                        <div className="available__container__bottom__container__card__center__top">
                                          <div className="available__container__bottom__container__card__center__top__container">
                                            <h1 className="available__container__bottom__container__card__center__top__container__title text-capitalize">
                                              {elem?.userData[0]?.firstName}{" "}
                                              {elem?.userData[0]?.lastName}
                                            </h1>
                                            {elem?.mentorRattings && (
                                              <>{gettingTopOrRising(elem)}</>
                                            )}
                                          </div>
                                          <p className="available__container__bottom__container__card__center__top__description ellipsis__text">
                                            {/* 10 years at Fast Track | Ex Interviewer
                                          | Will provide you with actionable
                                          guidance and tips to perform */}
                                            {
                                              elem?.mentorProfiles[0]
                                                ?.background
                                            }
                                          </p>
                                        </div>
                                        <div className="available__container__bottom__container__card__center__bar"></div>
                                        <div className="available__container__bottom__container__card__center__bottom">
                                          {closestProfessionalExperience && (
                                            <div className="available__container__bottom__container__card__center__bottom__container">
                                              <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                                {
                                                  closestProfessionalExperience?.company
                                                }
                                                ,{" "}
                                                {
                                                  closestProfessionalExperience?.position
                                                }
                                              </h2>
                                              <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                                <p>
                                                  {new Date(
                                                    closestProfessionalExperience?.startDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                                  {" - "}
                                                  {closestProfessionalExperience?.currentlyStuding
                                                    ? "Current"
                                                    : new Date(
                                                        closestProfessionalExperience?.endDate
                                                      ).toLocaleDateString(
                                                        "en-US",
                                                        {
                                                          year: "numeric",
                                                          month: "long",
                                                        }
                                                      )}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                          {closestInvestmentBank && (
                                            <div className="available__container__bottom__container__card__center__bottom__container">
                                              <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                                {closestInvestmentBank?.bank},{" "}
                                                {
                                                  closestInvestmentBank?.position
                                                }
                                              </h2>
                                              <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                                <p>
                                                  {new Date(
                                                    closestInvestmentBank?.startDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                                  {" - "}
                                                  {closestInvestmentBank?.currentlyStuding
                                                    ? "Current"
                                                    : new Date(
                                                        closestInvestmentBank?.endDate
                                                      ).toLocaleDateString(
                                                        "en-US",
                                                        {
                                                          year: "numeric",
                                                          month: "long",
                                                        }
                                                      )}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                          {closestEducation && (
                                            <div className="available__container__bottom__container__card__center__bottom__container">
                                              <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                                {closestEducation?.degree},{" "}
                                                {closestEducation?.institution}
                                              </h2>
                                              <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                                <p>
                                                  {new Date(
                                                    closestEducation?.startDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                                  {" - "}
                                                  {closestEducation?.currentlyStuding
                                                    ? "Current"
                                                    : new Date(
                                                        closestEducation?.endDate
                                                      ).toLocaleDateString(
                                                        "en-US",
                                                        {
                                                          year: "numeric",
                                                          month: "long",
                                                        }
                                                      )}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="available__container__bottom__container__card__right">
                                        <Dropdown
                                          className="available__container__bottom__container__card__right__dropdown"
                                          onSelect={(eventKey) =>
                                            handleSelectSession(eventKey, elem)
                                          }
                                        >
                                          <Dropdown.Toggle className="coaching__filter__button padding__button__mobile">
                                            <span className="coaching__filter__button__text marginTop">
                                              {elem?.selectedSession ||
                                                "Select type"}

                                              {console.log(
                                                "this is th selection"
                                              )}
                                            </span>
                                            <img
                                              src={DropDownArrow}
                                              className="coaching__filter__button__image"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                            {elem?.selectSession.map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  className="custom__dropdown__item"
                                                  key={index}
                                                  eventKey={item}
                                                  // onClick={() => setSelectedPricing(item)}
                                                  // onClick={() =>
                                                  //   GetPriceValue(item, index)
                                                  // }
                                                >
                                                  {item}
                                                </Dropdown.Item>
                                              )
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <h2 className="my-2">
                                          {elem?.mentorPricing?.map(
                                            (item, index) => {
                                              return (
                                                <>
                                                  {item?.currency === "usd"
                                                    ? "$"
                                                    : item?.currency === "euro"
                                                    ? "€"
                                                    : item?.currency == "gbp"
                                                    ? "£"
                                                    : item?.currency === "chf"
                                                    ? "₣"
                                                    : ""}
                                                  {/* {item?.currency} */}
                                                  {elem?.mentorPricing[0]?.[
                                                    elem?.selectedSession
                                                  ]
                                                    ? elem?.mentorPricing[0]?.[
                                                        elem?.selectedSession
                                                      ]
                                                    : "0"}
                                                </>
                                              );
                                            }
                                          )}
                                        </h2>
                                        <button
                                          className={` ${
                                            elem?.mentorAvalible?.every(
                                              (session) => session.booked
                                            )
                                              ? "book__button__disabled"
                                              : "available__container__bottom__container__card__right__button"
                                          }`}
                                          onClick={() => {
                                            handleElementClick(elem);
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "0px",
                                              marginTop: "1px",
                                            }}
                                          >
                                            {elem?.mentorAvalible?.every(
                                              (session) => session.booked
                                            )
                                              ? "Fully booked"
                                              : "Book session"}
                                          </p>
                                        </button>
                                      </div>
                                    </div>
                                    {/* {elem?.mentorAvalible?.map((elem) => {
                                    return (
                                      <>
                                        <p>{moment(elem?.startDateTime).format("HH:mm")}</p>
                                        <p>{moment(elem?.endDateTime).format("HH:mm")}</p>
                                        <p>{elem?.date}</p>
  
                                      </>
                                    )
                                  })} */}
                                  </MySessionCard>
                                );
                              }
                            })

                          // : (
                          //   // <div
                          //   //   style={{
                          //   //     display: "flex",
                          //   //     alignItems: "center",
                          //   //     justifyContent: "center",

                          //   //     // height: "75vh",
                          //   //   }}
                          //   //   className="reschedule__session__container__bottom__left"
                          //   // >
                          //   //   <div
                          //   //     style={{ marginTop: "184px" }}
                          //   //     className="no__found__main"
                          //   //   >
                          //   //     <div>
                          //   //       <img src={noFound} />
                          //   //     </div>
                          //   //     <div className="no__found">
                          //   //       No sessions available matching your search
                          //   //       criterias
                          //   //     </div>
                          //   //     <div className="appear__here">
                          //   //       Sessions proposed by others would appear on this
                          //   //       page
                          //   //     </div>
                          //   //   </div>
                          //   // </div>
                          // )
                        }
                        {temp2?.length > 0 &&
                          temp2.map((items, index) => {
                            const closestProfessionalExperience =
                              findClosestProfessionalExperience(
                                items.professionalDetails
                              );
                            const closestInvestmentBank =
                              findClosestInvestmentBank(items.investmentBank);
                            const closestEducation = findClosestEducation(
                              items.educationDetails
                            );
                            return (
                              <MySessionCard key={items._id}>
                                <div className="available__container__bottom__container__card">
                                  <div className="available__container__bottom__container__card__left">
                                    <img
                                      // src={elem?.mentorProfiles[0]?.image}
                                      src={items?.image || AvatarIcon}
                                      alt="avatar"
                                      className="available__container__bottom__container__card__left__image"
                                    />
                                    <div className="available__container__bottom__container__card__left__container">
                                      {/* <Ratings /> */}
                                      <Rating
                                        readonly={true}
                                        initialValue={individualRatingAll(
                                          items
                                        )}
                                        allowFraction={true}
                                        fillIcon={
                                          <img
                                            src={StarFull}
                                            width={16}
                                            alt=""
                                          />
                                        }
                                        emptyIcon={
                                          <img
                                            src={EmptyStar}
                                            width={16}
                                            alt={""}
                                          />
                                        }
                                        size={20}
                                        fillColor="#F9A63A"
                                      />
                                      <p>
                                        ({" "}
                                        {items?.reliability.length > 0 &&
                                        items?.reliability[0]
                                          ? items?.reliability[0]?.booked
                                          : "0"}{" "}
                                        Sessions )
                                      </p>
                                    </div>
                                  </div>
                                  <div className="available__container__bottom__container__card__center">
                                    <div className="available__container__bottom__container__card__center__top">
                                      <div className="available__container__bottom__container__card__center__top__container">
                                        <h1 className="available__container__bottom__container__card__center__top__container__title text-capitalize">
                                          {items?.mentorId?.firstName}{" "}
                                          {items?.mentorId?.lastName}
                                        </h1>
                                        {/* {elem?.mentorRattings && (
                                          <>{gettingTopOrRising(elem)}</>
                                        )} */}
                                      </div>
                                      <p className="available__container__bottom__container__card__center__top__description ellipsis__text">
                                        {/* 10 years at Fast Track | Ex Interviewer
                                          | Will provide you with actionable
                                          guidance and tips to perform */}
                                        {items?.background}
                                      </p>
                                    </div>
                                    <div className="available__container__bottom__container__card__center__bar"></div>
                                    <div className="available__container__bottom__container__card__center__bottom">
                                      {closestProfessionalExperience && (
                                        <div className="available__container__bottom__container__card__center__bottom__container">
                                          <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                            {
                                              closestProfessionalExperience?.company
                                            }
                                            ,{" "}
                                            {
                                              closestProfessionalExperience?.position
                                            }
                                          </h2>
                                          <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                            <p>
                                              {new Date(
                                                closestProfessionalExperience?.startDate
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                              })}
                                              {" - "}
                                              {closestProfessionalExperience?.currentlyStuding
                                                ? "Current"
                                                : new Date(
                                                    closestProfessionalExperience?.endDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {closestInvestmentBank && (
                                        <div className="available__container__bottom__container__card__center__bottom__container">
                                          <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                            {closestInvestmentBank?.bank},{" "}
                                            {closestInvestmentBank?.position}
                                          </h2>
                                          <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                            <p>
                                              {new Date(
                                                closestInvestmentBank?.startDate
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                              })}
                                              {" - "}
                                              {closestInvestmentBank?.currentlyStuding
                                                ? "Current"
                                                : new Date(
                                                    closestInvestmentBank?.endDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {closestEducation && (
                                        <div className="available__container__bottom__container__card__center__bottom__container">
                                          <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                            {closestEducation?.degree},{" "}
                                            {closestEducation?.institution}
                                          </h2>
                                          <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                            <p>
                                              {new Date(
                                                closestEducation?.startDate
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                              })}
                                              {" - "}
                                              {closestEducation?.currentlyStuding
                                                ? "Current"
                                                : new Date(
                                                    closestEducation?.endDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="available__container__bottom__container__card__right">
                                    <Dropdown
                                      className="available__container__bottom__container__card__right__dropdown"
                                      onSelect={(eventKey) => {
                                        if (eventKey === "CV Review") {
                                          handleSelectSession2(
                                            "cvReview",
                                            items
                                          );
                                        } else if (
                                          eventKey === "Cover Letter Review"
                                        ) {
                                          handleSelectSession2(
                                            "coverLetter",
                                            items
                                          );
                                        } else if (
                                          eventKey === "General Guidance"
                                        ) {
                                          handleSelectSession2(
                                            "generalGuidance",
                                            items
                                          );
                                        } else if (
                                          eventKey === "Interview Practice"
                                        ) {
                                          handleSelectSession2(
                                            "interviewPractice",
                                            items
                                          );
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle className="coaching__filter__button padding__button__mobile">
                                        <span className="coaching__filter__button__text marginTop">
                                        
                                          {items?.selectedSession === "cvReview"
                                            ? "CV Review"
                                            : items?.selectedSession ===
                                              "coverLetter"
                                            ? "Cover Letter Review"
                                            : items?.selectedSession ===
                                              "generalGuidance"
                                            ? "General Guidance"
                                            : items?.selectedSession ===
                                              "interviewPractice"
                                            ? "Interview Practice"
                                            : "Select Type"}
                                        </span>
                                        <img
                                          src={DropDownArrow}
                                          className="coaching__filter__button__image"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                        {items?.selectSession.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              className="custom__dropdown__item"
                                              key={index}
                                              eventKey={item}
                                              // onClick={() => setSelectedPricing(item)}
                                              // onClick={() =>
                                              //   GetPriceValue(item, index)
                                              // }
                                            >
                                              {item}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    <h2 className="my-2">
                                      {/* {elem?.mentorPricing?.map(
                                        (item, index) => {
                                          return (
                                            <> */}
                                      {items?.mentorPricing?.currency === "usd"
                                        ? "$"
                                        : items?.mentorPricing?.currency ===
                                          "euro"
                                        ? "€"
                                        : items?.mentorPricing?.currency ==
                                          "gbp"
                                        ? "£"
                                        : items?.mentorPricing?.currency ===
                                          "chf"
                                        ? "₣"
                                        : ""}

                                      {items?.mentorPricing?.[
                                        items?.selectedSession
                                      ]
                                        ? items?.mentorPricing?.[
                                            items?.selectedSession
                                          ]
                                        : "0"}
                                      {/* </> */}
                                    </h2>
                                    <button
                                      className="book__button__disabled"
                                      // onClick={() => {
                                      //   handleElementClick(items);
                                      // }}
                                    >
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          marginTop: "1px",
                                        }}
                                      >
                                        Not Avalible
                                      </p>
                                    </button>
                                  </div>
                                </div>
                                {/* {elem?.mentorAvalible?.map((elem) => {
                                    return (
                                      <>
                                        <p>{moment(elem?.startDateTime).format("HH:mm")}</p>
                                        <p>{moment(elem?.endDateTime).format("HH:mm")}</p>
                                        <p>{elem?.date}</p>
  
                                      </>
                                    )
                                  })} */}
                              </MySessionCard>
                            );
                          })}
                      </div>
                    </div>
                    <div
                      style={{ marginBottom: "300px" }}
                      className="dashboard__footer foter__fixed d-md-none d-xl-block"
                    >
                      <Footer />
                    </div>
                  </div>
                  {userData?._id ? (
                    ""
                  ) : (
                    <div className="col-xl-4 p-0 col-12  p-0 network__visitor__container">
                      <div className="network__visitor__container__inner">
                        <div className="join__all__over__the__world">
                          Join us to learn with mentors from all over the world
                        </div>
                        <div className="join__button__container">
                          <Link to="/signup" className="create__account">
                            Create an account
                          </Link>
                          <Link
                            to="/login"
                            className="network__right__side__login"
                          >
                            Log in
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{ marginBottom: "300px" }}
                    className="dashboard__footer d-none d-md-block d-xl-none"
                  >
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BookSession;
