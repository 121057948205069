// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__container__inner {
  padding-top: 25px;
  padding-bottom: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}
.footer__container__inner__image {
  width: 122.326px;
  height: 28px;
}
.footer__container__inner__text {
  font-size: 14px;
  font-style: normal;
  font-family: AktivGrotesk-Regular;
  font-weight: 400;
  line-height: 120%;
  color: #989fad;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .footer__container {
    margin-bottom: 20px;
  }
  .footer__container__inner {
    padding: 12px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,iBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AADI;EACE,gBAAA;EACA,YAAA;AAGN;AADI;EACE,eAAA;EACA,kBAAA;EACA,iCAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAGN;;AAEA;EACE;IACE,mBAAA;EACF;EAAE;IACE,eAAA;EAEJ;AACF","sourcesContent":[".footer__container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &__inner {\n    padding-top: 25px;\n    padding-bottom: 54px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 12px;\n    &__image {\n      width: 122.326px;\n      height: 28px;\n    }\n    &__text {\n      font-size: 14px;\n      font-style: normal;\n      font-family: AktivGrotesk-Regular;\n      font-weight: 400;\n      line-height: 120%;\n      color: #989fad;\n      margin-bottom: 0;\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .footer__container {\n    margin-bottom: 20px;\n    &__inner {\n      padding: 12px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
