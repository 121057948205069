import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../Navbar/NavBar.jsx";

//
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import PerformanceIcon from "../../assets/performance-icon.svg";
import PerformanceIconGreen from "../../assets/performance-icon-green.svg";
import InterviewIcon from "../../assets/interview-icon.svg";
import InterviewIconGreen from "../../assets/interview-icon-green.svg";
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import { useParams } from "react-router-dom";
// import Interview from "../InterviewGuides/Interview";
import "./dashboardbar.scss";
//
import BookSession from "../BookSession/BookSession";
import MySessions from "../Slot/Slot";
import Interview from "../InterviewGuides/Interview";
import Performance from "../Performance/Performance";
import pay from "../../assets/play-circle.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter.js";
import { mySession } from "../../service/redux/middleware/session.js";
import { mySessionNotBooked } from "../../service/redux/middleware/session.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRelability } from "../../service/redux/middleware/relaible";
import Dashboard from "./Dashboard.js";

const InterViewGuidInterView = ({ children }) => {
  const [relaible, setRelaible] = useState();
  const [showBook, setShowBook] = useState(false);

  const [mySessionData, setMySessionData] = useState([]);
  const history = useHistory();

  const [notBookedSessionData, setNotBookedSessionData] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const { mysessionId } = useParams();
  const [selected, setSelected] = useState(0);
  const setSelectedComponent = (asd) => {
    setSelected(asd);
  };



  useEffect(() => {
    if (mysessionId == undefined) {
      setSelectedComponent(0);
    } else if (mysessionId === "purpose") {
      setSelectedComponent(0);
    } else {
      setSelectedComponent(mysessionId);
    }
  }, [mysessionId]);

  const changePath = (index) => {
    // if (mysessionId) {
    setSelectedComponent(index);
    // history.push("/interview");
    // } else {
    // }
  };
  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      // Convert the stored string back to a boolean
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      <Dashboard>
      <Interview setSelectedComponent={setSelectedComponent} />
      </Dashboard>
    </>
  );
};

export default InterViewGuidInterView;
