import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { Form } from "react-bootstrap";
import eyeIcon from "../../assets/eyeIcon.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import NavLogo from "../../assets/8.svg";
import { mentorSignUpEmail } from "../../service/redux/middleware/mentor";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../MyToaster";
import ScreenLoader from "../Loader/ScreenLoader";

const MentorSignInEmail = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loader, setLoader] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const { email } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password?.length > 0) {
      const objectToSend = {
        password: password,
      };
      const data = {
        email: email,
        objectToSend,
      };
      setLoader(true);
      dispatch(mentorSignUpEmail(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          SuccessToast(res?.payload?.data?.success);
          history.push("/createstripe");
        } else {
          ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Please enter Password.");
    }
  };
  return (
    <div>
      {loader && <ScreenLoader />}
      <div className="PersonalInfoSec">
        <div className="widthDivForResestMentor">
          <div className="PersonalInfoSec__nav">
            <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="" />
          </div>
          <div className="PersonalInfoSec__Heading">
            <h1 className="">Please enter password</h1>
            <p className="">{email}</p>
          </div>
          <div className="PersonalInfoSecForm">
            <Form>
              {/* <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  First name
                </Form.Label>
                <Form.Control
                  type="text"
                  className="loginfield__type"
                  placeholder="Angleo"
                  alue={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  className="loginfield__type"
                  placeholder="Doe"
                  alue={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  Phone number
                </Form.Label>
                <PhoneInput
                  className="loginfield__type"
                  placeholder="please enter the number"
                  //defaultCountry="RU"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e)}
                />
              </Form.Group> */}
              <Form.Group className=" loginfield " controlId="">
                <Form.Label className="loginfield__Label ">
                  Enter password
                </Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  className="loginfield__type"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <img
                  className="loginfield__img"
                  style={{ top: "53px" }}
                  src={!showPassword ? eyeIcon : EyeSlash}
                  onClick={() => setShowPassword(!showPassword)}
                  alt=""
                ></img>
              </Form.Group>
              <button className="login__green__button" onClick={handleSubmit}>
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorSignInEmail;
