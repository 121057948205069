import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../client";
import axios from "axios";

// export const getInterviewGuide = createAsyncThunk("getInterviewGuide", async (data) => {
//     try {
//         const res = await axios.post(`${API_URL}/api/getInterviewGuid`, {
//             difficulty: data
//         })
//         return { status: res.status, data: res?.data };
//     } catch (error) {
//         return {
//             message: error?.response?.data?.error,
//             status: error?.response?.status,
//         };

//     }
// });

// mentordashboard guides
export const getInterviewGuideMentor = createAsyncThunk(
  "getInterviewGuideMentor",
  async (data) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/getGuidMentor/${data.meetingId}/${data.userId}`
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

//

export const getInterviewGuide = createAsyncThunk(
  "getInterviewGuide",
  async (data) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/mySessionGuid/${data.meetingId}/${data.userId}`
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getInterviewGuideForUser = createAsyncThunk(
  "getInterviewGuideForUser",
  async (id) => {
    try {
      const res = await axios.get(`${API_URL}/api/getGuid/${id}`);
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
