import React, { useState, useEffect, useRef } from "react";
// icons
import LeftArrow from "../../../assets/arrow-left.svg";
import "./proposeSession.scss";
import { ConfirmModal } from "../../SessionModal/SessionModal";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import moment from "moment-timezone";
import "moment-timezone/data/meta/latest.json";
import { createSession } from "../../../service/redux/middleware/proposeSessionApi";
import { viewSession } from "../../../service/redux/middleware/session";
import ScreenLoader from "../../Loader/ScreenLoader";
import RemoveIcon from "../../../assets/remove-icon.svg";
import { mySessionNotBooked } from "../../../service/redux/middleware/session";

import DateTimePicker from "react-datetime-picker";
import CalendarEaxct from "../../../assets/CalendarEaxct.svg";
import squareAddWhite from "../../../assets/squareAddWhite.svg";

let timeHalfHour = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  // "23:00",
  // "23:30",
  // "00:00",
];
let timeHalfHourEnd = [
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "23:59",
];
const formatDate = (date) => {
  const year = date?.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const ProposeSession = ({
  setShowPropose,
  setSessionDataBook,
  setNotBookedSessionData,
}) => {
  const [loader, setLoader] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [timeZone, setTimeZone] = useState();
  const [timeZoneValue, setTimeZoneValue] = useState("");
  const [hourStartTime, setHourStartTime] = useState();
  const [timeZoneArray, setTimeZoneArray] = useState(getTimeZones());
  const timeZones = getTimeZones();
  const searchRef = useRef(null);
  const [openDateCalender, setOenDateCalender] = useState(false);
  const [calenderIndex, setCalenderIndex] = useState();
  const [timeSlot, setTimeSlot] = useState([
    {
      timezone: timeZone,
      timeZoneValue: timeZoneValue,
      date: moment(new Date()).format("YYYY-MM-DD"),
      dateSend: new Date(),
      startTime: "",
      endTime: "",
      originTime: "",
    },
  ]);

  function getEndTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setHours(currentTime.getHours() + 1);
    currentTime.setMinutes(currentTime.getMinutes() + 30);
    const updatedHours = currentTime.getHours().toString().padStart(2, "0");
    const updatedMinutes = currentTime.getMinutes().toString().padStart(2, "0");
    return `${updatedHours}:${updatedMinutes}`;
  }
  //
  const handleSelectStartTime = (selected, index) => {
    const updatedMultiplemages = [...timeSlot];
    updatedMultiplemages[index]["startTime"] = selected;
    updatedMultiplemages[index]["endTime"] = getEndTime(selected);
    setTimeSlot(updatedMultiplemages);
  };
  //
  const handleSelectEndTime = (selected, index) => {
    if (timeSlot[index].startTime === "") {
      ErrorToast("Please select start time first.");
    } else {
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index]["endTime"] = selected;
      setTimeSlot(updatedMultiplemages);
    }
  };
  //
  const handleChangeForDateAndTime = (e, index, isDate = "") => {
    let currentDate = new Date();
    if (isDate.length > 0) {
      let isToday = false;
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      if (timeZoneValue === userTimeZone) {
        const todayDate = moment(currentDate).format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = getEndTime(hourStartTime);
          updatedMultiplemages[index]["startTime"] = hourStartTime;
          updatedMultiplemages[index]["originTime"] = hourStartTime;
          setTimeSlot(updatedMultiplemages);
        } else {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = "";
          updatedMultiplemages[index]["startTime"] = "";
          updatedMultiplemages[index]["originTime"] = "";
          setTimeSlot(updatedMultiplemages);
        }
      } else {
        const now = moment.tz(timeZoneValue);
        const timeZoness = now.format("YYYY-MM-DD HH:mm:ss");
        const todayDate = moment
          .utc(timeZoness)
          .startOf("day")
          .format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = getEndTime(hourStartTime);
          updatedMultiplemages[index]["startTime"] = hourStartTime;
          updatedMultiplemages[index]["originTime"] = hourStartTime;
          setTimeSlot(updatedMultiplemages);
        } else {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = "";
          updatedMultiplemages[index]["startTime"] = "";
          updatedMultiplemages[index]["originTime"] = "";
          setTimeSlot(updatedMultiplemages);
        }
      }
    } else if (e.target.name === "startTime") {
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      updatedMultiplemages[index]["endTime"] = getEndTime(e.target.value);
      setTimeSlot(updatedMultiplemages);
     // e.target.value = null;
    } else if (e.target.name === "endTime") {
      if (timeSlot[index].startTime === "") {
        ErrorToast("Please select start time first.");
      } else {
        const updatedMultiplemages = [...timeSlot];
        updatedMultiplemages[index][e.target?.name] = e.target?.value;
        setTimeSlot(updatedMultiplemages);
      }
    } else {
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      setTimeSlot(updatedMultiplemages);
     // e.target.value = null;
    }
  };
  const handleAddClickForDate = (e) => {
    if (timeZone === null || timeZone === undefined) {
      ErrorToast("Please select your timezone");
      return false;
    } else {
      e.preventDefault();
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === timeZoneValue
      );
      setTimeSlot([
        ...timeSlot,
        {
          timezone: timeZone,
          timeZoneValue: matchedTimeZone.label,
          date: moment(new Date()).format("YYYY-MM-DD"),
          dateSend: new Date(),
          startTime: hourStartTime,
          endTime: getEndTime(hourStartTime),
          originTime: hourStartTime,
        },
      ]);
    }
  };

  const handleRemoveClickForDate = (e, index) => {
    e.preventDefault();
    const list = [...timeSlot];
    list.splice(index, 1);
    setTimeSlot(list);
  };

  const formValidation = () => {
    if (timeZone === null || timeZone === undefined) {
      ErrorToast("Please select your timezone");
      return false;
    }
    let allGood3 = true;
    timeSlot?.map((item) => {
      if (item?.startTime !== "") {
      } else {
        ErrorToast("Plese Select Date and Time");
        allGood3 = false;
      }
    });
    const selectedTimeSlots = {};
    timeSlot.forEach((item, index) => {
      if (item.date) {
        const key = `${item.date}-${item.startTime}-${item.endTime}`;
        if (selectedTimeSlots[key]) {
          ErrorToast("Duplicate time slots are not allowed.");
          allGood3 = false;
          return false;
        } else {
          selectedTimeSlots[key] = true;
        }
      } else {
        ErrorToast("Please select a date.");
        allGood3 = false;
      }
    });
    if (allGood3) {
      if (difficultyLevel === "") {
        ErrorToast("Please select difficulty Level");
        return false;
      } else {
        let allGood2 = true;
        sessionObjectives?.map((item) => {
          if (item.objecive !== "") {
          } else {
            ErrorToast("Please add session objectives.");
            allGood2 = false;
          }
        });
        if (allGood2 === false) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  function getTimeZones() {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset() / 60;
      const offsetString = offset >= 0 ? `+${offset}` : offset;
      const timeZoneName = moment.tz(zone).zoneAbbr();

      return {
        value: zone,
        label: `${zone}, ${timeZoneName} (GMT ${offsetString})`,
      };
    });
  }

  const handleSelectTimeZone = (selected) => {
    if (selected !== "default") {
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === selected
      );
      const selectedTimeZone = matchedTimeZone.value;
      const selectedLabel = matchedTimeZone.label;
      const origin = moment.tz(selectedTimeZone);
      setTimeZoneValue(matchedTimeZone.value);
      let s1 = origin.format("HH");
      let s2 = origin.format("mm");
      if (s1 < 22) {
        if (s2 <= 30) {
          s2 = "30";
        } else {
          s2 = "00";
          s1 = Number(s1) + 1;
          if (s1 < 10) {
            s1 = "0" + s1;
          }
        }
      }
      let ss = s1 + ":" + s2;
      let date11 = origin?.format("MMM DD, yyyy");
      console.log("origin ss", ss, date11);
      if (ss <= "22:30") {
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const initialOption1 = timeHalfHour.find((item) => item == ss);
        if (initialOption1) {
          const updatedTimeSlot = timeSlot.map((item) => ({
            ...item,
            startTime: initialOption1,
            date: moment(origin).format("YYYY-MM-DD"),
            dateSend: date11,
            timezone: offsetMinutes,
            timeZoneValue: selectedLabel,
            originTime: initialOption1,
            endTime: getEndTime(initialOption1),
          }));
          setHourStartTime(initialOption1);
          setTimeSlot(updatedTimeSlot);
        }
      } else {
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const updatedTimeSlot = timeSlot.map((item) => ({
          ...item,
          startTime: "00:00",
          timezone: offsetMinutes,
          timeZoneValue: selectedLabel,
          originTime: "00:00",
          endTime: getEndTime("00:00"),
        }));
        setHourStartTime("00:00");
        setTimeSlot(updatedTimeSlot);
      }
    }
  };

  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const data = {
    userId: userData?._id,
    token: token,
  };
  function getTimeZones() {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset() / 60;
      const offsetString = offset >= 0 ? `+${offset}` : offset;
      const timeZoneName = moment.tz(zone).zoneAbbr();

      return {
        value: zone,
        label: `${zone}, ${timeZoneName} (GMT ${offsetString})`,
      };
    });
  }

  const dispatch = useDispatch();
  const history = useHistory();

  const CreateSession = () => {
    const newArray = timeSlot?.map((obj) => {
      const { dateSend, originTime, ...newObj } = obj;
      return newObj;
    });

    const objectiveStringArr = sessionObjectives.map((obj) => obj.objecive);
    const data1 = {
      difficultyLevel: difficultyLevel,
      creatorId: `${userData._id}`,
      objecive: objectiveStringArr,
      timeSlot: newArray,
      token: token,
    };
    setLoader(true);
    dispatch(createSession(data1)).then((res1) => {
      setLoader(false);
      if (res1?.payload?.status === 200) {
        setLoader(false);
        SuccessToast(res1?.payload?.data?.message);
        dispatch(viewSession()).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            let data = res?.payload?.data?.filter(
              (elem) => elem?.creatorId !== userData?._id
            );
            setSessionDataBook(data);
            setShowConfirmModal(false);
            setShowPropose(false);
            history.push("/interviewdashboard");
          } else {
            setLoader(false);
          }
        });
        dispatch(mySessionNotBooked(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setNotBookedSessionData(res?.payload?.data);
          } else {
          }
        });
      } else {
        setLoader(false);
        setShowConfirmModal(false);
        ErrorToast(res1?.payload?.message);
      }
    });
  };
  const cancelConfirmation = () => {
    setShowConfirmModal(false);
  };

  const SubmitBookSesssion = () => {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setShowConfirmModal(true);
  };

  const [sessionObjectives, setSessionObjectives] = useState([
    { objecive: "" },
  ]);
  const handleChangeForSessionObjective = (e, index) => {
    const updatedMultiplemages = [...sessionObjectives];
    updatedMultiplemages[index][e.target?.name] = e.target?.value;
    setSessionObjectives(updatedMultiplemages);
    // e.target.value = null;
  };

  const handleAddClick1 = (e) => {
    e.preventDefault();
    if (sessionObjectives.length < 3) {
      setSessionObjectives([...sessionObjectives, { objecive: "" }]);
    } else {
      ErrorToast("Maximum of 3 session objectives per booking.");
    }
  };

  const handleRemoveClick1 = (e, index) => {
    e.preventDefault();
    const list = [...sessionObjectives];
    list.splice(index, 1);
    setSessionObjectives(list);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changePath = () => {
    setShowPropose(false);
  };

  function addAndCheckTime(timeStr, i) {
    const currentTime = new Date();
    const [hours, minutes] = timeStr.split(":").map(Number);
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59);
    const timeDifference = endOfDay - currentTime;
    let endDF = timeDifference / 60 / 60 / 1000;
    if (endDF === 1.4997222222222222) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 29);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      let toUpdae = { ...timeSlot };
      if (toUpdae[i].endTime) {
        toUpdae[i].endTime = `${resultHours}:${resultMinutes}`;
        setTimeSlot(toUpdae);
      }
      return `${resultHours}:${resultMinutes}`;
    } else if (timeDifference > 1.5 * 60 * 60 * 1000) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      let toUpdae = { ...timeSlot };
      if (!toUpdae[i].endTime) {
        toUpdae[i].endTime = `${resultHours}:${resultMinutes}`;
        setTimeSlot(toUpdae);
      }
      return `${resultHours}:${resultMinutes}`;
    } else {
      return "25:00";
    }
  }

  function addAndCheckTime(timeStr) {
    const currentTime = new Date();
    const [hours, minutes] = timeStr?.split(":").map(Number);
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59);
    const timeDifference = endOfDay - currentTime;
    let endDF = timeDifference / 60 / 60 / 1000;
    if (endDF === 1.4997222222222222) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 29);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else if (timeDifference > 1.5 * 60 * 60 * 1000) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else {
      return "25:00";
    }
  }
  //
  //
  // Search Timezone
  //
  //
  const [searchedTimezone, setSearchedTimezone] = useState("");
  const searchTimeZonesValue = (e) => {
    setSearchedTimezone(e.target.value);
  };
  const searchTimezonesFromArray = () => {
    if (searchedTimezone === "") {
      setTimeZoneArray(timeZones);
    } else {
      let list = [...timeZones];
      list = list.filter((item) =>
        item.label.toLowerCase().includes(searchedTimezone.toLowerCase())
      );
      setTimeZoneArray(list);
    }
  };

  const handleDropdownToggleTimeZone = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    searchTimezonesFromArray();
  }, [searchedTimezone]);
  return (
    <>
      <div className="reschedule__session__container">
        <div className="reschedule__session__container__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={() => changePath()}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back to Book sessions
            </p>
          </div>
        </div>
        <div className="reschedule__session__container__bottom">
          <Row  className="reschedule__session__container__bottom__row">
            <Col
              md={8}
              lg={8}
              xl={8}
              className="reschedule__session__container__bottom__left"
            >
              <h2 className="reschedule__session__container__bottom__left__title">
                Propose session
              </h2>
              <div className="reschedule__session__container__bottom__left__form">
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Timezone
                    </p>
                  </label>

                  <Dropdown
                    onSelect={handleSelectTimeZone}
                    onToggle={handleDropdownToggleTimeZone}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdownToSelect select__icon"
                    >
                      <p className="forumm__button__text">
                        {timeZoneValue
                          ? timeZoneValue
                          : "Please select timezone by city"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu timezone__relative "
                      style={{ padding: "0px" }}
                    >
                      <Form.Control
                        ref={searchRef}
                        type="text"
                        className="search__input search__timezone"
                        placeholder="Search timezone by city..."
                        name="timezone"
                        value={searchedTimezone}
                        onChange={(e) => searchTimeZonesValue(e)}
                      />
                      {timeZoneArray?.map((zone) => (
                        <Dropdown.Item
                          key={zone.value}
                          eventKey={zone.value}
                          className="dropdownToSelect__item"
                        >
                          {zone.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {timeSlot?.map((x, i) => {
                  return (
                    <div>
                      <div className="reschedule__session__container__bottom__left__form__propose">
                        <div className="cancelbtn-container">
                          {timeSlot.length > 1 && i > 0 && (
                            <button
                              className="btn-cancel"
                              onClick={(e) => handleRemoveClickForDate(e, i)}
                            >
                              <p>Remove</p>
                              <img src={RemoveIcon} />
                            </button>
                          )}
                        </div>
                        <p className=" reschedule__session__container__bottom__left__form__propose__text">
                          Add date
                        </p>

                        <DateTimePicker
                          className="schedules__guides__contaiener__calendar calender__padding"
                          name="dateSend"
                          format="MMM dd, yyyy"
                          disableClock={true}
                          isCalendarOpen={
                            openDateCalender
                              ? calenderIndex === i && true
                              : false
                          }
                          onClick={() => {
                            setOenDateCalender(!openDateCalender);
                            setCalenderIndex(i);
                          }}
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                          onChange={(e) =>
                            handleChangeForDateAndTime(e, i, "dateSend")
                          }
                          value={timeSlot[i]?.dateSend}
                        />
                        <div className="reschedule__session__container__bottom__left__form__container__two reschedule__session__container__bottom__left__form__propose__times">
                          <Dropdown
                            onSelect={(e) => handleSelectStartTime(e, i)}
                            style={{ width: "49%" }}
                          >
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className={`dropdownToSelect select__icon ${timeZone == null
                                  ? "dropdownToSelect__maindisabled"
                                  : ""
                                }`}
                            >
                              <p className="forumm__button__text">
                                {timeSlot[i]?.startTime
                                  ? timeSlot[i]?.startTime
                                  : "Select start time"}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              {timeHalfHour.map((hour) => (
                                <Dropdown.Item
                                  className={`${hour < timeSlot[i]?.originTime
                                      ? " dropdownToSelect__disabled"
                                      : timeSlot[i]?.startTime === hour
                                        ? "dropdownToSelect__item__checked"
                                        : "dropdownToSelect__item"
                                    }`}
                                  eventKey={hour}
                                  key={hour}
                                  disabled={hour < timeSlot[i]?.originTime}
                                >
                                  {hour}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown
                            onSelect={(e) => handleSelectEndTime(e, i)}
                            style={{ width: "49%" }}
                            show={false}
                          >
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className={`dropdownToSelect  ${timeZone == null
                                  ? "dropdownToSelect__maindisabled"
                                  : ""
                                }`}
                            >
                              <p className="forumm__button__text">
                                {timeSlot[i]?.endTime
                                  ? timeSlot[i]?.endTime
                                  : timeSlot[i]?.startTime === ""
                                    ? "End time"
                                    : addAndCheckTime(timeSlot[i].startTime)}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              {timeHalfHourEnd.map((hour) => (
                                <Dropdown.Item
                                  className={`${hour < timeSlot[i]?.startTime
                                      ? " dropdownToSelect__disabled"
                                      : timeSlot[i]?.endTime === hour
                                        ? "dropdownToSelect__item__checked"
                                        : "dropdownToSelect__item"
                                    }`}
                                  eventKey={hour}
                                  key={hour}
                                  disabled={
                                    (hour !== "00:00" &&
                                      timeSlot[i]?.startTime &&
                                      hour <
                                      addAndCheckTime(
                                        timeSlot[i].startTime
                                      )) ||
                                    hour >
                                    addAndCheckTime(timeSlot[i].startTime)
                                  }
                                >
                                  {hour}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text__btn__container">
                          <p className="reschedule__session__container__bottom__left__subtitle">
                            Each meeting slot is allocated a duration of 1.5
                            hours
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="btn-box d-flex ">
                  <button
                    className="personalADD mt-0"
                    onClick={(e) => handleAddClickForDate(e)}
                  >
                    <img
                      src={squareAddWhite}
                      alt=""
                      width="16px"
                      height="17px"
                    />
                    <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                      Add another slot
                    </p>
                  </button>
                </div>
              </div>
              <div className="reschedule__session__container__bottom__left__form margin-propose-32">
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Interview guide difficulty level
                    </p>
                  </label>
                  <div className="level__btn__btn__main">
                    <div className="level__btn__btn__main__inner">
                      <button
                        onClick={() => setDifficultyLevel("Fundamental")}
                        className={
                          difficultyLevel === "Fundamental" ? "active" : ""
                        }
                      >
                        Fundamental
                      </button>
                    </div>
                    <div className="level__btn__btn__main__inner">
                      <button
                        onClick={() => setDifficultyLevel("Advanced")}
                        className={` ${difficultyLevel === "Advanced" ? " active" : ""
                          }`}
                      >
                        Advanced
                      </button>
                    </div>
                  </div>
                </div>
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Session objective
                    </p>
                  </label>

                  {sessionObjectives?.map((x, i) => {
                    return (
                      <>
                        {sessionObjectives.length > 1 && i > 0 && (
                          <div className="cancelbtn-container">
                            <button
                              className="btn-cancel"
                              onClick={(e) => handleRemoveClick1(e, i)}
                            >
                              <p>Remove</p>
                              <img src={RemoveIcon} />
                            </button>
                          </div>
                        )}
                        <div
                          key={i}
                          className={"propose__objective__container"}
                        >
                          <input
                            type="text"
                            placeholder="Enter an objective"
                            className="reschedule__session__container__bottom__left__form__container__input input__element "
                            name="objecive"
                            value={sessionObjectives[i]?.objecive}
                            onChange={(e) =>
                              handleChangeForSessionObjective(e, i)
                            }
                          />
                        </div>
                      </>
                    );
                  })}
                  {sessionObjectives?.map((x, i) => {
                    return (
                      <div>
                        {sessionObjectives?.length >= 3 ? (
                          ""
                        ) : (
                          <div className="btn-box">
                            {sessionObjectives.length - 1 === i && (
                              <button
                                className="personalADD"
                                style={{ marginTop: "0px" }}
                                onClick={(e) => handleAddClick1(e)}
                              >
                                <img
                                  src={squareAddWhite}
                                  alt=""
                                  width="16px"
                                  height="17px"
                                />
                                <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                                  {i > 0
                                    ? "Add another objective"
                                    : "Add another objective"}
                                </p>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <button className="btn-green" onClick={SubmitBookSesssion}>
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* session confirm modal */}

      <ConfirmModal show={showConfirmModal} setShow={setShowConfirmModal}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Propose session</div>
          <div className="para__para__para ">
            Are you sure you want to go ahead and propose this session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={CreateSession}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={cancelConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>
      {loader && <ScreenLoader />}
    </>
  );
};

export default ProposeSession;
