// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainWrapper {
  padding: 40px 20px 40px;
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin: 0 auto;
  gap: 20px;
}
.mainWrapper h1 {
  color: #f5f6f7;
  font-family: AktivGrotesk-Medium;
  font-size: 44px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin-bottom: 20px !important;
}
.mainWrapper p {
  color: var(--gray-gray-200, #b9bdc7);
  font-family: AktivGrotesk-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 0;
}
.mainWrapper__item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.mainWrapper__item h2 {
  color: #f5f6f7;
  font-family: AktivGrotesk-Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.mainWrapper__top {
  display: flex;
  padding: 24px 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #2f3033;
}

.link__privacy {
  text-decoration: none;
  color: #3dd7a1;
}`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy/PrivacyPolicy.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,sBAAA;EAGA,cAAA;EACA,cAAA;EACA,SAAA;AADF;AAEE;EACE,cAAA;EACA,gCAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,8BAAA;AAAJ;AAEE;EACE,oCAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,gBAAA;AAAJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AACI;EACE,cAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AACN;;AAIA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;AADF;;AAIA;EACE,qBAAA;EACA,cAAA;AADF","sourcesContent":[".mainWrapper {\n  padding: 40px 20px 40px;\n  display: flex;\n  flex-direction: column;\n  //   align-items: center;\n  //   justify-content: center;\n  max-width: 60%;\n  margin: 0 auto;\n  gap: 20px;\n  h1 {\n    color: #f5f6f7;\n    font-family: AktivGrotesk-Medium;\n    font-size: 44px !important;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%; /* 19.2px */\n    margin-bottom: 20px !important;\n  }\n  p {\n    color: var(--gray-gray-200, #b9bdc7);\n    font-family: AktivGrotesk-Regular;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 140%; /* 19.6px */\n    margin-bottom: 0;\n  }\n  &__item {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    h2 {\n      color: #f5f6f7;\n      font-family: AktivGrotesk-Medium;\n      font-size: 32px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 120%; /* 19.2px */\n    }\n  }\n}\n\n.mainWrapper__top {\n  display: flex;\n  padding: 24px 20px;\n  align-items: center;\n  justify-content: center;\n  border-bottom: 1px solid #2f3033;\n}\n\n.link__privacy {\n  text-decoration: none;\n  color: #3dd7a1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
