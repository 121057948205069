// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter__button {
  padding: 10px 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: #2f3033;
  border-radius: 12px;
  transition: all 0.3s ease-in;
}
.filter__button__text {
  font-family: AktivGrotesk-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #ffffff;
}
.filter__button:hover {
  background-color: #3DD7A1;
}`, "",{"version":3,"sources":["webpack://./src/components/Custom/Buttons/FilterButton/FilterButton.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,yBAAA;EACA,mBAAA;EACA,4BAAA;AACF;AAAE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AACE;EACE,yBAAA;AACJ","sourcesContent":[".filter__button {\n  padding: 10px 12px;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  background-color: #2f3033;\n  border-radius: 12px;\n  transition: all 0.3s ease-in;\n  &__text {\n    font-family: AktivGrotesk-Medium;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n    color: #ffffff;\n    \n  }\n  &:hover{\n    background-color:#3DD7A1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
