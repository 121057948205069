import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import NavBar from "../Navbar/NavBar";
import { Form } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import PhoneInput from "react-phone-number-input";
import { Dropdown } from "react-bootstrap";
import EditIcon from "../../assets/edit.svg";
import TrashIcon from "../../assets/trash.svg";
//
import "./ProfileSettings.scss";
//
import minus from "../../assets/minus.svg";
import RemoveIcon from "../../assets/remove-icon.svg";
import CalendarEaxct from "../../assets/CalendarEaxct.svg";
import cvIcon from "../../assets/cvIcon.svg";

import squareAddWhite from "../../assets/squareAddWhite.svg";
import editIcon from "../../assets/editGreen.svg";
import moment from "moment";
import plusWhite from "../../assets/plusWhite.svg";
import Multiselect from "multiselect-react-dropdown";
import LeftArrow from "../../assets/arrow-left.svg";
import { useHistory } from "react-router-dom";
import PersonalInformation from "../PersonalInformation/PersonalInformation";
import { useRef } from "react";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
import { useSelector, useDispatch } from "react-redux";
import { viewMentor } from "../../service/redux/middleware/mentor";
import {
  addEducationProfile,
  addExperienceProfile,
  deleteStudentEducation,
  deleteStudentProfession,
  getUserProfile,
  updateUserEducation,
  updateUserProfession,
} from "../../service/redux/middleware/userProfile";

import {
  updatprofile,
  changeMail,
} from "../../service/redux/middleware/userProfile";
import countryList from "react-select-country-list";

import { ErrorToast, SuccessToast } from "../MyToaster";

import { ConfirmModal } from "../SessionModal/SessionModal";
import ScreenLoader from "../../components/Loader/ScreenLoader";

const ProfileSettings = () => {
  // locations
  const optionsCountries = useMemo(() => countryList().getData(), []);
  //
  const [indexwait, setindexwait] = useState(0);
  const [showText, setShowText] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [mentorData, setMentorData] = useState([]);
  const rightContentRef = useRef(null);
  const dispatch = useDispatch();

  // multi Slect Input Field code

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options] = useState([
    { name: "French", id: 1 },
    { name: "English", id: 2 },
    { name: "Urdu", id: 3 },
  ]);
  const [lang, setLang] = useState([]);

  //modal
  const [openModalForEdit, setOpenModalForEdit] = useState(false);

  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const onSelect = (selectedList, selectedItem) => {
    let pfData = { ...profileData };
    pfData.language = selectedList;
    setProfileData(pfData);
    setLang([...lang, selectedItem]);
  };
  const onRemove = (selectedList, removedItem) => {
    let pfData = { ...profileData };
    pfData.language = selectedList;
    setProfileData(pfData);
    setLang(selectedList);
  };
  const scrollToTitle = (title) => {
    if (rightContentRef.current) {
      const titleElement = rightContentRef.current.querySelector(
        `[data-title="${title}"]`
      );
      if (titleElement) {
        titleElement.scrollIntoView({ behavior: "smooth", block: "center" });
        // titleElement.scrollIntoView({ behavior: "smooth",block:"nearest" });
        // titleElement.scrollIntoView({ behavior: "smooth",block:"end" });
      }
    }
  };
  const handleItemClick = (title, index) => {
    setActiveItem(title);
    scrollToTitle(title);
    setindexwait(index);
  };
  const back = () => {
    history.push("/interviewdashboard");
  };
  const cancelAction = () => {
    history.push("/interviewdashboard");
  };
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    background: "",
    location: "",
    language: "",
  });
  const [userProfileData, setUserProfileData] = useState({
    email: "",
    phoneNo: "",
  });
  const [Currency, setCurrency] = useState({
    CurrencyValue: "",
  });
  //
  // set education details
  //
  const [educationDetails, setEducationDetails] = useState([]);
  const [singleEducation, setSingleEducation] = useState([]);
  const [isEducationDone, setIsEducationDone] = useState(false);
  const [isEducationCheck, setIsEducationCheck] = useState([]);
  const [openModalForEdit3, setOpenModalForEdit3] = useState(false);
  const [educationInfo, setEducationInfo] = useState([
    {
      institution: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    },
  ]);
  //
  const handleRemoveEducationClick = (e, index) => {
    e.preventDefault();
    const list = [...educationInfo];
    list.splice(index, 1);
    setEducationInfo(list);
    //
    const checkboxes = isEducationCheck;
    checkboxes.splice(index, 1);
    setIsEducationCheck(checkboxes);
  };
  const EditEducation = (elem) => {
    // e.preventDefault();
    setIsEducationCheck([elem?.currentlyStuding]);
    setSingleEducation({ ...elem });
    setEducationInfo([{ ...elem }]);
    setOpenModalForEdit3(true);
  };
  const educationHandler = (e, index, isDate = "") => {
    console.log('new one');
    if (isDate.length > 0) {
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][isDate] = e;
      setEducationInfo(updatedEducationInfo);
    } else {
      // const { name, value } = e.target;
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][e.target?.name] = e.target?.value;
      setEducationInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };
  const handleCheckboxChange = (index) => {
    const updatedEducationInfo = [...educationInfo];
    updatedEducationInfo[index].currentlyStuding =
      !updatedEducationInfo[index].currentlyStuding;
    // updatedEducationInfo[index].endDate = "";

    setEducationInfo(updatedEducationInfo);
    const updatedIsEducationCheck = [...isEducationCheck];
    updatedIsEducationCheck[index] = !updatedIsEducationCheck[index]; // Toggle the value
    setIsEducationCheck(updatedIsEducationCheck);
  };

  const handleEducationSubmit = () => {
    let allGood = true;
    educationInfo.map((item, index) => {
      if (item.degree && item.institution && item.startDate) {
        if (item.currentlyStuding) {
        } else {
          if (item.endDate) {
          } else {
            allGood = false;
            return;
          }
        }
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsEducationDone(true);
      // toggleVisibilityBank(true)
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
      setIsEducationDone(false);
    }
  };
  // handle change for save button
  const UpdateEducationAPIcall = async (event) => {
    event.preventDefault();
    const stDate = moment(educationInfo[0]?.startDate).toISOString();
    const edDate = moment(educationInfo[0]?.endDate).toISOString();
    const data = {
      userId: userData?._id,
      id: singleEducation._id,
      institution: educationInfo[0]?.institution,
      degree: educationInfo[0]?.degree,
      startDate: stDate,
      endDate: edDate,
      current: educationInfo[0]?.currentlyStuding,
      programe: educationInfo[0]?.programme,
      token: token,
    };
    setLoader(true);
    dispatch(updateUserEducation(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Education updated successfully.");
        setEducationDetails(resi?.payload?.data?.educationDetails);
        //   if (res?.payload?.status === 200) {
        //     // SuccessToast(res?.payload?.data?.message);
        //     // emptying modal
        //     setOpenModalForEdit3(false);
        //     setSingleEducation();
        //     setEducationInfo([]);
        //     setIsEducationCheck([]);
        //     //
        //     setUserDataNew(res?.payload?.data);
        //     const initialOption = options.find(
        //       (item) => item.name == res?.payload?.data?.data?.language
        //     );
        //     if (initialOption) {
        //       setLang([initialOption]);
        //       // setProfileData(initialOption);
        //     }
        //     setLoader(false);
        //     // setTemp(res?.payload?.data)
        //   } else {
        //     setLoader(false);
        //     // ErrorToast(res?.payload?.message);
        //   }
        // });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };
  //
  // education end
  //
  //

  // edit investment bank

  // Experience starts
  //
  const [experienceDetails, setExperienceDetails] = useState([]);
  const [singleExperience, setSingleExperience] = useState([]);
  const [isExperienceDone, setIsExperienceDone] = useState(false);
  const [isExperienceCheck, setIsExperienceCheck] = useState([]);
  const [openModalForEdit4, setOpenModalForEdit4] = useState(false);
  const [editSingleProExp, setEditSingleProExp] = useState([]);
  // const [cv, setCV] = useState('')
  const [experienceInfo, setExperienceInfo] = useState([
    {
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);
  const EditProfessionInfo = (elem) => {
    setIsExperienceCheck([elem?.currentlyEmployed]);
    setEditSingleProExp({ ...elem });
    setExperienceInfo([{ ...elem }]);
    setOpenModalForEdit4(true);
  };
  const experienceHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][isDate] = e;
      setExperienceInfo(updatedExperienceInfo);
    } else {
      // const { name, value } = e.target;
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][e.target?.name] = e.target?.value;
      setExperienceInfo(updatedExperienceInfo);
      // e.target.value = null;
    }
  };
  const handleCheckboxChangeExperience = (index) => {
    const updatedExperienceInfo = [...experienceInfo];
    updatedExperienceInfo[index].currentlyEmployed =
      !updatedExperienceInfo[index].currentlyEmployed;
    // updatedExperienceInfo[index].endDate = "";

    setExperienceInfo(updatedExperienceInfo);
    const updatedIsExperienceCheck = [...isExperienceCheck];
    updatedIsExperienceCheck[index] = !updatedIsExperienceCheck[index]; // Toggle the value
    setIsExperienceCheck(updatedIsExperienceCheck);
  };
  // handle change for save button
  const deleteStudentProfessionExperience=async(event)=>{
    setLoader(true)
    const data = {
      userId: userData?._id,
      id: event,
      token: token,
    };
    dispatch(deleteStudentProfession(data)).then(async (resi) => {

      if (resi?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Professional experience deleted successfully")
        setExperienceDetails(resi?.payload?.data?.professionalDetails);

      }

    })
  }
  const deleteStudentEducationExperience=async(event)=>{
    setLoader(true)
    const data = {
      userId: userData?._id,
      id: event,
      token: token,
    };
    dispatch(deleteStudentEducation(data)).then(async (resi) => {

      if (resi?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Education experience deleted successfully")
        setEducationDetails(resi?.payload?.data?.educationDetails);
        

      }

    })
  }
  const UpdateProfessionAPIcall = async (event) => {
    event.preventDefault();
    const stDate = moment(experienceInfo[0]?.startDate).toISOString();
    const edDate = moment(experienceInfo[0]?.endDate).toISOString();
    const data = {
      userId: userData?._id,
      id: editSingleProExp._id,
      position: experienceInfo[0]?.position,
      startDate: stDate,
      endDate: edDate,
      current: experienceInfo[0]?.currentlyEmployed,
      company: experienceInfo[0]?.company,
      token: token,
    };
    setLoader(true);
    dispatch(updateUserProfession(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Professional Experience updated successfully.");
        // professionalDetails
        setExperienceDetails(resi?.payload?.data?.professionalDetails);

        //   if (res?.payload?.status === 200) {
        //     // SuccessToast("Education updated ");
        //     // emptying modal
        //     setOpenModalForEdit4(false);
        //     setIsExperienceCheck([]);
        //     setEditSingleProExp();
        //     setExperienceInfo([]);
        //     //
        //     setUserDataNew(res?.payload?.data);
        //     const initialOption = options.find(
        //       (item) => item.name == res?.payload?.data?.data?.language
        //     );
        //     if (initialOption) {
        //       setLang([initialOption]);
        //       // setProfileData(initialOption);
        //     }
        //     setLoader(false);
        //     // setTemp(res?.payload?.data)
        //   } else {
        //     setLoader(false);
        //     // ErrorToast(res?.payload?.message);
        //   }
        // });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };
  //
  // Experience ends
  //
  // Add Another education
  //

  const [addAnotherEducationCheck, setAddAnotherEducationCheck] =
    useState(false);
  const [addPropdownProgramme, setAddDropdownProgramme] = useState("");
  const [addAnotherEducationInfo, setAddAnotherEducationInfo] = useState({
    institution: "",
    degree: "",
    programme: "",
    startDate: new Date(),
    endDate: new Date(),
    currentlyStuding: false,
  });
  const [isAddEducationCheck, setIsAddEducationCheck] = useState(false);
  const AddEducationHandler = (e, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = { ...addAnotherEducationInfo };
      updatedEducationInfo[isDate] = e;
      setAddAnotherEducationInfo(updatedEducationInfo);
    } else {
      // const { name, value } = e.target;
      const updatedEducationInfo = { ...addAnotherEducationInfo };
      updatedEducationInfo[e.target?.name] = e.target?.value;
      setAddAnotherEducationInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };
  const handleAddDropDownProgramme = (e) => {
    setAddDropdownProgramme(e);
    setAddAnotherEducationInfo({
      ...addAnotherEducationInfo,
      programme: e,
    });
  };
  const handleAddDropDownProgrammeForEdit = (e, index) => {
    setEducationInfo((prevEducationInfo) => {
      const updatedEducationInfo = [...prevEducationInfo];
      updatedEducationInfo[index] = {
        ...updatedEducationInfo[index],
        programme: e,
      };
      return updatedEducationInfo;
    });
  };
  const handleAddEducationCheckboxChange = () => {
    let toUpdate = { ...addAnotherEducationInfo };
    toUpdate.currentlyStuding = !toUpdate.currentlyStuding;
    setAddAnotherEducationInfo(toUpdate);
    setIsAddEducationCheck(!isAddEducationCheck);
  };
  const validateAddAnotherEducation = () => {
    if (
      addAnotherEducationInfo.institution !== "" &&
      addAnotherEducationInfo.degree !== "" &&
      addAnotherEducationInfo.programme !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleAddAnotherEducationSubmit = () => {
    const isValid = validateAddAnotherEducation();
    if (isValid) {
      const data = {
        userId: userData?._id,
        token: token,
        educationdetail: {
          institution: addAnotherEducationInfo.institution,
          degree: addAnotherEducationInfo.degree,
          programme: addAnotherEducationInfo.programme,
          startDate: moment(addAnotherEducationInfo.startDate).toISOString(),
          endDate: moment(addAnotherEducationInfo.endDate).toISOString(),
          currentlyStuding: addAnotherEducationInfo.currentlyStuding,
        },
      };
      setLoader(true);
      dispatch(addEducationProfile(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          SuccessToast("Education added successfully");
          setEducationDetails(resi?.payload?.data?.educationDetails);
          setLoader(false);

          setAddAnotherEducationCheck(false);
          setIsAddEducationCheck(false);
          setAddDropdownProgramme("");
          setAddAnotherEducationInfo({
            institution: "",
            degree: "",
            programme: "",
            startDate: new Date(),
            endDate: new Date(),
            currentlyStuding: false,
          });
          //   if (res?.payload?.status === 200) {
          //     // SuccessToast("Education Added ");
          //     // emptying modal
          //     setAddAnotherEducationCheck(false);
          //     setIsAddEducationCheck(false);
          //     setAddDropdownProgramme("");
          //     setAddAnotherEducationInfo({
          //       institution: "",
          //       degree: "",
          //       programme: "",
          //       startDate: new Date(),
          //       endDate: new Date(),
          //       currentlyStuding: false,
          //     });
          //     //
          //     setUserDataNew(res?.payload?.data);

          //     const initialOption = options.find(
          //       (item) => item.name == res?.payload?.data?.data?.language
          //     );
          //     if (initialOption) {
          //       setLang([initialOption]);
          //       // setProfileData(initialOption);
          //     }
          //     setLoader(false);
          //     // setTemp(res?.payload?.data)
          //   } else {
          //     setLoader(false);
          //     // ErrorToast(res?.payload?.message);
          //   }
          // });
        } else {
          ErrorToast(resi?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
  };

  //
  //
  // Add another Experience
  //
  //
  const [addAnotherExperienceCheck, setAddAnotherExperienceCheck] =
    useState(false);
  const [addAnotherExperienceInfo, setAddAnotherExperienceInfo] = useState({
    company: "",
    position: "",
    startDate: new Date(),
    endDate: new Date(),
    currentlyEmployed: false,
  });
  const [isAddExperienceCheck, setIsAddExperienceCheck] = useState(false);
  const AddExperienceHandler = (e, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = { ...addAnotherExperienceInfo };
      updatedEducationInfo[isDate] = e;
      setAddAnotherExperienceInfo(updatedEducationInfo);
    } else {
      const updatedEducationInfo = { ...addAnotherExperienceInfo };
      updatedEducationInfo[e.target?.name] = e.target?.value;
      setAddAnotherExperienceInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };
  const handleAddExperienceCheckboxChange = () => {
    let toUpdate = { ...addAnotherExperienceInfo };
    toUpdate.currentlyEmployed = !toUpdate.currentlyEmployed;
    setAddAnotherExperienceInfo(toUpdate);
    setIsAddExperienceCheck(!isAddEducationCheck);
  };
  const validateAddAnotherExperience = () => {
    if (
      addAnotherExperienceInfo.company !== "" &&
      addAnotherExperienceInfo.position !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleAddAnotherExperienceSubmit = () => {
    const isValid = validateAddAnotherExperience();
    if (isValid) {
      const data = {
        userId: userData?._id,
        token: token,
        professionalDetail: {
          company: addAnotherExperienceInfo.company,
          position: addAnotherExperienceInfo.position,
          startDate: moment(addAnotherExperienceInfo.startDate).toISOString(),
          endDate: moment(addAnotherExperienceInfo.endDate).toISOString(),
          currentlyEmployed: addAnotherExperienceInfo.currentlyEmployed,
        },
      };
      setLoader(true);
      dispatch(addExperienceProfile(data)).then(async (resi) => {
        if (resi?.payload?.status === 200) {
          SuccessToast("Professional Experience added successfully.");
          setExperienceDetails(resi?.payload?.data?.professionalDetails);
          setLoader(false);
          setAddAnotherExperienceCheck(false);
          setIsAddExperienceCheck(false);
          setAddAnotherExperienceInfo({
            company: "",
            position: "",
            startDate: new Date(),
            endDate: new Date(),
            currentlyEmployed: false,
          });
          //   if (res?.payload?.status === 200) {
          //     // emptying modal
          //     // setAddAnotherExperienceCheck()
          //     setAddAnotherExperienceCheck(false);
          //     setIsAddExperienceCheck(false);
          //     setAddAnotherExperienceInfo({
          //       company: "",
          //       position: "",
          //       startDate: new Date(),
          //       endDate: new Date(),
          //       currentlyEmployed: false,
          //     });
          //     //
          //     setUserDataNew(res?.payload?.data);

          //     const initialOption = options.find(
          //       (item) => item.name == res?.payload?.data?.data?.language
          //     );
          //     if (initialOption) {
          //       setLang([initialOption]);
          //       // setProfileData(initialOption);
          //     }
          //     setLoader(false);
          //     // setTemp(res?.payload?.data)
          //   } else {
          //     setLoader(false);
          //     // ErrorToast(res?.payload?.message);
          //   }
          // });
        } else {
          ErrorToast(resi?.payload?.message);
          setLoader(false);
        }
      });
      // SuccessToast("Truururur experience");
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
  };

  //
  //
  //

  //new haris work
  const [userDataNew, setUserDataNew] = useState([]);
  const [verified, setVerified] = useState(false);
  const [passwordVer, setPasswordVer] = useState();
  const [openModalForEdit1, setOpenModalForEdit1] = useState(false);

  useEffect(() => {
    setProfileData({
      firstName: userDataNew?.user?.firstName
        ? userDataNew?.user?.firstName
        : "",
      lastName: userDataNew?.user?.lastName ? userDataNew?.user?.lastName : "",
      background: userDataNew?.data?.background
        ? userDataNew?.data?.background
        : "",
      location: userDataNew?.data?.location ? userDataNew?.data?.location : "",
      language: userDataNew?.data?.language ? userDataNew?.data?.language : "",
    });
    //
    setEducationDetails(userDataNew?.data?.educationDetails);
    setExperienceDetails(userDataNew?.data?.professionalDetails);
    //
    setUserProfileData({
      email: userDataNew?.user?.email ? userDataNew?.user?.email : "",
      phoneNo: userDataNew?.user?.phoneNo ? userDataNew?.user?.phoneNo : "",
    });
    setCurrency({
      CurrencyValue: "Dollar",
    });
  }, [userDataNew]);
  useEffect(() => {
    setLoader(true);
    dispatch(getUserProfile(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setUserDataNew(res?.payload?.data);
        const initialOption = options.find(
          (item) => item.name == res?.payload?.data?.data?.language
        );
        if (initialOption) {
          setLang([initialOption]);
          // setProfileData(initialOption);
        }
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });
  }, []);

  const data = {
    userId: userData?._id,
    token: token,
  };
  //update calling

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data1 = {
      userId: userData?._id,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      location: profileData?.location,
      background: profileData?.background,
      phoneNo: userProfileData?.phoneNo,
      email: userProfileData?.email,
      language: profileData?.language,
      educationDetails: "",
      professionalDetails: "",
      token: token,
    };
    setLoader(true);
    dispatch(updatprofile(data1)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Profile updated successfully");
        dispatch(getUserProfile(data)).then((res) => {
          if (res?.payload?.status === 200) {
            // SuccessToast(resi?.payload?.data?.success);

            setUserDataNew(res?.payload?.data);
            setVerified(false);
            setLoader(false);
          } else {
            setLoader(false);
            // ErrorToast(res?.payload?.message);
          }
        });
      } else {
        ErrorToast(resi?.payload?.message);
        setLoader(false);
      }
    });
  };

  const handleChange = (e) => {
    profileData[e.target?.name] = e.target?.value;
    setProfileData({ ...profileData });
  };
  //
  //
  const handleSelectCountry = (selected) => {
    let toUpdate = { ...profileData };
    toUpdate.location = selected;
    setProfileData({ ...toUpdate });
  };
  // currency
  const [userCurrency, setUserCurrency] = useState("");
  const handleSelectCurrency = (selected) => {
    setUserCurrency(selected);
  };

  //
  //
  const handleChnageAccount = (e) => {
    if (verified) {
      userProfileData[e.target?.name] = e.target?.value;
      setUserProfileData({ ...userProfileData });
    }
  };

  const handleChnagePhone = (e) => {
    if (verified) {
      userProfileData.phoneNo = e;
      setUserProfileData({ ...userProfileData });
    }
  };

  const checkVerification = () => {
    const data = {
      userId: userData?._id,
      passwordVer: passwordVer,
    };

    setLoader(true);
    dispatch(changeMail(data)).then((res) => {
      if (res?.payload?.status === 200) {
        SuccessToast("Success");
        setVerified(true);
        setLoader(false);
        // setTemp(res?.payload?.data)
      } else {
        setLoader(false);

        ErrorToast(res?.payload?.message);
      }
    });
  };

  const handleChangeEmailReset = (e) => {
    setPasswordVer(e.target.value);
    // userProfileData[e.target.name] = e.target.value;
    // setUserProfileData({ ...userProfileData });
  };

  const handleChangeLocation = (e) => {
    const pD = profileData;
    pD[e.target.name] = e.target.value;
    setProfileData({ ...pD });
  };

  const ChangeInputValue = (e) => {
    e.preventDefault();
    if (!userDataNew?.user?.social) {
      setOpenModalForEdit(true);
    } else {
      ErrorToast("you signed up with social account");
    }
  };

  const ChangeInputValue1 = (e) => {
    e.preventDefault();
    setOpenModalForEdit1(true);
  };

  return (
    <div className="profilesetting__container container__custom">
      {loader && <ScreenLoader />}

      <NavBar mentorData={mentorData} />
      <div className="profsetting__main__cc">
        <div className="reschedule__session__container__top profsetting__main__cc__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={back}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back To Network
            </p>
          </div>
          <div className="reschedule__session__container__top__right btn__des">
            <button
              className="btn-green"
              // onClick={() => setShowConfirmModal(true)}
              onClick={handleSubmit}
            >
              Save
            </button>
            <button className="btn-grey" onClick={cancelAction}>
              Cancel
            </button>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="mobile__topnav">
          <ul className="settings__topnav">
            <li>
              <a
                onClick={() => {
                  handleItemClick("Personal Information", 0);
                }}
                className={
                  indexwait === 0 ? "list__item active" : "list__item "
                }
              >
                <span className="ms-2">Personal Information</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Account Management", 1);
                }}
                className={
                  indexwait === 1 ? "list__item active" : "list__item "
                }
              >
                <span className="ms-2">Account Management</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Payments", 2);
                }}
                className={
                  indexwait === 2 ? "list__item active" : "list__item "
                }
              >
                <div className="set-flex-for-value">
                  <span className="ms-2">Payments</span>
                </div>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleItemClick("Password & Security", 3);
                }}
                className={
                  indexwait === 3 ? "list__item active" : "list__item "
                }
              >
                <div className="set-flex-for-value">
                  <span className="ms-2">Password & Security</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/*  */}
        {/*  */}
        <div className="lr_padding">
          <div className="app-wrapper scroller__main">
            <div className="sidebar__column web-sidebar scroller__main__left">
              <div className="sidebar__wrapper ">
                <div className="sidebar__wrapper__inner">
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Personal Information", 0);
                        }}
                        className={
                          indexwait === 0 ? "list__item active" : "list__item "
                        }
                      >
                        <span className="ms-2">Personal Information</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Account Management", 1);
                        }}
                        className={
                          indexwait === 1 ? "list__item active" : "list__item "
                        }
                      >
                        <span className="ms-2">Account Management</span>
                      </a>
                    </li>
                    
                    <li>
                      <a
                        onClick={() => {
                          handleItemClick("Password & Security", 3);
                        }}
                        className={
                          indexwait === 3 ? "list__item active" : "list__item "
                        }
                      >
                        <div className="set-flex-for-value">
                          <span className="ms-2">Password & Security</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="scroller__main__right">
              <div className="content__column__1 ">
                {/* <PersonalInformation /> */}
                <div>
                  <div className="setting__container">
                    <div className="" ref={rightContentRef}>
                      <h2
                        className="setting__heading setting__heading__padding"
                        data-title={"Personal Information"}
                      >
                        Personal Information
                      </h2>
                      <div className="personalDiv">
                        <div className="name__div__main">
                          <Form.Group
                            className="loginfield__optional__margin "
                            controlId=""
                            style={{ width: "100%" }}
                          >
                            <Form.Label className="loginfield__Label ">
                              First name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="loginfield__type height__input padding__input"
                              placeholder="John"
                              name="firstName"
                              value={profileData?.firstName}
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                          <div
                            className="margin__lastname"
                            style={{ width: "100%" }}
                          >
                            <Form.Group
                              className="loginfield__optional__margin"
                              controlId=""
                            >
                              <Form.Label className="Languages  loginfield__Label  ">
                                Last name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="loginfield__type height__input padding__input"
                                placeholder="Deo"
                                name="lastName"
                                value={profileData?.lastName}
                                onChange={(e) => handleChange(e)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="DropDownLabel">
                          <h1>Location</h1>
                        </div>
                        <Dropdown onSelect={handleSelectCountry}>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="dropdownToSelect height__input select__icon"
                          >
                            <p className="forumm__button__text">
                              {profileData?.location
                                ? profileData?.location
                                : "Please select country"}
                            </p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                            {optionsCountries?.map((item, index) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={item.label}
                                className={`dropdownToSelect__item ${item.label === profileData?.location
                                  ? "dropdownToSelect__item__checked"
                                  : ""
                                  }`}
                              >
                                {item.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* location old */}
                        {/* <select
                          id="location"
                          value={profileData?.location}
                          onChange={(e) => handleChange(e)}
                          name="location"
                          className="DropDownMentor__select padding__input select__icon"
                        >
                          <option
                            className="DropDownMentor__select__item"
                            value={"All"}
                          >
                            {profileData?.location}
                          </option>
                          {optionsCountries?.map((item, index) => (
                            <option
                              className="DropDownMentor__select__item"
                              value={item.label}
                              key={index}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select> */}
                        {/* location old  */}
                        <Form.Group
                          className="  loginfield__optional__margin"
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label">
                            Brief background
                          </Form.Label>
                          <textarea
                            type="text"
                            placeholder="Tell candidates about yourself and your background. Explain them why you are the right mentor for them"
                            className="reschedule__session__container__bottom__left__form__container__input input__element font__DM padding__input height__input__area"
                            style={{ marginTop: "16px" }}
                            name="background"
                            value={profileData?.background}
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Group>
                        {/* <Form.Group className=" loginfield " controlId="">
                          <Form.Label className="loginfield__Label mb-3">
                            Other Languages
                          </Form.Label>
                          <Multiselect
                            options={options}
                            selectedValues={lang}
                            onSelect={onSelect}

                            onRemove={onRemove}
                            displayValue="name"
                          >
                            {lang?.name}
                          </Multiselect>
                        </Form.Group> */}
                      </div>
                      {/* <div className='personalsetting__line'></div> */}
                      {/* Education */}
                      <div className="EducationDiv btn__setting__mtop28">
                        <>
                          {/* <div
                          className={true > 0 ? "sdfdsfdsf" : " sdfdsfdsfdfdf"}
                        >
                          <Form.Group className=" loginfield ">
                            <Form.Label className="loginfield__Label">
                              Institution
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="loginfield__type"
                              placeholder="Crowder University "
                              // value={multiplemages[i]?.institution}
                              name="institution"
                              // onChange={(e) => handleChange1(e, i)}
                            />
                          </Form.Group>
                          <Form.Group className=" loginfield ">
                            <Form.Label className="loginfield__Label">
                              Field of Study
                            </Form.Label>
                            <Form.Control
                              type=" password"
                              className="loginfield__type"
                              placeholder="Accounting"
                              // value={multiplemages[i]?.degree}
                              name="degree"
                              // onChange={(e) => handleChange1(e, i)}
                            />
                          </Form.Group>
                          <div className=" datepickerRow ">
                            <DateTimePicker
className="schedules__guides__contaiener__calendar"
                              className="custom-datepicker-input "
                              name="startDate"
                              // selected={multiplemages[i]?.startDate}
                              placeholder="Select expiration date..."
                              // onChange={(e) =>
                              //   handleChange1(e, i, "startDate")
                              // }
                              //  minDate={moment()?.toDate()}
                            />
                            <DateTimePicker
className="schedules__guides__contaiener__calendar"
                              className="custom-datepicker-input ms-2 "
                              // selected={multiplemages[i]?.endDate}
                              name="endDate"
                              placeholder="Select expiration date..."
                              // onChange={(e) =>
                              //   handleChange1(e, i, "endDate")
                              // }
                              // disabled={multiplemages[i]?.currentlyStuding}
                              //  minDate={moment()?.toDate()}
                            />
                          </div>
                          <div className="personalCheckbox ">
                            <Form.Check
                              aria-label="option 1"
                              // checked={isChecked[i]}
                              // onChange={() => handleCheckboxChange(i)}
                            />
                            <p>I am still working at this company</p>
                          </div>
                        </div> */}
                        </>

                        {educationDetails?.map((elem, index) => {
                          return (
                            <div className=" tab__info__setting ">
                              <div className="mentorEducationInfo__Block">
                                <div className="mentorEducationInfo__edu">
                                  {/* <h1>{elem?.programme} {elem?.degree}{""},{""}{elem?.institution}</h1> */}
                                  {/* Show on mobile screens */}
                                  <h1 className="d-md-none">
                                    {elem?.degree}
                                    {" , "}
                                    {elem?.institution}
                                  </h1>

                                  {/* Show on medium screens and above */}
                                  <h1 className="d-none d-md-block">
                                    {elem?.programme} in {elem?.degree}
                                    {" , "}
                                    {elem?.institution}
                                  </h1>
                                  <h2>
                                    {moment(elem?.startDate).format(
                                      "MMMM, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(elem?.endDate).format("MMMM, YYYY")}{" "}
                                    . {mentorData?.data?.location}
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="mentorEducationInfo__Edit"
                               
                              >
                                <div  onClick={() => EditEducation(elem)}>
                                    <img
                                      // onClick={() => getSingleData(item)}
                                      src={EditIcon}
                                      alt="edit icon"
                                      style={{ cursor: "pointer" }}
                                      className="editschedules__editcard__container__inner__image"
                                    />
                                  </div>
                                  {
                                    index !==0 &&
                                    <img
                                      onClick={() =>
                                        deleteStudentEducationExperience(elem?._id)
                                      }
                                      src={TrashIcon}
                                      style={{ cursor: "pointer" }}
                                      alt="trash icon"
                                      className="editschedules__editcard__container__inner__image"
                                    />
                                  }
                                 
                              </div>
                            </div>
                          );
                        })}
                        <div className="btn-box">
                          <button
                            className="personalADD btn__setting__mbottom btn__setting__mtop padding__addanother"
                            onClick={() => setAddAnotherEducationCheck(true)}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                              Add education
                            </p>
                          </button>
                        </div>
                      </div>
                      {/* <div className='personalsetting__line'></div> */}
                      {/* Experience */}
                      {experienceDetails?.map((elem, index) => {
                        return (
                          <div className=" tab__info__setting ">
                            <div className="mentorEducationInfo__Block">
                              <div className="mentorEducationInfo__edu">
                                <h1>
                                  {elem?.position}
                                  {" , "}
                                  {elem?.company}
                                </h1>
                                <h2>
                                  {" "}
                                  {moment(elem?.startDate).format(
                                    "MMMM, YYYY"
                                  )}{" "}
                                  - {moment(elem?.endDate).format("MMMM, YYYY")}{" "}
                                </h2>
                              </div>
                            </div>
                            <div
                              className="mentorEducationInfo__Edit"
                              
                            >
                               <div  onClick={() => EditProfessionInfo(elem)}>
                                    <img
                                      // onClick={() => getSingleData(item)}
                                      src={EditIcon}
                                      alt="edit icon"
                                      style={{ cursor: "pointer" }}
                                      className="editschedules__editcard__container__inner__image"
                                    />
                                  </div>
                                  {
                                    index !==0 &&
                                    <img
                                      onClick={() =>
                                        deleteStudentProfessionExperience(elem?._id)
                                      }
                                      src={TrashIcon}
                                      style={{ cursor: "pointer" }}
                                      alt="trash icon"
                                      className="editschedules__editcard__container__inner__image"
                                    />
                                  }
                                 
                            </div>
                          </div>
                        );
                      })}
                      {/*  */}
                      <div className="btn-box">
                        <button
                          className="personalADD  btn__setting__mtop padding__addanother"
                          onClick={() => setAddAnotherExperienceCheck(true)}
                        >
                          <img src={squareAddWhite} alt="" />
                          <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                            Add professional experience
                          </p>
                        </button>
                      </div>
                      <div className="account__management__container">
                        <h2
                          className="setting__heading"
                          data-title={"Account Management"}
                        >
                          Account Management
                        </h2>
                        <Form.Group
                          className="loginfield__optional__margin"
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Email
                          </Form.Label>
                          <div className="change__email__main ">
                            <Form.Control
                              className="loginfield__type1 height__input padding__input"
                              placeholder="johndoe@fasttrack.com"
                              type="email"
                              value={userProfileData?.email}
                              name="email"
                              onChange={(e) => handleChnageAccount(e)}
                            />
                            <button
                              // onClick={(e) => ChangeInputValue(e)}
                              onClick={(e) => ChangeInputValue(e)}
                            >
                              Change
                            </button>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="loginfield__optional__margin"
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Phone number
                          </Form.Label>
                          <div className="change__email__main">
                            <PhoneInput
                              className="loginfield__type1 height__input padding__input  phone__input__m0"
                              placeholder="please enter the number"
                              //defaultCountry="RU"
                              name="phoneNo"
                              disabled={!verified}
                              value={userProfileData?.phoneNo}
                              onChange={(e) => handleChnagePhone(e)}
                            />
                            <button
                              // onClick={(e) => ChangeInputValue1(e)}
                              onClick={(e) => ChangeInputValue(e)}
                            >
                              Change
                            </button>
                          </div>
                        </Form.Group>
                      </div>
                      {/* <div className="setting__payment__container">
                        <h2
                          className="setting__heading"
                          data-title={"Payments"}
                        >
                          Payments
                        </h2>
                        <div className="DropDownLabel">
                          <h1>Change Currency</h1>
                        </div>
                        <Dropdown onSelect={handleSelectCurrency}>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="dropdownToSelect height__input select__icon"
                          >
                            <p className="forumm__button__text">
                              Please select currency
                            </p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                            <Dropdown.Item
                              eventKey={"usd"}
                              className="dropdownToSelect__item"
                            >
                              US Dollar - $
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="pkr"
                              className="dropdownToSelect__item"
                            >
                              PKR
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey={"euro"}
                              className="dropdownToSelect__item"
                            >
                              Euro
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        
                      </div> */}
                      <div className="setting__password__security">
                        <h2
                          className="setting__heading"
                          data-title={"Password & Security"}
                        >
                          Password & Security
                        </h2>
                        <div className="DropDownLabel">
                          {/* <Link
                            to="/email"
                            className="change__password text-capitalize"
                          >
                            Click here to reset your password
                          </Link> */}

                          {userDataNew?.user?.social ? (
                            <a
                              className="change__password text-capitalize"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                ErrorToast(
                                  " Can not change as login through socail "
                                )
                              }
                            >
                              Click here to reset your password
                            </a>
                          ) : (
                            <Link
                              to="/email"
                              className="change__password text-capitalize"
                            >
                              Click here to reset your password
                            </Link>
                          )}
                        </div>
                      </div>
                      {/* adding buttons */}
                      <div className="btns__desktop">
                        <button
                          className="btn-green"
                          // onClick={() => setShowConfirmModal(true)}
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                        <button className="btn-grey" onClick={cancelAction}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsletterFooter />

      <ConfirmModal show={openModalForEdit} setShow={setOpenModalForEdit}>
        <div className="networkdb__payment__modal__top">
          <h2>Please Enter password to verify</h2>
          <Form.Group className="  " controlId="">
            <Form.Label className="loginfield__Label ">Password</Form.Label>
            <Form.Control
              className="loginfield__type height__input"
              placeholder="Enter password"
              type="password"
              // value={loginData.loginEmail}
              name="resetPassword"
              onChange={(e) => handleChangeEmailReset(e)}
            />
          </Form.Group>
        </div>
        <div className="networkdb__payment__modal__bottom">
          <button
            onClick={() => {
              setOpenModalForEdit(false);
              checkVerification();
            }}
            className="btn-green"
          >
            Update
          </button>
        </div>
      </ConfirmModal>

      {/* edit Education Modal */}

      <ConfirmModal show={openModalForEdit3} setShow={setOpenModalForEdit3}>
        <div
          className="networkdb__payment__modal__top"
          style={{ border: "0px" }}
        >
          <div className="EducationDiv">
            {educationInfo?.map((item, i) => {
              return (
                <div key={i}>
                  <Form.Group className=" " controlId="">
                    <Form.Label className="loginfield__Label">
                      Program
                    </Form.Label>
                    <Dropdown onSelect={(e) => handleAddDropDownProgrammeForEdit(e, i)}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="height__input dropdownToSelect select__icon"
                      >
                        <p className="forumm__button__text">
                          {educationInfo[i]?.programme
                            ? educationInfo[i]?.programme
                            : "Please select degree"}
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                        <Dropdown.Item
                          eventKey={"MBA"}
                          className="dropdownToSelect__item"
                        >
                          MBA
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={"Bachelors"}
                          className="dropdownToSelect__item"
                        >
                          Bachelors
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={"Masters"}
                          className="dropdownToSelect__item  "
                        >
                          Masters
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Control
                      type="text"
                      className="loginfield__type height__input"
                      placeholder="Crowder University"
                      value={educationInfo[i]?.programme}
                      name="programme"
                      onChange={(e) => educationHandler(e, i)}
                    /> */}
                  </Form.Group>
                  <Form.Group
                    className=" loginfield__optional__margin "
                    controlId=""
                  >
                    <Form.Label className="loginfield__Label">
                      Institution
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="loginfield__type height__input"
                      placeholder="Crowder University"
                      value={educationInfo[i]?.institution}
                      name="institution"
                      onChange={(e) => educationHandler(e, i)}
                    />
                  </Form.Group>

                  <Form.Group
                    className=" loginfield__optional__margin "
                    controlId=""
                  >
                    <Form.Label className="loginfield__Label">
                      Field of study
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="loginfield__type height__input"
                      placeholder="Accounting"
                      value={educationInfo[i]?.degree}
                      name="degree"
                      onChange={(e) => educationHandler(e, i)}
                    />
                  </Form.Group>

                  <div className=" datepickerRow ">
                    <div className="datepickerRow__inner">
                      <p className="datepickerRow__inner__text">Start Date</p>
                      <DateTimePicker
                        format="MM-dd-yyyy"
                        disableClock={true}
                        className="height__input mt__cl__16 schedules__guides__contaiener__calendar"
                        name="startDate"
                        selected={educationInfo[i]?.startDate}
                        value={educationInfo[i]?.startDate}
                        placeholder="Select expiration date..."
                        calendarIcon={
                          <img
                            src={CalendarEaxct}
                            alt="Calendar Icon"
                            style={{ width: "20px", height: "20px" }} // Set the size as needed
                          />
                        }
                        onChange={(e) => educationHandler(e, i, "startDate")}
                      //  minDate={moment()?.toDate()}
                      />
                    </div>
                    <div className="datepickerRow__inner">
                      <p className="datepickerRow__inner__text">End Date</p>
                      <DateTimePicker
                        format="MM-dd-yyyy"
                        disableClock={true}
                        className="height__input mt__cl__16 schedules__guides__contaiener__calendar"
                        selected={educationInfo[i]?.endDate}
                        value={educationInfo[i]?.endDate}
                        // name="endDate"
                        placeholder="Select expiration date..."
                        calendarIcon={
                          <img
                            src={CalendarEaxct}
                            alt="Calendar Icon"
                            style={{ width: "20px", height: "20px" }} // Set the size as needed
                          />
                        }
                        onChange={(e) => educationHandler(e, i, "endDate")}
                        disabled={educationInfo[i]?.currentlyStuding}
                      //  minDate={moment()?.toDate()}
                      />
                    </div>
                  </div>
                  <label
                    className="personalCheckbox "
                    id="editeducationintternsids"
                  >
                    <Form.Check
                      id="editeducationintternsids"
                      name="editeducationintternsids"
                      aria-label="option 1"
                      checked={isEducationCheck[i]}
                      onChange={() => handleCheckboxChange(i)}
                    />
                    <p>I am still attending this institution</p>
                  </label>
                  <div style={{ marginTop: "20px" }}>
                    <div className="cancelbtn-container">
                      {educationInfo.length > 1 && i > 0 && (
                        <button
                          className="btn-cancel"
                          onClick={(e) => handleRemoveEducationClick(e, i)}
                        >
                          <p>Remove</p>
                          <img src={RemoveIcon} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              className="login__green__button mt__0__tab"
              // onClick={handleEducationSubmit}
              onClick={(e) => {
                setOpenModalForEdit3(false);
                handleEducationSubmit();
                UpdateEducationAPIcall(e);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </ConfirmModal>

      {/* Edit experience details */}
      <ConfirmModal show={openModalForEdit4} setShow={setOpenModalForEdit4}>
        <div
          className="networkdb__payment__modal__top"
          style={{ border: "0px" }}
        >
          <div className="ProfessionalExpDiv">
            {experienceInfo?.map((item, i) => {
              return (
                <div key={i}>
                  <Form.Group className="  " controlId="">
                    <Form.Label className="loginfield__Label">
                      Company
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="height__input loginfield__type"
                      placeholder="Facebook"
                      value={experienceInfo[i]?.company}
                      name="company"
                      onChange={(e) => experienceHandler(e, i)}
                    />
                  </Form.Group>
                  <Form.Group
                    className=" loginfield__optional__margin "
                    controlId=""
                  >
                    <Form.Label className="loginfield__Label">
                      Position
                    </Form.Label>
                    <Form.Control
                      type=" password"
                      className="height__input loginfield__type"
                      placeholder="Chief Accountant"
                      value={experienceInfo[i]?.position}
                      name="position"
                      onChange={(e) => experienceHandler(e, i)}
                    />
                  </Form.Group>

                  <div className=" datepickerRow ">
                    <div className="datepickerRow__inner">
                      <p className="datepickerRow__inner__text">Start Date</p>
                      <DateTimePicker
                        format="yyyy-MM-dd"
                        disableClock={true}
                        className="height__input schedules__guides__contaiener__calendar mt__cl__16"
                        name="startDate"
                        selected={experienceInfo[i]?.startDate}
                        value={experienceInfo[i]?.startDate}
                        placeholder="Select expiration date..."
                        calendarIcon={
                          <img
                            src={CalendarEaxct}
                            alt="Calendar Icon"
                            style={{ width: "20px", height: "20px" }} // Set the size as needed
                          />
                        }
                        onChange={(e) => experienceHandler(e, i, "startDate")}
                      //  minDate={moment()?.toDate()}
                      />
                    </div>
                    <div className="datepickerRow__inner">
                      <p className="datepickerRow__inner__text">End Date</p>
                      <DateTimePicker
                        format="yyyy-MM-dd"
                        disableClock={true}
                        className="height__input schedules__guides__contaiener__calendar mt__cl__16"
                        selected={experienceInfo[i]?.endDate}
                        value={experienceInfo[i]?.endDate}
                        name="endDate"
                        placeholder="Select expiration date..."
                        calendarIcon={
                          <img
                            src={CalendarEaxct}
                            alt="Calendar Icon"
                            style={{ width: "20px", height: "20px" }} // Set the size as needed
                          />
                        }
                        onChange={(e) => experienceHandler(e, i, "endDate")}
                        disabled={experienceInfo[i]?.currentlyEmployed}
                      //  minDate={moment()?.toDate()}
                      />
                    </div>
                  </div>
                  <div className="personalCheckbox ">
                    <Form.Check
                      aria-label="check experience"
                      checked={isExperienceCheck[i]}
                      onChange={() => handleCheckboxChangeExperience(i)}
                    />
                    <p>I am still working at this company</p>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div className="cancelbtn-container">
                      {/* {experienceInfo.length > 1 && i > 0 && (
                        <button
                          className='btn-cancel'
                          onClick={(e) => handleRemoveExperienceClick(e, i)}
                        >
                          <p>Remove</p>
                          <img src={RemoveIcon} />
                        </button>
                      )} */}
                    </div>
                  </div>
                </div>
              );
            })}
            <button
              className="login__green__button mt__0__tab"
              // onClick={handleExperienceSubmit}
              onClick={(e) => {
                setOpenModalForEdit4(false);
                // handleSubmit(e);
                UpdateProfessionAPIcall(e);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </ConfirmModal>

      {/* Add another Education */}
      <ConfirmModal
        show={addAnotherEducationCheck}
        setShow={setAddAnotherEducationCheck}
      >
        <div
          className="networkdb__payment__modal__top"
          style={{ border: "0px" }}
        >
          <div className="EducationDiv">
            <div>
              <Form.Group className="  ">
                <Form.Label className="loginfield__Label" htmlFor={"programme"}>
                  Program
                </Form.Label>
                <Dropdown onSelect={handleAddDropDownProgramme}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="height__input dropdownToSelect select__icon"
                  >
                    <p className="forumm__button__text">
                      {addPropdownProgramme
                        ? addPropdownProgramme
                        : "Please select degree"}
                    </p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                    <Dropdown.Item
                      eventKey={"MBA"}
                      className="dropdownToSelect__item"
                    >
                      MBA
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={"Bachelors"}
                      className="dropdownToSelect__item"
                    >
                      Bachelors
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={"Masters"}
                      className="dropdownToSelect__item  "
                    >
                      Masters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* Old select for programme */}
                {/* <select
                  className="DropDownMentor__select"
                  name="programme"
                  id="programme"
                  value={addPropdownProgramme}
                  onChange={(e) => handleAddDropDownProgramme(e)}
                >
                  <option
                    className="DropDownMentor__select__item"
                    value={"All"}
                  >
                    Please select a programme
                  </option>
                  <option className="DropdownMain__menu__item" value="MBA">
                    MBA
                  </option>
                  <option
                    className="DropDownMentor__select__item"
                    value="Bachelors"
                  >
                    Bachelors
                  </option>
                  <option
                    className="DropDownMentor__select__item"
                    value="Masters"
                  >
                    Masters
                  </option>
                </select> */}
                {/* Old select for programme */}
              </Form.Group>
              <Form.Group
                className=" loginfield__optional__margin "
                controlId=""
              >
                <Form.Label className="loginfield__Label">
                  Institution
                </Form.Label>
                <Form.Control
                  type="text"
                  className="height__input loginfield__type"
                  placeholder="Crowder University"
                  value={addAnotherEducationInfo.institution}
                  name="institution"
                  onChange={(e) => AddEducationHandler(e)}
                />
              </Form.Group>

              <Form.Group
                className=" loginfield__optional__margin "
                controlId=""
              >
                <Form.Label className="loginfield__Label">
                  Field of study
                </Form.Label>
                <Form.Control
                  type="text"
                  className="height__input loginfield__type"
                  placeholder="Accounting"
                  value={addAnotherEducationInfo.degree}
                  name="degree"
                  onChange={(e) => AddEducationHandler(e)}
                />
              </Form.Group>

              <div className=" datepickerRow ">
                <div className="datepickerRow__inner">
                  <p className="datepickerRow__inner__text">Start Date</p>
                  <DateTimePicker
                    format="MM-dd-yyyy"
                    disableClock={true}
                    className="height__input schedules__guides__contaiener__calendar mt__cl__16"
                    name="startDate"
                    selected={addAnotherEducationInfo.startDate}
                    value={addAnotherEducationInfo.startDate}
                    placeholder="Select expiration date..."
                    calendarIcon={
                      <img
                        src={CalendarEaxct}
                        alt="Calendar Icon"
                        style={{ width: "20px", height: "20px" }} // Set the size as needed
                      />
                    }
                    onChange={(e) => AddEducationHandler(e, "startDate")}
                  //  minDate={moment()?.toDate()}
                  />
                </div>
                <div className="datepickerRow__inner">
                  <p className="datepickerRow__inner__text">End Date</p>
                  <DateTimePicker
                    format="MM-dd-yyyy"
                    disableClock={true}
                    className="height__input mt__cl__16 schedules__guides__contaiener__calendar"
                    selected={addAnotherEducationInfo.endDate}
                    value={addAnotherEducationInfo.endDate}
                    // name="endDate"
                    placeholder="Select expiration date..."
                    calendarIcon={
                      <img
                        src={CalendarEaxct}
                        alt="Calendar Icon"
                        style={{ width: "20px", height: "20px" }} // Set the size as needed
                      />
                    }
                    onChange={(e) => AddEducationHandler(e, "endDate")}
                    disabled={addAnotherEducationInfo.currentlyStuding}
                  //  minDate={moment()?.toDate()}
                  />
                </div>
              </div>
              <label
                htmlFor="addprofileandothereducations"
                className="personalCheckbox "
              >
                <Form.Check
                  id="addprofileandothereducations"
                  name="addprofileandothereducations"
                  aria-label="option 1"
                  checked={isAddEducationCheck}
                  onChange={() => handleAddEducationCheckboxChange()}
                />
                <p>I am still attending this institution</p>
              </label>
            </div>
            <button
              className="login__green__button "
              style={{ marginTop: "20px" }}
              onClick={handleAddAnotherEducationSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </ConfirmModal>

      {/* Add another Experience */}
      <ConfirmModal
        show={addAnotherExperienceCheck}
        setShow={setAddAnotherExperienceCheck}
      >
        <div
          className="networkdb__payment__modal__top"
          style={{ border: "0px" }}
        >
          <div className="EducationDiv">
            <Form.Group className="" controlId="">
              <Form.Label className="loginfield__Label">Company</Form.Label>
              <Form.Control
                type="text"
                className="height__input loginfield__type"
                placeholder="Facebook"
                value={addAnotherExperienceInfo.company}
                name="company"
                onChange={(e) => AddExperienceHandler(e)}
              />
            </Form.Group>
            <Form.Group className=" loginfield__optional__margin " controlId="">
              <Form.Label className="loginfield__Label">Position</Form.Label>
              <Form.Control
                type="text"
                className="height__input loginfield__type"
                placeholder="Chief Accountant"
                value={addAnotherExperienceInfo?.position}
                name="position"
                onChange={(e) => AddExperienceHandler(e)}
              />
            </Form.Group>
            <div className=" datepickerRow ">
              <div className="datepickerRow__inner">
                <p className="datepickerRow__inner__text">Start Date</p>
                <DateTimePicker
                  format="yyyy-MM-dd"
                  disableClock={true}
                  className="mt__cl__16 height__input schedules__guides__contaiener__calendar"
                  name="startDate"
                  selected={addAnotherExperienceInfo?.startDate}
                  value={addAnotherExperienceInfo?.startDate}
                  placeholder="Select expiration date..."
                  calendarIcon={
                    <img
                      src={CalendarEaxct}
                      alt="Calendar Icon"
                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                    />
                  }
                  onChange={(e) => AddExperienceHandler(e, "startDate")}
                //  minDate={moment()?.toDate()}
                />
              </div>
              <div className="datepickerRow__inner">
                <p className="datepickerRow__inner__text">End Date</p>
                <DateTimePicker
                  format="yyyy-MM-dd"
                  disableClock={true}
                  className="mt__cl__16 height__input schedules__guides__contaiener__calendar"
                  selected={addAnotherExperienceInfo?.endDate}
                  value={addAnotherExperienceInfo?.endDate}
                  name="endDate"
                  placeholder="Select expiration date..."
                  calendarIcon={
                    <img
                      src={CalendarEaxct}
                      alt="Calendar Icon"
                      style={{ width: "20px", height: "20px" }} // Set the size as needed
                    />
                  }
                  onChange={(e) => AddExperienceHandler(e, "endDate")}
                  disabled={addAnotherExperienceInfo.currentlyEmployed}
                //  minDate={moment()?.toDate()}
                />
              </div>
            </div>
            <label
              className="personalCheckbox "
              htmlFor="addexperieceinsettingslabvel"
            >
              <Form.Check
                id="addexperieceinsettingslabvel"
                name="addexperieceinsettingslabvel"
                aria-label="check experience"
                checked={isAddExperienceCheck}
                onChange={() => handleAddExperienceCheckboxChange()}
              />
              <p>I am still working at this company</p>
            </label>
            <button
              className="login__green__button "
              onClick={handleAddAnotherExperienceSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </ConfirmModal>

      {/* <ConfirmModal show={openModalForEdit1} setShow={setOpenModalForEdit1}>
        <div className="networkdb__payment__modal__top">
          <h2>Please Edit Your Number</h2>
          <Form.Group className=" " controlId="">
            <Form.Label className="loginfield__Label ">Phone Number</Form.Label>
            <PhoneInput
              className="loginfield__type"
              placeholder="please enter the number"
              //defaultCountry="RU"
              // value={loginData.phoneNo}
              // onChange={(e) => setPhoneNumber(e)}
              onChange={(e) => handleChnagePhone(e)}
            />
          </Form.Group>
        </div>
        <div className="networkdb__payment__modal__bottom">
          <button
            onClick={() => 
              {
                checkVerification()
                
                setOpenModalForEdit1(false)
              }
              }
            className="btn-green"
          >
            Update
          </button>
        </div>
      </ConfirmModal> */}
    </div>
  );
};

export default ProfileSettings;
