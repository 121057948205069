import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Dropdown, Form } from "react-bootstrap";
//
import LeftArrow from "../../../../assets/arrow-left.svg";
import ClockIcon from "../../../../assets/clock.svg";
import AddSquareIcon from "../../../../assets/add-square.svg";
//
import moment from "moment-timezone";
import "moment-timezone/data/meta/latest.json";
import "./Adddate.scss";
//
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast, SuccessToast } from "../../../MyToaster";
import { addDateAvailbility } from "../../../../service/redux/middleware/mentor";

//new
import DateTimePicker from "react-datetime-picker";
import ScreenLoader from "../../../Loader/ScreenLoader";
import { useHistory } from "react-router-dom";
import CalendarIcon from "../../../../assets/calendar.svg";
// for timepicker
import squareAddWhite from "../../../../assets/squareAddWhite.svg";
import difficultyLevelIcon from "../../../../assets/levelgray.svg";
import { ConfirmModal } from "../../../SessionModal/SessionModal";
import { Calendar } from "primereact/calendar";
import Multiselect from "multiselect-react-dropdown";
import { viewMentor } from "../../../../service/redux/middleware/mentor";
import downArrow from "../../../../assets/downIcon.jpg";
import RemoveIcon from "../../../../assets/remove-icon.svg";
import CalendarEaxct from "../../../../assets/CalendarEaxct.svg";

const timeHour = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
];
const timeHalfHour = [
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  // "23:00",
  // "23:30",
  // "23:59",
];

const AddDate = ({ setShowAddDate, setMentorData }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [calenderIndex, setCalenderIndex] = useState();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [timeZoneValue, setTimeZoneValue] = useState("");
  const searchRef = useRef(null);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  function getTimeZones() {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset() / 60;
      const offsetString = offset >= 0 ? `+${offset}` : offset;
      const timeZoneName = moment.tz(zone).zoneAbbr();

      return {
        value: zone,
        label: `${zone}, ${timeZoneName} (GMT ${offsetString})`,
      };
    });
  }
  const timeZones = getTimeZones();
  const [timeZoneArray, setTimeZoneArray] = useState(getTimeZones());
  const [openDateCalender, setOenDateCalender] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options] = useState([
    { name: "General Guidance", id: 1 },
    { name: "CV Review", id: 2 },
    { name: "Cover Letter Review", id: 3 },
    { name: "Interviews Profile", id: 4 },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event here
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event here
  };

  //
  const cancelConfirmation = () => {
    setShowConfirmModal(false);
  };
  //
  const data1 = {
    userId: userData?._id,
    token: token,
  };

  const AddNewdate = () => {
    const newArray = timeSlot.map((obj) => {
      const { dateSend, originTime, ...newObj } = obj;
      return newObj;
    });
    const names = selectedOptions?.map((option) => option.name);
    const data = {
      // selectSession: names,
      creatorId: `${userData._id}`,
      timeSlot: newArray,
      token: token,
    };
    setLoader(true);
    dispatch(addDateAvailbility(data)).then((res1) => {
      setLoader(false);
      if (res1?.payload?.status === 200) {
        setLoader(false);
        SuccessToast(res1?.payload?.data?.message);
        dispatch(viewMentor(data1)).then((res) => {
          if (res?.payload?.status === 200) {
            setMentorData(res?.payload?.data);
            setShowConfirmModal(false);
            setLoader(false);
            setShowAddDate(false);
          } else {
            setLoader(false);
            setShowConfirmModal(false);
            setShowAddDate(false);
          }
        });
      } else {
        setLoader(false);
        setShowConfirmModal(false);
        ErrorToast(res1?.payload?.message);
        // ClearAll();
      }
    });
  };

  const SubmitBookSesssion = () => {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setShowConfirmModal(true);
  };
  //
  //
  const [newOne, setNewOne] = useState("");
  const [hourStartTime, setHourStartTime] = useState();
  const [timeZone, setTimeZone] = useState();
  const [timeSlot, setTimeSlot] = useState([
    {
      timezone: timeZone,
      timeZoneValue: timeZoneValue,
      date: moment(new Date()).format("YYYY-MM-DD"),
      dateSend: new Date(),
      startTime: "",
      endTime: "",
      originTime: "",
    },
  ]);
  //
  //
  //

  function getEndTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    // Create a Date object to handle the time
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    // Add 1 hour and 30 minutes
    currentTime.setHours(currentTime.getHours() + 1);
    currentTime.setMinutes(currentTime.getMinutes() + 30);
    // Get the updated hours and minutes
    const updatedHours = currentTime.getHours().toString().padStart(2, "0");
    const updatedMinutes = currentTime.getMinutes().toString().padStart(2, "0");
    // Return the updated time in "HH:mm" format
    return `${updatedHours}:${updatedMinutes}`;
  }
  //
  const handleSelectStartTime = (selected, index) => {
    const updatedMultiplemages = [...timeSlot];
    updatedMultiplemages[index]["startTime"] = selected;
    updatedMultiplemages[index]["endTime"] = getEndTime(selected);
    setTimeSlot(updatedMultiplemages);
  };
  //
  const handleSelectEndTime = (selected, index) => {
    if (timeSlot[index].startTime === "") {
      ErrorToast("Please select start time first.");
    } else {
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index]["endTime"] = selected;
      setTimeSlot(updatedMultiplemages);
    }
  };
  //
  //
  const handleChangeForDateAndTime = (e, index, isDate = "") => {
    let currentDate = new Date();
    if (isDate.length > 0) {
      let isToday = false;
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timeZoneValue === userTimeZone) {
        const todayDate = moment(currentDate).format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = getEndTime(hourStartTime);
          updatedMultiplemages[index]["startTime"] = hourStartTime;
          updatedMultiplemages[index]["originTime"] = hourStartTime;
          setTimeSlot(updatedMultiplemages);
        } else {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = "";
          updatedMultiplemages[index]["startTime"] = "";
          updatedMultiplemages[index]["originTime"] = "";
          setTimeSlot(updatedMultiplemages);
        }
      }
      else {
        const now = moment.tz(timeZoneValue);
        const timeZoness = now.format('YYYY-MM-DD HH:mm:ss')
        const todayDate = moment.utc(timeZoness).startOf('day').format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = getEndTime(hourStartTime);
          updatedMultiplemages[index]["startTime"] = hourStartTime;
          updatedMultiplemages[index]["originTime"] = hourStartTime;
          setTimeSlot(updatedMultiplemages);
        } else {
          const updatedMultiplemages = [...timeSlot];
          updatedMultiplemages[index][isDate] = e;
          updatedMultiplemages[index]["date"] = formatDate(e);
          updatedMultiplemages[index]["endTime"] = "";
          updatedMultiplemages[index]["startTime"] = "";
          updatedMultiplemages[index]["originTime"] = "";
          setTimeSlot(updatedMultiplemages);
        }
      }

    } else if (e.target.name === "startTime") {
      console.log("Start time is " + e.target?.value);
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      updatedMultiplemages[index]["endTime"] = getEndTime(e.target.value);
      setTimeSlot(updatedMultiplemages);
     // e.target.value = null;
    } else if (e.target.name === "endTime") {
      if (timeSlot[index].startTime === "") {
        ErrorToast("Please select start time first.");
      } else {
        console.log("End time is " + e.target?.value);
        const updatedMultiplemages = [...timeSlot];
        updatedMultiplemages[index][e.target?.name] = e.target?.value;
        setTimeSlot(updatedMultiplemages);
      }
    } else {
      const updatedMultiplemages = [...timeSlot];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      setTimeSlot(updatedMultiplemages);
     // e.target.value = null;
    }
  };

  // done
  const handleAddClickForDate = (e) => {
    if (timeZone === null || timeZone === undefined) {
      ErrorToast("Please select your timezone");
      return false;
    } else {
      e.preventDefault();
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === timeZoneValue
      );
      setTimeSlot([
        ...timeSlot,
        {
          timezone: timeZone,
          timeZoneValue: matchedTimeZone.label,
          date: moment(new Date()).format("YYYY-MM-DD"),
          dateSend: new Date(),
          startTime: hourStartTime,
          endTime: getEndTime(hourStartTime),
          originTime: hourStartTime,
        },
      ]);
    }
  };
  // done end
  const handleRemoveClickForDate = (e, index) => {
    e.preventDefault();
    const list = [...timeSlot];
    list.splice(index, 1);
    setTimeSlot(list);
  };

  const ClearAll = () => {
    setTimeSlot({
      date: new Date(),
      startTime: "",
      endTime: "",
    });
  };

  const formValidation = () => {
    if (timeZone === null || timeZone === undefined) {
      ErrorToast("Please select your timezone");
      return false;
    }

    // if (selectedOptions?.length > 0) {
    let allGood3 = true;
    timeSlot?.map((item) => {
      if (item?.startTime !== "") {
      } else {
        ErrorToast("Plese Select Time.");
        allGood3 = false;
      }
    });
    const selectedTimeSlots = {};
    timeSlot.forEach((item, index) => {
      if (item.date) {
        const key = `${item.date}-${item.startTime}-${item.endTime}`;
        if (selectedTimeSlots[key]) {
          ErrorToast("Duplicate time slots are not allowed.");
          allGood3 = false;
          return false;
        } else {
          selectedTimeSlots[key] = true;
          // const [hours1, minutes1] = item.startTime.split(":").map(Number);
          // const [hours2, minutes2] = item.endTime.split(":").map(Number);
          // const date1 = new Date(0, 0, 0, hours1, minutes1);
          // const date2 = new Date(0, 0, 0, hours2, minutes2);
          // const timeDifferenceMinutes = Math.abs(date2 - date1) / (60 * 1000);
          // const maxAllowedDifferenceMinutes = 90;
          // const isWithinLimit =
          //   timeDifferenceMinutes === maxAllowedDifferenceMinutes;
          // if (timeDifferenceMinutes === maxAllowedDifferenceMinutes) {
          //   // Valid time slot
          // } else {
          //   // Invalid time slot, display an error
          //   ErrorToast(
          //     "Difference between start and end time should be 1.5 Hours."
          //   );
          //   allGood3 = false;
          // }
        }
      } else {
        // Date, start time, or end time not selected, display an error
        ErrorToast(
          "Please select date, start time, and end time for all time slots."
        );
        allGood3 = false;
      }
    });
    if (allGood3) {
      return true;
    } else {
      return false;
    }
    // } else {
    // ErrorToast("Please select one option");
    // return false;
    // }
  };

  //   if (selected !== "default") {
  //     const timeZonesList = getTimeZones();
  //     const matchedTimeZone = timeZonesList.find(
  //       (item) => item.value === selected
  //     );
  //     const selectedTimeZone = matchedTimeZone.value;
  //     const selectedLabel = matchedTimeZone.label;
  //     const origin = moment.tz(selectedTimeZone);
  //     setTimeZoneValue(matchedTimeZone.value);
  //     let s1 = origin.format("HH");
  //     let s2 = origin.format("mm");
  //     if (s1 < 22) {
  //       if (s2 <= 30) {
  //         s2 = "30";
  //       } else {
  //         s2 = "00";
  //         s1 = Number(s1) + 1;
  //         if (s1 < 10) {
  //           s1 = "0" + s1;
  //         }
  //       }
  //     }
  //     let ss = s1 + ":" + s2;
  //     const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
  //     setTimeZone(offsetMinutes);
  //     const initialOption1 = timeHalfHour.find((item) => item == ss);
  //     if (initialOption1) {
  //       const updatedTimeSlot = timeSlot.map((item) => ({
  //         ...item,
  //         startTime: initialOption1,
  //         timezone: offsetMinutes,
  //         timeZoneValue: selectedLabel,
  //         originTime: initialOption1,
  //         endTime: getEndTime(initialOption1),
  //       }));
  //       setHourStartTime(initialOption1);
  //       setNewOne(initialOption1);
  //       setTimeSlot(updatedTimeSlot);
  //     }
  //   }
  // };
  const handleSelectTimeZone = (selected) => {
    if (selected !== "default") {
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === selected
      );
      const selectedTimeZone = matchedTimeZone.value;
      const selectedLabel = matchedTimeZone.label;
      const origin = moment.tz(selectedTimeZone);
      setTimeZoneValue(matchedTimeZone.value);
      let s1 = origin.format("HH");
      let s2 = origin.format("mm");
      if (s1 < 22) {
        if (s2 <= 30) {
          s2 = "30";
        } else {
          s2 = "00";
          s1 = Number(s1) + 1;
          if (s1 < 10) {
            s1 = "0" + s1;
          }
        }
      }
      let ss = s1 + ":" + s2;
      let date11 = origin?.format("MMM DD, yyyy")
      console.log('origin ss', ss, date11);
      if (ss <= '22:30') {
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const initialOption1 = timeHalfHour.find((item) => item == ss);
        if (initialOption1) {
          const updatedTimeSlot = timeSlot.map((item) => ({
            ...item,
            startTime: initialOption1,
            date: moment(origin).format("YYYY-MM-DD"),
            dateSend: date11,
            timezone: offsetMinutes,
            timeZoneValue: selectedLabel,
            originTime: initialOption1,
            endTime: getEndTime(initialOption1),
          }));
          setHourStartTime(initialOption1);
          setTimeSlot(updatedTimeSlot);
        }
      } else {
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const updatedTimeSlot = timeSlot.map((item) => ({
          ...item,
          startTime: "00:00",
          timezone: offsetMinutes,
          timeZoneValue: selectedLabel,
          originTime: "00:00",
          endTime: getEndTime("00:00"),
        }));
        setHourStartTime("00:00");
        setTimeSlot(updatedTimeSlot);
      }
    }
  };
  //
  //

  const handleChange = (e) => {
    if (e.target.name === "timezone" && e.target.value !== "default") {
      const selectedTimeZone = e.target.value;
      const selectedLabel = e.target.options[e.target.selectedIndex].text;
      const origin = moment.tz(selectedTimeZone);
      setTimeZoneValue(selectedTimeZone);
      let s1 = origin.format("HH");
      let s2 = origin.format("mm");
      if (s1 < 22) {
        if (s2 <= 30) {
          s2 = "30";
        } else {
          s2 = "00";
          s1 = Number(s1) + 1;
          if (s1 < 10) {
            s1 = "0" + s1;
          }
        }
      }
      let ss = s1 + ":" + s2;
      const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
      setTimeZone(offsetMinutes);
      const initialOption1 = timeHalfHour.find((item) => item == ss);
      if (initialOption1) {
        const updatedTimeSlot = timeSlot.map((item) => ({
          ...item,
          startTime: initialOption1,
          timezone: offsetMinutes,
          timeZoneValue: selectedLabel,
          originTime: initialOption1,
          endTime: getEndTime(initialOption1),
        }));
        setHourStartTime(initialOption1);
        setNewOne(initialOption1);
        setTimeSlot(updatedTimeSlot);
      }
    }
  };

  function addAndCheckTime(timeStr) {
    // if (timeStr === '00:00') {
    //   return timeStr
    // }

    const currentTime = new Date();
    const [hours, minutes] = timeStr?.split(":").map(Number);
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59);
    const timeDifference = endOfDay - currentTime;
    let endDF = timeDifference / 60 / 60 / 1000;
    if (endDF === 1.4997222222222222) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 29);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else if (timeDifference > 1.5 * 60 * 60 * 1000) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else {
      return "25:00";
    }
  }

  //
  //
  // Search Timezone
  //
  //
  const [searchedTimezone, setSearchedTimezone] = useState("");
  const searchTimeZonesValue = (e) => {
    setSearchedTimezone(e.target.value);
  };
  const searchTimezonesFromArray = () => {
    if (searchedTimezone === "") {
      setTimeZoneArray(timeZones);
    } else {
      let list = [...timeZones];
      list = list.filter((item) =>
        item.label.toLowerCase().includes(searchedTimezone.toLowerCase())
      );
      setTimeZoneArray(list);
    }
  };

  const handleDropdownToggleTimeZone = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    searchTimezonesFromArray();
  }, [searchedTimezone]);

  return (
    <>
      <div className="reschedule__session__container">
        <div className="reschedule__session__container__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={() => setShowAddDate(false)}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back to Mentor dashboard
            </p>
          </div>
          {/* <div className="reschedule__session__container__top__right">
          <button
            className="btn-green"
            onClick={() => setShowConfirmModal(true)}
          >
            Save
          </button>
          <button className="btn-grey">Cancel</button>
        </div> */}
        </div>
        <div className="reschedule__session__container__bottom edit__schedule__margin">
          <Row className="reschedule__session__container__bottom__row">
            <Col
              md={10}
              xl={8}
              xxl={7}
              className="reschedule__session__container__bottom__left"
            >
              <h2 className="reschedule__session__container__bottom__left__title">
                Add to schedule
              </h2>
              <div className="reschedule__session__container__bottom__left__form">
                <div className="reschedule__session__container__bottom__left__form__container">
                  <>
                    {/* <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p
                      className="reschedule__session__container__bottom__left__form__container__label__text"
                      style={{ marginBottom: "4px" }}
                    >
                      Select Sessions(s)
                    </p>
                  </label> */}
                    {/* <div style={{ marginBottom: "8px" }}>
                    <Multiselect
                      options={options}
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      placeholder="Select services"
                    />
                  </div> */}
                  </>
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Timezone
                    </p>
                    <img
                      src={ClockIcon}
                      alt="clock icon"
                      className="reschedule__session__container__bottom__left__form__container__label__icon"
                    />
                  </label>
                  <Dropdown onSelect={handleSelectTimeZone} onToggle={handleDropdownToggleTimeZone}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdownToSelect select__icon"
                    >
                      <p className="forumm__button__text">
                        {timeZoneValue
                          ? timeZoneValue
                          : "Please select timezone by city"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu timezone__relative"
                      style={{ padding: "0px" }}
                    >
                      <Form.Control
                      ref={searchRef}
                        type="text"
                        className="search__input search__timezone"
                        placeholder="Search timezone by city..."
                        name="timezone"
                        value={searchedTimezone}
                        onChange={(e) => searchTimeZonesValue(e)}
                      />
                      {timeZoneArray?.map((zone) => (
                        <Dropdown.Item
                          key={zone.value}
                          eventKey={zone.value}
                          className={`dropdownToSelect__item ${zone.value === timeZoneValue
                            ? "dropdownToSelect__item__checked"
                            : ""
                            }`}
                        >
                          {zone.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Select timezone old */}
                  {/* <select
                    className="reschedule__session__container__bottom__left__form__container__input input__element custom-select select__icon"
                    name="timezone"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={"default"}>
                      Please select your timezone
                    </option>
                    {timeZones?.map((zone) => (
                      <option key={zone.value} value={zone.value}>
                        {zone.label}
                      </option>
                    ))}
                    <img src={downArrow} />
                  </select> */}
                  {/* Select timezone old */}

                  {timeSlot?.map((x, i) => {
                    return (
                      <div className="add__anotherdate__main">
                        <div className="add__anotherdate__main__inner">
                          <p className="reschedule__session__container__bottom__left__form__container__label__text">
                            Add date
                          </p>
                          <div className="cancelbtn-container">
                            {timeSlot.length > 1 && i > 0 && (
                              <button
                                className="btn-cancel"
                                onClick={(e) => handleRemoveClickForDate(e, i)}
                              >
                                <p>Remove</p>
                                <img src={RemoveIcon} />
                              </button>
                            )}
                          </div>
                        </div>
                        <DateTimePicker
                          className="schedules__guides__contaiener__calendar calendar__pointer__none calender__padding"
                          name="dateSend"
                          format="MMM dd, yyyy"
                          isCalendarOpen={openDateCalender ?  calenderIndex === i && true : false}
                          onClick={() => {setOenDateCalender(!openDateCalender);setCalenderIndex(i);}}
                          disableClock={true}
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                          onChange={(e) =>
                           { handleChangeForDateAndTime(e, i, "dateSend");
                            }
                          }
                          value={timeSlot[i]?.dateSend}
                        // minDate={new Date()}
                        // minDate={timeSlot[i].startTime >= "22:30" ? "" : new Date()}
                        />
                        <div className="add__anotherdate__main__selects reschedule__session__container__bottom__left__form__container__two">
                          <Dropdown
                            onSelect={(e) => handleSelectStartTime(e, i)}
                            style={{ width: "49%" }}
                          >
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className={`dropdownToSelect select__icon ${timeZone == null
                                ? "dropdownToSelect__maindisabled"
                                : ""
                                }`}
                            >
                              <p className="forumm__button__text">
                                {timeSlot[i]?.startTime
                                  ? timeSlot[i]?.startTime
                                  : "Select start time"}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              {timeHalfHour.map((hour) => (
                                <Dropdown.Item
                                  className={`${hour < timeSlot[i]?.originTime
                                    ? " dropdownToSelect__disabled"
                                    : timeSlot[i]?.startTime === hour
                                      ? "dropdownToSelect__item__checked"
                                      : "dropdownToSelect__item"
                                    }`}
                                  eventKey={hour}
                                  key={hour}
                                  disabled={hour < timeSlot[i]?.originTime}
                                >
                                  {hour}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* Start time  */}
                          <>
                            {/* <select
                            id="startTime"
                            name="startTime"
                            className={`eschedule__session__container__bottom__left__form__container__input input__element select__icon ${
                              timeZone == null ? "start__time__disabled" : ""
                            } `}
                            value={timeSlot[i]?.startTime}
                            onChange={(e) => handleChangeForDateAndTime(e, i)}
                            style={{ width: "49%" }}
                          >
                            <option
                              value=""
                              disabled
                              selected
                              hidden
                              className="DropDownMentor__select__item"
                            >
                              Start Time
                            </option>
                            {timeHour.map((hour) => (
                              <option
                                className=""
                                value={hour}
                                disabled={hour < timeSlot[i]?.originTime}
                              >
                                {hour}
                              </option>
                            ))}
                          </select> */}
                          </>
                          <Dropdown
                            onSelect={(e) => handleSelectEndTime(e, i)}
                            style={{ width: "49%" }}
                            show={false}
                          >
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className={`dropdownToSelect  ${timeZone == null
                                ? "dropdownToSelect__maindisabled"
                                : ""
                                }`}
                            >
                              <p className="forumm__button__text">
                                {timeSlot[i]?.endTime
                                  ? timeSlot[i]?.endTime
                                  : timeSlot[i]?.startTime === ""
                                  ? "End time"
                                  : addAndCheckTime(timeSlot[i].startTime)}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              {timeHalfHour.map((hour) => (
                                <Dropdown.Item
                                  className={`${hour < timeSlot[i]?.startTime
                                    ? " dropdownToSelect__disabled"
                                    : timeSlot[i]?.endTime === hour
                                      ? "dropdownToSelect__item__checked"
                                      : "dropdownToSelect__item"
                                    }`}
                                  eventKey={hour}
                                  key={hour}
                                  disabled={
                                    (hour !== "00:00" &&
                                      timeSlot[i]?.startTime &&
                                      // hour <= timeSlot[i]?.startTime
                                      hour <
                                        addAndCheckTime(
                                          timeSlot[i].startTime
                                        )) ||
                                    hour >
                                      addAndCheckTime(timeSlot[i].startTime)
                                  }
                                >
                                  {hour}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* End time old */}
                          {/* <select
                            id="endTime"
                            name="endTime"
                            className={`eschedule__session__container__bottom__left__form__container__input input__element pointer__none ${
                              timeZone == null ? "start__time__disabled" : ""
                            } `}
                            value={
                              timeSlot[i]?.endTime
                                ? timeSlot[i]?.endTime
                                : timeSlot[i]?.startTime === ""
                                ? ""
                                : addAndCheckTime(timeSlot[i].startTime)
                            }
                            onChange={(e) => handleChangeForDateAndTime(e, i)}
                            style={{ width: "49%" }}
                          >
                            <option value="" disabled selected hidden>
                              End Time
                            </option>
                            {timeHalfHour.map((hour) => (
                              <option
                                disabled={
                                  (hour !== "00:00" &&
                                    timeSlot[i]?.startTime &&
                                    // hour <= timeSlot[i]?.startTime
                                    hour <
                                      addAndCheckTime(timeSlot[i].startTime)) ||
                                  hour > addAndCheckTime(timeSlot[i].startTime)
                                }
                                value={hour}
                              >
                                {hour}
                              </option>
                            ))}
                          </select> */}
                          {/* End time new */}
                        </div>
                        <p className="reschedule__session__container__bottom__left__subtitle">
                          Each meeting slot is allocated a duration of 1.5 hours
                        </p>
                      </div>
                    );
                  })}

                  <div className="btn-box d-flex ">
                    <button
                      className="btn-addanother"
                      // style={{ marginTop: '-20px' }}
                      onClick={(e) => handleAddClickForDate(e)}
                    >
                      <img src={squareAddWhite} alt="" />
                      <p className="">Add another schedule</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="reschedule__session__container__bottom__left__form">
                <button
                  className="btn-green"
                  onClick={SubmitBookSesssion}
                  style={{ marginTop: "10px" }}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* session confirm modal */}

      <ConfirmModal show={showConfirmModal} setShow={setShowConfirmModal}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Propose session</div>
          <div className="para__para__para ">
            Are you sure you want to go ahead and propose this session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={AddNewdate}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={cancelConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>
      {loader && <ScreenLoader />}
    </>
  );
};

export default AddDate;
