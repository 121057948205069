import { Col, Row } from "react-bootstrap";
import NavBar from "../Navbar/NavBar";
import "./approedeny.scss";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../service/client";
import moment from "moment";
import ScreenLoader from "../Loader/ScreenLoader";
import { SuccessToast } from "../MyToaster";
import { useHistory } from "react-router-dom";

const ApproveDeny = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [userId,setUserId]=useState();
  const history = useHistory();

  async function approveReschedule() {
    setLoader(true);
    try {
      const approveresponse = await axios.post(
        `${API_URL}/api/acceptReSchedule/${id}`,{
          userId:userId
        }
      );
      if (approveresponse.status === 200) {
        setLoader(false);
        SuccessToast("Accepted Successfully");
        history.push("/interviewdashboard");
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  }

  async function declineReschedule() {
    setLoader(true);
    try {
      const approveresponse = await axios.post(
        `${API_URL}/api/cancleReschedule/${id}`
      );
      if (approveresponse.status === 200) {
        setLoader(false);
        SuccessToast("Canclled Successfully");
        history.push("/interviewdashboard");
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  }
  async function getRescheduleData() {
    try {
      const ress = await axios.post(`${API_URL}/api/getReschedule/${id}`);
      setData(ress?.data);
      console.log("this is the response", ress);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getRescheduleData();
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const userId = url.searchParams.get('userId');
    setUserId(userId)
  }, [id]);

  const preendDateTime = moment(data?.preEndDateTime);
  const prestartDateTime = moment(data?.preStartDateTime);
  const endDateTime = moment(data?.endDateTime);
  const startDateTime = moment(data?.startDateTime);

  const formattedEndDateTime = preendDateTime?.format("HH:mm");
  const formattedStartDateTime = prestartDateTime?.format("HH:mm");
  const formattedEndDateTime1 = endDateTime?.format("HH:mm");
  const formattedStartDateTime1 = startDateTime?.format("HH:mm");

  const formattedRange1 = `${formattedStartDateTime} - ${formattedEndDateTime}, ${preendDateTime?.format('MMMM D, YYYY')}`;
  const formattedRange2 = `${formattedStartDateTime1} - ${formattedEndDateTime1}, ${endDateTime?.format('MMMM D, YYYY')}`;

  console.log("id", id);
  return (
    <>
      {loader && <ScreenLoader />}
      <NavBar />
      <div className="container__main__of__reschedule">
        <h2 className="res___sessions__sessions text-center">
          Reschedule Session
        </h2>
        <div className="pre__time__main">
          <div className="time__time">Previous time</div>
          <div className="show__time__time">
            {formattedRange1}{" "}
          </div>
        </div>
        <div className="pre__time__main">
          <div className="time__time">Rescheduled time</div>
          <div className="show__time__time__time">
            {formattedRange2}
          </div>
        </div>
        <div className="app_deny__main__main">
          <button onClick={approveReschedule} className="btn-green">
            Approve
          </button>
          <button onClick={declineReschedule} className="deny__btn">
            Deny
          </button>
        </div>
      </div>
    </>
  );
};

export default ApproveDeny;
