import React from "react";

// internal imports
import "./joinsession.scss";
const JoinSessionButton = ({ startTime }) => {
  return (
    <button
      className={`join__session__button ${
        startTime ? "join__session__disabled" : "join__session__enabled"
      }`}
    >
      {startTime !== "" ? `Session starts ${startTime}` : "Join session"}
    </button>
  );
};

export default JoinSessionButton;
