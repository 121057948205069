import * as React from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../service/client";
import { useSelector } from "react-redux";

export default function Vedio() {
  const { roomID } = useParams();
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);

  let myMeeting = async (element) => {
    // generate Kit Token
    const appID = 1252235071;
    const serverSecret = "58cfacef79210ab308c9412470c2063a";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      Date.now().toString(),
      `${userData?.firstName} ${userData?.lastName}`
    );

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      // sharedLinks: [
      //   {
      //     name: 'Personal link',
      //     url:
      //      window.location.protocol + '//' +
      //      window.location.host + window.location.pathname +
      //       '?roomID=' +
      //       roomID,
      //   },
      // ],
      maxUsers: 2,
      showRoomTimer: true,
      showLeavingView: false,
      onLeaveRoom: async () => {
        try {
          const currentURL = window.location.href;
          const urlParts = currentURL.split("/");

          const data = await axios.get(
            `${API_URL}/api/meetingEnded/${urlParts[5]}`
          );
          window.location.href = `${data.data.url}`;
        } catch (err) {
          console.log(err);
        }

        // history.push("/")
        // window.location.reload()
      },

      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });
  };

  return (
    <div
      className="myCallContainer"
      ref={myMeeting}
      style={{ width: "100vw", height: "100vh" }}
    ></div>
  );
}
