import React from "react";
import tick from "../../assets/cancelMeeting.svg";
import NavLogo from "../../assets/8.svg";
import { useHistory } from "react-router-dom";

const AlreadyVerified = () => {
    const history = useHistory()

    const dashboard = () => {
        history.push('/login')
    }
  return (
    <div className="stripe__stripe">
      <div className="PersonalInfoSec">
        <div className="PersonalInfoSec__nav">
          <img
            className="PersonalInfoSec__nav__img "
            src={NavLogo}
            alt="logo"
          />
        </div>
        <div className="middle">
          <div>
            <img src={tick} alt="stripe" />
          </div>
          <div className="text___main success__message">
            <h1 className="">Your Email is Already Verified</h1>
          </div>
          <div className="stripe__btn">
            <button onClick={dashboard} className="btn__common__css first__btn">
              Go to Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlreadyVerified;
