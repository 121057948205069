import React, { useState, useEffect } from "react";
import NavBar from "../../Navbar/NavBar";
//
import ArrowLeft from "../../../assets/arrow-left.svg";
import Dot from "../../../assets/bi_dot.svg";
import Avatar1 from "../../../assets/a1.jpg";
import ShieldIcon from "../../../assets/shield.svg";
import LikeIcon from "../../../assets/like.svg";
import LinkIcon from "../../../assets/link-2.svg";
import messageIcon from "../../../assets/messageIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import likeWhite from "../../../assets/likeWhite.svg";
import likeGreen from "../../../assets/likeGreen.svg";

import "./Thread.scss";
import { Editor } from "primereact/editor";
import { Col, Row } from "react-bootstrap";
import NewsletterFooter from "../../NewsletterFooter/NewsletterFooter";
import {
  likeForum,
  commentForum,
  likesUserComment,
  getForumSingleData,
  getRelatedTopic,
} from "../../../service/redux/middleware/forum";
import moment from "moment";
import ScreenLoader from "../../Loader/ScreenLoader";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import MyLoader from "../../Loader/MyLoader";
import LoaderSmall from "../../Loader/Loadersmall";
import VerticalRange from "../VerticalRange";

const Thread = ({ setShowThread, elem }) => {
  const [editorText, setEditorText] = useState("");
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const [singleData, setSingleData] = useState("");
  const [discussionContent, setDiscussionContent] = useState("");
  const [forum, setForum] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [likesLoader1, setLikesLoader1] = useState(false);
  const [likesLoader2, setLikesLoader2] = useState(false);
  const [likedIndex, setLikedIndex] = useState();

  const [relatedTopic, setRelatedTopic] = useState([]);

  useEffect(() => {
    setLoader(true);
    dispatch(getForumSingleData(elem?.forum?._id)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        // if (singleData?.forum?.discussion) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = res?.payload?.data[0]?.forum?.discussion;
        const imgTags = tempDiv.querySelectorAll("img");
        imgTags.forEach((img) => {
          img.classList.add("images__images__in__forum");
        });
        setDiscussionContent(tempDiv.innerHTML);
        addSelectedKey(res?.payload?.data[0]);
        setForum(res?.payload?.data[0]);
      } else {
        setLoader(false);
      }
    });
    dispatch(getRelatedTopic(elem?.forum?._id)).then((res) => {
      console.log("res", res);
      if (res?.payload?.status === 200) {
        // setRelatedTopic()
      } else {
        setLoader(false);
      }
    });
  }, []);

  // add key in commnet likes
  const addSelectedKey = (data) => {
    const toUpdate = data?.comments?.map((item) => {
      let newItem = { ...item, count: item?.comment?.likes?.length };
      return newItem;
    });
    setSingleData(toUpdate);
    setLoader(false);
  };

  // post like
  const likesPost = (e) => {
    e.preventDefault();
    const data = {
      forumId: forum?.forum?._id,
      userId: userData?._id,
    };

    if (!likesLoader1) {
      try {
        setLikesLoader1(true);
        dispatch(likeForum(data)).then((res) => {
          if (res?.payload?.status === 200) {
            dispatch(getForumSingleData(elem?.forum?._id)).then((res) => {
              if (res?.payload?.status === 200) {
                setLikesLoader1(false);
                setForum(res?.payload?.data[0]);
              } else {
                setLikesLoader1(false);
              }
            });
          } else {
            setLikesLoader1(false);
          }
        });
      } catch (error) {
        setLikesLoader1(false);
      }
    }
  };

  // create new comment

  const commentOnForm = (e) => {
    e.preventDefault();
    if (editorText === null || editorText === "") {
      ErrorToast(
        "Please enter a discussion topic before submitting your question."
      );
      return false;
    }
    const data = {
      forumId: forum?.forum?._id,
      userId: userData?._id,
      text: editorText,
    };

    if (!loading) {
      setLoading(true);
      try {
        // setLoader(true);
        dispatch(commentForum(data)).then((res) => {
          if (res?.payload?.status === 200) {
            // setLoader(false);
            SuccessToast("Commented");
            setEditorText("");
            dispatch(getForumSingleData(elem?.forum?._id)).then((res1) => {
              if (res1?.payload?.status === 200) {
                setLoading(false);
                addSelectedKey(res1?.payload?.data[0]);
              }
            });
          } else {
            ErrorToast(res?.payload?.message);
            setLoading(false);
            setEditorText("");
          }
        });
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      } finally {
        // setLoading(false)
      }
    }
  };

  // comment like

  const likesComment = (elem) => {
    console.log("like");

    const data = {
      forumId: forum?.forum?._id,
      userId: userData?._id,
      commentId: elem?.comment?._id,
    };
    if (!likesLoader2) {
      try {
        setLikesLoader2(true);
        dispatch(likesUserComment(data)).then((res) => {
          if (res?.payload?.status === 200) {
            dispatch(getForumSingleData(forum?.forum?._id)).then((res) => {
              if (res?.payload?.status === 200) {
                setLikesLoader2(false);
                addSelectedKey(res?.payload?.data[0]);
              } else {
                setLikesLoader2(false);
              }
            });
          } else {
            setLikesLoader2(false);
          }
        });
      } catch (error) {
        setLikesLoader2(false);
      }
    }
  };

  let found = forum?.forum?.likes.find((like) => like === userData._id);

  return (
    <>
      {loader && <ScreenLoader />}
      <NavBar />
      <div className="container__custom">
        <div className="thread__container">
          <div className="thread__container__top">
            <div
              className="thread__container__top__inner"
              onClick={() => setShowThread(false)}
            >
              <img
                src={ArrowLeft}
                alt="back arrow"
                className="thread__container__top__inner__image"
              />
              <p className="thread__container__top__inner__text">
                Back to forum
              </p>
            </div>
          </div>
          <Row className="threadup">
            <Col className="thread__container__bottom p-0" md={12}>
              <div className="thread__container__bottom__title">
                <div className="thread__container__bottom__title__inner">
                  <h1 className="thread__container__bottom__title__inner__heading">
                    {forum?.forum?.title}
                  </h1>
                  {/* <div className="thread__container__bottom__title__inner__container">
                    <p className="thread__container__bottom__title__inner__container__text">
                      {forum?.forum?.topic}
                    </p>
                    <img
                      src={Dot}
                      className="thread__container__bottom__title__inner__container__image"
                      alt="dot"
                    />
                    <p className="thread__container__bottom__title__inner__container__text">
                      General Overview
                    </p>
                  </div> */}
                </div>
              </div>
              {/* post */}
              <div className="thread__container__bottom__post">
                <div className="thread__container__bottom__post__left">
                  <img
                    src={forum?.image}
                    alt="avatar"
                    className="thread__container__bottom__post__left__image"
                  />
                </div>
                <div className="thread__container__bottom__post__right">
                  <div className="thread__container__bottom__post__right__top">
                    <div className="thread__container__bottom__post__right__top__left">
                      <img
                        src={Avatar1}
                        alt="avatar"
                        className="thread__container__bottom__post__right__top__left__imageA"
                      />
                      <h3 className="thread__container__bottom__post__right__top__left__heading">
                        {forum?.firstName} {forum?.lastName}
                      </h3>
                      <img
                        src={ShieldIcon}
                        alt="shield"
                        className="thread__container__bottom__post__right__top__left__image"
                      />
                    </div>
                    <p className="thread__container__bottom__post__right__top__text">
                      {moment(forum?.forum?.createdAt).format("MMM D")}
                    </p>
                  </div>
                  <div />
                  <div className="thread__container__bottom__post__right__bottom">
                    {/* <div className="thread__container__bottom__post__right__bottom__text" style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: singleData?.forum?.discussion }} /> */}
                    <div
                      className="thread__container__bottom__post__right__bottom__text"
                      dangerouslySetInnerHTML={{ __html: discussionContent }}
                    />
                    <div className="thread__container__bottom__post__right__bottom__image"></div>
                    <p className="thread__container__bottom__post__right__bottom__text"></p>
                    {/* <div className="thread__container__bottom__post__right__bottom__panel">
                      {userData?._id ? (
                        <>
                          {!likesLoader1 ? (
                            <button
                              className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn"
                              onClick={likesPost}
                            >
                              <div className="safdew__xawf">
                                {found ? (
                                  <img
                                    src={likeGreen}
                                    className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                  />
                                ) : (
                                  <img
                                    src={LikeIcon}
                                    className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                  />
                                )}
                                <p className="thread__container__bottom__post__right__bottom__panel__inner__text">
                                  {forum?.forum?.likes?.length}
                                </p>
                              </div>
                            </button>
                          ) : (
                            likesLoader1 && <LoaderSmall />
                          )}
                        </>
                      ) : (
                        <button className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn">
                          <div className="safdew__xawf">
                            {found ? (
                              <img
                                src={likeGreen}
                                className="thread__container__bottom__post__right__bottom__panel__inner__image"
                              />
                            ) : (
                              <img
                                src={LikeIcon}
                                className="thread__container__bottom__post__right__bottom__panel__inner__image"
                              />
                            )}
                            <p className="thread__container__bottom__post__right__bottom__panel__inner__text">
                              {forum?.forum?.likes?.length}
                            </p>
                          </div>
                        </button>
                      )}
                      <img
                        src={LinkIcon}
                        className="thread__container__bottom__post__right__bottom__panel__image"
                      />
                    </div> */}
                    {userData?._id && (
                      <div className="thread__container__bottom__post__right__bottom__editor">
                        <Editor
                          id="description"
                          value={editorText}
                          onTextChange={(e) => setEditorText(e.htmlValue)}
                          style={{ height: "170px" }}
                          className="editor__text"
                          placeholder="Type Comment..."
                        />
                      </div>
                    )}

                    {userData?._id && (
                      <button
                        className="forum__modal__button"
                        onClick={(e) => commentOnForm(e)}
                        disabled={loading}
                      >
                        {loading ? "Comment..." : "Comment"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* comments */}
              {/* <div className="thread__container__bottom__comments">
                {singleData?.length > 0 ? (
                  <>
                    {singleData?.map((item) => {
                      let found = item?.comment?.likes.find(
                        (like) => like === userData._id
                      );
                      return (
                        <div
                          className="thread__container__bottom__comments__item"
                          key={item}
                        >
                          <div className="thread__container__bottom__comments__item__left">
                            <img
                              src={item?.image}
                              alt="avatar"
                              className="thread__container__bottom__comments__item__left__image"
                            />
                          </div>
                          <div className="thread__container__bottom__comments__item__right">
                            <div className="thread__container__bottom__comments__item__right__top">
                              <div className="thread__container__bottom__comments__item__right__top__left">
                                <img
                                  src={item?.image}
                                  alt="avatar"
                                  className="thread__container__bottom__comments__item__right__top__left__imageA"
                                />
                                <h3 className="thread__container__bottom__comments__item__right__top__left__heading">
                                  {item?.firstName} {item?.lastName}
                                </h3>
                              </div>
                              <p className="thread__container__bottom__comments__item__right__top__text">
                                {moment(item?.date).format("MMM D")}
                              </p>
                            </div>
                            <div
                              className="thread__container__bottom__comments__item__right__text"
                              dangerouslySetInnerHTML={{
                                __html: item?.comment?.text,
                              }}
                            />
                            <div className="thread__container__bottom__comments__item__right__bottom">
                              {userData?._id ? (
                                <>
                                  {found ? (
                                    <>
                                      <button
                                        className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn"
                                        onClick={() => {
                                          unlikesComment(item);
                                        }}
                                      >
                                        <div className="safdew__xawf">
                                          {!item?.found ? (
                                            <img
                                              src={likeGreen}
                                              className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                            />
                                          ) : (
                                            <img
                                              src={LikeIcon}
                                              className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                            />
                                          )}
                                          <p className="thread__container__bottom__comments__item__right__bottom__inner__text">
                                            {item?.comment?.likes?.length >= 0
                                              ? item?.count
                                              : "0"}
                                          </p>
                                        </div>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn"
                                        onClick={() => {
                                          likesComment(item);
                                        }}
                                      >
                                        <div className="safdew__xawf">
                                          {item?.found ? (
                                            <img
                                              src={likeGreen}
                                              className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                            />
                                          ) : (
                                            <img
                                              src={LikeIcon}
                                              className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                            />
                                          )}

                                          <p className="thread__container__bottom__comments__item__right__bottom__inner__text">
                                            {item?.comment?.likes?.length >= 0
                                              ? item?.count
                                              : "0"}
                                          </p>
                                        </div>
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : (
                                <button className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn">
                                  <div className="safdew__xawf">
                                    {found ? (
                                      <img
                                        src={likeGreen}
                                        className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                      />
                                    ) : (
                                      <img
                                        src={LikeIcon}
                                        className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                      />
                                    )}
                                    <p className="thread__container__bottom__post__right__bottom__panel__inner__text">
                                      {singleData?.forum?.likes?.length}
                                    </p>
                                  </div>
                                </button>
                              )}
                              <img
                                src={LinkIcon}
                                className="thread__container__bottom__comments__item__right__bottom__image"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div> */}
              <div className="thread__container__bottom__comments">
                {singleData?.length > 0 ? (
                  <>
                    {singleData?.map((item, index) => {
                      let found = item?.comment?.likes.find(
                        (like) => like === userData._id
                      );
                      console.log("found", found);
                      return (
                        <div
                          className="thread__container__bottom__comments__item"
                          key={item}
                        >
                          <div className="thread__container__bottom__comments__item__left">
                            <img
                              src={item?.image}
                              alt="avatar"
                              className="thread__container__bottom__comments__item__left__image"
                            />
                          </div>
                          <div className="thread__container__bottom__comments__item__right">
                            <div className="thread__container__bottom__comments__item__right__top">
                              <div className="thread__container__bottom__comments__item__right__top__left">
                                <img
                                  src={item?.image}
                                  alt="avatar"
                                  className="thread__container__bottom__comments__item__right__top__left__imageA"
                                />
                                <h3 className="thread__container__bottom__comments__item__right__top__left__heading">
                                  {item?.firstName} {item?.lastName}
                                </h3>
                              </div>
                              <p className="thread__container__bottom__comments__item__right__top__text">
                                {moment(item?.date).format("MMM D")}
                              </p>
                            </div>
                            <div
                              className="thread__container__bottom__comments__item__right__text"
                              dangerouslySetInnerHTML={{
                                __html: item?.comment?.text,
                              }}
                            />
                            <div className="thread__container__bottom__comments__item__right__bottom">
                              <>
                                {/* {!likesLoader2  ? ( */}
                                <>
                                  <button
                                    className="thread__container__bottom__post__right__bottom__panel__inner liked__btn__btn__btn"
                                    onClick={() => {
                                      likesComment(item);
                                      setLikedIndex(index);
                                    }}
                                  >
                                    {likesLoader2 && likedIndex === index ? (
                                      <LoaderSmall />
                                    ) : (
                                      <div className="safdew__xawf">
                                        {found ? (
                                          <img
                                            src={likeGreen}
                                            className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                          />
                                        ) : (
                                          <img
                                            src={LikeIcon}
                                            className="thread__container__bottom__post__right__bottom__panel__inner__image"
                                          />
                                        )}
                                        <p className="thread__container__bottom__post__right__bottom__panel__inner__text">
                                          {item?.comment?.likes?.length >= 0
                                            ? item?.count
                                            : "0"}
                                        </p>
                                      </div>
                                    )}
                                  </button>
                                </>
                                {/* ) : (
                                  likesLoader2 &&
                                  likedIndex === index && <LoaderSmall />
                                )} */}
                              </>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <NewsletterFooter />
    </>
  );
};

export default Thread;
