import { combineReducers, configureStore } from "@reduxjs/toolkit";
import signup from "./reducer/signup";
import singin from "./reducer/signin";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import mentorSignup from "./reducer/mentorSignup";
import forumReducer from "./reducer/forumReducer";
import meetingReducer from "./reducer/meetingSlice";

// export const store = configureStore({
//   reducer: {
//     singup: SingUp,
//     singin: SingIn,
//     user: User,
//   },
// });

const appReducer = combineReducers({
  signup: signup,
  signin: singin,
  mentorSignup: mentorSignup,
  forumData: forumReducer,
  meetingData: meetingReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
