import React, { useState } from "react";
import { MySessionCard } from "../../Custom";
import SortIcon from "../../../assets/sort.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row } from "react-bootstrap";
import "./mysession.scss";
import AvatarIcon from "../../../assets/avatar.svg";
import Dots from "../../../assets/dot.svg";
import LocationIcon from "../../../assets/location.svg";
import GraduationIcon from "../../../assets/graduation.svg";
import UniversityIcon from "../../../assets/university.svg";
import GridGreen from "../../../assets/grid-green.svg";
import ListView from "../../../assets/menu.svg";
import Ratings from "../../Custom/Ratings/Ratings";
import "../../Custom/Cards/MySessions/mysessioncard.scss";
import LevelIcon from "../../../assets/level-session.svg";
import DocumentIcon from "../../../assets/document.svg";
import EyeIcon from "../../../assets/eye-green.svg";
import LockIcon from "../../../assets/lock.svg";
import SessionModal from "../../SessionModal/SessionModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import RescheduleSession from './RescheduleSession/RescheduleSession'
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  getInterviewGuide,
  getInterviewGuideMentor,
} from "../../../service/redux/middleware/interviewGuide";
import addIcon from "../../../assets/add.svg";
import noFound from "../../../assets/mySessionEmpty.svg";
import UserProfileMentor from "./UserProfile/UserProfileMentor";
import { setSessionData } from "../../../service/redux/reducer/meetingSlice";
import {
  cancelMentorSession,
  viewMentorSession,
} from "../../../service/redux/middleware/mentor";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import ScreenLoader from "../../Loader/ScreenLoader";

import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";
import StarFull from "../../../assets/star-yellow.svg";
import { addRelability } from "../../../service/redux/middleware/relaible";
// let mySessionDataTemp = [];
const MySessions = ({
  mySessionData,
  setMySessionData,
  mySessionDataTemp,
  setMySessionDataTemp,
  setSelectedComponent,
  setShowAddSchedules,
  setShowAddDate,
  showAddDate,
}) => {
  const [modalShowInterview, setModalShowInterview] = React.useState(false);
  // const [mySessionData, setMySessionData] = useState([]);
  const [myInterviewGuideData, setMyInterviewGuideData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const dispatch = useDispatch();
  //
  const [userProfileElem, setUserProfileElem] = useState(false);
  const [elementData, setElementData] = useState();
  //userdtail
  const userdetail = () => {
    setUserProfileElem(true);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const [isActive, setIsActive] = useState(false);
  const endDateTime = new Date("2023-09-22T14:09:00.000Z");
  const [showDiv, setShowDiv] = useState(false);
  const [singleData, setSingleData] = useState("");
  const [dropdown, setDropDown] = useState("Filter");

  //
  //
  const getSingleData = (elem) => {
    setSingleData(elem);
    console.log("getSingleData", elem);
    if (elem?.mentorAvalible?.sessionType === "Interview Practice") {
      setModalShow(!modalShow);
    } else {
      let timeDifferenceHours = findingDifference(elem?.mentorAvalible);
      //  he meeting if the time difference is within 5 minutes before the meeting time
      if (timeDifferenceHours <= 0.0833) {
        let idToSend = "";
        if (userData?._id === elem?.mentorAvalible?.bookedby) {
          idToSend = userData?._id;
        } else {
          idToSend = elem?.mentorAvalible?.bookedby;
        }
        let data = {
          sessionData: elem,
          isMentor: true,
          idToSend,
        };

        dispatch(setSessionData(data));
        history.push("/meetingpagementor");
        SuccessToast("Welcome to the meeting.");
        // const data2 = {
        //   id: userData?._id,
        //   meetingId: elem?.mentorAvalible?._id,
        //   token: token,
        // };
        // dispatch(addRelability(data2)).then((res) => {
        //   if (res?.payload?.status === 200) {
        //     dispatch(setSessionData(data));
        //     history.push("/meetingpagementor");
        //     SuccessToast("Welcome to the meeting.");
        //   }
        // });
        // Add logic here to actually join the meeting
      } else {
        ErrorToast("Meeting not started yet  ");
      }
    }
  };
  const showGuideModal = () => {
    getUserInterViewGuide(singleData);
  };
  const getUserInterViewGuide = (item) => {
    setLoader1(true);
    setModalShowInterview(true);
    let data = {
      meetingId: item?.mentorAvalible?._id,
      userId: userData?._id,
    };
    dispatch(getInterviewGuideMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMyInterviewGuideData(res?.payload?.data);
        setLoader1(false);
        // setOnSuccess(true);
      } else {
        setLoader1(false);
      }
    });
  };

  const data11 = {
    userId: userData?._id,
    token: token,
  };

  const handleDropdownSelect = (eventKey) => {
    setDropDown(eventKey);
    filter(eventKey);
  };
  const filter = (option) => {
    if (option === "All") {
      setMySessionDataTemp([]);
      setMySessionDataTemp(() => mySessionData);
    } else if (option === "Oldest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.mentorAvalible.startDateTime) {
          startTime = sessionA.mentorAvalible.startDateTime;
        } else {
          if (sessionA.mentorAvalible.length === 1) {
            startTime = sessionA.mentorAvalible.startDateTime;
          } else {
            let filteredData = sessionA.mentorAvalible.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d2 - d1;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.mentorAvalible.startDateTime) {
          startTime2 = sessionB.mentorAvalible.startDateTime;
        } else {
          if (sessionB.mentorAvalible.length === 1) {
            startTime2 = sessionB.mentorAvalible.startDateTime;
          } else {
            let filteredData2 = sessionB.mentorAvalible.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d2 - d1;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeB - startTimeA;
      });
      setMySessionDataTemp(filter);
    } else if (option === "Newest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.mentorAvalible.startDateTime) {
          startTime = sessionA.mentorAvalible.startDateTime;
        } else {
          if (sessionA.mentorAvalible.length === 1) {
            startTime = sessionA.mentorAvalible.startDateTime;
          } else {
            let filteredData = sessionA.mentorAvalible.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d1 - d2;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.mentorAvalible.startDateTime) {
          startTime2 = sessionB.mentorAvalible.startDateTime;
        } else {
          if (sessionB.mentorAvalible.length === 1) {
            startTime2 = sessionB.mentorAvalible.startDateTime;
          } else {
            let filteredData2 = sessionB.mentorAvalible.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d1 - d2;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeA - startTimeB;
      });
      setMySessionDataTemp(filter);
    }
  };
  const findingDifference = (slot) => {
    const localDate = new Date();
    const utcDate = new Date(localDate.toUTCString());
    const sameFormat = utcDate.toISOString();
    const dateDb = new Date(slot?.startDateTime);
    const localDateTime = new Date();
    const timeDifferenceMillis = dateDb - localDateTime;
    // const timeDifferenceMillis = Math.abs(dateDb - localDateTime);

    // Convert the time difference to hours
    const timeDifferenceHours = timeDifferenceMillis / (60 * 60 * 1000);
    return timeDifferenceHours;
  };
  const handleJoinMeeting = () => {
    if (singleData?.mentorAvalible) {
      let timeDifferenceHours = findingDifference(singleData?.mentorAvalible);
      //  he meeting if the time difference is within 5 minutes before the meeting time
      if (timeDifferenceHours <= 0.0833) {
        let idToSend = "";
        if (userData?._id === singleData?.mentorAvalible?.bookedby) {
          idToSend = userData?._id;
        } else {
          idToSend = singleData?.mentorAvalible?.bookedby;
        }
        let data = {
          sessionData: singleData,
          isMentor: true,
          idToSend,
        };

        dispatch(setSessionData(data));
        history.push("/meetingpagementor");
        SuccessToast("Welcome to the meeting.");

        // const data2 = {
        //   id: userData?._id,
        //   meetingId: singleData?.mentorAvalible?._id,
        //   token: token,
        // };
        // dispatch(addRelability(data2)).then((res) => {
        //   if (res?.payload?.status === 200) {
        //     dispatch(setSessionData(data));
        //     history.push("/meetingpagementor");
        //     SuccessToast("Welcome to the meeting.");
        //   }
        // });
        // Add logic here to actually join the meeting
      } else {
        ErrorToast("Meeting not started yet  ");
      }
    }
  };

  //
  const goToAvailability = () => {
    setSelectedComponent(0);
    setShowAddDate(true);
  };

  const cancelMentorSessionHandle = (elem) => {
    const data = {
      creatorId: elem?.mentorAvalible?._id,
      token: token,
    };
    setLoader(true);
    dispatch(cancelMentorSession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        SuccessToast(res?.payload?.data?.message);
        dispatch(viewMentorSession(data11)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setMySessionData(res?.payload?.data);
            setMySessionDataTemp(res?.payload?.data);
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };

  const individualRating = (elem) => {
    const averageIndividual =
      Number(elem?.rattingStudent?.communication) +
      Number(elem?.rattingStudent?.fitQuestion) +
      Number(elem?.rattingStudent?.presence) +
      Number(elem?.rattingStudent?.technicalQuestion);
    let averageRatingind = (Number(averageIndividual) / 4).toFixed(1);
    return isNaN(averageRatingind) ? 0 : averageRatingind;
    // return averageRatingind;
  };
  const topicOrder = [
    "Intro",
    "Fit",
    "Situational",
    "IB",
    "Accounting concepts",
    "Accounting scenarios",
    "Enterprise value",
    "Valuation",
    "DCF",
    "Other",
    "Brainteasers",
  ];

  return (
    <>
      {loader1 ? (
        <ScreenLoader />
      ) : (
        <SessionModal
          show={modalShowInterview}
          setShow={setModalShowInterview}
          style={{ width: "670px !important", padding: "0 !important" }}
        >
          <div className="">
            <Row className="flex-column-reverse flex-md-row">
              <Col xs={12} md={12} className="p-0">
                <section className="faqs border-grad1">
                  <div className="asked__heading">
                    <p className="dashboard__title">
                      <span className="ms-2"> You will ask</span>
                    </p>
                  </div>
                  <div className="mobile-responsive-table">
                    <Accordion defaultActiveKey="0">
                      {myInterviewGuideData[0]?.interViewGuidData?.answeredQuestions
                        .sort(
                          (a, b) =>
                            topicOrder.indexOf(a.text.split(" - ")[0]) -
                            topicOrder.indexOf(b.text.split(" - ")[0])
                        )
                        .map((elem, index) => (
                          <Accordion.Item
                            eventKey={index}
                            style={{ background: "#1A1C1F" }}
                          >
                            <Accordion.Header>{elem?.text}</Accordion.Header>
                            <Accordion.Body style={{ background: "#1A1C1F" }}>
                              <div className="my-tabs">
                                <Tabs
                                  defaultActiveKey="answer"
                                  id="fill-tab-example"
                                  className="mb-3"
                                  fill
                                >
                                  <Tab eventKey="answer" title="Answer">
                                    <p className="para">{elem?.answer}</p>
                                  </Tab>
                                  <Tab eventKey="scorecard" title="Scorecard">
                                    <p className="para">{elem?.scoreCard}</p>
                                  </Tab>
                                </Tabs>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                    </Accordion>
                  </div>
                </section>
              </Col>
            </Row>
          </div>
        </SessionModal>
      )}
      {userProfileElem ? (
        <UserProfileMentor
          elementData={elementData}
          userprofileElemData={mySessionDataTemp}
          setUserProfileElem={setUserProfileElem}
          individualRating={individualRating}
        />
      ) : (
        <div className="mysessions__container min__height__100">
          <div className="mysessions__container__top">
            <p className="mysessions__container__top__title">
              {mySessionData?.length} Sessions
            </p>
            <div className="mysessions__container__top__container">
              <Dropdown onSelect={handleDropdownSelect}>
                <Dropdown.Toggle id="dropdown-basic" className="filter__button">
                  <img src={SortIcon} className="filter__button__image" />
                  <span className="filter__button__text">{dropdown}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                  <Dropdown.Item
                    onClick={() => setDropDown("All")}
                    className="custom__dropdown__item"
                    eventKey="All"
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Newest"
                    className="custom__dropdown__item"
                  >
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="custom__dropdown__item"
                    eventKey="Oldest"
                  >
                    Oldest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <div className="listgridview">
                        <div
                          className={`listgridview__left ${
                            isGrid ? "grid__active" : "grid__disabled"
                          }`}
                          onClick={() => setIsGrid(true)}
                        >
                          <img src={GridGreen} alt="grid button" />
                        </div>
                        <div
                          className={`listgridview__right ${
                            isGrid ? "grid__disabled" : "grid__active"
                          }`}
                          onClick={() => setIsGrid(false)}
                        >
                          <img src={ListView} alt="list button" />
                        </div>
                      </div> */}
            </div>
          </div>
          <div className="mysessions__container__bottom">
            <Row className="">
              {mySessionDataTemp.length > 0 ? (
                mySessionDataTemp?.map((elem, index) => {
                  return (
                    <Col
                      style={{ marginBottom: "20px", height: "320px" }}
                      xs={12}
                      md={isGrid ? 6 : 12}
                      lg={isGrid ? 4 : 12}
                      className="column__mysession position-relative"
                      key={index}
                    >
                      <Dropdown
                        style={{
                          padding: "10px",
                          zIndex: "100",
                          position: "absolute",
                          right: "5%",
                          top: "3%",
                        }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="dots__button"
                        >
                          <img
                            src={Dots}
                            alt="see more"
                            className="dots__button__dots"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            onClick={() => getUserInterViewGuide(elem)}
                          >
                            View guide
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            onClick={() => {
                              userdetail();
                              setElementData(elem);
                            }}
                          >
                            View User Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            onClick={() => cancelMentorSessionHandle(elem)}
                          >
                            Remove Session
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div
                        onClick={() => {
                          userdetail();
                          setElementData(elem);
                        }}
                      >
                        <MySessionCard startTime={elem?.time} heightSp={true}>
                          <div className="mysession__container__top">
                            <img
                              src={
                                elem?.userProfile?.image
                                  ? elem?.userProfile?.image
                                  : AvatarIcon
                              }
                              alt="avatar icon"
                              className="mysession__container__top__image"
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div className="mysession__container__center">
                            <h2 className="mysession__container__center__title">
                              {elem?.userData?.firstName
                                ? elem?.userData?.firstName
                                : "-----"}
                              {elem?.userData?.lastName
                                ? elem?.userData?.lastName
                                : ""}
                              {elem?.creatorId === userData?._id && (
                                <span className="pill-light-squared">
                                  Proposed
                                </span>
                              )}
                            </h2>
                            {/* time */}
                            <div className="mysession__container__center__top">
                              <div className="mysession__container__center__top__container">
                                <p>
                                  {/* 11:15am – 12:15pm, Thursday, August 17 */}
                                  {moment(
                                    elem?.mentorAvalible?.startDateTime
                                  ).format("HH:mm")}{" "}
                                  -{" "}
                                  {moment(
                                    elem?.mentorAvalible?.endDateTime
                                  ).format("HH:mm")}
                                  ,{" "}
                                  {moment(
                                    elem?.mentorAvalible?.startDateTime
                                  ).format("dddd, MMMM D")}
                                </p>
                              </div>
                            </div>
                            <div className="mysession__container__center__top">
                              <div className="mysession__container__center__top__container">
                                <img
                                  src={LocationIcon}
                                  alt="location icon"
                                  className="mysession__container__center__top__container__image"
                                />
                                <p
                                  style={{
                                    color: "#b9bdc7",
                                    fontFamily: "DM Sans",
                                  }}
                                  className="text-capitalize"
                                >
                                  {elem?.userProfile?.location
                                    ? elem?.userProfile?.location
                                    : "Pakistan"}
                                </p>
                              </div>
                              <div className="mysession__container__center__top__container">
                                <img
                                  src={GraduationIcon}
                                  alt="graduation icon"
                                  className="mysession__container__center__top__container__image"
                                />
                                <p
                                  style={{
                                    color: "#b9bdc7",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  {elem?.userProfile?.educationDetails[0]
                                    ?.degree
                                    ? elem?.userProfile?.educationDetails[0]
                                        ?.degree
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="mysession__container__center__bottom">
                              <img
                                src={UniversityIcon}
                                alt="graduation icon"
                                className="mysession__container__center__bottom__image"
                              />
                              <p>
                                {/* University of Washington */}
                                {elem?.userProfile?.educationDetails[0]
                                  ?.institution
                                  ? elem?.userProfile?.educationDetails[0]
                                      ?.institution
                                  : ""}
                              </p>
                            </div>
                            <div className="mysession__container__center__ratings">
                              {/* <Ratings /> */}
                              <Rating
                                readonly={true}
                                initialValue={individualRating(elem)}
                                allowFraction={true}
                                fillIcon={
                                  <img src={StarFull} width={16} alt="" />
                                }
                                emptyIcon={<img src={EmptyStar} width={16} />}
                                size={20}
                                fillColor="#F9A63A"
                                className="mb-2"
                              />
                              {/* <p>(12 Interviews taken, 98% Reliability)</p> */}
                              <p>
                                ({" "}
                                {elem?.relability
                                  ? elem?.relability?.booked
                                  : "0"}{" "}
                                Interview,{" "}
                                {elem?.relability?.count &&
                                elem?.relability?.booked
                                  ? (
                                      (Number(elem?.relability?.booked) /
                                        Number(elem?.relability?.count)) *
                                      100
                                    ).toFixed(0)
                                  : "0"}
                                % Relability )
                              </p>
                            </div>
                          </div>
                        </MySessionCard>
                      </div>
                      <div
                        className="mysession__container__bottom"
                        style={{
                          padding: "10px 25px 16px 16px",
                          zIndex: "100",
                          position: "absolute",
                          right: "1%",
                          left: "1%",
                          bottom: "-1%",
                        }}
                      >
                        <button
                          onClick={() => {
                            getSingleData(elem);
                            setElementData(elem);
                          }}
                          className={`join__session__enabled join__session__button`}
                        >
                          Join session
                        </button>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="nofound__container"
                >
                  <div className="nofound__container__inner">
                    <div className="nofound__container__inner__image">
                      <img src={noFound} />
                    </div>
                    <div className="nofound__container__inner__container">
                      <div className="nofound__container__inner__container__title">
                        No sessions found
                      </div>
                    </div>

                    <button
                      className="nofound__container__inner__button"
                      onClick={goToAvailability}
                    >
                      Add availability
                    </button>
                  </div>
                </div>
              )}
            </Row>
          </div>
        </div>
      )}
      <>
        <SessionModal
          show={modalShow}
          setShow={setModalShow}
          style={{ width: "670px !important", padding: "0 !important" }}
        >
          <div className="modal__content__container">
            <h2 className="modal__content__container__title">Scheduled</h2>
            {/* <div className="pill-green">Available</div> */}
          </div>
          <Row className="modal__content__container__bottom">
            <Col
              className="modal__content__container__bottom__left"
              style={{ padding: "0", paddingRight: "10px" }}
            >
              <div className="modal__content__container__bottom__left__difficulty">
                <div className="modal__content__container__bottom__left__difficulty__inner">
                  <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                    Interview guide difficulty level
                  </h2>
                  <img
                    src={LevelIcon}
                    alt="level"
                    className="modal__content__container__bottom__left__difficulty__inner__image"
                  />
                </div>
                <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                  <div className="pill-grey">
                    {elementData?.mentorAvalible?.dificultyLevel}
                  </div>
                </div>
              </div>
              <div className="modal__content__container__bottom__left__topics">
                <div className="modal__content__container__bottom__left__topics__inner">
                  <h2 className="modal__content__container__bottom__left__topics__inner__title">
                    Topics
                  </h2>
                  <img
                    src={DocumentIcon}
                    alt=""
                    className="modal__content__container__bottom__left__topics__inner__image"
                  />
                </div>
                <div className="modal__content__container__bottom__left__topics__pills">
                  {[
                    "Fit",
                    "Brainteasers",
                    "Accounting",
                    "Valuation",
                    "DCF",
                    "LBO",
                    "Merger Model",
                  ].map((item) => (
                    <div className="pill-grey">{item}</div>
                  ))}
                </div>
              </div>
              <div className="modal__content__container__bottom__left__bottom">
                <div
                  className="modal__content__container__bottom__left__bottom__top"
                  onClick={showGuideModal}
                >
                  <p className="modal__content__container__bottom__left__bottom__top__title">
                    Interview guide - Candidate
                  </p>
                  <img
                    src={EyeIcon}
                    alt=""
                    className="modal__content__container__bottom__left__bottom__top__image"
                  />
                </div>
                {singleData?.mentorAvalible?.cv && (
                  <a href={singleData?.mentorAvalible?.cv} target="_blank">
                    <div
                      className="modal__content__container__bottom__left__bottom__top"
                      // onClick={showGuideModal}
                    >
                      <p className="modal__content__container__bottom__left__bottom__top__title">
                        View Cover Letter or CV
                      </p>
                      <img
                        src={EyeIcon}
                        alt=""
                        className="modal__content__container__bottom__left__bottom__top__image"
                      />
                    </div>
                  </a>
                )}
              </div>
            </Col>
            {/* <Col md={6} style={{ padding: "0", paddingLeft: "10px" }}>
              <MySessionCard>
                <div className="modal__card__container">
                  <div className="modal__card__container__top">
                    <img
                      src={AvatarIcon}
                      alt="avatar icon"
                      className="modal__card__container__top__image"
                    />
                    <p className="modal__card__container__top__text">
                      See Profile
                    </p>
                  </div>
                </div>
                <div className="modal__card__container__bottom">
                  <h2 className="modal__card__container__bottom__title">
                    Angelo Doe
                  </h2>
                  <div className="modal__card__container__bottom__container">
                    <div className="modal__card__container__bottom__container__inner">
                      <img
                        src={LocationIcon}
                        alt="location icon"
                        className="modal__card__container__bottom__container__inner__image"
                      />
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="dots__button"
                        >
                          <img
                            src={Dots}
                            alt="see more"
                            className="dots__button__dots"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            // href=""
                            onClick={getUserInterViewGuide}
                          >
                            See Details
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            // href=""
                            onClick={rescheduleSession}
                          >
                            Reschedule Session
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            href=""
                          >
                            Cancel Session
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <p>Portugal</p>
                    </div>
                    <div className="modal__card__container__bottom__container__inner">
                      <img
                        src={GraduationIcon}
                        alt="graduation icon"
                        className="modal__card__container__bottom__container__inner__image"
                      />
                      <p>MBA</p>
                    </div>
                    <div className="mysession__container__bottom">
                      {elem?.mentorAvalible?.booked ?
                        (
                          <button
                            onClick={() => getSingleData(elem)}
                            className={`join__session__button ${!isActive
                              ? "join__session__disabled"
                              : "join__session__enabled"
                              }`}
                          >
                            {!isActive !== ""
                              ? `Session starts ${moment(
                                elem?.mentorAvalible?.startDateTime
                              ).format("YYYY-MM-DD HH:mma A")}`
                              : "Join Session"}
                          </button>
                        ) : (
                          <button
                            onClick={() => getSingleData(elem)}
                            className={`join__session__button ${!isActive
                              ? "join__session__disabled"
                              : "join__session__enabled"
                              }`}
                          >
                            {!isActive !== ""
                              ? `Session starts ${moment(
                                elem?.creatorAvalible?.startDateTime
                              ).format("YYYY-MM-DD HH:mma A")}`
                              : "Non Booked"}
                          </button>
                        )}
                    </div>
                  </MySessionCard>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
      {/* <SessionModal
        show={modalShow}
        setShow={setModalShow}
        style={{ width: "670px !important", padding: "0 !important" }}
      >
        <div className="modal__content__container">
          <h2 className="modal__content__container__title">Scheduled</h2>
          <div className="pill-green">Available</div>
        </div>
        <Row className="modal__content__container__bottom">
          <Col
            md={6}
            className="modal__content__container__bottom__left"
            style={{ padding: "0", paddingRight: "10px" }}
          >
            <div className="modal__content__container__bottom__left__difficulty">
              <div className="modal__content__container__bottom__left__difficulty__inner">
                <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                  Difficulty level
                </h2>
                <img
                  src={LevelIcon}
                  alt="level"
                  className="modal__content__container__bottom__left__difficulty__inner__image"
                />
              </div>
              <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                <div className="pill-grey">Intermediate</div>
              </div>
            </div>
            <div className="modal__content__container__bottom__left__topics">
              <div className="modal__content__container__bottom__left__topics__inner">
                <h2 className="modal__content__container__bottom__left__topics__inner__title">
                  Topics
                </h2>
                <img
                  src={DocumentIcon}
                  alt=""
                  className="modal__content__container__bottom__left__topics__inner__image"
                />
              </div>
              <div className="modal__content__container__bottom__left__topics__pills">
                {[
                  "Fit",
                  "Brainteasers",
                  "Accounting",
                  "Valuation",
                  "DCF",
                  "LBO",
                  "Merger Model",
                ].map((item) => (
                  <div className="pill-grey">{item}</div>
                ))}
              </div>
            </div>
            <div className="modal__content__container__bottom__left__bottom">
              <div className="modal__content__container__bottom__left__bottom__top">
                <p className="modal__content__container__bottom__left__bottom__top__title">
                  Interview guide - Interviewer
                </p>
                <img
                  src={EyeIcon}
                  alt=""
                  className="modal__content__container__bottom__left__bottom__top__image"
                />
              </div>
              <div className="modal__content__container__bottom__left__bottom__top">
                <p className="modal__content__container__bottom__left__bottom__top__title">
                  Interview guide - Candidate
                </p>
                <img
                  src={LockIcon}
                  alt=""
                  className="modal__content__container__bottom__left__bottom__top__image"
                />
              </div>
            </div>
          </Col>
          <Col md={6} style={{ padding: "0", paddingLeft: "10px" }}>
            <MySessionCard>
              <div className="modal__card__container">
                <div className="modal__card__container__top">
                  <img
                    src={AvatarIcon}
                    alt="avatar icon"
                    className="modal__card__container__top__image"
                  />
                  <p className="modal__card__container__top__text">
                    See Profile
                  </p>
                </div>
              </div>
              <div className="modal__card__container__bottom">
                <h2 className="modal__card__container__bottom__title">
                  Angelo Doe
                </h2>
                <div className="modal__card__container__bottom__container">
                  <div className="modal__card__container__bottom__container__inner">
                    <img
                      src={LocationIcon}
                      alt="location icon"
                      className="modal__card__container__bottom__container__inner__image"
                    />
                    <p>Portugal</p>
                  </div>
                  <div className="modal__card__container__bottom__bottom">
                    <img
                      src={UniversityIcon}
                      alt="graduation icon"
                      className="modal__card__container__bottom__bottom_image"
                    />
                    <p>London School of Business</p>
                  </div>
                  <div className="modal__card__container__bottom__ratings">
                    <Ratings />
                    <p className="modal__card__container__bottom__ratings__text">
                      (12 Interviews taken, 98% Reliability)
                    </p>
                  </div>
                </div>
              </MySessionCard>
            </Col> */}
          </Row>
          <div className="modal__content__container__footer">
            <button className="btn-green" onClick={handleJoinMeeting}>
              Join session
            </button>
          </div>
        </SessionModal>
      </>
    </>
  );
};

export default MySessions;
