import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  sessionData: {},
  idToSend: "",
  isMentor: false,
};
const meetingSlice = createSlice({
  name: "meetingData",
  initialState,
  reducers: {
    setSessionData: (state, action) => {
      state.sessionData = action.payload.sessionData;
      state.idToSend = action.payload.idToSend;
      state.isMentor = action.payload.isMentor;
    },
  },
});
export const { setSessionData } = meetingSlice.actions;
export default meetingSlice.reducer;
