import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import NavLogo from "../../../assets/8.svg";
import Notification from "../../../assets/notification.svg";
import Profile from "../../../assets/Avatar.png";
import Search from "../../../assets/search.svg";
import MovieIcon from "../../../assets/video.svg";
import StarGreen from "../../../assets/star-green.svg";
import MenuIcon from "../../../assets/menu.svg";
import SaveSessionsIcon from "../../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../../assets/save-my-sessions-green.svg";
import VideoGreen from "../../../assets/video.svg";
import VideoGrey from "../../../assets/video-grey.svg";
// import MyAvailability from "./../MyAvailability/MyAvailability";
// import MySessions from "./MySession/MySession";
import wallet from "../../../assets/wallet.svg";
import walletGrey from "../../../assets/walletGrey.svg"
import { useHistory } from "react-router-dom";
// import "./navbar.scss";  

export default function MentorNavbar() {
     
    const history=useHistory()
    const [isMobileActive, setIsMobileActive] = useState(false);
    const location = useLocation();
    const userData = useSelector((state) => state.signin.signInData.data);
    const Logout = () => {
        localStorage.clear()
        // window.location.assign('/')
        history.push('/login')
    }

    const [selected, setSelected] = useState(0);
    const setSelectedComponent = (asd) => {
      setSelected(asd);
    };

    return (
        <>
            <div className="Wrapper ">
                <div className="container__custom navbar__container">
                    <div className="Nav__Brand ms-4 mt-4 mb-4">
                        <div className="nav__logo">
                            <a >

                            <img  src={NavLogo} alt="Logo" />

                            </a>
                        </div>
                    </div>
                    <div className="dashboardbar__container__mentor__main">
                        <div className="dashboardbar__container__mentor">
                            <ul className="dashboardbar__container__mentor__nav">
                                <li className="dashboardbar__container__mentor__nav__item  dashboardbar__container__mentor__nav__item__first">
                                    <div
                                        onClick={() => {
                                            setSelectedComponent(0);
                                        }}
                                        className={`dashboardbar__container__mentor__nav__item__link ${selected === 0 ? "active" : ""
                                            }`}
                                    >
                                        <img
                                            src={selected === 0 ? VideoGreen : VideoGrey}
                                            className="dashboardbar__container__mentor__nav__item__link__image"
                                        />
                                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                                            My availability
                                        </p>
                                    </div>
                                </li>
                                <li className="dashboardbar__container__mentor__nav__item">
                                    <div
                                        onClick={() => {
                                            setSelectedComponent(1);
                                        }}
                                        className={`dashboardbar__container__mentor__nav__item__link ${selected === 1 ? "active" : ""
                                            }`}
                                    >
                                        <img
                                            src={selected === 1 ? SaveSessionsGreen : SaveSessionsIcon}
                                            className="dashboardbar__container__mentor__nav__item__link__image"
                                        />
                                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                                            My sessions
                                        </p>
                                    </div>
                                </li>
                                <li className="dashboardbar__container__mentor__nav__item">
                                    <div
                                        onClick={() => {
                                            setSelectedComponent(2);
                                        }}
                                        className={`dashboardbar__container__mentor__nav__item__link ${selected === 2 ? "active" : ""
                                            }`}
                                    >
                                        <img
                                            src={selected === 2 ? wallet : walletGrey}
                                            className="dashboardbar__container__mentor__nav__item__link__image"
                                        />
                                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                                            My earnings
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav_Right__container">
                        {userData?._id ? (
                            <div className="nav__Right">
                                {/* <img src={Search} alt="Logo" /> */}
                                <Dropdown className="navbar__notification">
                                    <Dropdown.Toggle className="navbar__notification__dropdown">
                                        <img src={Notification} alt="Logo" className="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                        style={{ background: "black", color: "white" }}
                                        className="navbar__notification__dropdown__main"
                                    >
                                        <h2 className="navbar__notification__dropdown__title">
                                            Notifications
                                        </h2>
                                        <div style={{ height: "500px" }}>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container navbar__notification__item__green"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={MovieIcon}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            Your session with <span>John Doe</span> starts in
                                                            30 minutes
                                                            <br /> you can preview John’s interview guide now.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            View my sessions
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="dot-green" />
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container navbar__notification__item__green"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={MovieIcon}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            <span>John Doe</span> has accepted your proposed
                                                            session,
                                                            <br /> scheduled for 11:00 AM - 12:30PM (GMT+1).
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            View my sessions
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="dot-green" />
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={StarGreen}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            You just earned a <span>Top Subscriber</span>{" "}
                                                            recognition.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            see what this means
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>{/* <div className="dot-green" /> */}</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={StarGreen}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            You just earned a <span>Top Subscriber</span>{" "}
                                                            recognition.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            see what this means
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>{/* <div className="dot-green" /> */}</div>
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to="/profilesettings">
                                    <img src={Profile} alt="Logo" width="40px" height="40px" />
                                </Link>
                                <div className="nav__login">
                                    <button className='nav__login__button' onClick={Logout}>
                                        Log out
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="nav__login">
                                <Link
                                    to="/signup"
                                    className={`nav__Right__navigations__item__link`}
                                >
                                    Sign up
                                </Link>
                                <Link
                                    to="/signup"
                                    className={`nav__Right__navigations__item__link nav__login__button`}
                                >
                                    Log in
                                </Link>
                            </div>
                        )}
                    </div>

                    
                    <div className="mobile__nav__container">
                        {userData?._id && (
                            <div className="nav__Right">
                                {/* <img src={Search} alt="Logo" /> */}
                                <Dropdown className="navbar__notification">
                                    <Dropdown.Toggle className="navbar__notification__dropdown">
                                        <img src={Notification} alt="Logo" className="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                        style={{ background: "black", color: "white" }}
                                        className="navbar__notification__dropdown__main"
                                    >
                                        <h2 className="navbar__notification__dropdown__title">
                                            Notifications
                                        </h2>
                                        <div style={{ height: "500px" }}>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container navbar__notification__item__green"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={MovieIcon}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            Your session with <span>John Doe</span> starts in
                                                            30 minutes
                                                            <br /> you can preview John’s interview guide now.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            View my sessions
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="dot-green" />
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container navbar__notification__item__green"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={MovieIcon}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            <span>John Doe</span> has accepted your proposed
                                                            session,
                                                            <br /> scheduled for 11:00 AM - 12:30PM (GMT+1).
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            View my sessions
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="dot-green" />
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={StarGreen}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            You just earned a <span>Top Subscriber</span>{" "}
                                                            recognition.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            see what this means
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>{/* <div className="dot-green" /> */}</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                style={{ color: "white" }}
                                                href=""
                                                className="navbar__notification__dropdown__container"
                                            >
                                                <div className="navbar__notification__dropdown__container__inner">
                                                    <img
                                                        src={StarGreen}
                                                        className="navbar__notification__dropdown__container__inner__image"
                                                    />
                                                    <div>
                                                        <p className="navbar__notification__dropdown__container__inner__message">
                                                            You just earned a <span>Top Subscriber</span>{" "}
                                                            recognition.
                                                        </p>
                                                        <p className="navbar__notification__dropdown__container__inner__type">
                                                            see what this means
                                                        </p>
                                                    </div>
                                                    <p className="navbar__notification__dropdown__container__inner__time">
                                                        10mins Ago
                                                    </p>
                                                </div>
                                                <div>{/* <div className="dot-green" /> */}</div>
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to="/profilesettings">
                                    <img src={Profile} alt="Logo" width="40px" height="40px" />
                                </Link>
                            </div>
                        )}
                        <img
                            className="me-4 hamburger__icon"
                            src={MenuIcon}
                            alt="hamburger"
                            onClick={() => setIsMobileActive(!isMobileActive)}
                        />
                    </div>
                    {isMobileActive && (
                        <ul
                            className={`nav__mobile__navigations ${isMobileActive && "nav__mobile__navigations__shown"
                                }`}
                        >
                            <li className="nav__mobile__navigations__item">
                                <Link
                                    to="/"
                                    onClick={() => setIsMobileActive(!isMobileActive)}
                                    className={`nav__mobile__navigations__item__link ${location.pathname === "/" ? "active" : ""
                                        }`}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav__mobile__navigations__item">
                                <Link
                                    onClick={() => setIsMobileActive(!isMobileActive)}
                                    to="/interview"
                                    isMobileActive
                                    className={`nav__mobile__navigations__item__link ${location.pathname === "/interview" ? "active" : ""
                                        }`}
                                >
                                    Interview
                                </Link>
                            </li>
                            <li className="nav__mobile__navigations__item">
                                <Link
                                    onClick={() => setIsMobileActive(!isMobileActive)}
                                    to="/network"
                                    className={`nav__mobile__navigations__item__link ${location.pathname === "/network" ? "active" : ""
                                        }`}
                                >
                                    Network
                                </Link>
                            </li>
                            <li className="nav__mobile__navigations__item">
                                <div
                                    onClick={() => setIsMobileActive(!isMobileActive)}
                                    // to="/forum"
                                    className={`nav__mobile__navigations__item__link`}
                                >
                                    Forum
                                </div>
                            </li>
                            <li className="nav__mobile__navigations__item">
                                <div
                                    // to="/resources"
                                    // className={`nav__Right__navigations__item__link ${
                                    //   location.pathname === "/resources" ? "active" : ""
                                    // }`}
                                    onClick={() => setIsMobileActive(!isMobileActive)}
                                    className={`nav__mobile__navigations__item__link`}
                                >
                                    Resources
                                </div>
                            </li>
                            <li className="nav__mobile__navigations__item">
                                <div
                                    // to="/contact"
                                    // className={`nav__Right__navigations__item__link ${
                                    //   location.pathname === "/contact" ? "active" : ""
                                    // }`}
                                    className={`nav__mobile__navigations__item__link`}
                                >
                                    Contact
                                </div>
                            </li>
                            {!userData?._id && (
                                <div className="nav__login__mobile">
                                    <Link
                                        onClick={() => setIsMobileActive(!isMobileActive)}
                                        to="/signup"
                                        className={`nav__Right__navigations__item__link`}
                                    >
                                        Sign up
                                    </Link>
                                    <Link
                                        onClick={() => setIsMobileActive(!isMobileActive)}
                                        to="/signup"
                                        className={`nav__Right__navigations__item__link nav__login__button`}
                                    >
                                        Log in
                                    </Link>
                                </div>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}
