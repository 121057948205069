import { createSlice } from "@reduxjs/toolkit";

import {mentorSignUpEmail} from '../middleware/mentor'


const initialState = {
  loading: false,
  error: "",
  signInDataForMentor: [],
};
const singUpSliceForMentorEmail = createSlice({
  name: "mentorSignUpEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mentorSignUpEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mentorSignUpEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.signInDataForMentor = action.payload;
    });
    builder.addCase(mentorSignUpEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default singUpSliceForMentorEmail.reducer;
