import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";

export const createSession = createAsyncThunk("createSession", async (data) => {
  try {
    const res = await client.post(`/api/proposeSession`, {
      difficultyLevel: data?.difficultyLevel,
      creatorId: data?.creatorId,
      objective: data?.objecive,
      timeSlot:data?.timeSlot,
    },{ headers: { authorization: `${data?.token}` } });
    return { status: res.status, data: res.data };
  } catch (error) {
    return { message: error.response.data.error, status: error.response.status, };
  }
});
