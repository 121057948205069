import { useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { signinLinkedAuth } from "../service/redux/middleware/signin";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import linkdin from "../../src/assets/linkdinIcon.svg";

const LinkedInButton = ({ signUpflag, loginFlag, children }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { linkedInLogin } = useLinkedIn({
    clientId: "77x3fecsx776tq",
    redirectUri: `http://localhost:3000/linkedin`,
    // redirectUri: `https://app.fast-track.community/linkedin`,
    scope: "openid,profile,email",
    onSuccess: (code) => {
      if (!loading) {
        try {
          dispatch(signinLinkedAuth(code)).then((res) => {

            if (res?.payload?.status === 200) {
              setLoading(false);
              if (res?.payload?.data?.role === 0) {
                if (res?.payload?.data?.profileUpdated) {
                  SuccessToast("Log in successfully");
                  history.push("/interviewdashboard");
                  // const timer = setTimeout(() => {
                  //   window.location.reload();
                  // }, 1000);
                  // return () => clearTimeout(timer);
                } else {
                  SuccessToast("Profile Update Your Profile to Continue.");
                  history.push("/personalInfo");
                  // const timer = setTimeout(() => {
                  //   window.location.reload();
                  // }, 1000);
                  // return () => clearTimeout(timer);
                }
              } else {
                if (res?.payload?.data?.profileUpdated) {
                  history.push("/mentordashboard");
                  // window.location.reload();
                }
              }
            } else {
              ErrorToast(res?.payload?.message);
              setLoading(false);
            }
          });
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
      }
    },

    onError: (error) => {
      console.log("error", error);
    },
  });

  const handleButtonClick = () => {
    if (signUpflag) {
      linkedInLogin();
    } else if (loginFlag) {
      linkedInLogin();
    }
  };

  return (
    <button className="signup__session__button11" onClick={handleButtonClick}>
      <img src={linkdin} alt="" className="" />
      <p className="signup__session__button__text">
        {/* Sign up with LinkedIn */}
        {children}
      </p>
    </button>
  );
};

export default LinkedInButton;
