import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Ratings from "../../../Custom/Ratings/Ratings";
//
//
import ArrowLeft from "../../../../assets/arrow-left.svg";
import AvatarIcon from "../../../../assets/Avatar.png";
import LocationIcon from "../../../../assets/location.svg";
import LanguageSquare from "../../../../assets/language-square.svg";
import DocumentIcon from "../../../../assets/document.svg";
import LevelIcon from "../../../../assets/levelgray.svg";
import ProfileModalIcon from "../../../../assets/profileModalIcon.svg";
import DiamondGrey from "../../../../assets/diamond-grey.svg";
import TaskIcon from "../../../../assets/taskIcon.svg";
import UniversityIcon from "../../../../assets/university.svg";
import GraduationIcon from "../../../../assets/graduation.svg";
import RisingStar from "../../../../assets/magic-star.svg";
//
// import "./userprofile.scss";
import CalendarSlot from "../../../Custom/Calendar/CalendarSlot";
//
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../../assets/star.svg";
import FullStar from "../../../../assets/star-full.svg";
import StarFull from "../../../../assets/star-yellow.svg";
const UserProfileMentor = ({
  setUserProfileElem,
  p,
  setP,
  elementData,
  individualRating,
  avialable = false,
}) => {
  const [slotToCalendar, setSlotToCalendar] = useState([
    elementData?.mentorAvalible,
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BacktoMysession = () => {
    if (p) {
      setP(false);
    } else {
      setUserProfileElem(false);
    }
  };

  return (
    <div className="userprofile min__height__mentor__profile">
      <div className="userprofile__top">
        <div
          className="userprofile__top__container"
          onClick={() => BacktoMysession()}
        >
          <img src={ArrowLeft} alt="left arrow" />
          <p>Back to {avialable ? "Availability" : "My Sessions"} </p>
        </div>
        {/* <button className="btn-green userprofile__top__button">Book</button> */}
      </div>
      {/* rows */}
      <Row className="userprofile__bottom min__height__mentor__profile">
        <Col
          md={8}
          style={{ padding: "0" }}
          className="userprofile__bottom__left"
        >
          {/* card */}
          <div className="userprofile__bottom__left__top">
            <div className="userprofile__bottom__left__top__container">
              <img
                // src={AvatarIcon}
                src={elementData?.userProfile?.image}
                alt="avatar icon"
                className="userprofile__bottom__left__top__container__image"
              />
              <div className="userprofile__bottom__left__top__container__right">
                <h2>
                  {elementData?.userData?.firstName}{" "}
                  {elementData?.userData?.lastName}{" "}
                </h2>
                {/* <p className="userprofile__bottom__left__top__container__right__description">
                  10 years at Fast Track | Ex Interviewer | Will provide you
                  with actionable guidance and tips to perform
                  {mentorAvalible.mentorProfiles[0].background}
                  {elementData?.userProfile?.professionalDetails[0].position}
                </p> */}
                <div className="userprofile__bottom__left__top__container__right__container">
                  <div className="userprofile__bottom__left__top__container__right__container__inner">
                    <img src={LocationIcon} alt="location" />
                    <p>{elementData?.userProfile.location}</p>
                  </div>
                  <div className="userprofile__bottom__left__top__container__right__container__inner d-md-none">
                    <img src={GraduationIcon} alt="GraduationIcon" />
                    <p>
                      {/* MBA */}
                      {elementData?.userProfile?.educationDetails[0]?.degree}
                      {/* {mentorAvalible.mentorProfiles[0].location} */}
                    </p>
                  </div>
                  <div className="userprofile__bottom__left__top__container__right__container__inner d-md-none">
                    <img src={UniversityIcon} alt="university" />
                    <p>
                      {
                        elementData?.userProfile?.educationDetails[0]
                          ?.institution
                      }
                      {/* {mentorAvalible.mentorProfiles[0].location} */}
                    </p>
                  </div>
                  {/* <div className="userprofile__bottom__left__top__container__right__container__inner">
                    <img src={LanguageSquare} alt="language square" />
                    <p>
                      {elementData?.userProfile?.language}

                    </p>
                  </div> */}
                </div>
                <div className="userprofile__bottom__left__top__container__right__ratings">
                  {/* <Ratings /> */}
                  <Rating
                    readonly={true}
                    initialValue={individualRating(elementData)}
                    allowFraction={true}
                    fillIcon={<img src={StarFull} width={16} alt="" />}
                    emptyIcon={<img src={EmptyStar} width={16} alt="" />}
                    size={20}
                    fillColor="#F9A63A"
                    className="mb-2"
                  />
                  <p>
                    {" "}
                    ({" "}
                    {elementData?.relability
                      ? elementData?.relability?.booked
                      : "0"}{" "}
                    {" Interviews "})
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* details */}
          <div className="userprofile__bottom__left__bottom">
            <div className="userprofile__bottom__left__bottom__services">
              <div className="userprofile__headings">
                <p>Background</p>
                <img src={ProfileModalIcon} />
              </div>
              <div className="userprofile__bottom__left__bottom__services__plans">
                <p className="userprofile__bottom__left__bottom__services__plans__description">
                  {elementData?.userProfile?.background}
                </p>
              </div>
            </div>
            {/* <div className="userprofile__bottom__left__bottom__goals">
              <div className="userprofile__headings">
                <p>Goals & Objectives</p>
                <img src={TaskIcon} />
              </div>
              <div className="userprofile__bottom__left__bottom__goals__container">
                <ul className="userprofile__bottom__left__bottom__goals__container__item">
                  {elementData?.objective?.map((items, index) => {
                    return (
                      <li className="userprofile__bottom__left__bottom__goals__container__item__title">
                        {items}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}
            <div className="userprofile__bottom__left__bottom__inner">
              <div className="userprofile__headings">
                <p>Topics</p>
                <img src={DocumentIcon} />
              </div>
              <div className="userprofile__bottom__left__bottom__inner__pillsnoh">
                {[
                  "Fit",
                  "Brainteasers",
                  "Accounting",
                  "Valuation",
                  "DCF",
                  "LBO",
                  "Merger Model",
                ].map((item, index) => (
                  <div
                    className="userprofile__bottom__left__bottom__inner__pillsnoh__pill"
                    key={index}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="userprofile__bottom__left__bottom__inner">
              <div className="userprofile__headings">
                <p>Difficulty level</p>
                <img src={LevelIcon} />
              </div>
              <div className="pill-grey">{elementData?.difficultyLevel}</div>
            </div> */}
            <>
              <div className="userprofile__bottom__left__bottom__education">
                <div className="userprofile__bottom__left__bottom__education__heading">
                  <p>Education</p>
                </div>

                <div className="userprofile__bottom__left__bottom__education__container">
                  {elementData?.userProfile?.educationDetails.map((elem) => {
                    return (
                      <div className="userprofile__bottom__left__bottom__education__container__item">
                        <p className="userprofile__bottom__left__bottom__education__container__item__heading">
                          {elem.institution}
                        </p>
                        <p className="userprofile__bottom__left__bottom__education__container__item__date">
                          {new Date(elem.startDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                            }
                          )}
                          {" - "}
                          {elem?.currentlyStuding
                            ? "Current"
                            : new Date(elem.endDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                }
                              )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="userprofile__bottom__left__bottom__experience">
                <div className="userprofile__bottom__left__bottom__experience__heading">
                  <p>Experience</p>
                </div>
                <div className="userprofile__bottom__left__bottom__experience__container">
                  {elementData?.userProfile?.professionalDetails.map((exp) => {
                    return (
                      <div className="userprofile__bottom__left__bottom__experience__container__item">
                        <p className="userprofile__bottom__left__bottom__experience__container__item__title">
                          {exp.position} , {exp.company}
                        </p>
                        <div className="userprofile__bottom__left__bottom__experience__container__item__inner">
                          <p>
                            {" "}
                            {new Date(exp.startDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                              }
                            )}
                            {" - "}
                            {exp?.currentlyEmployed
                              ? "Current"
                              : new Date(exp.endDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                  }
                                )}
                          </p>
                          {/* <img src={BiDot} alt="bi dot" /> */}
                          {/* <p>Portugal</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* <div className="userprofile__bottom__left__bottom__inner">
              <div className="userprofile__headings">
                <p>Recognitions</p>
                <img src={DiamondGrey} alt="gray diamond" />
              </div>
              <div className="userprofile__bottom__left__bottom__inner__pills">
                <div className="userprofile__bottom__left__bottom__inner__pill pill-grey">
                  <img src={RisingStar} alt="magic star" />
                  <p>Hardened</p>
                </div>
              </div>
            </div> */}
          </div>
        </Col>
        <Col md={4} className="userprofile__bottom__right">
          <div className="userprofile__bottom__right__right">
            <CalendarSlot singleCalendarData={slotToCalendar} />
          </div>
          {/* {elementData?.mentorAvalible[0]?.cv && ( */}
          <div className="ProfessionalExpDiv">
            <a href={elementData?.mentorAvalible?.cv} target="_blank">
              <label htmlFor="cvupload" className="w-100 mb-0">
                <div
                  // className={`${cv.length > 0
                  //   ? "resume__button__success"
                  //   : "resume__button"
                  //   }`}
                  className={`resume__button__success`}
                >
                  View Cover Letter or CV
                </div>
              </label>
            </a>
          </div>
          {/* )} */}
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileMentor;
