import React from "react";
import "./ScheduleSlotButton.scss";
import AddIcon from "../../../../assets/add.svg";

const ScheduleSlotButton = () => {
  return (
    <button className="schedule__button">
      <img
        src={AddIcon}
        alt="Add Schedule"
        className="schedule__button__image"
      />
      <span className="schedule__button__text">Propose session</span>
    </button>
  );
};

export default ScheduleSlotButton;
