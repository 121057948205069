import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Ratings from "../../../Custom/Ratings/Ratings";
import Calendar from "../../../Custom/Calendar/Calendar";
import Payment from "../Payment/Payment";
//
//
import ArrowLeft from "../../../../assets/arrow-left.svg";
import AvatarIcon from "../../../../assets/avatar.svg";
import LocationIcon from "../../../../assets/location.svg";
import LanguageSquare from "../../../../assets/language-square.svg";
import TaskIcon from "../../../../assets/taskIcon.svg";
import Briefcase from "../../../../assets/briefcase.svg";
import BiDot from "../../../../assets/bi_dot.svg";
import Graduation from "../../../../assets/graduation.svg";
import InfoCircle from "../../../../assets/info-circle.svg";

import DiamondGrey from "../../../../assets/diamond-grey.svg";
import RisingStar from "../../../../assets/magic-star.svg";
//
import { useHistory } from "react-router-dom";
import "./Book.scss";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast, SuccessToast } from "../../../MyToaster";
import { bookNetworkSession } from "../../../../service/redux/middleware/network";
import ScreenLoader from "../../../Loader/ScreenLoader";
import { Rating } from "react-simple-star-rating";
//simple gold rating
import EmptyStar from "../../../../assets/star.svg";
import FullStar from "../../../../assets/star-full.svg";
import StarFull from "../../../../assets/star-yellow.svg";
import RatedStar from "../../../../assets/star-rated.svg";

//reviewRtings
import FullStargreen from "../../../../assets/star-rating-full.svg";
import EmptyStarGreen from "../../../../assets/star-rating-empty.svg";
import cvIcon from "../../../../assets/cvIcon.svg";
import { API_URL, client } from "../../../../service/client";

import { getMentorRatings } from "../../../../service/redux/middleware/rating";
import MyLoader from "../../../Loader/MyLoader";
import LoaderSmall from "../../../Loader/Loadersmall";

const Book = ({
  setShowSession,
  singleData,
  selectedPricing,
  individualRating,
}) => {
  //ratings
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [ratings, setRatings] = useState([]);
  const [ratingsTemp, setRatingsTemp] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [calendarReturn, setCalendarReturn] = useState({});
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  // const handleBookClick = () => {
  //   if (calendarReturn?.selectedtime?._id) {
  //     setShowPayment(true);
  //   } else {
  //     ErrorToast("Please select a date.");
  //   }
  // };

  const [loader, setLoader] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  //
  const handleSelectDifficulty = (selected) => {
    setDifficultyLevel(selected);
  };
  //
  const handleSelectChangeForDifficulty = (event) => {
    setDifficultyLevel(event.target.value);
  };

  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      // Convert the stored string back to a boolean
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);
  const BookedMySession = (elem) => {
    if (userData?._id && isMentor == false) {
      if (calendarReturn.selectedtime?._id) {
        if (elem?.selectedSession !== "Interview Practice") {
          if (cv !== "") {
            let priceSend = elem?.mentorPricing[0]?.[elem?.selectedSession];
            let data = {
              price: priceSend,
              meetingId: calendarReturn?.selectedtime?._id,
              id: elem?.mentorId,
              bookedby: userData?._id,
              dificultyLevel: difficultyLevel,
              sessionType: elem?.selectedSession,
              currency: elem?.mentorPricing[0]?.currency,
              token: token,
            };

            if (cv !== "") {
              data = {
                ...data,
                cv: cv,
              };
            }
            setLoader(true);
            dispatch(bookNetworkSession(data)).then((res) => {
              if (res?.payload?.status === 200) {
                setLoader(false);

                // SuccessToast('Your Slot Booked Successfully');
                window.open(res?.payload?.data, "_self");
                // window.location.reload();
              } else {
                setLoader(false);
                ErrorToast(res?.payload?.message);
              }
            });
          } else {
            ErrorToast("Please upload your cv");
          }
        } else {
          if (difficultyLevel !== "") {
            let priceSend = elem?.mentorPricing[0]?.[elem?.selectedSession];
            let data = {
              price: priceSend,
              meetingId: calendarReturn?.selectedtime?._id,
              id: elem?.mentorId,
              bookedby: userData?._id,
              dificultyLevel: difficultyLevel,
              sessionType: elem?.selectedSession,
              currency: elem?.mentorPricing[0]?.currency,
              token: token,
            };
            setLoader(true);
            dispatch(bookNetworkSession(data)).then((res) => {
              if (res?.payload?.status === 200) {
                setLoader(false);

                // SuccessToast('Your Slot Booked Successfully');
                // window.location.href(res?.payload?.data, "_self");
                window.location.href = res?.payload?.data;
                // window.location.reload();
                // window.open(res?.payload?.data);
              } else {
                setLoader(false);
                ErrorToast(res?.payload?.message);
              }
            });
          } else {
            ErrorToast(
              "Please choose your interview guide’s difficulty level."
            );
          }
        }
      } else {
        ErrorToast("Please select a time slot.");
      }
    } else {
      ErrorToast("Please log in to book session.");
      history.push("/login");
    }
  };

  const handlePaymentReturn = () => {
    setShowPayment(false);
    setCalendarReturn({});
  };

  const [filteredValues, setFilteredValues] = useState([]);

  useEffect(() => {
    // Filter and store the values of the "coverLetter" key in a new state
    const coverLetterValues = singleData?.mentorPricing?.map(
      (mentor) => mentor.mentorPricing?.selectedPricing
    );
    setFilteredValues(coverLetterValues);
  }, []);

  // const options = {

  //   // Fully customizable with appearance API.
  //   appearance: {
  //     theme: "Stripe", //Flat Night Minimal Bubblegum [Ninety Five] [Dark Blue]
  //     variables: {
  //       fontFamily: "Ideal Sans, system-ui, sans-serif",
  //       fontSizeBase: "14px",
  //       spacingUnit: "2px",
  //       borderRadius: "8px",
  //       colorPrimary: "#b9bdc7",
  //       colorBackground: "#2E3033",
  //       colorText: "#b9bdc7",
  //       colorDanger: "#ff0033",

  //       // See all possible variables below
  //       // colorPrimaryText:"#05f7ff"
  //       spacingGridColumn:"16px",
  //       spacingGridRow:"28px"
  //     },
  //     rules: {
  //       ".Label": {
  //         marginBottom: "4px",
  //       },
  //       ".Input":{
  //         padding:"16px",
  //         border:"none"
  //       }
  //     },
  //   },
  // };
  //
  //irfan raating copy
  useEffect(() => {
    setLoader(true);
    dispatch(getMentorRatings(singleData?.mentorId)).then((res) => {
      if (res?.payload?.status === 200) {
        setRatings(res?.payload?.data[0]?.rating);
        setRatingsTemp(res?.payload?.data[0]);
      } else {
        console.log("Error getting ratings for " + res?.payload?.message);
      }
      setLoader(false);
    });
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = ratings?.slice(startIndex, endIndex);
  const handleAddButtonClick = () => {
    if (endIndex < ratings?.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  //
  //

  const gettingTopOrRising = (elem) => {
    const reliabilityData = elem?.relability[0];
    const ratings = elem?.mentorRattings;
    const ratingAftDiv =
      Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
    const isHighrated = ratingAftDiv >= 4.5;
    let IsHighRatedReturn = "";
    if (isHighrated) {
      IsHighRatedReturn = () => {
        return (
          <div className="network__book__bottom__left__bottom__recognitions__container__item pill-grey">
            <img src={RatedStar} alt="magic star" />
            <p>High Rated</p>
          </div>
        );
      };
    }
    let isTop = "";
    // Rating > 4 & interviews > 5
    if (ratingAftDiv >= 4 && reliabilityData?.booked >= 5) {
      isTop = "Top Coach";
    } else if (ratingAftDiv >= 4 && reliabilityData?.booked >= 2) {
      // Rating > 4 & interviews > 2
      isTop = "Rising";
    }

    return (
      <>
        {isTop?.length > 0 && isTop === "Rising" && (
          <div className="network__book__bottom__left__bottom__recognitions__container__item pill-grey">
            <img src={RisingStar} alt="magic star" />
            <p>{isTop}</p>
          </div>
        )}
        {isHighrated && <IsHighRatedReturn />}
        {isTop?.length > 0 && isTop === "Top Coach" && (
          <div className="network__book__bottom__left__bottom__recognitions__container__item pill-grey">
            <img src={DiamondGrey} alt="diamond grey" />
            <p>{isTop}</p>
          </div>
        )}
        {!isHighrated && isTop.length === 0 && (
          <p className="no__recognitions">No Recognition Found</p>
        )}
      </>
    );
  };

  const [cv, setCV] = useState("");

  const [loader1, setLoader1] = useState(false);
  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size >= 2872139) {
      ErrorToast("File size should not exceed maximum limit of 3 MB");
    } else {
      var data = new FormData();
      data.append("files", file);
      if (file) {
        setLoader1(true);
        const responses = await client.post(`${API_URL}/api/upload`, data);
        if (responses?.status === 200) {
          setLoader1(false);
          SuccessToast("Document uploaded successfully");
        } else {
          setLoader1(false);
        }
        setCV(responses.data.imageUrl);
      }
    }
  };

  //
  //
  const handleSeemoreClick = () => {
    setIsSeeMore(!isSeeMore);
  };
  return (
    <>
      {showPayment ? (
        // <Elements stripe={stripePromise} options={options}>
        <Payment
          setShowPayment={handlePaymentReturn}
          mentorData={singleData}
          selectedTime={calendarReturn}
        />
      ) : (
        // </Elements>
        <div className="network__book">
          <div className="network__book__top">
            <div
              className="network__book__top__container"
              onClick={() => setShowSession(false)}
            >
              <img src={ArrowLeft} alt="left arrow" />
              <p className="marginTop">Back to Dashboard</p>
            </div>
          </div>
          {/* rows */}
          <Row className="network__book__bottom">
            <Col
              style={{ padding: "0" }}
              className="network__book__bottom__left"
            >
              {/* card */}
              <div className="network__book__bottom__left__top">
                <div className="network__book__bottom__left__top__container">
                  <img
                    // src={AvatarIcon}
                    src={singleData.mentorProfiles[0]?.image || AvatarIcon}
                    alt="avatar icon"
                    className="network__book__bottom__left__top__container__image"
                  />
                  <div className="network__book__bottom__left__top__container__right">
                    <h2>
                      {singleData?.userData[0]?.firstName}{" "}
                      {singleData?.userData[0]?.lastName}
                    </h2>
                    <p className={`${!isSeeMore ? "ellipsis__text" : ""} `}>
                      {singleData?.mentorProfiles[0]?.background}
                    </p>
                    {singleData?.mentorProfiles[0]?.background?.length >=
                      320 && (
                      <button
                        className="seemore__button"
                        onClick={handleSeemoreClick}
                      >
                        {!isSeeMore ? "See more" : "See less"}
                      </button>
                    )}
                    <div className="network__book__bottom__left__top__container__right__container">
                      <div className="network__book__bottom__left__top__container__right__container__inner">
                        <img src={LocationIcon} alt="location" />
                        <p>{singleData?.mentorProfiles[0]?.location}</p>
                      </div>
                      {/* <div className="network__book__bottom__left__top__container__right__container__inner">
                        <img src={LanguageSquare} alt="language square" />
                        <p className="text-capitalize">
                          {singleData?.mentorProfiles[0]?.language}
                        </p>
                      </div> */}
                    </div>
                    <div className="network__book__bottom__left__top__container__right__ratings">
                      {/* <Ratings /> */}
                      <Rating
                        readonly={true}
                        initialValue={individualRating(singleData)}
                        allowFraction={true}
                        fillIcon={<img src={StarFull} width={16} alt="" />}
                        emptyIcon={<img src={EmptyStar} width={16} alt={""} />}
                        size={20}
                        fillColor="#F9A63A"
                        className="mb-1"
                      />
                      <p>
                        ({" "}
                        {singleData?.relability.length > 0
                          ? singleData?.relability[0]?.booked
                          : "0"}{" "}
                        Sessions )
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* details */}
              <div className="network__book__bottom__left__bottom">
                <div className="network__book__bottom__left__bottom__services">
                  <div className="network__book__bottom__left__bottom__services__heading">
                    <p>About Service</p>
                    {/* <img src={TaskIcon} alt="task icon" /> */}
                  </div>
                  <div className="network__book__bottom__left__bottom__services__plans">
                    <p className="network__book__bottom__left__bottom__services__plans__description">
                      {singleData?.mentorPricing[0]?.summary}
                    </p>
                    {/* {singleData?.mentorPricing[0]?.["Interview Practice"] >
                      0 && (
                      <div className="network__book__bottom__left__bottom__services__plans__plan">
                        <h3>Interview practice</h3>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="network__book__bottom__left__bottom__experience">
                  <div className="network__book__bottom__left__bottom__experience__heading">
                    <p>Experience</p>
                    {/* <img src={Briefcase} alt="briefcase icon" /> */}
                  </div>
                  <div className="network__book__bottom__left__bottom__experience__container">
                    {singleData?.mentorProfiles[0]?.professionalDetails?.map(
                      (exp) => {
                        return (
                          <div className="network__book__bottom__left__bottom__experience__container__item">
                            <p className="network__book__bottom__left__bottom__experience__container__item__title">
                              {/* Community Manager, Fast Track */}
                              {exp?.position}
                              {", "}
                              {exp?.company}
                            </p>
                            <div className="network__book__bottom__left__bottom__experience__container__item__inner">
                              <p>
                                {" "}
                                {new Date(exp.startDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                  }
                                )}
                                {/* {""} - {""}
                                {new Date(exp.endDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                  }
                                )} */}
                                {" - "}
                                {exp?.currentlyEmployed
                                  ? "Current"
                                  : new Date(exp.endDate).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                      }
                                    )}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="network__book__bottom__left__bottom__education">
                  <div className="network__book__bottom__left__bottom__education__heading">
                    <p>Education</p>
                    {/* <img src={Graduation} alt="graduation" /> */}
                  </div>

                  <div className="network__book__bottom__left__bottom__education__container">
                    {singleData?.mentorProfiles[0]?.educationDetails?.map(
                      (elem) => {
                        return (
                          <div className="network__book__bottom__left__bottom__education__container__item">
                            <p className="network__book__bottom__left__bottom__education__container__item__heading">
                              {/* London School of Business, United Kingdom */}
                              {elem?.degree}, {elem?.institution}
                            </p>
                            <p className="network__book__bottom__left__bottom__education__container__item__date">
                              {new Date(elem?.startDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                }
                              )}
                              {/* {" "}
                              -{" "}

                              {new Date(elem.endDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                }
                              )} */}

                              {" - "}
                              {elem?.currentlyStuding
                                ? "Current"
                                : new Date(elem?.endDate).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                    }
                                  )}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="network__book__bottom__left__bottom__recognitions">
                  <div className="network__book__bottom__left__bottom__recognitions__heading">
                    <p>Recognitions</p>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <p className="custom__tooltip">
                            Find the best mentors! "Rising" mentors have
                            garnered over 4 stars with a minimum of 2 sessions,
                            showcasing consistent positive performance and
                            growth potential. "Top Coach" is reserved for those
                            maintaining a rating exceeding 4 stars and
                            completing at least 5 sessions, highlighting
                            expertise as highly effective mentors. "High Rated"
                            acknowledges individuals achieving an outstanding
                            rating surpassing 4.5 stars.
                          </p>
                        </Tooltip>
                      }
                    >
                      <img src={InfoCircle} alt="info circle" />
                    </OverlayTrigger>
                    {/* <img src={DiamondGrey} alt="gray diamond" /> */}
                  </div>
                  <div className="network__book__bottom__left__bottom__recognitions__container">
                    {singleData?.mentorRattings && (
                      <>{gettingTopOrRising(singleData)}</>
                    )}
                  </div>
                </div>
              </div>
              {/* calendar mobile */}
              <div className="network__book__bottom__right d-md-none">
                <div className="calendar-bg">
                  <Calendar
                    setCalendarReturn={setCalendarReturn}
                    singleCalendarData={singleData}
                    slotTime={""}
                  />
                </div>
                <div className="network__book__bottom__right__container">
                  {/* <p className="network__book__bottom__right__container__timezone">
                    Timezone - Portugal, Europe (GMT+2)
                  </p> */}
                  <div className="network__book__bottom__right__container__select">
                    <Dropdown onSelect={handleSelectDifficulty}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdownToSelect select__icon"
                      >
                        <p className="forumm__button__text">
                          {difficultyLevel
                            ? difficultyLevel
                            : "Select your guide difficulty level"}
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                        <Dropdown.Item
                          eventKey="Fundamental"
                          className="dropdownToSelect__item"
                        >
                          Fundamental
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Advanced"
                          className="dropdownToSelect__item"
                        >
                          Advanced
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Select
                      aria-label="Default select example"
                      className="network__book__bottom__right__container__select__input network__book__select"
                      onChange={handleSelectChangeForDifficulty}
                      value={difficultyLevel}
                    >
                      <option className="network__book__bottom__right__container__select__input__item">
                        Select your guide difficulty level
                      </option>
                      <option
                        value="Fundamental"
                        className="network__book__bottom__right__container__select__input__item"
                      >
                        Fundamental
                      </option>
                      <option
                        value="Advanced"
                        className="network__book__bottom__right__container__select__input__item"
                      >
                        Advanced
                      </option>
                    </Form.Select> */}
                  </div>
                  <button
                    className="network__book__bottom__right__container__button btn-green"
                    onClick={() => BookedMySession(singleData)}
                  >
                    Book
                  </button>
                </div>
              </div>
              {/* reviews */}
              <div className="mentor__profile__bottom__left__reviews">
                <div className="mentor__profile__bottom__left__reviews__top">
                  <div className="mentor__profile__bottom__left__reviews__top__container">
                    <h2>
                      {ratingsTemp?.totalReviews > 0
                        ? ratingsTemp?.totalReviews
                        : 0}{" "}
                      Reviews
                    </h2>
                    <div style={{ marginTop: "-4px" }}>
                      <Rating
                        initialValue={
                          ratingsTemp?.totalReviews > 0
                            ? ratingsTemp?.totalRatting /
                              ratingsTemp?.totalReviews
                            : 0
                        }
                        readonly={true}
                        transition={true}
                        // fillIcon={<img src={FullStargreen} width={16} alt=""/>}
                        // emptyIcon={<img src={EmptyStarGreen} width={16} alt=""/>}
                        fillIcon={<img src={StarFull} width={16} alt="" />}
                        emptyIcon={<img src={EmptyStar} width={16} alt={""} />}
                        size={16}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                {ratingsTemp?.totalReviews > 0 ? (
                  <>
                    <div className="mentor__profile__bottom__left__reviews__center">
                      {currentData.map((item) => (
                        <div
                          className="mentor__profile__bottom__left__reviews__center__item"
                          key={item?._id}
                        >
                          <div className="mentor__profile__bottom__left__reviews__center__item__top">
                            <div className="mentor__profile__bottom__left__reviews__center__item__top__heading">
                              <h2>
                                {item?.studentId?.firstName}{" "}
                                {item?.studentId?.lastName}
                              </h2>
                              <div style={{ marginTop: "-6px" }}>
                                <Rating
                                  initialValue={item?.interview}
                                  readonly={true}
                                  transition={true}
                                  fillIcon={
                                    <img src={StarFull} width={16} alt="" />
                                  }
                                  emptyIcon={
                                    <img src={EmptyStar} width={16} alt={""} />
                                  }
                                  size={16}
                                />
                              </div>
                            </div>
                            <div className=" mentor__profile__bottom__left__reviews__center__item__top__details">
                              <p>{item?.sessionType}</p>
                              {/* <p>1-hour Fit Coaching</p> */}
                              <img src={BiDot} alt="bi dot" />
                              <p>
                                {new Date(item?.createdAt).toLocaleDateString(
                                  "en-GB"
                                )}
                              </p>
                              {/* <p>15.08.2023</p> */}
                            </div>
                          </div>
                          <p className="mentor__profile__bottom__left__reviews__center__item__bottom">
                            {item?.feedback}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="mentor__profile__bottom__left__reviews__bottom">
                      <p
                        onClick={handleAddButtonClick}
                        className="btn__loadmore"
                      >
                        Load More Reviews
                      </p>
                    </div>
                  </>
                ) : (
                  <p className="no__rating">No Rating Availables</p>
                )}
              </div>
            </Col>
            <Col
              style={{ padding: "0" }}
              md={4}
              className="network__book__bottom__right d-none d-md-block"
            >
              <div className="calendar-bg">
                <Calendar
                  setCalendarReturn={setCalendarReturn}
                  singleCalendarData={singleData}
                  slotTime={""}
                />
              </div>
              <div className="network__book__bottom__right__container">
                {/* <p className="network__book__bottom__right__container__timezone">
                  Timezone - Portugal, Europe (GMT+2)
                </p> */}

                {singleData?.selectedSession !== "Interview Practice" && (
                  <>
                    <div className="ProfessionalExpDiv">
                      {loader1 ? (
                        <LoaderSmall />
                      ) : (
                        <label htmlFor="cvupload" className="w-100 mb-0">
                          <div
                            className={`${
                              cv.length > 0
                                ? "resume__button__success"
                                : "resume__button"
                            }`}
                          >
                            {cv.length > 0 ? (
                              "Document uploaded successfully"
                            ) : (
                              <>
                                <img src={cvIcon} alt=""></img> Attach Resume or
                                CV
                              </>
                            )}
                          </div>
                          <input
                            type="file"
                            accept="application/pdf"
                            className="d-none"
                            id="cvupload"
                            onChange={(e) => handleCVUpload(e)}
                          />
                        </label>
                      )}
                    </div>
                  </>
                )}

                {singleData?.selectedSession === "Interview Practice" && (
                  <>
                    <div className="network__book__bottom__right__container__select">
                      <Dropdown onSelect={handleSelectDifficulty}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="dropdownToSelect select__icon"
                        >
                          <p className="forumm__button__text">
                            {difficultyLevel
                              ? difficultyLevel
                              : "Select your guide difficulty level"}
                          </p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                          <Dropdown.Item
                            eventKey="Fundamental"
                            className="dropdownToSelect__item"
                          >
                            Fundamental
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Advanced"
                            className="dropdownToSelect__item"
                          >
                            Advanced
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                )}

                <button
                  className="network__book__bottom__right__container__button btn-green"
                  onClick={() => BookedMySession(singleData)}
                >
                  Book
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {loader && <ScreenLoader />}
    </>
  );
};

export default Book;
