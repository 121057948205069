import React, { useState } from "react";
import "./SignupEmail.scss";
import Login from "../../src/Login/Login";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Carousel from "react-bootstrap/Carousel";
import CarouselImage1 from "../../src/assets/carouselimage1.svg";
import emailIcon from "../../src/assets/emailIcon.svg";
import linkdin from "../../src/assets/linkdinIcon.svg";
import googleIcon from "../../src/assets/googleIcon.svg";
import GreenTick from "../assets/greenTick.svg";
// import PhoneInput from "react-phone-number-input";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import eyeIcon from "../assets/eyeIcon.svg";
import EyeSlash from "../assets/eye-slash.svg";
import { useSelector, useDispatch } from "react-redux";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { postSignUp } from "../service/redux/middleware/postSignUp";
import { useHistory } from "react-router-dom";
import FastLogo from "../assets/8.svg";
import MyLoader from "../components/Loader/MyLoader";
import { Link } from "react-router-dom";

const SignupEmail = () => {
  const [activeTab, setActiveTab] = useState("signup");
  const [showPassword, setShowpassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [loginData, setLoginData] = useState({
    firstName: "",
    lastName: "",
    loginEmail: "",
    phoneNo: "",
    loginPassword: "",
    confirmPassword: "",
  });
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    loginData[e.target.name] = e.target.value;
    setLoginData({ ...loginData });
  };
  const setPhoneNumber = (e) => {
    setLoginData({ ...loginData, phoneNo: e });
  };
  const formValidation = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(loginData.loginEmail);
    const passwordRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    const isPasswordValid = passwordRegex.test(loginData.loginPassword);
    const isPasswordValidLength = loginData.loginPassword.length < 8;

    if (!loginData.firstName) {
      ErrorToast("Please enter your first name.");
      return false;
    } else if (!loginData.lastName) {
      ErrorToast("Please enter your last name.");
      return false;
    } else if (!loginData.loginEmail) {
      ErrorToast("Please enter your email address.");
      return false;
    } else if (!isValidEmail) {
      ErrorToast("Please enter a valid email address.");
      return false;
    } else if (!loginData?.phoneNo) {
      ErrorToast("Please enter a valid phone number.");
      return false;
    } else if (!loginData.loginPassword) {
      ErrorToast("Please enter the correct password.");
      return false;
    } else if (!isPasswordValid) {
      ErrorToast(
        "Invalid password. Your password must include a special character."
      );
      return false;
    } else if (isPasswordValidLength) {
      ErrorToast(
        "Invalid password. Your password must include a minimum length of 8 characters."
      );
      return false;
    } else if (!loginData.confirmPassword) {
      ErrorToast("Please confirm your password.");
      return false;
    } else if (loginData?.loginPassword !== loginData?.confirmPassword) {
      ErrorToast("Invalid password. Your passwords do not match.");
      return false;
    } else if (!isConfirm) {
      ErrorToast("Please accept the terms and conditions.");
      return false;
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    const res = formValidation();
    if (res === false) {
      return false;
    }
    const ClearAll = () => {
      setLoginData({
        firstName: "",
        lastName: "",
        loginEmail: "",
        phoneNo: "",
        loginPassword: "",
        confirmPassword: "",
      });
    };
    const data = {
      firstName: loginData.firstName,
      lastName: loginData.lastName,
      email: loginData.loginEmail,
      phoneNo: loginData.phoneNo,
      password: loginData.loginPassword,
    };
    setLoader(true);
    dispatch(postSignUp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(true);
        SuccessToast(
          "Account created successfully. Please check your email to verify your account"
        );
        history.push("/signup");
        ClearAll();
      } else {
        ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <div className="SignUPSection">
      <div
        className={`SignUPSection__main row  ${
          activeTab == "signup" ? "" : "justify-content-center"
        }`}
      >
        {activeTab == "signup" ? (
          <div  className="d-none d-md-block col-md-5 p-0">
            <div style={{position:"fixed",width:"50%"}} className="carousel__my__container">
              <Carousel
                style={{ padding: "16px" }}
                controls={false}
                wrap={true}
                interval={1000}
              >
                <Carousel.Item>
                  <img
                    style={{ height: "97vh", borderRadius: "20px" }}
                    src={CarouselImage1}
                    className="carousel__image"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <div style={{ padding: "16px" }} className="caption__inner">
                      <h5>
                        “As a recent job seeker in the banking industry,
                        fast_track really played a pivotal role in helping me
                        secure my dream job.”
                      </h5>
                      <p>Jay Collen - Senior Accounting Manager</p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    style={{ height: "97vh", borderRadius: "20px" }}
                    src={CarouselImage1}
                    className="carousel__image  "
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <div className="caption__inner">
                      <h5>
                        “As a recent job seeker in the banking industry,
                        fast_track really played a pivotal role in helping me
                        secure my dream job.”
                      </h5>
                      <p>Jay Collen - Senior Accounting Manager</p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    style={{ height: "97vh", borderRadius: "20px" }}
                    src={CarouselImage1}
                    className="carousel__image "
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <div className="caption__inner">
                      <h5>
                        “As a recent job seeker in the banking industry,
                        fast_track really played a pivotal role in helping me
                        secure my dream job.”
                      </h5>
                      <p>Jay Collen - Senior Accounting Manager</p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        ) : (
          ""
          // <div className="d-none d-md-block col-md-4 SignUPSection__main__empty__login">
          //   <a href="https://www.fast-track.community/">
          //     <img
          //       src={FastLogo}
          //       className="SignUPSection__main__empty__image"
          //     />
          //   </a>
          //   <div className="SignUPSection__main__empty__login__content">
          //     <h1>
          //       Learn more on the go from
          //       <br /> peers and mentors, from all
          //       <br /> over the world
          //     </h1>
          //   </div>
          // </div>
        )}

        <div
          className={`${
            activeTab === "signup"
              ? "col-12 col-md-7 pad__pad__pad"
              : "col-12 col-sm-8 col-md-7  "
          }`}
        >
          <div className="small_screen__logo">
            <a href="https://www.fast-track.community/">
              <img
                src={FastLogo}
                className="SignUPSection__main__empty__image"
              />
            </a>
          </div>
          <div className="bar-bar-bar"></div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={activeTab}
            className=""
            onSelect={handleTabSelect}
          >
            <div className="svdv">
              <div className="col-11 col-md-9 col-xl-6 m-auto">
                {/* {activeTab === "signup" ? (
                  <div
                    className="justify-content-center remove-elements"
                    style={{ marginBottom: "56px" }}
                  >
                    <img
                      src={FastLogo}
                      className="SignUPSection__main__empty__image"
                    />
                  </div>
                ) : (
                  <div 
                    className="justify-content-center remove-elements"
                    style={{ position: "absolute",top:"0%",left:"45%" }}
                  >
                    <img
                      src={FastLogo}
                      className="SignUPSection__main__empty__image"
                    />
                  </div>
                )} */}

                {/* <div
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "56px" }}
                >
                  <img
                    src={FastLogo}
                    className="SignUPSection__main__empty__image"
                  />
                </div> */}
                <div style={activeTab === "signup" ?{marginTop:"0px"}:{marginTop:"80px"}} className=" SignUPSection__main__formSection__title scvebve textmt">
                  {activeTab === "signup" ? "Create a " : "Log into your "}
                  fast_track account
                </div>
                <div className="text__text__text">
                  {activeTab === "signup"
                    ? "Join the leading investment banking community for aspiring bankers"
                    : "Learn more on the go from peers and mentors, from all over the world"}
                </div>
                <Nav className="flex-row Toggler justify-content-center ">
                  {/* { signup ? (<div></div>):()} */}
                  <Nav.Item
                    className={`col-6 text-center ${
                      activeTab === "signup"
                        ? "Toggler__itemactive"
                        : "Toggler__itemunactive"
                    }`}
                  >
                    {/* <Nav.Link eventKey="second" onClick={clickHandler}> */}
                    <Nav.Link
                      onClick={() => {
                        history.push("/signup");
                      }}
                      className={
                        activeTab === "signup"
                          ? "Toggler__itemactive__textactive"
                          : "Toggler__itemunactive__untextactive"
                      }
                      eventKey="signup"
                    >
                      Sign up
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className={`col-6 text-center ${
                      activeTab === "login"
                        ? "Toggler__itemactive"
                        : "Toggler__itemunactive"
                    }`}
                  >
                    {/* <Nav.Link eventKey="login" onClick={clickHandler}> */}
                    <Nav.Link
                      className={
                        activeTab === "login"
                          ? "Toggler__itemactive__textactive"
                          : "Toggler__itemunactive__untextactive"
                      }
                      eventKey="login"
                    >
                      Login
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="signup">
                    <div className="loginSection">
                      <Form onSubmit={handleLogin}>
                        <div className="flex__names__container">
                          <Form.Group
                            className=" loginfield p-0  justify-space-between"
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label ">
                              First name
                            </Form.Label>
                            <Form.Control
                              className="loginfield__type"
                              placeholder="John"
                              type="text"
                              value={loginData.firstName}
                              name="firstName"
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className=" margin___last__name"
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label ">
                              Last name
                            </Form.Label>
                            <Form.Control
                              className="loginfield__type"
                              placeholder="Doe"
                              type="text"
                              value={loginData.lastName}
                              name="lastName"
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </div>

                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Email
                          </Form.Label>
                          <Form.Control
                            className="loginfield__type"
                            placeholder="johndoe@fasttrack.com"
                            type="email"
                            value={loginData.loginEmail}
                            name="loginEmail"
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Group>

                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Phone number
                          </Form.Label>

                          <PhoneInput
                            className="loginfield__type"
                            placeholder="please enter the number"
                            //defaultCountry="RU"
                            value={loginData.phoneNo}
                            onChange={(e) => setPhoneNumber(e)}
                            error={
                              loginData.phoneNo
                                ? isValidPhoneNumber(loginData.phoneNo)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Password
                          </Form.Label>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            className="loginfield__type"
                            placeholder="Enter password"
                            value={loginData.loginPassword}
                            name="loginPassword"
                            onChange={(e) => handleChange(e)}
                          />
                          <img
                            onClick={() => setShowpassword(!showPassword)}
                            className="loginfield__img"
                            src={showPassword ? EyeSlash : eyeIcon}
                            alt=""
                          ></img>
                        </Form.Group>
                        <Form.Group
                          className=" loginfield__optional__margin "
                          controlId=""
                        >
                          <Form.Label className="loginfield__Label ">
                            Confirm password
                          </Form.Label>
                          <Form.Control
                            type={showConfirmPass ? "text" : "password"}
                            className="loginfield__type"
                            placeholder="Type Password Again "
                            value={loginData.confirmPassword}
                            name="confirmPassword"
                            onChange={(e) => handleChange(e)}
                          />
                          <img
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                            className="loginfield__img"
                            src={showConfirmPass ? EyeSlash : eyeIcon}
                            alt=""
                          ></img>
                        </Form.Group>
                        <label
                          className="loginCheckbox "
                          htmlFor="logincheckbox"
                        >
                          <Form.Check
                            aria-label="option 1"
                            id="logincheckbox"
                            name="logincheckbox"
                            value={isConfirm}
                            onChange={(e) => setIsConfirm(!isConfirm)}
                          />
                          <p>
                            By clicking “Create account”, you accept the
                            {/* <span
                              className="loginCheckbox__span"
                              style={{ marginLeft: "5px" }}
                            > */}
                            {/* terms and conditions{" "} */}
                            <Link
                              className="loginCheckbox__span"
                              style={{ marginLeft: "5px" }}
                              to="/termsconditions"
                              target="_blank"
                            >
                              terms and conditions
                            </Link>{" "}
                            {/* </span>{" "} */}
                            given by Fast Track, for the community
                          </p>
                        </label>
                        {isConfirm ? (
                          <>
                            {/* <div
                            className="wrapper__of__login__green__button"
                           type="submit"
                          > */}
                            <button
                              className="wrapper__of__login__green__button"
                              type="submit"
                            >
                              Create account
                              <div className="ms-2">
                                {loader && <MyLoader />}
                              </div>
                            </button>
                            <br />
                          </>
                        ) : (
                          <>
                            {/* <div
                            className="wrapper__of__login__green__button__disabled"
                            type="submit"
                          > */}
                            <button
                              className="wrapper__of__login__green__button__disabled"
                              type="submit"
                            >
                              Create account
                              <div className="ms-2">
                                {loader && <MyLoader />}
                              </div>
                            </button>
                            {/* </div> */}
                          </>
                        )}
                      </Form>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="login">
                    <Login />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default SignupEmail;
