import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../client";
import axios from "axios";

export const sendSubscription = createAsyncThunk(
  "sendSubscription",
  async (data) => {
    try {
      const res = await axios.post(`${API_URL}/api/addNewsLetter`, data);
      return { status: res.status, data: res.data?.message };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
