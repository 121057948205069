import React from 'react'
import "./ForumThread.scss"
import arrowleft from "../../assets/arrow-left.svg"
const ForumThread = () => {
  return (
    <div className='ForumThreadSection container ' >
        <div className='ForumThreadSection__Back'>
            <img  className=''src={arrowleft} alt=''/>
            <h1>Back</h1>
        </div>
        <div className="ForumSection__Content row  ">
        <div className="col-8 test " style={{ padding: "0px" }}>
          <h1 className="ForumSection__Content__heading1">
            Likely Interview Toolkit
          </h1>
          <p1 className="ForumSection__Content__para1 ">
            Interview <span className="">.</span> General Overview
          </p1>
        </div>
       
      </div>
    </div>
  )
}

export default ForumThread