import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";

export const getCredits = createAsyncThunk("getCredits", async (data) => {
  try {
    const res = await client.get(`/api/getCredits/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const buyCredits = createAsyncThunk("buyCredits", async (data) => {
  try {
    const res = await client.post(
      `/session`,
      { currency: data.currency },
      { headers: { authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const confirmBuyCredits = createAsyncThunk(
  "confirmBuyCredits",
  async (data) => {
    try {
      const res = await client.post(
        `/api/buyCredits`,
        {
          userId: data.userId,
          session: data.session,
          amount: data?.amount,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
