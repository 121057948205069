import React from "react";

//
import Modal from "react-bootstrap/Modal";
import CloseBtn from '../../../assets/meeting/close-circle.svg'
//


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

const CustomModal = ({ modalShow, setModalShow, children }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        {children}
      </MyVerticallyCenteredModal>
    </>
  );
};






export const HeaderLandingModal = ({ headerModal, seHeaderModal, children }) => {
  return (
    <>

      <Modal
        show={headerModal}
        onHide={() => seHeaderModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header onClick={() => seHeaderModal(false)} style={{ background: '#2f3033', borderBottom: 'none', justifyContent: 'end', position: 'relative' }} >
          <img src={CloseBtn} style={{ position: 'absolute', top: '-26px', cursor: 'pointer' }} />
        </Modal.Header>
        <Modal.Body style={{ padding: '0px' }}>{children}</Modal.Body>
      </Modal>
    </>
  );
};


export default CustomModal;