import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import TimeRangeSlider from "react-time-range-slider";
import SortIcon from "../../assets/sort.svg";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import { viewSession } from "../../service/redux/middleware/session";
import AvatarIcon from "../../assets/avatar.svg";
import LocationIcon from "../../assets/location.svg";
import UniversityIcon from "../../assets/university.svg";
import GraduationIcon from "../../assets/graduation.svg";
import ArrowLeftMini from "../../assets/arrow-left-mini.svg";
import ArrowRightMini from "../../assets/arrow-right-mini.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getMeetingTime } from "../../service/redux/middleware/meeting";
import "./booksession.scss";
import Book from "./Book/Book";
import { useHistory } from "react-router-dom";
import { ErrorToast } from "../MyToaster";
import pay from "../../assets/play-circle.svg";
import NavbarLanding from "../Navbar/NavbarLanding";
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../assets/star.svg";
import StarFull from "../../assets/star-yellow.svg";
import noFound from "../../assets/searchempty.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
import HeaderLandingPage from "../HeaderForLandingPage/HeaderLandingPage";
import { HeaderLandingModal } from "../Custom/Modal/ModalCustom";
import HeaderLandingPageForModal from "../HeaderForLandingPage/HeaderLandingPageForModal";
import MentorSecondNavbar from "../MentorDashboard/MentorSecondNavbar";
import ScreenLoader from "../Loader/ScreenLoader";

const InterviewHome = () => {
  const [showBook, setShowBook] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [education, setEducation] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [temp, setTemp] = useState([]);
  const [singleData, setSingleData] = useState("");
  const [loader, setLoader] = useState(false);
  const [isMentor, setIsMentor] = useState(false);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const featureRef = useRef();
  const [value, setValue] = useState({
    start: "00:00",
    end: "23:59",
  });
  const changeStartHandler = () => {
    if (value.start <= "00:00") {
      setValue((prevValue) => ({
        ...prevValue,
        start: "00:00",
      }));
    }
  };

  const changeCompleteHandler = (time) => {};
  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];

      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };
  const timeChangeHandler = (time) => {
    const startTime = timeParser(time.start);
    const endTime = timeParser(time.end);
    setValue({
      start: startTime,
      end: endTime,
    });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  const getSingleData = (elem) => {
    setSingleData(elem);
    setShowBook(true);
  };
  const filterTime = (val, isNext = false, isPrev = false) => {
    if (val === "") {
      val = new Date();
    }
    if (isNext) {
      let ndate = nextDate(val);
      setStartDate(ndate);
    } else if (isPrev) {
      let pdate = prevDate(val);
      setStartDate(pdate);
    } else {
      setStartDate(val);
    }
  };
  const nextDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
  };
  const prevDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date;
  };
  const ApplyFilter = () => {
    let filteredData = [...sessionData];
    let filterFunctions = [];

    if (education) {
      filterFunctions.push(
        (item) =>
          item.creatorData[0].educationDetails[0]?.programme === education
      );
    }

    if (startDate) {
      filterFunctions.push((item) => {
        let matchedData = item.creatorAvalible?.filter((citem) => {
          return citem?.date === moment(startDate).format("YYYY-MM-DD");
        });
        return matchedData.length > 0;
      });
    }
    if (value?.start && value?.end) {
      if (!(value?.start === "00:00" && value?.end === "23:59")) {
        filterFunctions.push((item) => {
          let matchedData = item.creatorAvalible?.filter(
            (citem) =>
              moment(citem.startDateTime).format("HH:mma") >= value?.start &&
              moment(citem?.endDateTime).format("HH:mma") <= value?.end
          );
          return matchedData.length > 0;
        });
      }
    }

    try {
      filterFunctions.forEach((filterFunction) => {
        filteredData = filteredData.filter(filterFunction);
      });
      return setTemp(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    ApplyFilter();
    return () => {
      ApplyFilter();
    };
  }, [startDate, education, value, sessionData]);

  const ResetFilter = () => {
    setTemp(sessionData);
    setEducation("");
    setStartDate(new Date());
    setValue({ start: "00:00", end: "23:59" });
  };

  let data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    setLoader(true);
    dispatch(viewSession()).then((res) => {
      if (res?.payload?.status === 200) {
        let data = res?.payload?.data?.filter(
          (elem) => elem?.creatorId !== userData?._id
        );
        checkingIfFullyBooked(data);
      }
    });
    const mentorFlag = localStorage.getItem("mentorFlag");
    console.log("mentorFlag", mentorFlag);
  }, []);
  const checkingIfFullyBooked = (data) => {
    let toUpdate = [...data];
    toUpdate = toUpdate.map((item) => {
      let itemUpdate = { ...item };
      let AllBooked = false;
      itemUpdate = itemUpdate?.creatorAvalible?.map((cavailable) =>
        cavailable?.booked ? (AllBooked = true) : (AllBooked = false)
      );
      return { ...item, AllBooked };
    });
    toUpdate = toUpdate.sort((a, b) => {
      if (a.AllBooked && !b.AllBooked) {
        return 1;
      } else if (!a.AllBooked && b.AllBooked) {
        return -1;
      } else {
        return 0;
      }
    });
    setSessionData(toUpdate);
    setTemp(toUpdate);
    setLoader(false);

    ApplyFilter();
  };
  const [calTime, seCalTime] = useState([]);
  useEffect(() => {
    dispatch(getMeetingTime(data)).then((res) => {
      seCalTime(res?.payload?.data);
    });
  }, []);
  const educationSelected = (val) => {
    if (education === val) {
      setEducation("");
    } else {
      setEducation(val);
    }
  };
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (userData?.role === 0 && currentPath === "/interview") {
      history?.push("/interviewdashboard");
    }
  }, []);

  const individualRating = (elem) => {
    const averageIndividual =
      Number(elem?.rattingStudent?.communication) +
      Number(elem?.ratings?.fitQuestion) +
      Number(elem?.ratings?.presence) +
      Number(elem?.ratings?.technicalQuestion);
    let averageRatingind = (Number(averageIndividual) / 4).toFixed(1);
    return isNaN(averageRatingind) ? 0 : averageRatingind;
  };

  const [headerModal, seHeaderModal] = useState(false);

  const handleElementClick = () => {
    ErrorToast("Please log in first");
  };
  useEffect(() => {
    const mentorFlag = localStorage.getItem("mentorFlag");
    setIsMentor(mentorFlag);
    console.log("mentorFlag", mentorFlag);
  }, []);

  return (
    <>
      {loader && <ScreenLoader />}

      <NavbarLanding />
      {/* {userData?.role === 1 && (
        <MentorSecondNavbar />
      )} */}
      <HeaderLandingPage home={true} />
      <div className="guest__landingpage" onClick={() => seHeaderModal(true)}>
        <img src={pay} />
        <div className="ms-2 truncate-text" style={{ lineHeight: "20px" }}>
          Explainer or something similar, to make things super easy for the
          users
        </div>
      </div>
      <HeaderLandingModal
        headerModal={headerModal}
        seHeaderModal={seHeaderModal}
      >
        <HeaderLandingPageForModal home={true} />
      </HeaderLandingModal>
      {!showBook ? (
        <div className="container__custom">
          <div className="dashboard">
            <div className="container-fluid" style={{ padding: "0" }}>
              <div className="row available__container min__height__100">
                <div className="col-md-3 col-4 d-none d-md-block available__container__filter">
                  <div className="available__container__filter__top">
                    <h2>Filters</h2>
                    <p onClick={ResetFilter}>Reset</p>
                  </div>
                  <div className="custom__dropdown">
                    <div className="custom__dropdown__main">
                      <h2 className="custom__dropdown__main__title">
                        Available day
                      </h2>
                      <div className="custom__dropdown__main__container">
                        <div className="ascdvre__grehgtr">
                          <div className="filter__label__inner">
                            <img
                              src={ArrowLeftMini}
                              onClick={() => filterTime(startDate, false, true)}
                            />
                            <label
                              htmlFor="filterDate"
                              className="filterDate filter__label__inner__dateshow"
                            >
                              <p className="">
                                {moment(startDate).format("YYYY-MM-DD") ===
                                moment(new Date()).format("YYYY-MM-DD")
                                  ? "Today"
                                  : formatDate(startDate)}
                              </p>
                            </label>
                            <img
                              src={ArrowRightMini}
                              onClick={() => filterTime(startDate, true, false)}
                            />
                          </div>
                          <DatePicker
                            className="filter__date__input d-none"
                            name="startDate"
                            id="filterDate"
                            placeholder="Select expiration date..."
                            onSelect={(date) => filterTime(date)}
                          />
                        </div>

                        <div className="custom__dropdown__main__container__range">
                          <p className="mb-3">Available time</p>
                          <TimeRangeSlider
                            ref={featureRef}
                            value={value ? value : ""}
                            onChangeStart={changeStartHandler}
                            onChange={timeChangeHandler}
                            onComplete={changeCompleteHandler}
                          />
                          <div className="custom__dropdown__main__container__range__inner mt-3">
                            <p style={{ fontSize: "14px" }} className="ms-1">
                              {value?.start ? value?.start : "00:00"}
                            </p>
                            <p style={{ marginRight: "3px", fontSize: "14px" }}>
                              {" "}
                              {value?.end ? value?.end : "23:59"}
                            </p>
                          </div>
                        </div>
                        <div className="custom__dropdown__main__container__experience">
                          <p>Education</p>
                          <div className="custom__dropdown__main__container__experience__pills">
                            <div
                              className={`booksession__container__filter__pill ${
                                education === "Bachelors"
                                  ? "education_active"
                                  : "pill-grey"
                              }`}
                              onClick={() => educationSelected("Bachelors")}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#F5F6F7",
                                }}
                              >
                                {" "}
                                Bachelors
                              </p>
                            </div>
                            <div
                              className={`booksession__container__filter__pill ${
                                education === "Masters"
                                  ? "education_active"
                                  : "pill-grey"
                              }`}
                              onClick={() => educationSelected("Masters")}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#F5F6F7",
                                }}
                              >
                                {" "}
                                Masters
                              </p>
                            </div>
                            <div
                              className={`booksession__container__filter__pill ${
                                education === "MBA"
                                  ? "education_active"
                                  : "pill-grey"
                              }`}
                              onClick={() => educationSelected("MBA")}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#F5F6F7",
                                }}
                              >
                                {" "}
                                MBA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-9 col-12 row p-0`}>
                  <div
                    className={`${
                      userData?.role == 1 && userData?._id
                        ? "col-xl-12"
                        : " col-xl-8"
                    } col-12 p-0 position-relative`}
                  >
                    <div className="available__container__top">
                      <div className="available__container__top__left">
                        <p>{temp?.length} Available sessions</p>
                      </div>
                      <Dropdown className="d-md-none">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="filter__button__mobile"
                        >
                          <img
                            src={SortIcon}
                            alt="filter button"
                            className="filter__button__mobile__image"
                          />
                          <p className="filter__button__mobile__text">Filter</p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" custom__dropdown__mobile custom__dropdown__bg">
                          <div className=" d-md-none available__container__filter">
                            <div className="available__container__filter__top">
                              <h2>Filters</h2>
                              <p onClick={ResetFilter}>Reset</p>
                            </div>
                            <div className="custom__dropdown">
                              <div className="custom__dropdown__main">
                                <h2 className="custom__dropdown__main__title">
                                  Available day
                                </h2>
                                <div className="custom__dropdown__main__container">
                                  <div className="ascdvre__grehgtr">
                                    <div className="filter__label__inner">
                                      <img
                                        src={ArrowLeftMini}
                                        onClick={() =>
                                          filterTime(startDate, false, true)
                                        }
                                      />
                                      <label
                                        htmlFor="filterDate"
                                        className="filterDate filter__label__inner__dateshow"
                                      >
                                        <p className="">
                                          {moment(startDate).format(
                                            "YYYY-MM-DD"
                                          ) ===
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "Today"
                                            : formatDate(startDate)}
                                        </p>
                                      </label>
                                      <img
                                        src={ArrowRightMini}
                                        onClick={() =>
                                          filterTime(startDate, true, false)
                                        }
                                      />
                                    </div>
                                    <DatePicker
                                      className="filter__date__input d-none"
                                      name="startDate"
                                      id="filterDate"
                                      placeholder="Select expiration date..."
                                      onSelect={(date) => filterTime(date)}
                                    />
                                  </div>

                                  <div className="custom__dropdown__main__container__range">
                                    <p className="mb-3">Available time</p>
                                    <TimeRangeSlider
                                      ref={featureRef}
                                      value={value ? value : ""}
                                      onChangeStart={changeStartHandler}
                                      onChange={timeChangeHandler}
                                      onComplete={changeCompleteHandler}
                                    />
                                    <div className="custom__dropdown__main__container__range__inner mt-3">
                                      <p className="ms-1">
                                        {value?.start ? value?.start : "00:00"}
                                      </p>
                                      <p style={{ marginRight: "3px" }}>
                                        {" "}
                                        {value?.end ? value?.end : "23:59"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="custom__dropdown__main__container__experience">
                                    <p>Education</p>
                                    <div className="custom__dropdown__main__container__experience__pills">
                                      <div
                                        className={`booksession__container__filter__pill ${
                                          education === "Bachelors"
                                            ? "education_active"
                                            : "pill-grey"
                                        }`}
                                        onClick={() =>
                                          educationSelected("Bachelors")
                                        }
                                      >
                                        <p> Bachelors</p>
                                      </div>
                                      <div
                                        className={`booksession__container__filter__pill ${
                                          education === "Masters"
                                            ? "education_active"
                                            : "pill-grey"
                                        }`}
                                        onClick={() =>
                                          educationSelected("Masters")
                                        }
                                      >
                                        <p> Masters</p>
                                      </div>
                                      <div
                                        className={`booksession__container__filter__pill ${
                                          education === "MBA"
                                            ? "education_active"
                                            : "pill-grey"
                                        }`}
                                        onClick={() => educationSelected("MBA")}
                                      >
                                        <p> MBA</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {temp?.length > 0 ? (
                      <>
                        {temp?.map((elem, index) => {
                          return (
                            <div
                              className="interview__book__card__container"
                              key={index}
                            >
                              <div className="interview__book__card__container__bottom">
                                <div className="interview__book__card">
                                  <div
                                    style={{ width: "64px", height: "64px" }}
                                    className="interview__book__card__left"
                                  >
                                    <img
                                      src={
                                        elem?.creatorData[0]?.image
                                          ? elem?.creatorData[0]?.image
                                          : AvatarIcon
                                      }
                                      alt="user avatar"
                                      className="interview__book__card__left__image"
                                      style={{
                                        width: "64px",
                                        height: "64px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <div className="interview__book__card__left__container">
                                    <div className="interview__book__card__left__container__mobilediv">
                                      <div className="interview__book__card__center">
                                        <div className="interview__book__card__center__created">
                                          <p className="interview__book__card__center__created__title">
                                            {elem?.userData[0]?.firstName}{" "}
                                            {elem?.userData[0]?.lastName}
                                          </p>
                                        </div>
                                        <div className="interview__book__card__center__details">
                                          <div className="interview__book__card__center__details__inner interview__book__card__center__details__graduation">
                                            <img
                                              src={GraduationIcon}
                                              className="interview__book__card__center__details__inner__image"
                                            />
                                            <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__graduation ">
                                              {elem?.creatorData[0]
                                                ?.educationDetails[0]?.programme
                                                ? elem?.creatorData[0]
                                                    ?.educationDetails[0]
                                                    ?.programme
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="interview__book__card__center__details__inner interview__book__card__center__details__location">
                                            <img
                                              src={GraduationIcon}
                                              className="interview__book__card__center__details__inner__image"
                                            />
                                            <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__location">
                                              {elem?.creatorData[0]
                                                ?.educationDetails[0]?.degree
                                                ? elem?.creatorData[0]
                                                    ?.educationDetails[0]
                                                    ?.degree
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="interview__book__card__center__details__inner interview__book__card__center__details__university">
                                            <img
                                              src={UniversityIcon}
                                              className="interview__book__card__center__details__inner__image"
                                            />
                                            <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__university">
                                              {elem?.creatorData[0]
                                                ?.educationDetails[0]
                                                ?.institution
                                                ? elem?.creatorData[0]
                                                    ?.educationDetails[0]
                                                    ?.institution
                                                : ""}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="interview__book__card__center__ratings">
                                          <Rating
                                            readonly={true}
                                            initialValue={individualRating(
                                              elem
                                            )}
                                            allowFraction={true}
                                            fillIcon={
                                              <img
                                                src={StarFull}
                                                width={16}
                                                alt=""
                                              />
                                            }
                                            emptyIcon={
                                              <img
                                                src={EmptyStar}
                                                width={16}
                                                alt=""
                                              />
                                            }
                                            size={20}
                                            fillColor="#F9A63A"
                                          />
                                          <p className="interview__book__card__center__ratings__text">
                                            ( {elem?.relability[0]?.booked}{" "}
                                            Interviews,{" "}
                                            {elem?.relability[0]?.count &&
                                            elem?.relability[0]?.booked
                                              ? (
                                                  (elem.relability[0].booked /
                                                    elem.relability[0].count) *
                                                  100
                                                ).toFixed(0)
                                              : "0"}
                                            % Reliability )
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="interview__new__card interview__new__card__home">
                                      <div className="interview__new__card__left">
                                        <div className="interview__new__card__left__top">
                                          {elem?.userData[0]?.firstName}{" "}
                                          {elem?.userData[0]?.lastName}
                                        </div>
                                        <div className="interview__new__card__left__ratings">
                                          <Rating
                                            readonly={true}
                                            initialValue={individualRating(
                                              elem
                                            )}
                                            allowFraction={true}
                                            fillIcon={
                                              <img
                                                src={StarFull}
                                                width={16}
                                                alt=""
                                              />
                                            }
                                            emptyIcon={
                                              <img src={EmptyStar} width={16} />
                                            }
                                            size={20}
                                            fillColor="#F9A63A"
                                          />
                                          <p className="interview__new__card__left__ratings__text">
                                            (
                                            {elem?.relability.length > 0
                                              ? elem?.relability[0]?.booked
                                              : "0"}
                                            {" Interviews, "}
                                            {elem?.relability[0]?.count &&
                                            elem?.relability[0]?.booked
                                              ? (
                                                  (elem.relability[0].booked /
                                                    elem.relability[0].count) *
                                                  100
                                                ).toFixed(0)
                                              : "0"}
                                            {"% Reliability"})
                                          </p>
                                        </div>
                                      </div>
                                      <div className="interview__new__card__right">
                                        <div className="interview__new__card__right__top">
                                          <div className="interview__new__card__right__top__container">
                                            <img
                                              src={GraduationIcon}
                                              className="interview__new__card__right__top__container__image"
                                            />
                                            <p className="interview__new__card__right__top__container__text">
                                              {elem?.creatorData[0]
                                                ?.educationDetails[0]?.programme
                                                ? `${elem?.creatorData[0]?.educationDetails[0]?.programme} - ${elem?.creatorData[0]?.educationDetails[0]?.degree}`
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="interview__new__card__right__top__container">
                                            <img
                                              src={UniversityIcon}
                                              className="interview__new__card__right__top__container__image"
                                            />
                                            <p className="interview__new__card__right__top__container__text">
                                              {elem?.creatorData[0]
                                                ?.educationDetails[0]?.degree
                                                ? elem?.creatorData[0]
                                                    ?.educationDetails[0]
                                                    ?.institution
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="interview__new__card__right__top__container">
                                            <img
                                              src={LocationIcon}
                                              className="interview__new__card__right__top__container__image"
                                            />
                                            <p className="interview__new__card__right__top__container__text">
                                              {elem?.creatorData[0]?.location
                                                ? elem?.creatorData[0]?.location
                                                : ""}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {elem?.creatorId === userData?._id ? (
                                      <div className="interview__book__card__right">
                                        <button
                                          className={` ${
                                            elem?.creatorId !== userData?._id
                                              ? "book__button"
                                              : "book__button__disabled___your___session"
                                          }`}
                                          onClick={() => getSingleData(elem)}
                                        >
                                          Your session
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="interview__book__card__right">
                                        <button
                                          style={{
                                            height: "38px",
                                            padding: "13.15px 18px",
                                          }}
                                          className={` ${
                                            elem?.creatorAvalible?.every(
                                              (session) => session.booked
                                            )
                                              ? "book__button__disabled"
                                              : "book__button"
                                          }`}
                                          onClick={() => {
                                            handleElementClick(elem);
                                          }}
                                        >
                                          {elem?.creatorAvalible?.every(
                                            (session) => session.booked
                                          )
                                            ? "Fully booked"
                                            : "Book session"}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // height: "75vh",
                          marginTop: "184px",
                          marginBottom: "100px",
                        }}
                        className="reschedule__session__container__bottom__left"
                      >
                        <div className="no__found__main">
                          <div>
                            <img src={noFound} />
                          </div>
                          <div className="no__found">
                            No sessions available matching your search criteria
                          </div>
                          <div className="appear__here">
                            Sessions proposed by others would appear on this
                            page
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{ marginBottom: "300px", marginTop: "70px" }}
                      className="dashboard__footer"
                    >
                      <Footer />
                    </div>
                  </div>

                  {userData?.role == 1 && userData?._id ? (
                    ""
                  ) : (
                    <div className="col-xl-4 p-0 col-12 mt-0 available__container__schedules__guides">
                      <div className="interview__buysessions__home">
                        <Row className="gy-4">
                          <Col xs={12} style={{ padding: "0" }}>
                            <div className="guest__landing__leftbar">
                              <div className="per__month">
                                €25
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "#D0D3D9",
                                    background: "none",
                                    marginLeft: "0px",
                                  }}
                                >
                                  / Session
                                </span>{" "}
                                {/* <span
                                  className="discount"
                                  style={{
                                    marginLeft: "auto",
                                  }}
                                >
                                  50% Off
                                </span> */}
                              </div>
                              <div className="try">
                                <span
                                  style={{
                                    color: "white",
                                    marginRight: "3px",
                                  }}
                                >
                                  €50
                                </span>
                                for
                                <span
                                  style={{
                                    color: "white",
                                    margin: "0px 3px",
                                  }}
                                >
                                  2
                                </span>
                                sessions
                              </div>
                              <div>
                                <button
                                  className="started"
                                  onClick={() => {
                                    if (userData.role === 1) {
                                      ErrorToast(
                                        "You are logged in as a mentor. Only student accounts are able to buy sessions."
                                      );
                                    } else {
                                      ErrorToast(
                                        "Please log in with your account to buy sessions."
                                      );

                                      history.push("/login");
                                    }
                                  }}
                                >
                                  Buy now
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} style={{ padding: "0" }}>
                            <div className="guest__landing__leftbar">
                              <div className="per__month">
                                €20{" "}
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "#D0D3D9",
                                    background: "none",
                                    marginLeft: "0px",
                                  }}
                                >
                                  / Session
                                </span>
                                <span
                                  className="discount"
                                  style={{
                                    marginLeft: "auto",
                                  }}
                                >
                                  20% Off
                                </span>
                              </div>
                              <div className="try">
                                <span
                                  style={{
                                    color: "white",
                                    marginRight: "3px",
                                  }}
                                >
                                  €100
                                </span>
                                for
                                <span
                                  style={{
                                    color: "white",
                                    margin: "0px 3px",
                                  }}
                                >
                                  5
                                </span>
                                sessions
                              </div>
                              <div>
                                <button
                                  className="started"
                                  onClick={() => {
                                    if (userData.role === 1) {
                                      ErrorToast(
                                        "You are logged in as a mentor. Only student accounts are able to buy sessions."
                                      );
                                    } else {
                                      ErrorToast(
                                        "Please log in with your account to buy sessions."
                                      );

                                      history.push("/login");
                                    }
                                  }}
                                >
                                  Buy now
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} style={{ padding: "0" }}>
                            <div className="guest__landing__leftbar">
                              <div className="per__month">
                                €15{" "}
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "#D0D3D9",
                                    background: "none",
                                    marginLeft: "0px",
                                  }}
                                >
                                  / Session
                                </span>{" "}
                                <span
                                  className="discount"
                                  style={{
                                    marginLeft: "auto",
                                  }}
                                >
                                  40% Off
                                </span>
                              </div>

                              <div className="try">
                                <span
                                  style={{
                                    color: "white",
                                    marginRight: "3px",
                                  }}
                                >
                                  €150
                                </span>
                                for
                                <span
                                  style={{
                                    color: "white",
                                    margin: "0px 3px",
                                  }}
                                >
                                  10
                                </span>
                                sessions
                              </div>
                              <div>
                                <button
                                  className="started"
                                  onClick={() => {
                                    if (userData.role === 1) {
                                      ErrorToast(
                                        "You are logged in as a mentor. Only student accounts are able to buy sessions."
                                      );
                                    } else {
                                      ErrorToast(
                                        "Please log in with your account to buy sessions."
                                      );

                                      history.push("/login");
                                    }
                                  }}
                                >
                                  Buy now
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} style={{ padding: "0" }}>
                            <div className="guest__landing__leftbar">
                              <div className="per__month">
                                €10
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "#D0D3D9",
                                    background: "none",
                                    marginLeft: "0px",
                                  }}
                                >
                                  / Session
                                </span>{" "}
                                <span
                                  className="discount"
                                  style={{
                                    marginLeft: "auto",
                                  }}
                                >
                                  60% Off
                                </span>
                              </div>
                              <div className="try">
                                <span
                                  style={{
                                    color: "white",
                                    marginRight: "3px",
                                  }}
                                >
                                  €200
                                </span>
                                for
                                <span
                                  style={{
                                    color: "white",
                                    margin: "0px 3px",
                                  }}
                                >
                                  20
                                </span>
                                sessions
                              </div>
                              <div>
                                <button
                                  className="started"
                                  onClick={() => {
                                    if (userData.role === 1) {
                                      ErrorToast(
                                        "You are logged in as a mentor. Only student accounts are able to buy sessions."
                                      );
                                    } else {
                                      ErrorToast(
                                        "Please log in with your account to buy sessions."
                                      );

                                      history.push("/login");
                                    }
                                  }}
                                >
                                  Buy now
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Book
          setShowBook={setShowBook}
          singleData={singleData}
          slotTime={calTime}
          setSessionData={setTemp}
        />
      )}
      <NewsletterFooter />
    </>
  );
};

export default InterviewHome;
