// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule__button {
  border: none;
  padding: 10px 12px;
  border-radius: 12px;
  background-color: #3DD7A1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease-in;
}
.schedule__button__image {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
}
.schedule__button__text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #212224;
}

.schedule__button:hover .schedule__button__text {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Custom/Buttons/ScheduleSlot/ScheduleSlotButton.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,4BAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAAE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAEJ;;AAEA;EACE,WAAA;AACF","sourcesContent":[".schedule__button {\n  border: none;\n  padding: 10px 12px;\n  border-radius: 12px;\n  background-color: #3DD7A1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  transition: all 0.3s ease-in;\n  &__image {\n    width: 16px;\n    height: 16px;\n    display: flex;\n    align-items: center;\n  }\n  &__text {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n    color: #212224;\n  }\n}\n\n.schedule__button:hover .schedule__button__text{\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
