import React from "react";
import "./Footer.scss";

//
import FooterLogo from "../../assets/8.svg";
const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer__container__inner">
        <img
          src={FooterLogo}
          alt="footer logo"
          className="footer__container__inner__image"
        />
        <p className="footer__container__inner__text">
          That’s all the available slots at the moment
        </p>
      </div>
    </div>
  );
};

export default Footer;



export const EraningFooter = () => {
  return (
    <div className="footer__container">
      <div className="footer__container__inner">
        <img
          src={FooterLogo}
          alt="footer logo"
          className="footer__container__inner__image"
        />
        <p className="footer__container__inner__text">
          That’s all your payments at the moment
        </p>
      </div>
    </div>
  );
};