import React, { useEffect, useState } from "react";
import BookSession from "./BookSession/BookSession";
import MySessions from "./MySession/MySession";
import Interview from "./InterviewGuides/Interview";
import Performance from "./Performance/Performance";
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import PerformanceIcon from "../../assets/performance-icon.svg";
import PerformanceIconGreen from "../../assets/performance-icon-green.svg";
import InterviewIcon from "../../assets/interview-icon.svg";
import InterviewIconGreen from "../../assets/interview-icon-green.svg";
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import NavBar from "../Navbar/NavBar";
import "./Networkdashboard.scss";
import "./dashboardbar.scss";
import { useSelector } from "react-redux";
import pay from "../../assets/play-circle.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import NetworkDashboard from "./NetworkDashboard";


const NetworkMyPerformanceRoute = ({children}) => {
  return (
    <>
    <NetworkDashboard>

        <Performance  />
    </NetworkDashboard>
     
    </>
  );
};

export default NetworkMyPerformanceRoute;
