import React, { Suspense, useEffect, lazy } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// const MyComponent = React.lazy(() => import("./App"));
//
import "bootstrap/dist/css/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
//
import "react-range-slider-input/dist/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import { Provider } from "react-redux";
import { persistor, store } from "./service/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

// const clientId = "763045527898-19g28hoto18bt8pel4u9lrd24cr2npml.apps.googleusercontent.com"
const clientId =
  "550417388956-o8n6kg03s8h6qdvc0jb3cqr3f1jfhbpt.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}

        <App />

        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
