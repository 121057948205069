import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";
import axios from "axios";
import { API_URL } from "../../client";

export const createprofile = createAsyncThunk("createprofile", async (data) => {
  try {
    const res = await client.post(
      `/api/studentProfile`,
      {
        image: data?.image,
        userId: data?.userId,
        language: data?.language,
        location: data?.location,
        background: data?.background,
        educationDetails: data?.educationDetails,
        professionalDetails: data?.professionalDetails,
        cv: data?.cv,
      },
      { headers: { authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const updatprofile = createAsyncThunk("updateprofile", async (data) => {
  try {
    const res = await client.put(
      `/api/updateProfile/${data?.userId}`,
      {
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phoneNo,
        email: data?.email,
        language: data?.language,
        location: data?.location,
        background: data?.background,
        educationDetails: data?.educationDetails,
        professionalDetails: data.professionalDetails,
      },
      { headers: { authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const changeMail = createAsyncThunk("changeMail", async (data) => {
  try {
    const res = await axios.post(`${API_URL}/changeMail/${data.userId} `, {
      password: data.passwordVer,
    });
    return { status: res?.status, data: res?.data?.data };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (data) => {
    try {
      const res = await client.get(`/api/viewProfile/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error?.response.data.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getUserImage = createAsyncThunk("getUserImage", async (data) => {
  try {
    const res = await client.get(`/getImage/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res?.data };
  } catch (error) {
    return {
      message: error?.response.data.error,
      status: error?.response?.status,
    };
  }
});

// updating user educational info
export const updateUserEducation = createAsyncThunk(
  "updateUserEducation",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateStudentEducation/${data?.userId}`,
        {
          programe: data?.programe,
          id: data?.id,
          institution: data?.institution,
          degree: data?.degree,
          startDate: data?.startDate,
          endDate: data?.endDate,
          current: data?.current,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const updateUserProfession = createAsyncThunk(
  "updateUserProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateStudentProdfession/${data?.userId}`,
        {
          id: data.id,
          position: data?.position,
          startDate: data?.startDate,
          endDate: data?.endDate,
          current: data?.current,
          company: data?.company,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const deleteStudentProfession = createAsyncThunk(
  "updateUserProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/deleteStudentProfession/${data?.userId}`,
        {
          id: data.id,
         
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const deleteStudentEducation = createAsyncThunk(
  "deleteStudentEducation",
  async (data) => {
    try {
      const res = await client.put(
        `/api/deleteStudentEducation/${data?.userId}`,
        {
          id: data.id,
         
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add education from profile settings
export const addEducationProfile = createAsyncThunk(
  "addEducationProfile",
  async (data) => {
    try {
      let { educationdetail } = data;
      const res = await client.put(
        `/api/addStudentEducation/${data?.userId}`,
        {
          educationdetail,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add experience from profile settings
export const addExperienceProfile = createAsyncThunk(
  "addExperienceProfile",
  async (data) => {
    try {
      const { professionalDetail } = data;
      const res = await client.put(
        `/api/addStudentProfession/${data?.userId}`,
        {
          professionalDetail,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
