import React, { useState, useEffect } from "react";
import "./inteerview.scss";
import { MySessionCard } from "../../Custom";
import { Col, Row } from "react-bootstrap";
// icons
import AvatarIcon from "../../../assets/avatar.svg";
import Dots from "../../../assets/dot.png";
import level from "../../../assets/level.svg";
import SortIcon from "../../../assets/sort.svg";
import GridGreen from "../../../assets/grid-green.svg";
import ListView from "../../../assets/menu.svg";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import MyAccordion from "../../MyAccordion/MyAccordion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ScreenLoader from "../../Loader/ScreenLoader";
import moment from "moment";
import { getGuidWithMentor } from "../../../service/redux/middleware/network";
import noFound from "../../../assets/books.svg";



const Interview = ({ setSelectedComponent }) => {
  const [show, setShow] = useState(false);
  const [isGrid, setIsGrid] = useState(true);

  const [loader, setLoader] = useState(false);
  const [interviewGuides, setInterviewGuides] = useState([]);
  const [selectedInterviewGuides, setSelectedInterviewGuides] = useState({});
  useEffect(() => {
    // window.location.reload("http://localhost:3000/interview")
  }, []);

  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleViewGuide = (item) => {
    setSelectedInterviewGuides(item);
    setShow(true);
  };

  const data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getGuidWithMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setInterviewGuides(res?.payload.data);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const GotoBooking = () => {
    setSelectedComponent(0);
  };

  return (
    <>
      {loader && <ScreenLoader />}
      {!show ? (
        <div className="interview__container min__height__100">
          <div className="interview__container__top">
            <p className="interview__container__top__title">
              <span className="interview__container__top__title__sub">
                {interviewGuides?.length > 0 ? interviewGuides.length : "0"}
              </span>
              Sessions
            </p>
            <div className="interview__container__top__bottom">
              {/* <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="filter__button">
                  <img src={SortIcon} className="filter__button__image" />
                  <span className="filter__button__text">Filter</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                  <Dropdown.Item className="custom__dropdown__item" href="">
                    All
                  </Dropdown.Item>

                  <Dropdown.Item className="custom__dropdown__item" href="">
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item className="custom__dropdown__item" href="">
                    Oldest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              {/* change grid list view button */}
              {/* <div className="listgridview">
                <div
                  className={`listgridview__left ${
                    isGrid ? "grid__active" : "grid__disabled"
                  }`}
                  onClick={() => setIsGrid(true)}
                >
                  <img src={GridGreen} alt="grid button" />
                </div>
                <div
                  className={`listgridview__right ${
                    isGrid ? "grid__disabled" : "grid__active"
                  }`}
                  onClick={() => setIsGrid(false)}
                >
                  <img src={ListView} alt="list button" />
                </div>
              </div> */}
            </div>
          </div>

          <div className="interview__container__bottom">
            <Row className="gy-4">
              {!loader ? (
                interviewGuides?.length > 0 ? (
                  interviewGuides?.map((guide, index) => (
                    <Col
                      xs={12}
                      md={isGrid ? 6 : 12}
                      lg={isGrid ? 4 : 12}
                      key={guide?.UserProfile?.userId}
                    >
                      <MySessionCard>
                        <div className="interview__card__top">
                          <img
                            src={guide?.UserProfile?.image || AvatarIcon}
                            alt="avatar icon"
                            className="interview__card__top__image"
                          />
                          <>
                            {/* <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="dots__button"
                          >
                            <img
                              src={Dots}
                              alt="see more"
                              className="dots__button__dots"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                            <Dropdown.Item
                              className="custom__dropdown__item"
                              href=""
                            >
                              Delete Guide
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                            {/* <img src={Dots} alt="see more" className="interview__card__top__dots" /> */}
                          </>
                        </div>
                        <div
                          className={`${
                            !isGrid
                              ? "interview__card__flex__btn__container"
                              : "interview__card__flex__btn__container__mobile"
                          }`}
                        >
                          <div className="interview__card__center">
                            <h2 className="interview__card__center__title">
                              Session with {guide?.UserData?.firstName}{" "}
                              {guide?.UserData?.lastName}
                            </h2>

                            <div className="interview__card__center__bottom">
                              {/* <img
                              src={level}
                              alt="graduation icon"
                              className="interview__card__center__bottom__image"
                            /> */}
                              <p>
                                {
                                  guide?.interViewGuidData?.answeredQuestions[0]
                                    ?.difficulty
                                }{" "}
                                Guide
                              </p>
                            </div>
                            <div className="interview__card__center__top">
                              <div className="interview__card__center__top__container">
                                {guide?.avail?.startDateTime ? (
                                  <p>
                                    {moment(guide?.avail?.startDateTime).format(
                                      "HH:mm"
                                    )}
                                    -
                                    {moment(guide?.avail?.endDateTime).format(
                                      "HH:mm"
                                    )}
                                    ,{" "}
                                    {moment(guide?.avail?.endDateTime).format(
                                      "dddd, MMMM D"
                                    )}
                                  </p>
                                ) : (
                                  <p>---</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleViewGuide(guide)}
                            className={`btn-green`}
                          >
                            View guide
                          </button>
                        </div>{" "}
                      </MySessionCard>
                    </Col>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="nofound__container"
                  >
                    <div className="nofound__container__inner">
                      <div className="nofound__container__inner__image">
                        <img src={noFound} />
                      </div>
                      <div className="nofound__container__inner__container">
                        <div className="nofound__container__inner__container__title">
                          You currently have no interview guides
                        </div>
                        <div className="nofound__container__inner__container__text">
                          Interview guides from sessions taken would appear on
                          this page
                        </div>
                      </div>
                      <button
                        className="nofound__container__inner__button"
                        onClick={GotoBooking}
                      >
                        Book sessions
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <ScreenLoader />
              )}
            </Row>
          </div>
        </div>
      ) : (
        <MyAccordion
          network={true}
          setShow={setShow}
          selectedGuide={selectedInterviewGuides}
        />
      )}
    </>
  );
};

export default Interview;
