import React, {useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import arrowLeft from "../../assets/arrow-left.svg";
import "./myaccodrian.scss";
import { MySessionCard } from "../Custom";
import { Col, Row } from "react-bootstrap";
import AvatarIcon from "../../assets/Avatar.png";
import level from "../../assets/level.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";

const MyAccordion = ({ setShow, selectedGuide = {}, network }) => {
  console.log("this is the guid data", selectedGuide);

  const topicOrder = [
    "Intro",
    "Fit",
    "Situational",
    "IB",
    "Accounting concepts",
    "Accounting scenarios",
    "Enterprise value",
    "Valuation",
    "DCF",
    "Other",
    "Brainteasers",
  ];
  return (
    <div className="accordion__container">
      <div className="accordian__main">
        <p
          className="dashboard__title"
          onClick={() => setShow(false)}
          style={{ cursor: "pointer" }}
        >
          <img src={arrowLeft} />
          <span className="ms-2"> Back to Interview Guides</span>{" "}
        </p>
      </div>
      <div className="accordion__title">
        <div className="accordion__title__left">
          <h2 className="accordion__title__left__title">
            Session with {selectedGuide?.UserData?.firstName}{" "}
            {selectedGuide?.UserData?.lastName}
          </h2>
          <p className="accordion__title__left__description">
            {selectedGuide?.avail?.difficultyLevel ||
              selectedGuide?.interViewGuidData?.answeredQuestions[0]
                ?.difficulty}{" "}
            Guide
          </p>
        </div>
        {selectedGuide?.avail?.startDateTime ? (
          <div className="accordion__title__right">
            {moment(selectedGuide?.avail?.startDateTime).format("HH:mm")} -{" "}
            {moment(selectedGuide?.avail?.endDateTime).format("HH:mm")} ,{" "}
            {moment(selectedGuide?.avail?.date).format("dddd, MMMM D")}
          </div>
        ) : (
          <div className="accordion__title__right">--</div>
        )}
      </div>
      <div className="accordion__main">
        <Row className="flex-column-reverse flex-md-row">
          <Col xs={12} md={12} className="p-0">
            <section className="faqs border-grad1">
              <div className="mobile-responsive-table">
                <Accordion defaultActiveKey="0">
                  <div className="asked__heading">
                    <p className="dashboard__title">
                      <span className="">You were asked</span>
                    </p>
                  </div>

                  {!network
                    ? selectedGuide?.bookerInterviewGuid?.answeredQuestions
                        .sort(
                          (a, b) =>
                            topicOrder.indexOf(a.text.split(" - ")[0]) -
                            topicOrder.indexOf(b.text.split(" - ")[0])
                        )
                        .map((elem, index) => (
                          <Accordion.Item
                            eventKey={index}
                            style={{ background: "#1A1C1F" }}
                          >
                            <Accordion.Header>{`${index + 1}. ${elem?.text}`}</Accordion.Header>
                            <Accordion.Body style={{ background: "#1A1C1F" }}>
                              <div className="my-tabs">
                                <Tabs
                                  defaultActiveKey="answer"
                                  id="fill-tab-example"
                                  className="mb-3"
                                  fill
                                >
                                  <Tab eventKey="answer" title="Answer">
                                    <p className="para ms-md-1">
                                      {elem?.answer}
                                    </p>
                                  </Tab>
                                  <Tab eventKey="scorecard" title="Scorecard">
                                    <p className="para">Scorecard</p>
                                  </Tab>
                                </Tabs>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                    : selectedGuide?.interViewGuidData?.answeredQuestions
                        .sort(
                          (a, b) =>
                            topicOrder.indexOf(a.text.split(" - ")[0]) -
                            topicOrder.indexOf(b.text.split(" - ")[0])
                        )
                        .map((elem, index) => (
                          <Accordion.Item
                            eventKey={index}
                            style={{ background: "#1A1C1F" }}
                          >
                            <Accordion.Header>{elem?.text}</Accordion.Header>
                            <Accordion.Body style={{ background: "#1A1C1F" }}>
                              <div className="my-tabs">
                                <Tabs
                                  defaultActiveKey="answer"
                                  id="fill-tab-example"
                                  className="mb-3"
                                  fill
                                >
                                  <Tab eventKey="answer" title="Answer">
                                    <p className="para1 ms-md-1">
                                      {elem?.answer}
                                    </p>
                                  </Tab>
                                  <Tab eventKey="scorecard" title="Scorecard">
                                    <p className="para">Scorecard</p>
                                  </Tab>
                                </Tabs>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                </Accordion>
              </div>
            </section>
          </Col>
          {/* {sessionData.map((arr, index) => (
            <Col xs={12} md={4} xl={4} key={arr.id} className="mb-3 mb-md-0">
              <MySessionCard>
                <div className="mysession__container__top">
                  <img
                    src={AvatarIcon}
                    alt="avatar icon"
                    className="mysession__container__top__image"
                  />
                </div>
                <div className="mysession__container__center">
                  <h2 className="mysession__container__center__title">
                    Interview with Angelo Doe
                  </h2>
                  <div className="mysession__container__center__bottom">
                    <img
                      src={level}
                      alt="graduation icon"
                      className="mysession__container__center__bottom__image"
                    /> 
                    <p>Beginner Guide</p>
                  </div>
                  <div className="mysession__container__center__top">
                    <div className="accordion__font__p">
                      <p>11:15am – 12:15pm, Thursday, August 17</p>
                    </div>
                  </div>
                </div>
              </MySessionCard>
            </Col>
          ))} 
        */}
        </Row>
      </div>
    </div>
  );
};

export default MyAccordion;
