import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../client";
import axios from "axios";
import { API_URL } from "../../client";

export const addDateAvailbility = createAsyncThunk(
  "addDateAvailbility",
  async (data) => {
    try {
      const res = await client.post(
        `/api/mentorAvalibility/${data.creatorId}`,
        {
          // selectSession: data?.selectSession,
          timeSlot: data?.timeSlot,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// export const MentorupdatePricing = createAsyncThunk(
//   "updatePricing",
//   async (data) => {
//     try {
//       const res = await client.patch(`/api/updatePricing/${data.creatorId}`, {
//         timezone: data?.timezone,
//         date: data.meetingDate,
//         startTime: data?.startTime,
//         endTime: data?.endTime,
//       });
//       return { status: res.status, data: res.data?.data };
//     } catch (error) {
//       return {
//         message: error.response.data.error,
//         status: error.response.status,
//       };
//     }
//   }
// );

export const createMentorProfile = createAsyncThunk(
  "createMentorProfile",
  async (data) => {
    try {
      console.log('data',data);
      const res = await client.post(`/api/mentorProfile`, data, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data?.message };
    } catch (error) {
      console.log(error)
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
      
    }
  }
);
export const viewMentorSession = createAsyncThunk(
  "mentorMySession",
  async (data) => {
    try {
      const res = await client.get(`/api/mentorMySession/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const viewMentor = createAsyncThunk("viewMentor", async (data) => {
  try {
    const res = await client.get(`/api/viewMentor/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const viewMentorProfile = createAsyncThunk(
  "viewMentorProfile",
  async (data) => {
    try {
      const res = await client.get(`/api/viewMentorProfile/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const cancelMentorSession = createAsyncThunk(
  "cancelMentorSession",
  async (data) => {
    try {
      const res = await client.post(
        `/api/cancelSession/${data?.creatorId}`,
        {},
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const updateMentorProfile = createAsyncThunk(
  "updateMentorProfile",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateMentorProfile/${data?.userId}`,
        {
          firstName: data?.firstName,
          lastName: data?.lastName,
          location: data?.location,
          bio: data?.bio,
          language: data?.language,
          email: data?.email,
          phone: data?.phoneNo,
          investmentBank: data?.investmentBank,
          // educationInfo:data?.educationInfo,
          // professionalDetails:data?.professionalDetails
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const updateMentorProfession = createAsyncThunk(
  "updateMentorProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateMentorProfession/${data?.userId}`,
        {
          id: data.id,
          position: data?.position,
          startDate: data?.startDate,
          endDate: data?.endDate,
          current: data?.current,
          company: data?.company,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const deleteMentorProfession = createAsyncThunk(
  "deleteMentorProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/deleteProfessionalExperinece/${data?.userId}`,
        {
          id: data.id,
         
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const deleteMentorBank = createAsyncThunk(
  "deleteMentorProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/deleteInvestmentBank/${data?.userId}`,
        {
          id: data.id,
         
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const deleteEducations = createAsyncThunk(
  "deleteMentorProfession",
  async (data) => {
    try {
      const res = await client.put(
        `/api/deleteEducation/${data?.userId}`,
        {
          id: data.id,
         
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const updateMentorEducation = createAsyncThunk(
  "updateMentorEducation",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateMentorEducation/${data?.userId}`,
        {
          id: data?.id,
          institute: data?.institute,
          field: data?.field,
          stateDate: data?.stateDate,
          endDate: data?.endDate,
          current: data?.current,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const updateMentorBank = createAsyncThunk(
  "updateMentorBank",
  async (data) => {
    try {
      const res = await client.put(
        `/api/updateInvestmentBank/${data?.userId}`,
        {
          id: data?.id,
          bank: data?.bank,
          position: data?.position,
          stateDate: data?.stateDate,
          endDate: data?.endDate,
          current: data?.current,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const mentorSignUpEmail = createAsyncThunk(
  "mentorSignUpEmail",
  async (data) => {
    try {
      const res = await axios.post(
        `${API_URL}/api/mentorSingUp/${data.email}`,
        data.objectToSend
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const mentorUpdateServices = createAsyncThunk(
  "mentorUpdateServices",
  async (data) => {
    try {
      const res = await client.patch(
        `${API_URL}/api/updatePricing/${data?.mentorId}`,
        {
          summary: data?.summary?.toString(),
          currency: data?.currency,
          generalGuidanceDescription: data?.generalGuidanceDescription,
          generalGuidance: data?.generalGuidance,
          cvReviewDescription: data?.cvReviewDescription,
          cvReview: data?.cvReview,
          interviewPracticeDescription: data?.interviewPracticeDescription,
          interviewPractice: data?.interviewPractice,
          coverLetterDescription: data?.coverLetterDescription,
          coverLetter: data?.coverLetter,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const mentorDeleteServicesOfGeneralGuidance = createAsyncThunk(
  "mentorUpdateServices",
  async (data) => {
    try {
      const res = await client.patch(
        `${API_URL}/api/updatePricing/${data?.mentorId}`,
        {
          generalGuidance: data?.generalGuidance,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const mentorDeleteServiceCVReviews = createAsyncThunk(
  "mentorUpdateServices",
  async (data) => {
    try {
      const res = await client.patch(
        `${API_URL}/api/updatePricing/${data?.mentorId}`,
        {
          cvReview: data?.cvReview,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);
export const mentorDeleteServicesInterviewPractice = createAsyncThunk(
  "mentorUpdateServices",
  async (data) => {
    try {
      const res = await client.patch(
        `${API_URL}/api/updatePricing/${data?.mentorId}`,
        {
          interviewPractice: data?.interviewPractice,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const mentorDeleteServicesCoverLetterReview = createAsyncThunk(
  "mentorUpdateServices",
  async (data) => {
    try {
      const res = await client.patch(
        `${API_URL}/api/updatePricing/${data?.mentorId}`,
        {
          coverLetter: data?.coverLetter,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const createStripe = createAsyncThunk("createStripe", async (data) => {
  try {
    const res = await axios.post(`${API_URL}/api/mentorStripAccount/${data}`);
    return { status: res.status, data: res?.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const mentorStripAccount = createAsyncThunk(
  "mentorStripAccount",
  async (data) => {
    try {
      const res = await axios.post(`${API_URL}/api/mentorStripAccount/${data}`);
      return { status: res.status, data: res?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const deleteTimeSlot = createAsyncThunk(
  "deleteTimeSlot",
  async (data) => {
    try {
      const res = await client.post(
        `/api/deleteTimeSlot/${data?.creatorId}`,
        {
          meetingId: data?.meetingId,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const checkMentorSrtipeAccount = createAsyncThunk(
  "no-strip-account",
  async (data) => {
    try {
      const res = await client.get(`/no-strip-account/${data?.creatorId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const rescheduleMentorSession = createAsyncThunk(
  "rescheduleMentorSession",
  async (data) => {
    try {
      const res = await client.post(
        `/api/rescheduleMentorSession/${data?.userId}`,
        {
          timezone: data?.timezone,
          timeZoneValue: data?.timeZoneValue,
          date: data?.date,
          startTime: data?.startTime,
          endTime: data?.endTime,
          meetingId: data?.meetingId,
        },
        { headers: { authorization: `${data?.token}` } }
      );

      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add education from mentor settings
export const addMentorEducation = createAsyncThunk(
  "addMentorEducation",
  async (data) => {
    try {
      let { educationdetail } = data;
      const res = await client.put(
        `/api/addMentorEducation/${data?.userId}`,
        {
          educationdetail,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add other experience from mentor settings
export const addMentorProfessional = createAsyncThunk(
  "addMentorProfessional",
  async (data) => {
    try {
      const { professionalDetail } = data;
      const res = await client.put(
        `/api/addMentorProfessional/${data?.userId}`,
        {
          professionalDetail,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add other experience from mentor settings
export const addInvestmentBank = createAsyncThunk(
  "addInvestmentBank",
  async (data) => {
    try {
      const { investmentBanks } = data;
      const res = await client.put(
        `/api/addInvestmentBank/${data?.userId}`,
        {
          investmentBanks,
        },
        { headers: { authorization: `${data?.token}` } }
      );
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// add other experience from mentor settings
export const getStripAccount = createAsyncThunk(
  "getStripAccount",
  async (data) => {
    try {
      const res = await client.get(`/getStripAccount/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data?.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

// get already currency

export const getCurrency = createAsyncThunk("getCurrency", async (data) => {
  try {
    const res = await client.get(`/api/getCurrency/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});
