import React, { useState, useEffect } from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
//
import infocircle from "../../../assets/info-circle.svg";
import GreenStar from "../../../assets/star-green.svg";
import WalletIcon from "../../../assets/wallet.svg";
import MovieIcon from "../../../assets/video.svg";
import DocumentIcon from "../../../assets/document-green.svg";
import DiamondIcon from "../../../assets/diamonds.svg";
import Dots from "../../../assets/dot.svg";
import ArrowDownIcon from "../../../assets/arrow-down.svg";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";
import HalfStar from "../../../assets/star-half.svg";
import { useDispatch, useSelector } from "react-redux";
import { ratingForMentorDashboard } from "../../../service/redux/middleware/rating";

//
import "./performance.scss";
import MySessionCard from "../../Custom/Cards/MySessions/MySessionCard";
const Performance = () => {
  const customIcons = [
    {
      icon: <EmptyStar size={50} />,
    },
    {
      icon: <EmptyStar size={50} />,
    },
    {
      icon: <EmptyStar size={50} />,
    },
    {
      icon: <EmptyStar size={50} />,
    },
    {
      icon: <EmptyStar size={50} />,
    },
  ];

  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const [performanceRating, setPerformanceRating] = useState("");
  const data = {
    userId: userData?._id,
    token: token,
  };
  useEffect(() => {
    dispatch(ratingForMentorDashboard(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setPerformanceRating(res?.payload?.data);
      }
    });
  }, []);

  const average =
    Number(performanceRating?.communication) +
    Number(performanceRating?.fitQuestion) +
    Number(performanceRating?.presence) +
    Number(performanceRating?.technicalQuestion);
  const averageRating = (Number(average) / 4)?.toFixed(1);

  return (
    <div style={{ color: "white" }} className="performance__container">
      <Row className="performance__container__top">
        <Col>
          <MySessionCard>
            <div className="performance__container__top__inner">
              <div className="performance__container__top__inner__top">
                <div className="performance__container__top__inner__top__left">
                  <div className="performance__container__top__inner__top__left__container">
                    <h2 className="performance__container__top__inner__top__left__container__title">
                      Overall performance rating
                    </h2>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip">
                          <p className="custom__tooltip">
                            Calculated as the average of performance ratings given by your peers when you are in the role of a candidate, across key categories: Presence, Communication Skills, Fit Questions, and Technical Questions.
                          </p>
                        </Tooltip>
                      }
                    >
                      <img
                        src={infocircle}
                        alt="info circle"
                        className="performance__container__top__inner__top__left__container__image"
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="performance__container__top__inner__top__left__text">
                    {averageRating > 0 ? (
                      <p>{Number(averageRating)?.toFixed(1)}</p>
                    ) : (
                      <div className="bar_bar bar_m"></div>
                    )}
                  </div>
                </div>
                {/* <div className="performance__container__top__inner__top__right">
                  <button className="btn-outline-grey">
                    <div className="performance__btn__overlay">
                      <p>Overlay</p>
                      <img src={ArrowDownIcon} className="" />
                    </div>
                  </button>
                </div> */}
              </div>
              <div className="performance__container__top__inner__radial">
                <div className="performance__container__top__inner__radial__inner">
                  <CircularProgressbarWithChildren
                    value={
                      averageRating > 0
                        ? ((Number(averageRating) / 5) * 100).toFixed(1)
                        : "0"
                    }
                    maxValue={100}
                    //  text={`${75}%`}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `#3DD7A1`, //color of progrees bar
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // Rotate the path
                        transform: "rotate(0turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: "rgb(77, 87, 86)",
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",
                        // Rotate the trail
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: "#fff",
                        // Text size
                        fontSize: "24px",
                      },
                      // Customize background - only used when the `background` prop is true
                      background: {
                        fill: "#354a12",
                      },
                    }}
                  >
                    <div className="ratingstar__container">
                      <Rating
                        readonly={true}
                        initialValue={
                          averageRating > 0
                            ? Number(averageRating).toFixed(1)
                            : "0"
                        }
                        // value={Number(performanceRating?.totalReviews).toFixed(1)}
                        allowFraction={true}
                        fillIcon={<img src={FullStar} width={20} alt="" />}
                        emptyIcon={<img src={EmptyStar} width={20} alt="" />}
                        size={20}
                        fillColor="#F0F1F3"
                      />
                    </div>
                    {averageRating > 0 ? (
                      ""
                    ) : (
                      <strong className="no__feedback">No feedback</strong>
                    )}
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </MySessionCard>
        </Col>
      </Row>
      <Row className="performance__container__bottom">
        {/* session fit */}
        <Col md={6} lg={3} style={{ marginTop: "20px" }}>
          <MySessionCard>
            <div className="performance__container__bottom__card">
              <div className="performance__container__bottom__card__top">
                <div className="performance__container__bottom__card__top__container">
                  <p>Fit questions</p>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        <p className="custom__tooltip">
                        Your cumulative rating as a candidate, given by your peers, assessing responses to fit and situational questions. Evaluations include likability, cultural fit, and how well others might enjoy working with you. Consider the "airplane test" – would interviewers want to be stuck in an airport with you?
                        </p>
                      </Tooltip>
                    }
                  >
                    <img src={infocircle} alt="info circle" />
                  </OverlayTrigger>
                </div>
                <div className="performance__container__bottom__card__top__bottom">
                  {performanceRating?.fitQuestion ? (
                    <p>{Number(performanceRating?.fitQuestion).toFixed(1)}</p>
                  ) : (
                    <div className="bar_bar bar_m"></div>
                  )}
                </div>
              </div>
              <div className="performance__container__bottom__card__bottom">
                <div className="performance__container__bottom__card__bottom__container">
                  <CircularProgressbarWithChildren
                    value={
                      performanceRating?.fitQuestion
                        ? (
                          (Number(performanceRating?.fitQuestion) / 5) *
                          100
                        ).toFixed(1)
                        : "0"
                    }
                    maxValue={100}
                    //  text={`${75}%`}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `#3DD7A1`, //color of progrees bar
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // Rotate the path
                        transform: "rotate(0turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: "rgb(77, 87, 86)",
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",
                        // Rotate the trail
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: "#fff",
                        // Text size
                        fontSize: "24px",
                      },
                      // Customize background - only used when the `background` prop is true
                      background: {
                        fill: "#354a12",
                      },
                    }}
                  >
                    <div className="ratingstar__container">
                      <Rating
                        readonly={true}
                        initialValue={
                          performanceRating?.fitQuestion
                            ? Number(performanceRating?.fitQuestion).toFixed(1)
                            : "0"
                        }
                        allowFraction={true}
                        fillIcon={<img src={FullStar} width={20} />}
                        emptyIcon={<img src={EmptyStar} width={20} />}
                        size={20}
                        fillColor="#F0F1F3"
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </MySessionCard>
        </Col>
        {/* technical skills */}
        <Col md={6} lg={3} style={{ marginTop: "20px" }}>
          <MySessionCard>
            <div className="performance__container__bottom__card">
              <div className="performance__container__bottom__card__top">
                <div className="performance__container__bottom__card__top__container">
                  <p>Technical Skills</p>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        <p className="custom__tooltip">
                        Your cumulative rating as a candidate, given by your peers, for responses to technical questions. Interviewers expect detailed knowledge in accounting, finance, valuation, and M&A/LBO modelling. Be prepared for unexpected technical challenges.
                        </p>
                      </Tooltip>
                    }
                  >
                    <img src={infocircle} alt="info circle" />
                  </OverlayTrigger>
                </div>
                <div className="performance__container__bottom__card__top__bottom">
                  {performanceRating?.technicalQuestion ? (
                    <p>
                      {Number(performanceRating?.technicalQuestion).toFixed(1)}
                    </p>
                  ) : (
                    <div className="bar_bar bar_m"></div>
                  )}
                </div>
              </div>
              <div className="performance__container__bottom__card__bottom">
                <div className="performance__container__bottom__card__bottom__container">
                  <CircularProgressbarWithChildren
                    value={
                      performanceRating?.technicalQuestion
                        ? (
                          (Number(performanceRating?.technicalQuestion) / 5) *
                          100
                        ).toFixed(1)
                        : "0"
                    }
                    maxValue={100}
                    //  text={`${75}%`}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `#3DD7A1`, //color of progrees bar
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // Rotate the path
                        transform: "rotate(0turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: "rgb(77, 87, 86)",
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",
                        // Rotate the trail
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: "#fff",
                        // Text size
                        fontSize: "24px",
                      },
                      // Customize background - only used when the `background` prop is true
                      background: {
                        fill: "#354a12",
                      },
                    }}
                  >
                    <div className="ratingstar__container">
                      <Rating
                        readonly={true}
                        initialValue={
                          performanceRating?.technicalQuestion
                            ? Number(
                              performanceRating?.technicalQuestion
                            ).toFixed(1)
                            : "0"
                        }
                        allowFraction={true}
                        fillIcon={<img src={FullStar} width={20} />}
                        emptyIcon={<img src={EmptyStar} width={20} />}
                        size={20}
                        fillColor="#F0F1F3"
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </MySessionCard>
        </Col>
        {/* Communication Skills */}
        <Col md={6} lg={3} style={{ marginTop: "20px" }}>
          <MySessionCard>
            <div className="performance__container__bottom__card">
              <div className="performance__container__bottom__card__top">
                <div className="performance__container__bottom__card__top__container">
                  <p>Communication skills</p>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip">
                        <p className="custom__tooltip">
                        Your cumulative rating as a candidate, given by your peers, evaluating communication effectiveness during interviews. Focus on maintaining eye contact, expressing yourself clearly, structuring answers, and demonstrating enthusiasm. These are crucial elements for success.
                        </p>
                      </Tooltip>
                    }
                  >
                    <img src={infocircle} alt="info circle" />
                  </OverlayTrigger>
                </div>
                <div className="performance__container__bottom__card__top__bottom">
                  {/* <div className="bar_bar bar_m"></div> */}
                  {performanceRating?.communication ? (
                    <p>{Number(performanceRating?.communication).toFixed(1)}</p>
                  ) : (
                    <div className="bar_bar bar_m"></div>
                  )}
                </div>
              </div>
              <div className="performance__container__bottom__card__bottom">
                <div className="performance__container__bottom__card__bottom__container">
                  <CircularProgressbarWithChildren
                    value={
                      performanceRating?.communication
                        ? (
                          (Number(performanceRating?.communication) / 5) *
                          100
                        ).toFixed(1)
                        : "0"
                    }
                    maxValue={100}
                    //  text={`${75}%`}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `#3DD7A1`, //color of progrees bar
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // Rotate the path
                        transform: "rotate(0turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: "rgb(77, 87, 86)",
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",
                        // Rotate the trail
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: "#fff",
                        // Text size
                        fontSize: "24px",
                      },
                      // Customize background - only used when the `background` prop is true
                      background: {
                        fill: "#354a12",
                      },
                    }}
                  >
                    <div className="ratingstar__container">
                      <Rating
                        readonly={true}
                        initialValue={
                          performanceRating?.communication
                            ? Number(performanceRating?.communication).toFixed(
                              1
                            )
                            : "0"
                        }
                        allowFraction={true}
                        fillIcon={<img src={FullStar} width={20} />}
                        emptyIcon={<img src={EmptyStar} width={20} />}
                        size={20}
                        fillColor="#F0F1F3"
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </MySessionCard>
        </Col>
        {/* Presence */}
        <Col md={6} lg={3} style={{ marginTop: "20px" }}>
          <MySessionCard>
            <div className="performance__container__bottom__card">
              <div className="performance__container__bottom__card__top">
                <div className="performance__container__bottom__card__top__container">
                  <p>Presence</p>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip">
                        <p className="custom__tooltip">
                        Your cumulative rating as a candidate, given by your peers, assessing how well you project presence during interviews. Factors include posture, body language, and engagement. Pay attention to cues like sitting up straight and maintaining eye contact.
                      </p>
                      </Tooltip>
                    }
                  >
                    <img src={infocircle} alt="info circle" />
                  </OverlayTrigger>
                </div>
                <div className="performance__container__bottom__card__top__bottom">
                  {performanceRating?.presence ? (
                    <p>{Number(performanceRating?.presence).toFixed(1)}</p>
                  ) : (
                    <div className="bar_bar bar_m"></div>
                  )}
                </div>
              </div>
              <div className="performance__container__bottom__card__bottom">
                <div className="performance__container__bottom__card__bottom__container">
                  <CircularProgressbarWithChildren
                    value={
                      performanceRating?.presence
                        ? (
                          (Number(performanceRating?.presence) / 5) *
                          100
                        ).toFixed(1)
                        : "0"
                    }
                    maxValue={100}
                    //  text={`${75}%`}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      // Customize the path, i.e. the "completed progress"
                      path: {
                        // Path color
                        stroke: `#3DD7A1`, //color of progrees bar
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // Rotate the path
                        transform: "rotate(0turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the circle behind the path, i.e. the "total progress"
                      trail: {
                        // Trail color
                        stroke: "rgb(77, 87, 86)",
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",
                        // Rotate the trail
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      // Customize the text
                      text: {
                        // Text color
                        fill: "#fff",
                        // Text size
                        fontSize: "24px",
                      },
                      // Customize background - only used when the `background` prop is true
                      background: {
                        fill: "#354a12",
                      },
                    }}
                  >
                    <div className="ratingstar__container">
                      <Rating
                        readonly={true}
                        initialValue={
                          performanceRating?.presence
                            ? Number(performanceRating?.presence).toFixed(1)
                            : "0"
                        }
                        allowFraction={true}
                        fillIcon={<img src={FullStar} width={20} />}
                        emptyIcon={<img src={EmptyStar} width={20} />}
                        allowHover={false}
                        size={20}
                        fillColor="#F0F1F3"
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </MySessionCard>
        </Col>
      </Row>
    </div>
  );
};

export default Performance;
