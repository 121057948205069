import { forgotPassword } from "../service/redux/middleware/password";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast } from "../components/MyToaster";
import NavLogo from "../assets/8.svg"
import Form from "react-bootstrap/Form";
import MyLoader from "../components/Loader/MyLoader";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";



const ResetPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [onSuccess, setOnSuccess] = useState(false);

    const [loader, setLoader] = useState(false)
    const token = useSelector((state) => state?.signin?.signInData?.token);

    const formValidation = () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(email);
        if (!email) {
            ErrorToast("Please enter email");
            return false;
        } else if (!isValidEmail) {
            ErrorToast("Please enter valid email");
            return false;
        }
    };

    const onForgotPassword = () => {
        const res = formValidation();
        if (res === false) {
            return false;
        }
        setLoader(true)
        const data = {
            email: email,
            token: token
        }
        dispatch(forgotPassword(data)).then((res) => {
            if (res?.payload?.status === 200) {
                setLoader(false)
                setOnSuccess(true);
            }
            else {
                ErrorToast(res?.payload?.message);
                setLoader(false)
            }
        });
    };
    return (

        <div className="PersonalInfoSec">
            {!onSuccess ? (
                <div className="widthDiv" style={{ width: "400px" }}>
                    <div className="PersonalInfoSec__nav">
                        <a href="https://www.fast-track.community/">
                            <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="" />
                        </a>
                    </div>
                    <div className="PersonalInfoSec__Heading">
                        <h1 className="">Please Enter your Email for Reset Password</h1>
                    </div>
                    <div className="PersonalInfoSecForm">
                        <Form.Group className=" loginfield " controlId="">
                            <Form.Label className="loginfield__Label " >Enter email</Form.Label>
                            <Form.Control
                                type="email"
                                className="loginfield__type "
                                placeholder="Enter email "
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <div className="wrapper__of__login__green__button" onClick={onForgotPassword}>
                            <button
                            // onClick={(e) => {
                            //     onForgotPassword(e);
                            // }}
                            >Reset</button>
                            {loader && <MyLoader />}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="widthDiv" style={{ width: "400px" }}>
                    <div className="PersonalInfoSec__nav">
                        <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="" />
                    </div>
                    <div className="PersonalInfoSec__Heading">
                        <h1
                            className="text-center"
                            fontSize="14px"
                            weight="400"
                            color="#11AF22"
                        >
                            An email is sent with the link to change password
                        </h1>
                    </div>
                    <div className="login__green__button">
                        <a href='https://mail.google.com/' style={{ color: 'black', textAlign: 'center' }} target='_blank'
                        >Go to gmail</a>
                    </div>
                </div>
            )}
        </div>
    )

}
export default ResetPassword