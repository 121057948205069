import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../client";

export const signin = createAsyncThunk("signin", async (data) => {
  try {
    const res = await axios.post(`${API_URL}/auth/login`, data);
    // localStorage.setItem("token", res?.data?.token);
    return { status: res?.status, data: res?.data?.data, token: res?.data?.token };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const signinGoogleAuth = createAsyncThunk("signin", async (data) => {
  try {
    const res = await axios.post(`${API_URL}/auth/google/Login `, data);
    // localStorage.setItem("token", res?.data?.token);
    return { status: res?.status, data: res?.data?.data, token: res?.data?.token };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const signinLinkedAuth = createAsyncThunk("signin", async (code) => {
  try {
    const res = await axios.post(`${API_URL}/linkedin/login `, { code: code })
    console.log("this is resposne", res)
    // localStorage.setItem("token", res?.data?.token);
    return { status: res?.status, data: res?.data?.data, token: res?.data?.token };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const storeLogout = createAsyncThunk("signin", async (data1) => {
  try {
    return { data: data1 };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
