import React, { useState, useEffect } from "react";
import "./AvailableCard.scss";
// icons
import AvatarIcon from "../../../../assets/Avatar.png";
import VideoIcon from "../../../../assets/video.svg";
import StarIcon from "../../../../assets/star.svg";
import Dot from "../../../../assets/bi_dot.svg";
import LocationIcon from "../../../../assets/location.svg";
import UniversityIcon from "../../../../assets/university.svg";
import GraduationIcon from "../../../../assets/graduation.svg";
// button
import BookButton from "../../Buttons/BookButton/BookButton";
import Ratings from "../../Ratings/Ratings";
import { useDispatch, useSelector } from "react-redux";

const AvailableCard = ({ setShow, sessionData }) => {
  return (
    <div className="available__card__container">
      {sessionData?.creatorData?.map((elem, index) => {
        return (
          <div className="available__left__card">
            <div className="available__card__container__left">
              <img
                src={AvatarIcon}
                alt="user avatar"
                className="available__card__container__left__image"
              />
            </div>
            <div className="available__card__container__center">
              <div className="available__card__container__center__created">
                <p className="available__card__container__center__created__title">
                  Created by Angelo Doe
                </p>
                <p className="available__card__container__center__created__text">
                  98% match
                </p>
              </div>
              <div className="available__card__container__center__details">
                <div className="available__card__container__center__details__inner">
                  <img
                    src={LocationIcon}
                    className="available__card__container__center__details__inner__image"
                  />
                  <p className="available__card__container__center__details__inner__text">
                    {elem?.location ? elem?.location : ""}
                  </p>
                </div>
                <div className="available__card__container__center__details__inner">
                  <img
                    src={GraduationIcon}
                    className="available__card__container__center__details__inner__image"
                  />
                  <p className="available__card__container__center__details__inner__text">
                    {elem?.educationDetails[0]?.degree
                      ? elem?.educationDetails[0]?.degree
                      : ""}
                  </p>
                </div>
                <div className="available__card__container__center__details__inner">
                  <img
                    src={UniversityIcon}
                    className="available__card__container__center__details__inner__image"
                  />
                  <p className="available__card__container__center__details__inner__text">
                    {elem?.educationDetails[0]?.institution
                      ? elem?.educationDetails[0]?.institution
                      : ""}
                  </p>
                </div>
              </div>
              <div className="available__card__container__center__ratings">
                <Ratings />
                <p>(12 Interviews, 98% Reliability)</p>
              </div>
            </div>
          </div>
        );
      })}
      {/* <div className="available__left__card">
        <div className="available__card__container__left">
          <img
            src={AvatarIcon}
            alt="user avatar"
            className="available__card__container__left__image"
          />
        </div>
        <div className="available__card__container__center">
          <div className="available__card__container__center__created">
            <p className="available__card__container__center__created__title">
              Created by Angelo Doe
            </p>
            <p className="available__card__container__center__created__text">
              98% match
            </p>
          </div>
          <div className="available__card__container__center__details">
            <div className="available__card__container__center__details__inner">
              <img
                src={LocationIcon}
                className="available__card__container__center__details__inner__image"
              />
              <p className="available__card__container__center__details__inner__text">
                Portugal
              </p>
            </div>
            <div className="available__card__container__center__details__inner">
              <img
                src={GraduationIcon}
                className="available__card__container__center__details__inner__image"
              />
              <p className="available__card__container__center__details__inner__text">
                MBA
              </p>
            </div>
            <div className="available__card__container__center__details__inner">
              <img
                src={UniversityIcon}
                className="available__card__container__center__details__inner__image"
              />
              <p className="available__card__container__center__details__inner__text">
                London School of Business
              </p>
            </div>
          </div>
          <div className="available__card__container__center__ratings">
            <Ratings />
            <p>(12 Interviews, 98% Reliability)</p>
          </div>
        </div>
      </div> */}
      <div className="available__card__container__right">
        <BookButton setShow={setShow} />
      </div>
    </div>
  );
};

export default AvailableCard;
