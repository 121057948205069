import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeRangeSlider from "react-time-range-slider";
import SortIcon from "../../assets/sort.svg";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import InfoCircle from "../../assets/info-circle.svg";
import Wallet from "../../assets/wallet.svg";
import VerifyIcon from "../../assets/verify.svg";
import GreenStar from "../../assets/star-green.svg";
import VideoIcon from "../../assets/video.svg";
import DropDownImage from "../../assets/arrow-down.svg";
import { MySessionCard } from "../Custom";
import { viewSession } from "../../service/redux/middleware/session";
import LevelIcon from "../../assets/level-session.svg";
import DocumentIcon from "../../assets/document.svg";
import EyeIcon from "../../assets/eye.svg";
import LockIcon from "../../assets/lock.svg";
import AvatarIcon from "../../assets/avatar.svg";
import EditIcon from "../../assets/edit11.svg";
import EmptyStar from "../../assets/star.svg";
import FullStar from "../../assets/star-full.svg";
import StarFull from "../../assets/star-yellow.svg";
import LocationIcon from "../../assets/location.svg";
import UniversityIcon from "../../assets/university.svg";
import GraduationIcon from "../../assets/graduation.svg";
import ArrowLeftMini from "../../assets/arrow-left-mini.svg";
import ArrowRightMini from "../../assets/arrow-right-mini.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getMeetingTime } from "../../service/redux/middleware/meeting";
import meeting from "../../assets/link-square.svg";
import Book from "./Book/Book";
import { useHistory } from "react-router-dom";
import { getCredits } from "../../service/redux/middleware/credits";
import { ratingForInterviewdDashboard } from "../../service/redux/middleware/rating";
import SessionModal from "../SessionModal/SessionModal";
import RescheduleSession from "../Slot/RescheduleSession/RescheduleSession";
import UserProfile from "../Slot/UserProfile/UserProfile";
import { ErrorToast } from "../MyToaster";
import EditPurposeScreen from "./BookSessionEditScreen/EditPurposeScreen";
import { Rating } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import noFound from "../../assets/searchempty.svg";
import ScreenLoader from "../Loader/ScreenLoader";

const BookSession = ({
  mySessionData,
  notBookedSessionData,
  setNotBookedSessionData,
  mysessionId,
  relaible,
  showBook,
  setShowBook,
}) => {
  const [showEditProposeScreen, setShowEditProposeScreen] = useState(false);
  const [myCredits, setMyCredits] = useState("");
  const [rating, setRating] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [education, setEducation] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [temp, setTemp] = useState([]);
  const [singleData, setSingleData] = useState("");
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [sessionModalM, setShowSessionModalM] = useState(false);
  const [sessionModalData, setShowSessionModalData] = useState({});
  const [loader, setLoader] = useState(false);
  //
  const featureRef = useRef();
  const [value, setValue] = useState({
    start: "00:00",
    end: "23:59",
  });
  const changeStartHandler = () => {
    if (value.start <= "00:00") {
      setValue((prevValue) => ({
        ...prevValue,
        start: "00:00",
      }));
    }
  };

  const changeCompleteHandler = (time) => {};
  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];
      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };
  const timeChangeHandler = (time) => {
    const startTime = timeParser(time.start);
    const endTime = timeParser(time.end);
    setValue({
      start: startTime,
      end: endTime,
    });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  const getSingleData = (elem) => {
    setSingleData(elem);
    setShowBook(true);
  };
  const filterTime = (val, isNext = false, isPrev = false) => {
    if (val === "") {
      val = new Date();
    }
    if (isNext) {
      let ndate = nextDate(val);
      setStartDate(ndate);
    } else if (isPrev) {
      let pdate = prevDate(val);
      setStartDate(pdate);
    } else {
      setStartDate(val);
    }
  };
  const nextDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
  };
  const prevDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date;
  };
  const educationSelected = (val) => {
    if (education === val) {
      setEducation("");
    } else {
      setEducation(val);
    }
  };
  const ResetFilter = () => {
    setTemp(sessionData);
    setEducation("");
    setStartDate(new Date());
    setValue({ start: "00:00", end: "23:59" });
  };

  let data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    setLoader(true);
    dispatch(viewSession()).then((res) => {
      if (res?.payload?.status === 200) {
        let data = res?.payload?.data?.filter(
          (elem) => elem?.creatorId !== userData?._id
        );
        checkingIfFullyBooked(data);
      }
    });

    dispatch(getCredits(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMyCredits(res?.payload?.data);
      }
    });
    dispatch(ratingForInterviewdDashboard(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setRating(res?.payload?.data);
      }
    });
  }, []);

  const checkingIfFullyBooked = (data) => {
    let toUpdate = [...data];
    toUpdate = toUpdate.map((item) => {
      let itemUpdate = { ...item };
      let AllBooked = false;
      itemUpdate = itemUpdate?.creatorAvalible?.map((cavailable) =>
        cavailable?.booked ? (AllBooked = true) : (AllBooked = false)
      );
      return { ...item, AllBooked };
    });
    toUpdate = toUpdate.sort((a, b) => {
      if (a.AllBooked && !b.AllBooked) {
        return 1;
      } else if (!a.AllBooked && b.AllBooked) {
        return -1;
      } else {
        return 0;
      }
    });
    setLoader(false);
    setSessionData(toUpdate);
    setTemp(toUpdate);
    ApplyFilter();
  };

  const BuyCredits = () => {
    history.push("/buycredits");
  };

  const average =
    Number(rating?.communication) +
    Number(rating?.fitQuestion) +
    Number(rating?.presence) +
    Number(rating?.technicalQuestion);
  const averageRating = (Number(average) / 4).toFixed(1);
  //
  const [dropdown, setDropDown] = useState("Overall");
  const [rs, res1] = useState(false);
  const [p, setP] = useState(false);
  const [calTime, seCalTime] = useState([]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (userData?.role === 0 && currentPath === "/interviewdashboard") {
      history?.push("/interviewdashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(getMeetingTime(data)).then((res) => {
      seCalTime(res?.payload?.data);
    });
  }, []);

  useEffect(() => {
    if (mysessionId == "0") {
      setShowEditProposeScreen(true);
    } else {
    }
  }, [mysessionId]);
  const ApplyFilter = () => {
    let filteredData = [...sessionData];
    let filterFunctions = [];
    if (education) {
      filterFunctions.push(
        (item) =>
          item.creatorData[0].educationDetails[0]?.programme === education
      );
    }
    if (startDate) {
      filterFunctions.push((item) => {
        let matchedData = item.creatorAvalible?.filter((citem) => {
          return citem?.date === moment(startDate).format("YYYY-MM-DD");
        });
        return matchedData.length > 0;
      });
    }
    if (value?.start && value?.end) {
      if (!(value?.start === "00:00" && value?.end === "23:59")) {
        filterFunctions.push((item) => {
          let matchedData = item.creatorAvalible?.filter(
            (citem) =>
              moment(citem.startDateTime).format("HH:mma") >= value?.start &&
              moment(citem?.endDateTime).format("HH:mma") <= value?.end
          );
          return matchedData.length > 0;
        });
      }
    }

    try {
      filterFunctions.forEach((filterFunction) => {
        filteredData = filteredData.filter(filterFunction);
      });
      return setTemp(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    ApplyFilter();
    return () => {
      ApplyFilter();
    };
  }, [startDate, education, value, sessionData]);
  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);
  const individualRating = (elem) => {
    const averageIndividual =
      Number(elem?.ratings[0]?.communication) +
      Number(elem?.ratings[0]?.fitQuestion) +
      Number(elem?.ratings[0]?.presence) +
      Number(elem?.ratings[0]?.technicalQuestion);
    let averageRatingind = (Number(averageIndividual) / 4).toFixed(1);
    return isNaN(averageRatingind) ? 0 : averageRatingind;
  };

  return (
    <>
      {loader && <ScreenLoader />}
      {!p ? (
        <>
          {!rs ? (
            <>
              {!showBook && !showEditProposeScreen ? (
                <>
                  {userData?._id && isMentor === false && (
                    <div>
                      <div className="booksession__container">
                        <div className=" d-flex justify-content-space-between">
                          <div className="booksession__container__top">
                            <h2>Welcome {userData?.firstName}</h2>
                            <p>
                              A brief summary before you book your next session
                            </p>
                          </div>
                        </div>
                        <Row style={{ padding: "0px" }}>
                          <Col md={6} style={{ padding: "0px" }}>
                            <div className="margin-right">
                              <MySessionCard>
                                <div
                                  className="booksession__container__inner"
                                  style={{ paddingBottom: "2px" }}
                                >
                                  <div className="booksession__container__inner__top">
                                    <div className="booksession__container__inner__top__left">
                                      <div
                                        className="booksession__container__inner__top__left__container"
                                        style={{ display: "flex" }}
                                      >
                                        <p>
                                          {dropdown === "Overall"
                                            ? "Overall performance rating"
                                            : dropdown}
                                        </p>
                                        {dropdown === "Overall" && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip
                                                id="tooltip"
                                                className="custom-tooltip"
                                              >
                                                <p className="custom__tooltip">
                                                  Calculated as the average of
                                                  performance ratings given by
                                                  your peers when you are in the
                                                  role of a candidate, across
                                                  key categories: Presence,
                                                  Communication Skills, Fit
                                                  Questions, and Technical
                                                  Questions
                                                </p>
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={InfoCircle}
                                              alt="info circle"
                                            />
                                          </OverlayTrigger>
                                        )}
                                        {dropdown === "Presence" && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                <p className="custom__tooltip">
                                                  Your cumulative rating as a
                                                  candidate, given by your
                                                  peers, assessing how well you
                                                  project presence during
                                                  interviews. Factors include
                                                  posture, body language, and
                                                  engagement. Pay attention to
                                                  cues like sitting up straight
                                                  and maintaining eye contact.
                                                </p>
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={InfoCircle}
                                              alt="info circle"
                                            />
                                          </OverlayTrigger>
                                        )}
                                        {dropdown ===
                                          "Communication Skills" && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                <p className="custom__tooltip">
                                                  Your cumulative rating as a
                                                  candidate, given by your
                                                  peers, evaluating
                                                  communication effectiveness
                                                  during interviews. Focus on
                                                  maintaining eye contact,
                                                  expressing yourself clearly,
                                                  structuring answers, and
                                                  demonstrating enthusiasm.
                                                  These are crucial elements for
                                                  success.
                                                </p>
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={InfoCircle}
                                              alt="info circle"
                                            />
                                          </OverlayTrigger>
                                        )}
                                        {dropdown === "Fit questions" && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                <p className="custom__tooltip">
                                                  Your cumulative rating as a
                                                  candidate, given by your
                                                  peers, assessing responses to
                                                  fit and situational questions.
                                                  Evaluations include
                                                  likability, cultural fit, and
                                                  how well others might enjoy
                                                  working with you. Consider the
                                                  "airplane test" – would
                                                  interviewers want to be stuck
                                                  in an airport with you?
                                                </p>
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={InfoCircle}
                                              alt="info circle"
                                            />
                                          </OverlayTrigger>
                                        )}
                                        {dropdown === "Technical questions" && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                <p className="custom__tooltip">
                                                  Your cumulative rating as a
                                                  candidate, given by your
                                                  peers, for responses to
                                                  technical questions.
                                                  Interviewers expect detailed
                                                  knowledge in accounting,
                                                  finance, valuation, and
                                                  M&A/LBO modelling. Be prepared
                                                  for unexpected technical
                                                  challenges.
                                                </p>
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={InfoCircle}
                                              alt="info circle"
                                            />
                                          </OverlayTrigger>
                                        )}
                                      </div>
                                      <p className="booksession__container__inner__top__left__text ">
                                        {dropdown === "Overall" &&
                                        averageRating > 0
                                          ? Number(averageRating)?.toFixed(1)
                                          : dropdown === "Fit questions" &&
                                            rating?.fitQuestion > 0
                                          ? Number(
                                              rating?.fitQuestion
                                            )?.toFixed(1)
                                          : dropdown ===
                                              "Technical questions" &&
                                            rating?.technicalQuestion
                                          ? Number(
                                              rating?.technicalQuestion
                                            ).toFixed(1)
                                          : dropdown ===
                                              "Communication Skills" &&
                                            rating?.communication > 0
                                          ? Number(
                                              rating?.communication
                                            ).toFixed(1)
                                          : dropdown === "Presence" &&
                                            rating?.presence > 0
                                          ? Number(rating?.presence).toFixed(1)
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="filter__button__top">
                                      <div className="">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="filter__button"
                                          >
                                            <span className="filter__button__text marginTop">
                                              {dropdown}
                                            </span>
                                            <img src={DropDownImage} />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                            {dropdown !== "Overall" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setDropDown("Overall")
                                                }
                                                className="custom__dropdown__item"
                                              >
                                                Overall
                                              </Dropdown.Item>
                                            )}
                                            {dropdown !== "Presence" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setDropDown("Presence")
                                                }
                                                className="custom__dropdown__item"
                                              >
                                                Presence
                                              </Dropdown.Item>
                                            )}
                                            {dropdown !==
                                              "Communication Skills" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setDropDown(
                                                    "Communication Skills"
                                                  )
                                                }
                                                className="custom__dropdown__item"
                                              >
                                                Communication skills
                                              </Dropdown.Item>
                                            )}
                                            {dropdown !== "Fit questions" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setDropDown("Fit questions")
                                                }
                                                className="custom__dropdown__item"
                                              >
                                                Fit questions
                                              </Dropdown.Item>
                                            )}
                                            {dropdown !==
                                              "Technical questions" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setDropDown(
                                                    "Technical questions"
                                                  )
                                                }
                                                className="custom__dropdown__item"
                                              >
                                                Technical questions
                                              </Dropdown.Item>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="booksession__container__inner__bottom">
                                    <div className="booksession__container__inner__bottom__inner">
                                      <CircularProgressbarWithChildren
                                        value={
                                          dropdown === "Overall" &&
                                          averageRating > 0
                                            ? (
                                                (Number(averageRating) / 5) *
                                                100
                                              ).toFixed(1)
                                            : dropdown === "Fit questions" &&
                                              rating?.fitQuestion > 0
                                            ? (
                                                (Number(rating?.fitQuestion) /
                                                  5) *
                                                100
                                              ).toFixed(1)
                                            : dropdown ===
                                                "Technical questions" &&
                                              rating?.technicalQuestion > 0
                                            ? (
                                                (Number(
                                                  rating?.technicalQuestion
                                                ) /
                                                  5) *
                                                100
                                              ).toFixed(1)
                                            : dropdown ===
                                                "Communication Skills" &&
                                              rating?.communication > 0
                                            ? (
                                                (Number(rating?.communication) /
                                                  5) *
                                                100
                                              ).toFixed(1)
                                            : dropdown === "Presence" &&
                                              rating?.presence > 0
                                            ? (
                                                (Number(rating?.presence) / 5) *
                                                100
                                              ).toFixed(1)
                                            : "0"
                                        }
                                        maxValue={100}
                                        strokeWidth={8}
                                        styles={{
                                          root: {},
                                          path: {
                                            stroke: `#3DD7A1`, //color of progrees bar
                                            transition:
                                              "stroke-dashoffset 0.5s ease 0s",
                                            transform: "rotate(0turn)",
                                            transformOrigin: "center center",
                                          },
                                          trail: {
                                            stroke: "rgb(77, 87, 86)",
                                            strokeLinecap: "butt",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                          },
                                          text: {
                                            fill: "#fff",
                                            fontSize: "24px",
                                          },
                                          background: {
                                            fill: "#354a12",
                                          },
                                        }}
                                      >
                                        <div className="ratingstar__container">
                                          <Rating
                                            readonly={true}
                                            initialValue={`${
                                              dropdown === "Overall" &&
                                              averageRating > 0
                                                ? Number(averageRating).toFixed(
                                                    1
                                                  )
                                                : dropdown ===
                                                    "Fit questions" &&
                                                  rating?.fitQuestion > 0
                                                ? Number(
                                                    rating?.fitQuestion
                                                  ).toFixed(1)
                                                : dropdown ===
                                                    "Technical questions" &&
                                                  rating?.technicalQuestion > 0
                                                ? Number(
                                                    rating?.technicalQuestion
                                                  ).toFixed(1)
                                                : dropdown ===
                                                    "Communication Skills" &&
                                                  rating?.communication > 0
                                                ? Number(
                                                    rating?.communication
                                                  ).toFixed(1)
                                                : dropdown === "Presence" &&
                                                  rating?.presence > 0
                                                ? Number(
                                                    rating?.presence
                                                  ).toFixed(1)
                                                : "0"
                                            }`}
                                            allowFraction={true}
                                            fillIcon={
                                              <img src={FullStar} width={20} />
                                            }
                                            emptyIcon={
                                              <img src={EmptyStar} width={20} />
                                            }
                                            size={20}
                                            fillColor="#F0F1F3"
                                          />
                                        </div>
                                        {dropdown === "Overall" &&
                                        averageRating > 0 ? (
                                          ""
                                        ) : dropdown === "Fit questions" &&
                                          rating?.fitQuestion > 0 ? (
                                          ""
                                        ) : dropdown ===
                                            "Technical questions" &&
                                          rating?.technicalQuestion > 0 ? (
                                          ""
                                        ) : dropdown ===
                                            "Communication Skills" &&
                                          rating?.communication > 0 ? (
                                          ""
                                        ) : dropdown === "Presence" &&
                                          rating?.presence > 0 ? (
                                          ""
                                        ) : (
                                          <strong className="performance__nofeedback">
                                            {" "}
                                            No feedbacks
                                          </strong>
                                        )}
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                </div>
                              </MySessionCard>
                            </div>
                          </Col>
                          <Col md={6} style={{ padding: "0px" }}>
                            <div className="margin-left">
                              <Row>
                                <Col xs={6} style={{ padding: "0px" }}>
                                  <div className=" margin-right">
                                    <MySessionCard>
                                      <div className="booksession__container__cards">
                                        <div className="booksession__container__cards__top">
                                          <img
                                            src={VideoIcon}
                                            alt="wallet"
                                            className="booksession__container__cards__top__wallet"
                                          />
                                        </div>
                                        <div className="booksession__container__cards__bottom">
                                          <div className="booksession__container__cards__bottom__tooltip__container">
                                            <p>Sessions taken</p>
                                            <div className="booksession__container__cards__bottom__tooltip__container__inner">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    <p className="custom__tooltip">
                                                      The total number of
                                                      sessions you've completed.
                                                      Updated at the end of each
                                                      session. Continuous
                                                      practice unlocks
                                                      additional interviewer
                                                      guides and enhances your
                                                      interviewing skills.
                                                    </p>
                                                  </Tooltip>
                                                }
                                              >
                                                <img
                                                  src={InfoCircle}
                                                  alt="info circle"
                                                />
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                          <h2>
                                            {rating?.totalReviews
                                              ? rating?.totalReviews
                                              : "0"}
                                          </h2>
                                        </div>
                                      </div>
                                    </MySessionCard>
                                  </div>
                                </Col>
                                <Col xs={6} style={{ padding: "0px" }}>
                                  <div className="margin-left ">
                                    <MySessionCard>
                                      <div className="booksession__container__cards">
                                        <div className="booksession__container__cards__top">
                                          <img
                                            src={Wallet}
                                            alt="wallet"
                                            className="booksession__container__cards__top__wallet"
                                          />
                                          <div
                                            onClick={BuyCredits}
                                            className="ms-auto buycredits__button__interview"
                                          >
                                            Buy sessions
                                          </div>
                                        </div>
                                        <div className="booksession__container__cards__bottom">
                                          <div className="booksession__container__cards__bottom__tooltip__container">
                                            <p>Available sessions</p>
                                            <div className="booksession__container__cards__bottom__tooltip__container__inner">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    <p className="custom__tooltip">
                                                      The number of practice
                                                      sessions still available
                                                      for you to practice.
                                                      Updated upon booking.
                                                      Cancel sessions at least 1
                                                      hour in advance to avoid
                                                      being charged. Timely
                                                      cancellations help
                                                      maintain a positive
                                                      experience.
                                                    </p>
                                                  </Tooltip>
                                                }
                                              >
                                                <img
                                                  src={InfoCircle}
                                                  alt="info circle"
                                                />
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                          <h2>
                                            {myCredits?.credits
                                              ? myCredits?.credits
                                              : "0"}
                                          </h2>
                                        </div>
                                      </div>
                                    </MySessionCard>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} style={{ padding: "0px" }}>
                                  <div className="margin-top margin-right">
                                    <MySessionCard>
                                      <div className="booksession__container__cards">
                                        <div className="booksession__container__cards__top">
                                          <img
                                            src={GreenStar}
                                            alt="wallet"
                                            className="booksession__container__cards__top__wallet"
                                          />
                                        </div>
                                        <div className="booksession__container__cards__bottom">
                                          <div className="booksession__container__cards__bottom__tooltip__container">
                                            <p>Average rating</p>
                                            <div className="booksession__container__cards__bottom__tooltip__container__inner">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    <p className="custom__tooltip">
                                                      Cumulative rating received
                                                      as an interviewer, given
                                                      by your peers. Our 2-way
                                                      rating system relies on
                                                      user feedback. Provide
                                                      comprehensive feedback to
                                                      maintain a positive
                                                      rating, attracting quality
                                                      peers for future sessions.
                                                    </p>
                                                  </Tooltip>
                                                }
                                              >
                                                <img
                                                  src={InfoCircle}
                                                  alt="info circle"
                                                />
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                          <h2>
                                            {isNaN(
                                              rating?.interview /
                                                rating?.totalReviews
                                            ) ||
                                            rating?.interview /
                                              rating?.totalReviews ===
                                              ""
                                              ? 0
                                              : (
                                                  rating?.interview /
                                                  rating?.totalReviews
                                                ).toFixed(1)}
                                          </h2>
                                        </div>
                                      </div>
                                    </MySessionCard>
                                  </div>
                                </Col>
                                <Col xs={6} style={{ padding: "0px" }}>
                                  <div className="margin-top margin-left">
                                    <MySessionCard>
                                      <div className="booksession__container__cards">
                                        <div className="booksession__container__cards__top">
                                          <img
                                            src={VerifyIcon}
                                            alt="verify"
                                            className="booksession__container__cards__top__wallet"
                                          />
                                        </div>
                                        <div className="booksession__container__cards__bottom">
                                          <div className="booksession__container__cards__bottom__tooltip__container">
                                            <p>Reliability</p>
                                            <div className="booksession__container__cards__bottom__tooltip__container__inner">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    <p className="custom__tooltip">
                                                      This score reflects missed
                                                      sessions without prior
                                                      cancellation when you are
                                                      in the role of an
                                                      interviewer. Maintain a
                                                      high reliability score by
                                                      cancelling at least 1 hour
                                                      before a session.
                                                      Reliability is crucial for
                                                      attracting reliable peers
                                                      and enhancing overall
                                                      session quality.
                                                    </p>
                                                  </Tooltip>
                                                }
                                              >
                                                <img
                                                  src={InfoCircle}
                                                  alt="info circle"
                                                />
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                          <h2>
                                            {relaible?.relibility
                                              ? `${(
                                                  (relaible?.totalReliility
                                                    ?.booked /
                                                    relaible?.totalReliility
                                                      ?.count) *
                                                  100
                                                ).toFixed(0)}%`
                                              : "0%"}
                                          </h2>
                                        </div>
                                      </div>
                                    </MySessionCard>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                  <div className="dashboard">
                    <div className="container-fluid" style={{ padding: "0" }}>
                      <div className="row available__container min__height__100">
                        <div className="col-md-3 col-4 d-none d-md-block available__container__filter">
                          <div className="available__container__filter__top">
                            <h2>Filters</h2>
                            <p onClick={ResetFilter}>Reset</p>
                          </div>
                          <div className="custom__dropdown">
                            <div className="custom__dropdown__main">
                              <h2
                                style={{ fontSize: "14px" }}
                                className="custom__dropdown__main__title"
                              >
                                Date
                              </h2>
                              <div className="custom__dropdown__main__container">
                                <div className="ascdvre__grehgtr">
                                  <div className="filter__label__inner">
                                    <img
                                      src={ArrowLeftMini}
                                      onClick={() =>
                                        filterTime(startDate, false, true)
                                      }
                                    />
                                    <label
                                      htmlFor="filterDate"
                                      className="filterDate filter__label__inner__dateshow"
                                    >
                                      <p className="">
                                        {moment(startDate).format(
                                          "YYYY-MM-DD"
                                        ) ===
                                        moment(new Date()).format("YYYY-MM-DD")
                                          ? "Today"
                                          : formatDate(startDate)}
                                      </p>
                                    </label>
                                    <img
                                      src={ArrowRightMini}
                                      onClick={() =>
                                        filterTime(startDate, true, false)
                                      }
                                    />
                                  </div>
                                  <DatePicker
                                    className="filter__date__input d-none"
                                    name="startDate"
                                    id="filterDate"
                                    placeholder="Select expiration date..."
                                    onSelect={(date) => filterTime(date)}
                                  />
                                </div>

                                <div className="custom__dropdown__main__container__range">
                                  <p
                                    style={{ fontSize: "14px" }}
                                    className="mb-3"
                                  >
                                    Time
                                  </p>
                                  <TimeRangeSlider
                                    format={24}
                                    ref={featureRef}
                                    value={value ? value : ""}
                                    onChangeStart={changeStartHandler}
                                    onChange={timeChangeHandler}
                                    onComplete={changeCompleteHandler}
                                    min={"00:00"}
                                    max={"23:59"}
                                  />
                                  <div className="custom__dropdown__main__container__range__inner mt-3">
                                    <p className="ms-1">
                                      {value?.start ? value?.start : "00:00"}
                                    </p>
                                    <p style={{ marginRight: "3px" }}>
                                      {" "}
                                      {value?.end ? value?.end : "23:59"}
                                    </p>
                                  </div>
                                </div>
                                <div className="custom__dropdown__main__container__experience">
                                  <p style={{ fontSize: "14px" }}>Education</p>
                                  <div className="custom__dropdown__main__container__experience__pills">
                                    <div
                                      style={{ color: "#F5F6F7" }}
                                      className={`booksession__container__filter__pill ${
                                        education === "Bachelors"
                                          ? "education_active"
                                          : "pill-grey"
                                      }`}
                                      onClick={() =>
                                        educationSelected("Bachelors")
                                      }
                                    >
                                      <p
                                        className="marginTop"
                                        style={{
                                          fontSize: "14px",
                                          color: "#F5F6F7",
                                        }}
                                      >
                                        {" "}
                                        Bachelors
                                      </p>
                                    </div>
                                    <div
                                      className={`booksession__container__filter__pill ${
                                        education === "Masters"
                                          ? "education_active"
                                          : "pill-grey"
                                      }`}
                                      onClick={() =>
                                        educationSelected("Masters")
                                      }
                                    >
                                      <p
                                        className="marginTop"
                                        style={{
                                          fontSize: "14px",
                                          color: "#F5F6F7",
                                        }}
                                      >
                                        {" "}
                                        Masters
                                      </p>
                                    </div>
                                    <div
                                      className={`booksession__container__filter__pill ${
                                        education === "MBA"
                                          ? "education_active"
                                          : "pill-grey"
                                      }`}
                                      onClick={() => educationSelected("MBA")}
                                    >
                                      <p
                                        className="marginTop"
                                        style={{
                                          fontSize: "14px",
                                          color: "#F5F6F7",
                                        }}
                                      >
                                        {" "}
                                        MBA
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9 col-12 row p-0">
                          <div
                            className={`${
                              userData?._id ? "col-12" : "col-xl-8 col-12"
                            } p-0 position-relative`}
                          >
                            {userData?._id && isMentor === false && (
                              <>
                                <div className="available__container__top">
                                  <div className=" available__container__top__left">
                                    <p className="proposeSseeioneditHeading">
                                      Proposed sessions
                                    </p>
                                  </div>
                                </div>
                                <div className="interview__book__card EditButtonCardDiv ">
                                  <div className="propose__book__container">
                                    <p className="text-center mb-0">
                                      {" "}
                                      {notBookedSessionData.length > 0
                                        ? `You have ${notBookedSessionData.length} Proposed session`
                                        : "You currently have no proposed sessions"}
                                    </p>
                                    {userData?._id ? (
                                      <>
                                        <button
                                          className="available__container__top__button newEditButton"
                                          style={{
                                            padding: "10px 24px",
                                          }}
                                          onClick={() => {
                                            setShowEditProposeScreen(true);
                                          }}
                                        >
                                          <img src={EditIcon} alt="add" />
                                          {notBookedSessionData.length > 0
                                            ? "Edit session"
                                            : "Propose session"}
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="available__container__top__button newEditButton"
                                        style={{
                                          padding: "10px 24px",
                                        }}
                                        onClick={() => {
                                          history.push("/login");
                                        }}
                                      >
                                        <img src={EditIcon} alt="add" />
                                        Edit session
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="available__container__top">
                              {userData?._id && (
                                <div className=" available__container__top__left book__seseion">
                                  <p className="proposeSseeioneditHeading">
                                    Book sessions
                                  </p>
                                </div>
                              )}
                              <div className="available__container__top__left">
                                <p>{temp?.length} Available sessions</p>
                              </div>
                              <Dropdown className="d-md-none">
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="filter__button__mobile"
                                >
                                  <img
                                    src={SortIcon}
                                    alt="filter button"
                                    className="filter__button__mobile__image"
                                  />
                                  <p className="filter__button__mobile__text">
                                    Filter
                                  </p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className=" custom__dropdown__mobile custom__dropdown__bg">
                                  <div className=" d-md-none available__container__filter">
                                    <div className="available__container__filter__top">
                                      <h2>Filters</h2>
                                      <p onClick={ResetFilter}>Reset</p>
                                    </div>
                                    <div className="custom__dropdown">
                                      <div className="custom__dropdown__main">
                                        <h2 className="custom__dropdown__main__title">
                                          Available day
                                        </h2>
                                        <div className="custom__dropdown__main__container">
                                          <div className="ascdvre__grehgtr">
                                            <div className="filter__label__inner">
                                              <img
                                                src={ArrowLeftMini}
                                                onClick={() =>
                                                  filterTime(
                                                    startDate,
                                                    false,
                                                    true
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor="filterDate"
                                                className="filterDate filter__label__inner__dateshow"
                                              >
                                                <p className="">
                                                  {moment(startDate).format(
                                                    "YYYY-MM-DD"
                                                  ) ===
                                                  moment(new Date()).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                    ? "Today"
                                                    : formatDate(startDate)}
                                                </p>
                                              </label>
                                              <img
                                                src={ArrowRightMini}
                                                onClick={() =>
                                                  filterTime(
                                                    startDate,
                                                    true,
                                                    false
                                                  )
                                                }
                                              />
                                            </div>
                                            <DatePicker
                                              className="filter__date__input d-none"
                                              name="startDate"
                                              id="filterDate"
                                              placeholder="Select expiration date..."
                                              onSelect={(date) =>
                                                filterTime(date)
                                              }
                                            />
                                          </div>

                                          <div className="custom__dropdown__main__container__range">
                                            <p className="mb-3">
                                              Available time
                                            </p>
                                            <TimeRangeSlider
                                              ref={featureRef}
                                              value={value ? value : ""}
                                              onChangeStart={changeStartHandler}
                                              onChange={timeChangeHandler}
                                              onComplete={changeCompleteHandler}
                                            />
                                            <div className="custom__dropdown__main__container__range__inner mt-3">
                                              <p
                                                style={{ fontSize: "14px" }}
                                                className="ms-1"
                                              >
                                                {value?.start
                                                  ? value?.start
                                                  : "00:00"}
                                              </p>
                                              <p
                                                style={{
                                                  marginRight: "3px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {" "}
                                                {value?.end
                                                  ? value?.end
                                                  : "23:59"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="custom__dropdown__main__container__experience">
                                            <p>Education</p>
                                            <div className="custom__dropdown__main__container__experience__pills">
                                              <div
                                                className={`booksession__container__filter__pill ${
                                                  education === "Bachelors"
                                                    ? "education_active"
                                                    : "pill-grey"
                                                }`}
                                                onClick={() =>
                                                  educationSelected("Bachelors")
                                                }
                                              >
                                                <p
                                                  className="pill-color"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {" "}
                                                  Bachelors
                                                </p>
                                              </div>
                                              <div
                                                className={`booksession__container__filter__pill ${
                                                  education === "Masters"
                                                    ? "education_active"
                                                    : "pill-grey"
                                                }`}
                                                onClick={() =>
                                                  educationSelected("Masters")
                                                }
                                              >
                                                <p
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "#F5F6F7",
                                                  }}
                                                >
                                                  {" "}
                                                  Masters
                                                </p>
                                              </div>
                                              <div
                                                className={`booksession__container__filter__pill ${
                                                  education === "MBA"
                                                    ? "education_active"
                                                    : "pill-grey"
                                                }`}
                                                onClick={() =>
                                                  educationSelected("MBA")
                                                }
                                              >
                                                <p
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "#F5F6F7",
                                                  }}
                                                >
                                                  {" "}
                                                  MBA
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            {temp?.length > 0 ? (
                              <>
                                {temp?.map((elem, index) => {
                                  return (
                                    <div
                                      className="interview__book__card__container"
                                      key={index}
                                    >
                                      <div className="interview__book__card__container__bottom">
                                        <div className="interview__book__card interview__book__card2">
                                          <div
                                            style={{
                                              width: "64px",
                                              height: "64px",
                                            }}
                                            className="interview__book__card__left"
                                          >
                                            <img
                                              src={
                                                elem?.creatorData[0]?.image
                                                  ? elem?.creatorData[0]?.image
                                                  : AvatarIcon
                                              }
                                              alt="user avatar"
                                              className="interview__book__card__left__image"
                                              style={{
                                                width: "64px",
                                                height: "64px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          </div>
                                          <div className="interview__book__card__left__container interview__book__card__left__container2">
                                            <div className="interview__book__card__left__container__mobilediv">
                                              <div className="interview__book__card__center interview__book__card__center2">
                                                <div className="interview__book__card__center__created interview__book__card__center__created2">
                                                  <p className="interview__book__card__center__created__title">
                                                    {
                                                      elem?.userData[0]
                                                        ?.firstName
                                                    }{" "}
                                                    {
                                                      elem?.userData[0]
                                                        ?.lastName
                                                    }
                                                  </p>
                                                </div>

                                                <div className="interview__book__card__center__details interview__book__card__center__details2">
                                                  <div className="interview__book__card__center__details__inner interview__book__card__center__details__graduation interview__book__card__center__details__graduation2">
                                                    <img
                                                      src={GraduationIcon}
                                                      className="interview__book__card__center__details__inner__image"
                                                    />
                                                    <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__graduation interview__book__card__center__details__inner__graduation2 ">
                                                      {elem?.creatorData[0]
                                                        ?.educationDetails[0]
                                                        ?.programme
                                                        ? elem?.creatorData[0]
                                                            ?.educationDetails[0]
                                                            ?.programme
                                                        : ""}
                                                    </p>
                                                  </div>
                                                  <div className="interview__book__card__center__details__inner interview__book__card__center__details__location interview__book__card__center__details__location2">
                                                    <img
                                                      src={GraduationIcon}
                                                      className="interview__book__card__center__details__inner__image"
                                                    />
                                                    <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__location interview__book__card__center__details__inner__location2">
                                                      {elem?.creatorData[0]
                                                        ?.educationDetails[0]
                                                        ?.degree
                                                        ? elem?.creatorData[0]
                                                            ?.educationDetails[0]
                                                            ?.degree
                                                        : ""}
                                                    </p>
                                                  </div>
                                                  <div className="interview__book__card__center__details__inner interview__book__card__center__details__university interview__book__card__center__details__university2">
                                                    <img
                                                      src={UniversityIcon}
                                                      className="interview__book__card__center__details__inner__image"
                                                    />
                                                    <p className="interview__book__card__center__details__inner__text interview__book__card__center__details__inner__university interview__book__card__center__details__inner__university2">
                                                      {elem?.creatorData[0]
                                                        ?.educationDetails[0]
                                                        ?.institution
                                                        ? elem?.creatorData[0]
                                                            ?.educationDetails[0]
                                                            ?.institution
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="interview__book__card__center__ratings">
                                                  <Rating
                                                    readonly={true}
                                                    initialValue={individualRating(
                                                      elem
                                                    )}
                                                    allowFraction={true}
                                                    fillIcon={
                                                      <img
                                                        src={StarFull}
                                                        width={16}
                                                        alt=""
                                                      />
                                                    }
                                                    emptyIcon={
                                                      <img
                                                        src={EmptyStar}
                                                        width={16}
                                                      />
                                                    }
                                                    size={20}
                                                    fillColor="#F9A63A"
                                                  />

                                                  <p className="interview__book__card__center__ratings__text">
                                                    (
                                                    {elem?.relability.length > 0
                                                      ? elem?.relability[0]
                                                          ?.booked
                                                      : "0"}
                                                    {" Interviews, "}
                                                    {elem?.relability[0]
                                                      ?.count &&
                                                    elem?.relability[0]?.booked
                                                      ? (
                                                          (elem.relability[0]
                                                            .booked /
                                                            elem.relability[0]
                                                              .count) *
                                                          100
                                                        ).toFixed(0)
                                                      : "0"}
                                                    {"% Reliability"})
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="interview__new__card interview__new__card__book">
                                              <div className="interview__new__card__left">
                                                <div className="interview__new__card__left__top">
                                                  {elem?.userData[0]?.firstName}{" "}
                                                  {elem?.userData[0]?.lastName}
                                                </div>
                                                <div className="interview__new__card__left__ratings">
                                                  <Rating
                                                    readonly={true}
                                                    initialValue={individualRating(
                                                      elem
                                                    )}
                                                    allowFraction={true}
                                                    fillIcon={
                                                      <img
                                                        src={StarFull}
                                                        width={16}
                                                        alt=""
                                                      />
                                                    }
                                                    emptyIcon={
                                                      <img
                                                        src={EmptyStar}
                                                        width={16}
                                                      />
                                                    }
                                                    size={20}
                                                    fillColor="#F9A63A"
                                                  />
                                                  <p className="interview__new__card__left__ratings__text">
                                                    (
                                                    {elem?.relability.length > 0
                                                      ? elem?.relability[0]
                                                          ?.booked
                                                      : "0"}
                                                    {" Interviews, "}
                                                    {elem?.relability[0]
                                                      ?.count &&
                                                    elem?.relability[0]?.booked
                                                      ? (
                                                          (elem.relability[0]
                                                            .booked /
                                                            elem.relability[0]
                                                              .count) *
                                                          100
                                                        ).toFixed(0)
                                                      : "0"}
                                                    {"% Reliability"})
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="interview__new__card__right">
                                                <div className="interview__new__card__right__top">
                                                  <div className="interview__new__card__right__top__container">
                                                    <img
                                                      src={GraduationIcon}
                                                      className="interview__new__card__right__top__container__image"
                                                    />
                                                    <p className="interview__new__card__right__top__container__text">
                                                      {elem?.creatorData[0]
                                                        ?.educationDetails[0]
                                                        ?.programme
                                                        ? `${elem?.creatorData[0]?.educationDetails[0]?.programme} - ${elem?.creatorData[0]?.educationDetails[0]?.degree}`
                                                        : ""}
                                                    </p>
                                                  </div>
                                                  <div className="interview__new__card__right__top__container">
                                                    <img
                                                      src={UniversityIcon}
                                                      className="interview__new__card__right__top__container__image"
                                                    />
                                                    <p className="interview__new__card__right__top__container__text">
                                                      {elem?.creatorData[0]
                                                        ?.educationDetails[0]
                                                        ?.degree
                                                        ? elem?.creatorData[0]
                                                            ?.educationDetails[0]
                                                            ?.institution
                                                        : ""}
                                                    </p>
                                                  </div>
                                                  <div className="interview__new__card__right__top__container">
                                                    <img
                                                      src={LocationIcon}
                                                      className="interview__new__card__right__top__container__image"
                                                    />
                                                    <p className="interview__new__card__right__top__container__text">
                                                      {elem?.creatorData[0]
                                                        ?.location
                                                        ? elem?.creatorData[0]
                                                            ?.location
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {elem?.creatorId ===
                                            userData?._id ? (
                                              <div className="interview__book__card__right">
                                                <button
                                                  className={` ${
                                                    elem?.creatorId !==
                                                    userData?._id
                                                      ? "book__button"
                                                      : "book__button__disabled___your___session"
                                                  }`}
                                                  onClick={() =>
                                                    getSingleData(elem)
                                                  }
                                                >
                                                  Your session
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="interview__book__card__right">
                                                <button
                                                  style={{
                                                    height: "38px",
                                                    padding: "13.15px 18px",
                                                  }}
                                                  className={`marginTop ${
                                                    elem?.creatorAvalible?.every(
                                                      (session) =>
                                                        session.booked
                                                    )
                                                      ? "book__button__disabled"
                                                      : "book__button"
                                                  }`}
                                                  onClick={() =>
                                                    getSingleData(elem)
                                                  }
                                                >
                                                  {elem?.creatorAvalible?.every(
                                                    (session) => session.booked
                                                  )
                                                    ? "Fully booked"
                                                    : "Book session"}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  // justifyContent: "center",
                                  // height: "60vh",
                                }}
                                className="nofound__container"
                              >
                                <div className="nofound__container__inner">
                                  <div className="nofound__container__inner__image">
                                    <img src={noFound} />
                                  </div>
                                  <div className="nofound__container__inner__container">
                                    <div className="nofound__container__inner__container__title">
                                      No sessions available matching your search
                                      criteria
                                    </div>
                                    <div className="nofound__container__inner__container__text">
                                      Sessions proposed by others would appear
                                      on this pages
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                marginBottom: "300px",
                                marginTop: "70px",
                              }}
                              className="dashboard__footer"
                            >
                              <Footer />
                            </div>
                          </div>

                          {userData?._id ? (
                            ""
                          ) : (
                            <div className="col-xl-4 p-0 col-12 mt-0 available__container__schedules__guides">
                              <div className="schedules__guides__contaiener book__session__guides">
                                <div className="schedules__guides__contaiener__center mt-0 ">
                                  <div className="schedules__guides__contaiener__center__inner">
                                    <div className="booksession__visitor__buy__container">
                                      <Row className="gy-4">
                                        <Col xs={12} style={{ padding: "0" }}>
                                          <div className="guest__landing__leftbar">
                                            <div className="per__month">
                                              €75{" "}
                                              <span className="discount d-none">
                                                30% Off
                                              </span>
                                            </div>
                                            <div className="try">
                                              for 5 sessions
                                            </div>
                                            <div>
                                              <button
                                                className="started"
                                                onClick={() => {
                                                  ErrorToast(
                                                    "Please log in with your account to buy sessions."
                                                  );
                                                  history.push("/login");
                                                }}
                                              >
                                                Buy now
                                              </button>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={12} style={{ padding: "0" }}>
                                          <div className="guest__landing__leftbar">
                                            <div className="per__month">
                                              €100{" "}
                                              <span className="discount">
                                                30% Off
                                              </span>
                                            </div>
                                            <div className="try">
                                              for 10 sessions
                                            </div>
                                            <div>
                                              <button
                                                className="started"
                                                onClick={() => {
                                                  ErrorToast(
                                                    "Please log in with your account to buy sessions."
                                                  );
                                                  history.push("/login");
                                                }}
                                              >
                                                Buy now
                                              </button>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={12} style={{ padding: "0" }}>
                                          <div className="guest__landing__leftbar">
                                            <div className="per__month">
                                              €150{" "}
                                              <span className="discount">
                                                50% Off
                                              </span>
                                            </div>
                                            <div className="try">
                                              for 20 sessions
                                            </div>
                                            <div>
                                              <button
                                                className="started"
                                                onClick={() => {
                                                  ErrorToast(
                                                    "Please log in with your account to buy sessions."
                                                  );
                                                  history.push("/login");
                                                }}
                                              >
                                                Buy now
                                              </button>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {sessionModalM && (
                    <SessionModal
                      show={sessionModalM}
                      setShow={setShowSessionModalM}
                      style={{
                        width: "670px !important",
                        padding: "0 !important",
                      }}
                    >
                      <div
                        className="modal__content__container"
                        style={{ display: "block" }}
                      >
                        <h2 className="modal__content__container__title">
                          Scheduled
                        </h2>
                        <div className="modal__content__container__bottom__left__topics__pills">
                          <div
                            className="pill-grey mt-3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "12px",
                            }}
                          >
                            <img src={meeting} />
                            <div
                              className="ms-2"
                              style={{ marginRight: "20px" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <Row className="modal__content__container__bottom">
                        <Col
                          md={12}
                          className="modal__content__container__bottom__left"
                          style={{ padding: "0", paddingRight: "10px" }}
                        >
                          <div className="modal__content__container__bottom__left__difficulty">
                            <div className="modal__content__container__bottom__left__difficulty__inner">
                              <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                                Interview guide difficulty level
                              </h2>
                              <img
                                src={LevelIcon}
                                alt="level"
                                className="modal__content__container__bottom__left__difficulty__inner__image"
                              />
                            </div>
                            <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                              <div className="pill-grey">
                                {sessionModalData?.difficultyLevel}
                              </div>
                            </div>
                          </div>
                          <div className="modal__content__container__bottom__left__topics">
                            <div className="modal__content__container__bottom__left__topics__inner">
                              <h2 className="modal__content__container__bottom__left__topics__inner__title">
                                Topics
                              </h2>
                              <img
                                src={DocumentIcon}
                                alt=""
                                className="modal__content__container__bottom__left__topics__inner__image"
                              />
                            </div>
                            <div className="modal__content__container__bottom__left__topics__pills">
                              {[
                                "Fit",
                                "Brainteasers",
                                "Accounting",
                                "Valuation",
                                "DCF",
                                "LBO",
                                "Merger Model",
                              ].map((item) => (
                                <div className="pill-grey">{item}</div>
                              ))}
                            </div>
                          </div>
                          <div className="modal__content__container__bottom__left__bottom">
                            <div className="modal__content__container__bottom__left__bottom__top">
                              <p className="modal__content__container__bottom__left__bottom__top__title">
                                Interview guide - Interviewer guide
                              </p>
                              <img
                                src={EyeIcon}
                                alt=""
                                className="modal__content__container__bottom__left__bottom__top__image"
                              />
                            </div>
                            <div className="modal__content__container__bottom__left__bottom__top">
                              <p className="modal__content__container__bottom__left__bottom__top__title">
                                Interview guide - Candidate guide
                              </p>
                              <img
                                src={LockIcon}
                                alt=""
                                className="modal__content__container__bottom__left__bottom__top__image"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="modal__content__container__footer">
                        <button className="btn-green">
                          Go to Meeting Room
                        </button>
                      </div>
                    </SessionModal>
                  )}
                </>
              ) : !showEditProposeScreen ? (
                <Book
                  setShowBook={setShowBook}
                  singleData={singleData}
                  slotTime={calTime}
                  checkingIfFullyBooked={checkingIfFullyBooked}
                  individualRating={individualRating}
                  setMyCredits={setMyCredits}
                />
              ) : (
                <EditPurposeScreen
                  setShowEditProposeScreen={setShowEditProposeScreen}
                  setSessionDataBook={setTemp}
                  notBookedSessionData={notBookedSessionData}
                  setNotBookedSessionData={setNotBookedSessionData}
                  myCredits={myCredits}
                />
              )}
            </>
          ) : (
            <RescheduleSession res1={res1} rs={rs} />
          )}
        </>
      ) : (
        <UserProfile setP={setP} p={p} userprofileElemData={mySessionData} />
      )}
    </>
  );
};

export default BookSession;
