import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, client } from "../../client";
import axios from "axios";

export const viewSession = createAsyncThunk("viewSession", async () => {
  try {
    const res = await axios.get(`${API_URL}/api/viewSession`);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const mySession = createAsyncThunk("mySession", async (data) => {
  try {
    const res = await client.get(`/api/mySession/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const mySessionNotBooked = createAsyncThunk(
  "mySessionNotBooked",
  async (data) => {
    try {
      const res = await client.get(`/api/mySessionNotBooked/${data?.userId}`, {
        headers: { authorization: `${data?.token}` },
      });
      return { status: res.status, data: res.data };
    } catch (error) {
      return {
        message: error.response.data.error,
        status: error.response.status,
      };
    }
  }
);

export const mentorSession = createAsyncThunk("mentorSession", async (data) => {
  try {
    const res = await client.get(`/api/myMentorSession/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});
