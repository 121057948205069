import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../Navbar/NavBar";
import Dashboard from "../Dashboard/Dashboard";
import Slot from "../Slot/Slot";
import Interview from "../InterviewGuides/Interview";
import Performance from "../Performance/Performance";
import overview from "../../assets/overview.svg";
//
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import PerformanceIcon from "../../assets/performance-icon.svg";
import PerformanceIconGreen from "../../assets/performance-icon-green.svg";
import InterviewIcon from "../../assets/interview-icon.svg";
import InterviewIconGreen from "../../assets/interview-icon-green.svg";
import SaveSessions from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import SessionModal from "../SessionModal/SessionModal";
import task from "../../assets/taskIcon.svg";
import starGrey from "../../assets/star-grey.svg";
import { ConfirmModal } from "../SessionModal/SessionModal";
import { getUserProfile } from "../../service/redux/middleware/userProfile";

import moneys from "../../assets/moneys.svg";
import "./sidebar.scss";

//sessionModal import session
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AvatarIcon from "../../assets/Avatar.png";
import LocationIcon from "../../assets/location.svg";
import GraduationIcon from "../../assets/graduation.svg";
import UniversityIcon from "../../assets/university.svg";
import LanguageIcon from "../../assets/language-square.svg";
import FiveStar from "../../assets/fiveStar.svg";
import profileModalIcon from "../../assets/profileModalIcon.svg";
import Document from "../../assets/document.svg";
import Level from "../../assets/level.svg";
import Diamond from "../../assets/diamond-grey.svg";
import difficultyLevelIcon from "../../assets/levelgray.svg";
//
// network dashboard
//

//
// network dashboard
//
import NetworkDashboard from "../NetworkDashboard/NetworkDashboard";
import MySessions from "../NetworkDashboard/MySession/MySession";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const SideBar = () => {
  const indexvv = localStorage.getItem("indexvalue");
  const [indexwait, setindexwait] = useState(0);
  const [show, setShow] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [level, setLevel] = useState("Expert");
  const DiffLevel = (e, value) => {
    e?.preventDefault();
    setLevel(value);
  };

  useEffect(() => {
    if (indexvv === "0") {
      setindexwait(0);
    } else if (indexvv === "1") {
      setindexwait(1);
    } else if (indexvv === "2") {
      setindexwait(2);
    } else if (indexvv === "3") {
      setindexwait(3);
    }
  }, [indexvv]);

  const hitfunctionss = (asd) => {
    setindexwait(asd);
    localStorage.setItem("indexvalue", asd);
  };

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);


  const data = {
    userId: userData?._id,
    token: token
  }

  useEffect(() => {
    dispatch(getUserProfile(data)).then((res) => {
      if (res?.payload?.status === 200) {
        // setOnSuccess(true);
      }
    });
  }, [userData]);

  return (
    <>
      <NavBar />
      <div className="lr_padding">
        <div className="app-wrapper">
          <div className="sidebar-column web-sidebar">
            <div className="sidebar-wrapper ">
              <div className="sidebar-wrapper-inner">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        hitfunctionss(0);
                      }}
                      className={
                        indexwait === 0 ? "list-item active" : "list-item "
                      }
                    >
                      <img src={indexwait !== 0 ? saveAdd : saveAddGreen} />
                      <span className="ms-2">Book Session</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        hitfunctionss(1);
                      }}
                      className={
                        indexwait === 1 ? "list-item active" : "list-item "
                      }
                    >
                      <img
                        src={indexwait !== 1 ? SaveSessions : SaveSessionsGreen}
                      />
                      <span className="ms-2">My Sessions</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        hitfunctionss(2);
                      }}
                      className={
                        indexwait === 2 ? "list-item active" : "list-item "
                      }
                    >
                      <div className="set-flex-for-value">
                        <img
                          src={
                            indexwait !== 2 ? InterviewIcon : InterviewIconGreen
                          }
                        />
                        <span className="ms-2">Interview guides</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        hitfunctionss(3);
                      }}
                      className={
                        indexwait === 3 ? "list-item active" : "list-item "
                      }
                    >
                      <div className="set-flex-for-value">
                        <img
                          src={
                            indexwait !== 3
                              ? PerformanceIcon
                              : PerformanceIconGreen
                          }
                        />
                        <span className="ms-2">My Performance</span>
                      </div>
                    </a>
                  </li>
                </ul>
                <Link to="/updateprofile">Update Profile</Link>
              </div>
              <div className="sidebar-wrapper-inner-bottom">
                <p className="sidebar-wrapper-inner-bottom-text">
                  Help & Support
                </p>
              </div>
            </div>
          </div>
          <div className="content-column">
            {indexwait === 0 ? (
              <>
                <Dashboard
                  show={showConfirmModal}
                  setShowConfirmModal={setShowConfirmModal}
                  setShow={setShow}
                />
              </>
            ) : indexwait === 1 ? (
              <>
                <Slot />
                {/* <MySessions/> */}
              </>
            ) : indexwait === 2 ? (
              <>
                <Interview />
              </>
            ) : indexwait === 3 ? (
              <>
                <Performance />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* session modal */}
      <SessionModal show={show} setShow={setShow} style={{ width: "623px" }}>
        <Row>
          <Col md={8} className="SessionModel__Column">
            <div className="SessionModel__card__container">
              <div className="SessionModel__card__container__top">
                <img
                  src={AvatarIcon}
                  alt="user avatar"
                  className="SessionModel__card__container__top__image"
                />
              </div>
              <div className="SessionModel__card__container__center">
                <div className="SessionModel__card__container__center__top">
                  <p className="SessionModel__card__container__center__top__title">
                    Angelo Doe
                  </p>
                </div>
                <div className="SessionModel__card__container__center__center">
                  <div className="SessionModel__card__container__center__center__inner">
                    <img
                      src={LocationIcon}
                      className="SessionModel__card__container__center__center__inner__image"
                      alt=""
                    />
                    <p className="SessionModel__card__container__center__center__inner__text">
                      Portugal
                    </p>
                  </div>
                  <div className="SessionModel__card__container__center__center__inner">
                    <img
                      src={GraduationIcon}
                      className="SessionModel__card__container__center__center__inner__image"
                      alt=""
                    />
                    <p className="SessionModel__card__container__center__center__inner__text">
                      MBA
                    </p>
                  </div>
                  <div className="SessionModel__card__container__center__center__inner">
                    <img
                      src={UniversityIcon}
                      className="SessionModel__card__container__center__center__inner__image"
                      alt=""
                    />
                    <p className="SessionModel__card__container__center__center__inner__text">
                      London School of Business
                    </p>
                  </div>
                </div>
                <div className="SessionModel__card__container__center__bottom">
                  <img
                    src={LanguageIcon}
                    alt=""
                    className="SessionModel__card__container__center__bottom__image"
                  />
                  <p className="SessionModel__card__container__center__bottom__title">
                    English
                  </p>
                </div>
              </div>
            </div>

            <Row className="SessionRating">
              <div className="col-3 SessionRating__inner">
                <h1 className="SessionRating__inner__heading">Overall</h1>
                <img
                  src={FiveStar}
                  className="SessionRating__inner__img"
                  alt=""
                ></img>
              </div>
              <div className="col-3 SessionRating__inner">
                <h1 className="SessionRating__inner__heading">Fit</h1>
                <img
                  src={FiveStar}
                  className="SessionRating__inner__img"
                  alt=""
                ></img>
              </div>
              <div className="col-3 SessionRating__inner">
                <h1 className="SessionRating__inner__heading">Technical</h1>
                <img
                  src={FiveStar}
                  className="SessionRating__inner__img"
                  alt=""
                ></img>
              </div>
              <div className="col-3 SessionRating__inner">
                <h1 className="SessionRating__inner__heading">Communication</h1>
                <img
                  src={FiveStar}
                  className="SessionRating__inner__img"
                  alt=""
                ></img>
              </div>

              <h1 className="SessionRating__lastline">
                12 Interviews, 98% Reliability
              </h1>
            </Row>

            <Row className="SessionModelPara">
              <div className="d-flex align-items-center ">
                <h1 className="SessionModelPara__heading">Background</h1>{" "}
                <img className="ms-1" src={profileModalIcon} alt="" />
              </div>
              <p1 className="SessionModelPara__para1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p1>
            </Row>

            <Row className="SessionModelSecondPara">
              <div className="d-flex align-items-center">
                <h1 className="SessionModelSecondPara__heading">
                  Session objectives{" "}
                </h1>
                <img src={task} className="ms-1" alt="" />
              </div>

              <p1 className="SessionModelSecondPara__para1">
                <li>Lorem ipsum dolor sit amet</li>
                <li>Ut enim ad minim veniam</li>
                <li>Quis nostrud exercitation </li>
                <li> Duis aute irure dolor in reprehenderit est laborum.</li>
              </p1>
            </Row>

            <Row className="SessionModelTopics">
              <div className="d-flex align-items-center">
                <h1 className="SessionModelTopics__heading">Topics </h1>
                <img src={Document} className="ms-1" alt="" />
              </div>

              <div className="SessionModelTopics__pills">
                <div className="pill-grey">Fit</div>
                <div className="pill-grey ">Brainteasers</div>
                <div className="pill-grey">Accounting</div>
                <div className="pill-grey">Valuation</div>
                <div className="pill-grey">DCF</div>
                <div className="pill-grey">LBO</div>
                <div className="pill-grey">Merger Model</div>
              </div>
            </Row>
            <Row className="SessionModelTopics">
              <div className="d-flex align-items-center">
                <h1 className="SessionModelTopics__heading">
                  Diffidulty Level{" "}
                </h1>
                <img src={Level} className="ms-1" alt="" />
              </div>
              <div className="SessionModelTopics__pills">
                <div className="pill-grey">Intermediate</div>
              </div>
            </Row>
            <Row className="SessionModelTopics">
              <div className="d-flex align-items-center">
                <h1 className="SessionModelTopics__heading">Recognitions </h1>
                <img src={Diamond} className="ms-1" alt="" />
              </div>
              <div className="SessionModelTopics__pills">
                <div className="pill-grey d-flex align-content-center justify-between ">
                  <img src={starGrey} alt="" />{" "}
                  <p className="my-0 ms-1">Long-term Subscriber</p>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={4} className="SessionModel__Column__right"></Col>
        </Row>
      </SessionModal>

      {/* confirm modal */}
      {/* 
      <ConfirmModal >
        <div className="confirmModal__main ">
          <div className='heading__heading'>Book Interview</div>
          <div className="reschedule__session__container__bottom__left__form__container">
            <label className="reschedule__session__container__bottom__left__form__container__label">
              <p className="reschedule__session__container__bottom__left__form__container__label__text para__para__para">
                Difficulty level
              </p>
              <img
                src={difficultyLevelIcon} alt="difficulty"
                className="reschedule__session__container__bottom__left__form__container__label__icon mt-3"
              />
            </label>
          </div>
          <div className="level__btn__btn__main modal__btn__btn__main" >
            <div className="level__btn__btn__main__inner">
              <button
                onClick={(e) => DiffLevel(e, 'Beginner')}
                className={level === 'Beginner' ? 'active' : ''}
              >
                Beginner
              </button>
            </div>
            <div className="level__btn__btn__main__inner">
              <button
                onClick={(e) => DiffLevel(e, 'Intermediate')}
                className={`ms-3 ${level === 'Intermediate' ? ' active' : ''}`}>
                Intermediate
              </button>
            </div>
            <div className="level__btn__btn__main__inner">
              <button
                onClick={(e) => DiffLevel(e, 'Expert')}
                className={`ms-3 ${level === 'Expert' ? ' active' : ''}`}
              >
                Expert
              </button>
            </div>
          </div>
          <hr />
          <div className='confirm__btn__main'>
            <button className='confirm__btn'>
              Add to Calendar
            </button>
          </div>
        </div>
      </ConfirmModal> */}
    </>
  );
};

export default SideBar;
