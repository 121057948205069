// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor__names {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.mt__cl__16 {
  margin-top: 16px;
}

@media (max-width: 768px) {
  .mentor__names {
    flex-direction: column;
    gap: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MentorDashboard/MentorSetting/MentorSetting.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE;IACE,sBAAA;IACA,MAAA;EAGF;AACF","sourcesContent":[".mentor__names {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  justify-content: space-between;\n}\n.mt__cl__16 {\n  margin-top: 16px;\n}\n@media (max-width: 768px) {\n  .mentor__names {\n    flex-direction: column;\n    gap: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
