import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import NavLogo from "../../assets/8.svg";
import Notification from "../../assets/notification.svg";
import Profile from "../../assets/Avatar.png";
import Search from "../../assets/search.svg";
import MovieIcon from "../../assets/video.svg";
import StarGreen from "../../assets/star-green.svg";
import MenuIcon from "../../assets/menu.svg";
import LogoutIcon from "../../assets/logout-icon.svg";
import coin from "../../assets/coin.svg";

import TeacherIcon from "../../assets/teacher-icon.svg";
import ProfileIcon from "../../assets/profile-icon.svg";
import NotificationNone from "../../assets/notification-none.png";
import "./navbar.scss";
import { useHistory } from "react-router-dom";
import { getUserImage } from "../../service/redux/middleware/userProfile";
//
import { socket } from "../../socket";
import { ErrorToast, SuccessToast } from "../MyToaster";
import notiSound from "../../assets/audio/notification_sound.mp3";
//
import ArrowRight from "../../assets/arrow-right-navbar.svg";
import ArrowLeft from "../../assets/arrow-left-navbar.svg";
import CancelNavbar from "../../assets/cancel-navbar.svg";
//
import { io } from "socket.io-client";
import axios from "axios";
import { API_URL } from "../../service/client";
import moment from "moment";
import { storeLogout } from "../../service/redux/middleware/signin";

import LinkedIn from "../../assets/linkdinIcon.svg";
import FacebookIcon from "../../assets/facebook.svg";
import TwitterIcon from "../../assets/twitter.svg";
import MailIcon from "../../assets/messageIcon.svg";
import AvatarIcon from "../../assets/avatar.svg";
import { viewMentor } from "../../service/redux/middleware/mentor";
export default function NavbarLanding() {
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isJoinUS, setIsJoinUs] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.signin.signInData.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [mentorData, setMentorData] = useState([]);

  //
  let audio = new Audio(notiSound);
  //
  const data1 = [];
  const Logout = () => {
    dispatch(storeLogout(data1)).then((res) => {});
    localStorage.clear();
    // window.location.assign("https://www.fast-track.community");
    history.push("/login");
  };
  const dispatch = useDispatch();

  const GoToMentor = () => {
    history.push("/mentor");
  };
  const GoToAccount = () => {
    history.push("/profilesettings");
  };
  // for redirect
  // useEffect(() => {
  //   if (userData?.role == '0') {
  //     history.push('/interview')
  //   }
  //   else if (userData?.role == '1') {
  //     history.push('/mentordashboard')
  //   }
  //   else {
  //     window.location.assign('/')
  //   }
  // }, [userData])

  const history = useHistory();
  const [image, setImage] = useState("");

  const data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    dispatch(getUserImage(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setImage(res?.payload?.data);
      }
    });
  }, []);
  //
  //
  const convertingToLocal = (data) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(data);
    const localTime = utcDate?.toLocaleString(undefined, {
      timeZone: userTimeZone,
    });
    return localTime;
  };
  //
  //
  // Socekts
  //
  //

  const BuyCredits = () => {
    history.push("/buycredits");
  };

  const [isMentor, setIsMentor] = useState(false);
  // useEffect(() => {
  //   const storedValue = localStorage.getItem("mentorFlag");
  //   if (storedValue !== null) {
  //     // Convert the stored string back to a boolean
  //     setIsMentor(JSON.parse(storedValue));
  //   }
  // }, []);

  const switchToMentor = () => {
    localStorage.removeItem("mentorFlag");
    history.push("/mentordashboard");
  };


  useEffect(() => {
    const mentorFlag = localStorage.getItem("mentorFlag");
    setIsMentor(mentorFlag);
    console.log("mentorFlag", mentorFlag);
    dispatch(viewMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMentorData(res?.payload?.data);
      }
    });
  }, []);

  return (
    <>
      <div className="Wrapper ">
        <div className="container__custom navbar__container">
          <div className="Nav__Brand ms-4 mt-4 mb-4">
            <a href="https://www.fast-track.community/" className="nav__logo">
              <img src={NavLogo} alt="Logo" />
            </a>
          </div>
          <ul className=" nav__Right__navigations">
            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/"
                className={`nav__Right__navigations__item__link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </a>
            </li>
            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/resources"
                className={`nav__Right__navigations__item__link`}
              >
                Study
              </a>
            </li>
            <li className="nav__Right__navigations__item">
              <Link
                to="/interview"
                className={`nav__Right__navigations__item__link ${
                  location.pathname === "/interview" ||
                  location.pathname === "/interviewdashboard" ||
                  location.pathname === "/interview/1"
                    ? "active"
                    : ""
                }`}
              >
                Interview
              </Link>
            </li>
            <li className="nav__Right__navigations__item">
              <Link
                to="/network"
                className={`nav__Right__navigations__item__link ${
                  location.pathname === "/network" ? "active" : ""
                }`}
              >
                Network
              </Link>
            </li>
            <li className="nav__Right__navigations__item">
              <Link
                to="/forum"
                className={`nav__Right__navigations__item__link ${
                  location.pathname === "/forum" ? "active" : ""
                }`}
              >
                Forum
              </Link>
            </li>

            <li className="nav__Right__navigations__item">
              <a
                href="https://www.fast-track.community/contact"
                className={`nav__Right__navigations__item__link`}
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="nav_Right__container">
            <div className="nav__login">
              {userData?.role===1 && userData?._id ? (
               <Dropdown>
               <Dropdown.Toggle id="dropdown-basic" className="avatar__menu">
                 {userData?.role == 0 ? (
                   <img
                     src={
                       userData?.images
                         ? userData?.images
                         : image?.image
                           ? image?.image
                           : AvatarIcon
                     }
                     alt="Logo"
                     style={{ borderRadius: "50%" }}
                     width="40px"
                     height="40px"
                   />
                 ) : (
                   <img
                     src={
                       mentorData[0]?.mentorProfiles[0]?.image
                         ? mentorData[0]?.mentorProfiles[0]?.image
                         : AvatarIcon
                     }
                     alt="Logo"
                     style={{ borderRadius: "50%" }}
                     width="40px"
                     height="40px"
                   />
                 )}
               </Dropdown.Toggle>
               <Dropdown.Menu className=" custom__dropdownNavbar custom__dropdown__bg">
                 <Dropdown.Item
                   className="custom__dropdown__item"
                   onClick={Logout}
                 >
                   <div className="profile__dropdown">
                     <img
                       src={LogoutIcon}
                       alt="logouticon"
                       className="profile__dropdown__image"
                     />
                     <p className="profile__dropdown__text">Log out</p>
                   </div>
                 </Dropdown.Item>
               </Dropdown.Menu>
             </Dropdown>
              ) : (
                <>
                  <Link
                    to="/signup"
                    className={`nav__Right__navigations__item__link`}
                  >
                    Sign up
                  </Link>
                  <Link
                    to="/login"
                    className={`nav__Right__navigations__item__link nav__login__button`}
                  >
                    Log in
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="mobile__nav__container">
        {
          userData?.role===1 && userData?._id && (
            <div style={{marginRight:"20px"}}>
                <Dropdown>
               <Dropdown.Toggle id="dropdown-basic" className="avatar__menu">
                 {userData?.role == 0 ? (
                   <img
                     src={
                       userData?.images
                         ? userData?.images
                         : image?.image
                           ? image?.image
                           : AvatarIcon
                     }
                     alt="Logo"
                     style={{ borderRadius: "50%" }}
                     width="40px"
                     height="40px"
                   />
                 ) : (
                   <img
                     src={
                       mentorData[0]?.mentorProfiles[0]?.image
                         ? mentorData[0]?.mentorProfiles[0]?.image
                         : AvatarIcon
                     }
                     alt="Logo"
                     style={{ borderRadius: "50%" }}
                     width="40px"
                     height="40px"
                   />
                 )}
               </Dropdown.Toggle>
               <Dropdown.Menu className=" custom__dropdownNavbar custom__dropdown__bg">
                 <Dropdown.Item
                   className="custom__dropdown__item"
                   onClick={Logout}
                 >
                   <div className="profile__dropdown">
                     <img
                       src={LogoutIcon}
                       alt="logouticon"
                       className="profile__dropdown__image"
                     />
                     <p className="profile__dropdown__text">Log out</p>
                   </div>
                 </Dropdown.Item>
               </Dropdown.Menu>
             </Dropdown>
            </div>
          )
        }
            <img
              className="me-4 hamburger__icon"
              src={MenuIcon}
              alt="hamburger"
              onClick={() => setIsMobileActive(!isMobileActive)}
            />
          </div>
          {isMobileActive && (
            <ul
              className={`nav__mobile__navigations ${
                isMobileActive && "nav__mobile__navigations__shown"
              }`}
            >
              <div className="mobile__menu__top">
                <img src={NavLogo} />
                <img
                  src={CancelNavbar}
                  className="mobile__menu__top__button"
                  onClick={() => {
                    setIsMobileActive(!isMobileActive);
                    setOpen(false);
                  }}
                />
              </div>
              {!open ? (
                <>
                  <div className="nav__mobile__navigations__container">
                    <li className="nav__mobile__navigations__container__item">
                      <a
                        href="https://www.fast-track.community/"
                        onClick={() => {
                          setIsMobileActive(false);
                          setOpen(!open);
                        }}
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <a
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        href="https://www.fast-track.community/resources"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Study
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);
                          setOpen(!open);
                        }}
                        to="/interview"
                        isMobileActive
                        className={`nav__mobile__navigations__container__item__link ${
                          location.pathname === "/interview" ||
                          location.pathname === "/interviewdashboard"
                            ? "active"
                            : ""
                        }`}
                      >
                        Interview
                      </Link>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        to="/network"
                        className={`nav__mobile__navigations__container__item__link ${
                          location.pathname === "/network" ? "active" : ""
                        }`}
                      >
                        Network
                      </Link>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <Link
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        to="/forum"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Forum
                      </Link>
                    </li>

                    <li className="nav__mobile__navigations__container__item">
                      <a
                        onClick={() => {
                          setIsMobileActive(false);

                          setOpen(!open);
                        }}
                        href="https://www.fast-track.community/contact"
                        className={`nav__mobile__navigations__container__item__link`}
                      >
                        Contact
                      </a>
                    </li>
                    <li className="nav__mobile__navigations__container__item">
                      <button
                        onClick={() => {
                          setOpen(true);
                        }}
                        // href='https://www.fast-track.community/contact'
                        className={`nav__mobile__navigations__container__item__link nav__mobile__navigations__container__item__button`}
                      >
                        <p>Join Us</p>
                        <img src={ArrowRight} />
                      </button>
                    </li>

                    <div className="nav__login__mobile">
                      {userData?.role===1 && userData?._id ? (
                       ""
                      ) : (
                        <>
                          <Link
                            onClick={() => setIsMobileActive(!isMobileActive)}
                            to="/signup"
                            className={`nav__Right__navigations__item__link`}
                          >
                            Sign up
                          </Link>
                          <Link
                            onClick={() => setIsMobileActive(!isMobileActive)}
                            to="/signup"
                            className={`nav__Right__navigations__item__link nav__login__button`}
                          >
                            Log in
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="nav__mobile__navigations__footer">
                    <div className="nav__mobile__navigations__footer__inner">
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={LinkedIn} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={FacebookIcon} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={TwitterIcon} />
                      </a>
                      <a
                        href="#"
                        className="nav__mobile__navigations__footer__inner__item"
                      >
                        <img src={MailIcon} />
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <div className="nav__mobile__navigations__container">
                  <li className="nav__mobile__navigations__container__item">
                    <button
                      onClick={() => setOpen(false)}
                      // href='https://www.fast-track.community/contact'
                      className={`nav__mobile__navigations__container__item__link nav__mobile__navigations__container__item__button2`}
                    >
                      <img src={ArrowLeft} />
                      <p>Join Us</p>
                    </button>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      to="/signup"
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Sign up
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Join us at fast_track
                      </span>
                    </Link>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      to="/mentor"
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Become a Mentor
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Mentor people from all over the world
                      </span>
                    </Link>
                  </li>
                  <li className="nav__mobile__navigations__container__item">
                    <Link
                      onClick={() => {
                        setIsMobileActive(!isMobileActive);
                        setOpen(false);
                      }}
                      to="/login"
                      className={`nav__mobile__navigations__container__item__link2`}
                    >
                      <p className="nav__mobile__navigations__container__item__link2__title">
                        Log in
                      </p>
                      <span className="nav__mobile__navigations__container__item__link2__subtitle">
                        Learn more on the go from peers
                      </span>
                    </Link>
                  </li>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
