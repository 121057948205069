import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { changePassword } from "../service/redux/middleware/password";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [onSuccess, setOnSuccess] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const onResetPassword = (e) => {
    e?.preventDefault();
    if (!oldPassword) {
      ErrorToast("Please enter old password.");
      return;
    } else if (!password) {
      ErrorToast("Please enter new password.");
      return;
    } else if (!confirmPassword) {
      ErrorToast("Please confirm your password.");
      return;
    }
    if (password !== confirmPassword) {
      ErrorToast("Invalid password. The passwords you have entered do not match.");
    } else {
      const data = {
        email: userData?.email,
        currentPassword: oldPassword,
        newPassword: password,
        token: token,
      };
      dispatch(changePassword(data)).then((res) => {
        if (res?.payload?.status === 200) {
          SuccessToast("Password updated successfully");
          setOnSuccess(true);
        }
      });
    }
  };

  return (
    <>
      <div className="container">
        <div className="form-box">
          <div className="body-form">
            <form>
              <div className="input-group mb-3">
                <input
                  type="password"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  className="form-control"
                  placeholder="old Password"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control"
                  placeholder="new Password"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  className="form-control"
                  placeholder="confirm Password"
                />
              </div>
              <button
                type="button"
                className="btn btn-secondary btn-block"
                onClick={(e) => {
                  // navigate("/");
                  onResetPassword(e);
                }}
              >
                Update Password
              </button>
            </form>
          </div>
          {onSuccess && (
            <p
              className="text-center"
              fontSize="14px"
              weight="400"
              color="#11AF22"
            >
              You successfully changed your password
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
