import React, { useEffect, useState } from "react";
import { Col, Row, Form, Dropdown } from "react-bootstrap";
import Ratings from "../../Custom/Ratings/Ratings";
import Calendar from "../../Custom/Calendar/Calendar";
import MyLoader from "../../Loader/MyLoader";
import Loadersmall from "../../Loader/Loadersmall";
import AvatarIcon from "../../../assets/avatar.svg";
import LocationIcon from "../../../assets/location.svg";
import GraduationIcon from "../../../assets/graduation.svg";
import UniversityIcon from "../../../assets/university.svg";
import LanguageIcon from "../../../assets/language-square.svg";
import FiveStar from "../../../assets/fiveStar.svg";
import ProfileModalIcon from "../../../assets/profileModalIcon.svg";
import DocumentIcon from "../../../assets/document.svg";
import LevelIcon from "../../../assets/levelgray.svg";
import DiamondGrey from "../../../assets/diamond-grey.svg";
import TaskIcon from "../../../assets/taskIcon.svg";
import starGrey from "../../../assets/star-grey.svg";
import ArrowLeft from "../../../assets/arrow-left.svg";
import ProposeSession from "../ProposeSession/ProposeSession";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCredits } from "../../../service/redux/middleware/credits";
import { bookMeeting } from "../../../service/redux/middleware/meeting";
import { viewSession } from "../../../service/redux/middleware/session";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";
import StarFull from "../../../assets/star-yellow.svg";
import "./book.scss";
import ScreenLoader from "../../Loader/ScreenLoader";
const Book = ({
  setShowBook,
  singleData,
  slotTime,
  checkingIfFullyBooked,
  individualRating,
  setMyCredits,
}) => {
  const [calendarReturn, setCalendarReturn] = useState({});
  const [loader, setLoader] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const parts = singleData?.creatorAvalible[0].timeZoneValue.split(", ");
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const token = useSelector((state) => state?.signin?.signInData?.token);
  const data111 = {
    userId: userData?._id,
    token: token,
  };
  const BookedMySession = (elem) => {
    if (userData?._id && isMentor === false) {
      if (calendarReturn.selectedtime?._id) {
        if (difficultyLevel !== "") {
          const data1 = {
            userId: elem.creatorId,
            bookedBy: userData?._id,
            id: calendarReturn.selectedtime?._id,
            difficultyLevel: difficultyLevel,
            token: token,
          };
          setLoader(true);
          dispatch(bookMeeting(data1)).then((res) => {
            if (res?.payload?.status === 200) {
              SuccessToast(res?.payload?.data?.message);
              setLoader(false);
              dispatch(viewSession()).then((res) => {
                if (res?.payload?.status === 200) {
                  let data = res?.payload?.data?.filter(
                    (elem) => elem?.creatorId !== userData?._id
                  );
                  dispatch(getCredits(data111)).then((res) => {
                    if (res?.payload?.status === 200) {
                      setMyCredits(res?.payload?.data);
                    }
                  });
                  checkingIfFullyBooked(data);
                  setShowBook(false);
                }
              });
            } else {
              ErrorToast(res?.payload?.message);
              setLoader(false);
            }
          });
        } else {
          ErrorToast("Please select your interview guide’s difficulty level to continue.");
        }
      } else {
        ErrorToast("Please select a time slot to book session.");
      }
    } else {
      ErrorToast("Please log in to book session.");
      setLoader(false);
      history.push("/login");
    }
  };

  const [difficultyLevel, setDifficultyLevel] = useState("");
  const handleSelectDifficulty = (selected) => {
    setDifficultyLevel(selected);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMentor, setIsMentor] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem("mentorFlag");
    if (storedValue !== null) {
      setIsMentor(JSON.parse(storedValue));
    }
  }, []);
  const handleSeemoreClick = () => {
    setIsSeeMore(!isSeeMore);
  };
  return (
    <>
      <div className="book__container">
        <div className="book__container__top">
          <div
            className="book__container__top__inner"
            onClick={() => setShowBook(false)}
          >
            <img src={ArrowLeft} alt="left arrow" className="" />
            <p className="marginTop">Back to Book sessions</p>
          </div>
        </div>
        <Row className="book__container__bottom interview__dashboard__content__container">
          <Col
            md={8}
            className="book__container__bottom__left"
            style={{ padding: "0" }}
          >
            <div className="book__container__bottom__left__top">
              <div className="book__container__bottom__left__top__image">
                <img
                  src={singleData?.creatorData[0]?.image || AvatarIcon}
                  alt="user avatar"
                  className=""
                />
              </div>
              <div className="book__container__bottom__left__top__center">
                <div className="book__container__bottom__left__top__center__top">
                  <p className="book__container__bottom__left__top__center__top__title">
                    {singleData?.userData[0]?.firstName}{" "}
                    {singleData?.userData[0]?.lastName}
                  </p>
                </div>
                <div className="book__container__bottom__left__top__center__center">
                  <div className="book__container__bottom__left__top__center__center__inner">
                    <img
                      src={LocationIcon}
                      className="book__container__bottom__left__top__center__center__inner__image"
                      alt=""
                    />
                    <p className="book__container__bottom__left__top__center__center__inner__text book__container__bottom__left__top__center__center__inner__profile">
                      {singleData?.creatorData[0]?.location
                        ? singleData?.creatorData[0]?.location
                        : ""}
                    </p>
                  </div>
                  <div className="book__container__bottom__left__top__center__center__inner">
                    <img
                      src={GraduationIcon}
                      className="book__container__bottom__left__top__center__center__inner__image"
                      alt=""
                    />
                    <p
                      className="book__container__bottom__left__top__center__center__inner__text
                    book__container__bottom__left__top__center__center__inner__profile
                    "
                    >
                      {singleData?.creatorData[0]?.educationDetails[0]?.degree
                        ? singleData?.creatorData[0]?.educationDetails[0]
                            ?.degree
                        : ""}
                    </p>
                  </div>
                  <div className="book__container__bottom__left__top__center__center__inner">
                    <img
                      src={UniversityIcon}
                      className="book__container__bottom__left__top__center__center__inner__image"
                      alt=""
                    />
                    <p className="book__container__bottom__left__top__center__center__inner__text book__container__bottom__left__top__center__center__inner__profile">
                      {singleData?.creatorData[0]?.educationDetails[0]
                        ?.institution
                        ? singleData?.creatorData[0]?.educationDetails[0]
                            ?.institution
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="book__container__bottom__left__top__center__bottom">
                  <Rating
                    readonly={true}
                    initialValue={individualRating(singleData)}
                    allowFraction={true}
                    fillIcon={<img src={StarFull} width={16} alt="" />}
                    emptyIcon={<img src={EmptyStar} width={16} alt="" />}
                    size={20}
                    fillColor="#F9A63A"
                  />
                  <p className="mt-2">
                    (
                    <span className="marginTop">
                      {singleData?.relability.length > 0
                        ? singleData?.relability[0]?.booked
                        : "0"}{" "}
                      Interviews ,{" "}
                      {singleData?.relability[0]?.count &&
                      singleData?.relability[0]?.booked
                        ? (
                            (singleData.relability[0].booked /
                              singleData.relability[0].count) *
                            100
                          ).toFixed(0)
                        : "0"}
                      % Reliability
                    </span>
                    )
                  </p>
                </div>
              </div>
            </div>
            <div className="book__container__bottom__left__bottom">
              <div className="book__container__bottom__left__bottom__inner">
                <div className="book__container__headings">
                  <p>Background</p>
                </div>

                <div>
                  <p
                    className={` book__container__bottom__left__bottom__inner__text  ${
                      !isSeeMore ? "ellipsis__text" : ""
                    } `}
                  >
                    {singleData?.creatorData[0]?.background
                      ? singleData.creatorData[0].background
                          .trim()
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                      : ""}
                  </p>
                  {singleData?.creatorData[0]?.background?.length >= 320 && (
                    <button
                      className="seemore__button"
                      onClick={handleSeemoreClick}
                    >
                      {!isSeeMore ? "See more" : "See less"}
                    </button>
                  )}
                </div>
              </div>
              <div className="book__container__bottom__left__bottom__inner">
                <div className="book__container__headings">
                  <p>Session objectives</p>
                </div>
                <ul className="book__container__bottom__left__bottom__inner__list">
                  {singleData?.objective?.map((item, index) => {
                    return (
                      <li className="book__container__bottom__left__bottom__inner__list__text">
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="book__container__bottom__left__bottom__inner">
                <div className="book__container__headings">
                  <p>Topics</p>
                </div>
                <div className="book__container__bottom__left__bottom__inner__pills">
                  {[
                    "Fit",
                    "Brainteasers",
                    "Accounting",
                    "Valuation",
                    "DCF",
                    "LBO",
                    "Merger Model",
                  ].map((item, index) => (
                    <div className="pill-grey" key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="book__container__bottom__left__bottom__inner">
                <div className="book__container__headings">
                  <p>Difficulty level</p>
                </div>
                <div className="pill-grey">{singleData?.difficultyLevel}</div>
              </div>
            </div>
          </Col>
          <Col
            md={4}
            className="book__container__bottom__right d-flex flex-column justify-content-between"
          >
            <div>
              <Calendar
                setCalendarReturn={setCalendarReturn}
                singleCalendarData={singleData}
              />
            </div>
            <div className="book__container__bottom__right__container ">
              <div className="book__container__bottom__right__container__select">
              <p className="book__container__bottom__right__container__timezone">
                Your timezone - {userTimeZone}
              </p>
                <Dropdown onSelect={handleSelectDifficulty}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdownToSelect select__icon"
                  >
                    <p className="forumm__button__text">
                      {difficultyLevel
                        ? difficultyLevel
                        : "Select your guide difficulty level"}
                    </p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                    <Dropdown.Item
                      eventKey="Fundamental"
                      className="dropdownToSelect__item"
                    >
                      Fundamental
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Advanced"
                      className="dropdownToSelect__item"
                    >
                      Advanced
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <button
                className="book__container__bottom__right__container__button btn-green"
                onClick={() => BookedMySession(singleData)}
              >
                Book{" "}
                <div className="book__container__bottom__right__container__button__loader">
                  {loader && <ScreenLoader/>}
                </div>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Book;
