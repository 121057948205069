import React, { useState, useEffect } from "react";
import { MySessionCard } from "../Custom";
import SortIcon from "../../assets/sort.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row } from "react-bootstrap";
import "./slot.scss";
import AvatarIcon from "../../assets/avatar.svg";
import Dots from "../../assets/dot.svg";
import LocationIcon from "../../assets/location.svg";
import GraduationIcon from "../../assets/graduation.svg";
import UniversityIcon from "../../assets/university.svg";
import meeting from "../../assets/link-square.svg";
import GridGreen from "../../assets/grid-green.svg";
import ListView from "../../assets/menu.svg";
import downArrow from "../../assets/downIcon.jpg";
import Ratings from "../Custom/Ratings/Ratings";
import "../Custom/Cards/MySessions/mysessioncard.scss";
import LevelIcon from "../../assets/level-session.svg";
import DocumentIcon from "../../assets/document.svg";
import EyeIcon from "../../assets/eye-green.svg";
import LockIcon from "../../assets/lock.svg";
import SessionModal from "../SessionModal/SessionModal";
import RescheduleSession from "./RescheduleSession/RescheduleSession";
import { mySession } from "../../service/redux/middleware/session";
import { getInterviewGuide } from "../../service/redux/middleware/interviewGuide";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import UserProfile from "./UserProfile/UserProfile";
import MyLoader from "../Loader/MyLoader";
import ScreenLoader from "../Loader/ScreenLoader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import { cancelSession } from "../../service/redux/middleware/meeting";
import { setSessionData } from "../../service/redux/reducer/meetingSlice";
import { SuccessToast, ErrorToast } from "../MyToaster";
import noFound from "../../assets/mySessionEmpty.svg";
import emptyState from "../../assets/books.svg";

import { addRelability } from "../../service/redux/middleware/relaible";

import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../assets/star.svg";
import FullStar from "../../assets/star-full.svg";
import StarFull from "../../assets/star-yellow.svg";

// import UserProfile from "./UserProfile/UserProfile";

const MySessions = ({ setSelectedComponent }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowInterview, setModalShowInterview] = React.useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [mySessionData, setMySessionData] = useState([]);
  const [mySessionDataTemp, setMySessionDataTemp] = useState([]);
  const [myInterviewGuideData, setMyInterviewGuideData] = useState([]);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [rescheduleElem, setRecheduleElem] = useState("");
  const [selectedSlot, setSelectedSlot] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const data = {
    userId: userData?._id,
    token: token,
  };

  useEffect(() => {
    setLoader(true);
    dispatch(mySession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        let meetings = res?.payload?.data
        meetings.sort((a, b) => new Date(a.slot.startDateTime) - new Date(b.slot.startDateTime));
        setMySessionData(meetings);
        setMySessionDataTemp(meetings);
        console.log(meetings)
        // setOnSuccess(true);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const [isActive, setIsActive] = useState(false);

  //PROFILE Data
  const [userProfileElem, setUserProfileElem] = useState(false);
  const [rescheduleProfileElem, setRescheduleProfileElem] = useState(false);
  const [elementData, setElementData] = useState();

  // useEffect(() => {
  //   const checkTimeDifference = () => {
  //     const currentTime = new Date();
  //     const timeDifference = endDateTime - currentTime;
  //     const hoursDifference = timeDifference / (1000 * 60 * 60);
  //     if (hoursDifference <= 1) {
  //       setShowDiv(true);
  //     } else {
  //       setShowDiv(false);
  //     }
  //   };
  //   // Check and update the div's visibility on page load
  //   checkTimeDifference();
  //   // Set up a timer to periodically check and update the div's visibility
  //   const intervalId = setInterval(checkTimeDifference, 60000); // Check every minute
  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  const [singleData, setSingleData] = useState("");

  const findingDifference = (slot) => {
    const localDate = new Date();
    const utcDate = new Date(localDate.toUTCString());
    const sameFormat = utcDate.toISOString();
    const dateDb = new Date(slot?.startDateTime);
    const localDateTime = new Date();
    const timeDifferenceMillis = dateDb - localDateTime;
    // const timeDifferenceMillis = Math.abs(dateDb - localDateTime);

    // Convert the time difference to hours
    const timeDifferenceHours = timeDifferenceMillis / (60 * 60 * 1000);
    return timeDifferenceHours;
  };

  const getSingleData = (elem) => {
    // Get the current local time
    if (elem?.slot?.length > 0) {
      // if (elem) {
      setSingleData(elem);
      setModalShow(!modalShow);
      setSelectedSlot({});
    } else {
      const timeDifferenceHours = findingDifference(elem?.slot);
      // Check if the time difference is greater than one hour
      if (timeDifferenceHours > 1) {
        ErrorToast("The meeting room will open 1 hour before.");
      } else {
        setSingleData(elem);
        setSelectedSlot(elem?.slot);
        setModalShow(!modalShow);
      }
    }
  };
  //rescheduleSession
  const rescheduleSession = (elem) => {
    // history.push("/reschedule");
    setRescheduleProfileElem(true);
    setSingleData(elem);
  };
  //userdtail
  const userdetail = () => {
    // history.push("/viewuserprofile");
    setUserProfileElem(true);
  };

  //userdtail
  const backtoBooking = () => {
    setSelectedComponent(0);
  };

  const getUserInterViewGuide = () => {
    let timeDifferenceHours = findingDifference(singleData?.slot);
  
    // Define the desired order of topics
    const topicOrder = [
      "Intro",
      "Fit",
      "Situational",
      "IB",
      "Accounting concepts",
      "Accounting scenarios",
      "Enterprise value",
      "Valuation",
      "DCF",
      "Other",
      "Brainteasers",
    ];
  
    // Allow joining the meeting if the time difference is within 1 hour before the meeting time
    if (timeDifferenceHours <= 1) {
      setLoader1(true);
      setModalShowInterview(true);
      let idToSend = "";
      if (userData?._id === singleData?.slot?.bookedby) {
        idToSend = singleData?.creatorId;
      } else {
        idToSend = singleData?.slot?.bookedby;
      }
      const data = {
        userId: idToSend,
        meetingId: singleData?.slot?._id,
      };
  
      dispatch(getInterviewGuide(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader1(false);
  
          // Sort the data based on the predefined order of topics
          const sortedData = res?.payload?.data?.data.sort((a, b) =>
            topicOrder.indexOf(a.topic) - topicOrder.indexOf(b.topic)
          );
  
          setMyInterviewGuideData(sortedData);
          // setOnSuccess(true);
        } else {
          setLoader1(false);
        }
      });
      // Add logic here to actually join the meeting
    } else {
      ErrorToast("Meeting has not started yet.");
    }
  };
  
  const [dropdown, setDropDown] = useState("Filter");
  const CancelSession = (elem) => {
    const data1 = {
      creatorId: elem?.creatorId,
      meetingId: elem?.slot?._id,
      token: token,
    };
    setLoader(true);
    dispatch(cancelSession(data1)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        SuccessToast(res?.payload?.data?.message);
        dispatch(mySession(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setMySessionData(res?.payload?.data);
            setMySessionDataTemp(res?.payload?.data);
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleDropdownSelect = (eventKey) => {
    setDropDown(eventKey);
    filter(eventKey);
  };
  const filter = (option) => {

    if (option === "All") {
      setMySessionDataTemp([]);
      setMySessionDataTemp(() => mySessionData);
    } else if (option === "Proposed") {
      let toFilter = mySessionData;

      toFilter = toFilter.filter(
        (item) => item?.creatorId === userData?._id
      );
      console.log(toFilter, "this is the filterss")

      setMySessionDataTemp(toFilter);
    } else if (option === "Accepted") {
      let toFilter = mySessionData;
      toFilter = toFilter.filter(
        (item) => item?.creatorId !== userData?._id
      );
      setMySessionDataTemp(toFilter);
    } else if (option === "Newest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.slot.startDateTime) {
          startTime = sessionA.slot.startDateTime;
        } else {
          if (sessionA.slot.length === 1) {
            startTime = sessionA.slot[0].startDateTime;
          } else {
            let filteredData = sessionA.slot.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d2 - d1;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.slot.startDateTime) {
          startTime2 = sessionB.slot.startDateTime;
        } else {
          if (sessionB.slot.length === 1) {
            startTime2 = sessionB.slot[0].startDateTime;
          } else {
            let filteredData2 = sessionB.slot.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d2 - d1;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeB - startTimeA;
      });
      setMySessionDataTemp(filter);
    } else if (option === "Oldest") {
      let filter = mySessionData;
      filter = filter.sort((sessionA, sessionB) => {
        // start time of session A
        let startTime = "";
        if (sessionA.slot.startDateTime) {
          startTime = sessionA.slot.startDateTime;
        } else {
          if (sessionA.slot.length === 1) {
            startTime = sessionA.slot[0].startDateTime;
          } else {
            let filteredData = sessionA.slot.sort((slotA, slotB) => {
              let d1 = new Date(slotA.startDateTime);
              let d2 = new Date(slotB.startDateTime);
              return d1 - d2;
            });
            startTime = filteredData[0].startDateTime;
          }
        }
        const startTimeA = new Date(startTime);
        // start time of session b
        let startTime2 = "";
        if (sessionB.slot.startDateTime) {
          startTime2 = sessionB.slot.startDateTime;
        } else {
          if (sessionB.slot.length === 1) {
            startTime2 = sessionB.slot[0].startDateTime;
          } else {
            let filteredData2 = sessionB.slot.sort((slotC, slotD) => {
              let d1 = new Date(slotC.startDateTime);
              let d2 = new Date(slotD.startDateTime);
              return d1 - d2;
            });
            startTime2 = filteredData2[0].startDateTime;
          }
        }
        const startTimeB = new Date(startTime2);
        return startTimeA - startTimeB;
      });
      setMySessionDataTemp(filter);
    }
  };
  // const handleJoinMeeting = () => {
  //   // let href=
  //   if (selectedSlot?.meetingLink) {
  //     let timeDifferenceHours = findingDifference(selectedSlot);
  //     if (timeDifferenceHours > 1) {
  //       ErrorToast("The meeting room will open 1 hour before.");
  //     } else {
  //       window.open(
  //         `${selectedSlot?.meetingLink}&userId=${userData?._id}`,
  //         "_blank"
  //       );
  //     }
  //   } else {
  //     if (singleData?.userData[0]?._id === userData?._id) {
  //       ErrorToast("Can't Join meeting before it's not Booked.");
  //     } else {
  //       ErrorToast("Can't Join Meeting");
  //     }
  //   }
  // };

  // const handleJoinMeeting = () => {
  //   // let href=
  //   if (singleData.slot) {
  //     let timeDifferenceHours = findingDifference(singleData.slot);
  //     if (0 > timeDifferenceHours) {
  //       ErrorToast("Meeting has not started yet.");
  //     }else if (timeDifferenceHours===0){
  //       SuccessToast("welcome to meeting.");

  //     }
  //     else {
  //       SuccessToast("welcome to meeting.");
  //     }
  //   }
  // };
 

  const handleJoinMeeting = () => {
    if (singleData?.slot) {
      let timeDifferenceHours = findingDifference(singleData?.slot);
      //  he meeting if the time difference is within 5 minutes before the meeting time
      if (timeDifferenceHours <= 0.0833) {
        let idToSend = "";
        if (userData?._id === singleData?.slot?.bookedby) {
          idToSend = singleData?.creatorId;
        } else {
          idToSend = singleData?.slot?.bookedby;
        }
        let data = {
          sessionData: singleData,
          isMentor: false,
          idToSend,
        };

      
        dispatch(setSessionData(data))
        history.push('/meetingpage')
        SuccessToast('Welcome to the meeting.')

      } else {
        ErrorToast("Join session only available 5 minutes before start");
      }
    }
  };

 

  const individualRating = (elem) => {
    // Use optional chaining and default values to handle potential undefined values
    const communication = Number(elem?.rattings?.[0]?.communication) || 0;
    const fitQuestion = Number(elem?.rattings?.[0]?.fitQuestion) || 0;
    const presence = Number(elem?.rattings?.[0]?.presence) || 0;
    const technicalQuestion =
      Number(elem?.rattings?.[0]?.technicalQuestion) || 0;
    // Calculate the average individual rating
    const averageIndividual =
      communication + fitQuestion + presence + technicalQuestion;
    let averageRatingind = (averageIndividual / 4).toFixed(1);
    return isNaN(averageRatingind) ? 0 : averageRatingind;
  };

  return (
    <>
     
      {
        !userProfileElem && !rescheduleProfileElem ? (
          <div className="mysessions__container">
            {!loader ? (
              mySessionData?.length > 0 ? (
                <>
                  <div className="mysessions__container__top">
                    <p className="mysessions__container__top__title">
                      <span className="filter__button__text d-dlex">
                        {mySessionDataTemp?.length}{" "}
                      </span>
                      Upcoming sessions
                    </p>
                    <div className="mysessions__container__top__container">
                      <Dropdown onSelect={handleDropdownSelect}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="filter__button"
                        >
                          <img
                            src={SortIcon}
                            className="filter__button__image"
                          />
                          <span className="filter__button__text">
                            {dropdown}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                          <Dropdown.Item
                            onClick={() => setDropDown("All")}
                            className="custom__dropdown__item"
                            eventKey="All"
                          >
                            All
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Proposed"
                            className="custom__dropdown__item"
                          >
                            Proposed
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Accepted"
                            className="custom__dropdown__item"
                          >
                            Accepted
                          </Dropdown.Item>
                         
                        </Dropdown.Menu>
                      </Dropdown>
                    
                    </div>
                  </div>
                  <div className="mysessions__container__bottom">
                    <Row className="">
                      {mySessionDataTemp?.map((elem, index) => (
                        <Col
                          style={{
                            marginBottom: "20px",
                            height: "320px",
                            // backgroundColor: "#2f3033",
                          }}
                          xs={12}
                          md={isGrid ? 6 : 12}
                          lg={isGrid ? 4 : 12}
                          className="column__mysession position-relative"
                          key={elem?.slot?._id || elem?.slot[0]?._id}
                        >
                          <Dropdown
                            style={{
                              padding: "10px",
                              zIndex: "100",
                              position: "absolute",
                              right: "5%",
                              top: "3%",
                            }}
                          >
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="dots__button"
                            >
                              <img
                                src={Dots}
                                alt="see more"
                                className="dots__button__dots"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                              <Dropdown.Item
                                className="custom__dropdown__item"
                                // href=""
                                onClick={() => {
                                  userdetail();
                                  setElementData(elem);
                                }}
                              >
                                View Details
                              </Dropdown.Item>
                          
                              <Dropdown.Item
                                className="custom__dropdown__item"
                          
                                onClick={() => {
                                  rescheduleSession(elem);
                                }}
                              >
                                Reschedule Session
                              </Dropdown.Item>
                              {/* )} */}

                              {findingDifference(elem?.slot) > 1 ? (
                                <Dropdown.Item
                                  onClick={() => CancelSession(elem)}
                                  className="custom__dropdown__item"
                                >
                                  Cancel Session
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                            
                            </Dropdown.Menu>
                          </Dropdown>
                          <div
                            onClick={() => {
                              userdetail();
                              setElementData(elem);
                            }}
                          >
                            <MySessionCard heightSp={true}>
                              <div className="mysession__container__top">
                                <img
                                  // src={AvatarIcon}
                                  src={
                                    elem?.userProfile[0]?.image || AvatarIcon
                                  }
                                  alt="avatar icon"
                                  className="mysession__container__top__image"
                                  style={{ borderRadius: "300%" }}
                                />
                              </div>
                              <div className="mysession__container__center">
                                <h2 className="mysession__container__center__title">
                                  {elem?.userData[0]?.firstName}{" "}
                                  {elem?.userData[0]?.lastName}
                                  {elem?.creatorId === userData?._id ? (
                                    <span className="pill-light-squared">
                                      Proposed
                                    </span>
                                  ) : <span className="pill-light-squared">
                                    Accepted
                                  </span>}
                                </h2>

                                {/* time */}
                                <div className="mysession__container__center__top">
                                  <div className="mysession__container__center__top__container">
                                    <p>
                                      {/* 11:15am – 12:15pm, Thursday, August 17 */}
                                      {moment(elem?.slot?.startDateTime).format(
                                        "HH:mm"
                                      )}{" "}
                                      -{" "}
                                      {moment(elem?.slot?.endDateTime).format(
                                        "HH:mm"
                                      )}
                                      ,{" "}
                                      {moment(elem?.slot?.endDateTime).format(
                                        "dddd, MMMM D"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div className="mysession__container__center__top">
                                  <div className="mysession__container__center__bottom">
                                    <img
                                      src={LocationIcon}
                                      alt="location icon"
                                      className="mysession__container__center__bottom__image"
                                    />
                                    <p className="text-capitalize">
                                      {elem?.userProfile[0]?.location
                                        ? elem?.userProfile[0]?.location
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="mysession__container__center__bottom">
                                    <img
                                      src={GraduationIcon}
                                      alt="graduation icon"
                                      className="mysession__container__center__bottom__image"
                                    />
                                    <p>
                                    
                                      {elem?.userProfile[0]?.educationDetails[0]
                                        ?.degree
                                        ? elem?.userProfile[0]
                                          ?.educationDetails[0]?.degree
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div className="mysession__container__center__bottom">
                                  <img
                                    src={UniversityIcon}
                                    alt="graduation icon"
                                    className="mysession__container__center__bottom__image"
                                  />

                                  <p
                                    className=""
                                    style={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      WebkitLineClamp: 2,
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {/* University of Washington */}
                                    {elem?.userProfile[0]?.educationDetails[0]
                                      ?.institution
                                      ? elem?.userProfile[0]
                                        ?.educationDetails[0]?.institution
                                      : ""}
                                  </p>
                                </div>
                                <div className="mysession__container__center__ratings">
                                  {/* <Ratings />
                                   */}
                                  <Rating
                                    readonly={true}
                                    initialValue={individualRating(elem)}
                                    allowFraction={true}
                                    fillIcon={
                                      <img src={StarFull} width={16} alt="" />
                                    }
                                    emptyIcon={
                                      <img
                                        src={EmptyStar}
                                        width={16}
                                        alt={""}
                                      />
                                    }
                                    size={20}
                                    fillColor="#F9A63A"
                                    className="mb-1"
                                  />
                                  <p>
                                    ({Number(elem?.realibility?.booked)}{" "}
                                    Sessions,{" "}
                                    {elem?.realibility?.count &&
                                      elem?.realibility?.booked &&
                                      (
                                        (Number(elem?.realibility?.booked) /
                                          Number(elem?.realibility?.count)) *
                                        100
                                      ).toFixed(0)}
                                    % Reliability)
                                  </p>
                                </div>
                                {/* <p style={{ color: "white" }}>
                                {elem?.slot?.startDateTime ||
                                  elem?.slot.map(
                                    (item) => `${item.startDateTime}     sss   `
                                  )}
                              </p> */}
                              </div>
                            </MySessionCard>
                          </div>
                          <div
                            className="mysession__container__bottom"
                            style={{
                              padding: "10px 25px 16px 16px",
                              zIndex: "100",
                              position: "absolute",
                              right: "1%",
                              left: "1%",
                              bottom: "-1%",
                            }}
                          >
                            <button
                              onClick={() => getSingleData(elem)}
                              className={`join__session__button join__session__enabled `}
                            >
                              Join session
                             
                            </button>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="mysessions__container__top">
                    <p className="mysessions__container__top__title">
                      <span className="filter__button__text d-dlex">
                        {mySessionDataTemp?.length}{" "}
                      </span>
                      Upcoming sessions
                    </p>
                    <div className="mysessions__container__top__container">
                      <Dropdown onSelect={handleDropdownSelect}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="filter__button"
                        >
                          <img
                            src={SortIcon}
                            className="filter__button__image"
                          />
                          <span className="filter__button__text">
                            {dropdown}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                          <Dropdown.Item
                            onClick={() => setDropDown("All")}
                            className="custom__dropdown__item"
                            eventKey="All"
                          >
                            All
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Proposed"
                            className="custom__dropdown__item"
                          >
                            Proposed
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Accepted"
                            className="custom__dropdown__item"
                          >
                            Accepted
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Newest"
                            className="custom__dropdown__item"
                          >
                            Newest
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="custom__dropdown__item"
                            eventKey="Oldest"
                          >
                            Oldest
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="nofound__container"
                  >
                    <div className="nofound__container__inner">
                      <div className="nofound__container__inner__image">
                        <img src={noFound} />
                      </div>
                      <div className="nofound__container__inner__container">
                        <div className="nofound__container__inner__container__title">
                          You currently have no booked sessions
                        </div>
                        <div className="nofound__container__inner__container__text">
                          Upcoming sessions would appear on this page
                        </div>
                      </div>
                      <button
                        onClick={() => backtoBooking()}
                        className="nofound__container__inner__button"
                      >
                        Book sessions{" "}
                      </button>
                    </div>
                  </div>
                </>
              )
            ) : (
              <ScreenLoader />
            )}
          </div>
        ) : userProfileElem ? (
          <UserProfile
            elementData={elementData}
            userprofileElemData={mySessionDataTemp}
            setUserProfileElem={setUserProfileElem}
            individualRating={individualRating}
          />
        ) : (
          rescheduleProfileElem && (
            <RescheduleSession
              setRescheduleProfileElem={setRescheduleProfileElem}
              singleData={singleData}
              setMySessionDataTemp={setMySessionDataTemp}
            />
          )
        )
       
      }


      {!loader1 ? (
        modalShowInterview && (
          <SessionModal
            show={modalShowInterview}
            setShow={setModalShowInterview}
            style={{ width: "670px !important", padding: "0 !important" }}
          >
            <div className=" guideModalNEW">
              <Row className="flex-column-reverse flex-md-row">
                <Col xs={12} md={12} className="p-0">
                  <section
                    className="faqs border-grad1"
                    style={{ borderRadius: "16px" }}
                  >
                    <div className="asked__heading">
                      <p className="dashboard__title">
                        <span className="ms-2"> You will ask</span>
                      </p>
                    </div>
                    <div className="mobile-responsive-table">
                      <Accordion defaultActiveKey="0">
                        {myInterviewGuideData?.map((elem, index) => {
                          return (
                            <Accordion.Item
                              eventKey={index}
                              style={{ background: "#1A1C1F" }}
                            >
                              <Accordion.Header>
                                {/* <span className='numberingss'>{index + 1}.</span>  */}
                                {elem?.text}
                              </Accordion.Header>
                              <Accordion.Body style={{ background: "#1A1C1F" }}>
                                <div className="my-tabs">
                                  <Tabs
                                    defaultActiveKey="answer"
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                  >
                                    <Tab eventKey="answer" title="Answer">
                                      <p className="para">{elem?.answer}</p>
                                    </Tab>
                                    <Tab eventKey="scorecard" title="Scorecard">
                                      <p className="para">{elem?.scoreCard}</p>
                                    </Tab>
                                  </Tabs>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </div>
                  </section>
                </Col>
              </Row>
            </div>
          </SessionModal>
        )
      ) : (
        <ScreenLoader />
      )}


      {modalShow && (
        <SessionModal
          show={modalShow}
          setShow={setModalShow}
          style={{ width: "670px !important", padding: "0 !important" }}
        >
          <div
            className="modal__content__container"
            style={{ display: "block" }}
          >
            <h2 className="modal__content__container__title">Scheduled</h2>
            <div className="modal__content__container__bottom__left__topics__pills">
              
            </div>
          </div>
          <Row className="modal__content__container__bottom">
            <Col
              md={12}
              className="modal__content__container__bottom__left"
              style={{ padding: "0", paddingRight: "10px" }}
            >
            
              <div className="modal__content__container__bottom__left__difficulty">
                <div className="modal__content__container__bottom__left__difficulty__inner">
                  <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                    Your interview guide difficulty level
                  </h2>
                  
                </div>
                <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                  <div className="pill-grey difficultyLevelAtcive">
                    {selectedSlot?.difficultyLevel}
                  </div>
                </div>
              </div>
              <div className="modal__content__container__bottom__left__topics">
                <div className="modal__content__container__bottom__left__topics__inner">
                  <h2 className="modal__content__container__bottom__left__topics__inner__title">
                    Topics
                  </h2>
                 
                </div>
                <div className="modal__content__container__bottom__left__topics__pills">
                  {[
                    "Fit",
                    "Brainteasers",
                    "Accounting",
                    "Valuation",
                    "DCF",
                    "LBO",
                    "Merger Model",
                  ].map((item) => (
                    <div className="pill-grey modal__content__container__bottom__left__topics__pills__pill">
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal__content__container__bottom__left__bottom">
                <div className="modal__content__container__bottom__left__difficulty__inner">
                  <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                    Interview guides
                  </h2>
                 
                </div>
                <div
                  className="modal__content__container__bottom__left__bottom__top"
                  onClick={getUserInterViewGuide}
                >
                  <p className="modal__content__container__bottom__left__bottom__top__title">
                    Interviewer (you will ask)
                  </p>
                  <img
                    src={EyeIcon}
                    alt=""
                    className="modal__content__container__bottom__left__bottom__top__image"
                  />
                </div>
                <div className="modal__content__container__bottom__left__bottom__top">
                  <p className="modal__content__container__bottom__left__bottom__top__title">
                    Candidate (you will be asked)
                  </p>
                  <img
                    src={LockIcon}
                    alt=""
                    className="modal__content__container__bottom__left__bottom__top__image"
                  />
                </div>
              </div>
            </Col>
            
          </Row>
          
          <div className="modal__content__container__footer">
            <button
             
              onClick={handleJoinMeeting}
              className="btn-green"
            >
              Go to Meeting room
            </button>
          </div>
          {/* )
          } */}
        </SessionModal>
      )}
    </>
  );
};

export default MySessions;
