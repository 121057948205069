import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import SaveSessionsIcon from "../../assets/save-my-sessions.svg";
import SaveSessionsGreen from "../../assets/save-my-sessions-green.svg";
import VideoGreen from "../../assets/video.svg";
import VideoGrey from "../../assets/video-grey.svg";
import MyAvailability from "./MyAvailability/MyAvailability";
import MySessions from "./MySession/MySession";
import wallet from "../../assets/wallet.svg";
import LogoutIcon from "../../assets/logout-icon.svg";
import MovieIcon from "../../assets/video.svg";
import ArrowLeftFull from "../../assets/arrow-left.svg";
import saveAdd from "../../assets/save-add.svg";
import saveAddGreen from "../../assets/save-add-green.svg";
import VideoIcon from "../../assets/video-white.svg";
import ProfileIcon from "../../assets/profile-icon.svg";
import AvatarIcon from "../../assets/avatar.svg";
import walletGrey from "../../assets/walletGrey.svg";
import Notification from "../../assets/notification.svg";
import NotificationNone from "../../assets/notification-none.png";
import MentorNavbar from "./MentorNavbar/MentorNavabr";
import EmptyNotifications from "../../assets/empty_notifications.svg";
import NavLogo from "../../assets/8.svg";
import Profile from "../../assets/Avatar.png";
import { Link } from "react-router-dom";
import MyEarning from "./MyEarning/MyEarning";
import { myEarning } from "../../service/redux/middleware/earning";
import { useSelector, useDispatch } from "react-redux";
import { viewMentor } from "../../service/redux/middleware/mentor";
import { viewMentorSession } from "../../service/redux/middleware/mentor";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
//modal unpcoming Sesiion
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { getInterviewGuide } from "../../service/redux/middleware/interviewGuide";
import SessionModal from "../../components/SessionModal/SessionModal";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MentorSetting from "./MentorSetting/MentorSetting";
import moment from "moment";
import { storeLogout } from "../../service/redux/middleware/signin";
import "./mentordashboard.scss";
import axios from "axios";
import { API_URL } from "../../service/client";
import { socket } from "../../socket";
import notiSound from "../../assets/audio/notification_sound.mp3";
import OutsideClickHandler from "react-outside-click-handler";
import NavBar from "../Navbar/NavBar";
import { useParams } from "react-router-dom";
const MentorDashboard = () => {
  const { id } = useParams();
  const [selected, setSelected] = useState(0);
  const [showmentorSetting, setShowmentorSetting] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showAddDate, setShowAddDate] = useState(false);
  const [isMobileNotificationActive, setIsMobileNotificationActive] =
    useState(false);
  //
  console.log("id", id);
  let audio = new Audio(notiSound);
  //
  const history = useHistory();
  const setSelectedComponent = (asd) => {
    setSelected(asd);
  };
  const data1 = [];
  const [loader, setLoader] = useState(false);
  const Logout = () => {
    dispatch(storeLogout(data1)).then((res) => {});
    localStorage.clear();
    history.push("/login");
    // window.location.assign("https://www.fast-track.community");
  };
  const SwitchToLearning = () => {
    localStorage?.setItem("mentorFlag", JSON.stringify(true));
    history.push("/network");
    // localStorage.clear();
  };
  const GoToAccount = () => {
    setSelected(4);
  };
  const setSelectedFromSetting = (index) => {
    setSelected(index);
  };
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const [mentorData, setMentorData] = useState([]);
  const [earningData, setEarningData] = useState([]);

  const data = {
    userId: userData?._id,
    token: token,
  };
  const userEarning = (index) => {
    setSelectedComponent(index);
    setLoader(true);
    dispatch(myEarning(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setEarningData(res?.payload?.data);
        setLoader(false);
        // window.open(res?.payload?.data, '_blank');
        // setSelected(0);
      } else {
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    setLoader(true);
    dispatch(viewMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setMentorData(res?.payload?.data);
        // checkingIfFullyBooked(res?.payload.data);
        setLoader(false);
        // setTemp(res?.payload?.data)
      }
    });
  }, []);
  const [mySessionData, setMySessionData] = useState([]);
  const [mySessionDataTemp, setMySessionDataTemp] = useState([]);
  useEffect(() => {
    // setLoader(true);
    dispatch(viewMentorSession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        let meetings=res?.payload?.data
        console.log(res?.payload?.data,"this is ok")
        meetings.sort((a, b) => new Date(a.mentorAvalible.startDateTime) - new Date(b.mentorAvalible.startDateTime));
        setMySessionData(meetings);
        setMySessionDataTemp(meetings);
        // setOnSuccess(true);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const [addSchedules, setShowAddSchedules] = useState(false);

  // mentor seesion calling
  // const getUserInterViewGuide = () => {
  // useEffect(() => {

  //   setLoader1(true);
  //   setModalShowInterview(true);
  //   dispatch(getInterviewGuide("Advanced")).then((res) => {
  //     if (res?.payload?.status === 200) {
  //       setLoader1(false);
  //       setMyInterviewGuideData(res?.payload?.data);
  //       // setOnSuccess(true);
  //     } else {
  //       setLoader1(false);
  //     }
  //   });
  // },[]);

  useEffect(() => {
    if (id == undefined) {
      setSelected(0);
    } else {
      setSelected(Number(id));
    }
  }, [id]);

  const getNotifications = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/notification/${userData?._id}`
      );
      if (response.status === 200) {
        setNotifications(response?.data?.reverse());
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getProposedNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getBookNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getMeetingSoonNotificationsOfMentor = () => {
    audio.play();
    getNotifications();
  };
  const getBookedByMentor = () => {
    audio.play();
    getNotifications();
  };

  useEffect(() => {
    getNotifications();
    socket.on("connect", () => {
      socket.emit("join", userData?._id);
    });
    if (userData?._id) {
      socket.on("mentor_propose_sesssion", getProposedNotificationsOfMentor);
      socket.on("mentor_booked_by", getBookNotificationsOfMentor);
      socket.on(
        "network_meeting_soon_creator",
        getMeetingSoonNotificationsOfMentor
      );
      socket.on("booked_mentor", getBookedByMentor);
      // socket.on("buy-credit", getBuyNotifications);
      // socket.on("meeting-bookedbyme", getBookedByMeNotifications);
    }
    socket.on("disconnect", (reason) => {});
    return () => {
      if (userData?._id) {
        socket.off("propose_sesssion", getProposedNotificationsOfMentor);
        socket.off("meeting-booked", getBookNotificationsOfMentor);
        socket.off(
          "network_meeting_soon_creator",
          getMeetingSoonNotificationsOfMentor
        );
        socket.off("booked_mentor", getBookedByMentor);
      }
      socket.off("connect", (reason) => {});
      socket.off("disconnect", (reason) => {});
    };
  }, []);

  const moveToNetwork = () => {
    history.push(`/network`);
  };

  return (
    <div className="container__custom">
      <NavBar />
      <div>
        <div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsMobileNotificationActive(false);
            }}
          >
            <div className="dashboardbar__container__mentor__main">
              <div className="dashboardbar__container__mentor">
                {/* <div
                  className="nav__logo Mobnavbar"
                >
                  <a
                    href="https://www.fast-track.community"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={NavLogo} alt="Logo" />
                  </a>
                </div> */}
                <div className="menu__desktop__mentor">
                  <ul className="dashboardbar__container__mentor__nav">
                    <li className="dashboardbar__container__mentor__nav__item  dashboardbar__container__mentor__nav__item__first">
                      <div
                        onClick={moveToNetwork}
                        className={`dashboardbar__container__mentor__nav__item__link ${
                          selected === null ? "active" : ""
                        }`}
                      >
                        <img
                          src={selected === null ? saveAddGreen : saveAdd}
                          className="dashboardbar__container__mentor__nav__item__link__image"
                        />
                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                          Student dashboard
                        </p>
                      </div>
                    </li>
                    <li className="dashboardbar__container__mentor__nav__item  dashboardbar__container__mentor__nav__item__first">
                      <div
                        onClick={() => {
                          setSelectedComponent(0);
                        }}
                        className={`dashboardbar__container__mentor__nav__item__link ${
                          selected == 0 ? "active" : ""
                        }`}
                      >
                        <img
                          src={selected === 0 ? VideoGreen : VideoGrey}
                          className="dashboardbar__container__mentor__nav__item__link__image"
                        />
                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                          My availability
                        </p>
                      </div>
                    </li>
                    <li className="dashboardbar__container__mentor__nav__item">
                      <div
                        onClick={() => {
                          setSelectedComponent(1);
                        }}
                        className={`dashboardbar__container__mentor__nav__item__link ${
                          selected == 1 ? "active" : ""
                        }`}
                      >
                        <img
                          src={
                            selected === 1
                              ? SaveSessionsGreen
                              : SaveSessionsIcon
                          }
                          className="dashboardbar__container__mentor__nav__item__link__image"
                        />
                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                          My sessions
                        </p>
                      </div>
                    </li>
                    <li className="dashboardbar__container__mentor__nav__item">
                      <div
                        onClick={() => {
                          userEarning(2);
                        }}
                        className={`dashboardbar__container__mentor__nav__item__link ${
                          selected == 2 ? "active" : ""
                        }`}
                      >
                        <img
                          src={selected === 2 ? wallet : walletGrey}
                          className="dashboardbar__container__mentor__nav__item__link__image"
                        />
                        <p className="dashboardbar__container__mentor__nav__item__link__text">
                          My earnings
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="networkdashboardswitch"> */}
                {/* <div className="notification__mobile image__notifications__container__mentor">
                    <img
                      src={Notification}
                      alt="Logo"
                      className="image__notifications__mentor"
                      onClick={() => {
                        getNotifications();
                        setIsMobileNotificationActive(
                          !isMobileNotificationActive
                        );
                      }}
                    />
                  </div> */}
                {/* <div className="notification__desktop">
                    <Dropdown className="navbar__notification">
                      <Dropdown.Toggle className="navbar__notification__dropdown">
                        <img
                          src={Notification}
                          onClick={getNotifications}
                          alt="Logo"
                          className=""
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          background: "#202224",
                          color: "white",
                          padding: "0px",
                        }}
                        className=""
                      >
                        <h2 className="navbar__notification__dropdown__title">
                          Notifications
                        </h2>
                        <div
                          style={{ height: "500px" }}
                          className={`navbar__notification__dropdown__main ${notifications?.length > 0 ? "" : "mentor__bot"
                            }`}
                        >
                          {notifications?.length > 0 ? (
                            notifications?.map((notification) => (
                              <Dropdown.Item
                                style={{ color: "white" }}
                                href=""
                                className="navbar__notification__dropdown__container navbar__notification__item__green"
                              >
                                <div className="navbar__notification__dropdown__container__inner">
                                  <img
                                    src={MovieIcon}
                                    className="navbar__notification__dropdown__container__inner__image"
                                  />
                                  <div>
                                    <p className="navbar__notification__dropdown__container__inner__message">
                                      {notification.massage}
                                    </p>
                                    <p className="navbar__notification__dropdown__container__inner__type">
                                      View my sessions
                                    </p>
                                  </div>
                                  <p className="navbar__notification__dropdown__container__inner__time">
                                    {moment(notification?.createdAt).fromNow()}
                                  </p>
                                </div>
                                <div>
                                  <div className="dot-green" />
                                </div>
                              </Dropdown.Item>
                            ))
                          ) : (
                            <div
                              className="no__notifications"
                              style={{ height: "500px" }}
                            >
                              <div className="no__notifications__container">
                                <img
                                  src={EmptyNotifications}
                                  className="no__notifications__container__image"
                                />
                                <h2 className="no__notifications__container__text">
                                  No Notifications Found
                                </h2>
                              </div>
                            </div>
                          )}
                          
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                {/* <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="avatar__menu"
                    >
                      <img
                        src={
                          mentorData[0]?.mentorProfiles[0]?.image
                            ? mentorData[0]?.mentorProfiles[0]?.image
                            : AvatarIcon
                        }
                        style={{ borderRadius: "50%" }}
                        alt="Logo"
                        width="40px"
                        height="40px"
                        className="right__logo"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="custom__dropdownNavbar custom__dropdown__bg"
                      style={{ width: "175px !important" }}
                    >
                      <Dropdown.Item
                        className="custom__dropdown__item"
                        onClick={GoToAccount}
                      >
                        <div className="profile__dropdown">
                          <img
                            src={ProfileIcon}
                            alt="profileicon"
                            className="profile__dropdown__image"
                          />
                          <p className="profile__dropdown__text">Account</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="custom__dropdown__item"
                        onClick={SwitchToLearning}
                      >
                        <div className="profile__dropdown">
                          <img
                            src={VideoIcon}
                            alt="teachericon"
                            className="profile__dropdown__image"
                          />
                          <p className="profile__dropdown__text">
                            Switch to learning
                          </p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="custom__dropdown__item"
                        onClick={Logout}
                      >
                        <div className="profile__dropdown">
                          <img
                            src={LogoutIcon}
                            alt="logouticon"
                            className="profile__dropdown__image"
                          />
                          <p className="profile__dropdown__text">Log out</p>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                {/* {isMobileNotificationActive && (
                    <div
                      className={`mobile__notifications__mentor ${isMobileNotificationActive &&
                        "mobile__notifications__shown__mentor"
                        }`}
                    >
                      <div
                        className="mobile__notifications__mentor__top"
                        onClick={() => {
                          setIsMobileNotificationActive(false);
                        }}
                      >
                        <img
                          src={ArrowLeftFull}
                          className="mobile__notifications__mentor__top__image"
                        />
                        <h2 className="mobile__notifications__mentor__top__title">
                          Notifications
                        </h2>
                      </div>
                      <div
                        className={`mobile__notifications__mentor__bottom ${notifications.length > 0 ? "" : "mentor__bot"
                          }`}
                      >
                        {notifications.length > 0 ? (
                          notifications.map((notification) => (
                            <div
                              // onClick={() => goToRoute(notification)}
                              style={{ color: "white" }}
                              href=""
                              className={`navbar__notification__dropdown__container ${notification?.read === false
                                ? "navbar__notification__item__green"
                                : ""
                                }`}
                            >
                              <div className="navbar__notification__dropdown__container__inner">
                                <img
                                  src={MovieIcon}
                                  className="navbar__notification__dropdown__container__inner__image"
                                />
                                <div>
                                  <p className="navbar__notification__dropdown__container__inner__message">
                                    {notification.massage}
                                  </p>
                                  <p className="navbar__notification__dropdown__container__inner__type">
                                    View my sessions
                                  </p>
                                </div>
                                <p className="navbar__notification__dropdown__container__inner__time">
                                  {moment(notification?.createdAt).fromNow()}
                                </p>
                              </div>
                              {notification?.read === false && (
                                <div>
                                  <div className="dot-green" />
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="no__notifications no__notifications__height">
                            <div className="no__notifications__container">
                              <img
                                src={EmptyNotifications}
                                className="no__notifications__container__image"
                              />
                              <h2 className="no__notifications__container__text">
                                No Notifications Found
                              </h2>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )} */}
                {/* <p className="networkdashboardswitch__text">Switch to Learning</p>
              <div className="networkdashboardswitch__switch">
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    className="mentor-switch"
                  />
                </Form>
              </div> */}
                {/* </div> */}
              </div>
            </div>
          </OutsideClickHandler>

          <div
            style={{ minHeight: "85vh" }}
            className="dashboard__container__d"
          >
            {/* {selected === 0 ? <MyAvailability /> : <MySessions />} */}
            {selected == 0 ? (
              <MyAvailability
                mentorData={mentorData}
                setMentorData={setMentorData}
                setMySessionData={setMySessionData}
                mySessionData={mySessionData}
                setSelectedComponent={setSelectedComponent}
                setShowAddSchedule={setShowAddSchedules}
                addSchedules={addSchedules}
                setShowAddDate={setShowAddDate}
                showAddDate={showAddDate}
                mySessionDataTemp={mySessionDataTemp}
              />
            ) : selected == 1 ? (
              <MySessions
                mySessionData={mySessionData}
                setMySessionData={setMySessionData}
                mySessionDataTemp={mySessionDataTemp}
                setMySessionDataTemp={setMySessionDataTemp}
                setSelectedComponent={setSelectedComponent}
                setShowAddSchedules={setShowAddSchedules}
                addSchedules={addSchedules}
                setShowAddDate={setShowAddDate}
                showAddDate={showAddDate}
              />
            ) : selected == 2 ? (
              <MyEarning
                loader={loader}
                earningData={earningData}
                setEarningData={setEarningData}
              />
            ) : (
              <MentorSetting
                setSelectedFromSetting={setSelectedFromSetting}
                setMentorData1={setMentorData}
              />
            )}
          </div>
        </div>
      </div>
      <NewsletterFooter />
    </div>
  );
};

export default MentorDashboard;
