import React from "react";
import StarEmpty from "../../../assets/star.svg";
import StarFull from "../../../assets/star-yellow.svg";
//
import "./Ratings.scss";

const Ratings = ({ fiveStar = false }) => {
  //  let totalRating =  (rating?.fitQuestion+ rating?.presence+ rating?.technicalQuestion + rating?.communication) /4

  return (
    <div className="ratings__container">
      <img
        src={StarFull}
        alt="Full star"
        className="ratings__container__image"
      />
      <img
        src={StarFull}
        alt="Full star"
        className="ratings__container__image"
      />
      <img
        src={StarFull}
        alt="Full star"
        className="ratings__container__image"
      />
      <img
        src={StarFull}
        alt="Full star"
        className="ratings__container__image"
      />
      <img
        src={fiveStar ? StarFull : StarEmpty}
        alt="Empty star"
        className="ratings__container__image"
      />
    </div>
  );
};

export default Ratings;
