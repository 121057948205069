import React, { useState, useEffect } from "react";
import {
  Accordion,
  Col,
  Dropdown,
  Row,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Calendar from "react-calendar";

import { MySessionCard } from "../../Custom";
//
import AvatarIcon from "../../../assets/avatar.svg";
import DiamondGrey from "../../../assets/diamond-grey.svg";
import GreenStar from "../../../assets/star-green.svg";
import VideoIcon from "../../../assets/video.svg";
import Dots from "../../../assets/dot.svg";
import InfoCircle from "../../../assets/info-circle.svg";

import Wallet from "../../../assets/sales.svg";

import BIDot from "../../../assets/bi_dot.svg";
import CalendarIcon from "../../../assets/calendar-2.svg";
import LocationIcon from "../../../assets/location.svg";
import GraduationIcon from "../../../assets/graduation.svg";
import UniversityIcon from "../../../assets/university.svg";
//
import AddSchedules from "./EditSchedules/AddSchedules";
import EditSchedules from "./EditSchedules/EditSchedules";
import Profile from "../../../assets/Avatar.png";
import { ConfirmModal } from "../../SessionModal/SessionModal";
import SessionModal from "../../SessionModal/SessionModal";

//
//
import LeftArrow from "../../../assets/arrow-left.svg";
import AddIcon from "../../../assets/add.svg";
import EditIcon from "../../../assets/edit.svg";
import TrashIcon from "../../../assets/trash.svg";
import "./MyAvailability.scss";
import Ratings from "../../Custom/Ratings/Ratings";
import eyeIcon from "../../../assets/eyeIcon.svg";
import AddDate from "./AddDate/AddDate";
//
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelMentorSession,
  mentorUpdateServices,
  viewMentor,
  viewMentorSession,
} from "../../../service/redux/middleware/mentor";
import moment from "moment";
import ScreenLoader from "../../Loader/ScreenLoader";
import { deleteTimeSlot } from "../../../service/redux/middleware/mentor";
import { ErrorToast, SuccessToast } from "../../MyToaster";
import { checkMentorSrtipeAccount } from "../../../service/redux/middleware/mentor";
//
import { Rating } from "react-simple-star-rating";
import EmptyStar from "../../../assets/star.svg";
import FullStar from "../../../assets/star-full.svg";
import StarFull from "../../../assets/star-yellow.svg";
import { client } from "../../../service/client";
import { getInterviewGuideMentor } from "../../../service/redux/middleware/interviewGuide";
import UserProfileMentor from "../MySession/UserProfile/UserProfileMentor";

const MyAvailability = ({
  mentorData,
  setMentorData,
  mySessionData,
  setMySessionData,
  mySessionDataTemp,
  setSelectedComponent,
  setShowAddDate,
  showAddDate,
}) => {
  const [date, setDate] = useState();
  //navigation states
  const [addSchedules, setShowAddSchedules] = useState(false);
  const [showEditSchedules, setShowEditSchedules] = useState(false);
  const [sessionTaken, setSessionTaken] = useState();
  const [ratting, setRattings] = useState();
  const [modalShowInterview, setModalShowInterview] = React.useState(false);
  const [myInterviewGuideData, setMyInterviewGuideData] = useState([]);
  const [userProfileElem, setUserProfileElem] = useState(false);
  const [elementData, setElementData] = useState();
  const userdetail = () => {
    setUserProfileElem(true);
  };
  console.log("Mentor Data mmm", mentorData);
  const [dropdown, setDropDown] = useState("Please select currency");
  console.log("dropdown mmm", dropdown);
  // const [showAddDate, setShowAddDate] = useState(false);
  useEffect(() => {
    if (mentorData[0]?.mentorPricing[0]?.currency) {
      setDropDown(mentorData[0]?.mentorPricing[0]?.currency);
    }
  }, [mentorData]);
  //integeration states

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const data11 = {
    userId: userData?._id,
    token: token,
  };

  function timeconversion(sTime, eTime, date) {
    const formattedStartDate = moment(sTime).format("h:mma");
    const formattedEndDate = moment(eTime).format("h:mma");
    const eventDate = moment(date).format("MMMM DD, YYYY");
    const formattedString = `${formattedStartDate} – ${formattedEndDate}, ${eventDate}`;
    return formattedString;
  }

  const [singleData, setSingleData] = useState("");
  const getSingleData = (elem) => {
    setSingleData(elem);
    setShowEditSchedules(true);
  };

  const delMySchedules = (elem) => {
    setDelModal(true);
    setSingleData(elem);
  };

  const [modalShow, setModalShow] = React.useState(false);

  const showMydetails = (elem) => {
    setModalShow(true);
    setSingleData(elem);
  };

  const deleteSlot = () => {
    const data = {
      creatorId: userData?._id,
      meetingId: singleData?._id,
      token: token,
    };
    setLoader(true);
    dispatch(deleteTimeSlot(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setDelModal(false);
        SuccessToast("Time slot removed successfully.");
        dispatch(viewMentor(data11)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setMentorData(res?.payload?.data);
            setDelModal(false);

            // setOnSuccess(true);
          } else {
            setLoader(false);
            setDelModal(false);
          }
        });
      } else {
        setLoader(false);
        setDelModal(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };

  const [delModal, setDelModal] = useState(false);

  const AddToSchedules = () => {
    setLoader(true);
    const data = {
      creatorId: userData?._id,
      token: token,
    };

    if (
      mentorData[0]?.mentorPricing[0]?.generalGuidance > 0 ||
      mentorData[0]?.mentorPricing[0]?.cvReview > 0 ||
      mentorData[0]?.mentorPricing[0]?.coverLetter > 0 ||
      mentorData[0]?.mentorPricing[0]?.interviewPractice > 0
    ) {
      dispatch(checkMentorSrtipeAccount(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setShowAddDate(!showAddDate);
          setLoader(false);
        } else {
          history.push("/createstripe");
          ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    } else {
      setLoader(false);
      ErrorToast("Please add a service first");
    }
  };

  //
  //
  const getUserInterViewGuide = (item) => {
    setLoader(true);
    let data = {
      meetingId: item?.mentorAvalible?._id,
      userId: userData?._id,
    };
    dispatch(getInterviewGuideMentor(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setModalShowInterview(true);

        setMyInterviewGuideData(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        setLoader(false);
      }
    });
  };
  //
  //

  const [mentorAvailableSchedules, setMnetorAvailableSchedules] = useState([]);

  const checkingIfFullyBooked = () => {
    let toUpdate = [...mentorData];
    const currentDate = new Date();
    const currentUtcDate = moment(currentDate).utc().format("YYYY-MM-DD");
    const currentStartTimeUTC = moment.utc().format("YYYY-MM-DD HH:mm");
    toUpdate = toUpdate[0]?.mentorAvaliblities[0]?.mentorAvalible.filter(
      (item) => {
        const itemDate = item.date;
        const itemStartTime = moment(item?.startDateTime)
          .utc()
          .format("YYYY-MM-DD HH:mm");

        const isFutureTime = itemStartTime >= currentStartTimeUTC;
        // const isFutureDate = itemDate >= currentUtcDate;
        return item.booked === false && isFutureTime;
      }
    );
    setMnetorAvailableSchedules(toUpdate);
  };

  async function getSessionTaken() {
    try {
      const res = await client.get(`/api/sessionTaken/${userData._id}`, {
        headers: { authorization: `${token}` },
      });
      setSessionTaken(
        res.data.totalReliility.booked ? res.data.totalReliility.booked : 0
      );
    } catch (e) {}
  }
  async function getSessionRatting() {
    try {
      const ress = await client.get(`/api/averageRatting/${userData._id}`, {
        headers: { authorization: `${token}` },
      });
      setRattings(
        ress.data.totalRatting.totalReviews
          ? (
              ress.data.totalRatting.totalRatting /
              ress.data.totalRatting.totalReviews
            ).toFixed(1)
          : ress.data.totalRatting
      );
    } catch (e) {
      console.log(e, "this is error");
    }
  }
  useEffect(() => {
    getSessionTaken();
    getSessionRatting();
  }, []);

  useEffect(() => {
    checkingIfFullyBooked();
  }, [mentorData]);

  const individualRating = (elem) => {
    const averageIndividual =
      Number(elem?.rattingStudent?.communication) +
      Number(elem?.ratings?.fitQuestion) +
      // Number(rating?.interview) +
      Number(elem?.ratings?.presence) +
      Number(elem?.ratings?.technicalQuestion);
    let averageRatingind = (Number(averageIndividual) / 4).toFixed(1);
    // return averageRatingind;
    return isNaN(averageRatingind) ? 0 : averageRatingind;
  };

  //
  //
  const cancelMentorSessionHandle = (elem) => {
    const data = {
      creatorId: elem?.mentorAvalible?._id,
    };
    setLoader(true);
    dispatch(cancelMentorSession(data11)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        SuccessToast(res?.payload?.data?.message);
        dispatch(viewMentorSession(data11)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setMySessionData(res?.payload?.data);
            // setOnSuccess(true);
          } else {
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
        ErrorToast(res?.payload?.message);
      }
    });
  };
  //
  //
  // currency
  const handleSelectCurrency = (selected) => {
    const data = {
      mentorId: userData?._id,
      currency: selected,
      token: token,
    };
    setLoader(true);
    setDropDown(selected);
    dispatch(mentorUpdateServices(data)).then(async (resi) => {
      if (resi?.payload?.status === 200) {
        dispatch(viewMentor(data11)).then((res) => {
          if (res?.payload?.status === 200) {
            setMentorData(res?.payload?.data);
            setLoader(false);
          }
        });
      } else {
        setLoader(false);
      }
    });
  };

  //
  //

  return showAddDate ? (
    <AddDate
      setShowAddDate={setShowAddDate}
      setMentorData={setMentorData}
      mentorData={mentorData}
    />
  ) : addSchedules ? (
    <AddSchedules
      setShowAddSchedules={setShowAddSchedules}
      setMentorData={setMentorData}
      mentorData={mentorData}
    />
  ) : showEditSchedules ? (
    <EditSchedules
      setShowEditSchedules={setShowEditSchedules}
      setMentorData={setMentorData}
      mentorData={mentorData}
      singleData={singleData}
    />
  ) : userProfileElem ? (
    <UserProfileMentor
      avialable={true}
      elementData={elementData}
      userprofileElemData={mySessionDataTemp}
      setUserProfileElem={setUserProfileElem}
      individualRating={individualRating}
    />
  ) : (
    <>
      <div className="myavailability__container dashboard__container__d">
        <Row className="myavailability__container__main">
          <Col
            style={{ padding: "0px" }}
            className="myavailability__container__main__inner"
          >
            <div className="mentortop">
              <div>
                <h2>Welcome {userData?.firstName}</h2>
                <p>Here you can manage all your activities as a mentor</p>
              </div>
              <Dropdown
                onSelect={handleSelectCurrency}
                style={{ width: "220px" }}
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mt-0 dropdownToSelect select__icon"
                  style={{ height: "47px" }}
                >
                  <p
                    className="forumm__button__text"
                    style={{ lineHeight: "0px" }}
                  >
                    {dropdown === "usd"
                      ? "USD"
                      : dropdown === "euro"
                      ? "EURO"
                      : dropdown === "gbp"
                      ? "GBP"
                      : dropdown === "chf"
                      ? "CHF"
                      : "Please select currency"}
                  </p>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu"
                  style={{ padding: "0px" }}
                >
                  <Dropdown.Item
                    eventKey={"usd"}
                    className="dropdownToSelect__item"
                  >
                    {/* US Dollar - $ */}
                    USD
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="euro"
                    className="dropdownToSelect__item"
                  >
                    EURO
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"gbp"}
                    className="dropdownToSelect__item"
                  >
                    GBP
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"chf"}
                    className="dropdownToSelect__item"
                  >
                    CHF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row className="myavailabilityCardROW">
              <Col
                xl={4}
                md={12}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <MySessionCard>
                  <div
                    className="myavailability__container__cards"
                    style={{ height: "125px" }}
                  >
                    <div className="myavailability__container__cards__top">
                      <img
                        src={VideoIcon}
                        alt="wallet"
                        className="myavailability__container__cards__top__wallet"
                      />
                    </div>
                    <div className="myavailability__container__cards__bottom">
                      <div className="myavailability__container__cards__bottom__tooltip__container">
                        <p className="myavailability__container__cards__bottom__tooltip__container__text">
                          Sessions taken
                        </p>
                        <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                <p className="custom__tooltip">
                                  The total number of sessions you've completed.
                                  Updated at the end of each session. Continuous
                                  practice unlocks additional interviewer guides
                                  and enhances your interviewing skills
                                </p>
                              </Tooltip>
                            }
                          >
                            <img src={InfoCircle} alt="info circle" />
                          </OverlayTrigger>
                        </div>
                      </div>

                      <h2>{sessionTaken}</h2>
                    </div>
                  </div>
                </MySessionCard>
              </Col>
              <Col
                xl={4}
                md={12}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <div className="myavailability__container__cards__bottom__tooltip__container">
                          <p className="myavailability__container__cards__bottom__tooltip__container__text">
                            Average rating
                          </p>
                          <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p className="custom__tooltip">
                                    Average rating
                                  </p>
                                </Tooltip>
                              }
                            >
                              <img src={InfoCircle} alt="info circle" />
                            </OverlayTrigger>
                          </div>
                        </div>

                        <h2>{ratting}</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                xl={4}
                md={12}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={Wallet}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <div className="myavailability__container__cards__bottom__tooltip__container">
                          <p className="myavailability__container__cards__bottom__tooltip__container__text">
                            Total sales
                          </p>
                          <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p className="custom__tooltip">
                                    This figure reflects the total number of
                                    practice sessions you have earned from your
                                    mentoring sessions.
                                  </p>
                                </Tooltip>
                              }
                            >
                              <img src={InfoCircle} alt="info circle" />
                            </OverlayTrigger>
                          </div>
                        </div>
                        <h2>0</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <MySessionCard>
                  <div
                    className="myavailability__container__cards"
                    style={{ height: "125px" }}
                  >
                    <div className="myavailability__container__cards__top">
                      <img
                        src={VideoIcon}
                        alt="wallet"
                        className="myavailability__container__cards__top__wallet"
                      />
                    </div>
                    <div className="myavailability__container__cards__bottom">
                      <div className="myavailability__container__cards__bottom__tooltip__container">
                        <p className="myavailability__container__cards__bottom__tooltip__container__text">
                          Sessions taken
                        </p>
                        <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                <p className="custom__tooltip">
                                  Sessions taken
                                </p>
                              </Tooltip>
                            }
                          >
                            <img src={InfoCircle} alt="info circle" />
                          </OverlayTrigger>
                        </div>
                      </div>
                      <h2>{sessionTaken}</h2>
                    </div>
                  </div>
                </MySessionCard>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <div className="myavailability__container__cards__bottom__tooltip__container">
                          <p className="myavailability__container__cards__bottom__tooltip__container__text">
                            Average rating
                          </p>
                          <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p className="custom__tooltip">
                                    Average rating
                                  </p>
                                </Tooltip>
                              }
                            >
                              <img src={InfoCircle} alt="info circle" />
                            </OverlayTrigger>
                          </div>
                        </div>

                        <h2>{ratting}</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={Wallet}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <div className="myavailability__container__cards__bottom__tooltip__container">
                          <p className="myavailability__container__cards__bottom__tooltip__container__text">
                            Total sales
                          </p>
                          <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p className="custom__tooltip">Total sales</p>
                                </Tooltip>
                              }
                            >
                              <img src={InfoCircle} alt="info circle" />
                            </OverlayTrigger>
                          </div>
                        </div>
                        <h2>0</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
            </Row>

            <Row className="myavailabilityServiceROW padding__services">
              <Col style={{ padding: "0px" }} xs={12}>
                <div className="myavailability__container__main__inner__heading">
                  <h2>Services</h2>
                  <a
                    onClick={() => setShowAddSchedules(!addSchedules)}
                    style={{ cursor: "pointer" }}
                  >
                    Edit services
                  </a>
                </div>
                <MySessionCard>
                  <div className="myavailability__container__main__inner__topcard">
                    <h1>About</h1>
                    <p>{mentorData[0]?.mentorPricing[0]?.summary}</p>
                  </div>
                </MySessionCard>
              </Col>
              <Col
                md={3}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top ">
                  <MySessionCard>
                    <div className="myavailability__container__cards11">
                      <div
                        className="myavailability__container__cards"
                        // style={{ height: "90px" }}
                      >
                        <div className="myavailability__container__cards__bottom">
                          <p>General guidance</p>
                          <h2>
                            {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                              ? "$"
                              : mentorData[0]?.mentorPricing[0]?.currency ===
                                "euro"
                              ? "€"
                              : mentorData[0]?.mentorPricing[0]?.currency ==
                                "gbp"
                              ? "£"
                              : mentorData[0]?.mentorPricing[0]?.currency ===
                                "chf"
                              ? "₣"
                              : ""}
                            {mentorData[0]?.mentorPricing[0]?.generalGuidance !=
                            null
                              ? mentorData[0]?.mentorPricing[0]?.generalGuidance
                              : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11"
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>CV review</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.cvReview != null
                            ? mentorData[0]?.mentorPricing[0]?.cvReview
                            : "0"}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left  ">
                  <MySessionCard className="oneissueCard">
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11 "
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>Cover letter review</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.coverLetter != null
                            ? mentorData[0]?.mentorPricing[0]?.coverLetter
                            : "0"}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className="col-md-4__hide"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11"
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>Interview pratice</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.interviewPractice !=
                          null
                            ? mentorData[0]?.mentorPricing[0]?.interviewPractice
                            : "0"}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top ">
                  <MySessionCard>
                    <div className="myavailability__container__cards11">
                      <div
                        className="myavailability__container__cards"
                        // style={{ height: "90px" }}
                      >
                        <div className="myavailability__container__cards__bottom">
                          <p>General guidance</p>
                          <h2>
                            {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                              ? "$"
                              : mentorData[0]?.mentorPricing[0]?.currency ===
                                "euro"
                              ? "€"
                              : mentorData[0]?.mentorPricing[0]?.currency ==
                                "gbp"
                              ? "£"
                              : mentorData[0]?.mentorPricing[0]?.currency ===
                                "chf"
                              ? "₣"
                              : ""}
                            {mentorData[0]?.mentorPricing[0]?.generalGuidance !=
                            null
                              ? mentorData[0]?.mentorPricing[0]?.generalGuidance
                              : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11"
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>CV review</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.cvReview != null
                            ? mentorData[0]?.mentorPricing[0]?.cvReview
                            : "0"}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left  ">
                  <MySessionCard className="oneissueCard">
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11 "
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>Cover letter review</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.coverLetter != null
                            ? mentorData[0]?.mentorPricing[0]?.coverLetter
                            : "0"}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="col-md-4__show"
                style={{ padding: "0px" }}
              >
                <div className="myavailability-margin-top myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards  myavailability__container__cards11"
                      // style={{ height: "90px" }}
                    >
                      <div className="myavailability__container__cards__bottom">
                        <p>Interviews Profile</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency === "usd"
                            ? "$"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "euro"
                            ? "€"
                            : mentorData[0]?.mentorPricing[0]?.currency == "gbp"
                            ? "£"
                            : mentorData[0]?.mentorPricing[0]?.currency ===
                              "chf"
                            ? "₣"
                            : ""}
                          {mentorData[0]?.mentorPricing[0]?.interviewPractice !=
                          null
                            ? mentorData[0]?.mentorPricing[0]?.interviewPractice
                            : "0"}{" "}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
            </Row>
            <Row className="editschedules__container__bottom__container">
              <Col style={{ padding: "0px" }} md={12}>
                <div className="editschedules__container__bottom__container__top">
                  <div className="editschedules__container__bottom__container__top__bottom">
                    <div className="editschedules__container__bottom__container__top__bottom__title">
                      <h1> Available schedules </h1>
                      <p onClick={AddToSchedules} style={{ cursor: "pointer" }}>
                        Add to schedule
                      </p>
                    </div>
                    {mentorAvailableSchedules?.length > 0 ? (
                      <>
                        {mentorAvailableSchedules?.map((item) => (
                          <div className="editschedules__editcard__container">
                            {/* <p className="editschedules__editcard__container__text textdot">
                                {timeconversion(
                                  item?.startDateTime,
                                  item?.endDateTime,
                                  item?.date
                                )} */}
                            <p className="editschedules__editcard__container__text textdot">
                              {moment(item?.startDateTime).format("HH:mm")}
                              <span className="ms-2">-</span>
                              <span className="ms-2">
                                {moment(item?.endDateTime).format("HH:mm")} ,
                              </span>

                              <span className="ms-2">
                                {moment(item?.startDateTime).format(
                                  "MMMM D, YYYY"
                                )}
                              </span>
                            </p>
                            {/* 11:15am – 12:15pm, August 25, 2023 */}
                            {/* </p> */}
                            <div className="editschedules__editcard__container__inner">
                              <img
                                onClick={() => showMydetails(item)}
                                src={eyeIcon}
                                alt="trash icon"
                                style={{ cursor: "pointer" }}
                                className="editschedules__editcard__container__inner__image"
                              />
                              <img
                                onClick={() => getSingleData(item)}
                                src={EditIcon}
                                alt="edit icon"
                                style={{ cursor: "pointer" }}
                                className="editschedules__editcard__container__inner__image"
                              />
                              <img
                                onClick={() => delMySchedules(item)}
                                src={TrashIcon}
                                style={{ cursor: "pointer" }}
                                alt="trash icon"
                                className="editschedules__editcard__container__inner__image"
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="editschedules__editcard__container">
                        <p className="editschedules__editcard__container__text">
                          No Data
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={4} className="col-md-4__hide" style={{ padding: "0px" }}>
            <div className="myavailability__left">
              <>
                {/* <div className="myavailability__left__top">
                <div className="myavailability__left__top__profile">
                  <img src={mentorData[0]?.mentorProfiles[0]?.image ? mentorData[0]?.mentorProfiles[0]?.image : Profile} alt="mentor image" />
                  <h1>
                    {mentorData[0]?.userData[0]?.firstName} {mentorData[0]?.userData[0]?.lastName}</h1>
                  <p>

                    {mentorData[0]?.userData[0]?.verified === true ? "Verified Mentor" : "Not Verified Mentor"}
                  </p>
                </div>
                <div className="myavailability__left__top__profileCard ">
                  <div className=" myavailability-margin-right col-6">
                    <div className="myavailability__container__cards__profile">
                      <div className="myavailability__container__cards__profile__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__profile__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__profile__bottom">
                        <p>Average Rating</p>
                        <h2>4.5</h2>
                      </div>
                    </div>
                  </div>
                  <div className=" myavailability-margin-right col-6">
                    <div className="myavailability__container__cards__profile">
                      <div className="myavailability__container__cards__profile__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__profile__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__profile__bottom">
                        <p>Sessions Taken</p>
                        <h2>54</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                {/* calendar */}
                {/* <div className="myavailability__left__center">
                <div className="myavailability__left__center__top">
                  <h2 className="myavailability__left__center__top__title">
                    Calendar
                  </h2>
                  <p className="myavailability__left__center__top__para">
                    Select to see schedules
                  </p>
                </div>
                <div>
                  <Calendar
                    onChange={setDate}
                    value={date}
                    className="myavailability__left__center__calendar"
                  />
                </div>
              </div> */}
                {/* upcomig sessions */}
              </>
              <div className="myavailability__left__bottom">
                <div className="myavailability__left__bottom__top">
                  <h2 className="myavailability__left__bottom__top__title">
                    Upcoming sessions
                  </h2>
                  <button
                    onClick={() => setSelectedComponent(1)}
                    className="schedules__guides__contaiener__center__inner__title__button"
                    href=""
                  >
                    View All
                  </button>
                </div>
                {/* cards */}
                <div className="myavailability__left__bottom__bottom">
                  <Row className="myavailability__left__bottom__bottom__container">
                    {mySessionData?.map((elem, index) => {
                      return (
                        <Col
                          style={{ padding: "0", marginBottom: "20px" }}
                          xs={12}
                          key={index}
                        >
                          <MySessionCard startTime={elem?.time}>
                            <div className="mysession__container__top">
                              <img
                                src={
                                  elem?.userProfile?.image
                                    ? elem?.userProfile?.image
                                    : AvatarIcon
                                }
                                alt="avatar icon"
                                className="mysession__container__top__image"
                                style={{ borderRadius: "50%" }}
                              />
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="dots__button"
                                >
                                  <img
                                    src={Dots}
                                    alt="see more"
                                    className="dots__button__dots"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                  <Dropdown.Item
                                    className="custom__dropdown__item"
                                    href=""
                                    onClick={() => getUserInterViewGuide(elem)}
                                  >
                                    View guide
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="custom__dropdown__item"
                                    // href=""
                                    onClick={() => {
                                      userdetail();
                                      setElementData(elem);
                                    }}
                                  >
                                    View User Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="custom__dropdown__item"
                                    onClick={() =>
                                      cancelMentorSessionHandle(elem)
                                    }
                                    // href=""
                                    // onClick={rescheduleSession}
                                  >
                                    Remove Session
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    className="custom__dropdown__item"
                                    href=""
                                  >
                                    Cancel Session
                                    </Dropdown.Item> */}
                                  {/* <Dropdown.Item className="custom__dropdown__item">
                                    View User Profile
                                  </Dropdown.Item> */}
                                  {/* <Dropdown.Item className="custom__dropdown__item">
                                    Remove Session
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="mysession__container__center">
                              <h2 className="mysession__container__center__title">
                                {elem?.userData?.firstName}{" "}
                                {elem?.userData?.lastName}
                                {elem?.creatorId === userData?._id && (
                                  <span className="pill-light-squared">
                                    Proposed
                                  </span>
                                )}
                              </h2>

                              {/* 11:15am – 12:15pm, Thursday, August 17 */}
                              <p
                                style={{
                                  color: "white",
                                  marginBottom: "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {moment(
                                  elem?.mentorAvalible?.startDateTime
                                ).format("HH:mm")}{" "}
                                -{" "}
                                {moment(
                                  elem?.mentorAvalible?.endDateTime
                                ).format("HH:mm")}
                                ,{" "}
                                {moment(
                                  elem?.mentorAvalible?.startDateTime
                                ).format("dddd, MMMM D")}
                              </p>
                              <div className="mysession__container__center__top">
                                <div className="mysession__container__center__top__container">
                                  <img
                                    src={LocationIcon}
                                    alt="location icon"
                                    className="mysession__container__center__top__container__image"
                                  />
                                  <p
                                    style={{
                                      color: "#b9bdc7",
                                      fontFamily: "DM Sans",
                                    }}
                                    className="text-capitalize"
                                  >
                                    {elem?.userProfile?.location
                                      ? elem?.userProfile?.location
                                      : "Pakistan"}
                                  </p>
                                </div>
                                <div className="mysession__container__center__top__container">
                                  <img
                                    src={GraduationIcon}
                                    alt="graduation icon"
                                    className="mysession__container__center__top__container__image"
                                  />
                                  <p
                                    style={{
                                      color: "#b9bdc7",
                                      fontFamily: "DM Sans",
                                    }}
                                  >
                                    {elem?.userProfile?.educationDetails[0]
                                      ?.degree
                                      ? elem?.userProfile?.educationDetails[0]
                                          ?.degree
                                      : ""}
                                  </p>
                                </div>
                                {/* mobile */}

                                <div className="mysession__container__center__bottom d-md-none">
                                  <img
                                    src={UniversityIcon}
                                    alt="graduation icon"
                                    className="mysession__container__center__bottom__image"
                                  />
                                  <p>
                                    {/* University of Washington */}
                                    {elem?.userProfile?.educationDetails[0]
                                      ?.institution
                                      ? elem?.userProfile?.educationDetails[0]
                                          ?.institution
                                      : ""}
                                  </p>
                                </div>
                              </div>
                              <div className="mysession__container__center__bottom d-none d-md-flex ">
                                <img
                                  src={UniversityIcon}
                                  alt="graduation icon"
                                  className="mysession__container__center__bottom__image"
                                />
                                <p>
                                  {/* University of Washington */}
                                  {elem?.userProfile?.educationDetails[0]
                                    ?.institution
                                    ? elem?.userProfile?.educationDetails[0]
                                        ?.institution
                                    : ""}
                                </p>
                              </div>
                              <div className="mysession__container__center__ratings ratings__container__availability ">
                                {/* <Ratings /> */}
                                {/* <p>(12 Interviews taken, 98% Reliability)</p> */}
                                <Rating
                                  readonly={true}
                                  initialValue={individualRating(elem)}
                                  allowFraction={true}
                                  fillIcon={
                                    <img src={StarFull} width={16} alt="" />
                                  }
                                  emptyIcon={
                                    <img src={EmptyStar} width={16} alt="" />
                                  }
                                  size={20}
                                  fillColor="#F9A63A"
                                  className="mb-2"
                                />
                                <p>
                                  ({" "}
                                  {elem?.relability
                                    ? elem?.relability?.booked
                                    : "0"}{" "}
                                  Interview,{" "}
                                  {elem?.relability?.count &&
                                  elem?.relability?.booked
                                    ? (
                                        (Number(elem?.relability?.booked) /
                                          Number(elem?.relability?.count)) *
                                        100
                                      ).toFixed(0)
                                    : "0"}
                                  % Relability )
                                </p>
                              </div>
                            </div>
                            {/* <div className="mysession__container__bottom">
                              {elem?.mentorAvalible?.booked ?
                                (
                                  <button
                                    onClick={() => getSingleData(elem)}
                                    className={`join__session__button ${!isActive
                                      ? "join__session__disabled"
                                      : "join__session__enabled"
                                      }`}
                                  >
                                    {!isActive !== ""
                                      ? `Session starts ${moment(
                                        elem?.mentorAvalible?.startDateTime
                                      ).format("YYYY-MM-DD HH:mma A")}`
                                      : "Join Session"}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => getSingleData(elem)}
                                    className={`join__session__button ${!isActive
                                      ? "join__session__disabled"
                                      : "join__session__enabled"
                                      }`}
                                  >
                                    {!isActive !== ""
                                      ? `Session starts ${moment(
                                        elem?.creatorAvalible?.startDateTime
                                      ).format("YYYY-MM-DD HH:mma A")}`
                                      : "Non Booked"}
                                  </button>
                                )}
                            </div> */}
                          </MySessionCard>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5} className="col-md-4__show" style={{ padding: "0px" }}>
            <div className="myavailability__left">
              <div className="myavailability__left__bottom">
                <div className="myavailability__left__bottom__top">
                  <h2 className="myavailability__left__bottom__top__title">
                    Upcoming sessions
                  </h2>
                  <button
                    onClick={() => setSelectedComponent(1)}
                    className="schedules__guides__contaiener__center__inner__title__button"
                    href=""
                  >
                    View All
                  </button>
                </div>
                <div className="myavailability__left__bottom__bottom">
                  <Row className="myavailability__left__bottom__bottom__container">
                    {mySessionData?.map((elem, index) => {
                      return (
                        <Col
                          style={{ padding: "0", marginBottom: "20px" }}
                          xs={12}
                          key={index}
                        >
                          <MySessionCard startTime={elem?.time}>
                            <div className="mysession__container__top">
                              <img
                                src={
                                  elem?.userProfile?.image
                                    ? elem?.userProfile?.image
                                    : AvatarIcon
                                }
                                alt="avatar icon"
                                className="mysession__container__top__image"
                                style={{ borderRadius: "50%" }}
                              />
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="dots__button"
                                >
                                  <img
                                    src={Dots}
                                    alt="see more"
                                    className="dots__button__dots"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                  <Dropdown.Item
                                    className="custom__dropdown__item"
                                    href=""
                                    onClick={() => getUserInterViewGuide(elem)}
                                  >
                                    View guide
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="custom__dropdown__item"
                                    // href=""
                                    // onClick={rescheduleSession}
                                  >
                                    Reschedule Sessions
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    className="custom__dropdown__item"
                                    href=""
                                  >
                                    Cancel Session
                                    </Dropdown.Item> */}
                                  {/* <Dropdown.Item className="custom__dropdown__item">
                                    View User Profile
                                  </Dropdown.Item> */}
                                  {/* <Dropdown.Item className="custom__dropdown__item">
                                    Remove Session
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="mysession__container__center">
                              <h2 className="mysession__container__center__title">
                                {elem?.userData?.firstName}{" "}
                                {elem?.userData?.lastName}
                                {elem?.creatorId === userData?._id && (
                                  <span className="pill-light-squared">
                                    Proposed
                                  </span>
                                )}
                              </h2>
                              <div className="mysession__container__center__top">
                                <div className="mysession__container__center__top__container">
                                  <img
                                    src={LocationIcon}
                                    alt="location icon"
                                    className="mysession__container__center__top__container__image"
                                  />
                                  <p className="text-capitalize">
                                    {elem?.userProfile?.location
                                      ? elem?.userProfile?.location
                                      : "Pakistan"}
                                  </p>
                                </div>
                                <div className="mysession__container__center__top__container">
                                  <img
                                    src={GraduationIcon}
                                    alt="graduation icon"
                                    className="mysession__container__center__top__container__image"
                                  />
                                  <p>
                                    {elem?.userProfile?.educationDetails[0]
                                      ?.degree
                                      ? elem?.userProfile?.educationDetails[0]
                                          ?.degree
                                      : ""}
                                  </p>
                                </div>
                                {/* mobile */}

                                <div className="mysession__container__center__bottom d-md-none">
                                  <img
                                    src={UniversityIcon}
                                    alt="graduation icon"
                                    className="mysession__container__center__bottom__image"
                                  />
                                  <p>
                                    {/* University of Washington */}
                                    {elem?.userProfile?.educationDetails[0]
                                      ?.institution
                                      ? elem?.userProfile?.educationDetails[0]
                                          ?.institution
                                      : ""}
                                  </p>
                                </div>
                              </div>
                              <div className="mysession__container__center__bottom d-none d-md-flex ">
                                <img
                                  src={UniversityIcon}
                                  alt="graduation icon"
                                  className="mysession__container__center__bottom__image"
                                />
                                <p>
                                  {/* University of Washington */}
                                  {elem?.userProfile?.educationDetails[0]
                                    ?.institution
                                    ? elem?.userProfile?.educationDetails[0]
                                        ?.institution
                                    : ""}
                                </p>
                              </div>
                              <div className="mysession__container__center__ratings ratings__container__availability">
                                <Ratings />
                                <p>(12 Interviews, 98% Reliability)</p>
                              </div>
                            </div>
                            {/* <div className="mysession__container__bottom">
                              {elem?.mentorAvalible?.booked ?
                                (
                                  <button
                                    onClick={() => getSingleData(elem)}
                                    className={`join__session__button ${!isActive
                                      ? "join__session__disabled"
                                      : "join__session__enabled"
                                      }`}
                                  >
                                    {!isActive !== ""
                                      ? `Session starts ${moment(
                                        elem?.mentorAvalible?.startDateTime
                                      ).format("YYYY-MM-DD HH:mma A")}`
                                      : "Join Session"}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => getSingleData(elem)}
                                    className={`join__session__button ${!isActive
                                      ? "join__session__disabled"
                                      : "join__session__enabled"
                                      }`}
                                  >
                                    {!isActive !== ""
                                      ? `Session starts ${moment(
                                        elem?.creatorAvalible?.startDateTime
                                      ).format("YYYY-MM-DD HH:mma A")}`
                                      : "Non Booked"}
                                  </button>
                                )}
                            </div> */}
                          </MySessionCard>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {loader && <ScreenLoader />}
      {/* delete slot modal */}
      <ConfirmModal show={delModal} setShow={() => setDelModal(false)}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Delete Slot</div>
          <div className="para__para__para ">
            Are you sure you want to delete this session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={deleteSlot}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={() => setDelModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>
      {modalShow && (
        <SessionModal
          show={modalShow}
          setShow={setModalShow}
          style={{ width: "670px !important", padding: "0 !important" }}
        >
          <div className="" style={{ display: "block" }}>
            <div className="modal__content__containerrr">
              <div className="modal__content__container__titleee">
                <h2 className="modal__content__container__titleee__left">
                  See Scheduled
                </h2>
                <p
                  className="modal__content__container__titleee__right"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedComponent(4)}
                >
                  Edit Profile
                </p>
              </div>
              <div className="modal__content__container__item">
                <p className="type__type modal__content__container__item__title">
                  Session type
                </p>
                <div className="modal__content__container__item__pills">
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {mentorData[0]?.mentorPricing[0]?.generalGuidance > 0 &&
                      "General Guidance"}
                  </div>
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {mentorData[0]?.mentorPricing[0]?.cvReview > 0 &&
                      "CV Review"}
                  </div>

                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {mentorData[0]?.mentorPricing[0]?.coverLetter > 0 &&
                      "Cover Letter Review"}
                  </div>
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {mentorData[0]?.mentorPricing[0]?.interviewPractice > 0 &&
                      "Interviews Profile"}
                  </div>
                </div>
              </div>
              {/* <div className="modal__content__container__item">
                <p className="type__type modal__content__container__item__title">
                  Timezone
                </p>
                <div className="modal__content__container__item__text">
                  Portugal, Europe (GMT +2)
                </div>
              </div> */}
              <div className="modal__content__container__item">
                <p className="type__type modal__content__container__item__title">
                  Date
                </p>
                <div className="modal__content__container__item__pills">
                  <div className="modal__content__container__item__text">
                    {/* {timeconversion(
                      singleData?.startDateTime,
                      singleData?.endDateTime,
                      singleData?.date
                    )} */}

                    {moment(singleData?.date).format("MMMM DD, YYYY")}
                  </div>
                </div>
              </div>
              <div className="modal__content__container__item">
                <p className="type__type modal__content__container__item__title">
                  Time Range
                </p>
                <div className="modal__content__container__item__pills">
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {moment(singleData?.startDateTime).format("h:mma")}
                  </div>
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    {moment(singleData?.endDateTime).format("h:mma")}
                  </div>
                  {/* <div className="pill-grey modal__content__container__item__pills__pill">
                    10:30 PM
                  </div>
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    11:30 PM
                  </div>
                  <div className="pill-grey modal__content__container__item__pills__pill">
                    12:30 PM
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <Row className="modal__content__container__bottom">
                <Col
                  md={12}
                  className="modal__content__container__bottom__left"
                  style={{ padding: "0", paddingRight: "10px" }}
                >
                  <div className="modal__content__container__bottom__left__difficulty">
                    <div className="modal__content__container__bottom__left__difficulty__inner">
                      <h2 className="modal__content__container__bottom__left__difficulty__inner__title">
                        Difficulty level
                      </h2>
                      <img
                        src={LevelIcon}
                        alt="level"
                        className="modal__content__container__bottom__left__difficulty__inner__image"
                      />
                    </div>
                    <div className="modal__content__container__bottom__left__difficulty__inner__pills">
                      <div className="pill-grey">{singleData?.difficultyLevel}</div>
                    </div>
                  </div>
                  <div className="modal__content__container__bottom__left__topics">
                    <div className="modal__content__container__bottom__left__topics__inner">
                      <h2 className="modal__content__container__bottom__left__topics__inner__title">
                        Topics
                      </h2>
                      <img
                        src={DocumentIcon}
                        alt=""
                        className="modal__content__container__bottom__left__topics__inner__image"
                      />
                    </div>
                    <div className="modal__content__container__bottom__left__topics__pills">
                      {[
                        "Fit",
                        "Brainteasers",
                        "Accounting",
                        "Valuation",
                        "DCF",
                        "LBO",
                        "Merger Model",
                      ].map((item) => (
                        <div className="pill-grey">{item}</div>
                      ))}
                    </div>
                  </div>
                  <div className="modal__content__container__bottom__left__bottom">
                    <div
                      className="modal__content__container__bottom__left__bottom__top"
                      onClick={getUserInterViewGuide}
                    >
                      <p className="modal__content__container__bottom__left__bottom__top__title">
                        Interview guide - Interviewer
                      </p>
                      <img
                        src={EyeIcon}
                        alt=""
                        className="modal__content__container__bottom__left__bottom__top__image"
                      />
                    </div>
                    <div className="modal__content__container__bottom__left__bottom__top">
                      <p className="modal__content__container__bottom__left__bottom__top__title">
                        Interview guide - Candidate
                      </p>
                      <img
                        src={LockIcon}
                        alt=""
                        className="modal__content__container__bottom__left__bottom__top__image"
                      />
                    </div>
                  </div>
                </Col>
              </Row> */}
        </SessionModal>
      )}
      {/*  */}
      <SessionModal
        show={modalShowInterview}
        setShow={setModalShowInterview}
        style={{ width: "670px !important", padding: "0 !important" }}
      >
        <div className="">
          <Row className="flex-column-reverse flex-md-row">
            <Col xs={12} md={12} className="p-0">
              <section className="faqs border-grad1">
                <div className="asked__heading">
                  <p className="dashboard__title">
                    <span className="ms-2"> You will ask</span>
                  </p>
                </div>
                <div className="mobile-responsive-table">
                  <Accordion defaultActiveKey="0">
                    {myInterviewGuideData[0]?.interViewGuidData?.answeredQuestions.map(
                      (elem, index) => {
                        return (
                          <Accordion.Item
                            eventKey={index}
                            style={{ background: "#1A1C1F" }}
                          >
                            <Accordion.Header>
                              {/* <span className='numberingss'>{index + 1}.</span>  */}
                              {elem?.text}
                            </Accordion.Header>
                            <Accordion.Body style={{ background: "#1A1C1F" }}>
                              <div className="my-tabs">
                                <Tabs
                                  defaultActiveKey="answer"
                                  id="fill-tab-example"
                                  className="mb-3"
                                  fill
                                >
                                  <Tab eventKey="answer" title="Answer">
                                    <p className="para">{elem?.answer}</p>
                                  </Tab>
                                  <Tab eventKey="scorecard" title="Scorecard">
                                    <p className="para">Scorecard</p>
                                  </Tab>
                                </Tabs>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      }
                    )}
                  </Accordion>
                </div>
              </section>
            </Col>
          </Row>
        </div>
      </SessionModal>
    </>
  );
};

export default MyAvailability;
