import Modal from "react-bootstrap/Modal";
// import TaskIcon from "../../assets/taskIcon.svg"
// import { SessionModelCard } from '../Custom';
// import "./SessionModal.css"

const SessionModal = ({show,setShow,children}) => {
  const HideModal= ()=> setShow(false)
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className="ModalWrapper"
      onHide={HideModal}
    >
     {children}
    </Modal>
  );
};

export default SessionModal;



export const ConfirmModal = ({show,setShow,children}) => {
  const HideModal= ()=> setShow(false)
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className="confirm__modal__modal__main"
      onHide={HideModal}
    >
     {children}
    </Modal>
  );
};


