import React, { useState, useEffect, useRef } from "react";
// icons
import LeftArrow from "../../../../assets/arrow-left.svg";
import ClockIcon from "../../../../assets/clock.svg";
import Modal from "react-bootstrap/Modal";
import { ConfirmModal } from "../../../SessionModal/SessionModal";
import { Col, Row, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SuccessToast, ErrorToast } from "../../../MyToaster";
import moment from "moment-timezone";
import "moment-timezone/data/meta/latest.json";
import { rescheduleMentorSession } from "../../../../service/redux/middleware/mentor";
import { viewSession } from "../../../../service/redux/middleware/session";
import DateTimePicker from "react-datetime-picker";
import Multiselect from "multiselect-react-dropdown";
import CalendarEaxct from "../../../../assets/CalendarEaxct.svg";
// for timepicker

const formatDate = (date) => {
  var inputDate = new Date(date);
  var year = inputDate.getFullYear();
  var month = inputDate.getMonth() + 1;
  var day = inputDate.getDate();
  var formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  return formattedDate
  // const year = date?.getFullYear();
  // const month = String(date.getMonth() + 1).padStart(2, "0");
  // const day = String(date.getDate()).padStart(2, "0");
  // return `${year}-${month}-${day}`;
};

const timeHalfHour = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
];
// setShowRescheduleSession
const EditSchedules = ({ setShowEditSchedules, singleData }) => {
  //validation States
  function timeconversionTime(time) {
    const formattedStartDate = moment(time).format("H:mm");
    return formattedStartDate;
  }

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hourStartTime, setHourStartTime] = useState();
  const [timeZone, setTimeZone] = useState(singleData?.timeZones);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [timeZoneArray, setTimeZoneArray] = useState(getTimeZones());
  const searchRef = useRef(null);
  const [openDateCalender, setOenDateCalender] = useState(false)


  const [timeZoneValue, setTimeZoneValue] = useState("");
  //
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options] = useState([
    { name: "General guidance", id: 1 },
    { name: "CV review", id: 2 },
    { name: "Cover Letter Review", id: 3 },
    { name: "Interviews Profile", id: 4 },
  ]);
  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event here
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event here
  };
  //
  // function timeconversion(sTime, eTime, date) {
  //   const formattedStartDate = moment(sTime).format("h:mma");
  //   const formattedEndDate = moment(eTime).format("h:mma");
  //   const eventDate = moment(date).format("MMMM DD, YYYY");
  //   const formattedString = `${formattedStartDate} – ${formattedEndDate}, ${eventDate}`;
  //   return formattedString;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BacktoMysession = () => {
    setShowEditSchedules(false);
  };
  // for caldander

  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];

      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };
  function getTimeZones() {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset() / 60;
      const offsetString = offset >= 0 ? `+${offset}` : offset;
      const timeZoneName = moment.tz(zone).zoneAbbr();

      return {
        value: zone,
        label: `${zone}, ${timeZoneName} (GMT ${offsetString})`,
      };
    });
  }
  const timeZones = getTimeZones();

  const dispatch = useDispatch();
  const history = useHistory();

  const formValidation = () => {
    // if (timeZone === null || timeZone === undefined) {
    //     ErrorToast("Please select your timezone");
    //     return false;
    // }
    let allGood3 = true;
    if (timeSlot?.startTime !== "") {
      //     const [hours1, minutes1] = hourStartTime?.split(":").map(Number);
      //     const [hours2, minutes2] = hourEndTime.split(":").map(Number);
      //     const date1 = new Date(0, 0, 0, hours1, minutes1);
      //     const date2 = new Date(0, 0, 0, hours2, minutes2);
      //     const timeDifferenceMinutes = Math.abs(date2 - date1) / (60 * 1000);
      //     const maxAllowedDifferenceMinutes = 90;
      //     const isWithinLimit =
      //         timeDifferenceMinutes === maxAllowedDifferenceMinutes;
      //     if (timeDifferenceMinutes === maxAllowedDifferenceMinutes) {
      //     } else {
      //         ErrorToast(
      //             "Difference between start and end time should be 1.5 Hours."
      //         );
      //         allGood3 = false;
      //         return false;
      //     }
    } else {
      ErrorToast("Plese Select Date and Time");
      allGood3 = false;
      // return;
    }

    if (allGood3) {
    } else {
      return false;
    }
  };
  //   if (timeZone === null || timeZone === undefined) {
  //     ErrorToast("Please select your timezone");
  //     return false;
  //   }
  //   let allGood3 = true;
  //   if (allGood3) {
  //     if (difficultyLevel === "") {
  //       ErrorToast("Please select difficulty Level");
  //       return false;
  //     } else {
  //       let allGood2 = true;
  //       sessionObjectives?.map((item) => {
  //         if (item.objecive !== "") {
  //         } else {
  //           ErrorToast("Please add objectives");
  //           allGood2 = false;
  //           // return;
  //         }
  //       });
  //       if (allGood2 === false) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  const reScheduleSession = () => {
    let send = timeSlot?.dateSend;
    if (timeSlot?.dateSend === singleData?.date) {
    } else {
      send = formatDate(timeSlot?.dateSend);
      if (singleData?.date === timeSlot?.dateSend) {
        send = timeSlot?.date;
      } else {
        send = formatDate(timeSlot?.dateSend);
      }
    }
    const data = {
      timezone: timeZone,
      timeZoneValue: timeSlot?.timeZoneValue,
      date: send,
      startTime: timeSlot?.startTime,
      endTime: timeSlot?.endTime,
      meetingId: singleData?._id,
      userId: userData?._id,
      token: token,
    };
    dispatch(rescheduleMentorSession(data)).then((res) => {
      console.log('res',res);
      if (res?.payload?.status === 200) {
        SuccessToast(res?.payload?.data?.message);
        dispatch(viewSession()).then((res1) => {
          if (res1?.payload?.status === 200) {
            // setSessionDataBook(res?.payload?.data);
          }
        });
        setShowConfirmModal(false);
        // setShowPropose(false);
        history.push("/interviewdashboard");
      } else {
        ErrorToast(res?.payload?.message);
      }
    });
  };
  const cancelConfirmation = () => {
    setShowConfirmModal(false);
  };

  const SubmitBookSesssion = () => {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setShowConfirmModal(true);
  };

  const [timeSlot, setTimeSlot] = useState({
    timezone: timeZone,
    timeZoneValue: timeZoneValue,
    date: moment(new Date()).format("YYYY-MM-DD"),
    dateSend: new Date(),
    startTime: hourStartTime,
    endTime: "",
    originTime: "",
  });

  const [zoneValue, setZoneValue] = useState("");

  const selectedValue = (elem) => {
    const parts = elem?.timeZoneValue.split(", ");
    const timezone = parts[0].trim();
    const origin = moment.tz(timezone);
    let s1 = origin.format("HH");
    let s2 = origin.format("mm");
    if (s1 < 22) {
      if (s2 <= 30) {
        s2 = "30";
      } else {
        s2 = "00";
        s1 = Number(s1) + 1;
        if (s1 < 10) {
          s1 = "0" + s1;
        }
      }
    }
    let ss = s1 + ":" + s2;

    setZoneValue(ss);
    if (elem?.date) {
      setTimeSlot((prevState) => ({
        ...prevState,
        dateSend: elem?.date,
      }));
    }
    let convert = "";
    if (elem?.startDateTime) {
      let a = timeconversionTime(elem?.startDateTime);
      convert = timeParser(a);
    }
    const initialOption = timeHalfHour.find((item) => item == convert);
    if (initialOption) {
      setHourStartTime(initialOption);
      setTimeSlot((prevState) => ({
        ...prevState,
        startTime: initialOption,
        originTime: ss,
        timeZoneValue: elem?.timeZoneValue,
        endTime: addAndCheckTime(initialOption),
      }));
    }
    const timeZoneValue = timeZones.find(
      (item) => item.label == singleData?.timeZoneValue
    );
    if (timeZoneValue) {
      setTimeZoneValue(timeZoneValue?.label);
    }
  };

  useEffect(() => {
    selectedValue(singleData);
  }, [singleData]);

  function getEndTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    // Create a Date object to handle the time
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    // Add 1 hour and 30 minutes
    currentTime.setHours(currentTime.getHours() + 1);
    currentTime.setMinutes(currentTime.getMinutes() + 30);
    // Get the updated hours and minutes
    const updatedHours = currentTime.getHours().toString().padStart(2, "0");
    const updatedMinutes = currentTime.getMinutes().toString().padStart(2, "0");
    // Return the updated time in "HH:mm" format
    return `${updatedHours}:${updatedMinutes}`;
  }
  //
  //
  // const handleSelectTimeZone = (selected) => {
  //   const timeZonesList = getTimeZones();
  //   const matchedTimeZone = timeZonesList.find(
  //     (item) => item.value === selected
  //   );
  //   const selectedTimeZone = matchedTimeZone.value;
  //   const selectedLabel = matchedTimeZone.label;
  //   const origin = moment.tz(selectedTimeZone);
  //   setTimeZoneValue(matchedTimeZone.value);
  //   let s1 = origin.format("HH");
  //   let s2 = origin.format("mm");
  //   if (s1 < 22) {
  //     if (s2 <= 30) {
  //       s2 = "30";
  //     } else {
  //       s2 = "00";
  //       s1 = Number(s1) + 1;
  //       if (s1 < 10) {
  //         s1 = "0" + s1;
  //       }
  //     }
  //   }
  //   let ss = s1 + ":" + s2;
  //   const parts = selectedTimeZone.split(", ");
  //   const timezone = parts[0].trim();
  //   const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();

  //   setTimeZone(offsetMinutes);
  //   const initialOption1 = timeHalfHour.find((item) => item == ss);
  //   if (initialOption1) {
  //     const updatedTimeSlot = {
  //       ...timeSlot,
  //       startTime: initialOption1,
  //       timezone: offsetMinutes,
  //       timeZoneValue: selectedLabel,
  //       // timeZoneValue: selectedTimeZone,
  //       originTime: ss,
  //       endTime: getEndTime(initialOption1),
  //     };

  //     setHourStartTime(initialOption1);
  //     setTimeSlot(updatedTimeSlot);
  //   }
  //   // }
  // };
  // const handleSelectTimeZone = (selected) => {
  //   if (selected !== "default") {
  //     const timeZonesList = getTimeZones();
  //     const matchedTimeZone = timeZonesList.find(
  //       (item) => item.value === selected
  //     );
  //     const selectedTimeZone = matchedTimeZone.value;
  //     const selectedLabel = matchedTimeZone.label;
  //     const origin = moment.tz(selectedTimeZone);
  //     setTimeZoneValue(matchedTimeZone.value);
  //     let s1 = origin.format("HH");
  //     let s2 = origin.format("mm");
  //     if (s1 < 22) {
  //       if (s2 <= 30) {
  //         s2 = "30";
  //       } else {
  //         s2 = "00";
  //         s1 = Number(s1) + 1;
  //         if (s1 < 10) {
  //           s1 = "0" + s1;
  //         }
  //       }
  //     }
  //     let ss = s1 + ":" + s2;
  //     let date11 = origin?.format("MMM DD, yyyy");
  //     let date111 = origin?.format("YYYY-DD-MM HH:MM");
  //     if (ss <= "22:30") {
  //       const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
  //       setTimeZone(offsetMinutes);
  //       const initialOption1 = timeHalfHour.find((item) => item == ss);
  //       if (initialOption1) {
  //         const updatedTimeSlot = timeSlot.map((item) => ({
  //           ...item,
  //           startTime: initialOption1,
  //           date: moment(origin).format("YYYY-MM-DD"),
  //           dateSend: date11,
  //           timezone: offsetMinutes,
  //           timeZoneValue: selectedLabel,
  //           originTime: initialOption1,
  //           endTime: getEndTime(initialOption1),
  //         }));
  //         setHourStartTime(initialOption1);
  //         setTimeSlot(updatedTimeSlot);
  //       }
  //     } else {
  //       const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
  //       setTimeZone(offsetMinutes);
  //       // const initialOption1 = timeHalfHour.find((item) => item == ss);
  //       // if (initialOption1) {
  //       const updatedTimeSlot = timeSlot.map((item) => ({
  //         ...item,
  //         startTime: "00:00",
  //         // date: moment(origin).format("YYYY-MM-DD"),
  //         // dateSend: date11,
  //         timezone: offsetMinutes,
  //         timeZoneValue: selectedLabel,
  //         originTime: "00:00",
  //         endTime: getEndTime("00:00"),
  //       }));
  //       setHourStartTime("00:00");
  //       setTimeSlot(updatedTimeSlot);
  //       // }
  //     }
  //   }
  // };

  const handleSelectTimeZone = (selected) => {
    if (selected !== "default") {
      const timeZonesList = getTimeZones();
      const matchedTimeZone = timeZonesList.find(
        (item) => item.value === selected
      );
      const selectedTimeZone = matchedTimeZone.value;
      const selectedLabel = matchedTimeZone.label;
      const origin = moment.tz(selectedTimeZone);
      setTimeZoneValue(matchedTimeZone.value);
      let s1 = origin.format("HH");
      let s2 = origin.format("mm");
      if (s1 < 22) {
        if (s2 <= 30) {
          s2 = "30";
        } else {
          s2 = "00";
          s1 = Number(s1) + 1;
          if (s1 < 10) {
            s1 = "0" + s1;
          }
        }
      }
      let ss = s1 + ":" + s2;
      let date11 = origin?.format("MMM DD, yyyy")

      if (ss <= '22:30') {
        console.log('if sufiiiiiiiii');
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        const initialOption1 = timeHalfHour.find((item) => item == ss);
        if (initialOption1) {
          const updatedTimeSlot = {
            ...timeSlot,
            startTime: initialOption1,
            date: moment(origin).format("YYYY-MM-DD"),
            dateSend: date11,
            timezone: offsetMinutes,
            timeZoneValue: selectedLabel,
            originTime: initialOption1,
            endTime: getEndTime(initialOption1),
          }
          setHourStartTime(initialOption1);
          setTimeSlot(updatedTimeSlot);
        }
      } else {
        console.log('else sufiiiiiiiii');
        const offsetMinutes = moment.tz(selectedTimeZone).utcOffset();
        setTimeZone(offsetMinutes);
        // const initialOption1 = timeHalfHour.find((item) => item == ss);
        // if (initialOption1) {
        const updatedTimeSlot = {
          ...timeSlot,
          startTime: "00:00",
          // date: moment(origin).format("YYYY-MM-DD"),
          // dateSend: date11,
          timezone: offsetMinutes,
          timeZoneValue: selectedLabel,
          originTime: "00:00",
          endTime: getEndTime("00:00"),
        }
        setHourStartTime("00:00");
        setTimeSlot(updatedTimeSlot);
        // }
      }
    }
  };

  //
  const handleSelectStartTime = (selected) => {
    const updatedTimeSlot = { ...timeSlot };
    updatedTimeSlot["startTime"] = selected;
    updatedTimeSlot["endTime"] = getEndTime(selected);
    setTimeSlot(updatedTimeSlot);
  };
  //
  const handleSelectEndTime = (selected) => {
    if (timeSlot.startTime === "") {
      ErrorToast("Please select start time first.");
    } else {
      const updatedTimeSlot = { ...timeSlot };
      updatedTimeSlot["endTime"] = selected;
      setTimeSlot(updatedTimeSlot);
    }
  };
  //
  //
  // const handleChangeForDateAndTime = (e, isDate = "") => {
  //   // Create a copy of the current timeSlot object
  //   const updatedTimeSlot = { ...timeSlot };

  //   // Update the properties based on different conditions
  //   if (isDate.length > 0) {
  //     // singleData;
  //     let isToday = false;
  //     const todayDate = moment(new Date()).format("YYYY-MM-DD");
  //     const itemDate = moment(e).format("YYYY-MM-DD");
  //     itemDate === todayDate ? (isToday = true) : (isToday = false);
  //     if (isToday) {
  //       updatedTimeSlot[isDate] = e;
  //       updatedTimeSlot["date"] = formatDate(e);
  //       updatedTimeSlot["startTime"] = hourStartTime;
  //       updatedTimeSlot["endTime"] = getEndTime(hourStartTime);
  //       updatedTimeSlot["originTime"] = zoneValue;
  //       setTimeSlot(updatedTimeSlot);
  //     } else {
  //       updatedTimeSlot[isDate] = e;
  //       updatedTimeSlot["date"] = formatDate(e);
  //       updatedTimeSlot["endTime"] = "";
  //       updatedTimeSlot["startTime"] = "";
  //       updatedTimeSlot["originTime"] = "";
  //       setTimeSlot(updatedTimeSlot);
  //     }
  //   } else if (e.target.name === "startTime") {
  //     updatedTimeSlot[e.target.name] = e.target.value;
  //     updatedTimeSlot["endTime"] = getEndTime(e.target.value);
  //   } else if (e.target.name === "endTime") {
  //     if (updatedTimeSlot.startTime === "") {
  //       ErrorToast("Please select start time first.");
  //     } else {
  //       updatedTimeSlot[e.target.name] = e.target.value;
  //     }
  //   } else {
  //     // Update other properties if needed
  //     updatedTimeSlot[e.target.name] = e.target.value;
  //   }

  //   // Set the modified timeSlot object to the state
  //   setTimeSlot(updatedTimeSlot);
  // };
  const handleChangeForDateAndTime = (e, isDate = "") => {
    const updatedTimeSlot = { ...timeSlot };
    if (isDate.length > 0) {
      let isToday = false;
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      if (timeZoneValue === userTimeZone) {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["startTime"] = hourStartTime;
          updatedTimeSlot["endTime"] = getEndTime(hourStartTime);
          updatedTimeSlot["originTime"] = zoneValue;
          setTimeSlot(updatedTimeSlot);
        } else {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["endTime"] = "";
          updatedTimeSlot["startTime"] = "";
          updatedTimeSlot["originTime"] = "";
          setTimeSlot(updatedTimeSlot);
        }
      }
      else{
        const now = moment.tz(timeZoneValue);
        const timeZoness = now.format('YYYY-MM-DD HH:mm:ss')
        const todayDate = moment.utc(timeZoness).startOf('day').format("YYYY-MM-DD");
        const itemDate = moment(e).format("YYYY-MM-DD");
        // const todayDate = moment(new Date()).format("YYYY-MM-DD");
        // const itemDate = moment(e).format("YYYY-MM-DD");
        itemDate === todayDate ? (isToday = true) : (isToday = false);
        if (isToday) {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["startTime"] = hourStartTime;
          updatedTimeSlot["endTime"] = getEndTime(hourStartTime);
          updatedTimeSlot["originTime"] = zoneValue;
          setTimeSlot(updatedTimeSlot);
        } else {
          updatedTimeSlot[isDate] = e;
          updatedTimeSlot["date"] = formatDate(e);
          updatedTimeSlot["endTime"] = "";
          updatedTimeSlot["startTime"] = "";
          updatedTimeSlot["originTime"] = "";
          setTimeSlot(updatedTimeSlot);
        }

      }
    } else if (e.target.name === "startTime") {
      updatedTimeSlot[e.target.name] = e.target.value;
      updatedTimeSlot["endTime"] = getEndTime(e.target.value);
    } else if (e.target.name === "endTime") {
      if (updatedTimeSlot.startTime === "") {
        ErrorToast("Please select start time first.");
      } else {
        updatedTimeSlot[e.target.name] = e.target.value;
      }
    } else {
      // Update other properties if needed
      updatedTimeSlot[e.target.name] = e.target.value;
    }

    // Set the modified timeSlot object to the state
    setTimeSlot(updatedTimeSlot);
  };

  function addAndCheckTime(timeStr) {
    // if (timeStr === '00:00') {
    //   return timeStr
    // }

    const currentTime = new Date();
    const [hours, minutes] = timeStr?.split(":").map(Number);
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59);
    const timeDifference = endOfDay - currentTime;
    let endDF = timeDifference / 60 / 60 / 1000;
    if (endDF === 1.4997222222222222) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 29);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else if (timeDifference > 1.5 * 60 * 60 * 1000) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const resultHours = currentTime.getHours().toString().padStart(2, "0");
      const resultMinutes = currentTime
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${resultHours}:${resultMinutes}`;
    } else {
      return "25:00";
    }
  }
  //
  //
  // Search Timezone
  //
  //
  const [searchedTimezone, setSearchedTimezone] = useState("");
  const searchTimeZonesValue = (e) => {
    setSearchedTimezone(e.target.value);
  };
  const searchTimezonesFromArray = () => {
    if (searchedTimezone === "") {
      setTimeZoneArray(timeZones);
    } else {
      let list = [...timeZones];
      list = list.filter((item) =>
        item.label.toLowerCase().includes(searchedTimezone.toLowerCase())
      );
      setTimeZoneArray(list);
    }
  };

  const handleDropdownToggleTimeZone = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    searchTimezonesFromArray();
  }, [searchedTimezone]);
  return (
    <>
      <div className="reschedule__session__container">
        <div className="reschedule__session__container__top">
          <div
            className="reschedule__session__container__top__left"
            onClick={BacktoMysession}
          >
            <img
              src={LeftArrow}
              alt="left arrow"
              className="reschedule__session__container__top__left__icon"
            />
            <p className="reschedule__session__container__top__left__text propose__session__text marginTop">
              Back To Availability
            </p>
          </div>
        </div>
        <div className="reschedule__session__container__bottom edit__schedule__margin">
          <Row className="reschedule__session__container__bottom__row">
            <Col
             style={{width: "68%"}}
              md={6}
              className="reschedule__session__container__bottom__left"
            >
              <h2 className="reschedule__session__container__bottom__left__title">
                Edit Schedule
              </h2>
              <div className="reschedule__session__container__bottom__left__form">
                <div className="reschedule__session__container__bottom__left__form__container">
                  {/* <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p
                      className="reschedule__session__container__bottom__left__form__container__label__text"
                      style={{ marginBottom: "4px" }}
                    >
                      Select Sessions(s)
                    </p>
                  </label> */}
                  {/* <div style={{ marginBottom: "8px" }}>
                    <Multiselect
                      options={options}
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      placeholder="Select services"
                    />
                  </div> */}
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Timezone
                    </p>
                    <img
                      src={ClockIcon}
                      alt="clock icon"
                      className="reschedule__session__container__bottom__left__form__container__label__icon"
                    />
                  </label>
                  <Dropdown onSelect={handleSelectTimeZone} onToggle={handleDropdownToggleTimeZone}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdownToSelect select__icon"
                    >
                      <p className="forumm__button__text">
                        {timeZoneValue
                          ? timeZoneValue
                          : "Please select timezone by city"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu timezone__relative"
                      style={{ padding: "0px" }}
                    >
                      <Form.Control
                      ref={searchRef}
                        type="text"
                        className="search__input search__timezone"
                        placeholder="Search timezone by city..."
                        name="timezone"
                        value={searchedTimezone}
                        onChange={(e) => searchTimeZonesValue(e)}
                      />
                      {timeZoneArray?.map((zone) => (
                        <Dropdown.Item
                          key={zone.value}
                          eventKey={zone.value}
                          className={`dropdownToSelect__item ${
                            zone.value === timeZoneValue
                              ? "dropdownToSelect__item__checked"
                              : ""
                          }`}
                        >
                          {zone.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* start time  */}
                  <>
                    {/* <select
                    className="reschedule__session__container__bottom__left__form__container__input input__element select__icon"
                    // value={selectedTimeZone}
                    // value={sessionData.timezone}
                    name="timezone"
                    // onChange={(e) => setSelectedTimeZone(e.target.value)}
                    value={timeZoneValue ? timeZoneValue : ""}
                    onChange={(e) => handleChange(e)}
                  >
                    {timeZones.map((zone) => (
                      <option key={zone.label}>{zone.label}</option>
                    ))}
                  </select> */}
                  </>
                  {/* start time  */}
                </div>
                <div className="reschedule__session__container__bottom__left__form__container">
                  <label className="reschedule__session__container__bottom__left__form__container__label">
                    <p className="reschedule__session__container__bottom__left__form__container__label__text">
                      Date
                    </p>
                  </label>
                  <DateTimePicker
                    format="MMM dd, yyyy"
                    disableClock={true}
                    isCalendarOpen={openDateCalender ? true : false}
                          onClick={() => setOenDateCalender(!openDateCalender)}
                    className="schedules__guides__contaiener__calendar calender__padding"
                    name="dateSend"
                    placeholder="Select expiration date..."
                    calendarIcon={
                      <img
                        src={CalendarEaxct}
                        alt="Calendar Icon"
                        style={{ width: "20px", height: "20px" }} // Set the size as needed
                      />
                    }
                    onChange={(e) => handleChangeForDateAndTime(e, "dateSend")}
                    value={timeSlot?.dateSend}
                    // minDate={timeSlot.startTime >= "22:30" ? "" : new Date()}
                    // minDate={new Date()}
                  />
                </div>

                <div className="reschedule__session__container__bottom__left__form__container__two">
                  <Dropdown
                    onSelect={(e) => handleSelectStartTime(e)}
                    style={{ width: "49%" }}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={`dropdownToSelect select__icon `}
                      // ${
                      //   timeZone == null ? "dropdownToSelect__maindisabled" : ""
                      // }
                    >
                      <p className="forumm__button__text">
                        {timeSlot?.startTime
                          ? timeSlot?.startTime
                          : "Select start time"}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                      {timeHalfHour.map((hour) => (
                        <Dropdown.Item
                          className={`${
                            hour < timeSlot?.originTime
                              ? " dropdownToSelect__disabled"
                              : timeSlot?.startTime === hour
                              ? "dropdownToSelect__item__checked"
                              : "dropdownToSelect__item"
                          }`}
                          eventKey={hour}
                          key={hour}
                          disabled={hour < timeSlot?.originTime}
                        >
                          {hour}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* Start time */}
                  {/* <select
                    id="startTime"
                    name="startTime"
                    className="reschedule__session__container__bottom__left__form__container__input input__element select__icon"
                    value={timeSlot?.startTime}
                    onChange={(e) => handleChangeForDateAndTime(e)}
                    style={{ width: "49%" }}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      hidden
                      className="DropDownMentor__select__item"
                    >
                      Select start time
                    </option>
                    {timeHalfHour.map((hour) => (
                      <option
                        className=""
                        value={hour}
                        disabled={hour < timeSlot?.originTime}
                      >
                        {hour}
                      </option>
                    ))}
                  </select> */}
                  {/* Start time */}
                  <Dropdown
                    onSelect={(e) => handleSelectEndTime(e)}
                    style={{ width: "49%" }}
                    show={false}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={`dropdownToSelect  ${
                        timeZone == null ? "dropdownToSelect__maindisabled" : ""
                      }`}
                    >
                      <p className="forumm__button__text">
                        {timeSlot?.endTime
                          ? timeSlot?.endTime
                          : timeSlot?.startTime === ""
                          ? "End time"
                          : // : addAndCheckTime(timeSlot?.startTime)}
                            ""}
                      </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                      {timeHalfHour.map((hour) => (
                        <Dropdown.Item
                          className={`${
                            hour < timeSlot?.startTime
                              ? " dropdownToSelect__disabled"
                              : timeSlot?.endTime === hour
                              ? "dropdownToSelect__item__checked"
                              : "dropdownToSelect__item"
                          }`}
                          eventKey={hour}
                          key={hour}
                          // disabled={
                          //   (hour !== "00:00" &&
                          //     timeSlot?.startTime &&
                          //     // hour <= timeSlot[i]?.startTime
                          //     hour < addAndCheckTime(timeSlot.startTime)) ||
                          //   hour > addAndCheckTime(timeSlot.startTime)
                          // }
                        >
                          {hour}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* End time */}
                  {/* <select
                    id="endTime"
                    name="endTime"
                    className={`eschedule__session__container__bottom__left__form__container__input input__element pointer__none
                    
                   `}
                    value={
                      timeSlot?.endTime
                        ? timeSlot?.endTime
                        : timeSlot?.startTime === ""
                        ? ""
                        : ""
                      // : addAndCheckTime(timeSlot?.startTime)
                    }
                    onChange={(e) => handleChangeForDateAndTime(e)}
                    style={{ width: "49%" }}
                  >
                    <option value="" disabled selected hidden>
                      Select end time
                    </option>
                    {timeHalfHour.map((hour) => (
                      <option disabled={hour <= hourStartTime} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select> */}
                  {/* End time */}
                </div>
              </div>
              <p style={{margin:"12px 0px 24px 0px "}} className="reschedule__session__container__bottom__left__subtitle">
                Each meeting slot is allocated a duration of 1.5 hours
              </p>
              <div className="reschedule__session__container__bottom__left__form margin-propose-32">
                <button className="btn-green" onClick={SubmitBookSesssion}>
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* session confirm modal */}

      <ConfirmModal show={showConfirmModal} setShow={setShowConfirmModal}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Reschedule Session</div>
          <div className="para__para__para ">
            Are you sure you want to go ahead and reschedule this session ?
          </div>
          <hr />
          <div className="confirm__btn__main">
            <button className="confirm__btn" onClick={reScheduleSession}>
              Confirm
            </button>
            <button className="cancel__btn" onClick={cancelConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

export default EditSchedules;
