import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/8.svg";
import { useDispatch } from "react-redux";
//
import FacebookIcon from "../../assets/facebook.svg";
import LinkedInIcon from "../../assets/linkedin.svg";
import TwitterIcon from "../../assets/twitter.svg";
import MailIcon from "../../assets/ion_mail.svg";
//
import "./NewsletterFooter.scss";
import { toast } from "react-toastify";
import { sendSubscription } from "../../service/redux/middleware/newsletter";
const NewsletterFooter = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleSignup = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
      const data = { email: email };
      dispatch(sendSubscription(data)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data);
        } else {
          toast.error(res?.payload?.data);
        }
      });
    } else {
      toast.info("Please enter a valid email address");
    }
  };
  return (
    <div>

    <div  className="container__custom newsletter-container">
      <div className="newsletter__container ">
        <div className="newsletter__container__top">
          <h3 className="newsletter__container__top__heading">
            Sign up for the fast_track newsletter
          </h3>
          <p className="newsletter__container__top__text">
            Receive startup news & insights, exclusive event invitations, and
            more directly to your inbox.
          </p>
          <div className="newsletter__container__top__email">
            <Form.Group className="loginfield mt-0 newsletter__container__top__email__container">
              <Form.Label
                htmlFor="email"
                className=" newsletter__container__top__email__container__label "
              >
                Email
              </Form.Label>
              <Form.Control
                id="email"
                type="email"
                placeholder="johndoe@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className=" loginfield__type newsletter__container__top__email__container__input"
              />
            </Form.Group>
            <p className="newsletter__container__top__email__text">
              By clicking submit, you are agreeing to receive communications
              from FastTrack and to our{" "}
              <Link
                to="/"
                className="newsletter__container__top__email__text__link"
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to="/"
                className="newsletter__container__top__email__text__link"
              >
                Privacy Policy
              </Link>
              . If you have questions please reach out us at
              contact@fasttrack.com.
            </p>
          </div>
          <div className="newsletter__container__top__button">
            <button className="newsletter__button" onClick={handleSignup}>
              Sign Me Up!
            </button>
          </div>
        </div>
        <div className="newsletter__container__bottom">
          <Row className=" p-0 flex-md-row flex-column-reverse">
            <Col md={6} className="p-0">
              <div className="newsletter__container__bottom__container">
                <div className="newsletter__container__bottom__container__left">
                  <div className="newsletter__container__bottom__container__left__image">
                    <img src={LogoIcon} />
                  </div>
                  <p className="newsletter__container__bottom__container__left__text">
                    Leading investment banking community for
                    <br />
                    aspiring bankers
                  </p>
                  <div className="newsletter__container__bottom__container__left__social">
                    <Link className="newsletter__container__bottom__container__left__social__item">
                      <img src={LinkedInIcon} />
                    </Link>
                    <Link className="newsletter__container__bottom__container__left__social__item">
                      <img src={FacebookIcon} />
                    </Link>
                    <Link className="newsletter__container__bottom__container__left__social__item">
                      <img src={TwitterIcon} />
                    </Link>
                    <Link className="newsletter__container__bottom__container__left__social__item">
                      <img src={MailIcon} />
                    </Link>
                  </div>
                </div>
                <p className="newsletter__container__bottom__container__copyright">
                  © FastTrack 2023, All Rights Reserved.
                </p>
              </div>
            </Col>
            <Col md={6} className="row links__footer__container p-0">
              <Col md={4} className="p-0">
                <div className="footer__links__container">
                  <h2 className="footer__links__container__header">About</h2>
                  <div className="footer__links__container__inner">
                    <a href="https://www.fast-track.community/resources">
                      Home
                    </a>
                    <Link to="/">Pricing</Link>
                    <a href="https://www.fast-track.community/contact">
                      Contact
                    </a>
                    <Link to="/termsconditions">Terms of Use</Link>
                    <Link to="/termsconditions">Privacy Policy</Link>
                  </div>
                </div>
              </Col>
              <Col md={4} className="p-0">
                <div className="footer__links__container">
                  <h2 className="footer__links__container__header">Learn</h2>
                  <div className="footer__links__container__inner">
                    <a href="https://www.fast-track.community/resources">
                      Study
                    </a>
                    <Link to="/">Interview</Link>
                    <Link to="/network">Network</Link>
                    <Link to="/forum">Forum</Link>
                  </div>
                </div>
              </Col>
              <Col md={4} className="p-0">
                <div className="footer__links__container">
                  <h2 className="footer__links__container__header">Join Us</h2>
                  <div className="footer__links__container__inner">
                    <Link to="/signup">Create an account</Link>
                    <Link to="/login">Log In</Link>
                    <Link to="/mentor">Become a Mentor</Link>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </div>
  );
};

export default NewsletterFooter;
