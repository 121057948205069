import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { buyCredits } from "../../service/redux/middleware/credits";
import { SuccessToast, ErrorToast } from "../MyToaster";
import ScreenLoader from "../Loader/ScreenLoader";
import { confirmBuyCredits } from "../../service/redux/middleware/credits";
import { useHistory } from "react-router-dom";
const PaymentForm = ({ sessionSelectedOption, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }
    if (!loading) {
      if (sessionSelectedOption?.value) {
        setLoader(true);
        setLoading(true);
        let data = {
          currency: Number(sessionSelectedOption?.price),
          token: token,
        };
        dispatch(buyCredits(data)).then(async (res) => {
          if (res?.payload?.status === 200) {
            // setLoader(false)
            const clientSecret = await res?.payload?.data?.clientSecret;
            if (clientSecret) {
              let data2 = {
                userId: userData?._id,
                session: Number(sessionSelectedOption?.sessions),
                amount: amount,
                token: token,
              };
              dispatch(confirmBuyCredits(data2)).then(async (res) => {
                if (res?.payload?.status === 200) {
                  SuccessToast("Sessions purchased successfully");
                  history.push("/interviewdashboard");
                  setLoader(false);
                  try {
                    const { error } = await stripe.confirmPayment({
                      elements,
                      clientSecret,
                      confirmParams: {
                        return_url: "http://localhost:3000",
                      },
                    });
                    if (error) {
                      setErrorMessage(error.message);
                    } else {
                    }
                  } catch (error) {
                    console.error("Error:", error);
                  } finally {
                    setLoader(false);
                    setLoading(false);
                  }
                }
              });
            }
          } else {
            ErrorToast(res?.payload?.message);
            setLoader(false);
            setLoading(false);
          }
        });
      } else {
        ErrorToast("Please select the number of sessions you want to buy.");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          className="payment__button mt-2 "
          disabled={!stripe || !elements || loading}
        >
          {loading ? "Paying..." : "Pay"}
        </button>
      </form>

      {loader && <ScreenLoader />}
    </>
  );
};

export default PaymentForm;
