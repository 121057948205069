import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Link,
  View,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import MyCustomFont from "../../assets/Fonts/AktivGrotesk/TTF/AktivGrotesk-Regular.ttf";

Font.register({
  family: "AntonFamily",
  src: MyCustomFont,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "AntonFamily",
  },
  titleMain: {
    fontSize: 24,
  },
  text: {
    marginBottom: 8,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "AntonFamily",
  },
  fDetails: {
    marginLeft: 18,
    marginTop: 8,
    marginTop: 8,
  },
  userDetails: {
    // display: 'flex',
    // alignItems: 'center',
    // gap: 8,
    marginTop: 14,
    marginBottom: 14,
  },
  textInner: {
    marginBottom: 8,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "AntonFamily",
    display: "inline",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  division: {
    height: 1,
    width: "100%",
    backgroundColor: "#000",
    marginBottom: 20,
  },
});

const PdfGeneratorMentor = ({ data }) => {
  return (
    <Document>
      <Page wrap={true} style={{ ...styles.body, backgroundColor: "#fff" }}>
        <Text style={styles.header} fixed></Text>
        {data?.data?.map((page, index) => {
          return (
            <React.Fragment key={index}>
              <Text style={styles.titleMain}>
                Transaction Number is: {index + 1}
              </Text>
              <View style={styles.fDetails}>
                <>
                  <Text style={styles.text}>Amount :{page?.amount}</Text>{" "}
                  <Text style={styles.text}>
                    Description :
                    {page?.fee_details[0]?.description
                      ? page?.fee_details[0]?.description
                      : "---"}
                  </Text>
                  <Text style={styles.text}>
                    Fee for platform : {Number(page?.fee / 100).toFixed(2)}
                  </Text>
                  <Text style={styles.text}>
                    Net Amount :{Number(page?.net / 100).toFixed(2)}
                  </Text>
                  <Text style={styles.text}>
                    Total Amount :{(Number(page?.amount) / 100).toFixed(2)}
                  </Text>
                  <Text style={styles.text}>Status :{page?.status}</Text>
                </>
              </View>
            </React.Fragment>
          );
        })}
      </Page>
    </Document>
  );
};

export default PdfGeneratorMentor;
