import React from 'react'
import message from '../../assets/meeting/size.svg'
import arrowright from '../../assets/meeting/arrow-right.svg'
import logoutImg from '../../assets/meeting/logout.svg'
import split from '../../assets/meeting/split.svg'
import videoSlash from '../../assets/meeting/video-slash.svg'
import video from '../../assets/meeting/video.svg'
import tick from '../../assets/meeting/tick-circle.svg'
import calender from '../../assets/meeting/calendar.svg'
import add from '../../assets/meeting/add.svg'
import openMeet from "../../assets/meeting/openMeet.svg"
import takeSession from "../../assets/meeting/takeSession.svg"

function HeaderLandingPage({ home }) {
  return (
    <>
      {home ? (
        <div className="main__tab">
          <div className="inner__tab">
            <div className="center__div" >
              <div className="main__of__content">
                <img src={calender} alt="" />
              </div>
              <p className="my__text">Select date and time</p>
            </div>
            <img className="right__img" src={arrowright} alt="" />
            <div className="center__div">
              <div className="main__of__content">
                <img src={add} alt="" />
              </div>
              <p className="my__text">Propose meeting</p>
            </div>
            <img className="right__img" src={arrowright} alt="" />
            <div className="center__div">
              <div className="main__of__content">
                <img src={tick} alt="" />
              </div>
              <p className="my__text">Wait for acceptance</p>
            </div>
            <img className="right__img" src={arrowright} alt="" />
            <div className="center__div">
              <div className="main__of__content">
                <img src={video} alt="" />
              </div>
              <p className="my__text exit">Attend session</p>
            </div>

          </div>
        </div>
      )
        :
        (

          <div className="main__tab">
            <div className="inner__tab">
              <div className="center__div" >
                <div className="main__of__content">
                  <img src={message} alt="" />
                </div>
                <p className="my__text">Two windows open</p>
              </div>
              <img className="right__img" src={arrowright} alt="" />
              <div className="center__div">
                <div className="main__of__content">
                  <img src={split} alt="" />
                </div>
                <p className="my__text">Split screen</p>
              </div>
              <img className="right__img" src={arrowright} alt="" />
              <div className="center__div">
                <div className="main__of__content">
                  <img src={openMeet} alt="" />
                </div>
                <p className="my__text">Open meeting</p>
              </div>
              <img className="right__img" src={arrowright} alt="" />
              <div className="center__div">
                <div className="main__of__content">
                  <img src={takeSession} alt="" />
                </div>
                <p className="my__text">Take the session</p>
              </div>
              <img className="right__img" src={arrowright} alt="" />
              <div className="center__div">
                <div className="main__of__content">
                  <img src={videoSlash} alt="" />
                </div>
                <p className="my__text">Exit video</p>
              </div>
              <img className="right__img" src={arrowright} alt="" />
              <div className="center__div">
                <div className="main__of__content">
                  <img src={logoutImg} alt="" />
                </div>
                <p className="my__text exit">Exit meeting room</p>
              </div>

            </div>
          </div>
        )
      }

    </>
  )
}

export default HeaderLandingPage