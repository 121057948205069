import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { Col, Dropdown, Row } from "react-bootstrap";
import { MySessionCard } from "../../Custom";
import DropDownImage from "../../../assets/arrow-down.svg";
import InfoCircle from "../../../assets/info-circle.svg";

import docs from "../../../assets/document-text.svg";
import SortIcon from "../../../assets/sort.svg";
import "./myearnring.scss";
import ScreenLoader from "../../Loader/ScreenLoader";
import { EraningFooter } from "../../Footer/Footer";
import PdfGeneratorMentor from "../../PDFGenerator/PdfGenerator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MyEarning = ({ loader, earningData, setEarningData }) => {
  const [dropdown, setDropDown] = useState("Date Range");
  const [dropdown1, setDropDown1] = useState("Activity");

  const sendEmailTemplate = () => {
    let document = <PdfGeneratorMentor data={earningData?.transactions} />;
    window.open(document, "_blank");
  };
  return (
    <>
      {loader && <ScreenLoader />}
      <div className="my__earning__main">
        <Row className="myavailabilityCardROW dxs_pad__pad">
          <Col md={12} xl={6} xs={12} style={{ padding: "0px" }}>
            <MySessionCard>
              <div className="first__card">
                <div className="myavailability__container__cards">
                  <div className="myavailability__container__cards__bottom">
                    <div className="d-flex align-items-center">
                      <p>Available balance</p>
                      <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              <p className="custom__tooltip">
                                The available balance indicates the total amount
                                earned but not yet withdrawn to date from your
                                mentoring earnings
                              </p>
                            </Tooltip>
                          }
                        >
                          <img src={InfoCircle} alt="info circle" />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="myavailability__container__cards__bottom">
                    <h2>$1,000</h2>
                    <p>
                      Payments being cleared:{" "}
                      <span className="amount__bold">$500</span>
                    </p>
                  </div>
                </div>
                {/* <button className="first__card__button">Withdraw Funds</button> */}
              </div>
            </MySessionCard>
          </Col>
          <Col md={12} xl={3} xs={12} style={{ padding: "0px" }}>
            <div className=" myavailability-margin-left">
              <MySessionCard>
                <div className="myavailability__container__cards">
                  <div className="myavailability__container__cards__bottom">
                    <div className="d-flex align-items-center">
                      <p>Earnings to date</p>
                      <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              <p className="custom__tooltip">
                                This figure shows the total earnings you've
                                accumulated from mentoring sessions to date.
                              </p>
                            </Tooltip>
                          }
                        >
                          <img src={InfoCircle} alt="info circle" />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="myavailability__container__cards__bottom">
                    <h2>$100,000</h2>
                    <p>Your earnings since joining</p>
                  </div>
                </div>
              </MySessionCard>
            </div>
          </Col>
          <Col md={12} xl={3} xs={12} style={{ padding: "0px" }}>
            <div className=" myavailability-margin-left">
              <MySessionCard>
                <div className="myavailability__container__cards">
                  <div className="myavailability__container__cards__bottom">
                    <div className="d-flex align-items-center">
                      <p>Withdrawn to date</p>
                      <div className="myavailability__container__cards__bottom__tooltip__container__toolcontainer">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              <p className="custom__tooltip">
                                The withdrawn to date amount represents the
                                total funds you have withdrawn from your
                                mentoring earnings.
                              </p>
                            </Tooltip>
                          }
                        >
                          <img src={InfoCircle} alt="info circle" />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="myavailability__container__cards__bottom">
                    <h2>$45,000</h2>
                    <p>Your total earnings withdrawn since joining</p>
                  </div>
                </div>
              </MySessionCard>
            </div>
          </Col>
          <>
            {/* <Col md={4} xs={12} className="col-md-4__hide" style={{ padding: "0px" }}>
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <p>Average Rating</p>
                        <h2>200</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col md={4} xs={12} className="col-md-4__hide" style={{ padding: "0px" }}>
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={Wallet}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <p>Total Sales</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency}
                          {mentorData[0]?.mentorPricing[0]?.coverLetter}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col md={12} xs={12} className="col-md-4__show" style={{ padding: "0px" }}>
                <MySessionCard>
                  <div
                    className="myavailability__container__cards"
                    style={{ height: "125px" }}
                  >
                    <div className="myavailability__container__cards__top">
                      <img
                        src={VideoIcon}
                        alt="wallet"
                        className="myavailability__container__cards__top__wallet"
                      />
                    </div>
                    <div className="myavailability__container__cards__bottom">
                      <p>Sessions Taken</p>
                      <h2>109</h2>
                    </div>
                  </div>
                </MySessionCard>
              </Col>
              <Col md={12} xs={12} className="col-md-4__show" style={{ padding: "0px" }}>
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={GreenStar}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <p>Average Rating</p>
                        <h2>200</h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>
              <Col md={12} xs={12} className="col-md-4__show" style={{ padding: "0px" }}>
                <div className=" myavailability-margin-left">
                  <MySessionCard>
                    <div
                      className="myavailability__container__cards"
                      style={{ height: "125px" }}
                    >
                      <div className="myavailability__container__cards__top">
                        <img
                          src={Wallet}
                          alt="wallet"
                          className="myavailability__container__cards__top__wallet"
                        />
                      </div>
                      <div className="myavailability__container__cards__bottom">
                        <p>Total Sales</p>
                        <h2>
                          {mentorData[0]?.mentorPricing[0]?.currency}
                          {mentorData[0]?.mentorPricing[0]?.coverLetter}
                        </h2>
                      </div>
                    </div>
                  </MySessionCard>
                </div>
              </Col>  */}
          </>
        </Row>
        <div className="border__bottom__bottom"></div>
        <div className="table__top__bar">
          <div
            className="reschedule__session__container__top__left"
            // onClick={() => setShowAddDate(false)}
          >
            <p className="transactions__earnings">
              <span className="transactions__earnings__bold">112</span>{" "}
              Transactions made
            </p>
          </div>
          <div className="reschedule__session__container__top__right">
            <div
              className="d-none d-md-flex align-items-center "
              style={{ gap: "16px" }}
            >
              <button
                className="btn-green11111"
                onClick={() => sendEmailTemplate()}
              >
                <img src={docs} />
                <span className="ms-2"> Email report</span>
              </button>
              {/* <PDFDownloadLink
                document={
                  <PdfGeneratorMentor data={earningData?.transactions} />
                }
                filename="earningData"
                className=""
              >
                {({ loading }) =>
                  loading ? (
                    <button
                      className="btn-green11111"
                    >
                      <img src={docs} />
                      <span className="ms-2"> Email Report</span>
                    </button>
                  ) : (
                    <button className="btn-green11111">
                      <img src={docs} />
                      <span className="ms-2"> Email Report</span>
                    </button>
                  )
                }
              </PDFDownloadLink> */}
            </div>
            {/* Filter adddddd */}
            <div
              className="d-md-none d-flex align-items-center "
              style={{ gap: "16px" }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="filter__button">
                  <img src={SortIcon} />
                  <span className="filter__button__text">{"Filter"}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                  {dropdown1 !== "Activity" && (
                    <Dropdown.Item
                      onClick={() => setDropDown1("Activity")}
                      className="custom__dropdown__item"
                    >
                      Activity
                    </Dropdown.Item>
                  )}
                  {dropdown1 !== "Session fit" && (
                    <Dropdown.Item
                      onClick={() => setDropDown1("Session fit")}
                      className="custom__dropdown__item"
                    >
                      Session fit
                    </Dropdown.Item>
                  )}
                  {dropdown1 !== "Technical skills" && (
                    <Dropdown.Item
                      onClick={() => setDropDown1("Technical skills")}
                      className="custom__dropdown__item"
                    >
                      Technical skills
                    </Dropdown.Item>
                  )}
                  {dropdown1 !== "Communication skills" && (
                    <Dropdown.Item
                      onClick={() => setDropDown1("Communication skills")}
                      className="custom__dropdown__item"
                    >
                      Communication skills
                    </Dropdown.Item>
                  )}
                  {dropdown1 !== "Presence" && (
                    <Dropdown.Item
                      onClick={() => setDropDown1("Presence")}
                      className="custom__dropdown__item"
                    >
                      Presence
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <button
                className="btn-green11111"
                // onClick={() => setShowConfirmModal(true)}
              >
                <img src={docs} />
              </button>
            </div>
            {/* <button className="btn-grey">Cancel</button> */}
          </div>
        </div>
        <div className="earning__table">
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Fee for platform</th>
                <th>Net amount</th>
                <th>Total amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {earningData?.transactions?.data?.map((elem) => {
                const unixTimestamp = elem?.created;
                const date = new Date(unixTimestamp * 1000);
                const localTime = date.toLocaleString().split(",")[0];
                return (
                  <tr>
                    <td>{localTime}</td>
                    <td>
                      {elem?.fee_details[0]?.description
                        ? elem?.fee_details[0]?.description
                        : "---"}
                    </td>
                    <td>{Number(elem?.fee / 100).toFixed(2)}</td>
                    <td>{Number(elem?.net / 100).toFixed(2)}</td>
                    <td>{(Number(elem?.amount) / 100).toFixed(2)}</td>
                    <td>{elem?.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="mt-3">
          <EraningFooter />
        </div>
      </div>
    </>
  );
};

export default MyEarning;
