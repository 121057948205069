import React from "react";

import "./bookbutton.scss";
const BookButton = ({ setShow,isDisabled }) => {
  return (
    <button className={`book__button ${isDisabled ? 'book__button__disabled':'book__button__enabled'}`} disabled={isDisabled}
    onClick={()=>setShow(true)}>
      Book Session
    </button>
  );
};

export default BookButton;
