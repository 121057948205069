import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  signin,
  signinGoogleAuth,
  signinLinkedAuth,
} from "../service/redux/middleware/signin";
import linkdin from "../../src/assets/linkdinIcon.svg";
import Form from "react-bootstrap/Form";
import googleIcon from "../../src/assets/googleIcon.svg";
import "./login.scss";
import eyeIcon from "../../src/assets/eyeIcon.svg";
import eyeSlashIcon from "../../src/assets/eye-slash.svg";
import MyLoader from "../components/Loader/MyLoader";
import { ErrorToast, SuccessToast } from "../components/MyToaster";
import { useGoogleLogin } from "@react-oauth/google";
// import jwt_decode from "jwt-decode";
import ArrowLeft from '../../src/assets/arrow-right-green.svg'

import axios from "axios";
import LinkedInButton from "./LinkedInButton";
import { useSelector } from "react-redux";

import { ConfirmModal } from "../components/SessionModal/SessionModal";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state?.signin?.signInData?.data);

  const formValidation = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(loginEmail);
    if (!loginEmail) {
      ErrorToast("Please enter email.");
      return false;
    } else if (!isValidEmail) {
      ErrorToast("Please enter valid email.");
      return false;
    } else if (!loginPassword) {
      ErrorToast("Please enter password.");
      return false;
    }
  };

  const [supportModal, setSupportModal] = useState(false);
  const [message, setMessage] = useState("");

  const handleLogin = (e) => {
    e?.preventDefault();
    const res = formValidation();
    if (res === false) {
      return false;
    }

    const data = {
      email: loginEmail,
      password: loginPassword,
    };
    setLoader(true);
    dispatch(signin(data)).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        if (res?.payload?.data?.role === 0) {
          if (res?.payload?.data?.profileUpdated) {
            // SuccessToast(res?.payload?.data?.message)
            SuccessToast("Log in successfully");
            localStorage.setItem("redirectUrl", "interviewdashboard");
            history.push("/interviewdashboard");
            // const timer = setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            // return () => clearTimeout(timer);
          } else {
            SuccessToast("Profile Update Your Profile to Continue.");
            history.push("/personalInfo");
            // SuccessToast(res?.payload?.data?.message)
            // const timer = setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            // return () => clearTimeout(timer);
          }
        } else {
          if (res?.payload?.data?.profileUpdated) {
            localStorage.setItem("redirectUrl", "mentordashboard");
            history.push("/mentordashboard");
            // window.location.reload();
          }
        }
      } else if (res?.payload?.status === 404) {
        ErrorToast(res?.payload?.message);
        setSupportModal(true);
        setMessage(res?.payload?.message);
        setLoader(false);
      } else {
        ErrorToast(res?.payload?.message);
        setLoader(false);
      }
    });
  };

  const forgetPassword = (e) => {
    e?.preventDefault();
    history.push("/email");
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      const data = {
        email: datas?.data?.email,
        familyName: datas?.data?.family_name,
        givenName: datas?.data?.given_name,
      };
      // var decoded = jwt_decode(tokenResponse?.access_token?.credential);
      dispatch(signinGoogleAuth(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          if (res?.payload?.data?.role === 0) {
            if (res?.payload?.data?.profileUpdated) {
              SuccessToast("Log in successfully");
              localStorage.setItem("redirectUrl", "interviewdashboard");
              history.push("/interviewdashboard");
              // const timer = setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
              // return () => clearTimeout(timer);
            } else {
              SuccessToast("Profile Update Your Profile to Continue.");
              history.push("/personalInfo");
              // const timer = setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
              // return () => clearTimeout(timer);
            }
          } else {
            if (res?.payload?.data?.profileUpdated) {
              localStorage.setItem("redirectUrl", "mentordashboard");
              history.push("/mentordashboard");
              // window.location.reload();
            }
          }
        } else {
          ErrorToast(res?.payload?.message);
          setLoader(false);
        }
      });
    },
  });

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      userData?.role === 0 &&
      userData?.profileUpdated === true &&
      currentPath === "/login"
    ) {
      // if (userData.profileUpdated) {
      history?.push("/interviewdashboard");
      // }

      // else {
      //   history?.push("/personalInfo");
      // }
    }
  }, [userData]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (userData?.role === 1 && currentPath === "/login") {
      history?.push("/mentordashboard");
    }
  }, []);


  const routeToHome=()=>{
    window.location.href = "https://www.fast-track.community"

  }

  return (
    <>
     <div className="auth__back__main auth__login__arrowdiv" onClick={routeToHome}>
        <img src={ArrowLeft} className="auth__back__main__image"/>
        <p className="auth__back__main__text">Back to Home</p>
      </div>
      <div className="loginSection">
        {/* <Form> */}
        <Form.Group className=" loginfield " controlId="">
          <Form.Label className="loginfield__Label ">Email</Form.Label>
          <Form.Control
            type=" email"
            className="loginfield__type"
            placeholder="johndoe@fasttrack.com"
            onChange={(e) => setLoginEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className=" loginfield__optional__margin " controlId="">
          <Form.Label className="loginfield__Label ">Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            className="loginfield__type"
            placeholder="Enter password "
            onChange={(e) => setLoginPassword(e.target.value)}
          />
          <img
            className="loginfield__img"
            src={showPassword ? eyeSlashIcon : eyeIcon}
            onClick={() => setShowPassword(!showPassword)}
            alt=""
          />
        </Form.Group>
        <div
          className="wrapper__of__login__green__button"
          onClick={handleLogin}
        >
          <button style={{ color: "black" }} className="">
            Log in
          </button>
          {loader && <MyLoader />}
        </div>
        {/* <button className="login__green__button" onClick={handleMentorLogin}>
        Mentor Signup
      </button> */}
        <button
          className="login__green__button__forget"
          onClick={forgetPassword}
        >
          Forgot password
        </button>
        <div className="emptyline ">
          <div className="emptyline__first "></div>
          <p className="">OR</p>
          <div className="emptyline__first"></div>
        </div>
        <LinkedInButton signUpflag={false} loginFlag={true}>
          Log in with LinkedIn
        </LinkedInButton>
        <button onClick={() => login()} className="signup__session__button11">
          <img src={googleIcon} alt="" className=""></img>
          <p className="signup__session__button__text"> Log in with Google</p>
        </button>
      </div>
      {/* session confirm modal */}

      <ConfirmModal show={supportModal} setShow={setSupportModal}>
        <div className="confirmModal__main ">
          <div className="heading__heading">Support </div>
          <div className="para__para__para ">{message}</div>
          <hr />
          <div className="confirm__btn__main">
            <button
              className="confirm__btn"
              onClick={() => history.push("/customersupports")}
            >
              Go to Support
            </button>
            <button
              className="cancel__btn"
              onClick={() => setSupportModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

export default Login;
