// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main__tab__modal {
  background: #2f3033;
  padding: 10px 0px;
}
.main__tab__modal .inner__tab {
  display: block;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.main__tab__modal .inner__tab .center__div {
  text-align: -webkit-center;
}
.main__tab__modal .inner__tab .main__of__content {
  border-radius: 100px;
  background: var(--gray-gray-900, #202224);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
}
.main__tab__modal .inner__tab .main__of__content img {
  width: 24px;
  height: 24px;
}
.main__tab__modal .inner__tab .my__text {
  color: var(--gray-white, #FFF);
  font-family: AktivGrotesk-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.main__tab__modal .inner__tab .right__img {
  width: 24px;
  height: 24px;
  margin-left: 47%;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderForLandingPage/headerlanding.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,iBAAA;AACJ;AAEI;EACI,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAAR;AAEQ;EACI,0BAAA;AAAZ;AAGQ;EACI,oBAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AADZ;AAGY;EACI,WAAA;EACA,YAAA;AADhB;AAOQ;EACI,8BAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;EACA,8BAAA;AALZ;AAQQ;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AANZ","sourcesContent":[".main__tab__modal {\n    background: #2f3033;\n    padding: 10px 0px;\n    // overflow-x: auto;\n\n    .inner__tab {\n        display: block;\n        align-items: center;\n        justify-content: center;\n        gap: 40px;\n\n        .center__div {\n            text-align: -webkit-center;\n        }\n\n        .main__of__content {\n            border-radius: 100px;\n            background: var(--gray-gray-900, #202224);\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            gap: 10px;\n            width: 30px;\n            height: 30px;\n\n            img {\n                width: 24px;\n                height: 24px;\n            }\n\n\n        }\n\n        .my__text {\n            color: var(--gray-white, #FFF);\n            font-family: AktivGrotesk-Regular;\n            font-size: 13px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: 120%;\n            margin-top: 16px !important;\n            margin-bottom: 16px !important;\n        }\n\n        .right__img {\n            width: 24px;\n            height: 24px;\n            margin-left: calc(100% - 53%);\n            margin-bottom: 16px;\n        }\n\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
