import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, client } from "../../client";
import axios from "axios";

export const addRelability = createAsyncThunk("addRelability", async (data) => {
  try {
    const res = await client.post(
      `/api/addRelability/${data.id}/${data.meetingId}`,
      {},
      { headers: { Authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const addRelabilityMentor = createAsyncThunk("addRelabilityMentor", async (data) => {
  try {
    const res = await client.post(
      `/api/addRelabilityMentor/${data.id}/${data.meetingId}`,
      {},
      { headers: { Authorization: `${data?.token}` } }
    );
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});

export const getRelability = createAsyncThunk("getRelability", async (data) => {
  try {
    const res = await client.get(`/api/getRelability/${data?.userId}`, {
      headers: { authorization: `${data?.token}` },
    });
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      message: error.response.data.error,
      status: error.response.status,
    };
  }
});
