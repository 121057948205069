import React from "react";
import ScreenLoader from "./ScreenLoader";

const PreLoader = () => {
  return (
    <div className="preloader__container">
      <ScreenLoader />
    </div>
  );
};

export default PreLoader;
