// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.networkdb__mysessions__container {
  display: flex;
  flex-direction: column;
  max-width: 90% !important;
  margin: 0 auto !important;
}
.networkdb__mysessions__container__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #d0d3d9;
  padding: 16px 24px;
  border-bottom: 1px solid #2f3033;
}
.networkdb__mysessions__container__top p {
  margin-bottom: 0;
}
.networkdb__mysessions__container__top__title {
  color: var(--gray-gray-100, #d0d3d9);
  font-family: AktivGrotesk-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.networkdb__mysessions__container__top__title__sub {
  color: var(--gray-white, #fff);
  font-family: AktivGrotesk-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}
.networkdb__mysessions__container__top__right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.networkdb__mysessions__container__bottom {
  padding: 24px 24px 36px 24px;
}

.filter__networkdashboard__button {
  height: 40px !important;
}

@media (max-width: 768px) {
  .networkdb__mysessions__container {
    max-width: 100% !important;
  }
  .networkdb__mysessions__container__top {
    padding: 12px 20px;
  }
  .networkdb__mysessions__container__bottom {
    padding: 20px 8px 36px 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NetworkDashboard/MySession/Mysession.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,yBAAA;AAFF;AAME;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,kBAAA;EACA,gCAAA;AAJJ;AAKI;EACE,gBAAA;AAHN;AAKI;EACE,oCAAA;EACA,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHN;AAIM;EACE,8BAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAFR;AAKI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAHN;AAOE;EACE,4BAAA;AALJ;;AASA;EACE,uBAAA;AANF;;AASA;EACE;IACE,0BAAA;EANF;EAOE;IACE,kBAAA;EALJ;EAOE;IACE,0BAAA;EALJ;AACF","sourcesContent":["//\n// sessions\n//\n.networkdb__mysessions__container {\n  display: flex;\n  flex-direction: column;\n  max-width: 90% !important;\n  margin: 0 auto !important;\n\n  // align-items: center;\n  // justify-content: space-between;\n  &__top {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: #d0d3d9;\n    padding: 16px 24px;\n    border-bottom: 1px solid #2f3033;\n    p {\n      margin-bottom: 0;\n    }\n    &__title {\n      color: var(--gray-gray-100, #d0d3d9);\n      font-family: AktivGrotesk-Regular;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 120%;\n      &__sub {\n        color: var(--gray-white, #fff);\n        font-family: AktivGrotesk-Medium;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 120%; /* 16.8px */\n      }\n    }\n    &__right {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n    }\n  }\n  // bottom\n  &__bottom {\n    padding: 24px 24px 36px 24px;\n  }\n}\n\n.filter__networkdashboard__button {\n  height: 40px !important;\n}\n\n@media (max-width: 768px) {\n  .networkdb__mysessions__container {\n    max-width: 100% !important;\n    &__top {\n      padding: 12px 20px;\n    }\n    &__bottom {\n      padding: 20px 8px 36px 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
