import React, { useMemo, useState } from "react";
import "./PersonalInformation.scss";
import NavLogo from "../../assets/8.svg";
import Form from "react-bootstrap/Form";
import minus from "../../assets/minus.svg";
import downArrowGrey from "../../assets/downArrowGrey.svg";
import redstareck from "../../assets/redstareck.svg";
import plusWhite from "../../assets/plusWhite.svg";
import squareAddWhite from "../../assets/squareAddWhite.svg";
import plusImage from "../../assets/plusGreen.svg";
import cvIcon from "../../assets/cvIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Countries from "../../countries.json";
import { ErrorToast, SuccessToast } from "../MyToaster";
import Dropdown from "react-bootstrap/Dropdown";
import { createprofile } from "../../service/redux/middleware/userProfile";
import DateTimePicker from "react-datetime-picker";
import { API_URL, client } from "../../service/client";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ScreenLoader from "../Loader/ScreenLoader";
import countryList from "react-select-country-list";
import { Link } from "react-router-dom";
import RemoveIcon from "../../assets/remove-icon.svg";
import CalendarEaxct from "../../assets/CalendarEaxct.svg";
//
//
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const PersonalInformation = () => {
  // locations
  const options = useMemo(() => countryList().getData(), []);
  // const [startDate, setStartDate] = useState(new Date());
  const [isVisiblePersonal, setIsVisiblePersonal] = useState(true);
  const [isVisibleEducation, setIsVisibleEducation] = useState(false);
  const [isVisibleExperience, setIsVisibleExperience] = useState(false);
  const [loader, setLoader] = useState(false);
  //personal info
  const [image, setImage] = useState("");
  const [dropdown, setDropDown] = useState("");
  const [description, setDescription] = useState("");
  // const [language, setLanguage] = useState('')

  // education states
  const [isChecked, setIsChecked] = useState([false]);
  const [dropdownProgramme, setDropdownProgramme] = useState([""]);
  const [multiplemages, setmultiplemages] = useState([
    {
      institution: "",
      programme: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    },
  ]);
  //
  // professional experience
  const [uploadPdf, setUploadPdf] = useState("");
  const [multiplemages1, setmultiplemages1] = useState([
    {
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);
  const [experienceCheck, setExperienceCheck] = useState([false]);
  //
  const [isPersonalDone, setIsPersonalDone] = useState(false);
  const [isEducationDone, setIsEducationDone] = useState(false);
  //
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleVisibilityPersonal = (isComing = false) => {
    if (isPersonalDone || isComing) {
      setIsVisiblePersonal(!isVisiblePersonal);
      setIsVisibleEducation(false);
      setIsVisibleExperience(false);
    } else {
      ErrorToast("Please fill all Personal details to continue.");
    }
  };
  const toggleVisibilityEducation = (isComing = false) => {
    if (isEducationDone || isComing) {
      setIsVisibleEducation(!isVisibleEducation);
      setIsVisiblePersonal(false);
      setIsVisibleExperience(false);
    } else {
      ErrorToast("Please fill all Education details to continue.");
    }
  };
  const toggleVisibilityExperience = () => {
    setIsVisibleExperience(!isVisibleExperience);
    setIsVisibleEducation(false);
    setIsVisiblePersonal(false);
  };
  //
  // perosnal
  //
  const handleDropDownLocation = (e) => {
    setDropDown(e.target.value);
  };
  //
  //
  const handleSelectCountryNew = (selected) => {
    setDropDown(selected);
  };
  //
  const handleDropDownProgrammeNew = (selected, index) => {
    const updateDD = [...dropdownProgramme];
    updateDD[index] = selected;
    setDropdownProgramme(updateDD);
    const updatedMultiplemages = [...multiplemages];
    updatedMultiplemages[index]["programme"] = selected;
    setmultiplemages(updatedMultiplemages);
  };
  //
  const handleDropDownProgramme = (e, index) => {
    const updateDD = [...dropdownProgramme];
    updateDD[index] = e.target.value;
    setDropdownProgramme(updateDD);
    const updatedMultiplemages = [...multiplemages];
    updatedMultiplemages[index][e.target?.name] = e.target?.value;
    setmultiplemages(updatedMultiplemages);
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    // if (file.size >= 2872139) {
    //   ErrorToast("File size should not exceed maximum limit of 3 MB");
    // } else
    //  {
    var data = new FormData();
    data.append("files", file);
    if (file) {
      const responses = await axios.post(`${API_URL}/api/upload`, data);
      if (responses.status === 200) {
        SuccessToast("Image uploaded successfully");
      }
      setImage(responses?.data?.imageUrl);
    }
    // }
  };

  const personalHandler = (e) => {
    e.preventDefault();
    if (dropdown && description) {
      setIsPersonalDone(true);
      toggleVisibilityEducation(true);
    } else {
      ErrorToast("Please fill all the details.");
    }
  };
  // personal info done

  const handleChange1 = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      // const formattedDate = formatDate(e);
      const updatedMultiplemages = [...multiplemages];
      updatedMultiplemages[index][isDate] = e;
      setmultiplemages(updatedMultiplemages);
    } else {
      const updatedMultiplemages = [...multiplemages];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      setmultiplemages(updatedMultiplemages);
      // e.target.value = null;
    }
  };
  const handleCheckboxChange = (index) => {
    const updatedMultiplemages = [...multiplemages];
    updatedMultiplemages[index].currentlyStuding =
      !updatedMultiplemages[index].currentlyStuding;
    // updatedMultiplemages[index].endDate = "";
    setmultiplemages(updatedMultiplemages);
    const updatedIsCheck = [...isChecked];
    updatedIsCheck[index] = !updatedIsCheck[index];
    setIsChecked(updatedIsCheck);
  };
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...multiplemages];
    list.splice(index, 1);
    setmultiplemages(list);

    const checkboxes = isChecked;
    checkboxes.splice(index, 1);
    setIsChecked(checkboxes);

    const programmeDD = [...dropdownProgramme];
    programmeDD.splice(index, 1);
    setDropdownProgramme(programmeDD);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setmultiplemages([
      ...multiplemages,
      {
        institution: "",
        degree: "",
        startDate: new Date(),
        endDate: new Date(),
        currentlyStuding: false,
      },
    ]);
    setIsChecked([...isChecked, false]);
  };
  const handleEducationSubmit = (e) => {
    e.preventDefault();
    let allGood = true;
    multiplemages.map((item) => {
      if (item.degree && item.institution && item.startDate && item.programme) {
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsEducationDone(true);
      toggleVisibilityExperience(true);
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
    }
    // toggleVisibilityExperience();
  };
  //
  //

  //
  //
  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size >= 2872139) {
      ErrorToast("File size should not exceed maximum limit of 3 MB");
    } else {
      var data = new FormData();
      data.append("files", file);
      if (file) {
        const responses = await client.post(`${API_URL}/api/upload`, data);
        if (responses?.status === 200) {
          SuccessToast("Document uploaded successfully.");
        }
        setUploadPdf(responses.data.imageUrl);
      }
    }
  };
  const handleChange11 = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      // const formattedDate = formatDate(e);
      const updatedMultiplemages = [...multiplemages1];
      updatedMultiplemages[index][isDate] = e;
      setmultiplemages1(updatedMultiplemages);
    } else {
      const updatedMultiplemages = [...multiplemages1];
      updatedMultiplemages[index][e.target?.name] = e.target?.value;
      setmultiplemages1(updatedMultiplemages);
      // e.target.value = null;
    }
  };

  const handleRemoveClick1 = (e, index) => {
    e.preventDefault();
    const list = [...multiplemages1];
    list.splice(index, 1);
    setmultiplemages1(list);

    const checkboxes = experienceCheck;
    checkboxes.splice(index, 1);
    setExperienceCheck(checkboxes);
  };

  const handleAddClick1 = (e) => {
    e.preventDefault();
    setmultiplemages1([
      ...multiplemages1,
      {
        company: "",
        position: "",
        startDate: new Date(),
        endDate: new Date(),
        currentlyEmployed: false,
      },
    ]);
    setExperienceCheck([...experienceCheck, false]);
  };

  const handleExperienceChackbox = (e, index) => {
    const updatedMultiplemages = [...multiplemages1];
    updatedMultiplemages[index].currentlyEmployed =
      !updatedMultiplemages[index].currentlyEmployed;
    // updatedMultiplemages[index].endDate = "";
    setmultiplemages1(updatedMultiplemages);
    const updatedIsCheck = [...experienceCheck];
    updatedIsCheck[index] = !updatedIsCheck[index];
    setExperienceCheck(updatedIsCheck);
  };

  const Finish = (e) => {
    e.preventDefault();
    if (dropdown && description) {
      let allGood = true;
      multiplemages.map((item) => {
        if (item.degree && item.institution && item.startDate) {
        } else {
          allGood = false;
          return;
        }
      });
      if (allGood) {
        // let allGood2 = true;
        // multiplemages1.map((item) => {
        //   if (item.company && item.position && item.startDate) {
        //   } else {
        //     allGood2 = false;
        //     return;
        //   }
        // });
        // if (allGood2 && uploadPdf) {

        let objectToSend = {
          userId: userData._id,
          // language: language,
          location: dropdown,
          background: description,
          educationDetails: multiplemages,
          token: token,
        };
        if (
          multiplemages1[0]?.company &&
          multiplemages1[0]?.position &&
          multiplemages1[0]?.startDate
        ) {
          objectToSend = {
            ...objectToSend,
            professionalDetails: multiplemages1,
          };
        }
        if (image !== "") {
          objectToSend = {
            ...objectToSend,
            image: image,
          };
        } else if (userData?.pics !== "") {
          objectToSend = {
            ...objectToSend,
            image: userData?.pics,
          };
        }
        // if (uploadPdf !== "") {
        //   objectToSend = {
        //     ...objectToSend,
        //     cv: uploadPdf,
        //   };
        // }
        setLoader(true);
        dispatch(createprofile(objectToSend)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            SuccessToast("Profile updated successfully");
            history.push("/interviewdashboard");
            // const timer = setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            // return () => clearTimeout(timer);
          } else {
            ErrorToast(res?.payload?.data?.message);
            setLoader(false);
          }
        });
        // }
        // else {
        //   ErrorToast("Please fill all mandatory fields under Education");
        // }
      } else {
        ErrorToast("Please fill all mandatory fields under Education");
      }
    } else {
      ErrorToast("Please fill all mandatory fields under Personal");
    }
  };

  return (
    <div className="PersonalInfoSec">
      <div className="widthDiv width-perosnalinfo">
        <Link to="/" className="PersonalInfoSec__nav">
          <img className="PersonalInfoSec__nav__img " src={NavLogo} alt="" />
        </Link>
        <div className="PersonalInfoSec__Heading">
          <h1 className="">Welcome {userData?.firstName}!</h1>
          <p className="">Let us get to know a little bit more about you</p>
        </div>
        <div className="PersonalInfoSecForm" style={{ marginTop: "32px" }}>
          {/* <Form> */}
          <div
            className={`PersonalInfoSecForm__footer ${isVisiblePersonal ? "" : "border__tab"
              }`}
            onClick={toggleVisibilityPersonal}
          >
            <h1>Personal Information</h1>
            <div className="MentorInfoSecForm__footer__container">
              {isPersonalDone && <div className="done__button">Done</div>}
              <img src={isVisiblePersonal ? minus : plusWhite} alt="" />
            </div>
          </div>
          {isVisiblePersonal && (
            <div>
              <div className="personalDiv">
                <div className="uploadImage">
                  <div className="DropDownLabel">
                    <h1 className="uploadImage__label">
                      {" "}
                      Upload a profile picture (less than 0.4 mb)
                    </h1>
                    {/* <img src={redstareck} alt='' /> */}
                  </div>
                  <label htmlFor="upload">
                    {image ? (
                      <div className="uploadImage__circle">
                        <img
                          src={image}
                          alt="user profile image"
                          className="uploadImage__circle__pf"
                        />
                      </div>
                    ) : userData?.pics ? (
                      <div className="uploadImage__circle">
                        <img
                          src={userData?.pics}
                          alt="user profile image"
                          className="uploadImage__circle__pf"
                        />
                      </div>
                    ) : (
                      <div className="uploadImage__circle">
                        <img
                          src={plusImage}
                          alt="profile img static"
                          className="uploadImage__circle__plus"
                        />
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="upload"
                    onChange={(e) => handleImageUpload(e)}
                  />
                </div>
                <div className="DropDownLabel">
                  <h1>Location</h1>
                  <img
                    style={{ marginLeft: "3px", paddingBottom: "5px" }}
                    src={redstareck}
                    alt=""
                  />
                </div>
                <Dropdown onSelect={handleSelectCountryNew}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdownToSelect select__icon"
                  >
                    <p className="forumm__button__text">
                      {dropdown ? dropdown : "Please select country"}
                    </p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                    {options?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.label}
                        className={`dropdownToSelect__item ${item.label === dropdown
                          ? "dropdownToSelect__item__checked"
                          : ""
                          }`}
                      >
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                {/* Old location */}
                {/* <select
                  className="DropDownMentor__select2 select__icon"
                  value={dropdown}
                  onChange={handleDropDownLocation}
                >
                  {options?.map((item, index) => (
                    <option
                      className="DropDownMentor__select__item"
                      value={item.label}
                      key={index}
                    >
                      {item.label}
                    </option>
                  ))}
                </select> */}
                {/* old select */}
                <Form.Group
                  className=" loginfield__optional__margin "
                  controlId=""
                >
                  <Form.Label className="loginfield__Label">
                    Brief background
                    <img
                      style={{ marginLeft: "3px", paddingBottom: "5px" }}
                      src={redstareck}
                      alt=""
                    />
                  </Form.Label>
                  <textarea
                    type="text"
                    placeholder="Tell candidates about yourself and your background. Explain them why you are the right mentor for them"
                    className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                    style={{ marginTop: "16px" }}
                    value={description}
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group className=' loginfield ' controlId=''>
                  <Form.Label className='loginfield__Label '>
                    Other Languages
                  </Form.Label>
                  <Form.Control
                    type='text'
                    className='loginfield__type'
                    placeholder='Select language(s)'
                    value={language}
                    name='language'
                    onChange={(e) => setLanguage(e.target.value)}
                  />
                </Form.Group> */}
                <button
                  className="button__continue"
                  onClick={(e) => personalHandler(e)}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          <div
            className={`PersonalInfoSecForm__footer ${isVisibleEducation ? "" : "border__tab "
              } ${isVisiblePersonal ? "border__tab__top" : ""}`}
            onClick={toggleVisibilityEducation}
          >
            <h1>Education</h1>
            <div className="MentorInfoSecForm__footer__container">
              {isEducationDone && <div className="done__button">Done</div>}
              <img src={isVisibleEducation ? minus : plusWhite} alt="" />
            </div>
          </div>
          {isVisibleEducation && (
            <div className="EducationDiv">
              {multiplemages?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className={i > 0 ? "sdfdsfdsf" : " sdfdsfdsfdfdf"}
                  >
                    <div className="cancelbtn-container">
                      {multiplemages.length > 1 && i > 0 && (
                        <button
                          className="btn-cancel"
                          onClick={(e) => handleRemoveClick(e, i)}
                        >
                          <p>Remove</p>
                          <img src={RemoveIcon} />
                        </button>
                      )}
                    </div>
                    <Form.Group className=" loginfield__optional__margin ">
                      <Form.Label className="loginfield__Label" htmlFor={i}>
                        Institution
                        <img
                          style={{ marginLeft: "3px", paddingBottom: "5px" }}
                          src={redstareck}
                          alt=""
                        />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="loginfield__type margin__ii"
                        placeholder="London Business School"
                        value={multiplemages[i]?.institution}
                        name="institution"
                        onChange={(e) => handleChange1(e, i)}
                      />
                    </Form.Group>
                    <Form.Group className=" loginfield__optional__margin ">
                      <Form.Label
                        className="loginfield__Label"
                        htmlFor={"programme"}
                      >
                        Degree
                        <img
                          style={{ marginLeft: "3px", paddingBottom: "5px" }}
                          src={redstareck}
                          alt=""
                        />
                      </Form.Label>
                      <Dropdown
                        onSelect={(e) => handleDropDownProgrammeNew(e, i)}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="dropdownToSelect select__icon"
                        >
                          <p className="forumm__button__text">
                            {dropdownProgramme[i]
                              ? dropdownProgramme[i]
                              : "Please select degree"}
                          </p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                          <Dropdown.Item
                            eventKey={"Bachelors"}
                            className="dropdownToSelect__item"
                          >
                            Bachelors
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={"Masters"}
                            className="dropdownToSelect__item  "
                          >
                            Masters
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey={"MBA"}
                            className="dropdownToSelect__item"
                          >
                            MBA
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* old degree select */}
                      {/* <select
                        className="DropDownMentor__select2 select__icon"
                        name="programme"
                        id="programme"
                        value={dropdownProgramme[i]}
                        onChange={(e) => handleDropDownProgramme(e, i)}
                      >
                        <option
                          className="DropDownMentor__select__item"
                          value={"All"}
                        >
                          Please select a Degree
                        </option>
                        <option
                          className="DropdownMain__menu__item"
                          value="MBA"
                        >
                          MBA
                        </option>
                        <option
                          className="DropDownMentor__select__item"
                          value="Bachelors"
                        >
                          Bachelors
                        </option>
                        <option
                          className="DropDownMentor__select__item"
                          value="Masters"
                        >
                          Masters
                        </option>
                      </select> */}
                      {/* old degree select */}
                    </Form.Group>

                    <Form.Group className=" loginfield__optional__margin ">
                      <Form.Label className="loginfield__Label" htmlFor={i}>
                        Field of study
                        <img
                          style={{ marginLeft: "3px", paddingBottom: "5px" }}
                          src={redstareck}
                          alt=""
                        />
                      </Form.Label>
                      <Form.Control
                        type=" password"
                        className="loginfield__type margin__ii"
                        placeholder="Finance"
                        value={multiplemages[i]?.degree}
                        name="degree"
                        onChange={(e) => handleChange1(e, i)}
                      />
                    </Form.Group>
                    <div className=" datepickerRow ">
                      <div className="datepickerRow__inner">
                        <p className="datepickerRow__inner__text">
                          Start date
                          <img
                            style={{ marginLeft: "3px", paddingBottom: "5px" }}
                            src={redstareck}
                            alt=""
                          />
                        </p>
                        <DateTimePicker
                          // format="MMM dd, yyyy"
                          format="MMM dd, yyyy"
                          disableClock={true}
                          className="schedules__guides__contaiener__calendar personal__contaiener__calendar"
                          name="startDate"
                          value={multiplemages[i]?.startDate}
                          placeholder="Select expiration date..."
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                          onChange={(e) => handleChange1(e, i, "startDate")}
                        //  minDate={moment()?.toDate()}
                        />
                      </div>
                      <div className="datepickerRow__inner">
                        <p className="datepickerRow__inner__text">End date</p>
                        <DateTimePicker
                          format="MMM dd, yyyy"
                          disableClock={true}
                          className="personal__contaiener__calendar schedules__guides__contaiener__calendar"
                          // selected={multiplemages[i]?.endDate}
                          name="endDate"
                          value={multiplemages[i]?.endDate}
                          placeholder="Select expiration date..."
                          onChange={(e) => handleChange1(e, i, "endDate")}
                          disabled={multiplemages[i]?.currentlyStuding}
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                        //  minDate={moment()?.toDate()}
                        />
                      </div>
                    </div>
                    <Form.Label
                      className="personalCheckbox"
                      id="infoPersonalEducation"
                    >
                      <Form.Check
                        id="infoPersonalEducation"
                        name="infoPersonalEducation"
                        aria-label="option 1"
                        checked={isChecked[i]}
                        onChange={() => handleCheckboxChange(i)}
                      />
                      <p>I am still attending this instituition</p>
                    </Form.Label>
                  </div>
                );
              })}
              {multiplemages?.map((x, i) => {
                return (
                  <div key={i}>
                    {multiplemages?.length >= 100 ? (
                      ""
                    ) : (
                      <div className="btn-box">
                        {multiplemages.length - 1 === i && (
                          <button
                            className="personalADD"
                            onClick={(e) => handleAddClick(e)}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                              {i > 0
                                ? "Add another education"
                                : "Add another education"}
                            </p>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <button
                className="button__continue btn__margin"
                onClick={(e) => handleEducationSubmit(e)}
              >
                Continue
              </button>
            </div>
          )}
          <div
            className={`PersonalInfoSecForm__footer ${isVisibleExperience ? "" : "border__tab "
              } ${isVisibleEducation ? "border__tab__top" : ""}`}
            onClick={toggleVisibilityExperience}
          >
            <h1>Professional Experience</h1>
            <img src={isVisibleExperience ? minus : plusWhite} alt=""></img>
          </div>

          {isVisibleExperience && (
            <div className="ProfessionalExpDiv">
              {/* <label htmlFor="cvupload" style={{ width: "100%" }}>
                <div
                  className={`${uploadPdf.length > 0
                    ? "resume__button__success"
                    : "resume__button"
                    }`}
                >
                  {uploadPdf.length > 0 ? (
                    "CV Uploaded Successfully"
                  ) : (
                    <>
                      <img src={cvIcon} alt=""></img>Attach Resume or CV
                    </>
                  )}
                </div>
                <input
                  type="file"
                  accept=".doc,.docx,.txt,.pdf"
                  className="d-none"
                  id="cvupload"
                  onChange={(e) => handleCVUpload(e)}
                />
              </label> */}
              {multiplemages1?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className={i > 0 ? "sdfdsfdsf" : " sdfdsfdsfdfdf"}
                  >
                    <div className="cancelbtn-container">
                      {multiplemages1.length > 1 && i > 0 && (
                        <button
                          className="btn-cancel"
                          onClick={(e) => handleRemoveClick1(e, i)}
                        >
                          <p>Remove</p>
                          <img src={RemoveIcon} />
                        </button>
                      )}
                    </div>
                    <Form.Group className=" loginfield__optional__margin ">
                      <Form.Label className="loginfield__Label" htmlFor="">
                        Company
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="loginfield__type margin__ii"
                        placeholder="Morgan Stanley "
                        value={multiplemages1[i]?.company}
                        name="company"
                        onChange={(e) => handleChange11(e, i)}
                      />
                    </Form.Group>
                    <Form.Group className=" loginfield__optional__margin ">
                      <Form.Label className="loginfield__Label" htmlFor={i}>
                        Position
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="loginfield__type margin__ii"
                        placeholder="Investment Banking Analyst"
                        value={multiplemages1[i]?.position}
                        name="position"
                        onChange={(e) => handleChange11(e, i)}
                      />
                    </Form.Group>
                    <div className=" datepickerRow ">
                      <div className="datepickerRow__inner">
                        <p className="datepickerRow__inner__text">Start date</p>
                        <DateTimePicker
                          format="MMM dd, yyyy"
                          disableClock={true}
                          className="schedules__guides__contaiener__calendar"
                          name="startDate"
                          // selected={multiplemages1[i]?.startDate}
                          value={multiplemages1[i]?.startDate}
                          placeholder="Select expiration date..."
                          onChange={(e) => handleChange11(e, i, "startDate")}
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                        //  minDate={moment()?.toDate()}
                        />
                      </div>
                      <div className="datepickerRow__inner">
                        <p className="datepickerRow__inner__text">End date</p>
                        <DateTimePicker
                          format="MMM dd, yyyy"
                          disableClock={true}
                          className="schedules__guides__contaiener__calendar"
                          // selected={multiplemages1[i]?.endDate}
                          value={multiplemages1[i]?.endDate}
                          name="endDate"
                          placeholder="Select expiration date..."
                          onChange={(e) => handleChange11(e, i, "endDate")}
                          disabled={multiplemages1[i]?.currentlyEmployed}
                          calendarIcon={
                            <img
                              src={CalendarEaxct}
                              alt="Calendar Icon"
                              style={{ width: "20px", height: "20px" }} // Set the size as needed
                            />
                          }
                        //  minDate={moment()?.toDate()}
                        />
                      </div>
                    </div>
                    <Form.Label
                      className="personalCheckbox "
                      id="experienceCheckLabel"
                    >
                      <Form.Check
                        aria-label="option 1"
                        id="experienceCheckLabel"
                        name="experienceCheckLabel"
                        checked={experienceCheck[i]}
                        onChange={(e) => handleExperienceChackbox(e, i)}
                      />
                      <p>I am still working at this company</p>
                    </Form.Label>
                  </div>
                );
              })}
              {multiplemages?.map((x, i) => {
                return (
                  <div>
                    {multiplemages1?.length >= 100 ? (
                      ""
                    ) : (
                      <div className="btn-box">
                        {multiplemages1.length - 1 === i && (
                          <button
                            className="personalADD"
                            onClick={(e) => handleAddClick1(e)}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="mb-0 dsfdshbfdshsdgsdkzcjxvk">
                              {i > 0
                                ? "Add another experience"
                                : "Add another experience"}
                            </p>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <button
                className="button__continue btn__margin"
                onClick={(e) => Finish(e)}
              >
                Finish
              </button>
            </div>
          )}
          {/* </div> */}
          {/* </Form> */}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
