// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardbar__container {
  padding: 16px 0;
  border-bottom: 1px solid #2f3033;
}
.dashboardbar__container__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  gap: 32px;
}
.dashboardbar__container__nav__item__link {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #b9bdc7;
  cursor: pointer;
}
.dashboardbar__container__nav__item__link__text {
  margin-bottom: 0;
  font-family: AktivGrotesk-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  background-color: transparent;
}
.dashboardbar__container__nav__item__link:hover {
  background-color: rgba(43, 153, 114, 0.0784313725);
}
.dashboardbar__container__nav__item .active {
  background-color: rgba(43, 153, 114, 0.0784313725);
  color: #3dd7a1;
}`, "",{"version":3,"sources":["webpack://./src/components/NetworkDashboard/dashboardbar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gCAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,SAAA;AACJ;AACM;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,cAAA;EACA,eAAA;AACR;AAAQ;EACE,gBAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,6BAAA;AAEV;AAAQ;EACE,kDAAA;AAEV;AAEM;EACE,kDAAA;EACA,cAAA;AAAR","sourcesContent":[".dashboardbar__container {\n  padding: 16px 0;\n  border-bottom: 1px solid #2f3033;\n\n  &__nav {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 !important;\n    gap: 32px;\n    &__item {\n      &__link {\n        padding: 10px;\n        border-radius: 10px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        gap: 8px;\n        color: #b9bdc7;\n        cursor: pointer;\n        &__text {\n          margin-bottom: 0;\n          font-family: AktivGrotesk-Medium;\n          font-size: 16px;\n          font-style: normal;\n          font-weight: 400;\n          line-height: 28px;\n          background-color: transparent;\n        }\n        &:hover {\n          background-color: #2b997214;\n        }\n      }\n\n      .active {\n        background-color: #2b997214;\n        color: #3dd7a1;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
