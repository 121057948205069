import React from "react";
import SortIcon from "../../../../assets/sort.svg";

// 
import './FilterButton.scss'
const FilterButton = () => {
  return (
    <button className="filter__button">
      <img src={SortIcon} className="filter__button__image" />
      <span className="filter__button__text">Filter</span>
    </button>
  );
};

export default FilterButton;
