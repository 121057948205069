import React, { useEffect, useState, useRef } from "react";
import "./Networkdashboard.scss";
import "./dashboardbar.scss";
import { useSelector } from "react-redux";
import pay from "../../assets/play-circle.svg";
import NavbarLanding from "../Navbar/NavbarLanding";

import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import InfoCircle from "../../assets/info-circle.svg";

import SortIcon from "../../assets/sort.svg";
import RatedStar from "../../assets/star-rated.svg";
import RisingStar from "../../assets/magic-star.svg";
import AvatarIcon from "../../assets/avatar.svg";
import BiDot from "../../assets/bi_dot.svg";
import DropDownArrow from "../../assets/arrow-down.svg";
import DiamondGrey from "../../assets/diamond-grey.svg";
import ArrowLeftMini from "../../assets/arrow-left-mini.svg";
import ArrowRightMini from "../../assets/arrow-right-mini.svg";
import { viewNetworkSession } from "../../service/redux/middleware/network";
import { Link } from "react-router-dom";
import { mentorSession } from "../../service/redux/middleware/session";
import DatePicker from "react-datepicker";
import { ErrorToast } from "../MyToaster";
import { useDispatch } from "react-redux";
import TimeRangeSlider from "react-time-range-slider";
import Footer from "../Footer/Footer";
import Ratings from "../Custom/Ratings/Ratings";
import { MySessionCard } from "../Custom";

import { useHistory } from "react-router-dom";
import noFound from "../../assets/searchempty.svg";
import NewsletterFooter from "../NewsletterFooter/NewsletterFooter";
import HeaderLandingPage from "../HeaderForLandingPage/HeaderLandingPage";
import { HeaderLandingModal } from "../Custom/Modal/ModalCustom";
import HeaderLandingPageForModal from "../HeaderForLandingPage/HeaderLandingPageForModal";
import MentorSecondNavbar from "../MentorDashboard/MentorSecondNavbar";

const InvestmentBanks = [
  "All",
  "BNP Paribas",
  "Barclays",
  "Bank of America Merrill Lynch",
  "Citigroup",
  "Credit Suisse",
  "Centerview Partners",
  "Deutsche Bank",
  "Evercore",
  "Goldman Sachs",
  "HSBC",
  "Jefferies",
  "Lazard",
  "Morgan Stanley",
  "Nomura",
  "RBC Capital Markets",
  "Rothschild",
  "UBS Group",
  "Wells Fargo Securities",
];

const NetworkDashboardHome = () => {
  const history = useHistory();
  const [showSession, setShowSession] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState("select price");
  const [mySession, setMySessions] = useState([]);
  const [temp, setTemp] = useState([]);
  const userData = useSelector((state) => state?.signin?.signInData?.data);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const [isMentor, setIsMentor] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [education, setEducation] = useState("");
  const [recognition, setRecognition] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const educationSelected = (val) => {
    if (education === val) {
      setEducation("");
    } else {
      setEducation(val);
    }
  };
  const recognitionSelected = (val) => {
    if (recognition === val) {
      setRecognition("");
    } else {
      setRecognition(val);
    }
  };
  const [banks, setBanks] = useState("");
  const handleDropdownBankChange = (event) => {
    setBanks(event.target.value);
  };
  const handleDropdownBankChangeNew = (selected) => {
    setBanks(selected);
  };

  const [loader, setLoader] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const dispatch = useDispatch();
  const data = {
    token: token,
  };
  useEffect(() => {
    // setLoader(true);
    dispatch(viewNetworkSession(data)).then((res) => {
      if (res?.payload?.status === 200) {
        addSelectedKey(res?.payload?.data);
        // setOnSuccess(true);
      } else {
        // setLoader(false);
      }
    });
  }, []);

  const addSelectedKey = (data) => {
    const toUpdate = data.map((item) => {
      let selectSession = [];
      let mentorPricing = item?.mentorPricing[0];
      if (
        mentorPricing?.["CV Review"] != 0 &&
        mentorPricing?.["CV Review"] !== null &&
        mentorPricing?.["CV Review"] !== undefined
      ) {
        selectSession.push("CV Review");
      }
      if (
        mentorPricing?.["Cover Letter Review"] != 0 &&
        mentorPricing?.["Cover Letter Review"] !== null &&
        mentorPricing?.["Cover Letter Review"] !== undefined
      ) {
        selectSession.push("Cover Letter Review");
      }
      if (
        mentorPricing?.["General Guidance"] != 0 &&
        mentorPricing?.["General Guidance"] !== null &&
        mentorPricing?.["General Guidance"] !== undefined
      ) {
        selectSession.push("General Guidance");
      }
      if (
        mentorPricing?.["Interview Practice"] != 0 &&
        mentorPricing?.["Interview Practice"] !== null &&
        mentorPricing?.["Interview Practice"] !== undefined
      ) {
        selectSession.push("Interview Practice");
      }
      let newItem = {
        ...item,
        // selectedSession: "",
        selectedSession: selectSession?.length > 0 ? selectSession[0] : "",
        selectSession: selectSession,
      };
      return newItem;
    });
    setSessionData(toUpdate);
    setTemp(toUpdate);
    setLoader(false);
    ApplyFilter();
  };

  const handleSelectSession = (eventKey, elem) => {
    const toUpdate = temp;
    let newData = toUpdate.map((item) => {
      return item?._id === elem?._id
        ? { ...item, selectedSession: eventKey }
        : item;
    });
    setTemp(newData);
  };

  const handleElementClick = () => {
    ErrorToast("Please login first");
  };

  const filterTime = (val, isNext = false, isPrev = false) => {
    if (val === "") {
      val = new Date();
    }
    if (isNext) {
      let ndate = nextDate(val);
      setStartDate(ndate);
    } else if (isPrev) {
      let pdate = prevDate(val);
      setStartDate(pdate);
    } else {
      setStartDate(val);
    }
  };
  const nextDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
  };
  const prevDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date;
  };
  const featureRef = useRef();
  const [value, setValue] = useState({
    start: "00:00",
    end: "23:59",
  });
  const changeStartHandler = () => {
    if (value.start <= "00:00") {
      setValue((prevValue) => ({
        ...prevValue,
        start: "00:00",
      }));
    }
  };

  const changeCompleteHandler = (time) => {};
  const timeParser = (time) => {
    const parts = time?.split(":");
    if (parts.length === 2) {
      let hours = parts[0];
      let minutes = parts[1];
      if (parseInt(minutes, 10) < 10 && minutes !== "00") {
        minutes = "0" + minutes;
      }
      if (parseInt(hours, 10) < 10 && hours !== "00") {
        hours = "0" + hours;
      }
      return `${hours}:${minutes}`;
    }
  };

  const timeChangeHandler = (time) => {
    const startTime = timeParser(time.start);
    const endTime = timeParser(time.end);
    setValue({
      start: startTime,
      end: endTime,
    });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const ApplyFilter = () => {
    let filteredData = [...sessionData];
    let filterFunctions = [];

    if (education) {
      filterFunctions.push((item) => {
        let matchedData = item.selectSession?.filter(
          (citem) => citem === education
        );
        return matchedData.length > 0;
      });
    }
    if (startDate) {
      filterFunctions.push((item) => {
        let matchedData = item.mentorAvalible?.filter((citem) => {
          return citem?.date === moment(startDate).format("YYYY-MM-DD");
        });
        return matchedData.length > 0;
      });
    }
    if (recognition) {
      if (recognition === "High Rated") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return ratingAftDiv >= 4.5;
        });
      } else if (recognition === "Top Coach") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return ratingAftDiv >= 4.5 && item?.reliabilityData?.booked >= 5;
        });
      } else if (recognition === "Rising") {
        filterFunctions.push((item) => {
          const ratings = item?.mentorRattings;
          const ratingAftDiv =
            Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
          return (
            ratingAftDiv >= 4.5 &&
            item?.reliabilityData?.booked >= 2 &&
            item?.reliabilityData?.booked < 5
          );
        });
      }
    }
    if (banks && banks !== "All") {
      filterFunctions.push((item) => {
        let matchedData = item.mentorProfiles[0]?.investmentBank.filter(
          (citem, index) => citem.bank === banks
        );
        return matchedData.length > 0;
      });
    }

    if (value?.start && value?.end) {
      if (!(value?.start === "00:00" && value?.end === "23:59")) {
        filterFunctions.push((item) => {
          let matchedData = item.mentorAvalible?.filter(
            (citem) =>
              moment(citem.startDateTime).format("HH:mma") >= value?.start &&
              moment(citem?.endDateTime).format("HH:mma") <= value?.end
          );
          return matchedData.length > 0;
        });
      }
    }

    try {
      filterFunctions.forEach((filterFunction) => {
        filteredData = filteredData.filter(filterFunction);
      });
      return setTemp(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    ApplyFilter();
    return () => {
      ApplyFilter();
    };
  }, [startDate, education, value, banks, sessionData, recognition]);

  const ResetFilter = () => {
    setTemp(sessionData);
    setEducation("");
    setStartDate(new Date());
    setBanks("");
    setValue({ start: "00:00", end: "23:59" });
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (userData?.role === 0 && currentPath === "/network") {
      history?.push("/networkDashboard");
    }
    const mentorFlag = localStorage.getItem("mentorFlag");
    setIsMentor(mentorFlag);
    console.log("mentorFlag", mentorFlag);
  }, []);

  const [headerModal, seHeaderModal] = useState(false);

  const gettingTopOrRising = (elem) => {
    const reliabilityData = elem?.relability[0];

    const ratings = elem?.mentorRattings;
    const ratingAftDiv =
      Number(ratings[0]?.totalRatting) / Number(ratings[0]?.totalReviews);
    const isHighrated = ratingAftDiv >= 4.5;
    let IsHighRatedReturn = "";
    if (isHighrated) {
      IsHighRatedReturn = () => {
        return (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={RatedStar}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              High Rated
            </p>
          </div>
        );
      };
    }
    let isTop = "";
    // Rating > 4 & interviews > 5
    if (ratingAftDiv >= 4 && reliabilityData?.booked >= 5) {
      isTop = "Top Coach";
    } else if (ratingAftDiv >= 4 && reliabilityData?.booked >= 2) {
      // Rating > 4 & interviews > 2
      isTop = "Rising";
    }
    return (
      <>
        {isTop?.length > 0 && isTop === "Rising" && (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={RisingStar}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              {isTop}
            </p>
          </div>
        )}
        {isHighrated && <IsHighRatedReturn />}
        {isTop?.length > 0 && isTop === "Top Coach" && (
          <div className="pill-light-network available__container__bottom__container__card__center__top__container__pill">
            <img
              src={DiamondGrey}
              className="available__container__bottom__container__card__center__top__container__pill__image"
            />
            <p className="available__container__bottom__container__card__center__top__container__pill__text">
              {isTop}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <NavbarLanding />
      {userData?.role === 1 && <MentorSecondNavbar />}
      {/* <HeaderLandingPage home={true} /> */}
      <div
        className="guest__landingpage guestLoginMentor"
        onClick={() => seHeaderModal(true)}
      >
        <img src={pay} />
        <div className="ms-2 truncate-text" style={{ lineHeight: "20px" }}>
          Explainer or something similar, to make things super easy for the
          users
        </div>
      </div>
      <HeaderLandingModal
        headerModal={headerModal}
        seHeaderModal={seHeaderModal}
      >
        <HeaderLandingPageForModal home={true} />
      </HeaderLandingModal>
      {/* <div className="guest__landingpage">
        <img src={pay} />
        <div className="guest__landingpage__text">
          Explainer or something similar, to make things super easy for the
          users
        </div>
      </div> */}
      <div className="container__custom">
        <div className="dashboard__network ">
          <div className="container-fluid" style={{ padding: "0" }}>
            <div
              className={`row available__container min__height__100 ${
                userData?.role === 1 || !userData?._id ? "border__top__0" : ""
              } `}
            >
              <div className="col-md-3 col-4 d-none d-md-block p-0 ">
                <div className="available__container__filter">
                  <div className="available__container__filter__top">
                    <h2>Filters</h2>
                    <p onClick={ResetFilter} style={{ cursor: "pointer" }}>
                      Reset
                    </p>
                  </div>
                  <div className="custom__dropdown">
                    <div className="custom__dropdown__main">
                      <h2 className="custom__dropdown__main__title">Date</h2>
                      <div className="custom__dropdown__main__container">
                        <div className="ascdvre__grehgtr">
                          <div className="filter__label__inner">
                            <img
                              src={ArrowLeftMini}
                              onClick={() => filterTime(startDate, false, true)}
                            />
                            <label
                              htmlFor="filterDate"
                              className="filterDate filter__label__inner__dateshow"
                            >
                              <p className="">
                                {moment(startDate).format("YYYY-MM-DD") ===
                                moment(new Date()).format("YYYY-MM-DD")
                                  ? "Today"
                                  : formatDate(startDate)}
                              </p>
                            </label>
                            <img
                              src={ArrowRightMini}
                              onClick={() => filterTime(startDate, true, false)}
                            />
                          </div>
                          <DatePicker
                            className="filter__date__input d-none"
                            name="startDate"
                            id="filterDate"
                            placeholder="Select expiration date..."
                            onSelect={(date) => filterTime(date)}
                            // minDate={moment()?.toDate()}
                          />
                        </div>

                        <div className="custom__dropdown__main__container__range">
                          <p className="mb-3">Time</p>
                          <TimeRangeSlider
                            ref={featureRef}
                            value={value ? value : ""}
                            onChangeStart={changeStartHandler}
                            onChange={timeChangeHandler}
                            onComplete={changeCompleteHandler}
                          />
                          <div
                            className="custom__dropdown__main__container__range__inner"
                            style={{ marginTop: "10px" }}
                          >
                            <p className="ms-1">
                              {value?.start ? value?.start : "00:00"}
                            </p>
                            <p style={{ marginRight: "3px" }}>
                              {" "}
                              {value?.end ? value?.end : "23:59"}
                            </p>
                          </div>
                        </div>

                        <div className="custom__dropdown__main__container__type">
                          <p>Type</p>
                          <div className="custom__dropdown__main__container__type__pills">
                            <div
                              className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                education === "General Guidance"
                                  ? "session_active"
                                  : ""
                              }`}
                            >
                              <p
                                style={{
                                  color: "#F5F6F7",
                                }}
                                className={`custom__dropdown__main__container__type__pills__name `}
                                onClick={() =>
                                  educationSelected("General Guidance")
                                }
                              >
                                General guidance
                              </p>
                            </div>
                            <div
                              className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                education === "CV Review"
                                  ? "session_active"
                                  : ""
                              }`}
                            >
                              <p
                                style={{
                                  color: "#F5F6F7",
                                }}
                                className={`custom__dropdown__main__container__type__pills__name `}
                                onClick={() => educationSelected("CV Review")}
                              >
                                CV review
                              </p>
                            </div>
                            <div
                              className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                education === "Cover Letter Review"
                                  ? "session_active"
                                  : ""
                              }`}
                            >
                              <p
                                style={{
                                  color: "#F5F6F7",
                                }}
                                className={`custom__dropdown__main__container__type__pills__name `}
                                onClick={() =>
                                  educationSelected("Cover Letter Review")
                                }
                              >
                                Cover letter review
                              </p>
                            </div>
                            <div
                              className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                education === "Interview Practice"
                                  ? "session_active"
                                  : ""
                              }`}
                            >
                              <p
                                style={{
                                  color: "#F5F6F7",
                                }}
                                className={`custom__dropdown__main__container__type__pills__name `}
                                onClick={() =>
                                  educationSelected("Interview Practice")
                                }
                              >
                                Interview practice
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="custom__dropdown__main__container__experience">
                          <div className="custom__dropdown__main__container__experience__inner">
                            <p>Recognitions</p>
                            <div className="custom__dropdown__main__container__experience__inner__tooltip">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    <p className="custom__tooltip">
                                      Find the best mentors! "Rising" mentors
                                      have garnered over 4 stars with a minimum
                                      of 2 sessions, showcasing consistent
                                      positive performance and growth potential.
                                      "Top Coach" is reserved for those
                                      maintaining a rating exceeding 4 stars and
                                      completing at least 5 sessions,
                                      highlighting expertise as highly effective
                                      mentors. "High Rated" acknowledges
                                      individuals achieving an outstanding
                                      rating surpassing 4.5 stars.
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <img src={InfoCircle} alt="info circle" />
                              </OverlayTrigger>
                            </div>
                          </div>
                          <div className="custom__dropdown__main__container__experience__pills">
                            {[
                              {
                                name: "Top coach",
                                icon: DiamondGrey,
                              },
                              {
                                name: "High rated",
                                icon: RatedStar,
                              },
                              {
                                name: "Rising",
                                icon: RisingStar,
                              },
                            ].map((item, index) => (
                              <div
                                className={`custom__dropdown__main__container__experience__pills__pill pill-grey
                              ${
                                recognition === item.name
                                  ? "session_active"
                                  : ""
                              }
                                  `}
                                onClick={() => {
                                  recognitionSelected(item.name);
                                }}
                              >
                                <img
                                  src={item.icon}
                                  className="custom__dropdown__main__container__experience__pills__image"
                                />
                                <p
                                  style={{
                                    color: "#F5F6F7",
                                  }}
                                  className={`custom__dropdown__main__container__experience__pills__name `}
                                  // ${
                                  //   index === 2
                                  //     ? "custom__dropdown__main__container__experience__pills__selected"
                                  //     : ""
                                  // }`}
                                >
                                  {item.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="custom__dropdown__main__container__country"
                          style={{ gap: "0px" }}
                        >
                          <p>Investment bank</p>

                          <Dropdown onSelect={handleDropdownBankChangeNew}>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="dropdownToSelect select__icon"
                            >
                              <p className="forumm__button__text marginTop">
                                {banks ? banks : "All"}
                              </p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                              {InvestmentBanks.map((item, index) => (
                                <Dropdown.Item
                                  eventKey={item}
                                  className={`dropdownToSelect__item ${
                                    item === banks
                                      ? "dropdownToSelect__item__checked"
                                      : ""
                                  }`}
                                  key={item}
                                >
                                  {item}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {/* <button className="btn-green">Apply</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-9 col-12 row p-0 main__network__content`}>
                {/* <div
                className={`${userData?._id ? "" : "col-xl-8"
                  } col-12 p-0 position-relative container__network__cards`}
              > */}
                <div
                  className={`${
                    userData?.role == 1 && userData?._id
                      ? "col-xl-12"
                      : " col-xl-8"
                  } p-0 position-relative container__network__cards`}
                >
                  <div className="available__container__top">
                    <div className="available__container__top__right">
                      <p>
                        <span className="filter__button__mobile__text">
                          {temp?.length}
                        </span>{" "}
                        Available sessions
                      </p>
                    </div>
                    <Dropdown className="d-md-none">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="filter__button__mobile"
                      >
                        <img
                          src={SortIcon}
                          alt="filter button"
                          className="filter__button__mobile__image"
                        />
                        <p className="filter__button__mobile__text">Filter</p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className=" custom__dropdown__mobile custom__dropdown__bg">
                        <div className="available__container__filter">
                          <div className="available__container__filter__top">
                            <h2>Filters</h2>
                            <p
                              onClick={ResetFilter}
                              style={{ cursor: "pointer" }}
                            >
                              Reset
                            </p>
                          </div>
                          <div className="custom__dropdown">
                            <div className="custom__dropdown__main">
                              <h2 className="custom__dropdown__main__title">
                                Date
                              </h2>
                              <div className="custom__dropdown__main__container">
                                <div className="ascdvre__grehgtr">
                                  <div className="filter__label__inner">
                                    <img
                                      src={ArrowLeftMini}
                                      onClick={() =>
                                        filterTime(startDate, false, true)
                                      }
                                    />
                                    <label
                                      htmlFor="filterDate"
                                      className="filterDate filter__label__inner__dateshow"
                                    >
                                      <p className="">
                                        {startDate
                                          ? formatDate(startDate)
                                          : "All"}
                                      </p>
                                    </label>
                                    <img
                                      src={ArrowRightMini}
                                      onClick={() =>
                                        filterTime(startDate, true, false)
                                      }
                                    />
                                  </div>
                                  <DatePicker
                                    className="filter__date__input d-none"
                                    name="startDate"
                                    id="filterDate"
                                    placeholder="Select expiration date..."
                                    onSelect={(date) => filterTime(date)}
                                    // minDate={moment()?.toDate()}
                                  />
                                </div>

                                <div className="custom__dropdown__main__container__range">
                                  <p className="mb-3">Time</p>
                                  <TimeRangeSlider
                                    ref={featureRef}
                                    value={value ? value : ""}
                                    onChangeStart={changeStartHandler}
                                    onChange={timeChangeHandler}
                                    onComplete={changeCompleteHandler}
                                  />
                                  <div
                                    className="custom__dropdown__main__container__range__inner "
                                    style={{ marginTop: "10px" }}
                                  >
                                    <p className="ms-1">
                                      {value?.start ? value?.start : "00:00"}
                                    </p>
                                    <p style={{ marginRight: "3px" }}>
                                      {" "}
                                      {value?.end ? value?.end : "23:59"}
                                    </p>
                                  </div>
                                </div>
                                <div className="custom__dropdown__main__container__type">
                                  <p>Type</p>
                                  <div className="custom__dropdown__main__container__type__pills">
                                    <div
                                      className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                        education === "General Guidance"
                                          ? "session_active"
                                          : ""
                                      }`}
                                    >
                                      <p
                                        className={`custom__dropdown__main__container__type__pills__name `}
                                        onClick={() =>
                                          educationSelected("General Guidance")
                                        }
                                      >
                                        General guidance
                                      </p>
                                    </div>
                                    <div
                                      className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                        education === "CV Review"
                                          ? "session_active"
                                          : ""
                                      }`}
                                    >
                                      <p
                                        className={`custom__dropdown__main__container__type__pills__name `}
                                        onClick={() =>
                                          educationSelected("CV Review")
                                        }
                                      >
                                        CV review
                                      </p>
                                    </div>
                                    <div
                                      className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                        education === "Cover Letter Review"
                                          ? "session_active"
                                          : ""
                                      }`}
                                    >
                                      <p
                                        className={`custom__dropdown__main__container__type__pills__name `}
                                        onClick={() =>
                                          educationSelected(
                                            "Cover Letter Review"
                                          )
                                        }
                                      >
                                        Cover letter review
                                      </p>
                                    </div>
                                    <div
                                      className={`custom__dropdown__main__container__type__pills__pill pill-grey ${
                                        education === "Interview Practice"
                                          ? "session_active"
                                          : ""
                                      }`}
                                    >
                                      <p
                                        className={`custom__dropdown__main__container__type__pills__name `}
                                        onClick={() =>
                                          educationSelected(
                                            "Interview Practice"
                                          )
                                        }
                                      >
                                        Interview practice
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="custom__dropdown__main__container__experience">
                                  <div className="custom__dropdown__main__container__experience__inner">
                                    <p>Recognitions</p>
                                    <div className="custom__dropdown__main__container__experience__inner__tooltip">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            <p className="custom__tooltip">
                                              Find the best mentors! "Rising"
                                              mentors have garnered over 4 stars
                                              with a minimum of 2 sessions,
                                              showcasing consistent positive
                                              performance and growth potential.
                                              "Top Coach" is reserved for those
                                              maintaining a rating exceeding 4
                                              stars and completing at least 5
                                              sessions, highlighting expertise
                                              as highly effective mentors. "High
                                              Rated" acknowledges individuals
                                              achieving an outstanding rating
                                              surpassing 4.5 stars.
                                            </p>
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src={InfoCircle}
                                          alt="info circle"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="custom__dropdown__main__container__experience__pills">
                                    {[
                                      {
                                        name: "Top coach",
                                        icon: DiamondGrey,
                                      },
                                      {
                                        name: "High rated",
                                        icon: RatedStar,
                                      },
                                      {
                                        name: "Rising",
                                        icon: RisingStar,
                                      },
                                    ].map((item, index) => (
                                      <div
                                        className={`custom__dropdown__main__container__experience__pills__pill pill-grey
                              ${
                                recognition === item.name
                                  ? "session_active"
                                  : ""
                              }
                                  `}
                                        onClick={() => {
                                          recognitionSelected(item.name);
                                        }}
                                      >
                                        <img
                                          src={item.icon}
                                          className="custom__dropdown__main__container__experience__pills__image"
                                        />
                                        <p
                                          className={`custom__dropdown__main__container__experience__pills__name `}
                                          // ${
                                          //   index === 2
                                          //     ? "custom__dropdown__main__container__experience__pills__selected"
                                          //     : ""
                                          // }`}
                                        >
                                          {item.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                <div
                                  className="custom__dropdown__main__container__country"
                                  style={{ gap: "0px" }}
                                >
                                  <p>Investment bank</p>

                                  <Dropdown
                                    onSelect={handleDropdownBankChangeNew}
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="dropdownToSelect select__icon"
                                    >
                                      <p className="forumm__button__text">
                                        {banks ? banks : "All"}
                                      </p>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                      {InvestmentBanks.map((item, index) => (
                                        <Dropdown.Item
                                          eventKey={item}
                                          className={`dropdownToSelect__item ${
                                            item === banks
                                              ? "dropdownToSelect__item__checked"
                                              : ""
                                          }`}
                                          key={item}
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                {/* <button className="btn-green">Apply</button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="available__container__bottom">
                    <div className="available__container__bottom__container">
                      {/* {mydata} */}
                      {temp?.length > 0 ? (
                        temp?.map((elem, index) => {
                          if (elem?.selectSession.length > 0) {
                            return (
                              <MySessionCard key={elem?.userData[0]?._id}>
                                <div className="available__container__bottom__container__card">
                                  <div className="available__container__bottom__container__card__left">
                                    <img
                                      // src={elem?.mentorProfiles[0]?.image}
                                      src={
                                        elem?.mentorProfiles[0]?.image ||
                                        AvatarIcon
                                      }
                                      alt="avatar"
                                      className="available__container__bottom__container__card__left__image"
                                    />
                                    <div className="available__container__bottom__container__card__left__container">
                                      <Ratings />
                                      <p>(112 Sessions)</p>
                                    </div>
                                  </div>
                                  <div className="available__container__bottom__container__card__center">
                                    <div className="available__container__bottom__container__card__center__top">
                                      <div className="available__container__bottom__container__card__center__top__container">
                                        <h1 className="available__container__bottom__container__card__center__top__container__title text-capitalize">
                                          {elem?.userData[0]?.firstName}{" "}
                                          {elem?.userData[0]?.lastName}
                                        </h1>
                                        {elem?.mentorRattings && (
                                          <>{gettingTopOrRising(elem)}</>
                                        )}
                                      </div>
                                      <p
                                        className="available__container__bottom__container__card__center__top__description cardBackgroundtopTitle"
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          WebkitLineClamp: 3,
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {/* 10 years at Fast Track | Ex Interviewer
                                        | Will provide you with actionable
                                        guidance and tips to perform */}
                                        {elem?.mentorProfiles[0]?.background}
                                      </p>
                                    </div>
                                    <div className="available__container__bottom__container__card__center__bar"></div>
                                    <div className="available__container__bottom__container__card__center__bottom">
                                      {elem?.mentorProfiles[0]?.professionalDetails.map(
                                        (profDetail) => {
                                          return (
                                            <div className="available__container__bottom__container__card__center__bottom__container">
                                              <h2 className="available__container__bottom__container__card__center__bottom__container__title">
                                                {profDetail?.position}{" "}
                                                {profDetail?.experience}
                                              </h2>
                                              <div className="available__container__bottom__container__card__center__bottom__container__inner">
                                                {/* <p>
                                                  September, 2011 - August, 2023
                                                </p> */}
                                                <p>
                                                  {new Date(
                                                    profDetail?.startDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}{" "}
                                                  -{" "}
                                                  {new Date(
                                                    profDetail?.endDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                    }
                                                  )}
                                                </p>
                                                <img src={BiDot} alt="dot" />
                                                <p>
                                                  Portugal
                                                  {/* {
                                                    elem?.mentorProfiles[0]
                                                      ?.location
                                                  } */}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="available__container__bottom__container__card__right">
                                    <Dropdown
                                      className="available__container__bottom__container__card__right__dropdown"
                                      onSelect={(eventKey) =>
                                        handleSelectSession(eventKey, elem)
                                      }
                                    >
                                      <Dropdown.Toggle className="coaching__filter__button padding__button__mobile">
                                        <span className="coaching__filter__button__text">
                                          {elem?.selectedSession ||
                                            "Select type"}
                                        </span>
                                        <img
                                          src={DropDownArrow}
                                          className="coaching__filter__button__image"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="custom__dropdown custom__dropdown__bg">
                                        {elem?.selectSession.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              className="custom__dropdown__item"
                                              key={index}
                                              eventKey={item}
                                              // onClick={() => setSelectedPricing(item)}
                                              // onClick={() =>
                                              //   GetPriceValue(item, index)
                                              // }
                                            >
                                              {item}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    <h2 className="my-2">
                                      {elem?.mentorPricing?.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              {item?.currency === "usd"
                                                ? "$"
                                                : item?.currency === "euro"
                                                ? "€"
                                                : item?.currency == "gbp"
                                                ? "£"
                                                : item?.currency === "chf"
                                                ? "₣"
                                                : ""}
                                              {/* {item?.currency} */}
                                              {elem?.mentorPricing[0]?.[
                                                elem?.selectedSession
                                              ]
                                                ? elem?.mentorPricing[0]?.[
                                                    elem?.selectedSession
                                                  ]
                                                : "0"}
                                            </>
                                          );
                                        }
                                      )}
                                    </h2>
                                    <button
                                      className="available__container__bottom__container__card__right__button"
                                      onClick={() => {
                                        handleElementClick(elem);
                                      }}
                                    >
                                      Book Session
                                    </button>
                                  </div>
                                </div>
                              </MySessionCard>
                            );
                          }
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // height: "75vh",
                            marginTop: "184px",
                            marginBottom: "100px",
                          }}
                          className="reschedule__session__container__bottom__left"
                        >
                          <div className="no__found__main">
                            <div>
                              <img src={noFound} />
                            </div>
                            <div className="no__found">
                              No sessions available matching your search
                              criteria
                            </div>
                            <div className="appear__here">
                              Sessions proposed by others would appear on this
                              page
                            </div>
                            {/* <button onClick={()=>backtoBooking()} className='newpropose__button'>
                                Start Booking
                              </button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dashboard__footer foter__fixed d-md-none d-xl-block">
                    <Footer />
                  </div>
                </div>

                {userData?.role == 1 && userData?._id ? (
                  ""
                ) : (
                  <div className="col-xl-4 p-0 col-12  p-0 network__visitor__container">
                    <div className="network__visitor__container__inner">
                      <div className="join__all__over__the__world">
                        Join us to learn with mentors from all over the world
                      </div>
                      <div className="join__button__container">
                        <Link to="/signup" className="create__account">
                          Create an account
                        </Link>
                        <Link
                          to="/login"
                          className="network__right__side__login"
                        >
                          Log in
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                <div className="dashboard__footer d-none d-md-block d-xl-none">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsletterFooter />
    </>
  );
};

export default NetworkDashboardHome;
