import React, { useState, useMemo } from "react";
import "./BecomeMentor.scss";
import Form from "react-bootstrap/Form";
import minus from "../../assets/minus.svg";
import plusWhite from "../../assets/plusWhite.svg";
import squareAddWhite from "../../assets/squareAddWhite.svg";
import cvIcon from "../../assets/cvIcon.svg";
import plusImage from "../../assets/plusGreen.svg";
import DateTimePicker from "react-datetime-picker";
import { client } from "../../service/client";
import { ErrorToast, SuccessToast } from "../MyToaster";
import { API_URL } from "../../service/client";
import { useDispatch, useSelector } from "react-redux";
import { createMentorProfile } from "../../service/redux/middleware/mentor";
import countryList from "react-select-country-list";

import { useHistory } from "react-router-dom";
import ScreenLoader from "../Loader/ScreenLoader";
import FastLogo from "../../assets/8.svg";
import { Link } from "react-router-dom";
import RemoveIcon from "../../assets/remove-icon.svg";
import PhoneInput from "react-phone-number-input";
import CalendarEaxct from "../../assets/CalendarEaxct.svg";
import { MenuItem, Select } from "@mui/material";
import { Dropdown } from "react-bootstrap";
const bank = [
  "Bank of America Merrill Lynch",
  "BNP Paribas",
  "Barclays",
  "Bank of America Merrill Lynch",
  "Citigroup",
  "Credit Suisse",
  "Centerview Partners",
  "Deutsche Bank",
  "Evercore",
  "Goldman Sachs",
  "HSBC",
  "Jefferies",
  "Lazard",
  "Morgan Stanley",
  "Nomura",
  "RBC Capital Markets",
  "Rothschild",
  "UBS Group",
  "Wells Fargo Securities",
];

const BecomeMentor = () => {
  const options = useMemo(() => countryList().getData(), []);

  const [loader, setLoader] = useState(false);
  const [isVisiblePersonal, setIsVisiblePersonal] = useState(true);
  const [isVisibleEducation, setIsVisibleEducation] = useState(false);
  const [isVisibleBank, setIsVisibleBank] = useState(false);
  const [isVisibleExperience, setIsVisibleExperience] = useState(false);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  //
  // status of fields
  //
  const [isPersonalDone, setIsPersonalDone] = useState(false);
  const [isEducationDone, setIsEducationDone] = useState(false);
  const [isBankDone, setIsBankDone] = useState(false);
  const [dropdown, setDropDown] = useState("");

  // states for mentor profile
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    background: "",
    image: "",
    linkedin: "",
    imageDB: "",
    email: "",
    location: "",
    phoneNo: "",
  });
  // education states
  const [dropdownProgramme, setDropdownProgramme] = useState([""]);
  const [educationInfo, setEducationInfo] = useState([
    {
      institution: "",
      programme: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    },
  ]);
  const [isEducationCheck, setIsEducationCheck] = useState([false]);

  console.log("educationInfo", educationInfo);

  const handleDropDownProgrammeNew = (selected, index) => {
    const updateDD = [...dropdownProgramme];
    updateDD[index] = selected;
    setDropdownProgramme(updateDD);
    const updatedMultiplemages = [...educationInfo];
    updatedMultiplemages[index]["programme"] = selected;
    setEducationInfo(updatedMultiplemages);
  };
  // experience states
  const [cv, setCV] = useState("");
  const [experienceInfo, setExperienceInfo] = useState([
    {
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);
  const [isExperienceCheck, setIsExperienceCheck] = useState([false]);
  const [bankInfo, setBankInfo] = useState([
    {
      bank: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    },
  ]);
  const [isBankCheck, setIsBankCheck] = useState([false]);
  const token = useSelector((state) => state?.signin?.signInData?.token);
  const dispatch = useDispatch();
  const history = useHistory();
  //
  // togglers
  //
  const toggleVisibilityPersonal = () => {
    setIsVisiblePersonal(!isVisiblePersonal);
    setIsVisibleEducation(false);
    setIsVisibleExperience(false);
    setIsVisiblePayment(false);
    setIsVisibleBank(false);
  };
  const toggleVisibilityEducation = (isComing = false) => {
    if (isPersonalDone || isComing) {
      setIsVisibleEducation(!isVisibleEducation);
      setIsVisiblePersonal(false);
      setIsVisibleExperience(false);
      setIsVisiblePayment(false);
      setIsVisibleBank(false);
    } else {
      ErrorToast("Please fill all Personal details to continue.");
    }
  };
  const toggleVisibilityBank = (isComing = false) => {
    if (isPersonalDone || isComing) {
      setIsVisibleBank(!isVisibleBank);
      setIsVisiblePersonal(false);
      setIsVisibleExperience(false);
      setIsVisiblePayment(false);
      setIsVisibleEducation(false);
    } else {
      ErrorToast("Please fill all Personal details to continue.");
    }
  };
  const toggleVisibilityExperience = (isComing = false) => {
    setIsVisibleExperience(!isVisibleExperience);
    setIsVisibleEducation(false);
    setIsVisiblePersonal(false);
    setIsVisiblePayment(false);
    setIsVisibleBank(false);
  };
  //
  // click handlers for form
  //
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    var data = new FormData();
    data.append("files", file);
    if (file) {
      const imageURLS = URL.createObjectURL(file);
      setPersonalInfo({ ...personalInfo, image: imageURLS });
      const responses = await client.post(`${API_URL}/api/upload`, data);
      if (responses?.status === 200) {
        SuccessToast("Image uploaded successfully");
      }
      setPersonalInfo({ ...personalInfo, imageDB: responses.data.imageUrl });
    }
  };
  const linkChecker = () => {
    var expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    var t = personalInfo?.linkedin;
    var m = "";
    if (t === undefined) {
      ErrorToast("Please add your LinkedIn profile to continue.");
    } else if (t.match(regex)) {
      m = personalInfo?.linkedin;
    } else {
      var t = personalInfo?.linkedin;
      m = "" + t;
    }
    return m;
  };

  const handleSelectCountryNew = (selected) => {
    setPersonalInfo({
      ...personalInfo,
      location: selected,
    });
    setDropDown(selected);
  };

  const personalInfoHandler = () => {
    const link = linkChecker();
    setPersonalInfo({ ...personalInfo, linkedin: link });
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(personalInfo.email);
    let message = "";
    if (!personalInfo.firstName) {
      message = "Please enter your First Name";
    } else if (!personalInfo.lastName) {
      message = "Please enter your Last Name";
    } else if (!personalInfo.background) {
      message = "Please enter your Bio";
    } else if (!personalInfo.location) {
      message = "Please enter your Location";
    } else if (personalInfo.email) {
      if (!isValidEmail) {
        message.length > 0
          ? (message = " and valid email.")
          : (message = "Please enter valid email.");
      }
    } else if (!personalInfo.email) {
      message.length > 0
        ? (message = " and email.")
        : (message = "Please enter email.");
    }
    if (personalInfo.phoneNo === "") {
      message = "Please enter your Number";
    }
    if (!personalInfo.linkedin) {
      message.length > 0
        ? (message = ", linkedin profile link")
        : (message = "Please enter linkedin profile link");
    }

    if (message.length > 0) {
      setIsPersonalDone(false);
      ErrorToast(message);
    } else {
      setIsPersonalDone((prev) => true);
      toggleVisibilityEducation(true);
    }
  };
  // personal end
  const educationHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][isDate] = e;
      setEducationInfo(updatedEducationInfo);
    } else {
      const updatedEducationInfo = [...educationInfo];
      updatedEducationInfo[index][e.target?.name] = e.target?.value;
      setEducationInfo(updatedEducationInfo);
      // e.target.value = null;
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedEducationInfo = [...educationInfo];
    updatedEducationInfo[index].currentlyStuding =
      !updatedEducationInfo[index].currentlyStuding;
    setEducationInfo(updatedEducationInfo);
    const updatedIsEducationCheck = [...isEducationCheck];
    updatedIsEducationCheck[index] = !updatedIsEducationCheck[index]; // Toggle the value
    setIsEducationCheck(updatedIsEducationCheck);
  };
  const handleAddAnotherEducation = () => {
    const updatedEducationInfo = [...educationInfo];
    updatedEducationInfo.push({
      institution: "",
      degree: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyStuding: false,
    });
    setEducationInfo(updatedEducationInfo);
    const checkboxes = isEducationCheck;
    checkboxes.push(false);
    setIsEducationCheck(checkboxes);
  };
  const handleRemoveEducationClick = (e, index) => {
    e.preventDefault();
    const list = [...educationInfo];
    list.splice(index, 1);
    setEducationInfo(list);
    const checkboxes = isEducationCheck;
    checkboxes.splice(index, 1);
    setIsEducationCheck(checkboxes);
  };
  const handleEducationSubmit = () => {
    let allGood = true;
    educationInfo.map((item, index) => {
      if (item.degree && item.institution && item.startDate) {
        if (item.currentlyStuding) {
        } else {
          if (item.endDate) {
          } else {
            allGood = false;
            return;
          }
        }
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsEducationDone(true);
      toggleVisibilityBank(true);
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
      setIsEducationDone(false);
    }
  };
  const handleBankSubmit = () => {
    let allGood = true;
    bankInfo.map((item, index) => {
      if (item.bank && item.position && item.startDate) {
        if (item.currentlyStuding) {
        } else {
          if (item.endDate) {
          } else {
            allGood = false;
            return;
          }
        }
      } else {
        allGood = false;
        return;
      }
    });
    if (allGood) {
      setIsBankDone(true);
      setIsVisibleBank(false);
      setIsVisibleExperience(true);
    } else {
      ErrorToast("Please fill all mandatory fields to continue.");
      setIsBankDone(false);
    }
  };
  // professional Experience
  const handleCVUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size >= 2872139) {
      ErrorToast("File size should not exceed maximum limit of 3 MB");
    } else {
      var data = new FormData();
      data.append("files", file);
      if (file) {
        const responses = await client.post(`${API_URL}/api/upload`, data);
        if (responses?.status === 200) {
          SuccessToast("CV uploaded successfully");
        }
        setCV(responses.data.imageUrl);
      }
    }
  };
  const experienceHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][isDate] = e;
      setExperienceInfo(updatedExperienceInfo);
    } else {
      const updatedExperienceInfo = [...experienceInfo];
      updatedExperienceInfo[index][e.target?.name] = e.target?.value;
      setExperienceInfo(updatedExperienceInfo);
      // e.target.value = null;
    }
  };
  const [availableBanks, setAvailableBanks] = useState(bank);
  const [selectedBanks, setSelectedBanks] = useState([""]);

  const handleBankDropDown = (selected, index) => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo[index]["bank"] = selected;
    setBankInfo(updatedExperienceInfo);
    const updateSelected = [...selectedBanks];
    updateSelected[index] = selected;
    setSelectedBanks(updateSelected);
  };
  const bankHandler = (e, index, isDate = "") => {
    if (isDate.length > 0) {
      const updatedExperienceInfo = [...bankInfo];
      updatedExperienceInfo[index][isDate] = e;
      setBankInfo(updatedExperienceInfo);
    } else {
      const updatedExperienceInfo = [...bankInfo];
      updatedExperienceInfo[index][e.target?.name] = e.target?.value;
      setBankInfo(updatedExperienceInfo);
      // e.target.value = null;
    }
  };
  const handleCheckboxChangeBank = (index) => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo[index].currentlyEmployed =
      !updatedExperienceInfo[index].currentlyEmployed;
    setBankInfo(updatedExperienceInfo);
    const updatedIsExperienceCheck = [...isBankCheck];
    updatedIsExperienceCheck[index] = !updatedIsExperienceCheck[index]; // Toggle the value
    setIsBankCheck(updatedIsExperienceCheck);
  };

  const handleCheckboxChangeExperience = (index) => {
    const updatedExperienceInfo = [...experienceInfo];
    updatedExperienceInfo[index].currentlyEmployed =
      !updatedExperienceInfo[index].currentlyEmployed;
    setExperienceInfo(updatedExperienceInfo);
    const updatedIsExperienceCheck = [...isExperienceCheck];
    updatedIsExperienceCheck[index] = !updatedIsExperienceCheck[index];
    setIsExperienceCheck(updatedIsExperienceCheck);
  };
  const handleAddAnotherExperience = () => {
    const updatedExperienceInfo = [...experienceInfo];
    updatedExperienceInfo.push({
      company: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    });
    setExperienceInfo(updatedExperienceInfo);
    const checkboxes = isExperienceCheck;
    checkboxes.push(false);
    setIsExperienceCheck(checkboxes);
  };
  const handleAddAnotherBank = () => {
    const updatedExperienceInfo = [...bankInfo];
    updatedExperienceInfo.push({
      bank: "",
      position: "",
      startDate: new Date(),
      endDate: new Date(),
      currentlyEmployed: false,
    });
    setBankInfo(updatedExperienceInfo);
    const checkboxes = isBankCheck;
    checkboxes.push(false);
    setIsBankCheck(checkboxes);
    let updateBanks = [...selectedBanks];
    updateBanks.push("");
    setSelectedBanks(selectedBanks);
  };
  const handleRemoveExperienceClick = (e, index) => {
    e.preventDefault();
    const list = [...experienceInfo];
    list.splice(index, 1);
    setExperienceInfo(list);
    const checkboxes = isExperienceCheck;
    checkboxes.splice(index, 1);
    setIsExperienceCheck(checkboxes);
  };
  const handleRemoveBankClick = (e, index) => {
    e.preventDefault();
    const list = [...bankInfo];
    list.splice(index, 1);
    setBankInfo(list);
    const checkboxes = isBankCheck;
    checkboxes.splice(index, 1);
    setIsExperienceCheck(checkboxes);
    let updateBanks = [...selectedBanks];
    updateBanks.splice(index, 1);
    setSelectedBanks(selectedBanks);
  };
  const handleExperienceSubmit = () => {
    if (personalInfo?.linkedin && personalInfo?.email) {
      let allGood = true;
      educationInfo.map((item) => {
        if (item.degree && item.institution && item.startDate) {
        } else {
          allGood = false;
          return;
        }
      });
      if (allGood) {
        let allGood2 = true;
        if (experienceInfo[0]?.company !== "") {
          experienceInfo.map((item) => {
            if (item.company && item.position && item.startDate) {
            } else {
              allGood2 = false;
              return;
            }
          });
        }
        if (allGood2) {
          let objectToSend = {
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            email: personalInfo.email,
            location: personalInfo?.location,
            background: personalInfo?.background,
            phoneNo: personalInfo.phoneNo,
            linkedIn: personalInfo.linkedin,
            educationDetails: educationInfo,
            investmentBank: bankInfo,
            token: token,
          };
          if (experienceInfo[0]?.company !== "") {
            objectToSend = {
              ...objectToSend,
              professionalDetails: experienceInfo,
            };
          }
          if (personalInfo.imageDB !== "") {
            objectToSend = {
              ...objectToSend,
              image: personalInfo.imageDB,
            };
          }
          if (cv !== "") {
            objectToSend = {
              ...objectToSend,
              cv: cv,
            };
          }
          setLoader(true);
          dispatch(createMentorProfile(objectToSend)).then((res) => {
            if (res?.payload?.status === 200) {
              setLoader(false);
              SuccessToast(res?.payload?.data);
              history.push("/login");
            } else {
              setLoader(false);
              ErrorToast(res?.payload?.message);
            }
          });
        } else {
          ErrorToast(
            "Please fill all mandatory fields under Investment Banking Experience."
          );
        }
      } else {
        ErrorToast("Please fill all mandatory fields under Education.");
      }
    } else {
      ErrorToast("Please fill all mandatory fields under Personal.");
    }
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="BecomeMentorSect">
        <div className="BecomeMentorSect__main row justify-content-center">
          <div className="col-12 col-md-8 BecomeMentorSect__main__formSection pad__pad__pad">
            {/* <div
            className="justify-content-center remove-elements"
            style={{ position: "absolute", top: "0%", left: "45%" }}
          >
            <img src={FastLogo} className="SignUPSection__main__empty__image" />
          </div> */}
            <div className="small_screen__logo">
              <Link to="/">
                <img
                  src={FastLogo}
                  className="SignUPSection__main__empty__image"
                />
              </Link>
            </div>

            <div className="bar-bar-bar"></div>
            <div className="MentorInfoSec">
              <div className="width-mentor">
                <div className="MentorInfoSec__Heading">
                  <h1 className="" style={{ fontWeight: "600" }}>
                    Become a Mentor
                  </h1>
                </div>
                <div className="text__text__text">
                  Mentor people like you, from all over the world, to become
                  better professionals
                </div>
                <div className="MentorInfoSecForm">
                  <div>
                    <div
                      className={`MentorInfoSecForm__footer  ${
                        isVisiblePersonal ? "" : "padding__footer__bottom"
                      }`}
                      onClick={toggleVisibilityPersonal}
                    >
                      <h1>Personal Information</h1>
                      <div className="MentorInfoSecForm__footer__container">
                        {isPersonalDone && (
                          <div className="done__button">Done</div>
                        )}
                        <img
                          src={isVisiblePersonal ? minus : plusWhite}
                          alt=""
                        />
                      </div>
                    </div>
                    {isVisiblePersonal ? (
                      <>
                        <div className="PersonalDiv">
                          <div className="uploadImage">
                            <h1 className="uploadImage__label">
                              Upload picture
                            </h1>
                            <label htmlFor="upload">
                              <div className="uploadImage__circle">
                                {personalInfo?.image ||
                                personalInfo?.imageDB ? (
                                  <img
                                    src={
                                      personalInfo.imageDB || personalInfo.image
                                    }
                                    alt="user profile image"
                                    className="uploadImage__circle__pf"
                                  />
                                ) : (
                                  <img
                                    src={plusImage}
                                    alt="profile img static"
                                    className="uploadImage__circle__plus"
                                  />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              accept="image/png, image/jpeg, image/jpg"
                              className="d-none"
                              id="upload"
                              onChange={(e) => handleImageUpload(e)}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Form.Group
                              style={{ marginRight: "10px" }}
                              className=" loginfield__optional__margin "
                              controlId=""
                            >
                              <Form.Label className="loginfield__Label">
                                First name
                              </Form.Label>
                              <Form.Control
                                type=" text"
                                className="loginfield__type text__color"
                                name="firstName"
                                value={personalInfo.firstName}
                                onChange={(e) =>
                                  setPersonalInfo({
                                    ...personalInfo,
                                    firstName: e.target.value,
                                  })
                                }
                                placeholder="John"
                              />
                            </Form.Group>
                            <Form.Group
                              className=" loginfield__optional__margin "
                              controlId=""
                            >
                              <Form.Label className="loginfield__Label">
                                Last name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="loginfield__type"
                                name="lastName"
                                value={personalInfo.lastName}
                                onChange={(e) =>
                                  setPersonalInfo({
                                    ...personalInfo,
                                    lastName: e.target.value,
                                  })
                                }
                                placeholder="Doe"
                              />
                            </Form.Group>
                          </div>
                          <Form.Group
                            className="  loginfield__optional__margin"
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label">
                              Brief background
                            </Form.Label>
                            <textarea
                              type="text"
                              placeholder="Tell candidates about yourself and your background. Explain them why you are the right mentor for them"
                              className="reschedule__session__container__bottom__left__form__container__input input__element text_area_area"
                              style={{ marginTop: "12px" }}
                              name="background"
                              value={personalInfo?.background}
                              onChange={(e) =>
                                setPersonalInfo({
                                  ...personalInfo,
                                  background: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            className=" loginfield__optional__margin "
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label">
                              Location
                            </Form.Label>
                            <Dropdown onSelect={handleSelectCountryNew}>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="dropdownToSelect select__icon"
                              >
                                <p className="forumm__button__text">
                                  {dropdown
                                    ? dropdown
                                    : "Please select country"}
                                </p>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                {options?.map((item, index) => (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={item.label}
                                    className={`dropdownToSelect__item ${
                                      item.label === dropdown
                                        ? "dropdownToSelect__item__checked"
                                        : ""
                                    }`}
                                  >
                                    {item.label}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Form.Group>
                          <Form.Group
                            className=" loginfield__optional__margin "
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label">
                              Email
                            </Form.Label>
                            <Form.Control
                              type=" text"
                              className="loginfield__type"
                              value={personalInfo.email}
                              onChange={(e) =>
                                setPersonalInfo({
                                  ...personalInfo,
                                  email: e.target.value,
                                })
                              }
                              placeholder="johndoe@gmail.com"
                            />
                          </Form.Group>

                          <Form.Group
                            className=" loginfield__optional__margin "
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label ">
                              Phone number
                            </Form.Label>

                            <PhoneInput
                              className="loginfield__type"
                              placeholder="please enter the number"
                              //defaultCountry="RU"
                              value={personalInfo.phoneNo}
                              onChange={(e) =>
                                setPersonalInfo({
                                  ...personalInfo,
                                  phoneNo: e,
                                })
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className=" loginfield__optional__margin "
                            controlId=""
                          >
                            <Form.Label className="loginfield__Label">
                              LinkedIn profile
                            </Form.Label>
                            <Form.Control
                              type=" text"
                              className="mentor__select__tag"
                              value={personalInfo.linkedin}
                              onChange={(e) =>
                                setPersonalInfo({
                                  ...personalInfo,
                                  linkedin: e.target.value,
                                })
                              }
                              placeholder=" https://www.linkedin.com/in/johndoe"
                            />
                          </Form.Group>
                          <button
                            className="continue__mentor__btn"
                            onClick={personalInfoHandler}
                            disabled= {personalInfoHandler ? false : true}
                          >
                            Next
                          </button>
                        </div>
                        <div className="border__bottom"></div>
                      </>
                    ) : (
                      <div className="border__bottom"></div>
                    )}

                    <div
                      className={`MentorInfoSecForm__footer ${
                        isVisibleEducation ? "" : "padding__footer__bottom"
                      }`}
                      onClick={toggleVisibilityEducation}
                    >
                      <h1>Education</h1>
                      <div className="MentorInfoSecForm__footer__container">
                        {isEducationDone && (
                          <div className="done__button">Done</div>
                        )}
                        <img
                          src={isVisibleEducation ? minus : plusWhite}
                          alt=""
                        />
                      </div>
                    </div>
                    {isVisibleEducation ? (
                      <>
                        <div className="EducationDiv">
                          {educationInfo?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div style={{ marginTop: "28px" }}>
                                  <div className="cancelbtn-container">
                                    {educationInfo.length > 1 && i > 0 && (
                                      <button
                                        className="btn-cancel"
                                        onClick={(e) =>
                                          handleRemoveEducationClick(e, i)
                                        }
                                      >
                                        <p>Remove</p>
                                        <img src={RemoveIcon} />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <p className="text__add__education">
                                  Add at least one education
                                </p>

                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Institution
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="mentor__field"
                                    placeholder="London Business School"
                                    value={educationInfo[i]?.institution}
                                    name="institution"
                                    onChange={(e) => educationHandler(e, i)}
                                  />
                                </Form.Group>

                                <Form.Group className=" loginfield__optional__margin ">
                                  <Form.Label
                                    className="loginfield__Label"
                                    htmlFor={"programme"}
                                  >
                                    Program
                                    <img
                                      style={{
                                        marginLeft: "3px",
                                        paddingBottom: "5px",
                                      }}
                                      // src={redstareck}
                                      alt=""
                                    />
                                  </Form.Label>
                                  <Dropdown
                                    onSelect={(e) =>
                                      handleDropDownProgrammeNew(e, i)
                                    }
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="dropdownToSelect select__icon"
                                    >
                                      <p className="forumm__button__text">
                                        {dropdownProgramme[i]
                                          ? dropdownProgramme[i]
                                          : "Please select degree"}
                                      </p>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                      <Dropdown.Item
                                        eventKey={"Bachelors"}
                                        className="dropdownToSelect__item"
                                      >
                                        Bachelors
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey={"Masters"}
                                        className="dropdownToSelect__item  "
                                      >
                                        Masters
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        eventKey={"MBA"}
                                        className="dropdownToSelect__item"
                                      >
                                        MBA
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Form.Group>

                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Field of study
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="mentor__field"
                                    placeholder="Finance"
                                    value={educationInfo[i]?.degree}
                                    name="degree"
                                    onChange={(e) => educationHandler(e, i)}
                                  />
                                </Form.Group>

                                <div className=" datepickerRow ">
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      Start time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      name="startDate"
                                      selected={educationInfo[i]?.startDate}
                                      value={educationInfo[i]?.startDate}
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        educationHandler(e, i, "startDate")
                                      }
                                    />
                                  </div>
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      End time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      selected={educationInfo[i]?.endDate}
                                      value={educationInfo[i]?.endDate}
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        educationHandler(e, i, "endDate")
                                      }
                                      disabled={
                                        educationInfo[i]?.currentlyStuding
                                      }
                                    />
                                  </div>
                                </div>
                                <Form.Label
                                  className="mentor__checkbox__container mb-0"
                                  htmlFor={`personalCheck${i}`}
                                >
                                  <Form.Check
                                    id={`personalCheck${i}`}
                                    name={`personalCheck${i}`}
                                    aria-label={`personalCheck${i}`}
                                    className="mentor__checkbox"
                                    checked={isEducationCheck[i]}
                                    onChange={() => handleCheckboxChange(i)}
                                  />
                                  <p>I am still attending this institution</p>
                                </Form.Label>
                              </div>
                            );
                          })}
                          <button
                            className="personalADD mentor__add"
                            onClick={handleAddAnotherEducation}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="m-0">Add another education</p>
                          </button>
                          <button
                            className="continue__mentor__btn continue__center"
                            onClick={handleEducationSubmit}

                          >
                            Next
                          </button>
                        </div>
                        <div className="border__bottom"></div>
                      </>
                    ) : (
                      <div className="border__bottom"></div>
                    )}

                    <div
                      className={`MentorInfoSecForm__footer ${
                        isVisibleBank ? "" : "padding__footer__bottom"
                      }`}
                      onClick={toggleVisibilityBank}
                    >
                      <h1>Investment banking experience</h1>
                      <div className="MentorInfoSecForm__footer__container">
                        {isBankDone && <div className="done__button">Done</div>}
                        <img src={isVisibleBank ? minus : plusWhite} alt="" />
                      </div>
                    </div>
                    {isVisibleBank ? (
                      <>
                        <div className="ProfessionalExpDiv">
                          <label htmlFor="cvupload" className="w-100 mb-0">
                            <div
                              className={`${
                                cv.length > 0
                                  ? "resume__button__success"
                                  : "resume__button"
                              }`}
                            >
                              {cv.length > 0 ? (
                                "CV Uploaded Successfully"
                              ) : (
                                <>
                                  <img src={cvIcon} alt=""></img> Attach CV
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              accept="application/pdf"
                              className="d-none"
                              id="cvupload"
                              onChange={(e) => handleCVUpload(e)}
                            />
                          </label>
                          {bankInfo?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="cancelbtn-container">
                                  {bankInfo.length > 1 && i > 0 && (
                                    <button
                                      className="btn-cancel"
                                      style={{ marginTop: "28px" }}
                                      onClick={(e) =>
                                        handleRemoveBankClick(e, i)
                                      }
                                    >
                                      <p>Remove</p>
                                      <img src={RemoveIcon} />
                                    </button>
                                  )}
                                </div>
                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Investment bank
                                  </Form.Label>
                                </Form.Group>
                                <Dropdown
                                  onSelect={(e) => handleBankDropDown(e, i)}
                                >
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="dropdownToSelect select__icon"
                                  >
                                    <p className="forumm__button__text">
                                      {selectedBanks[i]
                                        ? selectedBanks[i]
                                        : "Select Investment bank"}
                                    </p>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="scrollbar-nonetimezone custom__dropdown__bg dropdownToSelect__menu ">
                                    {availableBanks?.map((item, index) => (
                                      <Dropdown.Item
                                        key={index}
                                        eventKey={item}
                                        className={`dropdownToSelect__item ${
                                          item === selectedBanks[i]
                                            ? "dropdownToSelect__item__checked"
                                            : ""
                                        }`}
                                      >
                                        {item}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Position
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="mentor__field"
                                    placeholder="Investment Banking Associate"
                                    name="position"
                                    value={bankInfo[i]?.position}
                                    onChange={(e) => bankHandler(e, i)}
                                  />
                                </Form.Group>
                                <div className=" datepickerRow ">
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      Start time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      name="startDate"
                                      selected={bankInfo[i]?.startDate}
                                      value={bankInfo[i]?.startDate}
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        bankHandler(e, i, "startDate")
                                      }
                                    />
                                  </div>
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      End time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      selected={bankInfo[i]?.endDate}
                                      value={bankInfo[i]?.endDate}
                                      name="endDate"
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        bankHandler(e, i, "endDate")
                                      }
                                      disabled={bankInfo[i]?.currentlyEmployed}
                                    />
                                  </div>
                                </div>
                                <Form.Label
                                  className="mentor__checkbox__container mb-0"
                                  htmlFor={`bankPersonalCheck${i}`}
                                >
                                  <Form.Check
                                    id={`bankPersonalCheck${i}`}
                                    name={`bankPersonalCheck${i}`}
                                    className={`bankPersonalCheck${i}`}
                                    aria-label={`bankPersonalCheck${i}`}
                                    checked={isBankCheck[i]}
                                    onChange={() => handleCheckboxChangeBank(i)}
                                  />
                                  <p>I am still working at this bank</p>
                                </Form.Label>
                              </div>
                            );
                          })}
                          <button
                            className="personalADD"
                            onClick={handleAddAnotherBank}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="m-0">Add another Bank</p>
                          </button>
                          <button
                            className="continue__mentor__btn continue__end"
                            onClick={handleBankSubmit}
                          >
                            Next
                          </button>
                        </div>
                        <div className="border__bottom"></div>
                      </>
                    ) : (
                      <div className="border__bottom"></div>
                    )}
                    <div
                      className={`MentorInfoSecForm__footer ${
                        isVisibleExperience ? "" : "padding__footer__bottom"
                      }`}
                      onClick={toggleVisibilityExperience}
                    >
                      <h1>Other Experience (Optional)</h1>
                      <img
                        src={isVisibleExperience ? minus : plusWhite}
                        alt=""
                      />
                    </div>

                    {isVisibleExperience ? (
                      <>
                        <div className="ProfessionalExpDiv">
                          {experienceInfo?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div>
                                  <div className="cancelbtn-container">
                                    {experienceInfo.length > 1 && i > 0 && (
                                      <button
                                        className="btn-cancel"
                                        onClick={(e) =>
                                          handleRemoveExperienceClick(e, i)
                                        }
                                      >
                                        <p>Remove</p>
                                        <img src={RemoveIcon} />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Company
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="mentor__field"
                                    placeholder="Black Stone"
                                    value={experienceInfo[i]?.company}
                                    name="company"
                                    onChange={(e) => experienceHandler(e, i)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className=" loginfield__optional__margin "
                                  controlId=""
                                >
                                  <Form.Label className="loginfield__Label">
                                    Position
                                  </Form.Label>
                                  <Form.Control
                                    type=" password"
                                    className="mentor__field"
                                    placeholder="Principal"
                                    value={experienceInfo[i]?.position}
                                    name="position"
                                    onChange={(e) => experienceHandler(e, i)}
                                  />
                                </Form.Group>

                                <div className=" datepickerRow ">
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      Start time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      name="startDate"
                                      selected={experienceInfo[i]?.startDate}
                                      value={experienceInfo[i]?.startDate}
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        experienceHandler(e, i, "startDate")
                                      }
                                    />
                                  </div>
                                  <div className="datepickerRow__inner">
                                    <p className="datepickerRow__inner__text">
                                      End time
                                    </p>
                                    <DateTimePicker
                                      format="MMM dd, yyyy"
                                      disableClock={true}
                                      className="schedules__guides__contaiener__calendar"
                                      selected={experienceInfo[i]?.endDate}
                                      value={experienceInfo[i]?.endDate}
                                      name="endDate"
                                      placeholder="Select expiration date..."
                                      calendarIcon={
                                        <img
                                          src={CalendarEaxct}
                                          alt="Calendar Icon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      }
                                      onChange={(e) =>
                                        experienceHandler(e, i, "endDate")
                                      }
                                      disabled={
                                        experienceInfo[i]?.currentlyEmployed
                                      }
                                    />
                                  </div>
                                </div>
                                <Form.Label
                                  className="mentor__checkbox__container mb-0"
                                  htmlFor={`professionalExperience${i}`}
                                >
                                  <Form.Check
                                    id={`professionalExperience${i}`}
                                    name={`professionalExperience${i}`}
                                    className="mentor__checkbox"
                                    aria-label={`professionalExperience${i}`}
                                    checked={isExperienceCheck[i]}
                                    onChange={() =>
                                      handleCheckboxChangeExperience(i)
                                    }
                                  />
                                  <p>I am still working at this company</p>
                                </Form.Label>
                              </div>
                            );
                          })}
                          <button
                            className="personalADD"
                            onClick={handleAddAnotherExperience}
                          >
                            <img src={squareAddWhite} alt="" />
                            <p className="m-0">Add another experience</p>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="border__bottom"></div>
                    )}
                  </div>
                </div>
                <button
                  className="continue__mentor__btn continue__submit"
                  onClick={handleExperienceSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeMentor;
